import {handle} from "redux-pack";
import {FETCH_DUTIES, FETCH_DUTY_SUMMARY} from "../../../actions/dashboard/finances/duty_action";

const initialState = {
    isLoading: false,
    error: null,
    dutySummary: null,
    duties: [],
    isFetchDutiesLoading: false,
    isFetchDutySummaryLoading: false

};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case FETCH_DUTIES:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, isFetchDutiesLoading: true, error: null, duties: []
                }),
                finish: prevState => ({...prevState, isFetchDutiesLoading: false, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, duties: payload.data.content
                }),
            });
        case FETCH_DUTY_SUMMARY:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, isFetchDutySummaryLoading: true, error: null, dutySummary: null
                }),
                finish: prevState => ({...prevState, isFetchDutySummaryLoading: false, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, dutySummary: payload.data
                }),
            });
        default:
            return state;

    }
}