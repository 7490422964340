import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, forEach, map, round, toSafeInteger } from 'lodash';
import { DOCUMENT_TYPE, formatOdometer, IMAGE_KIT_URL_PREFIX } from '../../../../constant';
import DocumentListModal from '../../../../utils/document_list_modal';
import LightBoxUtils from '../../../../utils/light_box_utils';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { getHumanizeTime } from '../../../../utils/date_utils';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { get, toNumber } from 'lodash';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import GenerateReportForSelectedTemplateModal from 'components/dashboard/reportTemplates/GenerateReportForSelectedTemplateModal';
import { REPORT_TEMPLATE_TYPES } from 'utils/reportTemplatesUtils';
import { withRoute } from '../../customHocs/withRoute';
import { Link } from 'react-router-dom';

class RemoteServiceEntryPaging extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            images: [],
            documents: [],
            isDocumentModalOpen: false,
            isGenerateReportForTemplateModalOpen: false,
            isDropdownOpen: false,
            selectedServiceEntry: null,
        };

        this.rowClick = this.rowClick.bind(this);
        this.showMoreButtons = this.showMoreButtons.bind(this);
        this.deleteServiceEntry = this.deleteServiceEntry.bind(this);
        this.showDocuments = this.showDocuments.bind(this);
        this.showImages = this.showImages.bind(this);
    }

    onGenerateReportForTemplateModalToggle = (id = null) => {
        this.setState({
            isGenerateReportForTemplateModalOpen: !this.state.isGenerateReportForTemplateModalOpen,
            selectedServiceEntry: this.state.isGenerateReportForTemplateModalOpen ? null : id,
        });
    };

    showDocuments(event) {
        const id = event.currentTarget.dataset.id;
        const serviceEntry = find(this.props.serviceEntryList, { 'id': toSafeInteger(id) });
        const documents = [];
        forEach(serviceEntry.serviceEntryImages, function (image) {
            if (image.documentType === DOCUMENT_TYPE.DOCUMENT) {
                documents.push({ id: image.id, url: IMAGE_KIT_URL_PREFIX + image.url });
            }
        });
        this.setState({
            documents: documents,
            isDocumentModalOpen: true,
        });
    }

    onCloseDocumentRequest() {
        this.setState({
            isDocumentModalOpen: false,
        });
    }

    showImages(event) {
        const id = event.currentTarget.dataset.id;
        const serviceEntry = find(this.props.serviceEntryList, { 'id': toSafeInteger(id) });
        const images = [];
        forEach(serviceEntry.serviceEntryImages, function (image) {
            if (image.documentType === DOCUMENT_TYPE.IMAGE) {
                images.push(IMAGE_KIT_URL_PREFIX + image.url);
            }
        });
        this.setState({
            images: images,
            isOpen: true,
        });
    }

    rowClick({ id }) {
        const serviceEntry = find(this.props.serviceEntryList, { 'id': toSafeInteger(id) });
        this.props.navigate(`/dashboard/maintenance/serviceentries/edit/${serviceEntry.vehicleId}/${id}`);
    }

    deleteServiceEntry(event) {
        if (confirm(`Are you sure you want to delete this Service Entry?`)) {
            this.props.onDeleteRow(event.currentTarget.dataset.id);
        }
    }

    deleteServiceEntryById(id) {
        if (confirm(`Are you sure you want to delete this Service Entry?`)) {
            this.props.onDeleteRow(id);
        }
    }

    showMoreButtons(id, serviceEntry) {
        let imageCount = 0;
        let documentCount = 0;

        forEach(serviceEntry.serviceEntryImages, (o) => {
            if (o.documentType === DOCUMENT_TYPE.IMAGE) {
                imageCount++;
            } else {
                documentCount++;
            }
        });

        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <ButtonDropdown
                    isOpen={this.state.isDropdownOpen === id}
                    toggle={() => {
                        this.setState({
                            isDropdownOpen: this.state.isDropdownOpen === id ? 0 : id,
                        });
                    }}
                    className="pos-static"
                >
                    <DropdownToggle className="px-3">
                        <i className="fa fa-ellipsis-v" style={{ fontSize: '1.1rem' }} />
                    </DropdownToggle>
                    <DropdownMenu style={{ width: '12rem' }} right>
                        <DropdownItem
                            onClick={() => {
                                this.onGenerateReportForTemplateModalToggle(id);
                            }}
                        >
                            <div className="d-flex align-items-center p-1">
                                <i className="fa fa-print mr-2" />
                                <span className="mr-2">Print</span>
                            </div>
                        </DropdownItem>
                        {imageCount > 0 && (
                            <DropdownItem
                                onClick={() => {
                                    this.showImages();
                                }}
                            >
                                <div className="d-flex align-items-center p-1">
                                    <i className="fa fa-image mr-2" />
                                    <span className="mr-2">Images:</span>
                                    <span>{imageCount}</span>
                                </div>
                            </DropdownItem>
                        )}
                        {documentCount > 0 && (
                            <DropdownItem
                                onClick={(e) => {
                                    this.showDocuments(e);
                                }}
                            >
                                <div className="d-flex align-items-center p-1">
                                    <i className="fa fa-file mr-2" />
                                    <span className="mr-2">Documents:</span>
                                    <span>{documentCount}</span>
                                </div>
                            </DropdownItem>
                        )}
                        {this.props.isUpdateServiceEntryAllowed && (
                            <DropdownItem
                                onClick={() => {
                                    this.rowClick({ id });
                                }}
                            >
                                <div className="d-flex align-items-center p-1">
                                    <i className="fa fa-edit mr-2" />
                                    <span className="mr-2">Edit</span>
                                </div>
                            </DropdownItem>
                        )}
                        {this.props.isDeleteServiceEntryAllowed && (
                            <DropdownItem
                                onClick={() => {
                                    this.deleteServiceEntryById(id);
                                }}
                            >
                                <div className="d-flex align-items-center p-1">
                                    <i className="fa fa-trash mr-2" />
                                    <span className="mr-2">Delete</span>
                                </div>
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        );
    }

    onCloseRequest() {
        this.setState({
            isOpen: false,
        });
    }

    getCaret(direction) {
        if (direction === 'asc') {
            return <i className="fa fa-caret-up" style={{ margin: '0 5px' }} />;
        }
        if (direction === 'desc') {
            return <i className="fa fa-caret-down" style={{ margin: '0 5px' }} />;
        }
        return <i className="fa fa-sort" style={{ margin: '0 5px' }} />;
    }

    showVehicleDetail = (vehicleId) => {
        const vehicle = this.props.vehicleListMiniAsMap[vehicleId];
        let name = '';
        let licensePlate = '';
        if (vehicle) {
            name = vehicle['name'];
            licensePlate = vehicle['licensePlate'];
        }
        return (
            <div className="whitespace-normal">
                <div className="text-capitalize">
                    <h5>{name}</h5>
                </div>
                <div className="text-uppercase">
                    <h6>{licensePlate}</h6>
                </div>
            </div>
        );
    };

    showDate = (date, serviceEntry) => {
        return (
            <div>
                <div>{getHumanizeTime(date)}</div>
                {serviceEntry.startDate && <div> Start Date: {getHumanizeTime(serviceEntry.startDate)}</div>}
            </div>
        );
    };

    showOdometer = (meterEntry) => {
        return <div>{formatOdometer(get(meterEntry, 'value', '0'))} km</div>;
    };

    showTotalAmount = (totalAmount) => {
        return (
            <div>
                {round(totalAmount, 2)} {getCurrencyFromCountry(this.props.loggedInUser, true)}
            </div>
        );
    };

    showPartsCost = (partsSubtotal) => {
        return (
            <div>
                {round(partsSubtotal, 2)} {getCurrencyFromCountry(this.props.loggedInUser, true)}
            </div>
        );
    };

    showLaborCost = (laborSubtotal) => {
        return (
            <div>
                {round(laborSubtotal, 2)} {getCurrencyFromCountry(this.props.loggedInUser, true)}
            </div>
        );
    };

    showDiscount = (discount, serviceEntry) => {
        let totalDiscount = 0;
        let subTotal = 0;

        if (serviceEntry.serviceLineItems?.length) {
            serviceEntry.serviceLineItems.forEach((item) => {
                if (item.subTotal) {
                    subTotal += item.subTotal;
                }
                if (item.serviceParts?.length) {
                    item.serviceParts.forEach((servicePart) => {
                        totalDiscount += (servicePart.cost || 0) * 0.01 * (servicePart.discountPercent || 0);
                    });
                }
            });
        }

        if (serviceEntry.discountPercentage > 0) {
            totalDiscount += serviceEntry.discountPercentage * 0.01 * subTotal;
        } else if (discount > 0) {
            totalDiscount += discount;
        }
        if (totalDiscount > 0) {
            return (
                <div>
                    {round(totalDiscount, 2)} {getCurrencyFromCountry(this.props.loggedInUser, true)}
                </div>
            );
        } else {
            return <div />;
        }
    };

    showTax = (taxTotal, serviceEntry) => {
        let combineTax = 0;
        let totalLaborCost = 0;
        if (serviceEntry.serviceLineItems?.length) {
            serviceEntry.serviceLineItems.forEach((item) => {
                if (item.serviceParts?.length) {
                    item.serviceParts.forEach((servicePart) => {
                        let itemCostAfterDiscount =
                            Number(servicePart.cost || 0) -
                            toNumber(servicePart.discountPercent || 0) * 0.01 * Number(servicePart.cost || 0);
                        // tax for 1 line item
                        combineTax +=
                            toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount +
                            toNumber((servicePart.tax2Type ? servicePart.tax2Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                    });
                }
                if (item.laborCost) {
                    totalLaborCost += toNumber(item.laborCost);
                }
            });
        }

        // parent level tax calculation
        if (serviceEntry.laborTax1) {
            combineTax += toNumber(serviceEntry.laborTax1);
        } else if (serviceEntry.laborTax1Percentage) {
            combineTax += toNumber(serviceEntry.laborTax1Percentage) * 0.01 * totalLaborCost;
        }
        if (serviceEntry.laborTax2) {
            combineTax += toNumber(serviceEntry.laborTax2);
        } else if (serviceEntry.laborTax2Percentage) {
            combineTax += toNumber(serviceEntry.laborTax2Percentage) * 0.01 * totalLaborCost;
        }

        return (
            <div>
                {combineTax && (
                    <div>
                        {round(combineTax, 2)} {getCurrencyFromCountry(this.props.loggedInUser, true)}
                    </div>
                )}
            </div>
        );
    };

    showMoreInfo = (notes, serviceEntry) => {
        return (
            <div>
                {notes && <div>Notes: {notes}</div>}
                {serviceEntry.vendor && <div>Vendor: {serviceEntry.vendor.name}</div>}
                {serviceEntry.reference && <div>Reference: {serviceEntry.reference}</div>}
            </div>
        );
    };

    toggleControlButtonDropdown = (id) => {
        this.setState({
            isControlButtonDropdownOpen: {
                ...this.state.isControlButtonDropdownOpen,
                [id]: !this.state.isControlButtonDropdownOpen[id],
            },
        });
    };

    showLineItems = (serviceLineItems) => {
        return <ol>{this.renderServiceLineItems(serviceLineItems)}</ol>;
    };
    showWorkOrder = (workOrderId, rowData) => {
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Link to={`/dashboard/workorders/edit/${workOrderId}`}>{get(rowData, 'workOrderNumber', '')}</Link>
            </div>
        );
    };

    renderServiceLineItems(serviceLineItems) {
        return map(serviceLineItems, (serviceLineItem, index) => {
            return (
                <li key={`${serviceLineItem.id}-${index}`}>
                    <div className="h6 mb-0">{serviceLineItem.name ? serviceLineItem.name : ''}</div>
                    {serviceLineItem.description && <div>Notes: {serviceLineItem.description}</div>}
                    {serviceLineItem.partsCost > 0 && (
                        <div>
                            Parts Cost: {serviceLineItem.partsCost}{' '}
                            {getCurrencyFromCountry(this.props.loggedInUser, true)}
                        </div>
                    )}
                    {serviceLineItem.laborCost > 0 && (
                        <div>
                            Labor Cost: {serviceLineItem.laborCost}{' '}
                            {getCurrencyFromCountry(this.props.loggedInUser, true)}
                        </div>
                    )}
                    {serviceLineItem.itemCount > 0 && (
                        <div>
                            Item Count: {serviceLineItem.itemCount}{' '}
                            {serviceLineItem.unit ? `(${serviceLineItem.unit})` : ''}{' '}
                        </div>
                    )}
                </li>
            );
        });
    }

    render() {
        const options = {
            hideSizePerPage: true,
            sortName: 'date',
            sortOrder: this.props.sortOrder,
            sizePerPage: this.props.sizePerPage,
            onPageChange: this.props.onPageChange,
            page: this.props.currentPage,
            alwaysShowAllBtns: true,
            onSortChange: this.props.onSortChange,
            onRowClick: this.props.updateServiceEntries ? this.rowClick : null,
            noDataText: 'No Service Entry found.',
        };
        return (
            <div>
                {this.state.isDocumentModalOpen && (
                    <DocumentListModal
                        {...this.state}
                        title="Documents"
                        onCloseRequest={this.onCloseDocumentRequest.bind(this)}
                    />
                )}
                {this.state.isOpen && <LightBoxUtils {...this.state} onCloseRequest={this.onCloseRequest.bind(this)} />}
                <div className="card fleetx-table-parent-container">
                    <BootstrapTable
                        containerClass="white-background"
                        tableContainerClass="fleetx-table-container"
                        tableHeaderClass="fleetx-table"
                        tableBodyClass="fleetx-table"
                        trClassName="tr-user-height cursor-pointer"
                        version="4"
                        hover
                        remote={true}
                        data={this.props.data}
                        pagination={true}
                        options={options}
                        fetchInfo={{ dataTotalSize: this.props.totalDataSize }}
                    >
                        {!this.props.vehicleDetail && (
                            <TableHeaderColumn
                                columnClassName="td-column-center"
                                width="140"
                                dataField="vehicleId"
                                dataFormat={this.showVehicleDetail}
                            >
                                Vehicle
                            </TableHeaderColumn>
                        )}
                        <TableHeaderColumn
                            columnClassName="td-column-center"
                            width="150"
                            dataField="date"
                            dataSort
                            caretRender={this.getCaret}
                            dataFormat={this.showDate}
                        >
                            Date
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            columnClassName="td-column-center text-center"
                            width="100"
                            dataField="meterEntry"
                            className="text-center whitespace-normal"
                            dataFormat={this.showOdometer}
                        >
                            Odometer
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            columnClassName="td-column-center text-center"
                            width="130"
                            dataField="runningHours"
                            className="text-center whitespace-normal"
                        >
                            Running Hours
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnClassName="td-column-left"
                            width="150"
                            dataField="workOrderId"
                            className="text-left whitespace-normal "
                            dataFormat={this.showWorkOrder}
                        >
                            Work Order
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnClassName="td-column-center"
                            width="190"
                            dataField="serviceLineItems"
                            className="text-center whitespace-normal"
                            dataFormat={this.showLineItems}
                        >
                            Line Items
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            columnClassName="td-column-center text-center"
                            width="100"
                            dataField="partsSubtotal"
                            className="text-center whitespace-normal"
                            dataFormat={this.showPartsCost}
                        >
                            Parts Cost
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            columnClassName="td-column-center text-center"
                            width="90"
                            dataField="laborSubtotal"
                            className="text-center whitespace-normal"
                            dataFormat={this.showLaborCost}
                        >
                            Labor Cost
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            columnClassName="td-column-center text-center"
                            width="90"
                            dataField="discount"
                            className="text-center whitespace-normal"
                            dataFormat={this.showDiscount}
                        >
                            Discount
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            columnClassName="td-column-center text-center"
                            width="90"
                            dataField="taxTotal"
                            className="text-center whitespace-normal"
                            dataFormat={this.showTax}
                        >
                            Tax
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            columnClassName="td-column-center text-center"
                            width="100"
                            dataField="totalAmount"
                            className="text-center whitespace-normal"
                            dataFormat={this.showTotalAmount}
                        >
                            Total Amount
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            columnClassName="td-column-center text-center"
                            width="180"
                            dataField="notes"
                            className="text-center whitespace-normal"
                            dataFormat={this.showMoreInfo}
                        >
                            More Info
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            hidden={!this.props.updateServiceEntries}
                            columnClassName="td-column-center"
                            width="200"
                            dataFormat={this.showMoreButtons}
                            isKey
                            dataField="id"
                        />
                    </BootstrapTable>
                </div>
                {this.state.isGenerateReportForTemplateModalOpen && (
                    <GenerateReportForSelectedTemplateModal
                        isOpen={this.state.isGenerateReportForTemplateModalOpen}
                        onClose={this.onGenerateReportForTemplateModalToggle}
                        formatType={REPORT_TEMPLATE_TYPES.SERVICE_HISTORY}
                        itemId={this.state.selectedServiceEntry}
                        onSuccess={this.onGenerateReportForTemplateModalToggle}
                    />
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serviceEntryList: state.serviceEntries.serviceEntryList,
        vehicleListMiniAsMap: state.vehicles.vehicleListMiniAsMap,
        loggedInUser: state.settings.loggedInUser,
    };
}

export default withRoute(connect(mapStateToProps)(RemoteServiceEntryPaging));
