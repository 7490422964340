import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import FxAsyncSelect from 'sharedV2/FxAsyncSelect';
import { get } from 'lodash';
import { Form } from 'antd';
const FxAsyncSelectFormik = (passedProps) => {
    const { t } = useTranslation();
    const { field, onChange, label, isRequired, form, ...props } = passedProps;
    const { touched, errors } = form;
    const value = field.value;
    const error = get(errors, field.name, '');
    const isTouched = get(touched, field.name, '');
    const onChangeHandler = (v, option) => {
        form.setFieldValue(field.name, v);
        if (onChange) {
            onChange(v, option);
        }
    };
    return (_jsx(Form.Item, { label: label, required: isRequired, validateStatus: isTouched && error ? 'error' : '', help: isTouched && error, labelCol: { span: 24 }, children: _jsx(FxAsyncSelect, { onChange: onChangeHandler, style: { width: '100%' }, allowClear: true, value: value, onBlur: () => {
                form.setFieldTouched(field.name);
            }, "data-testid": field.name, ...props }) }));
};
export default FxAsyncSelectFormik;
