import { jsx as _jsx } from "react/jsx-runtime";
export const FxValue = (props) => {
    const { style, ...rest } = props;
    return (_jsx("span", { style: {
            fontSize: '17px',
            lineHeight: '22px',
            fontWeight: 600,
            ...style,
        }, ...rest, children: props.children }));
};
export const FxLabel = (props) => {
    const { style, ...rest } = props;
    return (_jsx("span", { style: {
            fontSize: 13,
            ...style,
        }, ...rest, children: props.children }));
};
