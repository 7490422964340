import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { FxSelectField, FxTextFieldStackedLabel } from '../../../shared';
import { fetchLedgers } from 'actions/dashboard/consignments/ledgersActions';
import { Col, Row } from 'reactstrap';
import { getFilteredAndMappedLedgerBasedOnBaseGroup } from 'utils/ERPProfileUtils';
const LedgerDetailsComponent = (props) => {
    const { accesstoken, ledgerDetailsFields, ledgers, ledgersFromParent, isNewRow = true } = props;
    const finalLedgers = ledgersFromParent ? ledgersFromParent : ledgers;
    const { t } = useTranslation();
    useEffect(() => {
        if (!ledgersFromParent)
            props.fetchLedgers(accesstoken);
    }, []);
    const Wrapper = useCallback(({ children }) => {
        if (isNewRow) {
            return _jsx(Row, { children: children });
        }
        return _jsx(_Fragment, { children: children });
    }, [isNewRow]);
    return (_jsx(Wrapper, { children: map(ledgerDetailsFields, (field) => {
            const name = get(field, 'name', '');
            const onChange = get(field, 'onChange', (ledgers) => { });
            const disabled = get(field, 'disabled', false);
            const label = get(field, 'label', '');
            const optionsFilter = get(field, 'optionsFilter', getFilteredAndMappedLedgerBasedOnBaseGroup);
            const options = useMemo(() => {
                return optionsFilter(finalLedgers) || [];
            }, [finalLedgers]);
            const shouldShowField = get(field, 'shouldShowField', true);
            const shouldShowAmountField = get(field, 'shouldShowAmountField', false);
            const amountFieldLabel = get(field, 'amountFieldInfo.label', '');
            const amountFieldName = get(field, 'amountFieldInfo.name', '');
            const isAmountFieldRequired = get(field, 'amountFieldInfo.isAmountFieldRequired', false);
            const hasSibling = get(field, 'hasSibling', false);
            const hasSiblingBefore = get(field, 'hasSiblingBefore', false);
            const siblingComponent = get(field, 'sibling', () => _jsx(_Fragment, {}));
            const siblingBeforeComponent = get(field, 'siblingBefore', () => _jsx(_Fragment, {}));
            const isRequired = get(field, 'isRequired', false);
            const colSize = get(field, 'colSize', 3);
            const colSizeAmount = get(field, 'amountFieldInfo.colSize', 3);
            const amountFieldType = get(field, 'amountFieldInfo.type', 'text');
            const amountFieldOnBlur = get(field, 'amountFieldInfo.onBlur', () => { });
            return (_jsxs(_Fragment, { children: [hasSiblingBefore && siblingBeforeComponent(), shouldShowField && (_jsx(Col, { md: colSize, xs: 12, children: _jsx(Field, { name: name, component: FxSelectField, options: options, label: label, onChange: (val) => onChange(finalLedgers, val), disabled: disabled, isRequired: isRequired }) })), shouldShowAmountField && (_jsx(Col, { md: colSizeAmount, xs: 12, children: _jsx(Field, { type: amountFieldType, name: amountFieldName, component: FxTextFieldStackedLabel, label: amountFieldLabel, isRequired: isAmountFieldRequired, onBlur: (val) => amountFieldOnBlur(val) }) })), hasSibling && siblingComponent()] }));
        }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchLedgers }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        ledgers: state.consignments.ledgers,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LedgerDetailsComponent);
