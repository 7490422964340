import { jsx as _jsx } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import FxModal from 'sharedV2/FxModal';
import FxSelect from 'sharedV2/FxSelect';
const DeletedStationariesModal = (props) => {
    const { accesstoken, onCancel, onOk, deletedStationaries, isOpen, onSelectedDeletedStationary, selectedDeletedStationary, } = props;
    const { t } = useTranslation();
    return (_jsx(FxModal, { open: isOpen, title: 'Select Stationary', onCancel: onCancel, onOk: onOk, children: _jsx(FxSelect, { options: map(deletedStationaries, (stationary) => {
                return {
                    label: stationary,
                    value: stationary,
                };
            }), value: selectedDeletedStationary, onChange: (selectedStationary) => onSelectedDeletedStationary(selectedStationary) }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DeletedStationariesModal);
