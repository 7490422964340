import { getEllipsisedText } from './string_utils';
import { get, includes, map, split } from 'lodash';

export const ISSUES_STATUS = {
    OPEN: 'OPEN',
    RESOLVED: 'RESOLVED',
    PENDING: 'PENDING',
    CLOSED: 'CLOSED',
    FOLLOW_UP: 'FOLLOW_UP',
    VEHICLE_AVAILABLE: 'VEHICLE_AVAILABLE',
    TECHNICIAN_ASSIGNED: 'TECHNICIAN_ASSIGNED',
};

export const ISSUES_STATUS_LABEL = {
    OPEN: 'Open',
    RESOLVED: 'Resolved',
    PENDING: 'Pending',
    CLOSED: 'Closed',
    FOLLOW_UP: 'Follow Up',
    VEHICLE_AVAILABLE: 'Vehicle Available',
    TECHNICIAN_ASSIGNED: 'Technician Assigned',
};

export const ISSUES_PRIORITY = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    URGENT: 'URGENT',
    CRITICAL: 'CRITICAL',
};
export const ISSUES_PRIORITY_LABEL = {
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
    URGENT: 'Urgent',
    CRITICAL: 'Critical',
};

export const ISSUES_CATEGORY = {
    GENERAL: 'GENERAL',
    INTERNAL_ORGANISATION: 'INTERNAL_ORGANISATION',
};
export const ISSUES_CATEGORY_LABEL = {
    GENERAL: 'General',
    INTERNAL_ORGANISATION: 'Internal Organisation',
};

export const ISSUES_TYPES = {
    ALARM: 'ALARM',
    FUEL: 'FUEL',
    DRIVING: 'DRIVING',
    ODOMETER: 'ODOMETER',
    SERVICE: 'SERVICE',
    UTILISATION: 'UTILISATION',
    ANOMALY: 'ANOMALY',
    GENERAL: 'GENERAL',
    RISK_CHECKLIST: 'Risk Checklist',
    HANDOVER: 'HANDOVER',
    ACCIDENT: 'ACCIDENT',
};
export const ISSUES_TYPES_LABEL = {
    ALARM: 'Alarm',
    FUEL: 'Fuel',
    DRIVING: 'Driving',
    ODOMETER: 'Odometer',
    SERVICE: 'Service',
    UTILISATION: 'Utilisation',
    ANOMALY: 'Anomaly',
    GENERAL: 'General',
    'Risk Checklist': 'Risk Checklist',
    HANDOVER: 'Handover',
    ACCIDENT: 'Accident',
};

export const ISSUES_ENTITY_TYPES = {
    VENDOR: 'VENDOR',
    VEHICLE: 'VEHICLE',
    WORK_ORDER: 'WORK_ORDER',
    PART: 'PART',
    PUCHAORDER: 'PUCHAORDER',
    ACCOUNT: 'ACCOUNT',
    TYRE: 'TYRE',
    JOB: 'JOB',
};
export const ISSUES_ENTITY_TYPES_LABEL = {
    VENDOR: 'Vendor',
    VEHICLE: 'Vehicle',
    WORK_ORDER: 'WorkOrder',
    PART: 'Part',
    PUCHAORDER: 'PurchaseOrder',
    ACCOUNT: 'Account',
    TYRE: 'Tyre',
    JOB: 'Job',
};

export function getIssuesSubType() {
    return {
        ALARM: {
            'Speeding': window.t('speeding'),
            'Job Deviation': window.t('jobDeviation'),
            'Fatigue Driving': window.t('fatigueDriving'),
            'Route Deviation': window.t('routeDeviation'),
            'Misuse': window.t('misuseAlert'),
            'Fuel Level': window.t('fuelLevel'),
            'Free Wheeling': window.t('freeWheeling'),
            'Maximum Driving': window.t('maximumDriving'),
            'Maximum Stoppage': window.t('maximumStoppage'),
            'Hard Acceleration': window.t('hardAcceleration'),
            'Hard Deceleration': window.t('hardDeceleration'),
            'Disconnect Alarm': window.t('disconnectAlarm'),
            'Parked': window.t('parkedOnJobAlarm'),
            'Zigzag Alarm': window.t('zigzagAlarm'),
            'Idle Engine': window.t('idleEngine'),
            'Emergency': window.t('emergency'),
            'Power Off': window.t('powerOff'),
        },
    };
}

export function getIssueStatusClass(status) {
    switch (status) {
        case ISSUES_STATUS.OPEN:
            return 'text-danger';
        case ISSUES_STATUS.RESOLVED:
            return 'text-muted';
        case ISSUES_STATUS.CLOSED:
            return 'text-gray';
        default:
            return '';
    }
}

export function getIssuePriorityClass(priority) {
    switch (priority) {
        case ISSUES_PRIORITY.LOW:
            return 'text-gray';
        case ISSUES_PRIORITY.MEDIUM:
            return 'text-primary';
        case ISSUES_PRIORITY.HIGH:
            return 'text-warning';
        case ISSUES_PRIORITY.URGENT:
        case ISSUES_PRIORITY.CRITICAL:
            return 'text-danger';
    }
}

export function getAssignmentType(reassignedTicket) {
    if (!reassignedTicket) {
        return <span style={{ color: 'green' }}>New</span>;
    } else {
        return <span style={{ color: 'red' }}>Reassigned</span>;
    }
}

export function isSystemGeneratedIssue(type) {
    return !type || type === 'Emergency';
}

export function issueOptionRender(option, { context }) {
    const { label, description } = option;
    if (context === 'value') {
        return label;
    }
    return (
        <div>
            {label}
            <div className="text-gray medium">{getEllipsisedText(description, 50)}</div>
        </div>
    );
}

export function isIssueAssignedToCurrentUser(assignedToId) {
    return get(window.FLEETX_LOGGED_IN_USER, 'id') === assignedToId;
}

export function extractLatLonFrom(issue) {
    const { type, description } = issue;
    let lat, lng;
    switch (type) {
        case 'unknownStoppage':
            const splitResultArray = split(description, '~');
            lat = splitResultArray[splitResultArray.length - 2];
            lng = splitResultArray[splitResultArray.length - 1];
            break;
    }

    return {
        lat,
        lng,
    };
}

export function mappedJobsForIssuesEntity(jobs, key = 'id', label = 'name') {
    return map(jobs, (o) => {
        return {
            value: get(o, key, ''),
            label: `${get(o, label, '')}`,
        };
    });
}

export function getIssueEntityLink(issueEntityType, issueEntityId) {
    if (!issueEntityType || !issueEntityId) {
        return '';
    }

    switch (issueEntityType) {
        case ISSUES_ENTITY_TYPES.JOB:
            return `https://app.fleetx.io/dashboard/routes/jobs/${issueEntityId}`;
        case ISSUES_ENTITY_TYPES.WORK_ORDER:
            return `https://app.fleetx.io/dashboard/workorders/view/${issueEntityId}`;
        default:
            return '';
    }
}

export function getIssuesPageRefreshTimeInSeconds() {
    return 30;
}

export function isIssuesAutoRefreshEnabled() {
    return includes([4895, 5248, 5380], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export const STATUS_TYPES = [
    {
        name: 'Pending',
        value: 'PENDING',
    },
    {
        name: 'Follow Up',
        value: 'FOLLOW_UP',
    },
    {
        name: 'Vehicle Available',
        value: 'VEHICLE_AVAILABLE',
    },
    {
        name: 'Technician Assigned',
        value: 'TECHNICIAN_ASSIGNED',
    },
    {
        name: 'Closed',
        value: 'CLOSED',
    },
    {
        name: 'Resolved',
        value: 'RESOLVED',
    },
];

export const REASON_FOLLOW_UP = [
    {
        name: 'Technician bandthwidth issue',
        value: 'Technician bandthwidth issue',
    },
    {
        name: 'Driver/POC number not reachable',
        value: 'Driver/POC number not reachable',
    },
    {
        name: 'Driver/POC not confirming availability',
        value: 'Driver/POC not confirming availability',
    },
    {
        name: 'Vehicle not available',
        value: 'Vehicle not available',
    },
    {
        name: 'Inventory shortage',
        value: 'Inventory shortage',
    },
    {
        name: 'Driver/POC not respoding',
        value: 'Driver/POC not respoding',
    },
    {
        name: 'Incorrect Driver/POC details',
        value: 'Incorrect Driver/POC details',
    },
    {
        name: 'Vehicle in Maintainance',
        value: 'Vehicle in Maintainance',
    },
    {
        name: 'Vehicle at Remote location',
        value: 'Vehicle at Remote location',
    },
    {
        name: 'Others',
        value: 'Others',
    },
];
export const CLOSED_REASON = [
    {
        name: 'Vehicle in Maintenance',
        value: 'Vehicle in Maintenance',
    },
    {
        name: 'Driver/POC not respoding',
        value: 'Driver/POC not respoding',
    },
    {
        name: 'False Alarm',
        value: 'False Alarm',
    },
    {
        name: 'Incorrect Driver/POC details',
        value: 'Incorrect Driver/POC details',
    },
    {
        name: 'Driver/POC not confirming availability',
        value: 'Driver/POC not confirming availability',
    },
    {
        name: 'Location not servicable',
        value: 'Location not servicable',
    },
    {
        name: 'Duplicate Ticket',
        value: 'Duplicate Ticket',
    },
    {
        name: 'Others',
        value: 'Others',
    },
];
export const RESOLVED_REASON = [
    {
        name: 'Visit Done - Wire Cut Amount to be recovered',
        value: 'Visit Done - Wire Cut Amount to be recovered',
    },
    {
        name: 'Visit Done - No Further Action',
        value: 'Visit Done - No Further Action',
    },
    {
        name: 'Via Troubleshooting',
        value: 'Via Troubleshooting',
    },
    {
        name: 'Auto Live',
        value: 'Auto Live',
    },
    {
        name: 'Others',
        value: 'Others',
    },
];
export const OPEN_REASON = [
    {
        name: 'Issue not Resolved',
        value: 'Issue not Resolved',
    },
    {
        name: 'Installation not complete',
        value: 'Installation not complete',
    },
    {
        name: 'Others',
        value: 'Others',
    },
];

export const AVAILABILITY_SLOT = [
    {
        label: '9 AM - 12 PM',
        value: '09-12',
    },
    {
        label: '12 PM - 3 PM',
        value: '12-15',
    },
    {
        label: '3 PM - 6 PM',
        value: '15-18',
    },
    {
        label: '6 PM - 9 PM',
        value: '18-21',
    },
];
