import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { concat, find, get, isEmpty, map, set } from 'lodash';
import RoutePointItemComponent from './RoutePointItemComponent';
import { FieldArray } from 'formik';
import { combineNameAddress } from '../../constant';
import { createIdValueFromAddress, mappedAddressBookWithIdMapping } from '../../utils/form_utils';
import { fetchCustomers } from '../../actions/dashboard/finances/customer_action';
import { fetchAddressBook } from '../../actions/dashboard/route_dispatch/address_book_action';
import { fetchCommodities } from '../../actions/dashboard/commodity_actions';
import SelectLocationFromGoogleMapModal from '../../components/dashboard/route_dispatch/jobs/select_location_from_google_map_modal';
import { fetchMaterials } from 'actions/dashboard/rateCharts/materialsActions';
import { isViewMaterialsAllowed } from 'components/dashboard/roles/permission_utils';
const RoutePointsDetailsComponent = (props) => {
    const { accesstoken, name = 'routePoints', formikProps, commodityList, materialList, customers, addressBook, isEdit, isExistingRouteSelected, dataFetchFlags, groupId, commodityName, cardNeeded, moreAddressBookOptions, } = props;
    const { setFieldValue, values, errors } = formikProps;
    const routePoints = get(values, name, []);
    const [googleMapLocationModal, setGoogleMapLocationModal] = useState(false);
    const [googleMapLocationIndex, setGoogleMapLocationIndex] = useState(0);
    const [addressBookExtraOption, setAddressBookExtraOption] = useState([]);
    const globalError = typeof get(errors, name) === 'string' ? get(errors, name) : '';
    const finalAddressBook = useMemo(() => {
        return concat(addressBook, addressBookExtraOption, moreAddressBookOptions);
    }, [addressBook, addressBookExtraOption, moreAddressBookOptions]);
    const addressBookOptions = useMemo(() => {
        return mappedAddressBookWithIdMapping(finalAddressBook);
    }, [finalAddressBook]);
    const { dontFetchCustomers, dontFetchAddress, dontFetchCommodity } = dataFetchFlags || {};
    const selectLocationFromGoogleMap = (index) => {
        setGoogleMapLocationIndex(index);
        setGoogleMapLocationModal((v) => !v);
    };
    const onGoogleMapLocationClosed = () => {
        setGoogleMapLocationIndex(0);
        setGoogleMapLocationModal(false);
    };
    const onRoutePointSelect = (point, index) => {
        if (point) {
            const newRoutePoints = {
                address: combineNameAddress(point.name, point.address),
                latitude: point.latitude,
                longitude: point.longitude,
                addressBookId: point.id,
                name: point.name,
                selectId: createIdValueFromAddress(point).value,
            };
            formikProps.setFieldValue(`${name}.[${index}].routePoint`, newRoutePoints);
        }
        else {
            formikProps.setFieldValue(`${name}.[${index}].routePoint`, {});
        }
    };
    const onGoogleMapLocationSelected = (location, index, setFieldValue) => {
        if (!location) {
            return;
        }
        set(location, 'address', location.formatted_address || get(location, 'address'));
        onGoogleMapLocationClosed();
        if (index >= 0) {
            const found = find(addressBook, {
                name: location.name,
                latitude: location.latitude,
                longitude: location.longitude,
            });
            if (!found) {
                set(location, 'name', location.formatted_address || get(location, 'name'));
                setAddressBookExtraOption((prevV) => [
                    ...prevV,
                    {
                        name: location.name,
                        latitude: location.latitude,
                        longitude: location.longitude,
                    },
                ]);
                setAddressRelatedFields(location, index, setFieldValue);
            }
            else {
                setAddressRelatedFields(found, index, setFieldValue);
            }
        }
    };
    const setAddressRelatedFields = (location, index, setFieldValue) => {
        const newRoutePoints = {
            latitude: location.latitude,
            longitude: location.longitude,
            name: location.name,
            selectId: createIdValueFromAddress({ ...location }).value,
        };
        if (location.address) {
            newRoutePoints.address = location.address;
        }
        if (location.addressBookId) {
            newRoutePoints.addressBookId = location.addressBookId;
        }
        setFieldValue(`${name}.[${index}].routePoint`, newRoutePoints);
    };
    const itemsUploaded = !isEmpty(routePoints);
    useEffect(() => {
        if (!dontFetchCustomers) {
            props.fetchCustomers(accesstoken);
        }
        if (!dontFetchCommodity) {
            props.fetchCommodities(accesstoken);
        }
        if (!dontFetchAddress) {
            props.fetchAddressBook(accesstoken, groupId);
        }
        if (isViewMaterialsAllowed(props.login.scope)) {
            props.fetchMaterials(accesstoken);
        }
    }, [dontFetchCustomers, dontFetchAddress, dontFetchCommodity]);
    return (_jsxs("div", { className: `mt-2 ${cardNeeded ? 'card' : ''}`, children: [_jsx("div", { className: `${cardNeeded ? 'card-body' : ''}`, children: _jsx(FieldArray, { name: name, children: ({ push, remove }) => {
                        return (_jsxs(_Fragment, { children: [itemsUploaded &&
                                    map(routePoints, (routePoint, index) => {
                                        return (_jsx(RoutePointItemComponent, { routePoint: routePoint, index: index, baseKey: `${name}.[${index}]`, commodityList: commodityList, materialList: materialList, customers: customers, addressBook: finalAddressBook, addressBookOptions: addressBookOptions, isEdit: isEdit, isExistingRouteSelected: isExistingRouteSelected, selectLocationFromGoogleMap: selectLocationFromGoogleMap, remove: remove, onRoutePointSelect: onRoutePointSelect, commodityName: commodityName }, index));
                                    }), !isExistingRouteSelected && (_jsx("div", { className: "d-flex justify-content-center", children: _jsxs("button", { type: "button", className: "btn", style: {
                                            borderRadius: '4px',
                                            'border': 'solid 1px #20a8d8',
                                            'color': '#20a8d8',
                                            height: '2.5rem',
                                        }, onClick: () => push({}), children: ["Add ", itemsUploaded ? 'More' : '', " Route point"] }) })), !!globalError && (_jsx("div", { className: 'd-flex justify-content-center text-danger', children: globalError }))] }));
                    } }) }), _jsx(SelectLocationFromGoogleMapModal, { index: googleMapLocationIndex, isOpen: googleMapLocationModal, onClosed: onGoogleMapLocationClosed, setFieldValue: formikProps.setFieldValue, onLocationSelected: onGoogleMapLocationSelected })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchCustomers, fetchAddressBook, fetchCommodities, fetchMaterials }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        addressBook: state.addressBook.addressBook,
        customers: get(state, 'customers.customers', []),
        commodityList: state.commodity.commodityList,
        materialList: state.material.materialList,
        groupId: state.local.defaultGroupId,
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RoutePointsDetailsComponent);
