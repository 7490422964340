export const CHANNELS_SALES_TABS = {
    GM: { name: 'GM', key: 'GM', title: 'CHANNEL_SALES_GENERAL_MANAGER' },
    CSM: { name: 'CSM', key: 'CSM', title: 'CHANNEL_SALES_MANAGER' },
    CSO: { name: 'CSO', key: 'CSO', title: 'CHANNEL_SALES_OFFICER' },
    CSP: { name: 'CSP', key: 'CSP', title: 'CHANNEL_SALES_PARTNER' },
};

export function isVisibleToUser(role, type, defaultTab) {
    let temp = [];
    if (role === 'CHANNEL_SALES_ADMIN' || role === 'ROLE_OWNER' || 'ROLE_ADMIN') {
        temp = ['GM', 'CSM', 'CSO', 'CSP'];
    }
    if (role === 'CHANNEL_SALES_GENERAL_MANAGER') {
        temp = ['CSM', 'CSO', 'CSP'];
    }
    if (role === 'CHANNEL_SALES_MANAGER') {
        temp = ['CSO', 'CSP'];
    }
    if (role === 'CHANNEL_SALES_OFFICER') {
        temp = ['CSP'];
    }
    if (role === 'CHANNEL_SALES_PARTNER') {
        temp = ['CSP'];
    }
    if (defaultTab) {
        return temp[0];
    }
    return temp.find((v) => v === type);
}
