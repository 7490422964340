import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Analytics = (props) => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20px", height: "20px", viewBox: "0 0 20 20", children: _jsx("path", { d: "M0.333984 17.6654V5.7487H4.12565V17.6654H0.333984ZM7.10482 17.6654V0.332031H10.8965V17.6654H7.10482ZM13.8757 17.6654V10.082H17.6673V17.6654H13.8757Z", fill: "#E86452" }) }));
};
const AnalyticsIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Analytics, ...props });
};
export default AnalyticsIcon;
