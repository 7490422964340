import { handle } from 'redux-pack';
import { forEach, get, map } from 'lodash';
import {
    FETCH_A_TO_B_TRIPS,
    FETCH_ASSET_TRACKER_POINTS,
    FETCH_LATEST_TRIP_BY_VEHICLE,
    FETCH_ROUTE_POINTS_SPEED,
    FETCH_TRIPS,
    FETCH_TRIPS_GRAPH,
    FETCH_TRIPS_MERGED,
    FETCH_TRIPS_MERGED_V2,
    FETCH_TRIPS_REPORT,
    FETCH_TRIPS_WITHOUT_AGGREGATIONS,
} from '../../actions/dashboard/trips_action';
import { fixRoutes } from '../../utils/vehicle_utils';
import { filterAssetMovementPoints } from '../../constant';

const initialState = {
    isLoading: false,
    error: null,
    aggregations: [],
    isFinished: false,
    graphData: [],
    isOnlyTripsLoading: false,
    isTripsAggregationsLoading: false,
    isTripsGraphLoading: false,
    isMergedTripsLoading: false,
    isSpeedingViolationsLoading: false,
    speedingViolationsList: [],
    isAssetMovementLoading: false,
    assetMovementDetails: [],
    aToBTripsData: [],
    isAToBTripsDataLoading: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_TRIPS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isTripsAggregationsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isTripsAggregationsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    aggregations: get(payload, 'data.aggregations'),
                }),
            });
        case FETCH_A_TO_B_TRIPS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAToBTripsDataLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isAToBTripsDataLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const parsedData = [];
                    forEach(payload.data, (vehicleData) => {
                        forEach(vehicleData.trips, (trip, index) => {
                            parsedData.push({
                                id: `${vehicleData.vehicleId}~${index}`,
                                trip: trip,
                                vehicleId: vehicleData.vehicleId,
                                licensePlate: vehicleData.licensePlate,
                            });
                        });
                    });

                    return {
                        ...prevState,
                        aToBTripsData: parsedData,
                    };
                },
            });
        case FETCH_TRIPS_WITHOUT_AGGREGATIONS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isOnlyTripsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isOnlyTripsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                }),
            });
        case FETCH_TRIPS_GRAPH:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isTripsGraphLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isTripsGraphLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    graphData: payload.data,
                }),
            });
        case FETCH_TRIPS_REPORT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_LATEST_TRIP_BY_VEHICLE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_TRIPS_MERGED:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isMergedTripsLoading: true,
                    mergedTrips: [],
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const mergedTrips = [];
                    map(payload.data, (mergedTripObject) => {
                        if (get(mergedTripObject, 'trip.score', 0) === -2) {
                            mergedTrips.push({
                                trip: mergedTripObject.trip,
                                routes: fixRoutes(get(mergedTripObject, 'routes', []), null, true),
                            });
                        } else {
                            mergedTrips.push(mergedTripObject);
                        }
                    });
                    return {
                        ...prevState,
                        mergedTrips: mergedTrips,
                        isMergedTripsLoading: false,
                    };
                },
            });
        case FETCH_TRIPS_MERGED_V2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isMergedTripsLoading: true,
                    mergedTrips: [],
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const mergedTrips = [];
                    const tripDetailDTOList = get(payload, 'data.tripDetailDTOList', []);
                    map(tripDetailDTOList, (mergedTripObject) => {
                        if (get(mergedTripObject, 'trip.score', 0) === -2) {
                            mergedTrips.push({
                                trip: mergedTripObject.trip,
                                routes: fixRoutes(get(mergedTripObject, 'routes', []), null, true),
                            });
                        } else {
                            mergedTrips.push(mergedTripObject);
                        }
                    });
                    return {
                        ...prevState,
                        mergedTrips: mergedTrips,
                        isMergedTripsLoading: false,
                    };
                },
            });
        case FETCH_ROUTE_POINTS_SPEED:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isSpeedingViolationsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isSpeedingViolationsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    speedingViolationsList: payload.data,
                }),
            });
        case FETCH_ASSET_TRACKER_POINTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAssetMovementLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isAssetMovementLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const assetMovementDetails = filterAssetMovementPoints(payload.data);
                    return {
                        ...prevState,
                        assetMovementDetails: assetMovementDetails,
                    };
                },
            });
        default:
            return state;
    }
}
