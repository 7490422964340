import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import LandingHeader from '../landing/landing_header_container';

class UnderMaintenanceComponent extends Component {
    mounted = false;

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    render() {
        return (
            <div>
                <LandingHeader />
                <div className="text-center mt-4">
                    <h3>Maintenance in Progress!</h3>
                    <div>
                        <p>
                            Sorry for the inconvenience but we're performing some unscheduled maintenance at the moment.
                            We'll be back at 9.15 PM IST!
                        </p>
                        <p>&mdash; Team Fleetx</p>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.mounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {}

    componentWillUnmount() {
        this.mounted = false;
    }
}

UnderMaintenanceComponent.propTypes = {};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderMaintenanceComponent);
