import { toString, get, toSafeInteger } from 'lodash';

export async function initCSBox({
    account_id,
    user_id,
    email,
    phone,
    name,
    customer_success_manager,
    repairManager,
    enterprise,
    typeofDevice,
    fleetSize,
    fleetType,
    address,
    accountStatus,
    billingCycle,
    installedVehicles,
    firstName,
    lastName,
    roleDescription,
    title,
    user_created_on,
    account_created_on,
}) {
    if (window.analytics) {
        // console.log(
        //     'CS Box Info',
        //     account_id,
        //     user_id,
        //     email,
        //     phone,
        //     name,
        //     customer_success_manager,
        //     repairManager,
        //     enterprise,
        //     typeofDevice,
        //     fleetSize,
        //     fleetType,
        //     address,
        //     accountStatus,
        //     billingCycle,
        //     installedVehicles,
        //     firstName,
        //     lastName,
        //     roleDescription,
        //     title,
        //     user_created_on,
        //     account_created_on
        // );
        analytics.load(function () {
            analytics.login(user_id, account_id);
            analytics.account(account_id, {
                account_id: toSafeInteger(account_id),
                name: toString(name),
                phone: toString(phone),
                email: toString(email),
                created_on: account_created_on,
                customer_success_manager: toString(customer_success_manager),
                custom_repairManager: toString(repairManager),
                custom_enterprise: toString(enterprise),
                custom_typeofDevice: toString(typeofDevice),
                custom_fleetSize: toSafeInteger(fleetSize),
                custom_fleetType: toSafeInteger(fleetType),
                custom_accountStatus: toString(accountStatus),
                custom_billingCycle: toString(billingCycle),
                custom_installedVehicles: toSafeInteger(installedVehicles),
                custom_address: `${get(address, 'addressLine1', '')} 
                    ${get(address, 'addressLine2', '')} 
                    ${get(address, 'addressStr', '')} 
                    ${get(address, 'city', '')} 
                    ${get(address, 'postalCode', '')} 
                    ${get(address, 'state', '')}`,
            });
            analytics.identify(user_id, {
                account_id: toSafeInteger(account_id),
                phone: toString(phone),
                email: toString(email),
                first_name: toString(firstName),
                last_name: toString(lastName),
                title,
                role: roleDescription,
                created_on: user_created_on,
            });
            window.trackEvent = (feature, module, product) => {
                if (analytics && analytics.feature) {
                    analytics.feature(feature, module, 'Web');
                }
            };
        });
    }
}
