import axios from 'axios';
import { ROOT_API_ERP } from '../../../constant';
export const CREATE_LOAD_TYPE = 'CREATE_LOAD_TYPE';
export const UPDATE_LOAD_TYPE = 'UPDATE_LOAD_TYPE';
export const DELETE_LOAD_TYPE = 'DELETE_LOAD_TYPE';
export const FETCH_LOAD_TYPES = 'FETCH_LOAD_TYPES';
export const FETCH_LOAD_TYPE = 'FETCH_LOAD_TYPE';
export function createLoadType(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}load-type`, data, config);
    return {
        type: CREATE_LOAD_TYPE,
        promise: request,
    };
}
export function updateLoadType(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}load-type`, data, config);
    return {
        type: UPDATE_LOAD_TYPE,
        promise: request,
    };
}
export function fetchLoadTypes(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}load-type`, config);
    return {
        type: FETCH_LOAD_TYPES,
        promise: request,
    };
}
export function fetchLoadType(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}load-type/${id}`, config);
    return {
        type: FETCH_LOAD_TYPE,
        promise: request,
    };
}
export function deleteLoadType(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}load-type/${id}`, config);
    return {
        type: DELETE_LOAD_TYPE,
        promise: request,
    };
}
