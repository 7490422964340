/**
 *
 * @param url ( Supported input formats image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/tiff, image/x-icon, image/svg+xml, image/webp, image/xxx)
 * @param outputFormat (Supported output formats image/png, image/jpeg, image/webp(chrome))
 * @param options
 * @returns {Promise<any>}
 */
export function toDataURL(url, outputFormat = 'image/png', options = {}) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.setAttribute('crossOrigin', 'anonymous');
        const { width, height } = options;
        img.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = width || this.width;
            canvas.height = height || this.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);
            const dataURL = canvas.toDataURL(outputFormat);
            resolve(dataURL);
        };
        img.onerror = function (err) {
            reject(err);
        };

        img.src = url;
    });
}

export function isImageUrl(url) {
    return !!(url && url.match(/\.(jpeg|jpg|gif|png|JPG)$/));
}
