import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider, Empty } from 'antd';
const FxEmpty = (props) => {
    const { defaultImage, themeModify, ...rest } = props;
    const extraProps = { description: 'Data Not Found', image: Empty.PRESENTED_IMAGE_SIMPLE };
    if (defaultImage) {
        extraProps.image = Empty.PRESENTED_IMAGE_DEFAULT;
    }
    return (_jsx(ConfigProvider, { theme: { token: { ...themeModify } }, children: _jsx(Empty, { ...extraProps, ...rest }) }));
};
export default FxEmpty;
