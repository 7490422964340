import axios from 'axios';
import { ROOT_API_URL } from '../../../constant';

export const CREATE_NEW_FEATURE = 'create_new_feature';
export const FETCH_NEW_FEATURE_DATA = 'request_new_feature_data';
export const UPDATE_NEW_FEATURE_REVIEW = 'update_new_feature_review';
export const FETCH_ALL_FEATURES = 'FETCH_ALL_FEATURES';

export function createNewFeature(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}feature/`, values, config);
    return {
        type: CREATE_NEW_FEATURE,
        promise: request,
    };
}

export function fetchNewFeatureData(accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}feature/latest`, config);
    return {
        type: FETCH_NEW_FEATURE_DATA,
        promise: request,
    };
}

export function updateNewFeatureReview(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}feature-review/`, values, config);
    return {
        type: UPDATE_NEW_FEATURE_REVIEW,
        promise: request,
    };
}

export function fetchAllFeatures(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}feature/`, config);
    return {
        type: FETCH_ALL_FEATURES,
        promise: request,
    };
}
