import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchUserListMini } from 'actions/dashboard/users_action';
import { FxFlex, FxSelect } from 'sharedV2';
import { mappedUsersMini } from 'utils/form_utils';
const FxEmailInput = (props) => {
    const { accesstoken, userListMini, label = 'Enter comma seperated email IDs to send the Report.', placeholder = 'Enter comma seperated email IDs', onChange, } = props;
    useEffect(() => {
        if (!userListMini.length) {
            props.fetchUserListMini(accesstoken);
        }
    }, []);
    const options = useMemo(() => {
        return mappedUsersMini(userListMini, undefined, undefined, true);
    }, [userListMini]);
    return (_jsxs(FxFlex, { vertical: true, className: "mb-3", children: [_jsx("label", { htmlFor: "emailIds", children: label }), _jsx(FxSelect, { mode: "multiple", creatable: true, id: "emailIds", onChange: (e) => onChange(e), options: options, placeholder: placeholder })] }));
};
const mapStateToProps = (state) => ({
    accesstoken: get(state, 'login.data.access_token', ''),
    userListMini: get(state, 'users.userListMini', []),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchUserListMini }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FxEmailInput);
