import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditRateChartComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-procurement-rate-chart-add-edit" */ 'components/dashboard/procurement/rate-chart/add_edit_rate_charts'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ratechartsRoutes = [
    {
        path: 'add',
        lazy: AddEditRateChartComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditRateChartComponent,
    },
];
export default ratechartsRoutes;
