import axios from "axios";
import {ROOT_API_URL} from "../../../constant";

export const CREATE_DUTY = 'create_duty';
export const UPDATE_DUTY = 'update_duty';
export const DELETE_DUTY = 'delete_duty';
export const FETCH_DUTIES = 'fetch_duties';
export const FETCH_DUTY_SUMMARY = 'fetch_duty_summary';


export function createDuty(accesstoken, values) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.post(`${ROOT_API_URL}duty/`, values, config);
    return {
        type: CREATE_DUTY, promise: request,
    }
}

export function updateDuty(accesstoken, values) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.put(`${ROOT_API_URL}duty/`, values, config);

    return {
        type: UPDATE_DUTY, promise: request
    }
}

export function deleteDuty(accesstoken, dutyId) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.delete(`${ROOT_API_URL}duty/${dutyId}`, config);

    return {
        type: DELETE_DUTY, promise: request
    }
}

export function fetchDuties(accesstoken, from, to, groupId, vehicleId, customerId, dutyStatus) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const toQuery = to ? `&to=${to}` : "";
    const groupQuery = (groupId && groupId != -1) ? `&groupId=${groupId}` : "";
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : "";
    const customerIdQuery = customerId ? `&customerId=${customerId}` : "";
    const dutyStatusQuery = dutyStatus ? `&status=${dutyStatus}` : "";
    const size = 1000;

    const request = axios.get(
        `${ROOT_API_URL}duty/?from=${from}${toQuery}${vehicleQuery}${groupQuery}${customerIdQuery}${dutyStatusQuery}&size=${size}`,
        config);

    return {
        type: FETCH_DUTIES, promise: request,
    }
}


export function fetchDutySummary(accesstoken, dutyId) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.get(`${ROOT_API_URL}duty/${dutyId}`,
        config);

    return {
        type: FETCH_DUTY_SUMMARY, promise: request,
    }
}