import { find, forEach, get, round, toNumber } from 'lodash';
import { getQueryStringParams } from 'constant';

export const TRANSACTION_ORDERS = {
    PART_STORE: 'PART_STORE',
    WORKORDER: 'WORKORDER',
    SCRAP: 'SCRAP',
    PART_ENTRY: 'PART_ENTRY',
};

export const WORK_ORDER_PRIORITY = {
    HIGH: 'HIGH',
    MEDIUM: 'MEDIUM',
    LOW: 'LOW',
};

export const WORK_ORDER_STATUS = {
    OPEN: 'OPEN',
    IN_PROGRESS: 'IN_PROGRESS',
    CANCELLED: 'CANCELLED',
    RESOLVED: 'RESOLVED',
};

export const WORK_ORDER_EDITABLE_STATUS = [WORK_ORDER_STATUS.OPEN, WORK_ORDER_STATUS.IN_PROGRESS];

export const WORK_ORDER_EDITABLE_STATUS_WITH_RESOLVED = [
    WORK_ORDER_STATUS.OPEN,
    WORK_ORDER_STATUS.IN_PROGRESS,
    WORK_ORDER_STATUS.RESOLVED,
];

export const WORK_ORDER_CATEGORY_LABEL = {
    BREAKDOWN: 'Breakdown',
    DAMAGE: 'Damage',
    INSPECTION: 'Inspection',
    UPGRADE: 'Upgrade',
    ON_ROAD_BREAKDOWN: 'On Road Breakdown',
    GARRAGE_WORK: 'Garage Work',
    ACCIDENTAL_VEHICLE: 'Accidental Vehicle',
    ON_ROUTE_MAINTENANCE: 'On Route Maintenance',
    OTHERS: 'Others',
};

export const WORK_ORDER_CATEGORY = {
    BREAKDOWN: 'BREAKDOWN',
    DAMAGE: 'DAMAGE',
    INSPECTION: 'INSPECTION',
    UPGRADE: 'UPGRADE',
    ON_ROAD_BREAKDOWN: 'ON_ROAD_BREAKDOWN',
    GARRAGE_WORK: 'GARRAGE_WORK',
    ACCIDENTAL_VEHICLE: 'ACCIDENTAL_VEHICLE',
    ON_ROUTE_MAINTENANCE: 'ON_ROUTE_MAINTENANCE',
    OTHERS: 'OTHERS',
};

export const SUMMARY_FILTER_CATEGORIES = {
    STATUS: 'workOrderStatus',
    CATEGORY: 'workOrderCategory',
    priority: 'workOrderPriority',
    PART: 'partId',
    DRIVER: 'driverId',
    VEHICLEDOMAIN: 'vehicleDomain',
};

export const PART_AUDIT_HISTORY_TYPES = {
    PART_ENTRY: 'PART_ENTRY',
    ISSUED: 'ISSUED',
    STORE_ENTRY: 'STORE_ENTRY',
};

export function getWorkOrderStatusClass(status) {
    switch (status) {
        case WORK_ORDER_STATUS.OPEN:
            return 'text-danger';
        case WORK_ORDER_STATUS.RESOLVED:
            return 'text-muted';
        case WORK_ORDER_STATUS.CANCELLED:
            return 'text-gray';
        case WORK_ORDER_STATUS.IN_PROGRESS:
            return 'text-success';
        default:
            return '';
    }
}

export function getWorkOrderPriorityClass(priority) {
    switch (priority) {
        case WORK_ORDER_PRIORITY.LOW:
            return 'text-gray';
        case WORK_ORDER_PRIORITY.MEDIUM:
            return 'text-warning';
        case WORK_ORDER_PRIORITY.HIGH:
            return 'text-danger';
        default:
            return '';
    }
}

export function getWorkOrderCategoryClass(priority) {
    switch (priority) {
        case WORK_ORDER_CATEGORY.BREAKDOWN:
        case WORK_ORDER_CATEGORY.DAMAGE:
            return 'text-danger';
        case WORK_ORDER_CATEGORY.UPGRADE:
            return 'text-success';
        case WORK_ORDER_CATEGORY.INSPECTION:
            return 'text-warning';
        default:
            return 'text-gray';
    }
}

export function getPartDisplayQuantity(quantity) {
    return round(quantity, 2);
}

export function getWorkOrderSubtotals(workOrder) {
    let { workOrderLineItems, discount, discountPercentage, partsSubtotal, discountSelect } = workOrder;
    let laborSubtotal = 0;
    let extraPartsSubtotal = 0;
    forEach(workOrderLineItems, (lineItem) => {
        const { laborCost, partsCost } = lineItem;
        laborSubtotal += toNumber(laborCost);
        extraPartsSubtotal += toNumber(partsCost);
    });

    const subtotal = laborSubtotal + extraPartsSubtotal + partsSubtotal;
    let discountAmount = toNumber(discount);

    if (discountPercentage && (!discountSelect || discountSelect === '%')) {
        discountAmount = (subtotal * discountPercentage) / 100;
        discount = 0;
    } else {
        discountPercentage = 0;
    }

    const total = subtotal - discountAmount;

    return {
        laborSubtotal: laborSubtotal,
        partsSubtotal: partsSubtotal,
        extraPartsSubtotal: extraPartsSubtotal,
        subtotal: subtotal,
        totalAmount: total,
        discount,
        discountPercentage,
    };
}

export function getPartAuditHistoryOnDate(row) {
    const { auditType, purchaseDate, startedAt, createdDate } = row;
    let date = null;
    switch (auditType) {
        case PART_AUDIT_HISTORY_TYPES.STORE_ENTRY:
            date = createdDate;
            break;
        case PART_AUDIT_HISTORY_TYPES.PART_ENTRY:
            date = purchaseDate;
            break;
        case PART_AUDIT_HISTORY_TYPES.ISSUED:
            date = startedAt;
            break;
    }
    return date;
}

export function getPartAuditHistoryNumber(row) {
    const { auditType, number: wordOrderNumber, billNumber } = row;
    switch (auditType) {
        case PART_AUDIT_HISTORY_TYPES.ISSUED:
            return wordOrderNumber;
        case PART_AUDIT_HISTORY_TYPES.PART_ENTRY:
            return billNumber;
    }
    return null;
}

export function getPartAuditHistoryQuantity(row, partId) {
    const { auditType, quantity, floatingQuantity, workOrderParts } = row;
    switch (auditType) {
        case PART_AUDIT_HISTORY_TYPES.STORE_ENTRY:
            return quantity;
        case PART_AUDIT_HISTORY_TYPES.PART_ENTRY:
            return floatingQuantity;
        case PART_AUDIT_HISTORY_TYPES.ISSUED:
            const part = find(workOrderParts, { partId });
            return get(part, 'usedQuantity', null);
    }
    return null;
}

export const PART_STOCK_CATEGORY = [
    {
        label: 'Part Inventory',
        value: 'partInventory',
    },
    {
        label: 'Categorisation and Models',
        value: 'categorisation',
    },
    {
        label: 'Stores',
        value: 'stores',
    },
];

export const PART_INVENTOR_CATEGORY = [
    {
        label: 'Entry',
        value: 'partEntry',
    },
    {
        label: 'History',
        value: 'partHistory',
    },
];

const summaryMapped = {
    distinctPart: 'Distinct Parts',
    totalInventory: 'Total Parts',
    totalValue: 'Purchase Cost',
    expiredItem: 'Expired',
    assignedItem: 'Assigned to Store',
    expired: 'Expired',
    unassignedItem: 'Unassigned',
};

export function mapKeyAndValueOfObject(object) {
    let listFromObject = [];
    Object.keys(object).map((v) => {
        if (summaryMapped[v]) {
            listFromObject.push({
                label: summaryMapped[v],
                value: object[v],
            });
        }
    });
    return listFromObject;
}

const storeSummaryMapped = {
    distinctParts: 'Distinct Parts',
    expiredItems: 'Expired',
    totalQuantity: 'Total Parts',
    totalValue: 'Store Value',
};

export function mapKeyAndValueOfObjectStore(object) {
    let listFromObject = [];
    Object.keys(object).map((v) => {
        if (storeSummaryMapped[v]) {
            listFromObject.push({
                label: storeSummaryMapped[v],
                value: object[v],
            });
        }
    });
    return listFromObject;
}

export function dataPushToUrl(value, row, navigate, type) {
    const rowType = type;
    let url = '/dashboard/partstockinventory' + '#';
    const partId = get(row, 'id');
    if (partId) {
        url += 'partId=' + partId;
    }
    if (rowType) {
        url += rowType ? '&' : '';
        url += 'rowType=' + rowType;
    }
    const parseUrl = getQueryStringParams();
    const tab = get(parseUrl, 'tab', null);
    if (tab) {
        url += tab ? '&' : '';
        url += 'tab=' + tab;
    }
    navigate(url);
}

export const COLUMNS_PART_INVENTORY_COMPLETE = [
    {
        label: 'Part',
        key: 'name',
        // width: 200,
        isKey: true,
        dataFormat: (v) => <div>{v}</div>,
    },
    {
        label: 'Quantity',
        key: 'quantity',
        // width: 200,
        dataFormat: (v) => <div>{v}</div>,
    },
    {
        label: 'Unit Price',
        key: 'basicUnitCost',
        // width: 200,
        dataFormat: (v) => <div>{v}</div>,
    },
    {
        label: 'Code #',
        key: 'codeNumber',
        // width: 200,
        dataFormat: (v) => <div>{v}</div>,
    },
    {
        label: 'Category',
        key: 'partCategory',
        // width: 200,
        dataFormat: (v) => <div>{get(v, 'name')}</div>,
    },
    {
        label: 'Model',
        key: 'partModel',
        // width: 200,
        dataFormat: (v) => <div>{get(v, 'name')}</div>,
    },
    {
        label: 'Maker',
        key: 'partMaker',
        // width: 200,
        dataFormat: (v) => <div>{get(v, 'name')}</div>,
    },
    {
        label: 'Measurement Unit',
        key: 'partMeasurementUnit',
        // width: 200,
        dataFormat: (v) => <div>{get(v, 'name')}</div>,
    },
    {
        label: 'Part Age',
        key: 'partAge',
        // width: 200,
        dataFormat: (v) => <div>{v ? v : '-'}</div>,
    },
    {
        label: 'Store',
        key: 'store',
        // width: 200,
        dataFormat: (value, row, navigate) => (
            <i
                role="presentation"
                onClick={() => dataPushToUrl(value, row, navigate, 'partStore')}
                className="store-icon"
            />
        ),
    },
    {
        label: 'Part Entry',
        // key: 'par',
        // width: 200,
        dataFormat: (value, row, navigate) => (
            <i
                role="presentation"
                onClick={() => dataPushToUrl(value, row, navigate, 'partEntry')}
                className="bill-icon"
            />
        ),
    },
    {
        label: 'Part History',
        key: 'partHistory',
        // width: 200,
        dataFormat: (value, row, navigate) => (
            <i
                role="presentation"
                onClick={() => dataPushToUrl(value, row, navigate, 'partHistory')}
                className="history-icon"
            />
        ),
    },
    {
        label: 'Action',
        key: 'action',
        // width: 200,
        dataFormat: (value, row, navigate) => (
            <div role="presentation">
                <span className="fa fa-edit" onClick={() => navigate(`settings/parts/edit/${get(row, 'id')}`)}></span>
                {/* <span className="ml-1 fa fa-trash"></span> */}
            </div>
        ),
    },
];

export function mapStoreIdAndQuantityStoreObject(object) {
    let listFromObject = [];
    Object.keys(object).map((v) => {
        listFromObject.push({
            storeId: v,
            quantity: object[v],
        });
    });
    return listFromObject;
}

export const getTaxTypeOptions = (isInternationalAcc) => {
    if (isInternationalAcc) {
        return [{ value: 'VAT', label: 'VAT' }];
    } else {
        return [
            { value: 'IGST', label: 'IGST' },
            { value: 'CGST/SGST', label: 'CGST/SGST' },
            { value: 'VAT', label: 'VAT' },
        ];
    }
};

export const purchaseTypeOptions = [
    { value: 'SALE', label: 'Sale' },
    { value: 'FOC', label: 'FOC' },
];
