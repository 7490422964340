import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Wrench = (props) => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", children: _jsx("path", { d: "M13.4996 2C12.8357 1.99986 12.18 2.14664 11.5795 2.4298C10.979 2.71296 10.4485 3.1255 10.0262 3.63782C9.60396 4.15014 9.30028 4.74956 9.13696 5.39309C8.97365 6.03662 8.95476 6.70831 9.08164 7.36L2.65664 14.018C2.21996 14.4654 1.97891 15.068 1.9865 15.6931C1.9941 16.3183 2.24972 16.9148 2.69714 17.3515C3.14456 17.7882 3.74713 18.0292 4.37228 18.0216C4.99743 18.014 5.59396 17.7584 6.03064 17.311L12.3956 10.863C13.1434 11.0525 13.9273 11.0465 14.672 10.8458C15.4168 10.645 16.0975 10.2562 16.6487 9.71661C17.1999 9.17703 17.6032 8.50482 17.8198 7.7645C18.0364 7.02418 18.0591 6.24061 17.8856 5.489C17.8655 5.40219 17.8225 5.32235 17.7611 5.25774C17.6997 5.19313 17.6222 5.1461 17.5365 5.12153C17.4508 5.09695 17.3602 5.09572 17.2739 5.11795C17.1876 5.14019 17.1088 5.18509 17.0456 5.248L14.4996 7.793L12.2076 5.5L14.7526 2.955C14.8156 2.89181 14.8604 2.81295 14.8826 2.72658C14.9048 2.64021 14.9035 2.54949 14.8788 2.4638C14.8541 2.37812 14.8069 2.30059 14.7422 2.23927C14.6775 2.17794 14.5975 2.13503 14.5106 2.115C14.1794 2.03875 13.8406 2.00017 13.5006 2H13.4996Z", fill: "#FF9845" }) }));
};
const WrenchIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Wrench, ...props });
};
export default WrenchIcon;
