import { handle } from 'redux-pack';
import { CREATE_COMMODITY, UPDATE_COMMODITY, DELETE_COMMODITY, FETCH_COMMODITY_BY_ID, FETCH_COMMODITIES, } from '../../actions/dashboard/commodity_actions';
const initialState = {
    isLoading: false,
    error: null,
    commodityList: [],
    isCommodityListLoading: false,
    isCommodityLoading: false,
    commodity: {},
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_COMMODITY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_COMMODITY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_COMMODITY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_COMMODITIES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isCommodityListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isCommodityListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    commodityList: payload.data,
                }),
            });
        case FETCH_COMMODITY_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isCommodityLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isCommodityLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, commodity: payload.data }),
            });
        default:
            return state;
    }
}
