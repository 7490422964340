// import React, { Component } from 'react';
// import { FxReactSelect as Select } from '../../../shared';
import { connect } from 'react-redux';
import { fetchVehicleListRemote } from '../../../actions/dashboard/vehicles_action';
import { get } from 'lodash';
import { setLocalVehicleId } from '../../../actions/dashboard/local_action';
import { showRemoveVehiclesInSearchForAccount, showVehicleNumber } from '../../../utils/account_utils';
import { isSwitchVehicles } from '../roles/permission_utils';
import { withTranslation } from 'react-i18next';
import { mappedVehicleListMiniOptions } from '../../utils/mapping_utils';
import { Component } from 'react';

import AsyncSelect from 'react-select/async';
import { queryString } from '../../../utils/string_utils';
import { find, toString, filter } from 'lodash';

class SelectVehicleRemote extends Component {
    constructor(props) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.promiseOptions = this.promiseOptions.bind(this);
        this.state = {
            vehicleOptions: [],
            searchById: false,
        };
    }

    promiseOptions = async (inputValue, value) => {
        inputValue = inputValue ? inputValue : this.props.vehicle;
        if (inputValue && get(toString(inputValue), 'length') > 2) {
            return new Promise(async (resolve) => {
                const vehicleResp = await fetchVehicleListRemote(
                    this.props.accesstoken,
                    inputValue ? inputValue : value,
                    showRemoveVehiclesInSearchForAccount(this.props.loggedInUser, this.props.isSwitchedUser),
                    this.props.groupId,
                    1,
                    40000,
                    this.props.vehicle ? true : this.props.isSwitchedUser
                );
                let vehicleList = get(vehicleResp, 'data', []);
                if (this.props.onlyFilterFuelLevelSensor) {
                    vehicleList = filter(vehicleList, (v) => {
                        const isFuelAvailable = get(v, 'fuelSensor', 0) > 0 || this.props.isFuelLevelAvailable(v.id);
                        return isFuelAvailable;
                    });
                }
                await this.setState({ vehicleOptions: mappedVehicleListMiniOptions(vehicleList) });
                resolve(mappedVehicleListMiniOptions(vehicleList));
            });
        } else {
            return new Promise((resolve) => {
                resolve([]);
            });
        }
    };
    handleSelectChange = (value, triggeredAction) => {
        setLocalVehicleId(value);
        this.props.onVehicleSelected(get(value, 'value'), value ? value.name : null, value ? value.licensePlate : null);
    };
    getSelectedOption = (value) => {
        if (
            this.props.simpleValue ||
            typeof value !== 'object' ||
            (Array.isArray(value) && value.length > 0 && typeof value[0] !== 'object')
        ) {
            return this.getSelectedOptionsFromSimpleValue(value);
        } else {
            return value;
        }
    };
    getSelectedOptionsFromSimpleValue = (selectedValue) => {
        if (selectedValue || typeof selectedValue === 'number') {
            return this.props.multi
                ? map(selectedValue, (value) => {
                      return find(this.state.vehicleOptions, { value: value });
                  })
                : find(this.state.vehicleOptions, (option) => get(option, 'value') == selectedValue);
        } else {
            return this.props.multi ? [] : null;
        }
    };
    render() {
        const { t } = this.props;
        return (
            <AsyncSelect
                loadOptions={(inputValue) => {
                    return this.promiseOptions(inputValue, this.props.value);
                }}
                placeholder={t('vehicles.selectVehicle')}
                noOptionsMessage={(inputValue) => {
                    return get(inputValue, 'inputValue.length', 0) > 2 ? t('noOptions') : t('typeToSeeOptions');
                }}
                value={this.getSelectedOption(this.props.value)}
                defaultOptions={true}
                onChange={this.handleSelectChange}
                isClearable={true}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    const parsed = queryString.parse(location.hash);

    return {
        accesstoken: state.login.data.access_token,
        loggedInUser: state.settings.loggedInUser,
        isSwitchUserAllowed: isSwitchVehicles(get(state, 'login.data.scope')),
        isSwitchedUser: get(state, 'login.isSwitchedUser'),
        vehicle: get(parsed, 'vehicleId', null),
    };
}

export default connect(mapStateToProps, { setLocalVehicleId })(withTranslation()(SelectVehicleRemote));
