import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../constant';
import { getMomentTime } from '../../utils/date_utils';
export const CREATE_SLOTS = 'CREATE_SLOTS';
export const UPDATE_SLOTS = 'UPDATE_SLOTS';
export const FETCH_SLOTS = 'FETCH_SLOTS';
export const CREATE_SHIFTS = 'CREATE_SHIFTS';
export const FETCH_SHIFTS = 'FETCH_SHIFTS';
export const FETCH_SHIFT_WISE_ASSIGNMENT = 'FETCH_SHIFT_WISE_ASSIGNMENT';
export const DRIVER_REPLACEMENT = 'DRIVER_REPLACEMENT';
export function createSlots(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}slots`, data, config);
    return {
        type: CREATE_SLOTS,
        promise: request,
    };
}
export function updateSlots(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}slots`, data, config);
    return {
        type: UPDATE_SLOTS,
        promise: request,
    };
}
export function fetchSlots(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}slots`, config);
    return {
        type: FETCH_SLOTS,
        promise: request,
    };
}
export function createShifts(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}shifts`, data, config);
    return {
        type: CREATE_SHIFTS,
        promise: request,
    };
}
export function fetchShifts(accesstoken, params) {
    const { vehicleId, from, to } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}shifts`, config);
    return {
        type: FETCH_SHIFTS,
        promise: request,
    };
}
export function driverReplacement(accesstoken, data, params = {}) {
    const { vehicleAssignmentId } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ vehicleAssignmentId }),
    };
    const request = axios.put(`${ROOT_API_URL}shifts/replacement`, data, config);
    return {
        type: DRIVER_REPLACEMENT,
        promise: request,
    };
}
export function fetchShiftwiseAssignment(accesstoken, params) {
    const { vehicleId, from, to } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_assignments/shift-wise`, config);
    return {
        type: FETCH_SHIFT_WISE_ASSIGNMENT,
        promise: request,
    };
}
