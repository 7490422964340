import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import { alternateRowClassNameFormat } from '../../../utils/grid_utils';
class CustomizeFieldOptionsListing extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        return null;
    }
    render() {
        const { columns, data } = this.props;
        return (_jsx(BootstrapTable, { wrapperClasses: "fleetx-table2-container", headerClasses: "", classes: "fleetx-table2", rowClasses: alternateRowClassNameFormat, bordered: false, remote: false, keyField: 'id', data: data, columns: columns, noDataIndication: () => _jsx("div", { className: "text-muted", children: "No Data" }) }));
    }
    componentDidMount() { }
    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }
    componentWillUnmount() { }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomizeFieldOptionsListing);
