import { split, get, reduce } from 'lodash';
import { isEnvLive } from '../../constant';
import { sendAppAlertToSlack } from 'utils/analytics_utils';
export function getScopesArr(scopes = ([] = get(window.FLEETX_LOGGED_IN_DATA, 'scope', []))) {
    if (typeof scopes === 'string') {
        return split(scopes, ' ');
    }
    else {
        return scopes;
    }
}
export const getScopeMap = (scopes = []) => {
    if (window.FX_SCOPE_MAP) {
        return window.FX_SCOPE_MAP;
    }
    else {
        return reduce(scopes, (obj, item) => {
            obj[item] = true;
            return obj;
        }, {});
    }
};
export function isWithinScope(scopeToCheck, scopes) {
    return getScopeMap(scopes)[scopeToCheck];
}
export const getModuleMap = (modules = []) => {
    if (window.FX_MODULE_MAP) {
        return window.FX_MODULE_MAP;
    }
    else {
        return reduce(modules, (obj, item) => {
            obj[item] = true;
            return obj;
        }, {});
    }
};
export function isModuleEnabledForUser(moduleToCheck, modules) {
    return getModuleMap(modules)[moduleToCheck];
}
export async function logUnauthorisedStoppedCallDetails(action, state) {
    try {
        if (!isEnvLive()) {
            return;
        }
        const login = get(state, 'login') || {};
        const loggedInUser = get(state, 'settings.loggedInUser') || {};
        if (!login || !loggedInUser) {
            return;
        }
        let s = '';
        s += 'type              : ' + get(action, 'type') + '\n';
        s += 'url               : ' + get(window, 'location.href') + '\n';
        s +=
            'Account           : ' +
                get(loggedInUser, 'email') +
                ' (accountId: ' +
                get(loggedInUser, 'accountId') +
                ') (userId: ' +
                get(loggedInUser, 'id') +
                ') (switchedUser: ' +
                get(login, 'isSwitchedUser') +
                ' ' +
                get(login, 'switchedUserOriginalEmailId') +
                ')\n';
        s +=
            'accesstoken            : ' +
                get(login, 'access_token', '-') +
                '\n' +
                'roleName            : ' +
                get(loggedInUser, 'role.name', '-') +
                '\n';
        sendAppAlertToSlack(s, '#unauthorised-stopped-calls-web');
    }
    catch (e) {
        console.log(e);
    }
}
export function getDummyActionResponse(metaData) {
    const { type, alertLoggingNotRequired, dataToReturn } = metaData || {};
    const payload = {
        data: dataToReturn,
        isFleetxDummyActionResponse: true,
    };
    return {
        type,
        promise: Promise.resolve(payload),
    };
}
