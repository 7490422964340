import { jsx as _jsx } from "react/jsx-runtime";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { TOOLBAR_CONFIG } from '../../utils/draftjs_utils';
const EditorContainer = styled.div `
    .wrapper-class {
        border: 1px solid #cfd7df;
        border-radius: 0.25rem;
        background-color: #fff;
        display: flex;
        flex-direction: column-reverse;
        &:hover {
            border-color: #8ad4ee;
            outline: none;
        }
    }
    .editor-class {
        padding: 0.5rem;
        padding-bottom: 0;
        overflow-y: auto;
        height: ${(props) => (props.editorHeight ? props.editorHeight : '12rem')};
        min-height: ${(props) => (props.editorMinHeight ? props.editorMinHeight : '5rem')};
        .public-DraftStyleDefault-block {
            margin: 0;
        }
    }
    .DraftEditor-root {
        height: auto;
    }
    .toolbar-class {
        border: 0;
        border-radius: 0;
        border-top: 1px solid #cfd7df;
    }
`;
const FxEditor = (props) => {
    const { editorState, onChange, editorProps, editorHeight, editorMinHeight } = props;
    const { placeholder = 'Begin typing...' } = editorProps || {};
    return (_jsx(EditorContainer, { editorHeight: editorHeight, editorMinHeight: editorMinHeight, children: _jsx(Editor, { editorState: editorState, onEditorStateChange: onChange, wrapperClassName: "wrapper-class", editorClassName: "editor-class", toolbarClassName: "toolbar-class", placeholder: placeholder, toolbar: TOOLBAR_CONFIG, ...editorProps }) }));
};
export default FxEditor;
