import {handle} from "redux-pack";
import {
    CREATE_CONTACT_RENEWAL_TYPE,
    DELETE_CONTACT_RENEWAL_TYPE,
    FETCH_CONTACT_RENEWAL_TYPE,
    FETCH_CONTACT_RENEWAL_TYPES,
    UPDATE_CONTACT_RENEWAL_TYPE
} from "../../../actions/dashboard/easy/contact_renewal_types_action";

const initialState = {
    isLoading: false, error: null, data: {}, contactRenewalTypeList: []
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case CREATE_CONTACT_RENEWAL_TYPE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case UPDATE_CONTACT_RENEWAL_TYPE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),

            });
        case DELETE_CONTACT_RENEWAL_TYPE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})
            });
        case FETCH_CONTACT_RENEWAL_TYPES :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, contactRenewalTypeList: payload.data
                }),

            });

        case FETCH_CONTACT_RENEWAL_TYPE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({...prevState, data: {...prevState.data, [payload.data.id]: payload.data}})
            });
        default:
            return state;
    }
}
