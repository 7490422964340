import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const TransporterAnalyticsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-transporter-analytics-transporter-analytics" */ 'components/dashboard/transporterAnalytics/TransporterAnalyticsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const TransporterAnalyticsDetailsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-transporter-analytics-transporter-analytics-details" */ 'components/dashboard/transporterAnalytics/transporterDetails/TransporterAnalyticsDetailsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const TaShipToPartyJobsDetailsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-transporter-analytics-transporter-analytics-details" */ 'components/dashboard/transporterAnalytics/transporterDetails/transporterAnanlyticsShipToPartyWise/taShipToPartyJobsDetails/TaShipToPartyJobsDetailsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const transporterAnalyticsRoutes = [
    {
        path: 'view/:id',
        lazy: TransporterAnalyticsDetailsComponent,
    },
    {
        path: 'mapview',
        lazy: TaShipToPartyJobsDetailsComponent,
    },
    {
        index: true,
        lazy: TransporterAnalyticsComponent,
    },
];
export default transporterAnalyticsRoutes;
