import { Component } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'reactstrap';
import { getWhiteLabelLogo, image } from '../../constant';

import { getAccountLogo, getJobShareLogoUrl, showJobShareLogo } from '../../utils/account_utils';
const fallbackLogoImage = '/public/img/logos/fleetx/fleetx-logo-final-full.svg?v=3';
class LandingHeader extends Component {
    reloadPage = () => {
        window.location.reload();
    };
    render() {
        const containerClass = this.props.fluid ? '' : 'container';
        // const logoUrl = showJobShareLogo(this.props.isFromJobShare, this.props.accountId)
        //     ? getJobShareLogoUrl(this.props.accountId)
        //     : '/public/img/logos/fleetx/fleetx-logo-final-full.svg?v=3';
        const logoUrl = this.props.accountId ? getAccountLogo(this.props.accountId) : fallbackLogoImage;
        const hostName = window.location.hostname;
        const whiteLabelLogo = getWhiteLabelLogo(hostName);
        return (
            <div className="landing-header font-montserrat pos-rel">
                <Navbar fixed="top" light full toggleable={`true`} className={`pos-rel p-md-0`}>
                    <div className={containerClass}>
                        <a href={whiteLabelLogo ? '' : 'https://www.fleetx.io/'}>
                            <span className="navbar-brand cursor-pointer">
                                <img
                                    className="fleetx-logo-full"
                                    alt="fleetx"
                                    src={image(whiteLabelLogo ? whiteLabelLogo : logoUrl)}
                                    onError={this.setFallbackImage}
                                />
                            </span>
                        </a>
                    </div>
                </Navbar>
            </div>
        );
    }
    setFallbackImage(event) {
        event.target.src = fallbackLogoImage;
    }
}

LandingHeader.propTypes = {
    fluid: PropTypes.bool,
};

export default LandingHeader;
