import axios from 'axios';
import { parseQueryParams, ROOT_API_ALFRED, ROOT_API_URL } from '../../../constant';
import { getMomentTime } from '../../../utils/date_utils';
export const FETCH_LOADING_POINT = 'fetch_loading_point';
export const FETCH_INCOMING_VEHICLES = 'fetch_incoming_vehicles';
export const UPDATE_INDENT = 'update_indent';
export const FETCH_ALL_CHECK_LIST_QUES = 'fetch_all_check_list_ques';
export const FETCH_LATEST_CHECK_LIST_ENTRY = 'fetch_latest_check_list_entry';
export const APPROVE_CHECK_LIST_ENTRY = 'approve_check_list_entry';
export const ADD_TRANSPORTER_EMAILS = 'add_transporter_email';
export const FETCH_TRANSPORTER_EMAILS = 'fetch_transporter_email';
export const FETCH_TRANSPORTER_DETAILS = 'fetch_transporter_details';
export const FETCH_TRANSPORTER_TMS = 'fetch_transporter';
export const FETCH_TRANSPORTER_REPORT = 'fetch_transporter_report';
export function fetchLoadingPoint(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}address_book/loading-points`, config);
    return {
        type: FETCH_LOADING_POINT,
        promise: request,
    };
}
export function fetchIncomingVehicles(accesstoken, licensePlates, destinationIds, from, to, compliance, page = 1, size = 2000) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            page: page - 1,
            size,
            licensePlates,
            destinationIds,
            compliance,
            from: from ? getMomentTime(from).valueOf() : from,
            to: to ? getMomentTime(to).valueOf() : to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporters/indent/search`, config);
    return {
        type: FETCH_INCOMING_VEHICLES,
        promise: request,
    };
}
export function updateIndent(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}transporters/indent`, data, config);
    return {
        type: UPDATE_INDENT,
        promise: request,
    };
}
export function getAllCheckListQuestion(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ ...params }),
    };
    const request = axios.get(`${ROOT_API_URL}questions`, config);
    return {
        type: FETCH_ALL_CHECK_LIST_QUES,
        promise: request,
    };
}
export function getCheckListEntryLatestListing(accesstoken, type, indentId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            type: type,
            indentId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}check-list-entry/latest-listing`, config);
    return {
        type: FETCH_LATEST_CHECK_LIST_ENTRY,
        promise: request,
    };
}
export function approvecheckListEntry(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}check-list-entry/approve`, data, config);
    return {
        type: APPROVE_CHECK_LIST_ENTRY,
        promise: request,
    };
}
export function addTransporterEmail(accesstoken, data, accountId, userId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            userId,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}transporters/emails`, data, config);
    return {
        type: ADD_TRANSPORTER_EMAILS,
        promise: request,
    };
}
export function fetchTransporterEmail(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporters/emails`, config);
    return {
        type: FETCH_TRANSPORTER_EMAILS,
        promise: request,
    };
}
export function fetchTransporterDetail(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporters/details`, config);
    return {
        type: FETCH_TRANSPORTER_DETAILS,
        promise: request,
    };
}
export function fetchTransporterTMS(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/transporter`, config);
    return {
        type: FETCH_TRANSPORTER_TMS,
        promise: request,
    };
}
export function getTransporterReport(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_URL}transporters/report`, config);
    return {
        type: FETCH_TRANSPORTER_REPORT,
        promise: request,
    };
}
