import jquery from 'jquery';
import {
    assign,
    filter,
    get,
    isEmpty,
    last,
    map,
    orderBy,
    round,
    set,
    slice as lodashSlice,
    toLower,
    toSafeInteger,
    toUpper,
    find,
    forEach,
    includes,
    startCase,
    upperCase,
} from 'lodash';
import {
    DEVICE_TYPE,
    FLEET_TYPE,
    getDeviceTypeFromDeviceId,
    getTripOdo,
    getUrlSuffix,
    image,
    isEnvLive,
    isViewportMDOrAbove,
    parseAddress,
    MAX_VEHICLES_TO_SHOW_FOR_LIVE,
    MAX_LIMIT_FOR_REVERSE_GEOCODE_LOCATION_CALL,
    renderRowAttribute,
    isNetworkValid,
    POLYLINE_NEAREST_POINT_RANGE,
    getLanLngCandidates,
    getNearestPointCandidatesFromQT,
    isViewportSMOrAbove,
    getNearestAddressFromQT,
} from '../../constant';
import {
    DATE_FORMAT_HUMANIZE_DAY_MONTH,
    DATE_FORMAT_HUMANIZE_TIME,
    DATE_FORMAT_TIME,
    DATE_FORMAT_TIMESTAMP,
    getHumanizeTime,
    getMomentTime,
    getTimeDiff,
    getTimeDiffHours,
    DATE_FORMAT_HUMANIZE_SECONDS,
    getFormattedTimeStringForAPI,
    DATE_FORMAT_TIME_24,
    DATE_FORMAT_HUMANIZE_24_TIME,
    DATE_FORMAT_HUMANIZE_24_DAY,
    DATE_FORMAT_HUMANIZE_DAY,
    getHumanizeTimeOnly,
    getEndDate,
    getMomentTimeFromArray,
} from '../date_utils';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import axios from 'axios';
import {
    getCurrentVehicleStatus,
    getVehicleNumberDigits,
    isEngineOn,
    isRunning,
    renderVehicleLicensePlate,
    VEHICLE_STATUS,
    VEHICLE_STATUS_NAME_LONG,
} from '../vehicle_utils';
import {
    AREA_TYPE,
    GEOCODER,
    getGeoCodedLocation,
    getNearestAddressFromAddressbook,
    getGeoCodedLocationsInBulk,
    getNearestAddress,
    deNormalizeLatitude,
    deNormalizeLongitude,
    normalizeLatitude,
    normalizeLongitude,
    createQuadTree,
} from './geocode_utils';
import { Circle, InfoWindow, Marker, Polygon, Polyline } from '@react-google-maps/api';
import { getAlarmDisplayName } from 'utils/alarm_utils';
import {
    fakePolylineKmDistanceCheckForAccount,
    fetchGeocodedlocationsAlwaysForReportForAccount,
    hideVehicleNameForAccount,
    isBajajAccount,
    isExpressRoadwaysAccount,
    isNSLAccount,
    isOnlyAssetTrackingAccount,
    showAllLinksOnMapInfowindowForAccount,
    showFullnameInMapForAccount,
    showLatLongOnMarker,
    showTagIdsToAccount,
    showDeviceIdOnMarker,
    showCustomFieldsOnMarker,
    getCustomFieldsToShowOnMarker,
    showOtherFieldsOnMarker,
    showJobConsignorOnMarker,
    showJobStartDate,
    showJobNameKeyForAcnt,
    getJobNameKeyForAcnt,
    rmcTripStatusNames,
    isVehicleNameColorBasedOnStatus,
    isDefaultNewJobFormForAccount,
    isQuadTreeSearchOpenForAccount,
    checkForNearestAddressMismatch,
    invalidNetworkPolylineKmDistanceCheckForAccount,
    getUserFleetType,
    useVehicleTypeValueForAccount,
    isSmartGreenAccount,
    showVehicleIconsAsMarker,
    dontShowParkedSinceInShareUrlForAccount,
    showAccountSpecificAlarms,
    FLEETX_ACCOUNTS,
    getSuggestedPolylineColor,
    getTripPolylineColor,
    getSIMPolylineColor,
    showRakeIconForAccount,
    hideVehicleCurrentStatusInRealtime,
    show24HoursFormatEverywhereForAccount,
    defaultHideInfoWindowRealtimeForAccount,
    showServiceReminderDataOnRealtimeMapWindowForAccount,
    showMapDefaultViewAsHybrid,
    satelliteTypeMapDefaultRest,
    isExpressJobFormOpenToUser,
    getAccountSpecificAlarms,
    ignoreLatLngDiffInMotionPointCalculation,
    isZHPOPPELTDAccount,
    showVehicleColorAccordingToServiceReminderForAccount,
    showAlarmSubFiltersForAccount,
    openHistoryFromRealtimeInNewTab,
    redirectToCustomViewFromRealtimeHistory,
} from '../account_utils';
import {
    isRoleViewerRealtimeHistory,
    isCreateJobAllowedForUser,
    showBikeIconsForGroupIdForSmartGreen,
    isViewIssuesAllowedForUser,
    isTripsVisible,
    showTagsFeature,
} from '../../components/dashboard/roles/permission_utils';
import { getCustomFieldObjectFromKeyName } from '../custom_field_utils';
import { getTripStatusFromTagIds } from '../tag_utils';
import { getClassNameForStatus, renderVehicleAlertIssuesCount } from '../realtime_utils';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { CLIENT_ISSUES_TYPES } from '../internal_tools/issues_utils';
import { getOSMCustomIconForImage, getOSMPolylineOptionsFromGMOptions } from './open_street_map_utils/osm_utils';
import { isWhiteLabelUrl, getWhiteLabelMapKey } from '../../../white_label_constants';
import ServiceRemindersForVehicleComponent from '../../components/dashboard/realtime/ServiceRemindersForVehicleComponent';
import PolylineDecorator from 'components/dashboard/trips/timeline/polylineDecoration';
import { getOrdinalSuffix } from 'utils/number_utils';
import { getFuelUnitForDevice } from 'utils/fuel_utils';
import { FxButton, FxSpace } from 'sharedV2';
import { EditOutlined } from '@ant-design/icons';
import ShowTagComponent from 'components/dashboard/tags/showTagComponent';
import { COLOR_REPAIRED_TRIP_POLYLINE } from '../../theme/colors';

export const SINGLE_VEHICLE_LIVE_TRIP_MAP = 'SINGLE_VEHICLE_LIVE_TRIP_MAP';
export const SINGLE_VEHICLE_TRIP_MAP = 'SINGLE_VEHICLE_TRIP_MAP';
export const MULTPLE_VEHICLES_MAP = 'MULTPLE_VEHICLES_MAP';
export const TRIPS_TIMELINE_MAP = 'TRIPS_TIMELINE_MAP';
export const ASSET_MOVEMENT_MAP = 'ASSET_MOVEMENT_MAP';
export const JOB_MAP = 'JOB_MAP';
export let GOOGLE_MAPS_API_KEY_PREMIUM_OG = 'AIzaSyDRM7bfv6TI6ZagyR3KdYIpSJ_RMvZlqD0';
export const GOOGLE_MAPS_API_KEY_PREMIUM_OM = 'AIzaSyAw99K9b8_OY2rXv1uTl3ThLzslr2_YGZk';
export const GOOGLE_MAPS_API_KEY_YOLO = 'AIzaSyAPBc6HT3cBjMETggxedawk7rtnP8ZnMWk';
export const GOOGLE_MAPS_WHITE_LABEL = 'AIzaSyCvCwry-Y3d3IrUYx5AqppMexuGlmi2e7I';
export const GOOGLE_MAPS_WHITE_LABEL_RIGHT_CHOICE = 'AIzaSyD75RALM7t7e8Z3hAU-A9obw850_SkJmgU';
export const GOOGLE_MAPS_WHITE_LABEL_ACCEX = 'AIzaSyD0hDSDdIjfZHKYu24oisARcuCEHEB82-8';
export const GOOGLE_MAPS_WHITE_LABEL_SHELL = 'AIzaSyDJv1nW5OblTAQhwErMQsO-zs-ipXbxT_g';
//export const GOOGLE_MAPS_WHITE_LABEL_SHOORA = 'AIzaSyBC0hd6YizeJpKIIM2qxJtPi8KTM7O6dts';
export let GOOGLE_MAPS_API_KEY_PREMIUM = isWhiteLabelUrl() ? getWhiteLabelMapKey() : GOOGLE_MAPS_API_KEY_PREMIUM_OG;
// New key test
// export const GOOGLE_MAPS_API_KEY_FLEETX_THIRDPARTY_OM = 'AIzaSyDUAJjatlyzqsH7qR4rQw35XcuUNiBsIcA';
// Third Part Key linked to seperate account.
export const GOOGLE_MAPS_API_KEY_FLEETX_THIRDPARTY_OM = 'AIzaSyDv50kAMXAsIr6kbCK6RzHsjNd7XAYIfS0';
export const GOOGLE_MAPS_API_LIBRARIES = ['geometry', 'drawing', 'places'];
export const GOOGLE_MAPS_API_LANGUAGE = 'en';
export const GOOGLE_MAPS_API_REGION = 'IN';
export const GOOGLE_MAPS_API_VERSION = '3.57';
//Latest = 3.32.13 (16/5/18) //Latest = 3.34.1 (14/8/18)  //Latest = 3.37 (18/5/19) //Latest = 3.40 (28/3/20) //Latest = 3.43.8 (02/07/21)
export const DEFAULT_ADDRESS_RADIUS = '100';

export const PLAYBACK_STATE = {
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
    STOPPED: 'STOPPED',
};
export const CAPITAL_LETTERS = 'ABCDEFGHIJKLMONPQRSTUVWXYZ';
export const PLAYBACK_TIMEOUT_MS = 1000;
export const PLAYBACK_SPEED_DEFAULT = 20;
export const GPS_FILTER_LIMIT_MAX = 5;
export const GPS_FILTER_LIMIT_HIGH = 2.95;
export const GPS_FILTER_LIMIT_HIGH_API = 3.05;
export const GPS_FILTER_LIMIT_HIGH_SUPER = 10.05;
export const GPS_FILTER_LIMIT_SIM = 50.05;
export const GPS_FILTER_LIMIT_LOW = 0.0;

//export const DEFAULT_MAP_CENTER = { lat: 28.424765, lng: 76.8496952 };
export const DEFAULT_MAP_ZOOM_LEVEL = 4;
export const MAX_ZOOM = 21;
export const MIN_ZOOM = 3;
export const MAX_ZOOM_SHARE = 19;
export const POLYLINE_COLOR = '#40A2FF';
export const POLYLINE_HIGHLIGHTED_COLOR = '#5c79fd';
export const icons = [
    {
        icon: {
            path: 'M -1,0 0,-1 1,0',
            strokeColor: '#3982db',
        },
        offset: '2%',
        repeat: '150px',
    },
];

export const iconsTrip = [
    {
        icon: {
            path: 'M -1.5,0 0,-1.5 1.5,0',
            strokeColor: '#4f73db',
        },
        offset: '2%',
        repeat: '30px',
    },
];

export const getIconsJobDirections = () => {
    const strokeColor = getSuggestedPolylineColor();
    return [
        {
            icon: {
                path: 'M -1,0 0,-1 1,0',
                strokeColor,
            },
            offset: '2%',
            repeat: '150px',
        },
    ];
};

export const getPolyLineDefaultOptions = () => {
    let strokeColor = getTripPolylineColor();
    return { options: { strokeColor: strokeColor, strokeWeight: 5, strokeOpacity: 0.8, icons: icons } };
};

export const polyLineDefaultOptionsFake = {
    options: { strokeColor: 'rgb(101,101,101)', strokeWeight: 5, strokeOpacity: 1 },
};

export const repairedTripPolylineOptions = {
    options: { strokeColor: COLOR_REPAIRED_TRIP_POLYLINE, strokeWeight: 5, strokeOpacity: 1 },
};

export const getPolyLineHoverOptions = () => {
    let strokeColor = getTripPolylineColor();
    return { options: { strokeColor: strokeColor, strokeWeight: 5, strokeOpacity: 1, icons: icons } };
};

export const getPolyLineTripDefaultOptions = () => {
    let strokeColor = getTripPolylineColor();
    return { options: { strokeColor: strokeColor, strokeWeight: 3, strokeOpacity: 0.8, icons: iconsTrip } };
};
export const polyLineTripDefaultOptionsFake = {
    options: { strokeColor: 'rgb(101,101,101)', strokeWeight: 4, strokeOpacity: 1 },
};

const RETURN_TRIP_POLYLINE_COLOR = 'rgba(15,83,255)';
export const returnTripPolyLineOptionOSM = {
    options: { strokeColor: RETURN_TRIP_POLYLINE_COLOR, strokeWeight: 4, strokeOpacity: 1 },
};
export const returnTripPolyLineOptionGoogleMap = {
    options: { strokeColor: RETURN_TRIP_POLYLINE_COLOR, strokeWeight: 5, strokeOpacity: 1, icons: icons },
};
export const polyLineTripDefaultOptionsInvalidNewtork = {
    options: { strokeColor: 'rgba(255,102,0,0.86)', strokeWeight: 4, strokeOpacity: 1 },
};

export const polyLineHighlightedOptions = {
    options: { strokeColor: POLYLINE_HIGHLIGHTED_COLOR, strokeWeight: 4, strokeOpacity: 1, icons: iconsTrip },
};

export const getPolyLineTripDefaultOptionsTripMovement = () => {
    let strokeColor = getTripPolylineColor();
    return { options: { strokeColor: strokeColor, strokeWeight: 3, strokeOpacity: 0.8, icons: iconsTrip } };
};

export const MAX_SECONDS_FOR_SHOWING_TIMELINE_MAP = 31 * 86400;
export const MAX_SECONDS_FOR_SHOWING_RPM_REPORT = 86400 * 15;
export const MAX_SECONDS_FOR_SHOWING_TRIP_STOPPAGE_REPORT = 31 * 86400;
export const MAX_SECONDS_FOR_SHOWING_TRIP_SPLIT_DISTANCE_REPORT = 31 * 86400;
export const MAX_SECONDS_FOR_TRIP_MOVEMENT_REPORT = 31 * 86400;
export const MAX_SECONDS_FOR_BULK_MOVEMENT_REPORT = 86400;
export const MAX_SECONDS_FOR_SHOWING_SPEEDING_VIOLATIONS = 31 * 86400;

export const MINIMIUM_POINTS_TO_SUPPORT_SNAPPING = 20;
export const MAX_DISTANCE_FOR_COMPACTING_TRIP_POINTS = 0.075;

export const DEFAULT_ARROW_ICON = {
    path:
        'M19.0297395,4.87348946 C19.0297395,4.40745821 18.6520207,4.02973946 18.1859895,4.02973946 C18.0805207,4.02973946 17.980677,4.05139571 17.8875832,4.08655196 L17.8867395,4.08458321 L1.57423946,10.2720832 L1.57508321,10.2743332 C1.25698946,10.3949895 1.02973946,10.700427 1.02973946,11.0609895 C1.02973946,11.458677 1.30564571,11.7899895 1.67605196,11.8791457 L1.67548946,11.8811145 L9.33055196,13.728927 L11.1783645,21.3839895 L11.1803332,21.383427 C11.2697707,21.7538332 11.600802,22.0297395 11.9984895,22.0297395 C12.359052,22.0297395 12.6644895,21.8024895 12.7851457,21.4843957 L12.7873957,21.4852395 L18.9748957,5.17273946 L18.969552,5.17077071 C19.0052707,5.07767696 19.0297395,4.97923946 19.0297395,4.87348946 Z',
    fillColor: '#0DB8FF',
    fillOpacity: 1,
    strokeColor: '#FFFFFF',
    rotation: 312,
};

// Define a symbol using SVG path notation, with an opacity of 1.
export const LINE_SYMBOL = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 2,
};
export const ARROW_SYMBOL = {
    path: 'M -1,0 0,-1 1,0',
    offset: '2%',
    repeat: '150px',
    strokeOpacity: 1,
    scale: 2.5,
};

export const MARKER_ICON_COLOR_DEFAULT = '#0DB8FF';
export const MARKER_ICON_COLOR_GREEN = '#6dbda5';
export const DEFAULT_MARKER_ICON = {
    path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
    fillColor: MARKER_ICON_COLOR_DEFAULT,
    fillOpacity: 1,
    strokeColor: '#FFFFFF',
    scale: 0.8,
};

export const TOLL_ICON = {
    path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
    fillColor: '#f86c6b',
    fillOpacity: 1,
    strokeColor: '#FFFFFF',
    scale: 0.4,
};

export function getAddressIcon(address) {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    //HOTEL_DHABA
    const { addressBookCategory, riskPoint } = address || {};

    if (riskPoint) {
        return {
            url: image('/public/img/markers/risky_point.svg'),
            imageSizes: [40],
        };
    }

    if (accountId === FLEETX_ACCOUNTS.TATA_STEEL_KALINGNAGAR && addressBookCategory === 'WAY_POINT') {
        return {
            path:
                'M9 13H11V10.25H13V13H15V8.25L12 6.25L9 8.25V13ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z',
            fillColor: '#20A8D8',
            fillOpacity: 1,
            strokeColor: '#20A8D8',
            scale: 0.5,
        };
    }
    if (addressBookCategory === 'YARD') {
        return {
            path:
                'M9 13H11V10.25H13V13H15V8.25L12 6.25L9 8.25V13ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z',
            fillColor: '#8CDDFA',
            fillOpacity: 1,
            strokeColor: '#75A8BA',
            scale: 0.5,
        };
    }
    if (addressBookCategory === 'HOTEL_DHABA') {
        return {
            url: image('/public/img/markers/hotel_dhaba.png'),
            imageSizes: [40],
        };
    } else if (addressBookCategory === 'PETROL_PUMP') {
        return {
            url: image('/public/img/markers/fuel_pump.png'),
            imageSizes: [40],
        };
    }

    return {
        url: image('/public/img/markers/default_address.svg'),
        imageSizes: [40],
    };
}

export const PLANT_ICON = {
    url: image('/public/img/markers/customer-site.png'),
    imageSizes: [24],
};
export const TOLL_ICON_FOR_OSM = '/public/img/markers/toll-icon.png';
export const SIM_ICON_FOR_OSM = '/public/img/markers/sim-icon-2.png';
export const GOOGLE_MAP_STYLES = [
    {
        featureType: 'poi.business',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'on' }],
    },
];

export const ADDRESS_BOOK_CLUSTER_ICON_OPTIONS = {
    imagePath: image('/public/img/markers/cluster/cluster_icon'),
    imageExtension: 'png',
    imageSizes: [42, 48, 54, 54],
};

export const ADDRESS_BOOK_CLUSTER_ICON_STYLES = [
    {
        url: image('/public/img/markers/cluster/cluster_icon1.png'),
        height: 42,
        width: 42,
        textColor: 'white',
        textSize: 14,
    },
    {
        url: image('/public/img/markers/cluster/cluster_icon2.png'),
        height: 48,
        width: 48,
        textColor: 'white',
        textSize: 14,
    },
    {
        url: image('/public/img/markers/cluster/cluster_icon3.png'),
        height: 54,
        width: 54,
        textColor: 'white',
        textSize: 14,
    },
    {
        url: image('/public/img/markers/cluster/cluster_icon4.png'),
        height: 70,
        width: 70,
        textColor: 'white',
        textSize: 14,
    },
];

export const REALTIME_CLUSTER_ICON_STYLES = [
    {
        url: image('/public/img/markers/cluster/realtime/m1.png'),
        height: 52,
        width: 53,
    },
    {
        url: image('/public/img/markers/cluster/realtime/m2.png'),
        height: 55,
        width: 56,
    },
    {
        url: image('/public/img/markers/cluster/realtime/m3.png'),
        height: 65,
        width: 66,
    },
    {
        url: image('/public/img/markers/cluster/realtime/m4.png'),
        height: 77,
        width: 78,
    },
    {
        url: image('/public/img/markers/cluster/realtime/m5.png'),
        height: 89,
        width: 90,
    },
];

export const MAX_RADIUS = 200;

export function showFakePolyline(distanceInKm, loggedInUser, isSkipped) {
    if (isSkipped) {
        return false;
    }
    let check = fakePolylineKmDistanceCheckForAccount(loggedInUser);
    return distanceInKm > check;
}

export function showInvalidNetworkPolyline(distanceInKm, loggedInUser, isSkipped) {
    if (isSkipped) {
        return false;
    }
    let check = invalidNetworkPolylineKmDistanceCheckForAccount(loggedInUser);
    return distanceInKm > check;
}

export function nearestLatLng(lat, lng, routes, ignoreOdo = false) {
    let mindiff = 99999;
    let closestIndex = 0;

    map(routes, (route, index) => {
        const latitude = get(route, 'latitude', false);
        const longitude = get(route, 'longitude', false);
        if (!!latitude && !!longitude) {
            const diff = pythagorasEquirectangular(lat, lng, latitude, longitude);
            if (
                diff < mindiff &&
                (get(route, 'totalOdometer') ||
                    get(route, 'otherAttributes.odometer') ||
                    ignoreOdo ||
                    get(route, 'otherAttributes.tripOdometer', 0))
            ) {
                closestIndex = index;
                mindiff = diff;
            }
        }
    });

    return routes ? routes[closestIndex] : null;
}

export function nearestLatLngMulti(lat, lng, routes, maxRadiusKm = 0.3) {
    let nearestLatLngs = [];

    if (isEmpty(routes)) {
        return nearestLatLngs;
    }
    map(routes, (point, index) => {
        const latitude = get(point, 'latitude', false);
        const longitude = get(point, 'longitude', false);
        if (!!latitude && !!longitude) {
            const diff = pythagorasEquirectangular(lat, lng, latitude, longitude);
            if (diff < maxRadiusKm) {
                let alreadyAdded = false;
                map(nearestLatLngs, (nearest) => {
                    alreadyAdded =
                        Math.abs(getMomentTime(nearest.timeStamp).diff(getMomentTime(point.timeStamp))) < 3000000;
                });
                if (!alreadyAdded) {
                    point.diff = diff;
                    nearestLatLngs.push(point);
                }
            }
        }
    });
    if (nearestLatLngs.length === 0) {
        return nearestLatLngMulti(lat, lng, routes, 2 * maxRadiusKm);
    } else {
        return orderBy(nearestLatLngs, 'diff', 'asc');
    }
}

export function pythagorasEquirectangular(lat1, lon1, lat2, lon2, convertToRadians = true) {
    if (convertToRadians) {
        lat1 = toRadians(lat1);
        lon1 = toRadians(lon1);
        lat2 = toRadians(lat2);
        lon2 = toRadians(lon2);
    }
    const R = 6371; // km
    const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    const y = lat2 - lat1;
    return Math.sqrt(x * x + y * y) * R;
}

export function getBoundingBox(lat, lon, halfSideInMeters) {
    const R = 6371; // km
    const pradius = R * Math.cos(lat);
    const latMin = lat - halfSideInMeters / R;
    const latMax = lat + halfSideInMeters / R;
    const lonMin = lon - halfSideInMeters / pradius;
    const lonMax = lon + halfSideInMeters / pradius;

    return [latMin, lonMin, latMax, lonMax];
}

export function latLonDistance(lat1, lon1, lat2, lon2) {
    /*
        haversine formula
        https://andrew.hedges.name/experiments/haversine/
        dlon = lon2 - lon1
        dlat = lat2 - lat1
        a = (sin(dlat/2))^2 + cos(lat1) * cos(lat2) * (sin(dlon/2))^2
        c = 2 * atan2( sqrt(a), sqrt(1-a) )
        d = R * c (where R is the radius of the Earth)
     */
    lat1 = toRadians(lat1);
    lon1 = toRadians(lon1);
    lat2 = toRadians(lat2);
    lon2 = toRadians(lon2);
    const R = 6371; // km
    const dlon = lon2 - lon1;
    const dlat = lat2 - lat1;
    const a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
}

export function toRadians(angle) {
    return angle * (Math.PI / 180);
}

export function toDegrees(radians) {
    return radians * (180 / Math.PI);
}

export function renderCurrentStatus(currentStatus) {
    switch (currentStatus) {
        case VEHICLE_STATUS.RUNNING:
            return <div className="text-success">{VEHICLE_STATUS_NAME_LONG[currentStatus]}</div>;
        case VEHICLE_STATUS.IDLE:
            return <div className="text-warning">{VEHICLE_STATUS_NAME_LONG[currentStatus]}</div>;
        case VEHICLE_STATUS.PARKED:
        case VEHICLE_STATUS.PARKED_10_DAYS:
            return <div>PARKED</div>;
        case VEHICLE_STATUS.DISCONNECTED:
        case VEHICLE_STATUS.IMMOBILISED:
        case VEHICLE_STATUS.NO_POWER:
        case VEHICLE_STATUS.BATTERY_DISCHARGED:
        case VEHICLE_STATUS.INSHOP:
        case VEHICLE_STATUS.STANDBY:
        case VEHICLE_STATUS.UNREACHABLE:
        case VEHICLE_STATUS.UNREACHABLE_3_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_4_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_5_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_6_DAYS:
        case VEHICLE_STATUS.API_UNREACHABLE:
        case VEHICLE_STATUS.WIRING_DEFECT:
        case VEHICLE_STATUS.WIRING_DEFECT_PARKED:
        case VEHICLE_STATUS.GPS_DEFECT:
        case VEHICLE_STATUS.GPS_0:
        case VEHICLE_STATUS.DISCONNECTED_3_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_4_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_5_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_6_DAYS:
            return <div className="text-danger">{VEHICLE_STATUS_NAME_LONG[currentStatus]}</div>;
        case VEHICLE_STATUS.REMOVED:
            return <div className="">{VEHICLE_STATUS_NAME_LONG[currentStatus]}</div>;
    }
}

export function renderAlarmLimit(label, thresholdLabel, unit, currentValue, thresholdValue) {
    return (
        <div className="mt-2">
            <div className="d-inline-block mr-4">
                <div className="info-window-item-label">{label}</div>
                <div>{`${currentValue} ${unit}`}</div>
            </div>
            {thresholdValue && (
                <div className="d-inline-block">
                    <div className="info-window-item-label">{thresholdLabel}</div>
                    <div>{`${thresholdValue} ${unit}`}</div>
                </div>
            )}
        </div>
    );
}

export function getInfowindowUi(
    currentStatus,
    durationEngineOn,
    speed = 0,
    idleDuration,
    tripOdometer,
    multiTimeStamps,
    address,
    lastStatusTime,
    showGetAddressButton,
    lat,
    lon,
    showCurrentTime24Hours,
    showActualTime,
    isOnlyAssetTracking,
    nearestAddress,
    showZeroSpeed = false,
    loggedInUser,
    isShared,
    isRequestedFrom,
    tripStatus,
    isRealtime,
    power,
    moreOptions = {},
    vehicleId
) {
    const { ct, polylineType, tripPoint, isOSMEnabled, dontShowParkedSinceTime, attachedTags, tags } = moreOptions;

    let timeStamp = multiTimeStamps;
    let multiTimestampsShow = false;
    if (typeof multiTimeStamps === 'object' && multiTimeStamps.length > 0 && !multiTimeStamps._isAMomentObject) {
        timeStamp = multiTimeStamps[0];
        multiTimestampsShow = true;
    }
    const accesstoken = get(window.FLEETX_LOGGED_IN_DATA, 'access_token', null);

    const showLatLong = showLatLongOnMarker(loggedInUser);
    const showTagIdsStatus = showTagIdsToAccount(loggedInUser);
    const showStatus = isRequestedFrom !== 'tripMovement';
    const labelClass = 'text-gray medium mb-2';
    const eachBoxWrapperClass = 'medium mr-4 mt-2 mt-md-0';
    const showLateDataReceivedTime = polylineType === 'invalidNetwork' && ct && !isNetworkValid(tripPoint);

    return (
        <div>
            {!hideVehicleCurrentStatusInRealtime() && (
                <div className="d-flex">
                    {showStatus && (
                        <div className="d-flex">
                            <div className={`${eachBoxWrapperClass}`}>
                                <div className={`${labelClass}`}>Status:</div>
                                <div>
                                    {renderCurrentStatus(currentStatus)}
                                    {showTagIdsStatus && tripStatus ? ` (${tripStatus}) ` : ''}
                                </div>
                            </div>
                            {durationEngineOn ? (
                                <div className={`${eachBoxWrapperClass}`}>
                                    <div className="d-inline-block">
                                        <div className={`${labelClass}`}>Duration:</div>
                                        <div>{getTimeDiff(durationEngineOn, false, true)}</div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                    <div className="d-flex">
                        {lastStatusTime && !showCurrentTime24Hours && !dontShowParkedSinceTime ? (
                            <div className={`${eachBoxWrapperClass}`}>
                                <div className={`${labelClass}`}>From:</div>
                                <div>
                                    {!showActualTime &&
                                        moment.duration(getMomentTime().diff(getMomentTime(lastStatusTime))).humanize()}
                                    {showActualTime && getHumanizeTime(lastStatusTime, false)}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {currentStatus === VEHICLE_STATUS.RUNNING && (speed || showZeroSpeed) ? (
                            <div className={`${eachBoxWrapperClass}`}>
                                <div className={`${labelClass}`}>Speed:</div>
                                <div>{`${round(speed)} km/h`}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {currentStatus === VEHICLE_STATUS.IDLE && idleDuration ? (
                            <div className={`${eachBoxWrapperClass}`}>
                                <div className={`${labelClass}`}>From:</div>
                                <div>{moment.duration(idleDuration).humanize()}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {!isOnlyAssetTracking && durationEngineOn && tripOdometer ? (
                            <div className={`${eachBoxWrapperClass}`}>
                                <div className={`${labelClass}`}>Distance:</div>
                                <div>{`${getTripOdo(tripOdometer)}`}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {!durationEngineOn && (
                            <div className={`${eachBoxWrapperClass}`}>
                                {tripOdometer ? (
                                    <div>
                                        <div className={`${labelClass}`}>Distance:</div>
                                        <div>{`${getTripOdo(tripOdometer)}`}</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                        {power === '0.0' && (
                            <div className={`${eachBoxWrapperClass}`}>
                                <div className={`${labelClass} text-danger`}>External Power:</div>
                                <div className="text-danger">{`${+power}`}</div>
                            </div>
                        )}
                        {currentStatus === VEHICLE_STATUS.DISCONNECTED && (
                            <span
                                className="font-sm font-weight-500 align-self-center"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Link
                                    to={`/dashboard/internalissues/add#vehicleIds=${vehicleId}&type=${CLIENT_ISSUES_TYPES.REPAIR}`}
                                    color="link"
                                >
                                    Create Ticket
                                </Link>
                            </span>
                        )}
                    </div>
                </div>
            )}
            {attachedTags?.length > 0 && (
                <div className="mt-2">
                    {map(attachedTags, (tagId) => {
                        const filteredTagId = find(tags, { id: tagId });
                        return (
                            <>
                                {filteredTagId ? (
                                    <div key={tagId} className={`d-inline-block mr-2`} style={{ lineHeight: 1 }}>
                                        <ShowTagComponent
                                            tag={filteredTagId}
                                            loggedInUser={loggedInUser}
                                            lineHeight={1}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        );
                    })}
                </div>
            )}
            {!hideVehicleCurrentStatusInRealtime() && <hr />}

            <div
                id="markerInfoWindowAddressContainer"
                className={`${!address && !showGetAddressButton ? 'd-none' : ''}`}
            >
                <div className="d-flex">
                    <span className={`text-gray medium mr-3`}>Location:</span>
                    <div>
                        {showGetAddressButton && !address && (
                            <Button
                                id="markerInfoWindowAddressButton"
                                size="sm"
                                outline
                                color="primary"
                                className="medium"
                                onClick={() => {
                                    getAddressForMarkerInfoButtonInTrip(lat, lon, accesstoken);
                                }}
                            >
                                Show Address
                            </Button>
                        )}
                        <div
                            className="medium d-inline-block"
                            id="markerInfoWindowAddressText"
                            style={{ width: '220px' }}
                        >
                            {parseAddress(address)}
                            {showLatLong && (
                                <div>
                                    <span className="my-1">
                                        Lat: {round(lat, 6)}, Lon: {round(lon, 6)}
                                    </span>
                                </div>
                            )}
                        </div>
                        {!isViewportMDOrAbove() && (
                            <div>
                                <a
                                    target="_blank"
                                    href={getGoogleMapLink(lat, lon)}
                                    style={{ color: '#427fed', fontWeight: '400', fontSize: '11px' }}
                                >
                                    <span>View on Google Maps</span>
                                </a>
                            </div>
                        )}
                        {(!nearestAddress || !nearestAddress.isInside) &&
                            !isRoleViewerRealtimeHistory(loggedInUser) &&
                            !isShared &&
                            !isRealtime && (
                                <div className="mt-1">
                                    {' '}
                                    <Link to={`/dashboard/routes/addressbook/add#lat=${lat}&lon=${lon}`}>
                                        <Button
                                            color="secondary"
                                            size="sm"
                                            className="medium"
                                            title="Add to Addressbook"
                                        >
                                            <i className="fa fa-plus" /> Add Address
                                        </Button>
                                    </Link>
                                </div>
                            )}
                    </div>
                </div>

                {nearestAddress && (
                    <div className="medium d-flex mt-3">
                        <div className="text-gray  mr-3">
                            Nearest <br /> Address:
                        </div>
                        <div
                            className={`${nearestAddress.isInside ? 'text-on-location' : ''}`}
                            style={{ width: '220px' }}
                        >
                            {nearestAddress.addressText}
                        </div>
                    </div>
                )}
            </div>
            <hr />
            <div className="row">
                <div className={`col-md-6`}>
                    {(showCurrentTime24Hours || timeStamp) && !multiTimestampsShow ? (
                        <div className="info-window-item-label">
                            {getHumanizeTime(timeStamp ? timeStamp : getMomentTime(), false, showCurrentTime24Hours)}
                        </div>
                    ) : (
                        ''
                    )}
                    {(showCurrentTime24Hours || timeStamp) && multiTimestampsShow ? (
                        <div className="info-window-item-label">
                            {map(multiTimeStamps, (time, index) => {
                                if (index < 2) {
                                    return (
                                        <div key={(time ? time : getMomentTime()).valueOf()}>
                                            {getHumanizeTime(
                                                time ? time : getMomentTime(),
                                                false,
                                                showCurrentTime24Hours
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                {showLateDataReceivedTime && (
                    <div className="col-md-6">
                        <span className={`${labelClass} mr-1`}>Late Data Received At:</span>
                        <span style={{ color: 'rgba(255,102,0,0.86)' }}>{getHumanizeTimeOnly(ct)}</span>
                        <div className="medium font-italic text-danger">* Due to network unavailability</div>
                    </div>
                )}
            </div>

            {!!(showCurrentTime24Hours || timeStamp || showLateDataReceivedTime) && <hr />}
        </div>
    );
}

export function getPolyLineSuggestedOptions(strokeColorOverride = '') {
    let strokeColor = getSuggestedPolylineColor();
    return {
        options: {
            strokeColor: strokeColorOverride ? strokeColorOverride : strokeColor,
            strokeWeight: 3,
            strokeOpacity: 0.7,
            icons: getIconsJobDirections(),
        },
    };
}

export function getLanePolyLineOptionsWithIndex(index) {
    const color_codes = [
        '#FF0000', // Red
        '#0000FF', // Blue
        '#00FF00', // Green
        '#FFFF00', // Yellow
        '#000000', // Black
        '#FFA500', // Orange
        '#800080', // Purple
        '#FFC0CB', // Pink
        '#008080', // Teal
        '#FF00FF', // Magenta
        '#D3D3D3', // Light Gray
        '#87CEEB', // Pale Blue
        '#98FB98', // Pastel Green
        '#FFFF99', // Pale Yellow
        '#E6E6FA', // Lavender
    ];

    let strokeColor = getSuggestedPolylineColor();

    const getIconsJobDirections = [
        {
            icon: {
                path: 'M -1,0 0,-1 1,0',
                strokeColor: get(color_codes, index, strokeColor),
            },
            offset: '2%',
            repeat: '150px',
        },
    ];
    return {
        options: {
            strokeColor: get(color_codes, index, strokeColor),
            strokeWeight: 3,
            strokeOpacity: 0.7,
            icons: getIconsJobDirections,
        },
    };
}

function HSLToHex(h, s, l) {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = l - c / 2,
        r = 0,
        g = 0,
        b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length == 1) r = '0' + r;
    if (g.length == 1) g = '0' + g;
    if (b.length == 1) b = '0' + b;

    return '#' + r + g + b;
}

//https://stackoverflow.com/questions/1152024/best-way-to-generate-a-random-color-in-javascript/14187677#14187677
export function randomColorGenerator() {
    // 30 random hues with step of 12 degrees
    let h = Math.floor(Math.random() * 30) * 12;
    let l = 60;
    let s = 90;
    return HSLToHex(h, s, l);
}

export const getChildVehicleColor = (label) => {
    switch (upperCase(label)) {
        case 'CF':
            return '#f53dd0';
        case 'CR':
            return '#abf53d';
        case 'SIM':
            return '#f53d87';
    }
};

export function getChildVehiclesPolylineOptions(label) {
    const childColor = getChildVehicleColor(label);
    let strokeColor = childColor ? childColor : randomColorGenerator();
    return {
        options: {
            strokeColor,
            strokeWeight: 3,
            strokeOpacity: 0.7,
            icons: getIconsJobDirections(),
        },
    };
}

export function getSIMPolyLineOptions() {
    let strokeColor = getSIMPolylineColor();
    return {
        options: {
            strokeColor,
            strokeWeight: 4,
            strokeOpacity: 0,
            icons: [
                { icon: LINE_SYMBOL, offset: '0', repeat: '8px' },
                { icon: ARROW_SYMBOL, offset: '0', repeat: '80px' },
            ],
        },
    };
}

export function getSnappedRoads(points, interpolate, forceSnap, callback) {
    if (!isEnvLive()) {
        // callback(points);
        // return;
    }

    if (!forceSnap) {
        callback(points);
        return;
    }

    let promises = [];
    const requests = Math.ceil(points.length / 100);
    let vehicleName, vehicleNumber;
    if (points[10]) {
        vehicleName = points[10].vehicleName;
        vehicleNumber = points[10].vehicleNumber;
    }
    if (!vehicleName && !vehicleNumber) {
        // sendAppAlertToSlack(`vehicleName & vehicleNumber not found while snapping. \n${window.location.href}`, '#test');
    }

    for (let i = 0; i < requests; i++) {
        const slice = lodashSlice(points, i * 100, i * 100 + 100);
        let path = '';
        map(slice, (point) => {
            if (point) {
                if (path) {
                    path += '|';
                }
                path += point.latitude + ',' + point.longitude;
            }
        });
        promises.push(
            axios.get(
                `https://roads.googleapis.com/v1/snapToRoads?key=${GOOGLE_MAPS_API_KEY_PREMIUM}&path=${path}&interpolate=${interpolate}`
            )
        );
    }

    let snappedPoints = [];
    let currentPointer = 0;
    let tripOdometer = 0;
    axios
        .all(promises)
        .then(
            axios.spread((...args) => {
                for (let i = 0; i < args.length; i++) {
                    const start = i * 100;
                    if (!args[i].data.warningMessage) {
                        if (args[i].data.snappedPoints && args[i].data.snappedPoints.length > 0) {
                            const alteredSnappedPoints = map(args[i].data.snappedPoints, (object) => {
                                if (object.originalIndex === 0 || object.originalIndex) {
                                    currentPointer = start + object.originalIndex;
                                }
                                object.isSnappedPoint = true;
                                object.latitude = object.location.latitude;
                                object.longitude = object.location.longitude;
                                object.durationEngineOn = get(points[currentPointer], 'durationEngineOn', 0);
                                set(object, 'otherAttributes', get(points[currentPointer], 'otherAttributes', {}));
                                set(
                                    object,
                                    'otherAttributes.course',
                                    get(points[currentPointer], 'otherAttributes.course', 0)
                                );
                                if (!!get(points[currentPointer], 'otherAttributes.tripOdometer')) {
                                    tripOdometer = get(points[currentPointer], 'otherAttributes.tripOdometer');
                                }
                                set(object, 'otherAttributes.tripOdometer', tripOdometer);
                                object.vehicleId = get(points[currentPointer], 'vehicleId');
                                object.vehicleName = vehicleName;
                                object.vehicleNumber = vehicleNumber;
                                object.timeStamp = points[currentPointer].timeStamp;
                                object.speed = points[currentPointer].speed;
                                object.currentStatus =
                                    points[currentPointer].currentStatus | (points[currentPointer].speed > 0)
                                        ? VEHICLE_STATUS.RUNNING
                                        : VEHICLE_STATUS.IDLE;
                                object.status = points[currentPointer].status;
                                object.mileage = points[currentPointer].mileage;
                                object.fuelType = points[currentPointer].fuelType;
                                object.driverName = points[currentPointer].driverName;
                                object.currentOdometer = points[currentPointer].currentOdometer;
                                object.totalOdometer = points[currentPointer].totalOdometer;
                                object.currentFuelConsumption = points[currentPointer].currentFuelConsumption;
                                return object;
                            });
                            snappedPoints = [...snappedPoints, ...alteredSnappedPoints];
                        } else {
                            // sendAppAlertToSlack('No data fetched in snapping : ' + window.location.href);
                        }
                    } else {
                        snappedPoints = [...snappedPoints, ...lodashSlice(points, start, start + 100)];
                        // sendAppAlertToSlack(args[i].data.warningMessage + ' : ' + window.location.href);
                    }
                }
            })
        )
        .then(() => {
            if (callback) {
                if (snappedPoints && snappedPoints.length > 0) {
                    const lastActual = last(points);
                    const lastSnapped = last(snappedPoints);
                    if (lastActual && lastSnapped && lastSnapped.timeStamp !== lastActual.timeStamp) {
                        const secondLast = assign({}, lastActual);
                        const last = assign({}, lastActual);
                        secondLast.timeStamp = getMomentTime(lastSnapped.timeStamp)
                            .add(1, 'seconds')
                            .format(DATE_FORMAT_TIMESTAMP);
                        snappedPoints = [...snappedPoints, secondLast, last];
                    }
                    callback(snappedPoints);
                } else {
                    callback(points);
                }
            }
        });
}

export function getPointerAngle(course) {
    const rotation = (toSafeInteger(course) - 48) % 360;
    return rotation > 0 ? rotation : 360 + rotation;
}

export function pushAlarmMarkers(alarm, allTripPoints, tripMarkers, index, showAdditionalAlarmMarkerDetails) {
    let { latitude, longitude } = alarm;
    const type = toLower(get(alarm, 'otherAttributes.alarm[0].type'));
    if (allTripPoints && allTripPoints.length > 0) {
        const nearestPoint = nearestLatLng(latitude, longitude, allTripPoints);
        latitude = nearestPoint.latitude;
        longitude = nearestPoint.longitude;
    }
    tripMarkers.push({
        tripItemId: getMomentTime(alarm.timeStamp).valueOf(),
        position: {
            lat: latitude,
            lng: longitude,
        },
        type: get(alarm, 'otherAttributes.alarm[0].type'),
        defaultAnimation: 0,
        key: 'alarm' + type + index,
        icon: image('/public/img/markers/warn-icon.png'),
        showInfo: false,
        createDate: get(alarm, 'createDate', ''),
        infoContent: (
            <div>
                <div className="mt-1 text-uppercase">
                    <b>{getAlarmDisplayName(type)}</b>
                </div>
                {type === 'speeding' &&
                    alarm.otherAttributes.alarm[0].currentValue < 60 &&
                    renderAlarmLimit(
                        '',
                        '',
                        '',
                        `Overspeeding for ${round(alarm.otherAttributes.alarm[0].currentValue)} min`,
                        ''
                    )}
                {type === 'speeding' &&
                    alarm.otherAttributes.alarm[0].currentValue >= 60 &&
                    renderAlarmLimit(
                        'SPEED',
                        'Limit',
                        'km/h',
                        round(alarm.otherAttributes.alarm[0].currentValue),
                        round(alarm.otherAttributes.alarm[0].threasholdValue)
                    )}

                {type === 'hard acceleration' &&
                    renderAlarmLimit(
                        'Value (in g)',
                        'Limit (in g)',
                        '',
                        round(alarm.otherAttributes.alarm[0].currentValue),
                        round(alarm.otherAttributes.alarm[0].threasholdValue)
                    )}

                {type === 'high engine coolant temperature' &&
                    renderAlarmLimit(
                        'TEMP.',
                        'Limit',
                        '°C',
                        round(alarm.otherAttributes.alarm[0].currentValue),
                        round(alarm.otherAttributes.alarm[0].threasholdValue)
                    )}

                {type === 'high rpm' &&
                    renderAlarmLimit(
                        'RPM.',
                        'Limit',
                        '',
                        round(alarm.otherAttributes.alarm[0].currentValue),
                        round(alarm.otherAttributes.alarm[0].threasholdValue)
                    )}

                {type === 'geofence' &&
                    renderAlarmLimit(
                        '.',
                        '',
                        '',
                        alarm.otherAttributes.alarm[0].subType,
                        alarm.otherAttributes.Geofence[0].name.split(':')[1]
                    )}

                {type === 'misuse' && renderAlarmLimit('.', '', '', alarm.otherAttributes.alarm[0].subType, '')}

                {type === 'fatigue driving' &&
                    renderAlarmLimit(
                        'Limit',
                        '',
                        '',
                        getTimeDiff(alarm.otherAttributes.alarm[0].threasholdValue * 60000, false, true),
                        ''
                    )}

                {type === 'fuellevel' &&
                    renderAlarmLimit(
                        `Fuel ${startCase(alarm.otherAttributes.alarm[0].subType)}`,
                        'Limit',
                        getFuelUnitForDevice(null, alarm.deviceId),
                        `${round(alarm.otherAttributes.alarm[0].currentValue)}`
                        // `${round(alarm.otherAttributes.alarm[0].threasholdValue)}`
                    )}

                {type === 'idle engine' &&
                    renderAlarmLimit(
                        'Limit',
                        '',
                        '',
                        getTimeDiff(alarm.otherAttributes.alarm[0].threasholdValue * 60000, false, true),
                        ''
                    )}

                {type === 'temperature' &&
                    renderAlarmLimit(
                        'TEMP.',
                        'Limit',
                        '°C',
                        round(alarm.otherAttributes.alarm[0].currentValue),
                        round(alarm.otherAttributes.alarm[0].threasholdValue)
                    )}
                {type === 'route deviation' &&
                    renderAlarmLimit(
                        'Deviation.',
                        'Limit',
                        'KM',
                        round(alarm.otherAttributes.alarm[0].currentValue),
                        round(alarm.otherAttributes.alarm[0].threasholdValue)
                    )}

                {type === 'parked' && renderAlarmLimit('STATUS', '', '', '', '')}

                {(type === 'external machince status' || type === 'external machince 2 status') &&
                    renderAlarmLimit('STATUS', '', '', startCase(alarm.otherAttributes.alarm[0].subType), '')}

                {type === 'drum status' &&
                    renderAlarmLimit('STATUS', '', '', startCase(alarm.otherAttributes.alarm[0].subType), '')}
                {type === 'dumper status' &&
                    renderAlarmLimit('STATUS', '', '', startCase(alarm.otherAttributes.alarm[0].subType), '')}

                {type === 'refer ac status' &&
                    renderAlarmLimit('STATUS', '', '', startCase(alarm.otherAttributes.alarm[0].subType), '')}
                {type === 'cabin ac status' &&
                    renderAlarmLimit('STATUS', '', '', startCase(alarm.otherAttributes.alarm[0].subType), '')}

                {(type === 'charging stop' || type === 'charging start' || type === 'charging full') &&
                    renderAlarmLimit('STATUS', '', '', '', '')}

                {type === 'free wheeling' &&
                    renderAlarmLimit('TIME', '', 'mins', round(alarm.otherAttributes.alarm[0].currentValue), '')}
                {type === 'engine oil pressure' &&
                    renderAlarmLimit(
                        'Engine oil Pressue',
                        'Limit',
                        'kPa',
                        round(alarm.otherAttributes.alarm[0].currentValue),
                        round(alarm.otherAttributes.alarm[0].threasholdValue)
                    )}

                {type === 'vehicle load status' &&
                    renderAlarmLimit('STATUS', '', '', startCase(alarm.otherAttributes.alarm[0].subType), '')}

                <hr />

                <div>
                    {alarm.durationEngineOn && !showAdditionalAlarmMarkerDetails ? (
                        <div className="d-inline-block mr-4">
                            <div className="info-window-item-label">RUNNING DURATION</div>
                            <div>{getTimeDiff(alarm.durationEngineOn, false, true)}</div>
                        </div>
                    ) : (
                        ''
                    )}
                    {alarm.otherAttributes.tripOdometer && !showAdditionalAlarmMarkerDetails ? (
                        <div className="d-inline-block">
                            <div className="info-window-item-label">RUNNING DISTANCE</div>
                            <div>{`${getTripOdo(alarm.otherAttributes.tripOdometer)}`}</div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {showAdditionalAlarmMarkerDetails && (
                    <div id="additionalAlarmDetails" className="mt-2">
                        Loading Details...
                    </div>
                )}

                {alarm.timeStamp ? (
                    <div className="info-window-item-label mt-3">{getHumanizeTime(alarm.timeStamp, true)}</div>
                ) : (
                    ''
                )}
            </div>
        ),
    });
}

export function getAlarmMarkers(tripAlarms, allTripPoints, accountId = null, showAdditionalAlarmMarkerDetails) {
    let tripMarkers = [];
    const showAccountAlarms = showAccountSpecificAlarms();
    map(tripAlarms, (alarm, index) => {
        const type = toLower(get(alarm, 'otherAttributes.alarm[0].type'));
        if (type === 'ignition on' || type === 'ignition off') {
            // DO nothing. skipping these alarms in UI
        } else {
            const accountSpecificAlarms = map(getAccountSpecificAlarms(), (type) => toUpper(type));
            if (showAccountAlarms) {
                includes(accountSpecificAlarms, toUpper(type)) &&
                    pushAlarmMarkers(alarm, allTripPoints, tripMarkers, index, showAdditionalAlarmMarkerDetails);
            } else {
                pushAlarmMarkers(alarm, allTripPoints, tripMarkers, index, showAdditionalAlarmMarkerDetails);
            }
        }
    });
    return tripMarkers;
}

export function getFilteredTripAlarms(
    allTripAlarms,
    alarmSearchFilter,
    playbackState,
    actualPlaybackElapsedTimeSeconds,
    sDate
) {
    let filteredAlarms = assign(allTripAlarms);
    if (!isEmpty(alarmSearchFilter)) {
        filteredAlarms = filter(filteredAlarms, (alarm) => {
            if (toLower(get(alarm, 'otherAttributes.alarm[0].type', '')) === alarmSearchFilter) {
                return true;
            }
            return false;
        });
    }
    if (playbackState !== PLAYBACK_STATE.STOPPED && sDate !== null) {
        //show alarms upto previous duration.
        const maxAlarmTime = getMomentTime(sDate).add(actualPlaybackElapsedTimeSeconds, 'seconds').valueOf();
        filteredAlarms = filter(filteredAlarms, (alarm) => {
            if (getMomentTime(alarm.timeStamp).valueOf() <= maxAlarmTime) {
                return true;
            }
            return false;
        });
    }
    return filteredAlarms;
}

function getAddressForMarkerInfoButtonInTrip(latitude, longitude, accesstoken, geocode = GEOCODER.BACKEND) {
    getGeoCodedLocation(accesstoken, latitude, longitude, geocode).then((result) => {
        const container = document.getElementById('markerInfoWindowAddressContainer');
        const button = document.getElementById('markerInfoWindowAddressButton');
        const text = document.getElementById('markerInfoWindowAddressText');
        if (container && button && text) {
            container.classList.remove('d-none');
            button.classList.add('d-none');
            if (result && result.address) {
                text.innerText = parseAddress(result.address);
            } else {
                text.innerText = 'No Address found.';
            }
        }
    });
}

export function getAddressForMarkerInfoButton(
    accesstoken,
    latitude,
    longitude,
    divIdToReplace,
    divIdToHide,
    didWait = false
) {
    if (!didWait) {
        setTimeout(() => {
            getAddressForMarkerInfoButton(accesstoken, latitude, longitude, divIdToReplace, divIdToHide, true);
        }, 100);
        return;
    }
    const div = document.getElementById(divIdToReplace);
    const divToHide = document.getElementById(divIdToHide);
    if (!div) {
        return;
    }

    getGeoCodedLocation(accesstoken, latitude, longitude, GEOCODER.BACKEND).then((result) => {
        let address = get(result, 'address', 'No Address found.');
        div.innerText = address;
        if (divToHide) {
            divToHide.classList.add('d-none');
        }
    });
}

export function getInfoCustomFieldUi(loggedInUser, jobCustomFields, originActualDeparture, accountId, isShareLink) {
    const customFieldsList = getCustomFieldsToShowOnMarker(loggedInUser, accountId, true);
    return (
        <>
            {map(customFieldsList, (cf) => {
                let customField = getCustomFieldObjectFromKeyName(jobCustomFields, cf.keyName);
                return (
                    <div key={cf.label} className="d-inline-block align-top">
                        <div className="mb-2 mr-2 mt-2">
                            <div className="info-window-item-label">{cf.label}</div>
                            <div>{get(customField, 'value', '')}</div>
                        </div>
                    </div>
                );
            })}
            {originActualDeparture && (
                <div className="d-inline-block align-top">
                    <div className="mb-2 mr-2 mt-2">
                        <div className="info-window-item-label">{'Plant Exit Time'}</div>
                        <div>{getMomentTime(originActualDeparture).format(DATE_FORMAT_TIMESTAMP)}</div>
                    </div>
                </div>
            )}
        </>
    );
}

export function showKeyOnMarkerStackedLabel(key, value) {
    return (
        <div key={key} className="d-inline-block align-top">
            <div className="mb-2 mr-2 mt-2">
                <div className="info-window-item-label">{key}</div>
                <div>{value}</div>
            </div>
        </div>
    );
}

export function getInfoOtherFieldsUi(loggedInUser, otherJobAttributes) {
    const etaType = get(otherJobAttributes, 'etaType');
    const eta = get(otherJobAttributes, 'eta')
        ? getMomentTime(get(otherJobAttributes, 'eta')).format(DATE_FORMAT_TIMESTAMP)
        : '-';
    let etaLabelOnDuty;
    let etaLabelOffDuty;
    if (etaType === 'customer') {
        etaLabelOnDuty = 'Customer ETA';
        etaLabelOffDuty = 'Plant ETA';
    } else {
        etaLabelOnDuty = 'Plant ETA';
        etaLabelOffDuty = 'Customer ETA';
    }
    return (
        <>
            {showKeyOnMarkerStackedLabel(etaLabelOnDuty, eta)}
            {showKeyOnMarkerStackedLabel(etaLabelOffDuty, '-')}
        </>
    );
}

export function getInfoUi(loggedInUser, jobCustomFields) {
    const customFieldsList = getCustomFieldsToShowOnMarker(loggedInUser);
    return map(customFieldsList, (cf) => {
        let customField = getCustomFieldObjectFromKeyName(jobCustomFields, cf.keyName);
        return (
            <div className="d-inline-block align-top">
                <div className="mb-2 mr-2 mt-2">
                    <div className="info-window-item-label">{cf.label}</div>
                    <div>{get(customField, 'value', '-')}</div>
                </div>
            </div>
        );
    });
}

export function getNormalMarker(
    loggedInUser,
    mapType,
    highlightedVehicleId,
    vehicleId,
    latitude,
    longitude,
    currentStatus,
    speed,
    lastUpdatedAt,
    lastStatusTime,
    vehicleName,
    durationEngineOn,
    vehicleNumber,
    driverName,
    address,
    course,
    tripOdometer,
    idleDuration,
    index,
    isSharedTrip,
    shareVehicleLocation,
    playbackState,
    timeStamp,
    distanceFromLocation,
    etaFromLocation,
    showNearbyVehiclesToLatLon,
    isJobModuleEnabled,
    addressBook,
    groupName,
    isRealtime,
    vehiclesLength,
    isRequestedFrom,
    groupId,
    isOSMEnabled,
    vehicleTypeValue,
    moreOptions = {},
    useArrowMarkericon,
    showLicensePlateInsteadOfNameInRealtime
) {
    const { accountId } = moreOptions;
    const angle = getPointerAngle(course);
    let icon;
    if (mapType === MULTPLE_VEHICLES_MAP) {
        icon = image(
            '/public/img/markers/' +
                (isRunning(currentStatus) ? 'green' : 'red') +
                '_Marker' +
                CAPITAL_LETTERS[index] +
                '.png'
        );
    } else {
        let vehicleImage = getMarkerImageBasedOnVehicleTypeAndStatus(vehicleTypeValue, currentStatus);

        icon = useArrowMarkericon ? vehicleImage : 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    }
    const suffixDate = getUrlSuffix(false);
    const suffix = `vehicleId=${vehicleId}${suffixDate ? `&${suffixDate}` : ''}`;
    let suffixToday = `vehicleId=${vehicleId}&startDate=${getMomentTime().startOf('day')}   `;
    const hideVehicleName = hideVehicleNameForAccount(loggedInUser);
    const showAllLinksOnMapInfowindow = showAllLinksOnMapInfowindowForAccount(loggedInUser);
    const isBajaj = isBajajAccount(loggedInUser);
    const isNSL = isNSLAccount(loggedInUser);
    const isExpressRoadways = isExpressRoadwaysAccount(loggedInUser);
    const isOnlyAssetTracking = isOnlyAssetTrackingAccount(loggedInUser);
    const showFullName = showFullnameInMapForAccount(loggedInUser);
    const isDefaultNewJobForm = isDefaultNewJobFormForAccount(loggedInUser);
    const isCreateJobAllowed = isCreateJobAllowedForUser(loggedInUser);
    const showCurrentTime24Hours = isBajaj;
    const showActualTime = isNSL;
    const nearestAddress =
        vehiclesLength > 100 ? '' : getNearestAddressFromAddressbook(latitude, longitude, addressBook, true);
    const digitsToShow = isZHPOPPELTDAccount(loggedInUser) ? 7 : '';

    return {
        position: {
            lat: latitude,
            lng: longitude,
        },
        defaultAnimation: 0,
        key: 'marker' + vehicleId,
        hasAddress: !!address,
        icon: isOSMEnabled ? getOSMCustomIconForImage(icon) : icon,
        arrowIcon: true,
        course: angle,
        speed: speed,
        currentStatus: currentStatus,
        vehicleId: vehicleId,
        groupId: groupId,
        vehicleNumber: vehicleNumber,
        vehicleTypeValue: vehicleTypeValue,
        showInfo: highlightedVehicleId && vehicleId && highlightedVehicleId === vehicleId,
        labelText: showLicensePlateInsteadOfNameInRealtime
            ? vehicleNumber
            : showFullName
            ? vehicleName
            : getVehicleNumberDigits(vehicleNumber, digitsToShow),
        infoContent: (
            <div className="">
                <div style={{ color: '#212121' }} className="mt-1">
                    {!hideVehicleName && vehicleName}{' '}
                    {renderVehicleLicensePlate(loggedInUser, vehicleNumber, true, true, true)}
                </div>
                <div className="mt-1" style={{ color: '#212121' }}>
                    {isRealtime && groupName && <span>Group: {groupName}</span>}
                    {isRealtime && groupName && driverName && <span className="mx-1 text-muted">|</span>}
                    {driverName && <span>Driver: {driverName}</span>}
                </div>
                {lastUpdatedAt && !showCurrentTime24Hours && playbackState === PLAYBACK_STATE.STOPPED && (
                    <div className="info-window-item-label mt-1">
                        {`Last updated : `}
                        {getHumanizeTime(lastUpdatedAt, true)}
                    </div>
                )}
                {!!(etaFromLocation && distanceFromLocation) && (
                    <div className="card my-1 mx-0">
                        <div className="card-block p-2">
                            <div>
                                <span className="">Distance: {getTripOdo(distanceFromLocation / 1000)}</span>
                                <span className="ml-3">ETA: {getTimeDiffHours(etaFromLocation * 1000)}</span>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                )}
                <hr />
                {(currentStatus === VEHICLE_STATUS.RUNNING || currentStatus === VEHICLE_STATUS.IDLE) &&
                    getInfowindowUi(
                        currentStatus,
                        durationEngineOn,
                        speed,
                        idleDuration,
                        tripOdometer,
                        timeStamp,
                        address,
                        null,
                        true,
                        latitude,
                        longitude,
                        showCurrentTime24Hours,
                        showActualTime,
                        isOnlyAssetTracking,
                        nearestAddress,
                        null,
                        loggedInUser,
                        isSharedTrip,
                        isRequestedFrom
                    )}
                {(currentStatus === VEHICLE_STATUS.PARKED ||
                    currentStatus === VEHICLE_STATUS.PARKED_10_DAYS ||
                    currentStatus == VEHICLE_STATUS.IMMOBILISED) &&
                    getInfowindowUi(
                        currentStatus,
                        0,
                        0,
                        0,
                        0,
                        timeStamp,
                        address,
                        lastStatusTime,
                        true,
                        latitude,
                        longitude,
                        showCurrentTime24Hours,
                        showActualTime,
                        isOnlyAssetTracking,
                        nearestAddress,
                        null,
                        loggedInUser,
                        isSharedTrip,
                        isRequestedFrom,
                        null,
                        null,
                        null,
                        {
                            dontShowParkedSinceTime:
                                dontShowParkedSinceInShareUrlForAccount(accountId, isSharedTrip) &&
                                includes([VEHICLE_STATUS.PARKED, VEHICLE_STATUS.PARKED_10_DAYS], currentStatus),
                        }
                    )}
                {(currentStatus === VEHICLE_STATUS.UNREACHABLE ||
                    currentStatus === VEHICLE_STATUS.UNREACHABLE_3_DAYS ||
                    currentStatus === VEHICLE_STATUS.UNREACHABLE_4_DAYS ||
                    currentStatus === VEHICLE_STATUS.UNREACHABLE_5_DAYS ||
                    currentStatus === VEHICLE_STATUS.UNREACHABLE_6_DAYS ||
                    currentStatus === VEHICLE_STATUS.API_UNREACHABLE) &&
                    getInfowindowUi(
                        currentStatus,
                        0,
                        0,
                        0,
                        0,
                        getMomentTime(lastUpdatedAt).format(DATE_FORMAT_TIMESTAMP),
                        address,
                        lastUpdatedAt,
                        true,
                        latitude,
                        longitude,
                        showCurrentTime24Hours,
                        showActualTime,
                        isOnlyAssetTracking,
                        nearestAddress,
                        null,
                        loggedInUser,
                        isSharedTrip,
                        isRequestedFrom
                    )}
                {(currentStatus === VEHICLE_STATUS.DISCONNECTED ||
                    currentStatus === VEHICLE_STATUS.DISCONNECTED_3_DAYS ||
                    currentStatus === VEHICLE_STATUS.DISCONNECTED_4_DAYS ||
                    currentStatus === VEHICLE_STATUS.DISCONNECTED_5_DAYS ||
                    currentStatus === VEHICLE_STATUS.DISCONNECTED_6_DAYS ||
                    currentStatus === VEHICLE_STATUS.WIRING_DEFECT ||
                    currentStatus === VEHICLE_STATUS.WIRING_DEFECT_PARKED ||
                    currentStatus === VEHICLE_STATUS.GPS_DEFECT ||
                    currentStatus === VEHICLE_STATUS.GPS_0 ||
                    currentStatus === VEHICLE_STATUS.REMOVED ||
                    currentStatus === VEHICLE_STATUS.INSHOP ||
                    currentStatus === VEHICLE_STATUS.STANDBY ||
                    currentStatus === VEHICLE_STATUS.NO_POWER ||
                    currentStatus === VEHICLE_STATUS.BATTERY_DISCHARGED) &&
                    getInfowindowUi(
                        currentStatus,
                        0,
                        0,
                        0,
                        0,
                        getMomentTime(lastUpdatedAt).format(DATE_FORMAT_TIMESTAMP),
                        address,
                        lastStatusTime,
                        true,
                        latitude,
                        longitude,
                        showCurrentTime24Hours,
                        showActualTime,
                        isOnlyAssetTracking,
                        nearestAddress,
                        null,
                        loggedInUser,
                        isSharedTrip,
                        isRequestedFrom
                    )}

                {!isSharedTrip && (
                    <div
                        className={`mt-2 fa-hover-dark`}
                        style={{ width: `${isViewportMDOrAbove() ? 'auto' : '250px'}` }}
                    >
                        {!isOnlyAssetTracking &&
                            mapType === MULTPLE_VEHICLES_MAP &&
                            (!isViewportMDOrAbove() || showAllLinksOnMapInfowindow) && (
                                <Link to={`/dashboard/realtime/overview/${vehicleId}/#${suffixDate}`} className="mr-2">
                                    <Button color="secondary" size="sm" title="View latest trip">
                                        <i className="fa fa-clock-o" /> Latest
                                    </Button>
                                </Link>
                            )}
                        {!isOnlyAssetTracking &&
                            mapType === MULTPLE_VEHICLES_MAP &&
                            (!isViewportMDOrAbove() || showAllLinksOnMapInfowindow) && (
                                <Link to={`/dashboard/trips/#${suffixToday}`} className="mr-2">
                                    <Button color="secondary" size="sm" title="View History">
                                        <i className="fa fa-map-o" /> History
                                    </Button>
                                </Link>
                            )}
                        {mapType === MULTPLE_VEHICLES_MAP && (!isViewportMDOrAbove() || showAllLinksOnMapInfowindow) && (
                            <Link to={`/dashboard/vehicles/view/#${suffix}`} className="mr-2">
                                <Button color="secondary" size="sm" title="View vehicle details">
                                    <i className="fa fa-car" /> Details
                                </Button>
                            </Link>
                        )}
                        {mapType === MULTPLE_VEHICLES_MAP && (!isViewportMDOrAbove() || showAllLinksOnMapInfowindow) && (
                            <span
                                onClick={shareVehicleLocation}
                                className="mr-2"
                                data-vehicleid={vehicleId}
                                data-vehiclenumber={vehicleNumber}
                            >
                                <Button color="secondary" size="sm" title="Share Realtime Tracking">
                                    <i className="fa fa-share" /> Share
                                </Button>
                            </span>
                        )}
                        {!isOnlyAssetTracking && mapType === MULTPLE_VEHICLES_MAP && (
                            <span>
                                <Button
                                    color="secondary"
                                    size="sm"
                                    className="mr-2"
                                    title="Nearby Vehicles"
                                    onClick={() => {
                                        showNearbyVehiclesToLatLon(vehicleName, address, latitude, longitude);
                                    }}
                                >
                                    <i className="fa fa-map-marker" /> Nearby
                                </Button>
                            </span>
                        )}
                        <Link to={`/dashboard/routes/addressbook/add#lat=${latitude}&lon=${longitude}`}>
                            <Button color="secondary" size="sm" className="mr-2" title="Add to Addressbook">
                                <i className="fa fa-plus" /> Add Address
                            </Button>
                        </Link>
                        {isJobModuleEnabled && isCreateJobAllowed && (
                            <Link
                                to={`${
                                    isExpressJobFormOpenToUser(loggedInUser)
                                        ? `/dashboard/routes/jobs/expressroadways/add#presetVehicleId=${vehicleId}`
                                        : isDefaultNewJobForm
                                        ? `/dashboard/routes/jobs/v2/add#presetVehicleId=${vehicleId}`
                                        : `/dashboard/routes/jobs/add#presetVehicleId=${vehicleId}`
                                }`}
                            >
                                <Button color="secondary" size="sm" className="mr-2" title="Create Job">
                                    <i className="fa fa-truck" /> Create Job
                                </Button>
                            </Link>
                        )}
                    </div>
                )}
            </div>
        ),
    };
}

export function createSIMMarker(point, vehicle, isOSMEnabled) {
    const deviationFlag = get(point, 'otherAttributes.valid');
    const icon = {
        path: isOSMEnabled ? '' : google.maps.SymbolPath.CIRCLE,
        strokeColor: deviationFlag === 'false' ? '#f86c6b' : '#6495ED',
        fillColor: deviationFlag === 'false' ? '#f86c6b' : '#6495ED',
        strokeOpacity: 0.8,
        fillOpacity: 0.6,
        scale: 3,
    };

    const { lat, lng, index, driverName, deviceId, timeStamp } = point;

    return {
        position: {
            lat,
            lng,
        },
        showInfo: false,
        defaultAnimation: 0,
        key: 'sim-marker' + index,
        icon: isOSMEnabled ? SIM_ICON_FOR_OSM : icon,
        infoContent: (
            <div className="fleetx-default-font-family">
                <div className="font-weight-bold" style={{ color: '#000000', fontSize: '16px' }}>
                    <div className="">{`SIM ping #${index + 1}`} </div>
                </div>

                <div className="mt-1 text-gray medium">
                    {driverName && <span>Driver: {driverName}</span>}
                    {driverName && <span className="mx-1">|</span>}
                    <span>Device: {deviceId}</span>
                </div>
                <div className="text-gray mt-2 medium">
                    <span className="mr-1">Date:</span>
                    {getMomentTime(timeStamp).format(DATE_FORMAT_HUMANIZE_SECONDS)}
                </div>
            </div>
        ),
    };
}

export function createWayPointMarker(point, index) {
    const icon = {
        path: google.maps.SymbolPath.CIRCLE,
        strokeColor: '#FF2222',
        fillColor: '#FFFFFF',
        strokeOpacity: 1,
        strokeWeight: 1.5,
        fillOpacity: 1,
        scale: 4,
    };

    const { latitude, longitude } = point;
    const key = `${latitude}-${longitude}-${index}`;

    return {
        position: {
            lat: latitude,
            lng: longitude,
        },
        draggable: true,
        showInfo: false,
        defaultAnimation: 0,
        key,
        icon: icon,
    };
}

export function createSIMStopMarker(point, index, accesstoken) {
    let icon = image('/public/img/markers/stop-icon.png');

    const { stopStartPoint, stopEndPoint, stopStart } = point;
    const stopStartTime = getMomentTime(get(stopStartPoint, 'timeStamp'));
    const stopEndTime = getMomentTime(get(stopEndPoint, 'timeStamp'));
    const duration = getTimeDiff(stopEndTime.diff(stopStartTime).valueOf(), false, true, true);

    const { lat, lng } = stopStartPoint;
    return {
        position: {
            lat,
            lng,
        },
        showInfo: false,
        defaultAnimation: 0,
        key: 'sim-stop-marker' + stopStart,
        icon: icon,
        infoContent: (
            <div className="fleetx-default-font-family">
                <div className="font-weight-bold" style={{ color: '#000000', fontSize: '16px' }}>
                    <div className="">{`Stop #${index + 1}`} </div>
                </div>

                <div className="mt-1 text-gray medium">{duration && <span>Duration: {duration}</span>}</div>
                <div className="text-gray mt-2 medium">
                    <span className="mr-1">Location:</span>
                    <Button
                        id="markerInfoWindowAddressButton"
                        size="sm"
                        outline
                        color="primary"
                        className="medium"
                        onClick={() => {
                            getAddressForMarkerInfoButton(
                                accesstoken,
                                lat,
                                lng,
                                `markerInfoWindowAddressText`,
                                'markerInfoWindowAddressButton'
                            );
                        }}
                    >
                        Show Address
                    </Button>
                    <div className="medium d-inline-block" id="markerInfoWindowAddressText"></div>
                </div>
                <div className="text-gray mt-2 medium">
                    <span className="my-1">
                        Lat: {round(lat, 6)}, Lon: {round(lng, 6)}
                    </span>
                </div>
                <div className="text-gray mt-2 medium">
                    <a
                        target="_blank"
                        href={getGoogleMapLink(lat, lng)}
                        style={{ color: '#427fed', fontWeight: '400', fontSize: '11px' }}
                    >
                        <span>View on Google Maps</span>
                    </a>
                </div>
            </div>
        ),
    };
}

export function createSIMStopBackendMarker(point, index, accesstoken) {
    let icon = image('/public/img/markers/stop-icon.png');

    const { stoppageFromTime, stoppageToTime, lat, lon } = point;
    const stopStartTime = getMomentTime(stoppageFromTime, false, DATE_FORMAT_TIME_24);
    const stopEndTime = getMomentTime(stoppageToTime, false, DATE_FORMAT_TIME_24);
    const duration = getTimeDiff(stopEndTime.diff(stopStartTime).valueOf(), false, true, true);

    return {
        position: {
            lat: +lat,
            lng: +lon,
        },
        showInfo: false,
        defaultAnimation: 0,
        key: 'sim-stop-marker' + stoppageFromTime,
        icon: icon,
        infoContent: (
            <div className="fleetx-default-font-family">
                <div className="font-weight-bold" style={{ color: '#000000', fontSize: '16px' }}>
                    <div className="">{`Stop #${index + 1}`} </div>
                </div>

                <div className="mt-1 text-gray medium">{duration && <span>Duration: {duration}</span>}</div>
                <div className="text-gray mt-2 medium">
                    <span className="mr-1">Location:</span>
                    <Button
                        id="markerInfoWindowAddressButton"
                        size="sm"
                        outline
                        color="primary"
                        className="medium"
                        onClick={() => {
                            getAddressForMarkerInfoButton(
                                accesstoken,
                                lat,
                                lon,
                                `markerInfoWindowAddressText`,
                                'markerInfoWindowAddressButton'
                            );
                        }}
                    >
                        Show Address
                    </Button>
                    <div className="medium d-inline-block" id="markerInfoWindowAddressText"></div>
                </div>
                <div className="text-gray mt-2 medium">
                    <span className="my-1">
                        Lat: {round(lat, 6)}, Lon: {round(lon, 6)}
                    </span>
                </div>
                <div className="text-gray mt-2 medium">
                    <a
                        target="_blank"
                        href={getGoogleMapLink(lat, lon)}
                        style={{ color: '#427fed', fontWeight: '400', fontSize: '11px' }}
                    >
                        <span>View on Google Maps</span>
                    </a>
                </div>
            </div>
        ),
    };
}

export function getNormalMarkerRealTime(
    loggedInUser,
    mapType,
    highlightedVehicleId,
    vehicleId,
    latitude,
    longitude,
    currentStatus,
    speed,
    lastUpdatedAt,
    lastStatusTime,
    vehicleName,
    durationEngineOn,
    vehicleNumber,
    driverName,
    address,
    course,
    tripOdometer,
    idleDuration,
    index,
    isSharedTrip,
    shareVehicleLocation,
    playbackState,
    timeStamp,
    distanceFromLocation,
    etaFromLocation,
    showNearbyVehiclesToLatLon,
    isJobModuleEnabled,
    addressBook,
    groupName,
    isRealtime,
    vehiclesLength,
    hideVehicleName = false,
    showAllLinksOnMapInfowindow = false,
    isBajaj = false,
    isNSL = false,
    isExpressRoadways = false,
    isOnlyAssetTracking = false,
    showFullName = false,
    groupId,
    jobCustomFields = [],
    otherJobAttributes = {},
    deviceId = '',
    originActualDeparture = '',
    showCreateJob,
    jobName,
    vehicleTypeValue,
    tagIds,
    handleMarkerClose,
    vehicleToNearestAddressMap,
    consignor,
    showLicensePlateInsteadOfNameInRealtime = false,
    isOSMEnabled,
    markers,
    addressBookAsQT,
    addressBookAsMap,
    vehicleAlertIssues,
    login,
    tags
) {
    let icon;
    if (mapType === MULTPLE_VEHICLES_MAP) {
        icon = image(
            '/public/img/markers/' +
                (isRunning(currentStatus) ? 'green' : 'red') +
                '_Marker' +
                CAPITAL_LETTERS[index] +
                '.png'
        );
    } else {
        icon = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    }
    const angle = getPointerAngle(course);
    const suffixDate = getUrlSuffix(false);
    const suffix = `vehicleId=${vehicleId}${suffixDate ? `&${suffixDate}` : ''}`;
    let suffixToday = `vehicleId=${vehicleId}&startDate=${getMomentTime().startOf('day')}   `;
    const showDeviceId = showDeviceIdOnMarker(loggedInUser);
    const showCurrentTime24Hours = isBajaj;
    const showActualTime = isNSL;

    let nearestAddress = null;
    if (isQuadTreeSearchOpenForAccount()) {
        nearestAddress = getNearestAddressFromQT(
            latitude,
            longitude,
            addressBookAsQT,
            addressBookAsMap,
            addressBook,
            loggedInUser
        );
    }

    const isDefaultNewJobForm = isDefaultNewJobFormForAccount(loggedInUser);

    let tripStatus;
    if (showTagIdsToAccount(loggedInUser)) {
        tripStatus = getTripStatusFromTagIds(tagIds, rmcTripStatusNames);
    }
    const showInfo = defaultHideInfoWindowRealtimeForAccount() ? false : highlightedVehicleId === vehicleId;

    const digitsToShow = isZHPOPPELTDAccount(loggedInUser) ? 7 : '';

    const markerData = find(markers, (marker) => marker.key === `marker${vehicleId}`);
    let suffixTodayNew = redirectToCustomViewFromRealtimeHistory({
        loggedInUser,
        vehicleId,
    });
    const isRouteDeviationIssue = find(
        vehicleAlertIssues?.openIssuesCount,
        (issue) => issue.subType === 'Route Deviation'
    );
    const labelText = showLicensePlateInsteadOfNameInRealtime
        ? vehicleNumber
        : showFullName
        ? vehicleName
        : getVehicleNumberDigits(vehicleNumber, digitsToShow);

    return {
        position: {
            lat: latitude,
            lng: longitude,
        },
        defaultAnimation: 0,
        key: 'marker' + vehicleId,
        hasAddress: !!address,
        address: address,
        icon: icon,
        arrowIcon: true,
        course: angle,
        speed: speed,
        currentStatus: currentStatus,
        vehicleId: vehicleId,
        groupId: groupId,
        vehicleNumber: vehicleNumber,
        vehicleTypeValue: vehicleTypeValue,
        showInfo: showInfo,
        labelText: isRouteDeviationIssue ? (
            <>
                {labelText}
                {'  '}
                {isRouteDeviationIssue && <i className="fa fa-exclamation-triangle text-danger" />}
            </>
        ) : (
            labelText
        ),
        infoContent:
            highlightedVehicleId == vehicleId ? (
                <div className="fleetx-default-font-family">
                    <div className="font-weight-bold" style={{ color: '#000000', fontSize: '16px' }}>
                        {!hideVehicleName && (
                            <div
                                className={
                                    isVehicleNameColorBasedOnStatus(loggedInUser)
                                        ? getClassNameForStatus(currentStatus, speed, durationEngineOn)
                                        : ''
                                }
                            >
                                <span className="mr-1">{vehicleName}</span>
                                <span>{vehicleNumber}</span>
                            </div>
                        )}
                        {hideVehicleName && <span>{vehicleNumber}</span>}
                    </div>
                    <div className="mt-1 text-gray medium">
                        {isRealtime && groupName && <span>Group: {groupName}</span>}
                        {isRealtime && groupName && driverName && <span className="mx-1">|</span>}
                        {driverName && <span>Driver: {driverName}</span>}
                        {showDeviceId &&
                            playbackState === PLAYBACK_STATE.STOPPED &&
                            ((isRealtime && groupName) || driverName) && <span className="mx-1">|</span>}
                        {showDeviceId && playbackState === PLAYBACK_STATE.STOPPED && <span>Device: {deviceId}</span>}
                    </div>
                    {showJobStartDate(loggedInUser) &&
                        get(otherJobAttributes, 'startDate') &&
                        playbackState === PLAYBACK_STATE.STOPPED && (
                            <div className="text-gray mt-1 medium">
                                <span className="mr-1">Job Start Date:</span>
                                {getMomentTime(get(otherJobAttributes, 'startDate')).format(
                                    DATE_FORMAT_HUMANIZE_SECONDS
                                )}
                            </div>
                        )}
                    {lastUpdatedAt && !showCurrentTime24Hours && playbackState === PLAYBACK_STATE.STOPPED && (
                        <div className="mt-1">
                            <div
                                className="mr-1 d-inline-block"
                                style={{
                                    width: '.5rem',
                                    height: '.5rem',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(239, 91, 91,.9)',
                                }}
                            />{' '}
                            <span className="mr-1 text-gray medium">Last updated:</span>
                            <span className="text-gray medium">{getHumanizeTime(lastUpdatedAt, true)}</span>
                        </div>
                    )}
                    {!!(etaFromLocation && distanceFromLocation) && (
                        <div className="card mt-1 mx-0 text-gray medium">
                            <div className="card-block p-2">
                                <div>
                                    <span className="">Distance: {getTripOdo(distanceFromLocation / 1000)}</span>
                                    <span className="ml-3">ETA: {getTimeDiffHours(etaFromLocation * 1000)}</span>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    )}
                    {isOSMEnabled ? '' : <hr />}
                    {(currentStatus === VEHICLE_STATUS.RUNNING || currentStatus === VEHICLE_STATUS.IDLE) &&
                        getInfowindowUi(
                            currentStatus,
                            durationEngineOn,
                            speed,
                            idleDuration,
                            tripOdometer,
                            timeStamp,
                            address,
                            null,
                            true,
                            latitude,
                            longitude,
                            showCurrentTime24Hours,
                            showActualTime,
                            isOnlyAssetTracking,
                            nearestAddress,
                            false,
                            loggedInUser,
                            tripStatus,
                            null,
                            null,
                            isRealtime,
                            null,
                            {
                                attachedTags: tagIds,
                                tags,
                            }
                        )}
                    {(currentStatus === VEHICLE_STATUS.PARKED ||
                        currentStatus === VEHICLE_STATUS.PARKED_10_DAYS ||
                        currentStatus == VEHICLE_STATUS.IMMOBILISED) &&
                        getInfowindowUi(
                            currentStatus,
                            0,
                            0,
                            0,
                            0,
                            timeStamp,
                            address,
                            lastStatusTime,
                            true,
                            latitude,
                            longitude,
                            showCurrentTime24Hours,
                            showActualTime,
                            isOnlyAssetTracking,
                            nearestAddress,
                            false,
                            loggedInUser,
                            '',
                            '',
                            tripStatus,
                            isRealtime,
                            null,
                            {
                                attachedTags: tagIds,
                                tags,
                            }
                        )}
                    {(currentStatus === VEHICLE_STATUS.UNREACHABLE ||
                        currentStatus === VEHICLE_STATUS.UNREACHABLE_3_DAYS ||
                        currentStatus === VEHICLE_STATUS.UNREACHABLE_4_DAYS ||
                        currentStatus === VEHICLE_STATUS.UNREACHABLE_5_DAYS ||
                        currentStatus === VEHICLE_STATUS.UNREACHABLE_6_DAYS ||
                        currentStatus === VEHICLE_STATUS.API_UNREACHABLE) &&
                        getInfowindowUi(
                            currentStatus,
                            0,
                            0,
                            0,
                            0,
                            getMomentTime(lastUpdatedAt).format(DATE_FORMAT_TIMESTAMP),
                            address,
                            lastUpdatedAt,
                            true,
                            latitude,
                            longitude,
                            showCurrentTime24Hours,
                            showActualTime,
                            isOnlyAssetTracking,
                            nearestAddress,
                            false,
                            loggedInUser,
                            '',
                            '',
                            tripStatus,
                            isRealtime,
                            null,
                            {
                                attachedTags: tagIds,
                                tags,
                            }
                        )}
                    {(currentStatus === VEHICLE_STATUS.DISCONNECTED ||
                        currentStatus === VEHICLE_STATUS.DISCONNECTED_3_DAYS ||
                        currentStatus === VEHICLE_STATUS.DISCONNECTED_4_DAYS ||
                        currentStatus === VEHICLE_STATUS.DISCONNECTED_5_DAYS ||
                        currentStatus === VEHICLE_STATUS.DISCONNECTED_6_DAYS ||
                        currentStatus === VEHICLE_STATUS.WIRING_DEFECT ||
                        currentStatus === VEHICLE_STATUS.WIRING_DEFECT_PARKED ||
                        currentStatus === VEHICLE_STATUS.GPS_DEFECT ||
                        currentStatus === VEHICLE_STATUS.GPS_0 ||
                        currentStatus === VEHICLE_STATUS.REMOVED ||
                        currentStatus === VEHICLE_STATUS.INSHOP ||
                        currentStatus === VEHICLE_STATUS.STANDBY ||
                        currentStatus === VEHICLE_STATUS.NO_POWER ||
                        currentStatus === VEHICLE_STATUS.BATTERY_DISCHARGED) &&
                        getInfowindowUi(
                            currentStatus,
                            0,
                            0,
                            0,
                            0,
                            getMomentTime(lastUpdatedAt).format(DATE_FORMAT_TIMESTAMP),
                            address,
                            lastStatusTime,
                            true,
                            latitude,
                            longitude,
                            showCurrentTime24Hours,
                            showActualTime,
                            isOnlyAssetTracking,
                            nearestAddress,
                            false,
                            loggedInUser,
                            '',
                            '',
                            tripStatus,
                            isRealtime,
                            null,
                            {},
                            vehicleId,
                            null,
                            {
                                attachedTags: tagIds,
                                tags,
                            }
                        )}
                    {showJobNameKeyForAcnt(loggedInUser) &&
                        jobName &&
                        showKeyOnMarkerStackedLabel(getJobNameKeyForAcnt(loggedInUser), jobName)}
                    {showCustomFieldsOnMarker(loggedInUser) &&
                        playbackState === PLAYBACK_STATE.STOPPED &&
                        getInfoCustomFieldUi(loggedInUser, jobCustomFields, originActualDeparture)}
                    {showOtherFieldsOnMarker(loggedInUser) &&
                        playbackState === PLAYBACK_STATE.STOPPED &&
                        !isEmpty(otherJobAttributes) &&
                        getInfoOtherFieldsUi(loggedInUser, otherJobAttributes)}
                    {((showJobNameKeyForAcnt(loggedInUser) && jobName) ||
                        (showCustomFieldsOnMarker(loggedInUser) && playbackState === PLAYBACK_STATE.STOPPED) ||
                        (showOtherFieldsOnMarker(loggedInUser) &&
                            playbackState === PLAYBACK_STATE.STOPPED &&
                            !isEmpty(otherJobAttributes))) && <hr />}
                    {showJobConsignorOnMarker(loggedInUser) &&
                        consignor &&
                        showKeyOnMarkerStackedLabel('Consignor', consignor)}

                    {vehicleAlertIssues &&
                        isViewIssuesAllowedForUser(get(login, 'scope')) &&
                        showAlarmSubFiltersForAccount() &&
                        renderVehicleAlertIssuesCount(vehicleAlertIssues['openIssuesCount'], vehicleId)}

                    {!isSharedTrip && showServiceReminderDataOnRealtimeMapWindowForAccount() && (
                        <div>
                            <ServiceRemindersForVehicleComponent vehicleId={vehicleId} />
                            <hr />
                        </div>
                    )}

                    {!isSharedTrip && (
                        <div
                            className={isOSMEnabled ? `mt-1` : `mt-0`}
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                width: `${isViewportMDOrAbove() ? 'auto' : '250px'}`,
                            }}
                        >
                            {!isOnlyAssetTracking &&
                                mapType === MULTPLE_VEHICLES_MAP &&
                                (!isViewportMDOrAbove() || showAllLinksOnMapInfowindow) && (
                                    <Link
                                        to={`/dashboard/realtime/overview/${vehicleId}/#${suffixDate}`}
                                        className="mr-2"
                                    >
                                        <div
                                            className="realtime-row-control-button d-inline-block mr-2 cursor-pointer"
                                            title="View latest trip"
                                        >
                                            Latest
                                        </div>
                                    </Link>
                                )}
                            {!isOnlyAssetTracking &&
                                mapType === MULTPLE_VEHICLES_MAP &&
                                (!isViewportMDOrAbove() || showAllLinksOnMapInfowindow) && (
                                    <Link to={`/dashboard/trips/#${suffixToday}`} className="mr-2">
                                        <div
                                            className="realtime-row-control-button d-inline-block mr-2 cursor-pointer"
                                            title="View History"
                                        >
                                            History
                                        </div>
                                    </Link>
                                )}
                            {mapType === MULTPLE_VEHICLES_MAP &&
                                (!isViewportMDOrAbove() || showAllLinksOnMapInfowindow) && (
                                    <Link to={`/dashboard/vehicles/view/#${suffix}`} className="mr-2">
                                        <div
                                            className="realtime-row-control-button d-inline-block mr-2 cursor-pointer"
                                            title="View vehicle details"
                                        >
                                            Details
                                        </div>
                                    </Link>
                                )}
                            {mapType === MULTPLE_VEHICLES_MAP &&
                                (!isViewportMDOrAbove() || showAllLinksOnMapInfowindow) && (
                                    <div
                                        onClick={shareVehicleLocation}
                                        data-vehicleid={vehicleId}
                                        data-vehiclenumber={vehicleNumber}
                                        className="realtime-row-control-button d-inline-block mr-2 cursor-pointer"
                                        title="Share Realtime Tracking"
                                    >
                                        Share
                                    </div>
                                )}
                            {!isOnlyAssetTracking &&
                                mapType === MULTPLE_VEHICLES_MAP &&
                                !isRoleViewerRealtimeHistory(loggedInUser) && (
                                    <div
                                        className="realtime-row-control-button d-inline-block m-1 p-2 cursor-pointer"
                                        title="Nearby Vehicles"
                                        onClick={() => {
                                            showNearbyVehiclesToLatLon(vehicleName, address, latitude, longitude);
                                        }}
                                    >
                                        Nearby
                                    </div>
                                )}
                            {!isRoleViewerRealtimeHistory(loggedInUser) && (
                                <Link
                                    style={{ outline: 'none' }}
                                    to={`/dashboard/routes/addressbook/add#lat=${latitude}&lon=${longitude}`}
                                >
                                    <div
                                        className="realtime-row-control-button d-inline-block m-1 p-2 cursor-pointer"
                                        title="Add to Addressbook"
                                    >
                                        Add Address
                                    </div>
                                </Link>
                            )}
                            {isJobModuleEnabled && showCreateJob && (
                                <Link
                                    to={`${
                                        isExpressJobFormOpenToUser(loggedInUser)
                                            ? `/dashboard/routes/jobs/expressroadways/add#presetVehicleId=${vehicleId}`
                                            : isDefaultNewJobForm
                                            ? `/dashboard/routes/jobs/v2/add#presetVehicleId=${vehicleId}`
                                            : `/dashboard/routes/jobs/add#presetVehicleId=${vehicleId}`
                                    }`}
                                >
                                    <div
                                        className="realtime-row-control-button d-inline-block m-1 p-2 cursor-pointer"
                                        title="Create Job"
                                    >
                                        Create Job
                                    </div>
                                </Link>
                            )}
                            {isTripsVisible(get(login, 'scope')) && (
                                <Link
                                    to={`/dashboard/trips/#${suffixTodayNew}`}
                                    target={openHistoryFromRealtimeInNewTab()}
                                >
                                    <div
                                        className="realtime-row-control-button d-inline-block m-1 p-2 cursor-pointer"
                                        title="History"
                                    >
                                        {t('history')}
                                    </div>
                                </Link>
                            )}
                        </div>
                    )}
                    {/* {!isOSMEnabled && (
                    <div>
                        <span
                            className="medium cursor-pointer"
                            style={{ float: 'right' }}
                            title="Create Job"
                            onClick={() => handleMarkerClose(vehicleId)}
                        >
                            <i className="fa fa-close" /> Close
                        </span>
                    </div>
                )} */}
                </div>
            ) : (
                get(markerData, 'infoContent', null)
            ),
    };
}

export function getSpeedMoveMarker(geoPoint) {
    return {
        position: {
            lat: geoPoint.latitude,
            lng: geoPoint.longitude,
        },
        icon: image('/public/img/markers/green-dot.png'),
        isSpeedGraphMarker: true,
    };
}

export function getIdleMarker(motionPoint, tripSDate, timeWithDate, isShared, isOSMEnabled, moreConfig = {}) {
    const { onViewPanoramaStreetViewClick } = moreConfig || {};
    // const isHaltMarker = motionPoint.status === VEHICLE_STATUS.PARKED;
    const isHaltMarker = false;
    const imgIdle = image('/public/img/markers/stop-orange-icon.png');
    const imgHalt = image('/public/img/markers/stop-icon.png');
    const sDate = getMomentTime(motionPoint.sDate);
    const eDate = getMomentTime(motionPoint.eDate);
    const tripItemId = sDate.valueOf();

    let originalIconFinal = isHaltMarker ? imgHalt : imgIdle;
    let iconFinal = isHaltMarker ? imgHalt : imgIdle;

    originalIconFinal = isOSMEnabled ? getOSMCustomIconForImage(originalIconFinal) : originalIconFinal;
    iconFinal = isOSMEnabled ? getOSMCustomIconForImage(iconFinal) : iconFinal;

    return {
        tripItemId: tripItemId,
        idleMarkerTripItemId: tripSDate ? tripSDate.valueOf() : tripItemId, // prevTripItemId: prevSDate ?
        // prevSDate.valueOf() : null,
        position: {
            lat: motionPoint.sLat,
            lng: motionPoint.sLon,
        },
        defaultAnimation: 0,
        key: 'trip-idle-' + tripItemId,
        original_icon: originalIconFinal,
        icon: iconFinal,
        showInfo: false,
        infoContent: (
            <div>
                <div style={{ color: '#212121' }} className="mt-1">
                    <b>VEHICLE {isHaltMarker ? 'STOPPED' : 'IDLE'}</b>
                </div>
                <div className="mt-3">
                    <div className="d-inline-block mr-4">
                        <div className="info-window-item-label">FROM</div>
                        <div>{timeWithDate ? getHumanizeTime(sDate) : getHumanizeTimeOnly(sDate)}</div>
                    </div>

                    <div className="d-inline-block  mr-4">
                        <div className="info-window-item-label">TO</div>
                        <div>{timeWithDate ? getHumanizeTime(eDate) : getHumanizeTimeOnly(eDate)}</div>
                    </div>

                    {eDate.valueOf() > sDate.valueOf() && (
                        <div className="d-inline-block">
                            <div className="info-window-item-label">DURATION</div>
                            <div>{getTimeDiff(eDate.diff(sDate).valueOf(), false, true)}</div>
                        </div>
                    )}
                </div>
                <div>
                    <div className="info-window-item-label mt-3">LOCATION</div>
                    <div id={`markerIdleInfoWindowAddressText-${tripItemId}`}>Updating...</div>
                </div>
                {!isShared && (
                    <FxSpace className={'mt-2'}>
                        <Link to={`/dashboard/routes/addressbook/add#lat=${motionPoint.sLat}&lon=${motionPoint.sLon}`}>
                            <Button color="secondary" size="sm" className="mr-2" title="Add to Addressbook">
                                <i className="fa fa-plus" /> Add Address
                            </Button>
                        </Link>
                        {!!onViewPanoramaStreetViewClick && (
                            <div className="text-right my-2">
                                <FxButton
                                    icon={<i className="fa fa-street-view" />}
                                    className={'mr-2'}
                                    onClick={() =>
                                        onViewPanoramaStreetViewClick({
                                            latitude: motionPoint.sLat,
                                            longitude: motionPoint.sLon,
                                        })
                                    }
                                >
                                    View Street View
                                </FxButton>
                            </div>
                        )}
                    </FxSpace>
                )}
            </div>
        ),
    };
}

export function getHaltMarker(
    point,
    index,
    sDate,
    eDate,
    address,
    isLast,
    prevSDate,
    isStoppageView,
    forceIcon,
    isStartAddressSaved,
    isShared,
    showHaltNo = false,
    isOSMEnabled,
    currentOdo = null,
    showLastAsDestination = false,
    optimizedMergedTripsDataEnabled = false,
    moreConfig = {}
) {
    const { isTimelineStartTripRunning, showStartAsSource, showStopAsVehicleIconAMarker } = moreConfig || {};
    let imgLast = image(
        showLastAsDestination ? '/public/img/markers/destination.png' : '/public/img/markers/stop-large-icon.png'
    );
    let imgFirst = image('/public/img/markers/start-large-icon.png');
    let imgMiddle = image('/public/img/markers/stop-icon.png');

    if (isStartAddressSaved) {
        imgLast = image(
            showLastAsDestination
                ? '/public/img/markers/destination.png'
                : '/public/img/markers/stop-green-large-icon.png'
        );
        imgFirst = image('/public/img/markers/start-large-icon.png');
        imgMiddle = image('/public/img/markers/stop-green-icon.png');
    }
    if (showStopAsVehicleIconAMarker) {
        let userFleetType = getUserFleetType(window.FLEETX_LOGGED_IN_USER);
        let vehicleImage = getMarkerImageBasedOnVehicleTypeAndStatus(userFleetType, VEHICLE_STATUS.DISCONNECTED);
        imgLast = vehicleImage ? vehicleImage : imgLast;
        imgMiddle = vehicleImage ? vehicleImage : imgMiddle;
        imgFirst = vehicleImage ? vehicleImage : imgFirst;
    }
    const originalIcon = isStoppageView ? imgMiddle : isLast ? imgLast : index === 0 ? imgFirst : imgMiddle;

    const stopNo =
        showHaltNo && (index === 0 ? !isTimelineStartTripRunning : true)
            ? `#${isTimelineStartTripRunning ? index : index + 1}`
            : '';

    if (eDate.valueOf() < sDate.valueOf()) {
        if (index === 0) {
            sDate = eDate;
        } else {
            eDate = sDate;
        }
    }

    const originalIconFinal = isOSMEnabled ? getOSMCustomIconForImage(originalIcon, [24, 24]) : originalIcon;
    const imgMiddleFinal = isOSMEnabled ? getOSMCustomIconForImage(imgMiddle, [24, 24]) : imgMiddle;

    const getHeading = () => {
        let label = `VEHICLE STOPPED ${stopNo}`;
        if (showLastAsDestination) {
            label = 'Timeline End Point';
        }
        if (showStartAsSource) {
            label = 'Timeline Start Point';
        }
        return <b>{label}</b>;
    };

    return {
        tripItemId: sDate.valueOf(),
        nextTripItemId: eDate.valueOf(),
        prevTripItemId: prevSDate ? prevSDate.valueOf() : null,
        position: {
            lat: point.latitude,
            lng: point.longitude,
        },
        defaultAnimation: 0,
        key: 'trip-stop-' + index,
        original_icon: originalIconFinal,
        icon: forceIcon ? imgMiddleFinal : null,
        labelText: stopNo,
        infoContent: (
            <div>
                <div style={{ color: '#212121' }} className="mt-1">
                    {getHeading()}
                </div>
                {eDate.diff(sDate) > 0 && !showLastAsDestination && (
                    <div className="mt-3">
                        <div className="d-inline-block mr-4">
                            <div className="info-window-item-label">FROM</div>
                            <div>{getHumanizeTimeOnly(sDate)}</div>
                            <div>{sDate.format(DATE_FORMAT_HUMANIZE_DAY_MONTH)}</div>
                        </div>

                        <div className="d-inline-block  mr-4">
                            <div className="info-window-item-label">TO</div>
                            <div>{getHumanizeTimeOnly(eDate)}</div>
                            <div>{eDate.format(DATE_FORMAT_HUMANIZE_DAY_MONTH)}</div>
                        </div>
                        <div className="d-inline-block">
                            <div className="info-window-item-label">DURATION</div>
                            <div>{getTimeDiff(eDate.diff(sDate).valueOf(), false, true)}</div>
                        </div>
                    </div>
                )}

                {currentOdo && !optimizedMergedTripsDataEnabled && (
                    <div className="info-window-item-label mt-3">DISTANCE TRAVELLED</div>
                )}
                {currentOdo && !optimizedMergedTripsDataEnabled && <div>{`${currentOdo}`}</div>}

                {address && <div className="info-window-item-label mt-3">LOCATION</div>}
                {address && <div>{parseAddress(address)}</div>}
                {(eDate.diff(sDate) <= 0 || showLastAsDestination) && (
                    <div className="info-window-item-label mt-3">{getHumanizeTime(sDate, true)}</div>
                )}
                <div>
                    {!isStartAddressSaved && !isShared && (
                        <Link to={`/dashboard/routes/addressbook/add#lat=${point.latitude}&lon=${point.longitude}`}>
                            <Button color="secondary" size="sm" className="mr-2 mt-3" title="Add to Addressbook">
                                <i className="fa fa-plus" /> Add Address
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
        ),
    };
}

export function getPositionForAddressBook(address) {
    return address.latitude && address.longitude
        ? {
              lat: address.latitude,
              lng: address.longitude,
          }
        : null;
}

export function getTripDistanceViaLatLon(routes) {
    let distance = 0;
    let lastLat = 0,
        lastLon = 0;
    map(routes, (r, index) => {
        if (r) {
            if (lastLat != 0 && lastLon != 0) {
                distance += pythagorasEquirectangular(lastLat, lastLon, r.latitude, r.longitude);
            }
            lastLat = r.latitude;
            lastLon = r.longitude;
        }
    });
    distance = round(distance, 2);
    return distance;
}

export function addSlidingMarkersScript() {
    if (typeof window.SlidingMarker !== 'undefined') {
        window.SlidingMarker.initializeGlobally();
    } else {
        var s1 = document.createElement('script');
        s1.setAttribute(
            'src',
            'https://cdnjs.cloudflare.com/ajax/libs/marker-animate-unobtrusive/0.2.8/vendor/markerAnimate.js'
        );
        s1.onload = () => {
            var s2 = document.createElement('script');
            s2.setAttribute(
                'src',
                'https://cdnjs.cloudflare.com/ajax/libs/marker-animate-unobtrusive/0.2.8/SlidingMarker.min.js'
            );
            s2.onload = () => {
                window.SlidingMarker.initializeGlobally();
            };
            document.body.appendChild(s2);
        };
        document.body.appendChild(s1);
    }
}

export function getFilteredTripsPoints(
    allUnSnappedTripPoints,
    checkDeviceIdsInRoutes,
    skipConcoxHeartbeat = true,
    skipMergedTrips,
    isSourceSIM
) {
    const unSnappedTripPoints = filter(allUnSnappedTripPoints, ['type', 'GPS']);
    const length = unSnappedTripPoints ? unSnappedTripPoints.length : 0;
    let failLarge = false;
    const sums = {
        both0: 0,
        failLargeStart: 0,
        failLarge: 0,
        speedDiff: 0,
        other: 0,
    };
    if (find(unSnappedTripPoints, ['otherAttributes.repaired', 'true'])) {
        console.log('Repaired trip found, skipping filter');
        return unSnappedTripPoints;
    }
    const filteredTripsPoints = filter(unSnappedTripPoints, (point, index) => {
        if (checkDeviceIdsInRoutes && !get(point, 'deviceId')) {
            return false;
        }
        if (skipConcoxHeartbeat && get(point, 'otherAttributes.iccid')) {
            //Skipping concox hearbeat packets
            return false;
        }
        if (index === 0 || index === length - 1) {
            return true;
        }
        if (point.type !== 'GPS') {
            //Redundant condition
            return false;
        }

        const diffPrev = pythagorasEquirectangular(
            point.latitude,
            point.longitude,
            unSnappedTripPoints[index - 1].latitude,
            unSnappedTripPoints[index - 1].longitude
        );
        const timeDiffPrev = getMomentTime(point.timeStamp).diff(unSnappedTripPoints[index - 1].timeStamp, 'minutes');
        const diffNext = pythagorasEquirectangular(
            point.latitude,
            point.longitude,
            unSnappedTripPoints[index + 1].latitude,
            unSnappedTripPoints[index + 1].longitude
        );

        const speedPrev = unSnappedTripPoints[index - 1].speed;
        const speedNext = unSnappedTripPoints[index + 1].speed;
        const speed = point.speed;

        const deviceType = getDeviceTypeFromDeviceId(point.deviceId);
        let gpsFilterLimitHigh = GPS_FILTER_LIMIT_HIGH;
        if (deviceType && deviceType.type === DEVICE_TYPE.API_DEVICE.type) {
            gpsFilterLimitHigh = GPS_FILTER_LIMIT_HIGH_API;
        }
        if (
            deviceType &&
            (deviceType.type === DEVICE_TYPE.MZCW4.type ||
                deviceType.type === DEVICE_TYPE.GPS_LOCK.type ||
                deviceType.type === DEVICE_TYPE.G300_LOCK.type)
        ) {
            gpsFilterLimitHigh = GPS_FILTER_LIMIT_HIGH_SUPER;
        }
        if ((deviceType && deviceType.type === DEVICE_TYPE.SIM.type) || isSourceSIM) {
            gpsFilterLimitHigh = GPS_FILTER_LIMIT_SIM;
        }
        if (skipMergedTrips) {
            gpsFilterLimitHigh = GPS_FILTER_LIMIT_HIGH_SUPER;
        }
        if (diffPrev == 0 && diffNext == 0) {
            sums.both0++;
            return ignoreLatLngDiffInMotionPointCalculation();
        } else if (diffPrev > 500 || (diffPrev > 100 && timeDiffPrev < 10)) {
            failLarge = !failLarge;
            sums.failLargeStart++;
            return false;
        } else if (failLarge) {
            sums.failLarge++;
            return false;
        } else if (diffPrev > GPS_FILTER_LIMIT_LOW && diffPrev <= gpsFilterLimitHigh) {
            return true;
        } else if (diffNext > GPS_FILTER_LIMIT_LOW && diffNext <= gpsFilterLimitHigh) {
            return true;
        } else if (
            point.speed !== unSnappedTripPoints[index - 1].speed ||
            point.speed !== unSnappedTripPoints[index + 1].speed
        ) {
            // disabling this as it failed in this case
            // https://www.fleetx.io/dashboard/trips/2768/7d146276-6a86-4e74-b603-0fb136aad77a
            // https://www.fleetx.io/dashboard/trips/26/0bdeaf47-e973-4cf9-8345-02ac45158002/
            sums.speedDiff++;
            return false;
        } else {
            sums.Other++;
            return false;
        }
    });
    //console.log(sums);
    return filteredTripsPoints;
}

export function getFirstLastTripPoints(metaData, liveData) {
    const {
        vehicleId,
        sLat,
        sLon,
        sDate,
        eLat,
        eLon,
        eDate,
        latitude,
        longitude,
        currentStatus,
        status,
        lastStatusTime,
        fuelType,
        duration,
        durationEngineOn,
        driverName,
        deviceId,
    } = metaData;
    const points = [];

    if (sLat && sLon) {
        points.push(
            getPointFromData(
                vehicleId,
                sLat,
                sLon,
                sDate,
                'Ignition On',
                fuelType,
                false,
                0,
                VEHICLE_STATUS.RUNNING,
                driverName,
                deviceId
            )
        );
        if (eLat && eLon && metaData.status && !isEngineOn(get(liveData, 'currentStatus'))) {
            points.push(
                getPointFromData(
                    vehicleId,
                    eLat,
                    eLon,
                    eDate,
                    'Ignition On',
                    fuelType,
                    false,
                    duration,
                    VEHICLE_STATUS.RUNNING,
                    driverName,
                    deviceId
                )
            );
        }
    } else if (latitude && longitude) {
        points.push(
            getPointFromData(
                vehicleId,
                latitude,
                longitude,
                getMomentTime(lastStatusTime).format(DATE_FORMAT_TIMESTAMP),
                status,
                fuelType,
                false,
                durationEngineOn,
                currentStatus,
                driverName,
                deviceId
            )
        );
    }
    return points;
}

export function getPointFromData(
    vehicleId,
    latitude,
    longitude,
    timeStamp,
    status,
    fuelType,
    isSnappedPoint,
    durationEngineOn,
    currentStatus,
    driverName,
    deviceId
) {
    const object = {
        deviceId,
        vehicleId,
        latitude,
        longitude,
        timeStamp,
        status,
        fuelType,
        isSnappedPoint,
        durationEngineOn,
        currentStatus,
        driverName,
        speed: 0,
        mileage: 0,
        currentOdometer: 0,
        currentFuelConsumption: 0,
        type: 'GPS',
    };
    set(object, 'otherAttributes.course', 0);
    return object;
}

export function centerMap(_mapComponent, position, zoomLevel) {
    if (position && _mapComponent) {
        // _mapComponent.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(
        //     position);
        // _mapComponent.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.panTo(position);
        _mapComponent.panTo(position);
    }

    if (zoomLevel && zoomLevel > 0 && _mapComponent) {
        _mapComponent.setZoom(zoomLevel);
    }
}

export function getMarkerColor(currentStatus) {
    switch (toUpper(currentStatus)) {
        case VEHICLE_STATUS.RUNNING:
            return '#4DBD74';
        case VEHICLE_STATUS.IDLE:
            return '#FFC107';
        case VEHICLE_STATUS.UNREACHABLE:
        case VEHICLE_STATUS.UNREACHABLE_3_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_4_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_5_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_6_DAYS:
        case VEHICLE_STATUS.API_UNREACHABLE:
        case VEHICLE_STATUS.WIRING_DEFECT:
        case VEHICLE_STATUS.WIRING_DEFECT_PARKED:
        case VEHICLE_STATUS.GPS_DEFECT:
        case VEHICLE_STATUS.GPS_0:
        case VEHICLE_STATUS.DISCONNECTED_3_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_4_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_5_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_6_DAYS:
        case VEHICLE_STATUS.DISCONNECTED:
        case VEHICLE_STATUS.IMMOBILISED:
        case VEHICLE_STATUS.INSHOP:
        case VEHICLE_STATUS.STANDBY:
        case VEHICLE_STATUS.NO_POWER:
        case VEHICLE_STATUS.BATTERY_DISCHARGED:
            return '#F86C6B';
        case VEHICLE_STATUS.REMOVED:
            return '#777777';
        case VEHICLE_STATUS.PARKED:
        case VEHICLE_STATUS.PARKED_10_DAYS:
        default:
            return DEFAULT_ARROW_ICON.fillColor;
    }
}

export function getMarkerImageCustomBaseURLForAccount(vType, vStatus, angle) {
    let relativeUrl = '/public/img/markers/vehicle/custom/';
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', null);
    switch (accountId) {
        case FLEETX_ACCOUNTS.JKLC:
            return `${relativeUrl}${accountId}`;
        default:
            return null;
    }
}

export function getMarkerImageBasedOnVehicleTypeAndStatus(vType, vStatus, angle, config = {}) {
    const { serviceReminderDues = null } = config || {};
    let type_prefix, status_prefix;
    let relativeUrl = '/public/img/markers/vehicle/';

    let angleNeeded = true;

    switch (toUpper(vStatus)) {
        case VEHICLE_STATUS.RUNNING:
            status_prefix = 'running';
            break;
        case VEHICLE_STATUS.IDLE:
            status_prefix = 'idle';
            break;
        case VEHICLE_STATUS.UNREACHABLE:
        case VEHICLE_STATUS.UNREACHABLE_3_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_4_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_5_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_6_DAYS:
        case VEHICLE_STATUS.API_UNREACHABLE:
        case VEHICLE_STATUS.WIRING_DEFECT:
        case VEHICLE_STATUS.WIRING_DEFECT_PARKED:
        case VEHICLE_STATUS.GPS_DEFECT:
        case VEHICLE_STATUS.GPS_0:
        case VEHICLE_STATUS.DISCONNECTED:
        case VEHICLE_STATUS.IMMOBILISED:
        case VEHICLE_STATUS.INSHOP:
        case VEHICLE_STATUS.STANDBY:
        case VEHICLE_STATUS.NO_POWER:
        case VEHICLE_STATUS.BATTERY_DISCHARGED:
        case VEHICLE_STATUS.DISCONNECTED_3_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_4_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_5_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_6_DAYS:
            status_prefix = 'error';
            break;
        case VEHICLE_STATUS.REMOVED:
            status_prefix = 'removed';
            break;
        case VEHICLE_STATUS.PARKED:
        case VEHICLE_STATUS.PARKED_10_DAYS:
        default:
            status_prefix = 'parked';
    }
    switch (vType) {
        case FLEET_TYPE.CAR:
            type_prefix = 'car';
            break;
        case FLEET_TYPE.BUS:
            type_prefix = 'bus';
            break;
        case FLEET_TYPE.TRUCK:
            type_prefix = 'truck';
            break;
        case FLEET_TYPE.MOTORBIKE:
            type_prefix = 'motorbike';
            break;
        case FLEET_TYPE.MOTORCYCLE:
        case '2W':
        case 'ATV':
            type_prefix = 'motorbike';
            break;
        case FLEET_TYPE.CONCRETE_MIXER:
            type_prefix = 'tmc';
            break;
        case FLEET_TYPE.PUMP:
            type_prefix = 'pump_v2';
            break;
        case FLEET_TYPE.TRACTOR:
            type_prefix = 'tractor';
            break;
        case FLEET_TYPE.TOW_VAN:
            type_prefix = 'tow_v2';
            break;
        case FLEET_TYPE.JCB:
            type_prefix = 'jcb';
            break;
        case FLEET_TYPE.PICKUP:
            type_prefix = 'pickup';
            break;
        case FLEET_TYPE.AUTO:
            type_prefix = 'auto';
            break;
        case FLEET_TYPE.RAKE:
            type_prefix = 'rake';
            break;
        case FLEET_TYPE.TRAILER:
            type_prefix = 'trailer';
            break;
        case FLEET_TYPE.SEDAN:
            type_prefix = 'sedan';
            break;
        case FLEET_TYPE.SUV:
            type_prefix = 'suv';
            break;
        case FLEET_TYPE.MINICAB:
            type_prefix = 'minicab';
            break;
        case FLEET_TYPE.SHOP:
            type_prefix = 'shop';
            status_prefix = 'none';
            angleNeeded = false;
            break;
        case FLEET_TYPE.EXCAVATOR:
            type_prefix = 'excavator';
            break;
        case FLEET_TYPE.TIPPER:
            type_prefix = 'tipper';
            break;
        case FLEET_TYPE.LUXURY_BUS:
            type_prefix = 'luxurybus';
            break;
        case FLEET_TYPE.LUXURY_CAB:
            type_prefix = 'luxurycab';
            break;
        case FLEET_TYPE.MINI_BUS:
            type_prefix = 'minibus';
            break;
        case FLEET_TYPE.URBANIA_BUS:
            type_prefix = 'urbaniabus';
            break;
        default:
            type_prefix = 'truck';
    }

    if (
        showServiceReminderDataOnRealtimeMapWindowForAccount() &&
        showVehicleColorAccordingToServiceReminderForAccount()
    ) {
        if (serviceReminderDues !== null) {
            if (serviceReminderDues.dueSoonCount > 0) {
                status_prefix = 'idle';
            }
            if (serviceReminderDues.overdueCount > 0) {
                status_prefix = 'error';
            }
        } else {
            status_prefix = 'running';
        }
    }

    if (type_prefix && status_prefix) {
        const angleDegrees = round((angle + 47) % 360);
        const customBaseUrl = getMarkerImageCustomBaseURLForAccount(vType, vStatus, angle);
        const baseUrl = customBaseUrl ? customBaseUrl : `${relativeUrl}${type_prefix}-${status_prefix}`;
        let finalUrl = `${baseUrl}-vehicle_marker_base.png`;

        if (angleNeeded) {
            finalUrl = `${finalUrl}#angle=${angleDegrees}deg`;
        }
        return image(finalUrl);
    } else {
        return null;
    }
}

export function getGooglePoint(x, y) {
    if (window.google) {
        return new window.google.maps.Point(x, y);
    } else {
        return { x: 0, y: 0 };
    }
}

export function getGoogleLatLng(lat, lng) {
    if (window.google) {
        return new google.maps.LatLng(lat, lng);
    } else {
        return new google.maps.LatLng(0, 0);
    }
}

export function createJobDeviationMarkers(
    jobDeviations,
    infoWindowPointToShow,
    onJobDeviationMarkerClick,
    onJobDeviationMarkerClose
) {
    const infoWindowKey = infoWindowPointToShow
        ? `${infoWindowPointToShow.deviationPointLat}-${infoWindowPointToShow.deviationPointLon}`
        : '';

    return map(jobDeviations, (point, index) => {
        const key = `${point.deviationPointLat}-${point.deviationPointLon}`;
        const position = {
            lat: point.deviationPointLat,
            lng: point.deviationPointLon,
        };

        return (
            <Marker
                key={key}
                position={position}
                icon={{ url: image('/public/img/markers/deviation-icon-large.png') }}
                onClick={() => onJobDeviationMarkerClick(point)}
            >
                <div>
                    {infoWindowKey === key && (
                        <InfoWindow onCloseClick={() => onJobDeviationMarkerClose(point)} position={position}>
                            <div>
                                <div style={{ color: '#212121' }} className="mt-1">
                                    <b>{parseAddress(point.deviationPointAddress)}</b>
                                </div>
                                <div>{getHumanizeTime(point.deviationTime)}</div>
                                <div>{point.deviationKm} Kms</div>
                            </div>
                        </InfoWindow>
                    )}
                </div>
            </Marker>
        );
    });
}

export function createAlarmMarkers(
    alarmMarkers,
    infoWindowPointToShow,
    onAlarmMarkerClick,
    onAlarmInfoWindowCloseClick,
    showAdditionalAlarmMarkerDetails,
    isOsm
) {
    const infoWindowKey = infoWindowPointToShow
        ? `${infoWindowPointToShow.latitude}-${infoWindowPointToShow.longitude}`
        : '';

    return map(alarmMarkers, (point, index) => {
        const { latitude, longitude, subType, type, distance, duration, maxSpeed } = point;
        const key = `${latitude}-${longitude}`;
        let markerIcon = '';
        let infoWindowText = '';
        const subTypeVal = subType && toUpper(subType);
        const typeVal = type && toUpper(type);
        if (subTypeVal === 'FILL') {
            markerIcon = image('/public/img/markers/fuel-fill-marker.png');
            infoWindowText = 'Fuel Fill';
        } else if (subTypeVal === 'THEFT') {
            markerIcon = image('/public/img/markers/fuel-theft-marker.png');
            infoWindowText = 'Fuel Theft';
        } else {
            markerIcon = image('/public/img/markers/warn-icon.png');
            infoWindowText = typeVal;
        }
        return (
            <Marker
                noRedraw={true}
                icon={{ url: markerIcon }}
                key={`${key}-${index}`}
                position={{ lat: latitude, lng: longitude }}
                anchor={new google.maps.Point(-10, -30)}
                onClick={() => {
                    onAlarmMarkerClick(point);
                }}
            >
                {key === infoWindowKey && (
                    <InfoWindow
                        position={{ lat: latitude, lng: longitude }}
                        onCloseClick={() => onAlarmInfoWindowCloseClick()}
                    >
                        <>
                            <div className="font-weight-bold large mb-2">{infoWindowText}</div>
                            {showAdditionalAlarmMarkerDetails && (
                                <>
                                    <div className="d-inline-block mr-3">
                                        <div className="info-window-item-label">DISTANCE (Km)</div>
                                        <div>{distance ? round(distance, 4) : '-'}</div>
                                    </div>
                                    <div className="d-inline-block mr-3">
                                        <div className="info-window-item-label">DURATION (dd:hh:mm:ss)</div>
                                        <div>{duration ? getTimeDiff(duration, true, false, true) : '-'}</div>
                                    </div>
                                    <div className="d-inline-block mr-3">
                                        <div className="info-window-item-label">MAX SPEED (km/hr)</div>
                                        <div>{maxSpeed ? round(maxSpeed, 2) : '-'}</div>
                                    </div>
                                </>
                            )}
                        </>
                    </InfoWindow>
                )}
            </Marker>
        );
    });
}

const renderTollInfoContent = (
    loggedInUser,
    point,
    index,
    infoWindowKey,
    tollKey,
    latitude,
    longitude,
    key,
    tollExpense
) => {
    return (
        <>
            <div>
                <div>
                    <span className="font-weight-bold large">{point.tollName} Toll</span>
                    {tollKey && (
                        <div className="mt-2">
                            {point.plazaExitTime && renderRowAttribute('Time', getHumanizeTime(point.plazaExitTime))}
                            {renderRowAttribute(
                                'Cost',
                                get(point, 'transactionToll', false) ? get(point, 'cost') : tollExpense,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                getCurrencyFromCountry(loggedInUser, false, true)
                            )}
                            {get(point, 'vehicleDesc') &&
                                renderRowAttribute('Toll Vehicle Size ', get(point, 'vehicleDesc'))}
                        </div>
                    )}
                    {!tollKey && (
                        <div className="mt-2">
                            <span>
                                <b>Rates</b>
                            </span>
                            {renderTollRate('LCM Vehicle', point.lcvrateSingle, loggedInUser)}
                            {renderTollRate('Bus/Truck', point.busRateMulti, loggedInUser)}
                            {renderTollRate('Upto 3 Axle', point.multiAxleRateSingle, loggedInUser)}
                            {renderTollRate('4 to 6 Axle', point.fourToSixExelSingle, loggedInUser)}
                            {renderTollRate('HCM Vehicle', point.hcmemesingle, loggedInUser)}
                            {renderTollRate('7 Or More Axle', point.sevenOrmoreExelSingle, loggedInUser)}
                            <div className="mt-1 mb-4 text-right">
                                <a
                                    target="_blank"
                                    href={`http://tis.nhai.gov.in/TollInformation?TollPlazaID=${point.tollPlazaId}`}
                                >
                                    More Information
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-2">
                    <Link to={`/dashboard/routes/addressbook/add#lat=${latitude}&lon=${longitude}`} target="_blank">
                        <Button color="secondary" size="sm" className="mr-2 medium" title="Add to Addressbook">
                            <i className="fa fa-plus" /> Add Address
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export function createConnectDisconnectMarker(point, index) {
    const {
        powerDisconnectLat,
        powerDisconnectLon,
        powerDisconnectTime,
        powerReconnectLat,
        powerReconnectLon,
        powerReconnectTime,
    } = point;
    const disconnectTime = getMomentTimeFromArray(powerDisconnectTime);
    const reconnectTime = getMomentTimeFromArray(powerReconnectTime);
    const disconnectDuration = Math.abs(disconnectTime.diff(reconnectTime));
    const nextPowerDisconnectTime = get(point, `powerDisconnectTime`);
    let nextDisconnectTime;
    let reconnetDuration;
    if (nextPowerDisconnectTime) {
        nextDisconnectTime = getMomentTimeFromArray(nextPowerDisconnectTime);
        reconnetDuration = Math.abs(nextDisconnectTime.diff(reconnectTime));
    }
    const disconnectIcon = {
        ...TOLL_ICON,
    };

    const disconnectMarker = {
        position: {
            lat: powerDisconnectLat,
            lng: powerDisconnectLon,
        },
        showInfo: false,
        defaultAnimation: 0,
        key: `disconnect-${powerDisconnectLat}-${powerDisconnectLon}`,
        icon: '/public/img/markers/disconnectIcon.svg',
        infoContent: (
            <div className="fleetx-default-font-family">
                <div className="font-weight-bold" style={{ color: '#000000', fontSize: '16px' }}>
                    <div className="">
                        {index + 1}
                        <sup>{getOrdinalSuffix(index + 1)}</sup> Disconnect
                    </div>
                    <div className="font-weight-normal mt-2">
                        {getHumanizeTime(getMomentTimeFromArray(powerDisconnectTime))}
                    </div>
                    <div className="font-weight-normal mt-2">
                        Remain Disconnected: {getTimeDiff(disconnectDuration, false, true)}
                    </div>
                </div>
            </div>
        ),
    };

    const connectMarker = {
        position: {
            lat: powerReconnectLat,
            lng: powerReconnectLon,
        },
        showInfo: false,
        defaultAnimation: 0,
        key: `connect-${powerReconnectLat}-${powerReconnectLon}`,
        icon: '/public/img/markers/connectIcon.svg',
        infoContent: (
            <div className="fleetx-default-font-family">
                <div className="font-weight-bold" style={{ color: '#000000', fontSize: '16px' }}>
                    <div className="">
                        {index + 1}
                        <sup>{getOrdinalSuffix(index + 1)}</sup> Connect
                    </div>
                    <div className="font-weight-normal mt-2">
                        {getHumanizeTime(getMomentTimeFromArray(powerReconnectTime))}
                    </div>
                    <div className="font-weight-normal mt-2">
                        Remain Connected: {getTimeDiff(reconnetDuration, false, true)}
                    </div>
                </div>
            </div>
        ),
    };

    return [connectMarker, disconnectMarker];
}

export function createTollMarkers(
    tollMarkers,
    infoWindowPointToShow,
    onTollMarkerClick,
    onTollInfoWindowCloseClick,
    vehicleType,
    loggedInUser,
    isOsmEnable,
    CustomMarker
) {
    const infoWindowKey = infoWindowPointToShow
        ? `${infoWindowPointToShow.latitude}-${infoWindowPointToShow.longitude}`
        : '';

    const tollKey = get(vehicleType, 'tollKey', null);

    return map(tollMarkers, (point, index) => {
        const { latitude, longitude } = point;
        const key = `${latitude}-${longitude}`;
        const tollExpense = get(point, tollKey, 'NA');

        if (isOsmEnable) {
            return (
                <CustomMarker
                    noRedraw={true}
                    icon={TOLL_ICON_FOR_OSM}
                    key={`${key}-${index}`}
                    openPopup={key === infoWindowKey}
                    position={{ lat: latitude, lng: longitude }}
                    onClick={() => {
                        onTollMarkerClick(point);
                    }}
                    // isTollMarker={true}
                    infoContent={renderTollInfoContent(
                        loggedInUser,
                        point,
                        index,
                        infoWindowKey,
                        tollKey,
                        latitude,
                        longitude,
                        key,
                        tollExpense
                    )}
                ></CustomMarker>
            );
        } else
            return (
                <Marker
                    noRedraw={true}
                    icon={{
                        ...TOLL_ICON,
                    }}
                    key={`${key}-${index}`}
                    position={{ lat: latitude, lng: longitude }}
                    anchor={new google.maps.Point(-10, -30)}
                    onClick={() => {
                        onTollMarkerClick(point);
                    }}
                >
                    {key === infoWindowKey && (
                        <InfoWindow
                            onCloseClick={() => onTollInfoWindowCloseClick()}
                            position={{ lat: latitude, lng: longitude }}
                        >
                            {renderTollInfoContent(
                                loggedInUser,
                                point,
                                index,
                                infoWindowKey,
                                tollKey,
                                latitude,
                                longitude,
                                key,
                                tollExpense
                            )}
                        </InfoWindow>
                    )}
                </Marker>
            );
    });
}

export function createAddressMarkers(
    addressBook,
    infoWindowPointToShow,
    onAddressMarkerClick,
    onAddressInfoWindowCloseClick,
    mapBound,
    showGeofence = false,
    moreOptions = {}
) {
    const { onViewPanoramaStreetViewClick } = moreOptions || {};
    const infoWindowKey = infoWindowPointToShow
        ? `${infoWindowPointToShow.latitude}-${infoWindowPointToShow.longitude}-${infoWindowPointToShow.id}`
        : '';
    const markers = [];
    map(addressBook, (point, index) => {
        const key = `${point.latitude}-${point.longitude}-${point.id}`;
        if (mapBound && !mapBound.contains(new google.maps.LatLng({ lat: point.latitude, lng: point.longitude }))) {
            //do nothing
        } else {
            const coordinates = map(get(point, 'coordinates', null), (c) => {
                return {
                    lat: get(c, 'lat'),
                    lng: get(c, 'lng', get(c, 'lon')),
                };
            });
            markers.push(
                <Marker
                    noRedraw={true}
                    icon={{
                        ...getAddressIcon(point),
                    }}
                    key={`${key}-${index}`}
                    position={{ lat: point.latitude, lng: point.longitude }}
                    anchor={new google.maps.Point(-10, -30)}
                    onClick={() => {
                        onAddressMarkerClick(point);
                    }}
                >
                    {key === infoWindowKey && (
                        <InfoWindow
                            onCloseClick={() => onAddressInfoWindowCloseClick()}
                            position={{ lat: point.latitude, lng: point.longitude }}
                        >
                            <div>
                                <div>
                                    <span className="font-weight-bold large">{point.name}</span>
                                    <div className="mt-2">
                                        <span>{parseAddress(point.address)}</span>
                                        <div className="text-right my-2">
                                            {onViewPanoramaStreetViewClick && (
                                                <FxButton
                                                    icon={<i className="fa fa-street-view" />}
                                                    className={'mr-2'}
                                                    onClick={() => onViewPanoramaStreetViewClick(point)}
                                                >
                                                    View Street View
                                                </FxButton>
                                            )}
                                            <FxButton
                                                icon={<EditOutlined />}
                                                target={'_blank'}
                                                href={`/dashboard/routes/addressbook/edit/${point.id}`}
                                            >
                                                Edit
                                            </FxButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </InfoWindow>
                    )}
                    {showGeofence &&
                        getGeofenceComponent(
                            point.areaType,
                            { lat: point.latitude, lng: point.longitude },
                            point.radius,
                            coordinates,
                            index
                        )}
                </Marker>
            );
        }
    });
    return markers;
}

export function createJobDestinationMarkers(
    vehiclesOnJob,
    infoWindowPointToShow,
    onJobDestinationMarkerClick,
    onJobDestinationMarkerCloseClick,
    mapBound,
    filteredVehicles
) {
    const infoWindowKey = infoWindowPointToShow
        ? `${infoWindowPointToShow.latitude}-${infoWindowPointToShow.longitude}`
        : '';
    const markers = [];
    const filteredVehiclesOnJob = [];

    map(vehiclesOnJob, (vehicleOnJob) => {
        if (find(filteredVehicles, { vehicleId: +vehicleOnJob.vehicleId })) {
            filteredVehiclesOnJob.push(vehicleOnJob);
        }
    });

    const pointsList = [];
    map(filteredVehiclesOnJob, (vehicle, index) => {
        const point = get(vehicle, 'otherAttributes.nextRoutePoint', []);
        if (point) {
            const key = `${point.latitude}-${point.longitude}`;
            if (mapBound && !mapBound.contains(new google.maps.LatLng({ lat: point.latitude, lng: point.longitude }))) {
                //do nothing
            } else {
                if (!find(pointsList, { address: point.address })) {
                    pointsList.push(point);
                    markers.push(
                        <Marker
                            noRedraw={true}
                            icon={{
                                ...PLANT_ICON,
                            }}
                            key={`${key}-${index}`}
                            position={{ lat: point.latitude, lng: point.longitude }}
                            anchor={new google.maps.Point(-10, -30)}
                            onClick={() => {
                                onJobDestinationMarkerClick(point);
                            }}
                        >
                            {key === infoWindowKey && (
                                <InfoWindow
                                    onCloseClick={() => onJobDestinationMarkerCloseClick()}
                                    position={{ lat: point.latitude, lng: point.longitude }}
                                >
                                    <div>
                                        <div>
                                            <span className="font-weight-bold large">{point.name}</span>
                                            <div className="mt-2">
                                                <span>{parseAddress(point.address)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    );
                }
            }
        }
    });
    return markers;
}

function renderTollRate(name, cost, loggedInUser) {
    return (
        <Row>
            <Col xs={6} className="whitespace-nowrap">
                {name}
            </Col>
            <Col xs={1} className="">
                :
            </Col>
            <Col xs={4} className="whitespace-nowrap">
                {cost} {getCurrencyFromCountry(loggedInUser)}
            </Col>
        </Row>
    );
}

export function getGoogleMapLink(lat, lon, isForReport = false, forceAbsolute = false) {
    if (lat && lon) {
        const isMobileView = !isViewportSMOrAbove();
        if (isMobileView && !isForReport) {
            return `https://maps.google.com/maps?q=${lat},${lon}`;
        }
        return `${isForReport ? '' : 'https://'}${
            forceAbsolute && !isForReport ? '//' : ''
        }www.google.com/maps/?q=${round(lat, 6)},${round(lon, 6)}`;
    }
    return '';
}

export function getTripLink(startDate, endDate, vehicleId) {
    const baseURL = window.location.origin;
    return `${baseURL}/dashboard/trips/#startDate=${getMomentTime(startDate).valueOf()}&endDate=${getMomentTime(
        endDate
    ).valueOf()}&vehicleId=${vehicleId}&REDIRECT_TO_TRIP=true`;
}

export function getGoogleMapLinkForTimeline(groupId, vehicleId, startDate, endDate) {
    const baseURL = window.location.origin;
    return `${baseURL}/dashboard/trips#groupId=${groupId}&vehicleId=${vehicleId}&startDate=${getMomentTime(
        startDate
    ).valueOf()}&endDate=${getMomentTime(endDate).valueOf()}&view=TIMELINE`;
}

export function getGoogleMapLinkForWayPoints(jobAnalytics, mergedTripsUnOrdered, jobDistance) {
    let mergedTrips = orderBy(mergedTripsUnOrdered, ['trip.sDate']);
    if (jobAnalytics && jobAnalytics.length > 0 && mergedTrips && mergedTrips.length > 0) {
        let allRoutesPoints = [];
        let filteredWayPointsRoute = [];
        let gapDistance = round(jobDistance / 8, 0);
        let origin;
        let destination;
        let wayPoints = `waypoints=`;

        let mapDirLink = 'https://www.google.com/maps/dir/?api=1&';

        map(mergedTrips, (mergedTrip) => {
            allRoutesPoints.push(...get(mergedTrip, 'routes', []));
        });

        origin = `origin=${get(mergedTrips, '0.trip.sLat')},${get(mergedTrips, '0.trip.sLon')}`;
        destination = `destination=${get(mergedTrips, `${[mergedTrips.length - 1]}.trip.eLat`)},${get(
            mergedTrips,
            `${[mergedTrips.length - 1]}.trip.eLon`
        )}`;

        let lat,
            lon,
            prevLat,
            prevLon,
            odometerTillNow = 0;
        for (let i = 0; i < allRoutesPoints.length; i++) {
            lat = get(allRoutesPoints, `${i}.latitude`);
            lon = get(allRoutesPoints, `${i}.longitude`);

            if (lat && lon && prevLat && prevLon) {
                odometerTillNow = odometerTillNow + pythagorasEquirectangular(lat, lon, prevLat, prevLon);
            }
            if (odometerTillNow >= gapDistance) {
                filteredWayPointsRoute.push(allRoutesPoints[i]);
                odometerTillNow = 0;
            }
            if (i >= 0) {
                prevLat = lat;
                prevLon = lon;
            }
        }

        map(filteredWayPointsRoute, (wayPoint) => {
            wayPoints = wayPoints + `${get(wayPoint, 'latitude')},${get(wayPoint, 'longitude')}|`;
        });

        return `${mapDirLink}&${origin}&${destination}&${wayPoints}`;
    }
}

// For Realtime Master Report, given an object of latitude and longitude returns either mapLink or reverse geocoded limits
export const getGoogleMapLinksForListOfLocations = async (
    accessToken,
    geoCodedLocations,
    progressUpdateCallback,
    loggedInUser,
    vehicleList
) => {
    let allMapLinks = {};
    let mapLink;
    const totalLocationsToFetch = Object.keys(geoCodedLocations).length;
    const fetchGeocodedlocationsAlwaysForReport = fetchGeocodedlocationsAlwaysForReportForAccount(loggedInUser);
    if (fetchGeocodedlocationsAlwaysForReport || totalLocationsToFetch < MAX_LIMIT_FOR_REVERSE_GEOCODE_LOCATION_CALL) {
        const getNewGeoLocation = (newGeoLocation) => {
            progressUpdateCallback('successful');
            allMapLinks = newGeoLocation;
        };
        try {
            progressUpdateCallback('loading');
            await getGeoCodedLocationsInBulk(
                accessToken,
                vehicleList,
                geoCodedLocations,
                getNewGeoLocation,
                loggedInUser,
                true
            );
        } catch (error) {
            progressUpdateCallback('error');
            console.log(error);
        }
    } else {
        map(geoCodedLocations, (location, index) => {
            mapLink = getGoogleMapLink(location.latitude, location.longitude);
            allMapLinks[index] = { address: mapLink };
        });
    }
    return allMapLinks;
};

// printAnyMap
export function printAnyMap(mapSelector = '.map-container', getExtraComponentHandler = null) {
    const $body = jquery('body');
    const $mapContainer = jquery(mapSelector);
    const $mapContainerParent = $mapContainer.parent();
    const $printContainer = jquery('<div style="position:relative; height: 100% !important">');
    const $mapElement = jquery('.map-element');
    $mapContainer.addClass('printMode');
    let $extraDiv;
    if (getExtraComponentHandler) {
        $extraDiv = jquery(getExtraComponentHandler());
    }

    $printContainer.append($mapContainer).prependTo($body);
    $printContainer.append($extraDiv);

    const $content = $body.children().not($printContainer).not('script').detach();

    /**
     * Needed for those who use Bootstrap 3.x, because some of
     * its `@media print` styles ain't play nicely when printing.
     */
    const $patchedStyle = jquery('<style media="print">')
        .text(
            `
          img { max-width: none !important; }
          a[href]:after { content: ""; }
        `
        )
        .appendTo('head');

    setTimeout(() => {
        window.print();
        $body.prepend($content);
        $mapContainerParent.prepend($mapContainer);
        $mapContainer.removeClass('printMode');
        $printContainer.remove();
        $patchedStyle.remove();
    }, 1200);
}

export function getMergedPolyline(directions) {
    if (!get(google, 'maps.geometry.encoding.decodePath')) {
        return get(directions, `overview_polyline`);
    }

    const legs = get(directions, 'legs', []);
    let steps = [];
    map(legs, (leg) => {
        const legSteps = get(leg, 'steps', []);
        steps = [...steps, ...legSteps];
    });
    let decodedPolyline = [];
    map(steps, (step) => {
        const stepPolyline = get(step, 'polyline.points');
        const stepPolylineDecoded = google.maps.geometry.encoding.decodePath(decodeURIComponent(stepPolyline));
        decodedPolyline = [...decodedPolyline, ...stepPolylineDecoded];
    });
    // reencode polylne here
    const reencodedPolyline = !!decodedPolyline.length ? google.maps.geometry.encoding.encodePath(decodedPolyline) : '';

    return !!reencodedPolyline ? reencodedPolyline : get(directions, `overview_polyline`);
}

export const getCoordinatesFromPolyline = (defaultPolyline) => {
    let coordinates = [];
    if (!window.google) {
        return false;
    }
    const path = new window.google.maps.geometry.encoding.decodePath(decodeURIComponent(defaultPolyline));
    map(path, (point, index) => {
        coordinates[index] = { lat: point.lat(), lng: point.lng(), id: index };
    });
    return coordinates;
};
export const snapPointOnPolyline = (lat, lon, defaultPolyline) => {
    let routeCoordinates = getCoordinatesFromPolyline(defaultPolyline);
    const routePointQT = createQuadTree(routeCoordinates);
    let candidates = getNearestPointCandidatesFromQT(
        { latitude: lat, longitude: lon },
        routePointQT,
        POLYLINE_NEAREST_POINT_RANGE
    );
    const candidatesWithLatLng = getLanLngCandidates(candidates);
    let nearestPoint = nearestLatLng(normalizeLatitude(lat), normalizeLongitude(lon), candidatesWithLatLng, true);
    return nearestPoint
        ? { lat: deNormalizeLatitude(nearestPoint.x), lon: deNormalizeLongitude(nearestPoint.y) }
        : { lat, lon };
};

export const INPUT_STYLE = {
    boxSizing: `border-box`,
    MozBoxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `250px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    left: '50%',
    marginLeft: '-120px',
    marginTop: '5px',
};

export const getGeofenceComponent = (areaType, center, radius, coordinates, key, moreOptions = {}) => {
    const { fillColor = '#0078D4' } = moreOptions || {};
    if (areaType === AREA_TYPE.CIRCLE && center) {
        return (
            <Circle
                key={`Circle-${key}`}
                center={center}
                radius={radius}
                options={{
                    fillColor,
                    fillOpacity: 0.2,
                    strokeWeight: 1,
                    clickable: false,
                    editable: false,
                    draggable: false,
                    zIndex: 1,
                }}
            />
        );
    } else if (areaType === AREA_TYPE.POLYGON && coordinates) {
        return (
            <Polygon
                key={`Polygon-${key}`}
                paths={coordinates}
                options={{
                    fillColor,
                    fillOpacity: 0.2,
                    strokeWeight: 1,
                    clickable: false,
                    editable: false,
                    draggable: false,
                    zIndex: 1,
                }}
            />
        );
    }
    return null;
};

export function getInvalidNetworksPolylines(allTrips, props, isOsmEnable) {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    let invalidNetworkPolylines = [];
    let firstInvalidNetworkPoint = null;
    let lastInvalidNetworkPoint = null;
    let polyPoints = [];

    forEach(allTrips, ({ trip, routes }, index) => {
        forEach(routes, (point, ind) => {
            if (isNetworkValid(point) && firstInvalidNetworkPoint && lastInvalidNetworkPoint) {
                const { latitude: lat1, longitude: lon1 } = firstInvalidNetworkPoint;
                const { latitude: lat2, longitude: lon2 } = lastInvalidNetworkPoint;
                const distance = pythagorasEquirectangular(lat1, lon1, lat2, lon2);

                if (showInvalidNetworkPolyline(distance, loggedInUser)) {
                    invalidNetworkPolylines.push(
                        isOsmEnable ? (
                            <PolylineDecorator
                                key={`invalid_network_${trip.tripId}_${index}_${ind}`}
                                {...getOSMPolylineOptionsFromGMOptions(polyLineTripDefaultOptionsInvalidNewtork)}
                                positions={polyPoints}
                                onMouseOver={(position) => {
                                    props.onPolylineMouseOver(position, polyPoints, trip, {
                                        polylineType: 'invalidNetwork',
                                    });
                                }}
                                onMouseOut={(position) => {
                                    props.onPolylineMouseOut();
                                }}
                                onClick={(position) => {
                                    props.onPolylineClick(position, polyPoints, trip, {
                                        polylineType: 'invalidNetwork',
                                    });
                                }}
                            />
                        ) : (
                            <Polyline
                                key={`invalid_network_${trip.tripId}_${index}_${ind}`}
                                {...polyLineTripDefaultOptionsInvalidNewtork}
                                path={polyPoints}
                                onMouseOver={(position) => {
                                    props.onPolylineMouseOver(position, polyPoints, trip, {
                                        polylineType: 'invalidNetwork',
                                    });
                                }}
                                onMouseOut={(position) => {
                                    props.onPolylineMouseOut();
                                }}
                                onClick={(position) => {
                                    props.onPolylineClick(position, polyPoints, trip, {
                                        polylineType: 'invalidNetwork',
                                    });
                                }}
                            />
                        )
                    );
                }

                //reset
                polyPoints = [];
                firstInvalidNetworkPoint = null;
                lastInvalidNetworkPoint = null;
            } else {
                if (!firstInvalidNetworkPoint) {
                    firstInvalidNetworkPoint = point;
                } else {
                    lastInvalidNetworkPoint = point;
                }
                polyPoints.push({
                    lat: point.latitude,
                    lng: point.longitude,
                });
            }
        });
    });

    return invalidNetworkPolylines;
}

export const getMapTypeId = (mapComponent, google) => {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const currentMapTypeId = get(mapComponent, 'mapTypeId', '');

    return currentMapTypeId
        ? currentMapTypeId
        : showMapDefaultViewAsHybrid(loggedInUser) || satelliteTypeMapDefaultRest()
        ? google?.maps?.MapTypeId?.HYBRID
        : google?.maps?.MapTypeId?.ROADMAP;
};

export const getGoogleMapsLabel = (getMapTypeId, google) => {
    return getMapTypeId === google?.maps?.MapTypeId?.SATELLITE || getMapTypeId === google?.maps?.MapTypeId?.HYBRID;
};

export function getVehicleIconForMap(marker, defaultIcon) {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const isSwitchedUser = get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false);
    const currentStatus = getCurrentVehicleStatus(marker);
    const course = get(marker, 'otherAttributes.course', get(marker, 'course', 0));

    let icon = defaultIcon;
    let imageUrl;

    let userFleetType = getUserFleetType(loggedInUser);
    let useVehicleTypeValue = useVehicleTypeValueForAccount(loggedInUser);

    if (isSmartGreenAccount(loggedInUser) && showBikeIconsForGroupIdForSmartGreen(marker.groupId)) {
        userFleetType = FLEET_TYPE.MOTORBIKE;
    }
    if (useVehicleTypeValue && get(marker, 'vehicleTypeValue')) {
        userFleetType = get(marker, 'vehicleTypeValue');
    }

    if (showRakeIconForAccount()) {
        userFleetType = FLEET_TYPE.RAKE;
    }

    imageUrl = getMarkerImageBasedOnVehicleTypeAndStatus(userFleetType, currentStatus, course);

    if (imageUrl && showVehicleIconsAsMarker(loggedInUser, isSwitchedUser)) {
        icon = {
            url: imageUrl,
            size: new google.maps.Size(70, 70),
            anchor: new google.maps.Point(27, 35),
        };
    } else if (!icon) {
        icon = {
            path: DEFAULT_ARROW_ICON.path,
            fillColor: getMarkerColor(currentStatus),
            fillOpacity: DEFAULT_ARROW_ICON.fillOpacity,
            anchor: new google.maps.Point(20, 0),
            rotation: toSafeInteger(course) - 40,
            scale: 0.8,
        };
    }
    return icon;
}
