import { FxReactSelect as Select, FxMultiDropdownWithCheckBox } from '../../shared';
import { get } from 'lodash';
import { showMultiSelectWithCheckBoxForAccount } from 'utils/account_utils';
import { FxSelect } from 'sharedV2/index';

export default (props) => {
    const {
        label,
        isRequired,
        meta: { touched, error },
        outerDivStyle,
        labelDivStyle = '',
        callOnChange,
        onSelected,
        handleOnChange,
        customField,
        isAntdSelect,
        helpText,
    } = props;

    const onChange = (value, triggeredAction) => {
        (callOnChange
            ? (value) => {
                  props.input.onChange(value);
              }
            : (value) => {
                  props.input.onChange(get(value, 'value', value));
              })(value);

        if (handleOnChange) {
            handleOnChange(triggeredAction);
        }

        if (onSelected) {
            onSelected(get(value, 'value', value));
        }
    };

    return (
        <div className={`${outerDivStyle}`}>
            {label && (
                <div className={touched && error ? 'form-control-label text-danger' : `${labelDivStyle}`}>
                    <label>{label}</label>
                    {customField && (
                        <>
                            {' '}
                            <sup className="text-info" style={{ fontSize: 11 }}>
                                Custom
                            </sup>{' '}
                        </>
                    )}
                    {isRequired && <abbr className="text-danger">*</abbr>}
                </div>
            )}
            {props.withCheckBoxOption && showMultiSelectWithCheckBoxForAccount() ? (
                <FxMultiDropdownWithCheckBox
                    {...props}
                    value={props.input.value || props.input.value === 0 ? props.input.value : null}
                    onChange={onChange}
                    onBlur={() => props.input.onBlur(props.input.value.value)}
                    options={props.options}
                    {...(props.disabled ? { disabled: true } : {})}
                />
            ) : (
                <>
                    {isAntdSelect ? (
                        <FxSelect
                            {...props}
                            value={props.input.value || props.input.value === 0 ? props.input.value : null}
                            onChange={onChange}
                            onBlur={() => props.input.onBlur(props.input.value.value)}
                            options={props.options}
                            {...(props.disabled ? { disabled: true } : {})}
                        />
                    ) : (
                        <Select
                            {...props}
                            value={props.input.value || props.input.value === 0 ? props.input.value : null}
                            onChange={onChange}
                            onBlur={() => props.input.onBlur(props.input.value.value)}
                            options={props.options}
                            {...(props.disabled ? { disabled: true } : {})}
                        />
                    )}
                </>
            )}
            {touched && error && <div className="text-help text-danger text-left">{error}</div>}
            {helpText && <div className="text-help">{helpText}</div>}
        </div>
    );
};
