import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
const NoDataFoundImageContainer = styled.div `
    background-image: url(/public/img/no_data.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 122px;
    width: 137px;
    margin: auto;
`;
const FxEmptyStateContainer = ({ text }) => {
    return (_jsx("div", { className: "app flex-row align-items-center", children: _jsxs("div", { className: "container", children: [_jsx(NoDataFoundImageContainer, {}), _jsx("div", { className: "text-center font-2xl mt-2", children: text || 'No Data Found' })] }) }));
};
export default FxEmptyStateContainer;
