import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Timeline, ConfigProvider } from 'antd';
import { get, map } from 'lodash';
import { ClockCircleFilled, CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT_HUMANIZE, getMomentTime } from 'utils/date_utils';
const colorMapping = {
    APPROVED: '#76c09d',
    PENDING: '#555555',
    REJECTED: '#e77470',
};
const dotMapping = {
    APPROVED: (_jsx(CheckCircleFilled, { style: {
            color: '#76c09d',
            fontSize: '17px',
        } })),
    PENDING: (_jsx(ClockCircleFilled, { style: {
            color: '#555555',
            fontSize: '17px',
        } })),
    REJECTED: (_jsx(CloseCircleOutlined, { style: {
            color: '#e77470',
            fontSize: '17px',
        } })),
};
const FxModifiedTimeline = (props) => {
    const themeLocal = {};
    const renderItems = map(props.ModifiedItems, (v, i) => {
        const levelName = get(v, 'levelName', '-');
        const byUser = get(v, 'byUser', '-');
        const date = get(v, 'date', '');
        const description = get(v, 'desc', '');
        const status = get(v, 'status', '-');
        return {
            color: colorMapping[status],
            dot: dotMapping[status],
            children: (_jsxs(_Fragment, { children: [_jsxs("div", { className: "level-text-wrapper d-flex", children: [_jsx("span", { children: _jsxs("div", { children: [levelName, " ", status] }) }), ",", _jsx("span", { className: "ml-2", style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }, children: byUser ? `${byUser}` : '' })] }), description ? _jsx("div", { className: "desc-text ml-2", children: description }) : '', _jsx("span", { className: "desc-text m-2", children: date ? getMomentTime(date).format(DATE_FORMAT_HUMANIZE) : '-' })] })),
        };
    });
    return (_jsxs("div", { children: [_jsx("div", { style: {
                    fontSize: '16px',
                    fontWeight: 600,
                    marginBottom: '20px',
                }, children: get(props, 'timlineTitle', 'Time Line') }), _jsx(ConfigProvider, { theme: themeLocal, children: _jsx(Timeline, { ...props, items: renderItems }) })] }));
};
export default FxModifiedTimeline;
