import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { DEFAULT_SIZE } from '../theme/utils';
import { FxButton, FxDivider, FxInput, FxSpace } from 'sharedV2';
import { FxPlusIcon } from 'sharedV2/FxIcons';
const FxSelect = (props) => {
    const { creatable, innerRef, style, ...rest } = props;
    // these all methods and states are being used to amke it creatable
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const [items, setItems] = useState(props.options);
    useEffect(() => {
        if (creatable) {
            setItems(props.options);
        }
    }, [props.options, creatable]);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setItems([{ label: name, value: name }, ...items]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    const dropdownRender = (menu) => (_jsxs(_Fragment, { children: [menu, _jsx(FxDivider, { style: { margin: '8px 0' } }), _jsxs(FxSpace, { style: { padding: '0 8px 4px' }, children: [_jsx(FxInput, { placeholder: "Please enter", innerRef: inputRef, value: name, onChange: onNameChange, onKeyDown: (e) => e.stopPropagation() }), _jsx(FxButton, { type: "text", icon: _jsx(FxPlusIcon, {}), onClick: addItem, children: "Add New" })] })] }));
    const optionsForCreatable = {};
    if (creatable) {
        optionsForCreatable.dropdownRender = dropdownRender;
        optionsForCreatable.options = items;
    }
    return (_jsx(Select, { style: { width: '100%', minWidth: props.minWidth, ...style }, ref: innerRef, size: DEFAULT_SIZE, showSearch: true, optionFilterProp: "label", allowClear: true, ...rest, ...optionsForCreatable }));
};
export default FxSelect;
