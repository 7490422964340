import axios from 'axios';
import { ROOT_API_URL } from '../../../constant';
export const CREATE_PART_MAKER = 'create_part_maker';
export const UPDATE_PART_MAKER = 'update_part_maker';
export const DELETE_PART_MAKER = 'delete_part_maker';
export const FETCH_PART_MAKERS = 'fetch_part_makers';
export const FETCH_PART_MAKER_BY_ID = 'fetch_part_maker_by_id';
export function createPartMaker(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}part-maker`, data, config);
    return {
        type: CREATE_PART_MAKER,
        promise: request,
    };
}
export function updatePartMaker(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}part-maker`, data, config);
    return {
        type: UPDATE_PART_MAKER,
        promise: request,
    };
}
export function fetchPartMakers(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}part-maker`, config);
    return {
        type: FETCH_PART_MAKERS,
        promise: request,
    };
}
export function fetchPartMakerById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}part-maker/${id}`, config);
    return {
        type: FETCH_PART_MAKER_BY_ID,
        promise: request,
    };
}
export function deletePartMaker(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}part-maker/${id}`, config);
    return {
        type: DELETE_PART_MAKER,
        promise: request,
    };
}
