import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Comment = (props) => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "20px", viewBox: "0 -960 960 960", width: "20px", fill: "#0078d4", children: _jsx("path", { d: "M240-384h480v-72H240v72Zm0-132h480v-72H240v72Zm0-132h480v-72H240v72ZM864-96 720-240H168q-29.7 0-50.85-21.15Q96-282.3 96-312v-480q0-29.7 21.15-50.85Q138.3-864 168-864h624q29.7 0 50.85 21.15Q864-821.7 864-792v696ZM168-312h582l42 42v-522H168v480Zm0 0v-480 480Z" }) }));
};
const CommentIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Comment, ...props });
};
export default CommentIcon;
