import { isEmpty, concat, map } from 'lodash';
import * as React from 'react';
import RoutePointItemComponentOld from './RoutePointItemComponentOld';

const RoutePointsList = ({
    fields,
    parentProps,
    addressBookExtraOption,
    onRoutePointSelect,
    selectLocationFromGoogleMap,
}) => {
    const {
        name = 'routePoints',
        commodityList,
        customers,
        addressBook,
        isEdit,
        isExistingRouteSelected,
        commodityName,
        routePoints = [],
        moreAddressBookOptions,
    } = parentProps;

    const itemsUploaded = !isEmpty(routePoints);
    const finalAddressBook = concat(addressBook, addressBookExtraOption, moreAddressBookOptions);

    return (
        <>
            {itemsUploaded &&
                map(routePoints, (routePoint, index) => {
                    return (
                        <RoutePointItemComponentOld
                            key={`${name}.[${index}]`}
                            routePoint={routePoint}
                            index={index}
                            baseKey={`${name}.[${index}]`}
                            commodityList={commodityList}
                            customers={customers}
                            addressBook={finalAddressBook}
                            isEdit={isEdit}
                            isExistingRouteSelected={isExistingRouteSelected}
                            selectLocationFromGoogleMap={selectLocationFromGoogleMap}
                            remove={fields.remove}
                            onRoutePointSelect={onRoutePointSelect}
                            commodityName={commodityName}
                        />
                    );
                })}
            {!isExistingRouteSelected && (
                <div className="d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn"
                        style={{
                            borderRadius: '4px',
                            'border': 'solid 1px #20a8d8',
                            'color': '#20a8d8',
                            height: '2.5rem',
                        }}
                        onClick={() => fields.push({})}
                    >
                        Add {itemsUploaded ? 'More' : ''} Route point
                    </button>
                </div>
            )}
        </>
    );
};

export default RoutePointsList;
