import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from 'constant';
export const FETCH_BILLS = 'FETCH_BILLS';
export function fetchBills(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}bill/filter`, config);
    return {
        type: FETCH_BILLS,
        promise: request,
    };
}
