import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CHANNELS_SALES_TABS, isVisibleToUser } from '../../../utils/channel_sales_utils';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Helmet } from 'react-helmet';
import ChannelSalesPaging from './channel_sales_paging';
import { fetchChannelSalesData } from '../../../actions/dashboard/channel_sales_actions';
import CalendarComponent from '../../utils/calendar_component';
import Loader from '../../../components/utils/loader';
import { get } from 'lodash';
import { getEndDate, getMomentTime, DEFAULT_DUTY_START_DATE_DAYS } from '../../../utils/date_utils';
import { getPageTitle } from '../../../../white_label_constants';
import { withRoute } from '../customHocs/withRoute';

class ChannelSalesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.view ? props.view : isVisibleToUser(this.props.role, '', true),
            channelSalesData: {},
            sizePerPage: 10,
            page: 1,
            totalDataSize: 0,
            startDate: getMomentTime().subtract(DEFAULT_DUTY_START_DATE_DAYS, 'days').startOf('day'),
            endDate: getEndDate(),
            isLoading: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const params = {
            from: this.state.startDate.valueOf(),
            to: this.state.endDate.valueOf(),
        };
        this.setState({
            isLoading: true,
        });
        this.props.fetchChannelSalesData(this.props.accesstoken, params).then((result) => {
            const activeTab = this.state.activeTab ? this.state.activeTab : isVisibleToUser(this.props.role, '', true);
            const activeTabTitle = get(CHANNELS_SALES_TABS[activeTab], 'title', '');
            const paggingData = get(result.payload.data, activeTabTitle, []);
            const currentIndex = this.state.page - 1;
            this.setState({
                channelSalesData: result.payload.data,
                paggingData: paggingData.slice(currentIndex, currentIndex + this.state.sizePerPage),
                totalDataSize: paggingData.length,
                activeTab,
                isLoading: false,
            });
        });
    };

    toggleVehicleSection = (tab) => {
        if (this.state.activeTab !== tab.name) {
            const paggingData = get(this.state.channelSalesData, tab.title, []);
            const currentIndex = 0;

            this.setState(
                {
                    activeTab: tab.name,
                    paggingData: paggingData.slice(currentIndex, currentIndex + this.state.sizePerPage),
                    totalDataSize: paggingData.length,
                    page: 1,
                },
                () => {
                    // this.props.toggleVehicleSection(tab);
                }
            );
        }
    };

    onPageChange = (page, sizePerPage) => {
        const activeTab = this.state.activeTab;
        const activeTabTitle = get(CHANNELS_SALES_TABS[activeTab], 'title', '');
        const paggingData = get(this.state.channelSalesData, activeTabTitle, []);
        const currentIndex = (page - 1) * sizePerPage;
        this.setState({
            paggingData: paggingData.slice(currentIndex, currentIndex + sizePerPage),
            page: page,
        });
    };

    renderNavItem = (tabKey, tabText, galabel) => {
        return (
            <NavItem>
                <NavLink
                    className={`${this.state.activeTab === tabKey.name ? 'active' : ''} cursor-pointer`}
                    onClick={() => {
                        this.toggleVehicleSection(tabKey);
                    }}
                    data-gacategory="Channel Sales"
                    data-gaaction="Click"
                    data-galabel={galabel}
                >
                    <span> {tabText}</span>
                </NavLink>
            </NavItem>
        );
    };

    onDateSelected = (startDate, endDate) => {
        this.setState(
            {
                startDate: startDate,
                endDate: endDate,
                currentPage: 1,
            },
            () => this.getData()
        );
    };

    render() {
        return (
            <div className="mt-4">
                <div>
                    <Helmet title={getPageTitle(`Channel Sales`)} />
                    <h4 className="pt-2">
                        {/* <i className="fa fa-car" />  */}
                        Channels Sales
                    </h4>
                </div>
                <div className="pull-right">
                    <div className="pull-right  mr-2 mb-2">
                        <div
                            className="pull-right mr-2 mb-2 calendar-component"
                            data-gacategory="Channel-sales"
                            data-gaaction="Click"
                            data-galabel="Channel Sales Select Date Range"
                        >
                            <CalendarComponent
                                onDateSelected={this.onDateSelected}
                                maxDate={getEndDate()}
                                startDate={this.state.startDate}
                                endDate={getEndDate(this.state.endDate)}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <Nav pills>
                        {isVisibleToUser(this.props.role, 'GM') &&
                            this.renderNavItem(CHANNELS_SALES_TABS.GM, 'GM', 'Channel-sales')}
                        {isVisibleToUser(this.props.role, 'CSM') &&
                            this.renderNavItem(CHANNELS_SALES_TABS.CSM, 'CSM', 'Channel-sales')}
                        {isVisibleToUser(this.props.role, 'CSO') &&
                            this.renderNavItem(CHANNELS_SALES_TABS.CSO, 'CSO', 'Channel-sales')}
                        {isVisibleToUser(this.props.role, 'CSP') &&
                            this.renderNavItem(CHANNELS_SALES_TABS.CSP, 'CSP', 'Channel-sales')}
                    </Nav>
                </div>
                {this.state.isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <ChannelSalesPaging
                            data={this.state.paggingData}
                            totalDataSize={this.state.totalDataSize}
                            activeTab={this.state.activeTab}
                            onPageChange={this.onPageChange}
                            sizePerPage={this.state.sizePerPage}
                            page={this.state.page}
                        />
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchChannelSalesData,
        },
        dispatch
    );
}

function mapStateToProps(state, ownProps) {
    return {
        accesstoken: state.login.data.access_token,
        role: state.login.data.role,
    };
}

export default withRoute(connect(mapStateToProps, mapDispatchToProps)(ChannelSalesComponent));
