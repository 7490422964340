import { find, findIndex, findLastIndex, forEach, get } from 'lodash';
export const getAxisYDomain = ({ refAreaLeft, refAreaRight, xAxisDataKey, yAxisDataKey, yAxisOffset, initialData = [], }) => {
    if (!initialData || !initialData.length) {
        return [0, 0];
    }
    const fromIndex = refAreaLeft
        ? findIndex(initialData, (item) => {
            return item[xAxisDataKey] >= refAreaLeft;
        })
        : 0;
    const toIndex = refAreaLeft
        ? findLastIndex(initialData, (item) => {
            return item[xAxisDataKey] <= refAreaRight;
        })
        : 0;
    const refData = initialData.slice(fromIndex, toIndex + 1);
    if (!refData.length) {
        return [0, 0];
    }
    let [bottom, top] = [refData[0][yAxisDataKey], refData[0][yAxisDataKey]];
    refData.forEach((d) => {
        if (d[yAxisDataKey] > top)
            top = d[yAxisDataKey];
        if (d[yAxisDataKey] < bottom)
            bottom = d[yAxisDataKey];
    });
    return [(bottom || 0) - yAxisOffset, (top || 0) + yAxisOffset];
};
export const initialStateValues = (override = {}) => {
    return {
        left: 'dataMin',
        right: 'dataMax',
        refAreaLeft: '',
        refAreaRight: '',
        yAxisDomains: [],
        zoomActivated: false,
        ...override,
    };
};
export const zoomOutGraph = (override = {}) => {
    return {
        ...initialStateValues(),
        ...override,
    };
};
export const zoomInGraph = ({ initialData, refAreaLeft, refAreaRight, yAxisDomainsRefs, xAxisDataKey, yAxisDomainsInitials, adjustYAxis = false, }) => {
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
        return {
            refAreaLeft: '',
            refAreaRight: '',
        };
    }
    // xAxis domain
    if (refAreaLeft > refAreaRight)
        [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    // yAxis domains
    const yAxisDomains = adjustYAxis ? [] : yAxisDomainsInitials;
    if (adjustYAxis) {
        forEach(yAxisDomainsRefs, (ref) => {
            const { yAxisDataKey, yAxisOffset } = ref;
            const [bottom, top] = getAxisYDomain({
                refAreaLeft,
                refAreaRight,
                yAxisDataKey,
                yAxisOffset,
                initialData,
                xAxisDataKey,
            });
            yAxisDomains.push({ bottom, top, yAxisDataKey });
        });
    }
    return {
        refAreaLeft: '',
        refAreaRight: '',
        left: refAreaLeft,
        right: refAreaRight,
        yAxisDomains,
        zoomActivated: true,
    };
};
export const styleOnRootComponent = {
    userSelect: 'none',
};
export function getYAxisDomain(yAxisDataKey, yAxisDomains) {
    const foundItem = find(yAxisDomains, { yAxisDataKey });
    return [get(foundItem, 'bottom'), get(foundItem, 'top')];
}
