import { FxCard, FxSkeleton, FxFlex } from '../../../shared';
import { Row, Col } from 'reactstrap';

export const TableSkeleton = (props) => {
    const { rowclassName } = props;
    return (
        <Row className={rowclassName ? rowclassName : ''}>
            <Col md={12}>
                <FxCard width="10rem" height="8rem">
                    <FxFlex direction="row">
                        <FxFlex direction="column">
                            <FxSkeleton width="90%" height="30px">
                                <div />
                            </FxSkeleton>
                            <FxSkeleton className="mt-2" width="90%" height="30px">
                                <div />
                            </FxSkeleton>
                        </FxFlex>
                        <FxFlex direction="column">
                            <FxSkeleton width="90%" height="30px">
                                <div />
                            </FxSkeleton>
                            <FxSkeleton className="mt-2" width="90%" height="30px">
                                <div />
                            </FxSkeleton>
                        </FxFlex>
                        <FxFlex direction="column">
                            <FxSkeleton width="90%" height="30px">
                                <div />
                            </FxSkeleton>
                            <FxSkeleton className="mt-2" width="90%" height="30px">
                                <div />
                            </FxSkeleton>
                        </FxFlex>
                    </FxFlex>
                </FxCard>
            </Col>
        </Row>
    );
};
