import axios from 'axios';
import { ROOT_API_URL } from '../../../constant';

export const CREATE_SERVICE_TASK = 'create_service_task';
export const FETCH_SERVICE_TASKS = 'fetch_service_tasks';
export const FETCH_SERVICE_TASK = 'fetch_service_task';
export const UPDATE_SERVICE_TASK = 'update_service_task';
export const DELETE_SERVICE_TASK = 'delete_service_task';

export function createServiceTask(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}service_tasks/`, values, config);
    return {
        type: CREATE_SERVICE_TASK,
        promise: request,
    };
}

export function updateServiceTask(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}service_tasks/${id}`, values, config);

    return {
        type: UPDATE_SERVICE_TASK,
        promise: request,
    };
}

export function fetchServiceTasks(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    //need to add in pagination in the future
    const request = axios.get(`${ROOT_API_URL}service_tasks/?page=0&size=10000`, config);

    return {
        type: FETCH_SERVICE_TASKS,
        promise: request,
    };
}

export function fetchServiceTask(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}service_tasks/${id}`, config);

    return {
        type: FETCH_SERVICE_TASK,
        promise: request,
    };
}

export function deleteServiceTask(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}service_tasks/${id}`, config);
    return {
        type: DELETE_SERVICE_TASK,
        promise: request,
    };
}
