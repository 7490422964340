import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const BranchComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-branch" */ 'components/dashboard/branch/branch_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditBranchComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-addEditBranch" */ 'components/dashboard/branch/add_edit_branch_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const officeBranchRoutes = [
    {
        path: 'all',
        lazy: BranchComponent,
    },
    {
        path: 'add',
        lazy: AddEditBranchComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditBranchComponent,
    },
];
export default officeBranchRoutes;
