import { Card, Row, Col } from 'reactstrap';
import { map, get, toSafeInteger } from 'lodash';
import { getImageKitLink } from '../constant';
import LightBoxUtils from '../utils/light_box_utils';
import { useState } from 'react';
import { isImageUrl } from '../utils/img_utils';
import { FxCloseOutlined } from '../sharedV2/FxIcons';

const FxThumbNailList = (props) => {
    const { imageList, remove, isDeletable = true } = props;
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [clickedPhotoIndex, setClickedPhotoIndex] = useState(0);

    const renderImageThumbnail = (imageUrl, style, ind) => {
        const isImage = isImageUrl(imageUrl);
        return (
            <div key={imageUrl} onClick={props.handleOnClick} className="d-inline-block mr-2 cursor-pointer">
                <div className="d-inline-block m-0 thumbnail" onClick={isImage && handleOnImgClick} data-index={ind}>
                    {isImage ? (
                        <img style={style} src={imageUrl} className="img-thumbnail" alt="PO/Link" />
                    ) : (
                        <a href={imageUrl} download>
                            <img style={style} src="/public/img/file.jpg" className="img-thumbnail" alt="File" />
                        </a>
                    )}
                </div>
            </div>
        );
    };

    const handleOnImgClick = (event) => {
        event.stopPropagation();
        const index = toSafeInteger(event.currentTarget.dataset.index);
        setIsLightBoxOpen(true);
        setClickedPhotoIndex(index);
    };

    return (
        <>
            {isLightBoxOpen && (
                <LightBoxUtils
                    photoIndex={clickedPhotoIndex}
                    images={map(imageList, (img) => get(img, 'imageUrl'))}
                    onCloseRequest={() => {
                        setIsLightBoxOpen(false);
                    }}
                />
            )}
            {map(imageList, (image, index) => {
                return image.imageUrl ? (
                    <div
                        style={{ ...props.style, position: 'relative', display: 'inline-block' }}
                        key={`${get(image, 'imageUrl', '-')}-${index}`}
                        className={'pr-2 mt-2 mr-2'}
                    >
                        {renderImageThumbnail(
                            image.imageUrl,
                            {
                                width: `${props.thumbStyle.width ? props.thumbStyle.width : '10rem'}`,
                                height: `${props.thumbStyle.height ? props.thumbStyle.height : '10rem'}`,
                                minWidth: `${props.thumbStyle.minWidth ? props.thumbStyle.minWidth : '10rem'}`,
                                minHeight: `${props.thumbStyle.minHeight ? props.thumbStyle.minHeight : '10rem'}`,
                                maxWidth: `${props.thumbStyle.maxWidth ? props.thumbStyle.maxWidth : '10rem'}`,
                                maxHeight: `${props.thumbStyle.maxHeight ? props.thumbStyle.maxHeight : '10rem'}`,
                            },
                            index
                        )}
                        {isDeletable && (
                            <div
                                className="pos-abs"
                                style={{ right: '0.6rem', top: '-0.5rem' }}
                                onClick={() => props.deleteImage(index, remove)}
                            >
                                <FxCloseOutlined />
                            </div>
                        )}
                        <a target="_blank" href={image.imageUrl}>
                            <div
                                style={{
                                    fontSize: '0.6rem',
                                    fontWeight: '500',
                                    color: '#a8a8a8',
                                    // maxWidth: '10rem',
                                }}
                            >
                                {image.name}
                            </div>
                        </a>
                    </div>
                ) : null;
            })}
        </>
    );
};

export default FxThumbNailList;
