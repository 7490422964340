import { Component } from 'react';
import {
    deleteServiceEntry,
    fetchServiceEntriesInBetween,
} from '../../../../actions/dashboard/easy/service_entry_action';
import { connect } from 'react-redux';
import {
    DATE_FORMAT_HUMANIZE,
    getEndDateWithCurrent,
    getHumanizeTime,
    getMomentTime,
} from '../../../../utils/date_utils';
import Loader from '../../../utils/loader';
import RemoteServiceEntryPaging from './remote_service_entry_paging';
import { filter } from 'lodash';

class RemoteServiceEntriesList extends Component {
    mounted = false;

    constructor(props) {
        super(props);
        this.serviceEntries = [];
        this.size = 0;
        this.state = {
            data: this.serviceEntries,
            totalDataSize: this.serviceEntries.length,
            sizePerPage: 10,
            currentPage: 1,
            sortOrder: null,
            sortByDateAsc: false,
        };

        this.fetchServiceEntriesList = this.fetchServiceEntriesList.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchServiceEntriesList();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.reloadCount !== this.props.reloadCount ||
            prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate ||
            prevProps.vehicleId !== this.props.vehicleId ||
            prevProps.groupId !== this.props.groupId ||
            prevProps.vendorId !== this.props.vendorId ||
            prevProps.currentLineItemFilter !== this.props.currentLineItemFilter
        ) {
            this.setState(
                {
                    currentPage: 1,
                },
                this.fetchServiceEntriesList
            );
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    showDate = (date, serviceEntry) => {
        if (serviceEntry.startDate) {
            return (
                <div>
                    <div>{getHumanizeTime(serviceEntry.startDate)}</div>
                    <div>{getHumanizeTime(date)}</div>
                </div>
            );
        } else {
            return <div>{getHumanizeTime(date)}</div>;
        }
    };

    fetchServiceEntriesList(
        page = this.state.currentPage,
        sizePerPage = this.state.sizePerPage,
        sortByDateAsc = this.state.sortByDateAsc
    ) {
        this.props
            .fetchServiceEntriesInBetween(
                this.props.accesstoken,
                page,
                sizePerPage,
                this.props.vehicleId,
                this.props.groupId,
                this.props.startDate.valueOf(),
                getEndDateWithCurrent(this.props.endDate).valueOf(),
                sortByDateAsc,
                this.props.vendorId,
                this.props.currentLineItemFilter
            )
            .then((result) => {
                if (!this.mounted) {
                    return;
                }
                if (!result.error) {
                    this.serviceEntries = result.payload.data.content;
                    this.size = result.payload.data.totalElements;
                    this.props.totalSizeCallback(this.size);
                    this.setState({
                        data: this.serviceEntries,
                        totalDataSize: this.size,
                        page,
                        currentPage: page,
                        sizePerPage,
                        sortByDateAsc: sortByDateAsc,
                    });
                }
            });
    }

    onPageChange(page, sizePerPage) {
        this.fetchServiceEntriesList(page, sizePerPage);
    }

    onSortChange(sortName, sortOrder) {
        this.setState({ sortOrder: sortOrder });
        if (sortOrder === 'asc') {
            this.fetchServiceEntriesList(this.state.page, this.state.sizePerPage, true);
        } else {
            this.fetchServiceEntriesList(this.state.page, this.state.sizePerPage, false);
        }
    }

    onDeleteRow(id) {
        this.props.deleteServiceEntry(this.props.accesstoken, id).then((result) => {
            if (!this.mounted) {
                return;
            }
            if (!result.error) {
                this.props.onServiceEntryDeleteSuccess();
                this.serviceEntries = filter(this.serviceEntries, (serviceEntry) => {
                    return serviceEntry.id != id;
                });

                this.setState({
                    data: this.serviceEntries,
                    totalDataSize: this.size - 1,
                });
            } else {
                alert('Something went wrong, please try again later!');
            }
        });
    }

    render() {
        if (this.props.isLoading) {
            return <Loader />;
        } else {
            return (
                <RemoteServiceEntryPaging
                    vehicleDetail={this.props.vehicleDetail}
                    onSortChange={this.onSortChange.bind(this)}
                    vehicleId={this.props.vehicleId}
                    onDeleteRow={this.onDeleteRow.bind(this)}
                    onPageChange={this.onPageChange.bind(this)}
                    {...this.state}
                    updateServiceEntries={this.props.updateServiceEntries}
                    isUpdateServiceEntryAllowed={this.props.isUpdateServiceEntryAllowed}
                    isCreateServiceEntryAllowed={this.props.isCreateServiceEntryAllowed}
                    isDeleteServiceEntryAllowed={this.props.isDeleteServiceEntryAllowed}
                />
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        accesstoken: state.login.data.access_token,
        isLoading: state.serviceEntries.isLoading,
    };
}

export default connect(mapStateToProps, { fetchServiceEntriesInBetween, deleteServiceEntry })(RemoteServiceEntriesList);
