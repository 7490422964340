import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from 'constant';
import { getMomentTime } from 'utils/date_utils';
export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_OUTSTANDING = 'GET_OUTSTANDING';
export const GET_BRANCH_AUDIT_TRAIL = 'GET_BRANCH_AUDIT_TRAIL';
export const GET_STOCK_REPORT = 'GET_STOCK_REPORT';
export const GET_MONTHLY_DISPATCH = 'GET_MONTHLY_DISPATCH';
export const GET_VEHICLE_SIZE_DISPATCH = 'GET_VEHICLE_SIZE_DISPATCH';
export function getInventory(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            to: getMomentTime().valueOf(),
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}inventory/material-present`, config);
    return {
        type: GET_INVENTORY,
        promise: request,
    };
}
export function getOutstanding(accesstoken, date, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            to: getMomentTime(date).valueOf(),
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}inventory/material-present`, config);
    return {
        type: GET_OUTSTANDING,
        promise: request,
    };
}
export function getBranchAuditTrail(accesstoken, from, to, params) {
    const size = 100000;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            size,
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}inventory/material`, config);
    return {
        type: GET_BRANCH_AUDIT_TRAIL,
        promise: request,
    };
}
export function getStockReport(accesstoken, from, to) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}inventory/stock-report`, config);
    return {
        type: GET_STOCK_REPORT,
        promise: request,
    };
}
export function getMonthlyDispatch(accesstoken, from, to) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}inventory/monthly-dispatch`, config);
    return {
        type: GET_MONTHLY_DISPATCH,
        promise: request,
    };
}
export function getVehicleSizeDispatch(accesstoken, from, to) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}inventory/vehicle-size-dispatch`, config);
    return {
        type: GET_VEHICLE_SIZE_DISPATCH,
        promise: request,
    };
}
