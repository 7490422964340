import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const PhoneTick = (props) => {
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "21", height: "21", viewBox: "0 0 21 21", fill: "none", children: [_jsx("path", { d: "M9.62462 17.5765H6.69535C6.25142 17.5765 5.82566 17.4002 5.51175 17.0863C5.19784 16.7724 5.02148 16.3466 5.02148 15.9027V4.18559C5.02148 3.74165 5.19784 3.31589 5.51175 3.00198C5.82566 2.68807 6.25142 2.51172 6.69535 2.51172H13.3908C13.8348 2.51172 14.2605 2.68807 14.5744 3.00198C14.8883 3.31589 15.0647 3.74165 15.0647 4.18559V12.1365M9.20616 3.34865H10.88M10.0431 14.2288V14.2372M12.5539 15.9027L14.2278 17.5765L17.5755 14.2288", stroke: "#945FB9", "stroke-width": "1.20519", "stroke-linecap": "round", "stroke-linejoin": "round" }), _jsx("rect", { x: "7.15234", y: "5.00781", width: "5.72132", height: "7.15165", rx: "0.715165", fill: "#945FB9" })] }));
};
const PhoneTickIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: PhoneTick, ...props });
};
export default PhoneTickIcon;
