import { handle } from 'redux-pack';
import { CREATE_INTERNAL_ISSUE, UPDATE_INTERNAL_ISSUE, DELETE_INTERNAL_ISSUE, SEARCH_INTERNAL_ISSUES, FETCH_INTERNAL_ISSUE_BY_ID, FETCH_INTERNAL_ISSUES_SUMMARY, INTERNAL_ISSUES_COUNT_PER_VEHICLE, GET_ASSIGNMENT_SUMMARY, } from '../../../actions/dashboard/internal_tools/issues_actions';
import { arrayToObject } from '../../../utils/list_utils';
const initialState = {
    isLoading: false,
    error: null,
    issuesList: [],
    isIssuesListLoading: false,
    isIssueLoading: false,
    isIssuesSummaryLoading: false,
    issue: {},
    totalElements: 0,
    issuesSummary: {},
    issuesCountPerVehicleAsMap: {},
    issuesCountPerVehicleLoading: false,
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_INTERNAL_ISSUE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_INTERNAL_ISSUE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_INTERNAL_ISSUE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case SEARCH_INTERNAL_ISSUES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isIssuesListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isIssuesListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    issuesList: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        case FETCH_INTERNAL_ISSUE_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isIssueLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isIssueLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, issue: payload.data }),
            });
        case FETCH_INTERNAL_ISSUES_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isIssuesSummaryLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isIssuesSummaryLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, issuesSummary: payload.data }),
            });
        case INTERNAL_ISSUES_COUNT_PER_VEHICLE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    issuesCountPerVehicleLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, issuesCountPerVehicleLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    issuesCountPerVehicleAsMap: arrayToObject(payload.data, 'vehicleId'),
                }),
            });
        case GET_ASSIGNMENT_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    issueSummary: payload.data,
                }),
            });
        default:
            return state;
    }
}
