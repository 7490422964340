import { jsx as _jsx } from "react/jsx-runtime";
import ServiceEntryAddEditWrapper from 'components/dashboard/maintenance/serviceentries/ServiceEntryAddEditWrapper';
import ServiceEntryWrapper from 'components/dashboard/maintenance/serviceentries/ServiceEntryWrapper';
const maintenanceRoutes = [
    {
        path: 'serviceentries/add/',
        element: _jsx(ServiceEntryAddEditWrapper, {}),
    },
    {
        path: 'serviceentries/add/:vehicleId',
        element: _jsx(ServiceEntryAddEditWrapper, {}),
    },
    {
        path: 'serviceentries/edit/:vehicleId/:id',
        element: _jsx(ServiceEntryAddEditWrapper, {}),
    },
    {
        path: 'serviceentries',
        element: _jsx(ServiceEntryWrapper, {}),
    },
];
export default maintenanceRoutes;
