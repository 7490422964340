import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../constant';
export const CREATE_COMMODITY = 'create_commodity';
export const FETCH_COMMODITIES = 'fetch_commodities';
export const UPDATE_COMMODITY = 'update_commodity';
export const FETCH_COMMODITY_BY_ID = 'fetch_commodity_by_id';
export const DELETE_COMMODITY = 'delete_commodity';
export function createCommodity(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}commodities`, values, config);
    return {
        type: CREATE_COMMODITY,
        promise: request,
    };
}
export function updateCommodity(accesstoken, id, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}commodities/${id}`, values, config);
    return {
        type: UPDATE_COMMODITY,
        promise: request,
    };
}
export function fetchCommodityById(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}commodities/${id}`, config);
    return {
        type: FETCH_COMMODITY_BY_ID,
        promise: request,
    };
}
export function fetchCommodities(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}commodities`, config);
    return {
        type: FETCH_COMMODITIES,
        promise: request,
    };
}
export function deleteCommodity(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}commodities/${id}`, config);
    return {
        type: DELETE_COMMODITY,
        promise: request,
    };
}
