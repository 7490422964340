import { jsx as _jsx } from "react/jsx-runtime";
import { Row, Col } from 'antd';
export const defaultGutter = { xs: 8, sm: 16, md: 24, lg: 32 };
export const FxRow = (props) => {
    const { dontUseDefaultGutter, ...rest } = props;
    const extraPropsToPass = {};
    if (!dontUseDefaultGutter && !props.gutter) {
        extraPropsToPass.gutter = defaultGutter;
    }
    return _jsx(Row, { ...rest, ...extraPropsToPass });
};
export const FxCol = Col;
