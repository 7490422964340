import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider, Divider } from 'antd';
const FxDivider = (props) => {
    const { themeModify, ...rest } = props;
    const themeLocal = {
        token: {
            ...themeModify,
        },
    };
    return (_jsx(ConfigProvider, { theme: themeLocal, children: _jsx(Divider, { ...rest }) }));
};
export default FxDivider;
