import { FxReactSelect as Select, FxMultiDropdownWithCheckBox } from '../shared';
import AsyncSelect from 'react-select/async';
import { get, map, find } from 'lodash';
import { showMultiSelectWithCheckBoxForAccount } from 'utils/account_utils';
import { UncontrolledTooltip } from 'reactstrap';
import FxSwitch from 'sharedV2/FxSwitch';

export default (passedProps) => {
    const { field, form, showLabelBySide, helpText, searchWith, ...props } = passedProps;
    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');

    const errorClass = isTouched && error ? 'form-control-label text-danger' : '';
    const getValue = (option) => {
        if (option) {
            return props.multi ? map(option, (item) => item.value) : option && option.value;
        } else {
            return props.multi ? [] : null;
        }
    };

    const getAsyncSelectValue = (selectedValue) => {
        const options = props.remoteVehicles || props.remoteOptions;
        return find(options, (option) => get(option, 'value') == selectedValue);
    };

    const onChange = (option, triggeredAction) => {
        const v = getValue(option);
        form.setFieldValue(field.name, v);
        if (props.onSelected) {
            props.onSelected(v);
        }
        if (props.onChange) {
            props.onChange(v, triggeredAction);
        }
        if (props.onOptionSelected) {
            props.onOptionSelected(option);
        }
    };

    const labelClass = `${isTouched && error ? 'text-danger' : ''}`;

    return (
        <div
            className={`form-group ${props.className} ${
                showLabelBySide ? `flex-row d-flex mt-4 align-items-center` : ''
            }`}
        >
            <div className={`d-flex ${showLabelBySide ? 'col-md-3' : ''}`}>
                {props.label && (
                    <div
                        style={{
                            width: '100%',
                        }}
                        className={
                            searchWith
                                ? `${errorClass} ${
                                      showLabelBySide
                                          ? 'pl-0 mt-3 d-flex justify-content-between'
                                          : ' d-flex justify-content-between'
                                  }`
                                : `${errorClass} ${showLabelBySide ? 'pl-0 mt-3' : ''}`
                        }
                    >
                        <label htmlFor={name} className={labelClass}>
                            {props.label} {props.isRequired && <abbr className="text-danger">*</abbr>}
                        </label>
                        {searchWith && (
                            <div>
                                Search With{' '}
                                <FxSwitch
                                    checkedChildren="Name"
                                    unCheckedChildren="Code"
                                    defaultChecked
                                    onChange={props.onSearchWith}
                                />
                            </div>
                        )}
                    </div>
                )}
                {props.info && (
                    <>
                        <i id={props.infoId} className="fa fa-info-circle cursor-pointer ml-2 mt-1" />
                        <UncontrolledTooltip placement="right" target={props.infoId}>
                            {props.info}
                        </UncontrolledTooltip>
                    </>
                )}
            </div>
            <div className={`${showLabelBySide ? 'col-md-9' : ''}`}>
                {props.loadAsync ? (
                    <AsyncSelect
                        getOptionLabel={props.formatOptionLabel}
                        loadOptions={(inputValue, callback) => {
                            if (props.callbackLoadOptions) {
                                props.promisifyOptions && props.promisifyOptions(inputValue, callback);
                            } else {
                                return props.promisifyOptions && props.promisifyOptions(inputValue, props.value, true);
                            }
                        }}
                        placeholder={props.placeholder ? props.placeholder : 'Select Vehicle'}
                        noOptionsMessage={(inputValue) => {
                            return get(inputValue, 'inputValue.length', 0) > 2 ? 'No Options' : 'Type to see options';
                        }}
                        defaultOptions={props.defaultOptions || true}
                        onChange={onChange}
                        value={getAsyncSelectValue(props.value)}
                        onBlur={() => form.setFieldTouched(field.name)}
                        {...(props.disabled ? { disabled: true } : {})}
                        inputId={field.name}
                    />
                ) : props.withCheckBoxOption && showMultiSelectWithCheckBoxForAccount() ? (
                    <FxMultiDropdownWithCheckBox
                        optionRenderer={props.formatOptionLabel}
                        inputId={field.name}
                        {...props}
                        {...field}
                        onChange={onChange}
                        onBlur={() => form.setFieldTouched(field.name)}
                        {...(props.disabled ? { disabled: true } : {})}
                    />
                ) : (
                    <Select
                        optionRenderer={props.formatOptionLabel}
                        inputId={field.name}
                        {...props}
                        {...field}
                        onChange={onChange}
                        onBlur={() => form.setFieldTouched(field.name)}
                        {...(props.disabled ? { disabled: true } : {})}
                    />
                )}
                {isTouched && error && <div className="text-help text-danger">{error}</div>}
                {helpText && <div className="form-text text-muted">{helpText}</div>}
            </div>
        </div>
    );
};
