import {} from 'formik';

const _FxGenericField = (passedProps) => {
    const { field, form, isRequired, ...props } = passedProps;
    const className = `form-group  row ${form.touched[field.name] && form.errors[field.name] ? 'has-danger' : ''}`;
    const labelClass = `${form.touched[field.name] && form.errors[field.name] ? 'text-danger' : ''}`;
    const handleChange = (event) => {
        field.onChange(event);
        if (passedProps.onChange) {
            passedProps.onChange(event);
        }
    };
    return (
        <div className={className}>
            {props.label && (
                <div className={`col-md-3 ${props.colClass}`}>
                    <label htmlFor={field.name} className={labelClass}>
                        {props.label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    </label>
                </div>
            )}
            <div className={`${props.label ? 'col-md-9' : 'col-md-12'}  ${props.colClass}`}>
                <input className="form-control rounded" {...field} {...props} onChange={handleChange} />
                {form.touched[field.name] && form.errors[field.name] ? (
                    <span className="help-block text-danger">{form.errors[field.name]}</span>
                ) : (
                    props.helptext && <span className="help-block">{props.helptext}</span>
                )}
            </div>
        </div>
    );
};

export default _FxGenericField;
