import axios from 'axios';
import { ROOT_API_URL } from 'constant';
export const GET_ENTITY_RENEWAL_TYPES = 'GET_ENTITY_RENEWAL_TYPES';
export const GET_ENTITY_RENEWAL_TYPE = 'GET_ENTITY_RENEWAL_TYPE';
export const DELETE_ENTITY_RENEWAL_TYPE = 'DELETE_ENTITY_RENEWAL_TYPE';
export const CREATE_ENTITY_RENEWAL_TYPE = 'CREATE_ENTITY_RENEWAL_TYPE';
export const UPDATE_ENTITY_RENEWAL_TYPE = 'UPDATE_ENTITY_RENEWAL_TYPE';
export const getEntityRenewalTypes = () => ({
    type: GET_ENTITY_RENEWAL_TYPES,
    promise: axios.get(`${ROOT_API_URL}entity_renewal_type/`),
});
export const getEntityRenewalType = (id) => ({
    type: GET_ENTITY_RENEWAL_TYPE,
    promise: axios.get(`${ROOT_API_URL}entity_renewal_type/${id}`),
});
export const deleteEntityRenewalType = (id) => ({
    type: DELETE_ENTITY_RENEWAL_TYPE,
    promise: axios.delete(`${ROOT_API_URL}entity_renewal_type/${id}`),
});
export const createEntityRenewalType = (data) => ({
    type: CREATE_ENTITY_RENEWAL_TYPE,
    promise: axios.post(`${ROOT_API_URL}entity_renewal_type/`, data),
});
export const updateEntityRenewalType = (id, data) => ({
    type: UPDATE_ENTITY_RENEWAL_TYPE,
    promise: axios.put(`${ROOT_API_URL}entity_renewal_type/${id}`, data),
});
