export const SERVICE_ENTRY_TABS = {
    OVERALL: 'Overall',
    PENDING_WITH_ME: 'Pending With Me',
};
export const VEHICLE_DOCUMENT_TABS = {
    OVERALL: 'Overall',
    PENDING_WITH_ME: 'Pending With Me',
};
export function getApprovalStatusColor(status) {
    switch (status) {
        case 'PENDING':
            return 'text-info';
        case 'APPROVED':
            return 'text-success';
        case 'REJECTED':
            return 'text-danger';
        case 'HOLD':
            return 'text-warning';
        default:
            return '';
    }
}
