import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from 'antd';
const FxSwitch = (props) => {
    const { className, label, ...rest } = props;
    if (props.label) {
        return (_jsxs("div", { className: className, children: [_jsx(Switch, { ...rest, className: `mr-1` }), " ", _jsx("span", { children: label })] }));
    }
    else {
        return _jsx(Switch, { ...rest });
    }
};
export default FxSwitch;
