import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from 'constant';
export const GET_ENTITY_REMINDER_SUMMARY = 'GET_ENTITY_REMINDER_SUMMARY';
export const GET_ENTITY_REMINDER_LISTING = 'GET_ENTITY_REMINDER_LISTING';
export const GET_ENTITY_REMINDER_BY_ID = 'GET_ENTITY_REMINDER_BY_ID';
export const CREATE_ENTITY_REMINDER = 'CREATE_ENTITY_REMINDER';
export const UPDATE_ENTITY_REMINDER = 'UPDATE_ENTITY_REMINDER';
export const DELETE_ENTITY_REMINDER = 'DELETE_ENTITY_REMINDER';
export const SEND_ENTITY_REMINDER_NOTIFICATION = 'SEND_ENTITY_REMINDER_NOTIFICATION';
export const GET_ENTITY_REMINDER_NOTIFICATION_DATA = 'GET_ENTITY_REMINDER_NOTIFICATION_DATA';
export const getEntityReminderSummary = (accesstoken) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({}),
    };
    const request = axios.get(`${ROOT_API_URL}entity_renewal_reminder/summary`, config);
    return {
        type: GET_ENTITY_REMINDER_SUMMARY,
        promise: request,
    };
};
export const getEntityReminderListing = (accesstoken, params) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}entity_renewal_reminder/listing`, config);
    return {
        type: GET_ENTITY_REMINDER_LISTING,
        promise: request,
    };
};
export const getEntityReminderById = (accesstoken, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({}),
    };
    const request = axios.get(`${ROOT_API_URL}entity_renewal_reminder/${id}`, config);
    return {
        type: GET_ENTITY_REMINDER_BY_ID,
        promise: request,
    };
};
export const createEntityReminder = (accesstoken, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.post(`${ROOT_API_URL}entity_renewal_reminder/`, data, config);
    return {
        type: CREATE_ENTITY_REMINDER,
        promise: request,
    };
};
export const updateEntityReminder = (accesstoken, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.put(`${ROOT_API_URL}entity_renewal_reminder/update`, data, config);
    return {
        type: UPDATE_ENTITY_REMINDER,
        promise: request,
    };
};
export const deleteEntityReminder = (accesstoken, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.delete(`${ROOT_API_URL}entity_renewal_reminder/${id}`, config);
    return {
        type: DELETE_ENTITY_REMINDER,
        promise: request,
    };
};
export const sendEntityReminderNotification = (accesstoken, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.get(`${ROOT_API_URL}entity_renewal_reminder/${id}/sendNotifications`, config);
    return {
        type: SEND_ENTITY_REMINDER_NOTIFICATION,
        promise: request,
    };
};
export const getEntityReminderNotificationData = (accesstoken, ids) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            ids: ids ? ids.join(',') : null,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}entity_renewal_reminder/notifications`, config);
    return {
        type: GET_ENTITY_REMINDER_NOTIFICATION_DATA,
        promise: request,
    };
};
