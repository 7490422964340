const API_ID = 'WgCc2kv9F74wAOFy';
const delightedKey = 'delighted';

import { isLocalStorageAvailable } from './reports/feature_availability_check_utils';

export async function initDelighted(
    accountId,
    accountName,
    accountStatus,
    userId,
    name,
    email,
    phoneNumber,
    userStatus,
    accountManager
) {
    const isActive = accountStatus === 'ACTIVE';

    if (!isActive) {
        return;
    }

    if (process.env.ENV !== 'production' || !isLocalStorageAvailable()) {
        return;
    }

    const initNow = async () => {
        if (window.delighted) {
            window.delighted.survey({
                email: email, // customer email (optional)
                name: name, // customer name (optional),
                forceDisplay: true,
                properties: {
                    // extra context (optional)
                    company: accountName,
                    accountId: accountId,
                    accountManager,
                },
            });
        }
    };

    if (!window.delighted) {
        if (!window[delightedKey]) {
            for (
                var a = (window[delightedKey] = []),
                    i = [
                        'survey',
                        'reset',
                        'config',
                        'init',
                        'set',
                        'get',
                        'event',
                        'identify',
                        'track',
                        'page',
                        'screen',
                        'group',
                        'alias',
                    ],
                    c = 0;
                c < i.length;
                c++
            ) {
                var s = i[c];
                a[s] =
                    a[s] ||
                    (function (e) {
                        return function () {
                            var t = Array.prototype.slice.call(arguments);
                            a.push([e, t]);
                        };
                    })(s);
            }
            a.SNIPPET_VERSION = '1.0.1';
        }

        const scriptSrc = `https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/${API_ID}/${delightedKey}.js`;
        const o = document.createElement('script');
        (o.type = 'text/javascript'), (o.async = !0), (o.src = scriptSrc);

        o.onload = () => {
            initNow();
        };
        document.body.appendChild(o);
    }
}
