import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const AddEditManualVoucherComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-voucher-add-edit" */ 'components/dashboard/vouchers/forms/AddEditManualVoucherComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VoucherComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-voucher-vouchers" */ 'components/dashboard/vouchers/VoucherComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const voucherRoutes = [
    {
        path: 'add',
        lazy: AddEditManualVoucherComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditManualVoucherComponent,
    },
    {
        path: 'all',
        lazy: VoucherComponent,
    },
];
export default voucherRoutes;
