import { forEach, get, includes, map, set, sumBy, values } from 'lodash';
import { isFormFieldRequired } from 'utils/accountUtils/common';
import { FLEETX_ACCOUNTS, isLedgerbasedtripsheetDriverBalance } from 'utils/account_utils';

export const EXPENSE_TYPES = {
    CASH_ADVANCE: 'CASH_ADVANCE',
    CASH_EXPENSE: 'CASH_EXPENSE',
    FASTAG_EXPENSE: 'FASTAG_EXPENSE',
    FUEL_ADVANCE: 'FUEL_ADVANCE',
    FUEL_EXPENSE: 'FUEL_EXPENSE',
    FASTAG_ADVANCE: 'FASTAG_ADVANCE',
    UREA_EXPENSE: 'UREA_EXPENSE',
    UREA_ADVANCE: 'UREA_ADVANCE',
    EMI_EXPENSE: 'EMI_EXPENSE',
};

export const EXPENSE_TYPES_LABELS = {
    CASH_ADVANCE: 'Cash Advance',
    CASH_EXPENSE: 'Cash Expense',
    FASTAG_EXPENSE: 'FASTag Expense',
    FUEL_ADVANCE: 'Fuel Advance',
    FUEL_EXPENSE: 'Fuel Expense',
    FASTAG_ADVANCE: 'FASTag Advance',
    UREA_EXPENSE: 'Urea Expense',
    UREA_ADVANCE: 'Urea Advance',
    EMI_EXPENSE: 'Emi Expense',
};
export const TRANSACTION_TYPES = {
    ADVANCE: 'ADVANCE',
    EXPENSE: 'EXPENSE',
};
export const TRANSACTION_TYPES_LABELS = {
    ADVANCE: 'Advance',
    EXPENSE: 'Expense',
};
export const EXPENSE_TYPES_TRANSACTION_TYPES = {
    CASH_ADVANCE: TRANSACTION_TYPES['ADVANCE'],
    CASH_EXPENSE: TRANSACTION_TYPES['EXPENSE'],
    FASTAG_EXPENSE: TRANSACTION_TYPES['EXPENSE'],
    FUEL_ADVANCE: TRANSACTION_TYPES['ADVANCE'],
    FUEL_EXPENSE: TRANSACTION_TYPES['EXPENSE'],
    FASTAG_ADVANCE: TRANSACTION_TYPES['ADVANCE'],
    UREA_ADVANCE: TRANSACTION_TYPES['ADVANCE'],
    UREA_EXPENSE: TRANSACTION_TYPES['EXPENSE'],
    EMI_EXPENSE: TRANSACTION_TYPES['EXPENSE'],
};

export const mappedExpenseTypes = () => {
    return map(EXPENSE_TYPES, (value, key) => {
        return { value, label: EXPENSE_TYPES_LABELS[key] };
    });
};
export const mappedTransactionTypes = () => {
    return map(TRANSACTION_TYPES, (value, key) => {
        return { value, label: TRANSACTION_TYPES_LABELS[key] };
    });
};

export const getLedgerDetailFields = () => [
    {
        name: 'debitLedgerId',
        label: 'Debit Ledger',
        shouldShowAmountField: false,
        isRequired: isFormFieldRequired(),
        colSize: 12,
    },
    {
        name: 'creditLedgerId',
        label: 'Credit Ledger',
        shouldShowAmountField: false,
        isRequired: isFormFieldRequired(),
        colSize: 12,
    },
];

export const mappedExpenseNames = (expenses, otherParams = {}) => {
    const valueAsName = get(otherParams, 'valueAsName', false);
    return map(expenses, (expense) => {
        return {
            value: valueAsName ? get(expense, 'expenseName') : get(expense, 'id'),
            label: `${get(expense, 'expenseName')} (${get(expense, 'transactionType')})`,
        };
    });
};

export const isNewExpenseFlowEnabled = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
};

export const expenseTypeHandler = (jobsData) => {
    let expenseNameList = {};
    forEach(jobsData, (job, i) => {
        let expenseNameCurrentJob = get(job, 'transactionExpenseType.expenseName', '');
        if (expenseNameCurrentJob?.endsWith('.')) {
            expenseNameCurrentJob = expenseNameCurrentJob?.slice(0, -1);
        }
        const transactionType = get(job, 'transactionExpenseType.transactionType');
        const includeInSettlement = get(job, 'transactionExpenseType.includeInSettlement');
        // if (!includeInSettlement) return;
        const crCurrent = get(expenseNameList, `${expenseNameCurrentJob}.CR`, 0);
        const drCurrent = get(expenseNameList, `${expenseNameCurrentJob}.DR`, 0);
        const transactionTypesCredit = [TRANSACTION_TYPES.EXPENSE];
        if (expenseNameCurrentJob) {
            if (get(expenseNameList, expenseNameCurrentJob)) {
                if (includes(transactionTypesCredit, transactionType)) {
                    expenseNameList[expenseNameCurrentJob].CR = crCurrent + get(job, 'amount');
                } else {
                    expenseNameList[expenseNameCurrentJob].DR = drCurrent + get(job, 'amount');
                }
            } else {
                if (includes(transactionTypesCredit, transactionType)) {
                    expenseNameList[expenseNameCurrentJob] = {
                        CR: crCurrent + get(job, 'amount'),
                        DR: 0,
                        includeInSettlement,
                    };
                } else {
                    expenseNameList[expenseNameCurrentJob] = {
                        DR: drCurrent + get(job, 'amount'),
                        CR: crCurrent,
                        includeInSettlement,
                    };
                }
            }
        }
    });

    return expenseNameList;
};

export const getClosingStatementData = (transactions, formValues) => {
    const expenseList = expenseTypeHandler(transactions);
    const advancePayment = sumBy(values(expenseList), (item) => (item.includeInSettlement ? item.CR : 0));
    const totalExpense = sumBy(values(expenseList), (item) => (item.includeInSettlement ? item.DR : 0));
    const openingBalance = get(formValues, 'openingBalance');
    const fuelOpeningBalance = get(formValues, 'fuelOpeningBalance');
    const closingBalance = openingBalance + totalExpense - advancePayment;
    let fuelClosingBalance = fuelOpeningBalance;
    forEach(transactions, function (jobTransaction) {
        if (get(jobTransaction, 'expenseType') == EXPENSE_TYPES.FUEL_EXPENSE) {
            fuelClosingBalance -= get(jobTransaction, 'fuelQuantity', 0);
        }
        if (get(jobTransaction, 'expenseType') == EXPENSE_TYPES.FUEL_ADVANCE) {
            fuelClosingBalance += get(jobTransaction, 'fuelQuantity', 0);
        }
    });
    return {
        totalExpense,
        advancePayment,
        closingBalance,
        openingBalance,
        fuelOpeningBalance,
        fuelClosingBalance,
    };
};

export const expense_name_tooltip_message = `User can now add their own expense type from the settings`;
