/**
 * Created by udbhav on 18/06/18.
 */

import { handle } from 'redux-pack';
import {
    FETCH_ISSUES_SUMMARY_BACKGROUND_V2,
    FETCH_ISSUE_BY_ID,
    FETCH_ISSUES_V2,
    FETCH_ISSUES_SUMMARY_V2,
    FETCH_ISSUE_BY_SUB_TYPE,
} from '../../actions/dashboard/issues_action';
import { arrayToObject } from '../../utils/list_utils';

const initialState = {
    isLoading: false,
    isIssuesLoading: false,
    isIssuesSummaryLoading: false,
    error: null,
    isFinished: false,
    issuesList: [],
    totalElements: 0,
    issueById: {},
    isIssueByIdLoading: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ISSUES_V2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isIssuesLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true, isIssuesLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    issuesList: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        case FETCH_ISSUE_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isIssueByIdLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isIssueByIdLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    issueById: payload.data,
                }),
            });
        case FETCH_ISSUES_SUMMARY_V2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isIssuesSummaryLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isIssuesSummaryLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    issuesSummary: payload.data,
                }),
            });
        case FETCH_ISSUES_SUMMARY_BACKGROUND_V2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    issuesSummaryBackground: payload.data,
                }),
            });
        case FETCH_ISSUE_BY_SUB_TYPE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    alertIssuesCountPerVehicleAsMap: arrayToObject(payload.data, 'vehicleId'),
                }),
            });
        default:
            return state;
    }
}
