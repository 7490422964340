import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditPartEntriesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-partentries-addEditPartEntries" */ 'components/dashboard/parts_inventory/part_entry/add_edit_part_entry_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddMultiplePartEnteries = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-partentries-addMultiplePartEnteries" */ 'components/dashboard/parts_inventory/part_entry/add_multiple_part_entries'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const PartEntriesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-partentries-PartEntriesComponent" */ 'components/dashboard/parts_inventory/part_entry/part_entries_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewPartEntryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-partentries-ViewPartEntryComponent" */ 'components/dashboard/parts_inventory/part_entry/view_part_entry_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const PartAuditHistoryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-partentries-PartAuditHistoryComponent" */ 'components/dashboard/parts_inventory/partAudit/PartAuditHistoryComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const partentriesRoutes = [
    {
        path: 'add',
        lazy: AddEditPartEntriesComponent,
    },
    {
        path: 'addMultiple',
        lazy: AddMultiplePartEnteries,
    },
    {
        path: 'edit/:id',
        lazy: AddEditPartEntriesComponent,
    },
    {
        path: 'editMultiple/:id',
        lazy: AddMultiplePartEnteries,
    },
    {
        path: 'view/:id',
        lazy: ViewPartEntryComponent,
    },
    {
        path: 'partsaudithistory',
        lazy: PartAuditHistoryComponent,
    },
    {
        index: true,
        lazy: PartEntriesComponent,
    },
];
export default partentriesRoutes;
