import { handle } from 'redux-pack';
import {
    CREATE_ADDRESS,
    DELETE_ADDRESS,
    FETCH_ADDRESS,
    FETCH_ADDRESS_BOOK,
    UPDATE_ADDRESS,
    FETCH_ADDRESS_BOOK_PAGINATED,
    FETCH_ADDRESS_BOOK_MINI_LIST,
} from '../../../actions/dashboard/route_dispatch/address_book_action';
import { preCalcAddressbook, createQuadTreeFromAddressBook } from '../../../utils/map/geocode_utils';
import { arrayToObject } from '../../../utils/list_utils';
import { isQuadTreeSearchOpenForAccount } from '../../../utils/account_utils';
import { getFilteredAddressBookForNearestAddress } from 'utils/address_book_utils';

const initialState = {
    isLoading: false,
    error: null,
    data: {},
    addressBook: [],
    addressBookAsMap: {},
    addressBookMiniList: [],
    addressBookMiniAsMap: {},
    addressBookMiniAsQT: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_ADDRESS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_ADDRESS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_ADDRESS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });

        case FETCH_ADDRESS_BOOK:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, isLoading: false }),
                success: (prevState) => {
                    const address = preCalcAddressbook(payload.data);
                    let addressBookAsQT = {};
                    if (isQuadTreeSearchOpenForAccount(window.FLEETX_LOGGED_IN_USER)) {
                        addressBookAsQT = createQuadTreeFromAddressBook(
                            getFilteredAddressBookForNearestAddress(address)
                        );
                    }
                    return {
                        ...prevState,
                        addressBook: address,
                        addressBookAsMap: arrayToObject(address, 'id'),
                        addressBookAsQT: addressBookAsQT,
                        isLoading: false,
                    };
                },
            });
        case FETCH_ADDRESS_BOOK_MINI_LIST:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, isLoading: false }),
                success: (prevState) => {
                    const address = preCalcAddressbook(payload.data);
                    let addressBookAsQT = {};
                    if (isQuadTreeSearchOpenForAccount(window.FLEETX_LOGGED_IN_USER)) {
                        addressBookAsQT = createQuadTreeFromAddressBook(
                            getFilteredAddressBookForNearestAddress(address)
                        );
                    }
                    return {
                        ...prevState,
                        addressBookMiniList: payload.data,
                        addressBookMiniAsMap: arrayToObject(payload.data, 'id'),
                        addressBookMiniAsQT: addressBookAsQT,
                        isLoading: false,
                    };
                },
            });

        case FETCH_ADDRESS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        default:
            return state;
    }
}
