import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const ExpensesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-expenses" */ 'components/dashboard/expenses/ExpensesComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditExpensesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-add-edit-expenses" */ 'components/dashboard/expenses/AddEditExpensesComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const expenseRoutes = [
    {
        index: true,
        lazy: ExpensesComponent,
    },
    {
        path: 'add',
        lazy: AddEditExpensesComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditExpensesComponent,
    },
];
export default expenseRoutes;
