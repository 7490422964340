export function getCaret(direction) {
    if (direction === 'asc') {
        return <i className="fa fa-caret-up" style={{ margin: '0 5px' }} />;
    }
    if (direction === 'desc') {
        return <i className="fa fa-caret-down" style={{ margin: '0 5px' }} />;
    }
    return <i className="fa fa-sort" style={{ margin: '0 5px' }} />;
}

export function alternateRowClassNameFormat(row, rowIndex) {
    const rowBgColor = rowIndex % 2 === 0 ? `white-background` : `grey-background`;
    return `cursor-pointer ${rowBgColor}`;
}

export function getCaretV2(direction) {
    if (direction === 'asc') {
        return (
            <span>
                {' '}
                <i className="fa fa-caret-up" />
            </span>
        );
    }
    if (direction === 'desc') {
        return (
            <span>
                {' '}
                <i className="fa fa-caret-down" />
            </span>
        );
    }
    return (
        <span>
            {' '}
            <i className="fa fa-sort" />
        </span>
    );
}
