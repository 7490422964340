import { includes, map, get } from 'lodash';
import {
    GOOGLE_MAPS_WHITE_LABEL,
    GOOGLE_MAPS_WHITE_LABEL_RIGHT_CHOICE,
    GOOGLE_MAPS_API_KEY_PREMIUM_OG,
    GOOGLE_MAPS_WHITE_LABEL_ACCEX,
    GOOGLE_MAPS_WHITE_LABEL_SHELL,
} from '../dashboard/src/utils/map/map_utils';
import { FLEETX_ACCOUNTS, getAllShellAccountIds } from 'utils/account_utils';

export const ALL_WHITE_LABEL_URLS = {
    FLEETX_INTERNAL: 'https://icici.fleetx.io',
    ICICI_LOMBARD: 'https://www.ilogman.com',
    RIGHT_CHOICE: 'https://www.rightchoicedubai.com',
    ACCEX: 'https://connect.accexscs.com',
    SHELL: 'https://shelltelematics.in',
};
//SHOORA: 'https://fms.shoora.com',
export const whiteLabelPrettyName = {
    FLEETX_INTERNAL: 'Fleetx White Label',
    ICICI_LOMBARD: 'ILogMan',
    RIGHT_CHOICE: 'Right Choice',
    ACCEX: 'Accex Supply Chain Solutions',
    SHELL: 'Shell Telematics',
};

//SHOORA: 'Shoora',

export function isWhiteLabelUrl() {
    const WHITE_LABEL_URLS = Object.values(ALL_WHITE_LABEL_URLS);
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);

    if (accountId && includes([...getAllShellAccountIds()], accountId)) {
        return true;
    }

    if (window && window.location) {
        const url = 'https://' + window.location.hostname;
        return includes(WHITE_LABEL_URLS, url);
    }
    return false;
}

export function isShellWhiteLabelUrl() {
    if (window && window.location) {
        const url = 'https://' + window.location.hostname;
        return includes(ALL_WHITE_LABEL_URLS.SHELL, url);
    }
    return false;
}

export const getWhiteLabelMapKey = () => {
    const WHITE_LABEL_URLS = Object.values(ALL_WHITE_LABEL_URLS);
    if (window && window.location) {
        const url = 'https://' + window.location.hostname;
        switch (url) {
            case ALL_WHITE_LABEL_URLS.ICICI_LOMBARD:
                return GOOGLE_MAPS_WHITE_LABEL;
            case ALL_WHITE_LABEL_URLS.RIGHT_CHOICE:
                return GOOGLE_MAPS_WHITE_LABEL_RIGHT_CHOICE;
            case ALL_WHITE_LABEL_URLS.ACCEX:
                return GOOGLE_MAPS_WHITE_LABEL_ACCEX;
            case ALL_WHITE_LABEL_URLS.SHELL:
                return GOOGLE_MAPS_WHITE_LABEL_SHELL;
            default:
                return GOOGLE_MAPS_API_KEY_PREMIUM_OG;
        }
    }
};
// case ALL_WHITE_LABEL_URLS.SHOORA:
//     return GOOGLE_MAPS_WHITE_LABEL_SHOORA;

export const TOGGLE_VALUE = [
    {
        name: 'Transaction',
        value: 'transaction',
    },
    {
        name: 'FAStag',
        value: 'fastag',
    },
    {
        name: 'Happay',
        value: 'happay',
    },
];

export const getPageTitle = (pageName) => {
    let title = `${pageName} - fleetx.io`;
    if (window && window.location) {
        const url = 'https://' + window.location.hostname;
        map(ALL_WHITE_LABEL_URLS, (wlUrl, index) => {
            if (wlUrl == url) {
                title = `${pageName} -  ${whiteLabelPrettyName[index]}`;
            }
        });
    }
    return title;
};

export const getWhiteLabelPrettyName = () => {
    let title = 'Fleetx';
    if (window && window.location) {
        const url = 'https://' + window.location.hostname;
        map(ALL_WHITE_LABEL_URLS, (wlUrl, index) => {
            if (wlUrl == url) {
                title = `${whiteLabelPrettyName[index]}`;
            }
        });
    }
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);

    if (includes(getAllShellAccountIds(), accountId)) {
        title = 'Shell';
    }
    return title;
};

export const isShellAccount = () => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes(getAllShellAccountIds(), accountId);
};
