import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BG_COLOR_PAGE } from '../../../theme/colors';
import { getPageTitle } from '../../../../white_label_constants';
import { Helmet } from 'react-helmet';
import { FxFlex } from '../../../sharedV2';
import PageControlPanelComponent from './PageControlPanelComponent';
import PageContentComponent from './PageContentComponent';
const PageLayoutComponent = (props) => {
    const { noPageControlPanel, pageLabel, pageSubLabel, tabs, size = 'small', pageIcon, tabTitle = '', skipBgColor, noGap, labelSuffix, } = props;
    return (_jsxs("div", { className: "animated fadeIn flex-1 h-100", style: {
            background: skipBgColor ? undefined : BG_COLOR_PAGE,
            margin: '0 -12px',
        }, children: [_jsx(Helmet, { title: getPageTitle(tabTitle) }), _jsxs(FxFlex, { vertical: true, gap: noGap ? 0 : 8, children: [!noPageControlPanel && (_jsx(PageControlPanelComponent, { icon: pageIcon, pageLabel: pageLabel, pageSubLabel: pageSubLabel, tabs: tabs, labelSuffix: labelSuffix, children: props.children && props.children[0] })), _jsx(PageContentComponent, { children: props.children && props.children[1] })] })] }));
};
export default PageLayoutComponent;
