export const RATE_CRITERIAS = {
    FIXED: 'FIXED',
    INCREMENTAL: 'INCREMENTAL',
    PRORATA: 'PRORATA',
};
export const RATE_CRITERIAS_LABEL = {
    FIXED: 'FIXED',
    INCREMENTAL: 'INCREMENTAL',
    PRORATA: 'PRORATA',
};
export const RATE_OPTIONS = {
    WEIGHT: 'WEIGHT',
    PIECES: 'PIECES',
    LENGTH: 'LENGTH',
    FIXED: 'FIXED',
    KM: 'KM',
    WEIGHT_KM: 'WEIGHT_KM',
    PIECES_KM: 'PIECES_KM',
    CHARGE_QTY: 'CHARGE_QTY',
    PIECES_QTY: 'PIECES_QTY',
    CHARGE_QTY_KM: 'CHARGE_QTY_KM',
    PIECES_QTY_KM: 'PIECES_QTY_KM',
};
export const RATE_OPTIONS_LABEL = {
    WEIGHT: 'WEIGHT',
    PIECES: 'PIECES',
    LENGTH: 'LENGTH',
    FIXED: 'FIXED',
    KM: 'KM',
    WEIGHT_KM: 'WEIGHT X KM',
    PIECES_KM: 'PIECES X KM',
    CHARGE_QTY: 'CHARGE QTY',
    PIECES_QTY: 'PIECES QTY',
    CHARGE_QTY_KM: 'CHARGE QTY KM',
    PIECES_QTY_KM: 'PIECES QTY KM',
};
export const CODE_TYPES = {
    HSN: 'HSN',
    SAC: 'SAC',
};
export const MODULE_TYPE = {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
};
export const CALCULATION_TYPES = {
    CN: 'CN',
    CN_MM: 'CN_MM',
};
export const CALCULATION_TYPES_LABEL = {
    CN: 'CN',
    CN_MM: 'CN & MM',
};
export const TRIP_NATURE = {
    LOAD_LOAD: 'LOAD_LOAD',
    EMPTY_LOAD: 'EMPTY_LOAD',
    LOAD_EMPTY: 'LOAD_EMPTY',
};
export const TRIP_NATURE_LABEL = {
    LOAD_LOAD: 'LOAD + LOAD',
    EMPTY_LOAD: 'EMPTY + LOAD',
    LOAD_EMPTY: 'LOAD + EMPTY',
};
export const DELIVERY_TYPES_CONTRACT = {
    ONE_WAY_EMPTY: 'ONE_WAY_EMPTY',
    ONE_WAY_LOAD: 'ONE_WAY_LOAD',
    ROUND_TRIP_LOAD_EMPTY: 'ROUND_TRIP_LOAD_EMPTY',
    ROUND_TRIP_LOAD_LOAD: 'ROUND_TRIP_LOAD_LOAD',
};
export const DELIVERY_TYPES_CONTRACT_LABEL = {
    ONE_WAY_EMPTY: 'One Way Empty',
    ONE_WAY_LOAD: 'One Way Load',
    ROUND_TRIP_LOAD_EMPTY: 'Round Trip Load Empty',
    ROUND_TRIP_LOAD_LOAD: 'Round Trip Load Load',
};
export const TRIP_NATURE_CONTRACT = {
    NORMAL: 'NORMAL',
    OVERLOAD: 'OVERLOAD',
    EXPRESS: 'EXPRESS',
};
export const TRIP_NATURE_CONTRACT_LABEL = {
    NORMAL: 'NORMAL',
    OVERLOAD: 'OVERLOAD',
    EXPRESS: 'EXPRESS',
};
export const DELIVERY_TYPES = {
    NORMAL: 'NORMAL',
    OVERLOAD: 'OVERLOAD',
    EXPRESS: 'EXPRESS',
};
export const DELIVERY_TYPES_LABEL = {
    NORMAL: 'NORMAL',
    OVERLOAD: 'OVERLOAD',
    EXPRESS: 'EXPRESS',
};
export const RATE_TYPES = {
    HOUR: 'HOUR',
    KM: 'KM',
    DAY: 'DAY',
    FIXED: 'FIXED',
    PER_TRIP: 'PER_TRIP',
};
export const RATE_TYPES_LABELS = {
    HOUR: 'HOUR',
    KM: 'KM',
    DAY: 'DAY',
    FIXED: 'FIXED',
    PER_TRIP: 'PER TRIP',
};
export const CONTRACT_RATE_TYPES_ADDITIONAL = {
    EXTRA_KM_PER_BILLING_CYCLE: 'EXTRA_KM_PER_BILLING_CYCLE',
    KM_PER_JOB: 'KM_PER_JOB',
    EXTRA_HOURS_PER_JOB: 'EXTRA_HOURS_PER_JOB',
    FIXED_PER_JOB_ADDITIONAL: 'FIXED_PER_JOB_ADDITIONAL',
    FIXED_PER_BILLING_CYCLE_ADDITIONAL: 'FIXED_PER_BILLING_CYCLE_ADDITIONAL',
    HILL_KM: 'HILL_KM',
    SLAB_WISE_KM: 'SLAB_WISE_KM',
    EXTRA_KM_PER_BILLING_CYCLE_PER_DISTINCT_VEHICLE: 'EXTRA_KM_PER_BILLING_CYCLE_PER_DISTINCT_VEHICLE',
};
export const CONTRACT_RATE_TYPES_ADDITIONAL_LABELS = {
    EXTRA_KM_PER_BILLING_CYCLE: 'EXTRA KM PER BILLING CYCLE',
    KM_PER_JOB: 'KM PER JOB',
    EXTRA_HOURS_PER_JOB: 'EXTRA HOURS PER JOB',
    FIXED_PER_JOB_ADDITIONAL: 'FIXED PER JOB ADDITIONAL',
    FIXED_PER_BILLING_CYCLE_ADDITIONAL: 'FIXED PER BILLING CYCLE ADDITIONAL',
    HILL_KM: 'HILL KM PER JOB',
    SLAB_WISE_KM: 'SLAB WISE KM',
    EXTRA_KM_PER_BILLING_CYCLE_PER_DISTINCT_VEHICLE: 'EXTRA KM PER BILLING CYCLE PER DISTINCT VEHICLE',
};
export const CONTRACT_RATE_TYPES = {
    HOUR: 'HOUR',
    KM: 'KM',
    DAY: 'DAY',
    FIXED_PER_JOB: 'FIXED_PER_JOB',
    FIXED_PER_BILLING_CYCLE: 'FIXED_PER_BILLING_CYCLE',
    FIXED_PER_BILLING_CYCLE_PER_DISTINCT_VEHICLE: 'FIXED_PER_BILLING_CYCLE_PER_DISTINCT_VEHICLE',
    STEP_RATE_PER_VEHICLE_PER_BILLING_CYCLE: 'STEP_RATE_PER_VEHICLE_PER_BILLING_CYCLE',
};
export const CONTRACT_RATE_TYPES_LABELS = {
    HOUR: 'HOUR',
    KM: 'KM',
    DAY: 'DAY',
    FIXED_PER_JOB: 'FIXED PER JOB',
    FIXED_PER_BILLING_CYCLE: 'FIXED PER BILLING CYCLE',
    FIXED_PER_BILLING_CYCLE_PER_DISTINCT_VEHICLE: 'FIXED PER BILLING CYCLE PER DISTINCT VEHICLE',
    STEP_RATE_PER_VEHICLE_PER_BILLING_CYCLE: 'STEP RATE PER VEHICLE PER BILLING CYCLE',
};
export const BILLING_NATURE = {
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    YEARLY: 'YEARLY',
};
export const BILLING_NATURE_LABEL = {
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    YEARLY: 'YEARLY',
};
export const UNIT_TYPES = {
    COUNT: 'COUNT',
    WEIGHT: 'WEIGHT',
    VOLUME: 'VOLUME',
    AREA: 'AREA',
    LENGTH: 'LENGTH',
};
export const UNIT_TYPES_LABELS = {
    COUNT: 'COUNT',
    WEIGHT: 'WEIGHT',
    VOLUME: 'VOLUME',
    AREA: 'AREA',
    LENGTH: 'LENGTH',
};
export const UNIT_TYPE_TO_UNITS_MAP = {
    COUNT: {
        BAGS: 'BAGS',
        BUNDLES: 'BUNDLES',
        BUCKLES: 'BUCKLES',
        BILLION_OF_UNIT: 'BILLION_OF_UNIT',
        BOX: 'BOX',
        BOTTLES: 'BOTTLES',
        BUNCHES: 'BUNCHES',
        CANS: 'CANS',
        CARTONS: 'CARTONS',
        DOZENS: 'DOZENS',
        DRUMS: 'DRUMS',
        GREAT_GROSS: 'GREAT_GROSS',
        GROSS: 'GROSS',
        NUMBERS: 'NUMBERS',
        PACKS: 'PACKS',
        PIECES: 'PIECES',
        PAIRS: 'PAIRS',
        ROLLS: 'ROLLS',
        SETS: 'SETS',
        TABLETS: 'TABLETS',
        TEN_GROSS: 'TEN_GROSS',
        THOUSANDS: 'THOUSANDS',
        TUBES: 'TUBES',
        UNITS: 'UNITS',
        OTHERS: 'OTHERS',
    },
    WEIGHT: {
        BALE: 'BALE',
        KILOGRAMS: 'KILOGRAMS',
        METRIC_TON: 'METRIC_TON',
        QUINTAL: 'QUINTAL',
        TONNES: 'TONNES',
        GRAMMES: 'GRAMMES',
    },
    VOLUME: {
        US_GALLONS: 'US_GALLONS',
        MILLILITRE: 'MILLILITRE',
        LITRES: 'LITRES',
        KILOLITRE: 'KILOLITRE',
        CENTIMETERS: 'CENTIMETERS',
        CUBIC_CENTIMETERS: 'CUBIC_CENTIMETERS',
        CUBIC_METERS: 'CUBIC_METERS',
        BULK_LITRE: 'BULK_LITRE',
        BARREL_LITER: 'BARREL_LITER',
    },
    AREA: {
        SQUARE_FEET: 'SQUARE_FEET',
        SQUARE_METERS: 'SQUARE_METERS',
        SQUARE_YARDS: 'SQUARE_YARDS',
    },
    LENGTH: {
        GROSS_YARDS: 'GROSS_YARDS',
        METERS: 'METERS',
        KILOMETRE: 'KILOMETRE',
        YARDS: 'YARDS',
        INCH: 'INCH',
        FEET: 'FEET',
    },
};
export const CHARGE_CALCULATION_TYPES = {
    FIXED: 'FIXED',
    PERCENTAGE: 'PERCENTAGE',
    PER_UNIT: 'PER_UNIT',
    PER_PIECES: 'PER_PIECES',
};
export const CHARGE_CALCULATION_TYPES_LABEL = {
    FIXED: 'FIXED',
    PERCENTAGE: 'PERCENTAGE',
    PER_UNIT: 'PER UNIT',
    PER_PIECES: 'PER PIECES',
};
export const ADDITIONAL_CHARGE_FIELD_CONFIG = [
    {
        value: 'discount',
        label: 'Discount',
    },
    {
        value: 'lrCharge',
        label: 'LR Charge',
    },
    {
        value: 'surcharge',
        label: 'Surcharge',
    },
    {
        value: 'labourLoading',
        label: 'Labour Loading',
    },
    {
        value: 'labourUnloading',
        label: 'Labour Unloading',
    },
    {
        value: 'cartage',
        label: 'Cartage',
    },
    {
        value: 'detention',
        label: 'Detention',
    },
    {
        value: 'detentionLoading',
        label: 'Detention Loading',
    },
    {
        value: 'dcCharge',
        label: 'DC Charge',
    },
    {
        value: 'previousFreightUnload',
        label: 'Previous Freight Unload',
    },
    {
        value: 'rcCharge',
        label: 'RC Charge',
    },
    {
        value: 'otherValue',
        label: 'Other Value',
    },
    {
        value: 'withPass',
        label: 'With Pass',
    },
    {
        value: 'handlingCharge',
        label: 'Handling Charge',
    },
    {
        value: 'rebook',
        label: 'Rebook',
    },
];
