import { DATE_FORMAT_TIMESTAMP, getMomentTime } from '../../../../utils/date_utils';
import { API_NAME, mappedDrivers, mappedTagsBranch, mappedTransporters, mappedVehiclesMini, mappedVehicleState, } from '../../../../utils/form_utils';
import { JOB_FORM_VEHICLE_STATE } from '../../../../utils/job_route_utils';
import * as Yup from 'yup';
import { get } from 'lodash';
import { handleVehicleChange } from '../../../utils/manual_job_utils';
import { mappedTmsConsignments } from '../../../../utils/tmsConsignmentsUtils';
const addJobComponentSchema = Yup.object().shape({
    name: Yup.string().required('Job name cannot be empty').nullable(),
    vehicle: Yup.object().shape({
        id: Yup.string().required('Please select a vehicle').nullable(),
    }),
    route: Yup.object().shape({
        routePoints: Yup.array().of(Yup.object().shape({
            address: Yup.string().required('Address is required').nullable(),
            scheduleArrival: Yup.string().nullable(),
            scheduleDeparture: Yup.string()
                .test('scheduledDepartureTest', 'Dispatch time cannot be before reporting time', function (value) {
                const scheduleArrival = get(this.options, 'parent.scheduleArrival', 0);
                const scheduleDeparture = get(this.options, 'parent.scheduleDeparture', 0);
                if (scheduleArrival &&
                    scheduleDeparture &&
                    getMomentTime(scheduleArrival).valueOf() <= getMomentTime(scheduleDeparture).valueOf()) {
                    return true;
                }
                else if (scheduleArrival && scheduleDeparture) {
                    return false;
                }
                else {
                    return true;
                }
            })
                .nullable(),
        })),
    }),
});
export const NSL_CONFIG = {
    fields: {
        'consignment.id': {
            type: 'select',
            label: 'Consignment',
            isOptionFetchRequired: true,
            apiName: API_NAME.CONSIGNMENTS,
            optionFunc: mappedTmsConsignments,
            colClassName: 'col-12 col-md-12',
        },
        'name': { type: 'text', label: 'Job Name', isRequired: true, className: 'col col-md-6' },
        'customFields.243': {
            type: 'select',
            label: 'Branch Name',
            className: 'col col-md-6',
            isOptionFetchRequired: true,
            apiName: API_NAME.TAGS,
            optionFunc: mappedTagsBranch,
            colClassName: 'col col-md-6',
        },
        'vehicle.id': {
            type: 'select',
            label: 'Vehicle Number',
            isRequired: true,
            isOptionFetchRequired: true,
            apiName: API_NAME.VEHICLE,
            optionFunc: mappedVehiclesMini,
            colClassName: 'col col-md-6',
        },
        'route.routePoints.1.lrnumber': {
            type: 'text',
            label: 'GR Number',
            className: 'col col-md-6',
        },
        'route.routePoints.0.scheduleDeparture': {
            type: 'date',
            label: 'Dispatch Date',
            isRequired: true,
            className: 'col col-md-6',
            valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP),
        },
        'route.routePoints.0.scheduleArrival': {
            type: 'date',
            label: 'Reporting Date',
            className: 'col col-md-6',
            valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP),
        },
        'route.routePoints.0.address': { type: 'text', label: 'From', isRequired: true, className: 'col col-md-6' },
        'route.routePoints.1.address': { type: 'text', label: 'To', isRequired: true, className: 'col col-md-6' },
        transporterName: {
            type: 'select',
            label: 'Transporter Name',
            isOptionFetchRequired: true,
            apiName: API_NAME.TRANSPORTER,
            optionFunc: mappedTransporters,
            colClassName: 'col col-md-6',
        },
        'route.routePoints.0.vehicleState': {
            type: 'select',
            label: 'Status',
            options: mappedVehicleState(JOB_FORM_VEHICLE_STATE),
            colClassName: 'col col-md-6',
        },
        'customFields.288': { type: 'text', label: 'Reporting Office', className: 'col col-md-6' },
        'customFields.289': { type: 'text', label: 'Going Toward', className: 'col col-md-6' },
        // 'route.routePoints.1.manualCurrentVehicleState': {
        //     type: 'select',
        //     label: 'Current Status',
        //     options: mappedVehicleState(JOB_FORM_VEHICLE_STATE),
        //     colClassName: 'col col-md-6',
        // },
        'route.routePoints.1.vehicleState': {
            type: 'select',
            label: 'Vehicle State',
            options: mappedVehicleState(JOB_FORM_VEHICLE_STATE),
            colClassName: 'col col-md-6',
        },
    },
    schema: addJobComponentSchema,
};
export const VIJAY_ROADLINES_CONFIG = {
    fields: {
        'name': { type: 'text', label: 'Job Name', isRequired: true, className: 'col col-md-6' },
        'vehicle.id': {
            type: 'select',
            label: 'Vehicle Number',
            isRequired: true,
            isOptionFetchRequired: true,
            isFetchRequired: true,
            apiName: API_NAME.VEHICLE,
            fetchApiName: API_NAME.LATEST_JOBS,
            onChange: handleVehicleChange,
            optionFunc: mappedVehiclesMini,
            colClassName: 'col col-md-6',
        },
        'driver.id': {
            type: 'select',
            label: 'Driver Name',
            isRequired: false,
            isOptionFetchRequired: true,
            apiName: API_NAME.DRIVER,
            optionFunc: mappedDrivers,
            colClassName: 'col col-md-6',
        },
        'route.routePoints.0.scheduleArrival': {
            type: 'date',
            label: 'From Date',
            className: 'col col-md-6',
            valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP),
        },
        'route.routePoints.0.scheduleDeparture': {
            type: 'date',
            label: 'To Date',
            isRequired: true,
            className: 'col col-md-6',
            valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP),
        },
        'customFields.394': { type: 'text', label: 'Supervisor Name', className: 'col col-md-6' },
        'route.routePoints.0.address': {
            type: 'text',
            label: 'From',
            isRequired: true,
            className: 'col col-md-6',
        },
        'route.routePoints.1.address': {
            type: 'text',
            label: 'To',
            isRequired: true,
            className: 'col col-md-6',
        },
        'customFields.393': { type: 'text', label: 'HCV LCV In Pressure', className: 'col col-md-6' },
        'customFields.392': { type: 'text', label: 'HCV LCV Out Pressure', className: 'col col-md-6' },
        'openingOdo': {
            type: 'text',
            label: 'Opening Odometer',
            isRequired: false,
            className: 'col col-md-6',
        },
        'closingOdo': {
            type: 'text',
            label: 'Closing Odometer',
            isRequired: false,
            className: 'col col-md-6',
        },
        'customFields.395': { type: 'text', label: 'Going Toward', className: 'col col-md-6' },
    },
    schema: addJobComponentSchema,
};
