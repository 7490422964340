import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
export function withRoute(Component) {
    return (props) => {
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();
        return _jsx(Component, { ...props, navigate: navigate, location: location, params: params });
    };
}
