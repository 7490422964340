import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stringifyUrlThroughState } from '../../../../constant';
import { fetchRecentTransactions } from './payment_actions';
import { FxFlex } from '../../../../shared';
import { get, orderBy } from 'lodash';
import { DEFAULT_JOB_START_DATE_DAYS, getMomentTime } from '../../../../utils/date_utils';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { getCaret } from '../../../../utils/grid_utils';
import { TableSkeleton } from '../../skeletons/tableSkeleton';
import CalendarComponent from '../../../utils/calendar_component';
class RecentTransacticons extends Component {
    sizePerPage = 20;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: true,
            currentPage: 1,
            sizePerPage: this.sizePerPage,
            transactions: [],
            dataTotalSize: 0,
            sortOrder: 'desc',
            sortName: 'createdDate',
            startDate: getMomentTime().subtract(DEFAULT_JOB_START_DATE_DAYS, 'days').startOf('day'),
            endDate: getMomentTime().add(DEFAULT_JOB_START_DATE_DAYS, 'days').endOf('day'),
        };
    }
    componentDidMount = async () => {
        this.fetchData();
    };
    onPageChange = (page, sizePerPage) => {
        this.setState({
            currentPage: page,
            sizePerPage: sizePerPage,
        }, () => {
            this.fetchData();
            stringifyUrlThroughState(this.state);
        });
    };
    onSortChange = (sortName, sortOrder) => {
        this.setState({
            sortName,
            sortOrder,
        }, () => {
            this.fetchData();
            stringifyUrlThroughState(this.state);
        });
    };
    fetchData = async () => {
        const { startDate, endDate, currentPage } = this.state;
        const sort = `${this.state.sortName},${this.state.sortOrder}`;
        const resp = await this.props.fetchRecentTransactions(this.props.accesstoken, startDate.valueOf(), endDate.valueOf(), currentPage - 1, this.sizePerPage, sort);
        const transactions = get(resp, 'payload.data.content', []);
        const dataTotalSize = get(resp, 'payload.data.totalElements', 0);
        const sortedTxns = orderBy(transactions, ['createdDate'], ['desc']);
        this.setState({ transactions: sortedTxns, dataTotalSize, loading: false });
    };
    handleFilterChange = (key, value) => {
        const newState = {
            currentPage: 1,
        };
        if (key === 'timeFilter') {
            newState['startDate'] = get(value, '[0]', null);
            newState['endDate'] = get(value, '[1]', null);
        }
        else {
            newState[key] = value;
        }
        this.setState(newState, () => {
            this.fetchData();
            stringifyUrlThroughState(this.state);
        });
    };
    render() {
        const { sizePerPage, currentPage, transactions, sortOrder, sortName, loading, startDate, endDate, dataTotalSize, } = this.state;
        const options = {
            hideSizePerPage: false,
            sortName: sortName,
            sortOrder: sortOrder,
            sizePerPage: sizePerPage,
            page: currentPage,
            alwaysShowAllBtns: true,
            paginationPosition: 'bottom',
            onPageChange: this.onPageChange,
            onSortChange: this.onSortChange,
            noDataText: 'No Payments found.',
        };
        if (loading) {
            return _jsx(TableSkeleton, { rowclassName: 'mt-4' });
        }
        return (_jsxs(_Fragment, { children: [_jsx(FxFlex, { direction: "row", justifyContent: "flex-end", alignItmes: "center", viewMargin: "8px 0px 8px 0px", children: _jsx("div", { className: `calendar-component`, "data-gacategory": "Internal Issues Filters", "data-gaaction": "Click", "data-galabel": "Issues-Date Range", children: _jsx(CalendarComponent, { onDateSelected: (sDate, eDate) => {
                                this.handleFilterChange('timeFilter', [sDate, eDate]);
                            }, startDate: startDate, endDate: endDate }) }) }), _jsx("div", { className: "fleetx-table-parent-container", children: _jsxs(BootstrapTable, { tableContainerClass: "card fleetx-table-container white-table-background", tableHeaderClass: "fleetx-table grey-table-background font-size-mid text-center", tableBodyClass: "fleetx-table white-table-background", trClassName: "tr-user-height table-row-style cursor-pointer", remote: true, data: transactions, pagination: true, options: options, version: "4", fetchInfo: { dataTotalSize }, children: [_jsx(TableHeaderColumn, { width: "120", dataField: "amount", children: "Amount paid" }), _jsx(TableHeaderColumn, { width: "100", dataField: "status", thStyle: { whiteSpace: 'normal', paddingRight: 0 }, tdStyle: { whiteSpace: 'normal' }, dataSort: true, children: "Status" }), _jsx(TableHeaderColumn, { width: "120", dataField: "createdDate", thStyle: { whiteSpace: 'normal', paddingRight: 0 }, tdStyle: { whiteSpace: 'normal' }, dataSort: true, children: "Date & Time" }), _jsx(TableHeaderColumn, { width: "150", 
                                // dataFormat={this.showIssueType}
                                thStyle: { whiteSpace: 'normal', paddingRight: 0 }, tdStyle: { whiteSpace: 'normal' }, dataSort: true, caretRender: getCaret, dataField: "orderId", isKey: true, children: "Transaction ID" }), _jsx(TableHeaderColumn, { width: "150", 
                                // dataFormat={this.showIssueType}
                                thStyle: { whiteSpace: 'normal', paddingRight: 0 }, tdStyle: { whiteSpace: 'normal' }, dataSort: true, caretRender: getCaret, dataField: "paymentId", children: "Payment ID" })] }) })] }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchRecentTransactions }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: state.login.data.access_token,
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RecentTransacticons);
