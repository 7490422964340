import axios from 'axios';
import { ROOT_API_URL, parseQueryParams } from '../../constant';
export const FETCH_METRICS = 'fetch_metrics';
export function fetchMetrics(accesstoken, from, to, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/metrics`, config);
    return {
        type: FETCH_METRICS,
        promise: request,
    };
}
