const _Bar = ({ progress, animationDuration, position, height }) => (
    <div
        style={{
            background: '#42cdb6',
            height: height ? height : 4,
            left: 0,
            width: progress ? `${progress}%` : '0%',
            position: `${position ? position : 'fixed'}`,
            top: 0,
            transition: `width ${animationDuration}ms linear`,
            zIndex: 1021,
        }}
    >
        <div
            style={{
                boxShadow: '0 0 10px #42cdb6, 0 0 5px #42cdb6',
                display: 'block',
                height: '100%',
                opacity: 1,
                position: 'absolute',
                right: 0,
                width: '100%',
            }}
        />
    </div>
);

export default _Bar;
