import { handle } from 'redux-pack';
import {
    CREATE_GROUP,
    DELETE_GROUP,
    FETCH_ALL_GROUP,
    FETCH_ALL_GROUPS,
    FETCH_GROUP,
    FETCH_GROUPS,
    UPDATE_GROUP,
} from '../../actions/dashboard/group_action';
import { get, orderBy } from 'lodash';

const initialState = {
    isLoading: false,
    error: null,
    data: {},
    groupList: [],
    allGroupsList: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_GROUP:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_GROUP:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_GROUP:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_GROUPS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoadingGroup: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, error: payload, isLoadingGroups: false }),
                success: (prevState) => ({
                    ...prevState,
                    groupList: get(payload, 'data.content', []),
                    isLoadingGroups: false,
                }),
            });
        case FETCH_ALL_GROUPS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    allGroupsList: orderBy(payload.data, 'name', 'asc'),
                }),
            });

        case FETCH_GROUP:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        default:
            return state;
    }
}
