import { handle } from 'redux-pack';
import { FETCH_TMS_CONSIGNMENTS } from '../../actions/dashboard/vendor_management/tmsConsignmentsActions';
const initialState = {
    isConsignmentsLoading: false,
    consignments: [],
    totalElements: 0,
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        // tms consignments
        case FETCH_TMS_CONSIGNMENTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isConsignmentsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isConsignmentsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    consignments: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        default:
            return state;
    }
}
