import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Upload } from 'antd';
import { isArray, join } from 'lodash';
const { Dragger } = Upload;
export const FxUploadDragIconWrapper = (props) => {
    return _jsx("p", { className: "ant-upload-drag-icon", children: props.children });
};
export const FxUploadTextWrapper = (props) => {
    return _jsx("p", { className: "ant-upload-text", children: props.children });
};
export const FxUploadHintWrapper = (props) => {
    return _jsx("p", { className: "ant-upload-hint", children: props.children });
};
const FxDropzone = (props) => {
    const { accept, ...rest } = props;
    const { t } = useTranslation();
    return _jsx(Dragger, { accept: isArray(accept) ? join(accept) : accept, ...rest });
};
export default FxDropzone;
