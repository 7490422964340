import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditConsignmentsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-consignments-consignments-add-edit" */ 'components/dashboard/consignments/consignments/form/AddEditConsignmentsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ConsignmentsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-consignments-consignments" */ 'components/dashboard/consignments/consignments/ConsignmentsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const consignmentRoutes = [
    {
        path: 'add',
        lazy: AddEditConsignmentsComponent,
    },
    {
        path: 'edit/:id/:status',
        lazy: AddEditConsignmentsComponent,
    },
    {
        index: true,
        lazy: ConsignmentsComponent,
    },
];
export default consignmentRoutes;
