import axios from "axios";
import {ROOT_API_URL} from "../../constant";
import {last7DaysRange} from "../../utils/date_utils";

export const ACCOUNT_OVERVIEW = 'account_overview';
export const ACCOUNT_ANALYTICS = 'account_analytics';


export function fetchAccountOverview(accesstoken, groupId) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const groupQuery = (groupId && groupId!=-1) ? `?groupId=${groupId}` : "";
    const request = axios.get(`${ROOT_API_URL}accounts/overview${groupQuery}`, config);

    return {
        type: ACCOUNT_OVERVIEW,
        promise: request
    }
}

export function fetchAccountAnalytics(accesstoken, groupId) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const last7daysRange = last7DaysRange();
    const groupQuery = (groupId && groupId!=-1) ? `&groupId=${groupId}` : "";
    const analyticsQuery = `?from=${last7daysRange[0].valueOf()}&to=${last7daysRange[1].valueOf()}`;
    const request = axios.get(`${ROOT_API_URL}analytics/account${analyticsQuery}${groupQuery}`, config);

    return {
        type: ACCOUNT_ANALYTICS,
        promise: request
    }
}

