import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../constant';

export const ADD_USER_IMAGE = 'add_user_image';
export const FETCH_USER_IMAGES = 'fetch_user_images';
export const DELETE_USER_IMAGE = 'delete_user_image';
export const SEARCH_USER_IMAGES = 'search_user_images';

export function addUserImage(values, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}user_images/`, values, config);

    return {
        type: ADD_USER_IMAGE,
        promise: request,
    };
}

export function deleteUserImage(id, userId, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}user_images/${id}?userId=${userId}`, config);
    return {
        type: DELETE_USER_IMAGE,
        promise: request,
    };
}

export function fetchUserImages(userId, documentType, page, size, accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            documentType,
            userId,
            page: page - 1,
            size,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}user_images/`, config);

    return {
        type: FETCH_USER_IMAGES,
        promise: request,
    };
}

export function searchUserImages(accesstoken, { userId, documentType, imageTag }, page = 1, size) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            documentType,
            userId,
            imageTag,
            page: page - 1,
            size,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}user_images/search`, config);

    return {
        type: SEARCH_USER_IMAGES,
        promise: request,
    };
}
