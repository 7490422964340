import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DASHBOARD_LANDING_PATH } from '../../constant';
import { get, trim, includes, set } from 'lodash';
import { withRoute } from './customHocs/withRoute';
import { Navigate } from 'react-router-dom';

class DashboardHomeComponent extends Component {
    constructor(props) {
        super(props);
    }

    customLoginPaths = ['/users/holisollogin', '/users/marutilogin'];

    render() {
        const {
            login,
            location: { search, pathname },
        } = this.props;
        let routeToLogin = false;
        if (get(login, 'data') && !includes(this.customLoginPaths, pathname)) {
            routeToLogin = false;
        } else {
            routeToLogin = true;
        }
        return routeToLogin ? <Navigate to="/users/login" /> : <Navigate to={DASHBOARD_LANDING_PATH} />;
    }
}

function mapStateToProps(state) {
    return {
        login: state.login,
    };
}

export default withRoute(connect(mapStateToProps, {})(DashboardHomeComponent));
