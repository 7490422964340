import { get, map, truncate, set, isEmpty, split } from 'lodash';
import { queryString } from 'utils/string_utils';

export function getFormDataAsSimpleString(data, trimKey = []) {
    if (data instanceof FormData) {
        try {
            let formDataAsSimpleString = 'FormData:{';
            for (let pair of data.entries()) {
                let key = pair[0];
                let value = pair[1];
                if (typeof value !== 'string') {
                    value = 'File(BLOB)';
                }
                formDataAsSimpleString += `${key}:${value}, `;
            }
            formDataAsSimpleString += '}';

            return formDataAsSimpleString;
        } catch (e) {
            return data;
        }
    } else {
        if (data && trimKey && !isEmpty(trimKey)) {
            let dataObj = JSON.parse(data);
            map(trimKey, (key) => {
                const value = get(dataObj, key);
                const newValue = truncate(value, { length: 10 });
                set(dataObj, key, newValue);
            });
            data = JSON.stringify(dataObj);
        }
        return data;
    }
}

export function getParamsAsSimpleString(config) {
    let finalParamsString;
    const params = get(config, 'params');
    const url = get(config, 'url');
    try {
        if (params) {
            finalParamsString = JSON.stringify(params);
        } else {
            const paramString = get(split(url, '?'), '[1]', '');
            if (paramString) {
                finalParamsString = JSON.stringify(queryString.parse(`?${paramString}`));
            }
        }
    } catch (e) {
        console.log(e);
    }

    return finalParamsString;
}
