import { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isEqual } from 'lodash';
import GoogleMapSearchBoxComponent from '../../../../utils/map/google_map_search_box_component';
import { GEOCODER, getGeoCodedLocation } from '../../../../utils/map/geocode_utils';
import { connect } from 'react-redux';
import { parseAddress } from 'constant';

class SelectLocationFromGoogleMapModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selectedPlace: null,
        };

        this.cancelModal = this.cancelModal.bind(this);
        this.ok = this.ok.bind(this);
        this.onMapClicked = this.onMapClicked.bind(this);
        this.onPlaceSelected = this.onPlaceSelected.bind(this);
    }

    cancelModal() {
        this.setState({
            isOpen: false,
        });
        this.props.onClosed();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }

    componentDidMount() {
        this.setState({
            isOpen: this.props.isOpen,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        this.setState({
            isOpen: this.props.isOpen,
        });

        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({
                selectedPlace: null,
            });
        }
    }

    ok() {
        this.props.onLocationSelected(this.state.selectedPlace, this.props.index, this.props.setFieldValue);
        this.setState({
            isOpen: false,
        });
        this.props.onClosed();
    }

    onPlaceSelected(places) {
        const place = places[0];
        if (place) {
            const selectedPlace = {
                name: place.name,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                formatted_address: parseAddress(place.formatted_address),
            };
            this.setState({
                selectedPlace: selectedPlace,
            });
        }
    }

    onMapClicked(latLng) {
        getGeoCodedLocation(this.props.accesstoken, latLng.lat(), latLng.lng(), GEOCODER.GOOGLE, null).then(
            (response) => {
                this.setState({
                    selectedPlace: { name: response.address, latitude: latLng.lat(), longitude: latLng.lng() },
                });
            }
        );
    }

    render() {
        return (
            <div>
                <Modal
                    zIndex={this.props.zIndex ? this.props.zIndex : undefined}
                    className="modal-lg"
                    isOpen={this.state.isOpen}
                >
                    <ModalHeader>Select Location</ModalHeader>
                    <ModalBody>
                        <GoogleMapSearchBoxComponent
                            allowRadius={false}
                            onPlaceSelected={this.onPlaceSelected}
                            onMapClicked={this.onMapClicked}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.ok}>
                            Ok
                        </Button>
                        <Button color="secondary" onClick={this.cancelModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        accesstoken: state.login.data.access_token,
    };
}

export default connect(mapStateToProps, {})(SelectLocationFromGoogleMapModal);
