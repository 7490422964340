import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP, ROOT_API_URL } from 'constant';
import { get } from 'lodash';
import { showSinglePartEntry } from 'utils/account_utils';
export const PART_STOCK_SUMMARY = 'part_summary_entries_summary';
export const COMPLETE_PART_INVENTORY = 'complete_part_inventory';
export const DELETE_PART_INVENTORY = 'DELETE_PART_INVENTORY';
export const COMPLETE_PART_LIST = 'COMPLETE_PART_LIST';
export const COMPLETE_PART_LIST_MASTER = 'COMPLETE_PART_LIST_MASTER';
export const COMPLETE_PART_ENTRY_BY_ID = 'complete_part_inventory_by_id';
export const PART_STORE_HISTORY_BY_ID = 'part_store_history_by_id';
export const FETCH_PARTS_LIST = 'fetch_parts_store_list';
export const DOWNLOAD_SAVING_REPORT = 'DOWNLOAD_SAVING_REPORT';
export const FETCH_PARTS_HISTORY_LIST = 'fetch_part_history_list';
export const DOWNLOAD_PURCHASE_REGISTER_REPORT = 'DOWNLOAD_PURCHASE_REGISTER_REPORT';
export function getPartStockEntrySummary(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-entry/summary`, config);
    return {
        type: PART_STOCK_SUMMARY,
        promise: request,
    };
}
export function getCompletePartInventoryData(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    let partEntryUrl = showSinglePartEntry() ? `${ROOT_API_URL}part-entry/filter` : `${ROOT_API_ERP}part-entry/bulk`;
    const request = axios.get(partEntryUrl, config);
    return {
        type: COMPLETE_PART_INVENTORY,
        promise: request,
    };
}
export function deletePartInventory(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}part-entry/bulk/${id}`, config);
    return {
        type: DELETE_PART_INVENTORY,
        promise: request,
    };
}
export function getCompletePartList(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part/listing`, config);
    return {
        type: COMPLETE_PART_LIST,
        promise: request,
    };
}
export function getCompletePartListMaster(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part/master/listing`, config);
    return {
        type: COMPLETE_PART_LIST_MASTER,
        promise: request,
    };
}
export function fetchDownloadStockInventorySavingReport(accesstoken, fromDate, toDate, reportFormat, otherParams = {}) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'blob' };
    }
    const dateType = get(otherParams, 'dateType');
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            fromDate,
            toDate,
            reportFormat,
            dateType,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-store-audit/report`, config);
    return {
        type: DOWNLOAD_SAVING_REPORT,
        promise: request,
    };
}
export function fetchDownloadPurchaseRegisterReport(accesstoken, fromDate, toDate) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: fromDate,
            to: toDate,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}report/purchase-register`, config);
    return {
        type: DOWNLOAD_PURCHASE_REGISTER_REPORT,
        promise: request,
    };
}
export function fetchPartsHistoryList(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-entry-store/filter`, config);
    return {
        type: FETCH_PARTS_HISTORY_LIST,
        promise: request,
    };
}
export function getPartEntryById(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-entry/filter`, config);
    return {
        type: COMPLETE_PART_ENTRY_BY_ID,
        promise: request,
    };
}
export function fetchPartsList(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part/listing`, config);
    return {
        type: FETCH_PARTS_LIST,
        promise: request,
    };
}
export function getStoreHistoryById(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-store-audit`, config);
    return {
        type: PART_STORE_HISTORY_BY_ID,
        promise: request,
    };
}
