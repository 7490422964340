import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
const RedirectToFormTemplateButton = (props) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx("i", { className: "fa fa-info-circle cursor-pointer", id: "create-template-info-tooltip", ref: ref }), ' ', _jsxs(Link, { to: "/dashboard/settings/formTemplates", className: "hover", title: "", children: [' ', t('createFormTemplate')] }), _jsx(UncontrolledTooltip, { placement: "right", target: ref, children: t('createFormTemplateInfo') })] }));
};
export default RedirectToFormTemplateButton;
