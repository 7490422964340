import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../constant';
export const CREATE_VEHICLE_SIZE = 'create_vehicle_size';
export const UPDATE_VEHICLE_SIZE = 'update_vehicle_size';
export const DELETE_VEHICLE_SIZE = 'delete_vehicle_size';
export const FETCH_VEHICLE_SIZES = 'fetch_vehicle_sizes';
export const FETCH_VEHICLE_SIZE_BY_ID = 'fetch_vehicle_size_by_id';
export function createVehicleSize(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}vehicle/sizes`, data, config);
    return {
        type: CREATE_VEHICLE_SIZE,
        promise: request,
    };
}
export function updateVehicleSize(accesstoken, sizeId, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}vehicle/sizes/${sizeId}`, data, config);
    return {
        type: UPDATE_VEHICLE_SIZE,
        promise: request,
    };
}
export function deleteVehicleSize(accesstoken, sizeId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}vehicle/sizes/${sizeId}`, config);
    return {
        type: DELETE_VEHICLE_SIZE,
        promise: request,
    };
}
export function fetchVehicleSizes(accesstoken, accountId, disabled = false) {
    const page = 0;
    const size = 100;
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            page: page,
            size: size,
            accountId,
            disabled,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle/sizes`, config);
    return {
        type: FETCH_VEHICLE_SIZES,
        promise: request,
    };
}
export function fetchVehicleSizeById(accesstoken, sizeId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}vehicle/sizes/${sizeId}`, config);
    return {
        type: FETCH_VEHICLE_SIZE_BY_ID,
        promise: request,
    };
}
