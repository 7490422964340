import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, find, get, pick, set } from 'lodash';
import * as Yup from 'yup';
import { createReportFromTemplate, createXMLReports, fetchReportTemplates, } from 'actions/dashboard/reportTemplatesAction';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { FxSelectField, FxSwitchField, FxTextAreaFormikStacked } from '../../../shared';
import { mappedReportTemplates } from 'utils/reportTemplatesUtils';
import { REPORT_FORMAT, REPORT_REQUEST_MODE, saveAs } from 'utils/report_utils';
import { handleError } from 'constant';
import { Link } from 'react-router-dom';
import { isFetchReportTemplateAllowedForUser } from '../roles/permission_utils';
import { getEndDateWithCurrent, getMomentTime } from 'utils/date_utils';
import CalendarComponent from 'components/utils/calendar_component';
import { validateEmails } from 'utils/validation_utils';
import FxEmailInput from 'sharedV2/FxEmailInput';
const GenerateReportForSelectedTemplateModal = (props) => {
    const { accesstoken, isOpen, onClose, onSuccess, extraParams, formatType, itemId, label = 'Download/Print', sendDateRange = false, isBulkPrint = false, emailSupport = false, } = props;
    const [initialValues] = useState({
        formatId: '',
        outFormat: REPORT_FORMAT.PDF,
        from: props.startDate ? props.startDate : getMomentTime().subtract(1, 'months').startOf('day'),
        to: props.endDate ? props.endDate : getMomentTime().endOf('day'),
        ...(props.selectField1
            ? { [get(props.selectField1, 'key')]: get(props.selectField1, 'defaultValue', null) }
            : {}),
    });
    const [submitError, setSubmitError] = useState('');
    const [reportTemplates, setReportTemplates] = useState([]);
    const [{ startDate, endDate }, setDateRange] = useState({
        startDate: props.startDate ? props.startDate : getMomentTime().subtract(1, 'months').startOf('day'),
        endDate: props.endDate ? props.endDate : getMomentTime().endOf('day'),
    });
    const [emails, setEmails] = useState([]);
    const [isExcel, setIsExcel] = useState(false);
    const emailRef = React.useRef(false);
    const { isXmL } = extraParams || {};
    const onSubmit = useCallback((values, { setSubmitting }) => {
        const showEmailOption = (emailSupport && emailRef.current) || isBulkPrint;
        const outputFormat = isExcel
            ? values.isExcelOutput
                ? REPORT_FORMAT.CSV
                : REPORT_FORMAT.PDF
            : REPORT_FORMAT.PDF;
        if (showEmailOption) {
            const validEmails = filter(validateEmails(emails.join(',')), (e) => get(e, 'isValid'));
            if (+get(validEmails, 'length') <= 0) {
                alert('Please select atleast one valid email');
                setSubmitting(false);
                return;
            }
        }
        const foundTemplate = find(reportTemplates, { id: values.formatId });
        const formatUrl = get(foundTemplate, 'url');
        const formatType = get(foundTemplate, 'type') || props.formatType;
        const requestMode = showEmailOption ? REPORT_REQUEST_MODE.EMAIL : REPORT_REQUEST_MODE.DOWNLOAD;
        const paramsFromValues = { ...pick(values, ['description']) };
        if (get(props.selectField1, 'key')) {
            set(paramsFromValues, get(props.selectField1, 'key'), get(values, get(props.selectField1, 'key')));
        }
        const params = {
            formatUrl,
            formatType,
            itemId,
            outputFormat,
            emails: showEmailOption ? emails.join(',') : null,
            ...(sendDateRange && { from: getMomentTime(values.from).startOf('day').valueOf() }),
            ...(sendDateRange && { to: getMomentTime(values.to).endOf('day').valueOf() }),
            ...extraParams,
            ...paramsFromValues,
            requestMode,
        };
        if (props.localTransporterAccount) {
            params.accountId = props.localTransporterAccount;
        }
        if (isXmL) {
            const params = {
                formatType,
                itemId,
                emails,
            };
            props
                .createXMLReports(accesstoken, params)
                .then((res) => {
                setSubmitting(false);
                if (!res.error) {
                    alert('Your request has been accepted. Bills will be sent to your email.');
                    if (onSuccess) {
                        onSuccess();
                        setEmails([]);
                    }
                }
                else if (res.error) {
                    throw new Error(handleError(res.payload.response));
                }
            })
                .catch((err) => {
                setSubmitting(false);
                setSubmitError(handleError(err));
            });
        }
        else {
            props
                .createReportFromTemplate(accesstoken, params)
                .then((res) => {
                setSubmitting(false);
                if (!res.error) {
                    // download report here
                    if (!showEmailOption) {
                        const result = get(res, 'payload.data');
                        const url = get(result, 'fileUrl');
                        saveAs(url, url);
                    }
                    else {
                        alert('Your request has been accepted. Bills will be sent to your email.');
                    }
                    if (onSuccess) {
                        onSuccess();
                        resetStates();
                    }
                }
            })
                .catch((err) => {
                setSubmitting(false);
                setSubmitError(handleError(err));
            });
        }
    }, [itemId, extraParams, reportTemplates, emails, isExcel]);
    useEffect(() => {
        if (!formatType || isXmL) {
            return;
        }
        const params = { type: formatType };
        if (isFetchReportTemplateAllowedForUser(props.login.scope)) {
            props
                .fetchReportTemplates(accesstoken, params, props.localTransporterAccount)
                .then((res) => {
                if (res.error) {
                    throw new Error(handleError(res.payload.response));
                }
                setReportTemplates(get(res, 'payload.data', []));
            })
                .catch((err) => {
                setSubmitError(handleError(err));
            });
        }
    }, [formatType]);
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            formatId: !isXmL ? Yup.number().required('Please select template.') : Yup.number().nullable(),
        });
    }, []);
    const onEmail = (submitForm) => {
        emailRef.current = true;
        submitForm();
    };
    const onChangeTemplate = (id, setFieldValue) => {
        const selectedTemplate = find(reportTemplates, { id });
        if (selectedTemplate) {
            const isExcel = get(selectedTemplate, 'url', '')?.split('.')?.pop() === 'xlsx';
            setIsExcel(isExcel);
            setFieldValue('isExcelOutput', false);
        }
    };
    const resetStates = () => {
        setIsExcel(false);
        setEmails([]);
        setSubmitError('');
    };
    return (_jsxs(Modal, { isOpen: isOpen, children: [_jsx(ModalHeader, { toggle: () => {
                    onClose();
                    resetStates();
                }, children: "Generate Report" }), _jsx(ModalBody, { children: _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: validationSchema, children: ({ setFieldValue, isSubmitting, submitForm, values }) => {
                        const isExcelOutput = get(values, 'isExcelOutput', false);
                        return (_jsxs(Form, { children: [!isXmL && (_jsxs(_Fragment, { children: [_jsx(Field, { name: "formatId", label: 'Template', clearable: false, options: mappedReportTemplates(reportTemplates), component: FxSelectField, isRequired: true, onChange: (v) => onChangeTemplate(v, setFieldValue), className: 'mb-0' }), _jsx(Link, { to: "/dashboard/settings/reporttemplates/add", className: "medium", target: "_blank", children: "Can't Find Template?. Add Here!" }), isExcel && (_jsxs("div", { className: "d-flex flex-wrap mt-2 mb-3", children: [_jsx(Field, { name: "isExcelOutput", className: "switch-input mr-2 float-left", switchInputWrapperClass: "switch-sm mb-0", component: FxSwitchField, type: "checkbox", checked: isExcelOutput }), _jsx("span", { children: "Output as Excel" })] })), !!props.selectField1 && (_jsx("div", { className: "mt-3", children: _jsx(Field, { name: get(props.selectField1, 'key'), label: get(props.selectField1, 'label'), clearable: false, options: get(props.selectField1, 'fieldOptions'), component: FxSelectField, className: 'mb-0' }) })), sendDateRange && (_jsx("div", { className: "w-100 mt-3 mb-2", children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                                                    setFieldValue('from', startDate);
                                                    setFieldValue('to', endDate);
                                                    setDateRange({ startDate, endDate });
                                                }, startDate: startDate, endDate: getEndDateWithCurrent(endDate), showLastSixMonth: true, width: "100%" }) })), _jsx(Field, { name: `description`, label: "Description", component: FxTextAreaFormikStacked, numberOfRow: 3, showLabelonTop: true })] })), props.children, (isBulkPrint || emailSupport) && (_jsx(FxEmailInput, { onChange: (value) => setEmails(value) })), submitError && _jsx("div", { className: "text-danger", children: submitError }), _jsxs("div", { className: "mt-2", children: [_jsx(Button, { color: "primary", className: "pull-right", type: "submit", disabled: isSubmitting, title: 'Download/Print', onClick: () => {
                                                emailRef.current = false;
                                            }, children: isSubmitting && !emailRef.current ? (_jsxs("span", { className: "", children: [_jsx("i", { className: "fa fa-spinner fa-pulse" }), " Downloading"] })) : (label) }), !isBulkPrint && emailSupport && (_jsx(Button, { onClick: () => onEmail(submitForm), className: "float-right mr-2", color: "primary", title: "Email", disabled: isSubmitting, children: isSubmitting && emailRef.current ? (_jsxs("span", { className: "", children: [_jsx("i", { className: "fa fa-spinner fa-pulse" }), " Emailing"] })) : ('Email') })), _jsx(Button, { onClick: () => {
                                                onClose();
                                                setEmails([]);
                                            }, type: "button", className: "float-right mr-2", color: "secondary", children: "Close" }), _jsx("div", { className: 'clearfix' })] })] }));
                    } }) })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createReportFromTemplate,
        fetchReportTemplates,
        createXMLReports,
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        localTransporterAccount: get(state, 'local.localTransporterAccount'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GenerateReportForSelectedTemplateModal);
