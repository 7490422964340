import axios from 'axios';
import { ROOT_API_URL, parseQueryParams, ROOT_NODE_API_URL } from '../../../constant';
import { getMomentTime } from 'utils/date_utils';

export const CREATE_INVOICE = 'create_invoice';
export const UPDATE_INVOICE = 'update_invoice';
export const DELETE_INVOICE = 'delete_invoice';
export const FETCH_INVOICES = 'fetch_invoices';
export const FETCH_INVOICE_SUMMARY = 'fetch_invoice_summary';
export const FETCH_INVOICES_SUMMARY = 'fetch_invoices_summary';
export const PRINT_INVOICE = 'print_invoice';

export function createInvoice(accesstoken, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}invoices/`, values, config);
    return {
        type: CREATE_INVOICE,
        promise: request,
    };
}

export function updateInvoice(accesstoken, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}invoices/`, values, config);

    return {
        type: UPDATE_INVOICE,
        promise: request,
    };
}

export function deleteInvoice(accesstoken, invoiceId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}invoices/${invoiceId}`, config);

    return {
        type: DELETE_INVOICE,
        promise: request,
    };
}

export function fetchInvoices(
    accesstoken,
    from,
    to,
    groupId,
    vehicleId,
    customerId,
    invoiceStatus,
    sort,
    page = 1,
    size,
    invoiceNumber
) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: from ? getMomentTime(from).valueOf() : '',
            to: to ? getMomentTime(to).valueOf() : '',
            groupId: groupId,
            vehicleId: vehicleId,
            customerId: customerId,
            status: invoiceStatus,
            sort,
            page: page - 1,
            size,
            invoiceNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}invoices/`, config);

    return {
        type: FETCH_INVOICES,
        promise: request,
    };
}

export function fetchInvoiceSummary(accesstoken, invoiceId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}invoices/${invoiceId}`, config);

    return {
        type: FETCH_INVOICE_SUMMARY,
        promise: request,
    };
}

export function fetchInvoicesSummary(accesstoken, from, to, groupId, vehicleId, customerId, invoiceStatus) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: from ? getMomentTime(from).valueOf() : '',
            to: to ? getMomentTime(to).valueOf() : '',
            groupId: groupId,
            vehicleId: vehicleId,
            customerId: customerId,
            status: invoiceStatus,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}invoices/summary`, config);

    return {
        type: FETCH_INVOICES_SUMMARY,
        promise: request,
    };
}

export function printInvoice(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` }, responseType: 'arraybuffer' };

    const request = axios.get(`${ROOT_API_URL}invoices/${id}/pdf`, config);

    return {
        type: PRINT_INVOICE,
        promise: request,
    };
}
