import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * Wrap your main router outlets with ErrorBoundary
 * but provide a unique key to ErrorBoundary to force it to teardown and
 * build a new instance when the location changes:
 */
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FxFlex } from '../../shared';
import styled from 'styled-components';
const ErrorBoundaryOops = styled.div `
    font-size: 44px;
    font-weight: bold;
    color: #8c8c8c;
`;
const ErrorBoundaryMsg = styled.div `
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #555555;
`;
const ErrorBoundaryReportCrashButton = styled(Button) `
    font-size: 14px;
    font-weight: bold;
    color: #20a8d8;
`;
const ErrorBoundaryReloadButton = styled(Button) `
    font-size: 16px;
    color: #ffffff;
`;
class ErrorBoundaryComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { hasError: false, error: null, errorInfo: null, eventId: '' };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    logErrorToSentry(error, errorInfo) {
        try {
            if (window.Sentry) {
                window.Sentry.withScope((scope) => {
                    scope.setTag('crash_type', 'ERROR_BOUNDARY_CRASH');
                    scope.setExtras(errorInfo);
                    const eventId = window.Sentry.captureException(error);
                    this.setState({ eventId });
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo,
        });
        this.logErrorToSentry(error, errorInfo);
    }
    openCrashReportWindow = () => {
        try {
            if (window.Sentry) {
                window.Sentry.showReportDialog({ eventId: this.state.eventId });
            }
        }
        catch (e) {
            console.log(e);
        }
    };
    onReloadPage = () => {
        window.location.reload();
    };
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            if (this.props.fallbackComponent && typeof this.props.fallbackComponent === 'function') {
                return this.props.fallbackComponent(this.state.error, this.state.errorInfo);
            }
            const { dontAllowCrashReport, justMsg } = this.props;
            const summaryMsg = this.props.summaryMsg || 'Something went wrong';
            return (_jsx(FxFlex, { className: "w-100 h-100 text-center", justifyContent: "center", alignItems: "center", children: _jsxs("div", { children: [!justMsg && _jsx(ErrorBoundaryOops, { children: "Oops!" }), _jsxs(ErrorBoundaryMsg, { children: [summaryMsg, ". Our software engineering team has been notified."] }), !dontAllowCrashReport && window.Sentry && !justMsg && (_jsx("div", { children: _jsx(ErrorBoundaryReportCrashButton, { className: "mb-3", onClick: this.openCrashReportWindow, color: "link", children: "Add a comment for this issue" }) })), !justMsg && (_jsx(ErrorBoundaryReloadButton, { onClick: this.onReloadPage, color: "primary", children: "Reload Page" }))] }) }));
        }
        return this.props.children;
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundaryComponent);
