import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const GreenMoney = (props) => {
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "16", viewBox: "0 0 20 16", fill: "none", children: [_jsx("g", { "clip-path": "url(#clip0_1140_357)", children: _jsx("path", { d: "M19 14H1C0.447812 14 0 13.5522 0 13V3C0 2.44781 0.447812 2 1 2H19C19.5522 2 20 2.44781 20 3V13C20 13.5522 19.5522 14 19 14ZM1.5 3.5V5.5C2.60469 5.5 3.5 4.60469 3.5 3.5H1.5ZM1.5 10.5V12.5H3.5C3.5 11.3953 2.60469 10.5 1.5 10.5ZM10 5C8.61906 5 7.5 6.34344 7.5 8C7.5 9.65688 8.61938 11 10 11C11.3806 11 12.5 9.65688 12.5 8C12.5 6.34281 11.3803 5 10 5ZM18.5 3.5H16.5C16.5 4.60469 17.3953 5.5 18.5 5.5V3.5ZM18.5 10.5C17.3953 10.5 16.5 11.3953 16.5 12.5H18.5V10.5Z", fill: "#76C09D" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_1140_357", children: _jsx("rect", { width: "20", height: "16", fill: "white", transform: "matrix(1 0 0 -1 0 16)" }) }) })] }));
};
const GreenMoneyIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: GreenMoney, ...props });
};
export default GreenMoneyIcon;
