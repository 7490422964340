import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, map, round } from 'lodash';
import { Button } from 'reactstrap';
import { getHumanizeTime, getMomentTime } from 'utils/date_utils';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { getDiscount, getTax } from 'components/dashboard/maintenance/serviceentries/service_entries_listing';
import { Link } from 'react-router-dom';
import { FxDrawer, FxSingleStep, FxSteps } from '../../../../sharedV2';
import ServiceEntryTimelineComponent from './serviceEntryTimelineComponent';
import { serviceEntryApprovalConfigEnabled } from 'utils/account_utils';
const ServiceEntriesDetail = (props) => {
    const { approvalMatrixData, selectedServiceEntry, userListMap } = props;
    const [showButton, setShowButton] = useState(false);
    const [acceptanceLevel, setAcceptanceLevel] = useState(0);
    const mappedData = useMemo(() => {
        let statusForSteps = null;
        let showButton = false;
        let acceptanceLevel = 0;
        let mappedValueFinal = map(get(approvalMatrixData, get(selectedServiceEntry, 'id', [])), (v, i) => {
            const currentStepStatus = get(v, 'approvalLevelStatus');
            let buttonStatusForCurrentStep = get(v, 'pendingButton');
            if (currentStepStatus == 'REJECTED') {
                statusForSteps = 'REJECTED';
            }
            if (buttonStatusForCurrentStep && currentStepStatus == 'PENDING' && !showButton) {
                showButton = true;
                acceptanceLevel = i + 1;
            }
            const byUserId = get(v, 'approvedBy', '-');
            let name = [];
            if (byUserId) {
                name = get(userListMap, `${byUserId}`);
            }
            return {
                levelName: get(v, 'approvalLevelName', '-'),
                byUser: get(name, '1', '-'),
                date: getMomentTime(get(v, 'approvedDate')),
                status: statusForSteps ? statusForSteps : currentStepStatus,
                ...v,
            };
        });
        setShowButton(showButton);
        setAcceptanceLevel(acceptanceLevel);
        return mappedValueFinal;
    }, [approvalMatrixData, selectedServiceEntry]);
    const currency = getCurrencyFromCountry(props.loggedInUser);
    const vehicle = get(props.vehicleListMiniAsMap, get(props, 'selectedServiceEntry.vehicleId'));
    const licensePlate = get(vehicle, 'licensePlate', '');
    const date = get(props, 'selectedServiceEntry.date');
    const odometer = get(props, 'selectedServiceEntry.meterEntry.value');
    const partCost = get(props, 'selectedServiceEntry.partsSubtotal');
    const laborCost = get(props, 'selectedServiceEntry.laborSubtotal');
    const discount = getDiscount(props.selectedServiceEntry);
    const tax = getTax(props.selectedServiceEntry);
    const total = get(props, 'selectedServiceEntry.totalAmount');
    const vendor = get(props, 'selectedServiceEntry.vendor.name');
    const notes = get(props, 'selectedServiceEntry.notes');
    const reference = get(props, 'selectedServiceEntry.reference');
    const renderItem = (label, value, unit, currency, fullWidth) => {
        return (_jsxs("div", { className: `mb-3 ${fullWidth ? 'col-12' : 'col-6'}`, children: [_jsx("div", { children: label }), _jsxs("div", { className: "font-weight-bold", children: [currency && `${currency} `, currency ? round(value, 2) : value, unit && ` ${unit}`] })] }));
    };
    const renderLineItemTitle = (item) => {
        return (_jsx("div", { className: "font-weight-bold", children: props.serviceTasksListAsMap && props.serviceTasksListAsMap.get(item.itemId)?.name }));
    };
    const renderLineItemDescription = (item) => {
        return (_jsxs("div", { children: [!!item?.vehicleDomain && (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-muted mr-1", children: "Vehicle Domain:" }), _jsx("span", { className: "font-weight-bold text-black", children: item.vehicleDomain }), _jsx("br", {})] })), !!item?.description && (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-muted mr-1", children: "Note:" }), _jsx("span", { className: "font-weight-bold text-black", children: item.description }), _jsx("br", {})] })), !!item?.laborCost && (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-muted mr-1", children: "Labor Cost:" }), _jsxs("span", { className: "text-black font-weight-bold", children: [currency, " ", item.laborCost] })] }))] }));
    };
    const renderServicePartTitle = (part, index) => {
        const tax1Percent = get(part, 'tax1Percent');
        const tax2Percent = get(part, 'tax2Percent');
        const discountPercent = get(part, 'discountPercent');
        const cost = get(part, 'cost', 0);
        const costAfterDiscount = cost - (cost * discountPercent) / 100;
        const tax1 = (costAfterDiscount * tax1Percent) / 100;
        const tax2 = (costAfterDiscount * tax2Percent) / 100;
        const total = round(costAfterDiscount + tax1 + tax2, 2);
        return (_jsxs("div", { className: "d-flex", children: [_jsxs("div", { className: "grey-border-badge mr-2", color: "white", children: ["Part ", String.fromCharCode(65 + index)] }), _jsx("div", { className: "text-muted mr-1", children: "Total Cost:" }), _jsxs("div", { className: "text-black font-weight-bold", children: [currency, " ", total] })] }));
    };
    const renderServicePartDescription = (part) => {
        const partType = get(part, 'part.name');
        const unit = get(part, 'unit');
        const quantity = get(part, 'count');
        const tax1Type = get(part, 'tax1Type');
        const tax1Percent = get(part, 'tax1Percent');
        const tax2Type = get(part, 'tax2Type');
        const tax2Percent = get(part, 'tax2Percent');
        const discountPercent = get(part, 'discountPercent');
        const cost = get(part, 'cost', 0);
        const discount = (cost * discountPercent) / 100;
        const costAfterDiscount = cost - discount;
        const tax1 = (costAfterDiscount * tax1Percent) / 100;
        const tax2 = (costAfterDiscount * tax2Percent) / 100;
        return (_jsxs("div", { className: "row mt-2", style: {
                color: 'black',
            }, children: [!!partType && renderItem('Part Type', partType), !!cost && renderItem('Parts Cost', cost, null, currency), !!tax1Type && !!tax1Percent && renderItem('Tax 1 Type', tax1Type), !!tax1Percent && renderItem('Tax 1', tax1, null, currency), !!tax2Type && !!tax2Percent && renderItem('Tax 2 Type', tax2Type), !!tax2Percent && renderItem('Tax 2', tax2, null, currency), !!discountPercent && renderItem('Discount', discount, null, currency), !!quantity && renderItem('Quantity', quantity, unit)] }));
    };
    return (_jsxs(FxDrawer, { title: _jsxs("div", { className: "d-flex col-gap-1", children: [_jsx(Link, { className: "btn btn-primary", to: `/dashboard/maintenance/serviceentries/edit/${props.selectedServiceEntry.vehicleId}/${props.selectedServiceEntry.id}`, children: "Edit" }), _jsx(Button, { color: "primary", outline: true, onClick: () => {
                        if (confirm(`Are you sure you want to delete this Service Entry?`)) {
                            props.onDeleteRow(props.selectedServiceEntry.id);
                        }
                    }, children: "Delete" })] }), open: props.openDetails, onClose: props.onDetailToggle, bodyStyle: {
            background: '#f6f8fa',
            padding: '1rem',
        }, headerStyle: {
            background: '#f6f8fa',
            borderBottom: 0,
        }, width: 450, className: "antd-new-drawer", children: [!!serviceEntryApprovalConfigEnabled() && (_jsx(ServiceEntryTimelineComponent, { approvalData: mappedData, showButton: showButton, acceptanceLevel: acceptanceLevel, onApproveOrReject: props.onApproveOrReject })), _jsxs("div", { className: "card mt-2", children: [_jsxs("div", { className: "d-flex justify-content-between p-3", style: {
                            borderBottom: '1px solid #e9ecef',
                        }, children: [_jsx("div", { className: "font-weight-bold", children: licensePlate }), _jsxs("div", { className: "text-muted font-xs", children: ["Date: ", getHumanizeTime(date)] })] }), _jsxs("div", { className: "row p-3", style: {
                            borderBottom: '1px solid #e9ecef',
                        }, children: [!!odometer && renderItem('Odometer', odometer, 'Km'), !!total && renderItem('Total', total, null, currency), !!partCost && renderItem('Part Cost', partCost, null, currency), !!laborCost && renderItem('Labor Cost', laborCost, null, currency), discount !== -1 && renderItem('Discount', discount, null, currency), !!tax && renderItem('Tax', tax, null, currency), !!vendor && renderItem('Vendor', vendor), !!notes && renderItem('Notes', notes, null, null, true), !!reference && renderItem('Reference', reference, null, null, true)] }), _jsxs("div", { className: "p-3", children: ["Line Items:", _jsxs(FxSteps, { className: "mt-2", direction: "vertical", size: "small", current: 1000, themeModify: {
                                    colorPrimary: '#76c09d',
                                }, children: [map(get(props, 'selectedServiceEntry.serviceLineItems'), (item, index) => {
                                        return (_jsxs(_Fragment, { children: [_jsx(FxSingleStep, { title: renderLineItemTitle(item), description: renderLineItemDescription(item), icon: _jsx("div", { className: "green-icon-number font-sm", color: "white", children: index + 1 }) }, index), map(get(item, 'serviceParts'), (part, index) => {
                                                    return (_jsx(FxSingleStep, { icon: _jsx("i", { className: "fa fa-circle font-sm pl-one-point-five", style: { color: '#76c09d' } }), title: renderServicePartTitle(part, index), description: renderServicePartDescription(part) }, index));
                                                })] }));
                                    }), _jsx(FxSingleStep, { title: "", description: "", icon: _jsx(_Fragment, {}) }, 1000)] })] })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        loggedInUser: get(state, 'login.data'),
        vehicleListMiniAsMap: get(state, 'vehicles.vehicleListMiniAsMap'),
        serviceTasksListAsMap: get(state, 'servicetasks.serviceTasksListAsMap'),
        userListMap: state.users.userListMiniAsMap,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceEntriesDetail);
