import { sendAppAlertToSlack } from '../../utils/analytics_utils';
export default function componentLoader(lazyComponent, retryCount) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
            //sendAppAlertToSlack(`Chunk Load Error + ${retryCount} + ${error}`, '#alerts-web');
            setTimeout(() => {
                if (retryCount === 1) {
                    sendAppAlertToSlack(`Chunk Load Error + ${retryCount} + ${error}`, '#alerts-web');
                    reject(error);
                    return;
                }
                componentLoader(lazyComponent, retryCount - 1).then(resolve, reject);
            }, 1000);
        });
    });
}
export const NO_OF_RETRIES = 3;
