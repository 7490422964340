import { jsx as _jsx } from "react/jsx-runtime";
import { filter, forEach, get, includes, last, map, set, split, trim } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { handleError } from 'constant';
import { isImageUrl } from 'utils/img_utils';
import { FxFileTwoTone, FxLoadingIcon, FxPaperClipOutlined } from '../sharedV2/FxIcons';
export const ALLOWED_MIME_FILE_TYPES = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'text/csv',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/gif',
    'video/mp4',
    'video/x-msvideo',
    'video/quicktime',
];
export const ALLOWED_MIME_FILE_TYPES_EXTENSIONS = {
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.ms-excel': ['.xls'],
    'text/csv': ['.csv'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/gif': ['.gif'],
    'video/mp4': ['.mp4'],
    'video/x-msvideo': ['.avi'],
    'video/quicktime': ['.mov'],
};
export function getValidUploadFileTypes(passedAcceptTypes = '') {
    if (!passedAcceptTypes) {
        return ALLOWED_MIME_FILE_TYPES_EXTENSIONS;
    }
    const passedAcceptTypesArr = filter(map(split(passedAcceptTypes, ','), (v) => trim(v)), (v2) => !!v2);
    const filteredAcceptTypes = filter(passedAcceptTypesArr, (type) => {
        return includes(ALLOWED_MIME_FILE_TYPES, type);
    });
    const validAcceptTypes = {};
    forEach(filteredAcceptTypes, (type) => {
        validAcceptTypes[type] = ALLOWED_MIME_FILE_TYPES_EXTENSIONS[type];
    });
    return validAcceptTypes;
}
export function validateFileBeforeUpload(file, options = {}) {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', '');
    const allowDotInFileName = includes([13032, 936], accountId);
    const {} = options;
    let error = false;
    let errorMsg = '';
    try {
        if (!file) {
            error = true;
            errorMsg = window.t('pleaseUploadAValidFile');
        }
        const fileName = `${file.name}`;
        // get the extension
        const ext = `${get(fileName.match(/\.(.+)$/), '[1]', '')}`;
        // Reject if ext still contain a dot (which is implemented as part of the double extension prevention)
        if (!allowDotInFileName && ext.indexOf('.') !== -1) {
            error = true;
            errorMsg = window.t('filenameWithTwoExtensionsError');
        }
        // reject if uploaded file type is not valid
        const type = file.type;
        if (!includes(ALLOWED_MIME_FILE_TYPES, type)) {
            error = true;
            errorMsg = window.t('fileTypeIsNotAllowedError');
        }
    }
    catch (e) {
        error = true;
        errorMsg = window.t('pleaseUploadAValidFile');
        console.log(e);
    }
    return {
        error,
        errorMsg,
    };
}
export function getFileNameFromUrl(url) {
    const urlTemp = `${url}`;
    return `${urlTemp}`.substring(`${urlTemp}`.lastIndexOf('/') + 1);
}
export function getFileExtFromUrl(url) {
    return last(split(url, '.'));
}
export function getUrlToUploadFileObject(url) {
    return {
        uid: uuidv4(),
        url,
        name: getFileNameFromUrl(url),
        status: 'done',
    };
}
export function getParsedFileList(fileList, options = {}) {
    const { pathToUrlInData } = options || {};
    return map(fileList, (file) => {
        if (file.status === 'error') {
            set(file, 'error', new Error(handleError(file.response)));
        }
        else if (!file.url && file.response) {
            const filePath = typeof file.response === 'string' ? file.response : get(file.response, pathToUrlInData);
            set(file, 'url', filePath);
        }
        return file;
    });
}
export var FILE_UPLOAD_STATUS;
(function (FILE_UPLOAD_STATUS) {
    FILE_UPLOAD_STATUS["ERROR"] = "error";
    FILE_UPLOAD_STATUS["SUCCESS"] = "success";
    FILE_UPLOAD_STATUS["DONE"] = "done";
    FILE_UPLOAD_STATUS["UPLOADING"] = "uploading";
    FILE_UPLOAD_STATUS["REMOVED"] = "removed";
})(FILE_UPLOAD_STATUS || (FILE_UPLOAD_STATUS = {}));
export const UPLOAD_LIST_TYPE = {
    TEXT: 'text',
    PICTURE: 'picture',
    PICTURE_CARD: 'picture-card',
};
export function setFieldValueOnFile(file, pairs) {
    if (file) {
        forEach(pairs, (keyVale) => {
            const key = keyVale[0];
            let value = keyVale[1];
            if (keyVale[0] === 'error') {
                value = new Error(value);
            }
            set(file, key, value);
        });
    }
    return file;
}
export const iconRender = (onClick) => {
    const onClickHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick(event);
    };
    return (file, uploadListType) => {
        if (file.status === FILE_UPLOAD_STATUS.UPLOADING) {
            return _jsx(FxLoadingIcon, {});
        }
        if (uploadListType === UPLOAD_LIST_TYPE.TEXT) {
            return _jsx(FxPaperClipOutlined, {});
        }
        if (uploadListType === UPLOAD_LIST_TYPE.PICTURE) {
            const url = get(file, 'url');
            return isImageUrl(url) ? (_jsx("img", { width: 48, height: 48, onClick: onClickHandler, src: get(file, 'url'), alt: 'img' })) : (_jsx(FxFileTwoTone, {}));
        }
    };
};
export function getMimeTypeToExtension(mimeType) {
    const ext = get(split(mimeType, '/'), '[0]');
    if (ext && includes(ALLOWED_MIME_FILE_TYPES_EXTENSIONS[mimeType], ext)) {
        return ext;
    }
    else {
        return ALLOWED_MIME_FILE_TYPES_EXTENSIONS[mimeType]?.[0];
    }
}
