import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_CLIENT_CHART = 'CREATE_CLIENT_CHART';
export const UPDATE_CLIENT_CHART = 'UPDATE_CLIENT_CHART';
export const FETCH_CLIENT_CHART_BY_ID = 'FETCH_CLIENT_CHART_BY_ID';
export const FETCH_CLIENT_CHARTS = 'FETCH_CLIENT_CHARTS';
export const DELETE_CLIENT_CHART = 'DELETE_CLIENT_CHART';
export const DOWNLOAD_CLIENT_CHART = 'DOWNLOAD_CLIENT_CHART';
export function createClientChart(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}rate-chart/client`, data, config);
    return {
        type: CREATE_CLIENT_CHART,
        promise: request,
    };
}
export function updateClientChart(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}rate-chart/client`, data, config);
    return {
        type: UPDATE_CLIENT_CHART,
        promise: request,
    };
}
export function fetchClientCharts(accesstoken, params) {
    const { routeId, loadTypeId, consignorId, consigneeId } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            routeId,
            loadTypeId,
            consigneeId,
            consignorId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}rate-chart/client`, config);
    return {
        type: FETCH_CLIENT_CHARTS,
        promise: request,
    };
}
export function fetchClientChartById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}rate-chart/client/${id}`, config);
    return {
        type: FETCH_CLIENT_CHART_BY_ID,
        promise: request,
    };
}
export function deleteClientChart(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}rate-chart/client/${id}`, config);
    return {
        type: DELETE_CLIENT_CHART,
        promise: request,
    };
}
export function downloadClientChart(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}report/rate-chart/client`, config);
    return {
        type: DOWNLOAD_CLIENT_CHART,
        promise: request,
    };
}
