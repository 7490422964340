import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const IssuesComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-issues" */ 'components/dashboard/issues/v2/issues_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditIssueComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-issues-add-edit" */ 'components/dashboard/issues/v2/add_edit_issue_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewIssueComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-issues-view" */ 'components/dashboard/issues/v2/view_issue_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const issuesRoutes = [
    {
        path: 'add',
        lazy: AddEditIssueComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditIssueComponent,
    },
    {
        path: 'view/:id',
        lazy: ViewIssueComponent,
    },
    {
        path: 'edit-template/:id',
        lazy: AddEditIssueComponent,
    },
    {
        index: true,
        lazy: IssuesComponent,
    },
];
export default issuesRoutes;
