import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find, get, set } from 'lodash';
import { FieldArray } from 'redux-form';
import { combineNameAddress } from '../../constant';
import { createIdValueFromAddress } from '../../utils/form_utils';
import { fetchCustomers } from '../../actions/dashboard/finances/customer_action';
import { fetchAddressBook } from '../../actions/dashboard/route_dispatch/address_book_action';
import { fetchCommodities } from '../../actions/dashboard/commodity_actions';
import SelectLocationFromGoogleMapModal from '../../components/dashboard/route_dispatch/jobs/select_location_from_google_map_modal';
import RoutePointsList from './RoutePointsList';
const RoutePointsDetailsComponentOld = (props) => {
    const { accesstoken, name, addressBook, dataFetchFlags, groupId, cardNeeded, change } = props;
    const [addressBookExtraOption, setAddressBookExtraOption] = useState([]);
    const { dontFetchCustomers, dontFetchAddress, dontFetchCommodity } = dataFetchFlags || {};
    const [googleMapLocationModal, setGoogleMapLocationModal] = useState(false);
    const [googleMapLocationIndex, setGoogleMapLocationIndex] = useState(0);
    const onGoogleMapLocationClosed = () => {
        setGoogleMapLocationIndex(0);
        setGoogleMapLocationModal(false);
    };
    const setAddressRelatedFields = (location, index, setFieldValue) => {
        const newRoutePoints = {
            latitude: location.latitude,
            longitude: location.longitude,
            name: location.name,
            selectId: createIdValueFromAddress({ ...location }).value,
        };
        if (location.address) {
            newRoutePoints.address = location.address;
        }
        if (location.addressBookId) {
            newRoutePoints.addressBookId = location.addressBookId;
        }
        setFieldValue(`${name}.[${index}].routePoint`, newRoutePoints);
    };
    const onGoogleMapLocationSelected = (location, index, setFieldValue) => {
        if (!location) {
            return;
        }
        onGoogleMapLocationClosed();
        if (index >= 0) {
            const found = find(addressBook, {
                name: location.name,
                latitude: location.latitude,
                longitude: location.longitude,
            });
            if (!found) {
                location = set(location, 'address', get(location, 'name'));
                setAddressBookExtraOption((prevV) => [
                    ...prevV,
                    {
                        name: location.name,
                        latitude: location.latitude,
                        longitude: location.longitude,
                    },
                ]);
                setAddressRelatedFields(location, index, setFieldValue);
            }
            else {
                setAddressRelatedFields(found, index, setFieldValue);
            }
        }
    };
    useEffect(() => {
        if (!dontFetchCustomers) {
            props.fetchCustomers(accesstoken);
        }
        if (!dontFetchCommodity) {
            props.fetchCommodities(accesstoken);
        }
        if (!dontFetchAddress) {
            props.fetchAddressBook(accesstoken, groupId);
        }
    }, [dontFetchCustomers, dontFetchAddress, dontFetchCommodity]);
    const onRoutePointSelect = (point, index) => {
        if (point) {
            const newRoutePoints = {
                address: combineNameAddress(point.name, point.address),
                latitude: point.latitude,
                longitude: point.longitude,
                addressBookId: point.id,
                name: point.name,
                selectId: createIdValueFromAddress(point).value,
            };
            change(`${name}.[${index}].routePoint`, newRoutePoints);
        }
        else {
            change(`${name}.[${index}].routePoint`, {});
        }
    };
    const selectLocationFromGoogleMap = (index) => {
        setGoogleMapLocationIndex(index);
        setGoogleMapLocationModal((v) => !v);
    };
    return (_jsxs("div", { className: `mt-2 ${cardNeeded ? 'card' : ''}`, children: [_jsx("div", { className: `${cardNeeded ? 'card-body' : ''}`, children: _jsx(FieldArray, { name: name, component: RoutePointsList, props: {
                        parentProps: props,
                        addressBookExtraOption,
                        onRoutePointSelect,
                        selectLocationFromGoogleMap,
                    } }) }), _jsx(SelectLocationFromGoogleMapModal, { index: googleMapLocationIndex, isOpen: googleMapLocationModal, onClosed: onGoogleMapLocationClosed, setFieldValue: change, onLocationSelected: onGoogleMapLocationSelected })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchCustomers, fetchAddressBook, fetchCommodities }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        addressBook: state.addressBook.addressBook,
        customers: get(state, 'customers.customers', []),
        commodityList: state.commodity.commodityList,
        groupId: state.local.defaultGroupId,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RoutePointsDetailsComponentOld);
