import { getAllWordsCapitalized } from '../../../utils/string_utils';
import { showTagNameInsteadOfJointNameForAcnt } from '../../../utils/account_utils';
export default (props) => {
    const tag = props.tag;
    const styleObject = {};
    if (props.maxWidth) {
        styleObject['maxWidth'] = props.maxWidth;
    }
    if (props.lineHeight) {
        styleObject['lineHeight'] = props.lineHeight;
    }
    if (props.iconPadding) {
        styleObject['paddingRight'] = 22;
    }
    const showTagName = showTagNameInsteadOfJointNameForAcnt(props.loggedInUser);
    if (!tag) {
        return null;
    }
    return (
        <div title={tag.jointName} className="font-sm badge-pill tag-style py-1" style={styleObject}>
            {getAllWordsCapitalized(showTagName ? tag.name : tag.jointName)}
        </div>
    );
};

export const ShowTyreTag = ({ tag }) => {
    return (
        <div title={tag.name} className="font-sm badge-pill tag-style py-1">
            {getAllWordsCapitalized(tag.name)}
        </div>
    );
};
