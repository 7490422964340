import React from 'react';
import moment from 'moment';
import { get } from 'lodash';

export const OverviewPages = {
    oldOverview: 'oldOverview',
    ownership: 'ownership',
    overviewWidget: 'overviewWidget',
    ptlAnalytics: 'ptlAnalytics',
    utilization: 'utilization',
    jobOverview: 'jobOverview',
    fuelOverview: 'fuelOverview',
};
export const RenderError = ({ error }) => {
    if (!error) {
        return null;
    }
    return (
        <div className="col-md-12 mb-2" style={{ textAlign: 'center', color: 'red' }}>
            <strong color="red">{error}</strong>
        </div>
    );
};

export const getUpdatedData = (xAxisKey, data) => {
    if (data.length) {
        return [
            { [xAxisKey]: '' },
            ...(data || []),
            {
                [xAxisKey]: ' ',
            },
        ];
    }
    return [];
};

export const getDateRangeParams = (key, startDate, endDate) => {
    const diff = endDate - startDate;
    switch (key) {
        case 0:
            return {
                from: moment(startDate)
                    .subtract(diff * 3)
                    .valueOf(),
                to: moment(endDate)
                    .subtract(diff * 3)
                    .valueOf(),
            };
        case 1:
            return {
                from: moment(startDate)
                    .subtract(diff * 2)
                    .valueOf(),
                to: moment(endDate)
                    .subtract(diff * 2)
                    .valueOf(),
            };
        case 2:
            return {
                from: moment(startDate).subtract(diff).valueOf(),
                to: moment(endDate).subtract(diff).valueOf(),
            };
        default:
            // 0
            return { from: startDate, to: endDate };
    }
};

export const VEHICLE_SERVICE_COL = [
    {
        key: 'type',
        isKey: true,
        label: 'Service Type',
        tdStyle: { width: 220 },
        thStyle: { paddingRight: 16, width: 220 },
    },
    {
        key: '0-1',
        label: '0-1 Days',
    },

    {
        key: '1-3',
        label: '1-3 Days',
    },
    {
        key: '3-7',
        label: '3-7 Days',
    },
    {
        key: '7-15',
        label: '7-15 Days',
    },
    {
        key: '>15',
        label: '>15 Days',
    },
];

export const JOB_POSITION_COL = [
    {
        key: 'type',
        isKey: true,
        label: 'Job Status',
    },
    {
        key: '20',
        label: '20 Feet',
    },
    {
        key: '40',
        label: '40 Feet',
    },

    {
        key: '12',
        label: '12 Feet',
    },
    {
        key: 'EmptySize',
        label: 'Others',
    },
];

export const VEHICLE_REMINDER_COL = [
    {
        key: 'type',
        isKey: true,
        label: 'Validity Doc',
        tdStyle: { width: 120 },
        thStyle: { width: 120 },
    },
    {
        key: 'dueSoonCount',
        selectKey: 'dueSoon',
        label: 'Due Soon',
        type: 'badge',
        tdStyle: { width: 100 },
        thStyle: { width: 100 },
    },
    {
        key: 'overdueCount',
        selectKey: 'overdue',
        label: 'Expired',
        type: 'badge',
        tdStyle: { width: 70 },
        thStyle: { paddingRight: 16, width: 80 },
    },
];

export const VEHICLE_STATUS_COL = [
    {
        key: 'vehicleStatus',
        isKey: true,
        tdStyle: { padding: '8px' },
    },
    {
        key: 'status',
        type: 'badge',
        tdStyle: { textAlign: 'end', width: 80, padding: '0px' },
        thStyle: { textAlign: 'end', width: 80, padding: '0px' },
    },
];

export const VEHICLE_WORKING_STATUS_COL = [
    {
        key: 'type',
        isKey: true,
        label: 'Tag',
        tdStyle: { width: 120 },
        thStyle: { width: 120 },
    },
    {
        key: 'workingVehicleCount',
        label: 'Working',
        type: 'badge',
        tdStyle: { width: 70 },
        thStyle: { width: 80 },
    },
    {
        key: 'nonWorkingVehicleCount',
        label: 'Non Working',
        type: 'badge',
        tdStyle: { width: 100 },
        thStyle: { width: 100 },
    },
];

export const getActiveStatusData = (summaryData) => {
    return [
        { label: window.t('totalActiveTrips'), value: get(summaryData, 'currentJobs', 0).toLocaleString() },
        {
            label: window.t('runningOnTime'),
            value: (
                <span>
                    {get(summaryData, 'currentOnTimeJobs', 0).toLocaleString()}
                    <span style={{ color: 'green', fontSize: 24 }}>
                        {`(${get(summaryData, 'currentOnTimeJobsPercentage', '0')}%)`}
                    </span>
                </span>
            ),
        },
        {
            label: window.t('onTimeDelivery'),
            value: get(summaryData, 'onTimeDeliveryJobs', 0).toLocaleString(),
        },
    ];
};
