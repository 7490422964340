import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';

export const CREATE_MULTIPLE_SERVICE_REMINDERS = 'create_multiple_service_reminders';
export const CREATE_SERVICE_REMINDER = 'create_service_reminder';
export const FETCH_SERVICE_REMINDERS = 'fetch_service_reminders';
export const FETCH_SERVICE_REMINDER = 'fetch_service_reminder';
export const UPDATE_SERVICE_REMINDER = 'update_service_reminder';
export const DELETE_SERVICE_REMINDER = 'delete_service_reminder';
export const FETCH_SERVICE_REMINDERS_COUNT = 'fetch_service_reminders_count';
export const FETCH_SERVICE_REMINDERS_NOTIFICATIONS = 'fetch_service_reminders_notifications';
export const FETCH_SERVICE_REMINDERS_VEHICLE_WISE_COUNT = 'fetch_service_reminders_vehicle_wise_count';
export const SEND_SERVICE_REMINDERS_NOTIFICATION = 'send_service_reminders_notification';
export const RESOLVE_SERVICE_REMINDER = 'resolve_service_reminder';
export const FETCH_SERVICE_HISTORY = 'fetch_service_history';
export const DELETE_DOCUMENT_FROM_SERVICE_REMINDER = 'DELETE_DOCUMENT_FROM_SERVICE_REMINDER';

export function createMultipleServiceReminders(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}service_reminders/createMultipleServiceReminders`, values, config);
    return {
        type: CREATE_MULTIPLE_SERVICE_REMINDERS,
        promise: request,
    };
}

export function createServiceReminder(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}service_reminders/`, values, config);
    return {
        type: CREATE_SERVICE_REMINDER,
        promise: request,
    };
}

export function updateServiceReminder(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}service_reminders/update`, values, config);

    return {
        type: UPDATE_SERVICE_REMINDER,
        promise: request,
    };
}

export function fetchServiceRemindersCount(accesstoken, groupId, vehicleId, serviceTaskId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const serviceTaskQuery = serviceTaskId ? `&serviceTask.id=${serviceTaskId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}service_reminders/count?${vehicleQuery}${groupQuery}${serviceTaskQuery}`,
        config
    );

    return {
        type: FETCH_SERVICE_REMINDERS_COUNT,
        promise: request,
    };
}

export function fetchVehicleWiseServiceReminderCount(accesstoken, serviceTaskId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            serviceTaskId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}service_reminders/count/vehicle-wise`, config);

    return {
        type: FETCH_SERVICE_REMINDERS_VEHICLE_WISE_COUNT,
        promise: request,
    };
}

export function fetchServiceHistory(accesstoken, serviceTaskId, vehicleId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const vehicleQuery = vehicleId ? `vehicleId=${vehicleId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}service_entries/service-task/vehicle/${serviceTaskId}?${vehicleQuery}`,
        config
    );

    return {
        type: FETCH_SERVICE_HISTORY,
        promise: request,
    };
}

export function fetchServiceReminders(
    accesstoken,
    page = 1,
    size = 1000,
    groupId,
    vehicleId,
    serviceTaskId,
    overdue,
    dueSoon
) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const serviceTaskQuery = serviceTaskId ? `&serviceTask.id=${serviceTaskId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const dueSoonQuery = dueSoon ? `&dueSoon=true` : '';
    const overdueQuery = overdue ? `&overdue=true` : '';
    const request = axios.get(
        `${ROOT_API_URL}service_reminders/?page=${
            page - 1
        }&size=${size}${vehicleQuery}${groupQuery}${serviceTaskQuery}${dueSoonQuery}${overdueQuery}`,
        config
    );

    return {
        type: FETCH_SERVICE_REMINDERS,
        promise: request,
    };
}

export function fetchServiceReminder(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}service_reminders/${id}`, config);

    return {
        type: FETCH_SERVICE_REMINDER,
        promise: request,
    };
}

export function fetchServiceRemindersNotifications(accesstoken, ids) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}service_reminders/notifications?ids=${ids}`, config);
    return {
        type: FETCH_SERVICE_REMINDERS_NOTIFICATIONS,
        promise: request,
    };
}

export function sendServiceReminderNotification(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}service_reminders/${id}/sendNotifications`, config);
    return {
        type: SEND_SERVICE_REMINDERS_NOTIFICATION,
        promise: request,
    };
}

export function resolveServiceReminder(accesstoken, id, lastOccurredAt) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const lastOccurredAtQuery = lastOccurredAt ? `?lastOccurredAt=${lastOccurredAt}` : '';
    const request = axios.get(`${ROOT_API_URL}service_reminders/${id}/resolve${lastOccurredAtQuery}`, config);
    return {
        type: RESOLVE_SERVICE_REMINDER,
        promise: request,
    };
}

export function deleteServiceReminder(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}service_reminders/${id}`, config);
    return {
        type: DELETE_SERVICE_REMINDER,
        promise: request,
    };
}

export function deleteDocumentFromServiceReminder(accesstoken, imageId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            imageId,
        }),
    };
    const request = axios.delete(`${ROOT_API_URL}service_reminders/delete-document`, config);
    return {
        type: DELETE_DOCUMENT_FROM_SERVICE_REMINDER,
        promise: request,
    };
}
