import { FxBadge, FxDrawer } from 'sharedV2';
import { useToken } from '../../theme';
import { get, set } from 'lodash';

export default (props) => {
    const { wrapperStyle = {} } = props;
    const { onlyBody, openLeft, drawerProps = {} } = props;
    const { token } = useToken();

    const titleRender = onlyBody ? null : (
        <div>
            {!props.activeFilters && props.title}
            {!!props.activeFilters && (
                <FxBadge
                    className={'ant-drawer-title'}
                    color={token.colorPrimary}
                    offset={[11, -2]}
                    count={props.activeFilters}
                >
                    {props.title}
                </FxBadge>
            )}
        </div>
    );
    if (!get(drawerProps, 'size') && !get(drawerProps, 'width')) {
        set(drawerProps, 'width', 300);
    }
    return (
        <FxDrawer
            style={wrapperStyle}
            open={props.open}
            title={titleRender}
            onClose={onlyBody ? null : props.onClose}
            closeIcon={!onlyBody}
            placement={openLeft ? 'left' : 'right'}
            {...drawerProps}
        >
            {props.children}
        </FxDrawer>
    );
};
