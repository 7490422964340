import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditDivisionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditDivision" */ 'components/dashboard/consignments/divisions/AddEditDivisionComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const DivisionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-divisions" */ 'components/dashboard/consignments/divisions/DivisionComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const divisionRoutes = [
    {
        path: 'add',
        lazy: AddEditDivisionComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditDivisionComponent,
    },
    {
        index: true,
        lazy: DivisionComponent,
    },
];
export default divisionRoutes;
