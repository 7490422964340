import axios from "axios";
import {ROOT_API_URL} from "../../../constant";

export const CREATE_CONTACT_RENEWAL_TYPE = 'create_contact_renewal_type';
export const FETCH_CONTACT_RENEWAL_TYPES = 'fetch_contact_renewal_types';
export const FETCH_CONTACT_RENEWAL_TYPE = 'fetch_contact_renewal_type';
export const UPDATE_CONTACT_RENEWAL_TYPE = 'update_contact_renewal_type';
export const DELETE_CONTACT_RENEWAL_TYPE = 'delete_contact_renewal_type';

export function createContactRenewalType(accesstoken, values) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.post(`${ROOT_API_URL}contact_renewal_types/`, values, config);
    return {
        type: CREATE_CONTACT_RENEWAL_TYPE, promise: request,
    }
}

export function updateContactRenewalType(accesstoken, values, id) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.patch(`${ROOT_API_URL}contact_renewal_types/${id}`, values, config);

    return {
        type: UPDATE_CONTACT_RENEWAL_TYPE, promise: request
    }
}

export function fetchContactRenewalTypes(accesstoken) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.get(`${ROOT_API_URL}contact_renewal_types/`, config);

    return {
        type: FETCH_CONTACT_RENEWAL_TYPES, promise: request,
    }

}

export function fetchContactRenewalType(id, accesstoken) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.get(`${ROOT_API_URL}contact_renewal_types/${id}`, config);

    return {
        type: FETCH_CONTACT_RENEWAL_TYPE, promise: request,
    }

}


export function deleteContactRenewalType(accesstoken, id) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.delete(`${ROOT_API_URL}contact_renewal_types/${id}`, config);
    return {
        type: DELETE_CONTACT_RENEWAL_TYPE, promise: request
    }
}
