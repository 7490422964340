import { jsx as _jsx } from "react/jsx-runtime";
import ErrorBoundary from 'components/utils/error_boundary_component';
import ArrivalComponent from 'components/dashboard/arrival/ArrivalComponent';
import AddEditArrivalComponent from 'components/dashboard/arrival/forms/AddEditArrivalComponent';
const arrivalRoutes = [
    {
        index: true,
        element: (_jsx(ErrorBoundary, { children: _jsx(ArrivalComponent, {}) })),
    },
    {
        path: 'add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditArrivalComponent, {}) })),
    },
    {
        path: 'edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditArrivalComponent, {}) })),
    },
    {
        path: 'lorry/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditArrivalComponent, { arrivalType: "LORRY_ARRIVAL" }) })),
    },
    {
        path: 'lorry/edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditArrivalComponent, { arrivalType: "LORRY_ARRIVAL" }) })),
    },
];
export default arrivalRoutes;
