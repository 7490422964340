import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditCustomChargesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditCustomCharges" */ 'components/dashboard/custom-charges/AddEditCustomChargesComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const CustomChargesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-customCharges" */ 'components/dashboard/custom-charges/CustomChargesComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const customChargesRoutes = [
    {
        index: true,
        lazy: CustomChargesComponent,
    },
    {
        path: 'add',
        lazy: AddEditCustomChargesComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditCustomChargesComponent,
    },
];
export default customChargesRoutes;
