import { handle } from 'redux-pack';

import {
    CHECK_CONSENT_FOR_ASSET_TRACKING,
    DELETE_SIM_ASSET_FOR_TRACKING,
    FETCH_DEVICE,
    FETCH_DEVICE_TYPES_COUNT_USER_HAS,
    FETCH_DEVICES_FOR_USER,
} from '../../actions/dashboard/device_action';

import { get } from 'lodash';

const initialState = {
    isLoading: false,
    error: null,
    data: {},
    isFinished: false,
    deviceTypesCountUserHas: {},
    isCheckingConsent: false,
    isDeletingConsent: false,
    deviceConsent: {},
    devicesForUser: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_DEVICE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        case FETCH_DEVICES_FOR_USER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, devicesForUser: payload.data }),
            });
        case FETCH_DEVICE_TYPES_COUNT_USER_HAS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, deviceTypesCountUserHas: payload.data.deviceTypeCountMap }),
            });
        case CHECK_CONSENT_FOR_ASSET_TRACKING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isCheckingConsent: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isCheckingConsent: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const mobileDeviceId = get(get(payload, 'config.url', '').match(/mobile=([\d]+)/), '[1]');
                    const deviceConsent = { ...prevState.deviceConsent };
                    if (mobileDeviceId) {
                        deviceConsent[mobileDeviceId] = payload.data.status;
                    }
                    return { ...prevState, deviceConsent: { ...deviceConsent } };
                },
            });
        case DELETE_SIM_ASSET_FOR_TRACKING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isDeletingConsent: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isDeletingConsent: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return { ...prevState };
                },
            });

        default:
            return state;
    }
}
