export default (props) => {
    const {
        input,
        name,
        label,
        isRequired,
        helptext,
        colClass,
        disabled,
        meta: { touched, error },
        style,
        placeholder,
        numberOfRow,
        outerDivClass = 'form-group',
    } = props;

    const className = `${outerDivClass} row ${touched && error ? 'has-danger' : ''}`;
    const labelClass = `${touched && error ? 'text-danger' : ''}`;
    return (
        <div className={className}>
            {label && (
                <div className={`col-md-3 ${colClass}`}>
                    <label htmlFor={name} className={labelClass}>
                        {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    </label>
                </div>
            )}
            <div className={`${label ? 'col-md-9' : 'col-md-12'} ${colClass}`}>
                <textarea
                    style={{ width: '100%', ...style }}
                    className="form-control"
                    rows={`${numberOfRow ? numberOfRow : '2'}`}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...input}
                />
                {touched && error ? (
                    <span className="help-block text-danger">{error}</span>
                ) : (
                    helptext && <span className="help-block">{helptext}</span>
                )}
            </div>
        </div>
    );
};
