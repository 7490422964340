import { handle } from 'redux-pack';
import { FETCH_ALL_CUSTOM_FIELDS_FOR_ACCOUNT } from '../../actions/dashboard/custom_field_actions';
import { get } from 'lodash';

const initialState = {
    isLoading: false,
    error: null,
    allCustomFieldsForAccount: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ALL_CUSTOM_FIELDS_FOR_ACCOUNT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    allCustomFieldsForAccount: get(payload, 'data.customFields'),
                }),
            });
        default:
            return state;
    }
}
