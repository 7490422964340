import { includes, round, toNumber } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { change, Field, FieldArray } from 'redux-form';
import { SERVICE_ENTRY_TYPES, SERVICE_WORK_TYPES, TAX_TYPES_V2 } from '../../../../constant';
import { isAgConstructionAccount } from '../../../../utils/account_utils';
import { mappedServiceTasks } from '../../../../utils/form_utils';
import { issueOptionRender } from '../../../../utils/issues_utils';
import { mappedIssuesOptions } from '../../../utils/mapping_utils';
import SelectInput from '../../../utils/SelectInput';
import AddEditServiceTaskComponent from '../../servicetasks/add_edit_service_task_component';
import ServicePart from './service_part';

import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { mappedServiceLineItemUnits } from '../../../../constant';

class ServiceLineItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceAddModal: false,
            isOldCodeMode: false,
        };
    }
    componentDidUpdate(prevProps, props) {
        if (
            !this.state.isOldCodeMode &&
            this.props.serviceParts?.length === 0 &&
            (this.props.hasPartsCost > 0 ||
                this.props.tax1 > 0 ||
                this.props.tax1Percentage > 0 ||
                this.props.tax2Percentage > 0 ||
                this.props.discount > 0 ||
                this.props.discountPercentage > 0 ||
                this.props.itemCount > 0 ||
                this.props.unit > 0)
        ) {
            this.setState({ isOldCodeMode: true });
        }
        if (this.state.isOldCodeMode) {
            if (this.props.itemType !== prevProps.itemType) {
                this.props.dispatch(
                    change('AddEditServiceEntryForm', `serviceLineItems[${this.props.index}].itemId`, null)
                );
            }
            if (
                this.props.hasLaborCost !== prevProps.hasLaborCost ||
                this.props.hasPartsCost !== prevProps.hasPartsCost ||
                this.props.tax1 !== prevProps.tax1 ||
                this.props.tax1Percentage !== prevProps.tax1Percentage ||
                this.props.taxSelect !== prevProps.taxSelect ||
                this.props.tax2 !== prevProps.tax2 ||
                this.props.tax2Percentage !== prevProps.tax2Percentage ||
                this.props.tax2Select !== prevProps.tax2Select ||
                this.props.discountSelect !== prevProps.discountSelect ||
                this.props.discount !== prevProps.discount ||
                this.props.discountPercentage !== prevProps.discountPercentage
            ) {
                let subTotal = this.props.hasPartsCost ? toNumber(this.props.hasPartsCost) : 0;
                let laborCost = this.props.hasLaborCost ? toNumber(this.props.hasLaborCost) : 0;
                let total1 = 0,
                    total2 = 0;

                // discount
                if (this.props.discountSelect && this.props.discountSelect !== '%') {
                    if (this.props.discount) {
                        subTotal -= toNumber(this.props.discount);
                    }
                } else if (!this.props.discountSelect || this.props.discountSelect === '%') {
                    if (this.props.discountPercentage) {
                        subTotal -= toNumber(this.props.discountPercentage) * 0.01 * subTotal;
                    }
                }

                // taxation
                if (this.props.taxSelect && this.props.taxSelect !== '%') {
                    if (this.props.tax1) {
                        total1 = toNumber(this.props.tax1);
                    }
                } else if (!this.props.taxSelect || this.props.taxSelect === '%') {
                    if (this.props.tax1Percentage) {
                        total1 = toNumber(this.props.tax1Percentage) * 0.01 * subTotal;
                    }
                }

                if (this.props.tax2Select && this.props.tax2Select !== '%') {
                    if (this.props.tax2) {
                        total2 = toNumber(this.props.tax2);
                    }
                } else if (!this.props.tax2Select || this.props.tax2Select === '%') {
                    if (this.props.tax2Percentage) {
                        total2 = toNumber(this.props.tax2Percentage) * 0.01 * subTotal;
                    }
                }

                subTotal += total1;
                subTotal += total2;
                this.props.dispatch(
                    change(
                        'AddEditServiceEntryForm',
                        `serviceLineItems[${this.props.index}].subTotal`,
                        subTotal + laborCost
                    )
                );
            }
        } else {
            if (this.props.itemType !== prevProps.itemType) {
                this.props.dispatch(
                    change('AddEditServiceEntryForm', `serviceLineItems[${this.props.index}].itemId`, null)
                );
            }

            if (
                this.props.hasLaborCost !== prevProps.hasLaborCost ||
                this.props.serviceParts !== prevProps.serviceParts
            ) {
                let subTotal = this.props.serviceParts?.length
                    ? this.props.serviceParts.reduce((sum, item) => {
                          let itemCost = Number(item.cost || 0);
                          //formula = (cost - discount) + tax1 + tax2
                          let itemCostAfterDiscount = itemCost - toNumber(item.discountPercent || 0) * 0.01 * itemCost;

                          let itemCostAfterTax =
                              itemCostAfterDiscount +
                              toNumber((item.tax1Type ? item.tax1Percent : 0) || 0) * 0.01 * itemCostAfterDiscount +
                              toNumber((item.tax2Type ? item.tax2Percent : 0) || 0) * 0.01 * itemCostAfterDiscount;

                          return sum + itemCostAfterTax;
                      }, 0)
                    : 0;
                subTotal = round(subTotal, 2);
                let laborCost = this.props.hasLaborCost ? toNumber(this.props.hasLaborCost) : 0;

                this.props.dispatch(
                    change(
                        'AddEditServiceEntryForm',
                        `serviceLineItems[${this.props.index}].subTotal`,
                        round(subTotal + laborCost, 2)
                    )
                );
            }
        }
    }

    shouldShowTax2Type(tax1Type) {
        return includes(['SGST', 'CGST'], tax1Type);
    }

    renderNewTextField(field) {
        const { type, min, step, input, disabled, className } = field;
        return (
            <div style={{ width: '100%' }}>
                <input
                    type={type}
                    className={`form-control ${className}`}
                    disabled={disabled}
                    {...(step ? { step: step } : {})}
                    {...input}
                    {...(min ? { min: min } : {})}
                />
            </div>
        );
    }

    renderTextArea(field) {
        const { input, rows } = field;

        return (
            <div>
                <textarea placeholder="Notes Here" style={{ width: '100%' }} name="notes" {...input} rows={rows} />
            </div>
        );
    }

    isServiceAddModalEnable = () => {
        this.setState({
            serviceAddModal: true,
        });
    };

    cancelModal = () => {
        this.setState({
            serviceAddModal: false,
        });
    };

    resetCount = (partIndex) => {
        this.props.dispatch(
            change(
                'AddEditServiceEntryForm',
                `serviceLineItems[${this.props.index}].serviceParts[${partIndex}].count`,
                ''
            )
        );
    };

    renderServiceParts = ({ partOptions, fields, meta: { error, submitFailed } }) => (
        <div
            style={{
                backgroundColor: '##BFBCBC',
                width: '100%',
            }}
        >
            <div className="col-md-12" style={{ padding: 0 }}>
                {fields.map((servicePart, index) => (
                    <ServicePart
                        partItem={servicePart}
                        fields={fields}
                        index={index}
                        key={index}
                        resetCount={this.resetCount}
                        partOptions={partOptions || []}
                        selector={this.props.selector}
                        loggedInUser={this.props.loggedInUser}
                        change={this.props.change}
                    />
                ))}
            </div>
            <div className="col-md-12 text-center">
                <button
                    type="button"
                    style={{
                        border: '1px solid #BFBCBC',
                        marginTop: 10,
                        fontSize: 12,
                        marginBottom: 10,
                        background: '#fefefe',
                        outline: 'none',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                    onClick={() => fields.push({ cost: null })}
                >
                    Add more parts
                </button>
                <div className="col-md-12 text-center">{submitFailed && error && <span>{error}</span>}</div>
            </div>
        </div>
    );

    calculateTax() {
        let partsCost = this.props.hasPartsCost ? toNumber(this.props.hasPartsCost) : 0;
        let tax1;
        let tax2;
        if (this.props.taxSelect && this.props.taxSelect !== '%') {
            tax1 = this.props.tax1;
            tax2 = this.props.tax2;
        } else if (!this.props.taxSelect || this.props.taxSelect === '%') {
            tax1 = this.props.tax1Percentage;
            tax2 = this.props.tax2Percentage;
        }

        return (
            partsCost *
                0.01 *
                (toNumber(tax1) + (tax2 && this.shouldShowTax2Type(this.props.tax1Type) ? toNumber(tax2) : 0)) || 0
        );
    }

    render() {
        const { lineItem, index, fields, itemType, taxSelect, tax2Select, discountSelect } = this.props;
        return (
            <li key={index} className="mb-1 py-2" style={{ backgroundImage: 'linear-gradient(#efefef, #eee)' }}>
                <div className="p-2">
                    <div className="mb-2">
                        <label className="mr-3">
                            <Field
                                name={`${lineItem}.itemType`}
                                component="input"
                                type="radio"
                                value={SERVICE_ENTRY_TYPES.SERVICE_TASK}
                                className="mr-1"
                            />
                            Service Task
                        </label>
                        <label>
                            <Field
                                name={`${lineItem}.itemType`}
                                component="input"
                                type="radio"
                                value={SERVICE_ENTRY_TYPES.ISSUE}
                                className="mr-1"
                            />
                            Issue
                        </label>
                    </div>
                    <div className="row">
                        {itemType === SERVICE_ENTRY_TYPES.SERVICE_TASK && (
                            <div className="col-md-3">
                                <div className="text-center">
                                    Service Task<abbr className="text-danger">*</abbr>
                                </div>
                                <div>
                                    <Field
                                        name={`${lineItem}.itemId`}
                                        placeholder="Select Service Task"
                                        options={mappedServiceTasks(this.props.serviceTasksList)}
                                        component={SelectInput}
                                    />
                                    {isAgConstructionAccount(this.props.loggedInUser) && (
                                        <Button
                                            color="link"
                                            className="pl-0"
                                            onClick={() => this.isServiceAddModalEnable()}
                                        >
                                            Add New Service task
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        {itemType === SERVICE_ENTRY_TYPES.ISSUE && (
                            <div className="col-md-3">
                                <div className="text-center">
                                    Issue<abbr className="text-danger">*</abbr>
                                </div>
                                <div>
                                    <Field
                                        name={`${lineItem}.itemId`}
                                        placeholder="Select Issue"
                                        options={mappedIssuesOptions(this.props.issuesList)}
                                        component={SelectInput}
                                        optionRenderer={issueOptionRender}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="col-md-3">
                            <div className="text-center">Work Type</div>
                            <div>
                                <Field
                                    name={`${lineItem}.workType`}
                                    options={SERVICE_WORK_TYPES}
                                    component={SelectInput}
                                />
                            </div>
                        </div>
                        <div className="col-md-6" style={{ display: 'flex' }}>
                            <div style={{ width: '100%' }}>
                                <div className="text-center">Description</div>
                                <div>
                                    <Field
                                        name={`${lineItem}.description`}
                                        component={this.renderNewTextField}
                                        type="text"
                                        step="any"
                                        placeholder="Type here"
                                    />
                                </div>
                            </div>
                            <button
                                type="button"
                                title="Remove Line Items"
                                style={{ marginBottom: 40, marginTop: -8 }}
                                onClick={() => {
                                    fields.remove(index);
                                }}
                            >
                                <i className="fa fa-remove" aria-hidden="true" />
                            </button>
                        </div>

                        {this.state.isOldCodeMode && (
                            <>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        Parts({getCurrencyFromCountry(this.props.loggedInUser)})
                                    </div>
                                    <div>
                                        <Field
                                            name={`${lineItem}.partsCost`}
                                            component={this.renderNewTextField}
                                            min="0"
                                            type="number"
                                            step="any"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3" style={{ paddingRight: 0 }}>
                                    <div className="text-center">Parts Tax Type</div>
                                    <div>
                                        <Field
                                            name={`${lineItem}.tax1Type`}
                                            placeholder="Select Tax Type"
                                            options={TAX_TYPES_V2}
                                            component={SelectInput}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">Tax Rate</div>
                                    <div className="d-flex">
                                        <div className="input-group-prepend">
                                            <Field
                                                style={{ height: '35px' }}
                                                name={`${lineItem}.taxSelect`}
                                                component="select"
                                            >
                                                <option value="%">%</option>
                                                <option value={getCurrencyFromCountry(this.props.loggedInUser)}>
                                                    {getCurrencyFromCountry(this.props.loggedInUser)}
                                                </option>
                                            </Field>
                                        </div>
                                        {taxSelect && taxSelect !== '%' && (
                                            <Field
                                                name={`${lineItem}.tax1`}
                                                component={this.renderNewTextField}
                                                min="0"
                                                type="number"
                                                step="any"
                                            />
                                        )}
                                        {(!taxSelect || taxSelect === '%') && (
                                            <Field
                                                name={`${lineItem}.tax1Percentage`}
                                                component={this.renderNewTextField}
                                                min="0"
                                                type="number"
                                                step="any"
                                            />
                                        )}
                                    </div>
                                </div>
                                {this.shouldShowTax2Type(this.props.tax1Type) ? (
                                    <>
                                        <div className="col-md-3" style={{ paddingRight: 0 }}>
                                            <div className="text-center">Parts Tax Type 2</div>
                                            <div>
                                                <Field
                                                    name={`${lineItem}.tax2Type`}
                                                    placeholder="Select Tax Type"
                                                    options={TAX_TYPES_V2}
                                                    component={SelectInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="text-center">Tax Rate</div>
                                            <div className="d-flex">
                                                <div className="input-group-prepend">
                                                    <Field
                                                        style={{ height: '35px' }}
                                                        name={`${lineItem}.tax2Select`}
                                                        component="select"
                                                    >
                                                        <option value="%">%</option>
                                                        <option value={getCurrencyFromCountry(this.props.loggedInUser)}>
                                                            {getCurrencyFromCountry(this.props.loggedInUser)}
                                                        </option>
                                                    </Field>
                                                </div>

                                                {tax2Select && tax2Select !== '%' && (
                                                    <Field
                                                        name={`${lineItem}.tax2`}
                                                        component={this.renderNewTextField}
                                                        min="0"
                                                        type="number"
                                                        step="any"
                                                    />
                                                )}
                                                {(!tax2Select || tax2Select === '%') && (
                                                    <Field
                                                        name={`${lineItem}.tax2Percentage`}
                                                        component={this.renderNewTextField}
                                                        min="0"
                                                        type="number"
                                                        step="any"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                <div className="col-md-3">
                                    <div>Discount:</div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <Field
                                                style={{ height: '35px' }}
                                                name={`${lineItem}.discountSelect`}
                                                component="select"
                                            >
                                                <option value="%">%</option>
                                                <option value={`${getCurrencyFromCountry(this.props.loggedInUser)}`}>
                                                    {getCurrencyFromCountry(this.props.loggedInUser)}
                                                </option>
                                            </Field>
                                        </div>
                                        {discountSelect && discountSelect !== '%' && (
                                            <Field
                                                name={`${lineItem}.discount`}
                                                component={this.renderNewTextField}
                                                min="0"
                                                type="number"
                                                step="any"
                                                className={'w-100'}
                                            />
                                        )}
                                        {(!discountSelect || discountSelect === '%') && (
                                            <Field
                                                name={`${lineItem}.discountPercentage`}
                                                component={this.renderNewTextField}
                                                min="0"
                                                max="100"
                                                type="number"
                                                step="any"
                                                className={'w-100'}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div>Total Tax</div>
                                    <div className="my-1 ml-2">{this.calculateTax()}</div>
                                </div>
                                <div className="col-md-2">
                                    <div className="text-center">Item Count</div>
                                    <div>
                                        <Field
                                            name={`${lineItem}.itemCount`}
                                            component={this.renderNewTextField}
                                            min="0"
                                            type="number"
                                            step="any"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">Item Unit</div>
                                    <Field
                                        name={`${lineItem}.unit`}
                                        placeholder="Select Item Unit"
                                        options={mappedServiceLineItemUnits()}
                                        component={SelectInput}
                                    />
                                </div>
                            </>
                        )}
                        {!this.state.isOldCodeMode && (
                            <FieldArray
                                className="col-md-12"
                                name={`${lineItem}.serviceParts`}
                                component={this.renderServiceParts}
                                props={{ partOptions: this.props.partOptions }}
                            />
                        )}

                        <div className="col-md-3">
                            <div className="text-center">Labor Cost</div>
                            <div>
                                <Field
                                    name={`${lineItem}.laborCost`}
                                    component={this.renderNewTextField}
                                    min="0"
                                    type="number"
                                    step="any"
                                    parse={(val) => round(val, 2)}
                                />
                            </div>
                        </div>

                        <div className={`col-md-2 ${this.state.isOldCodeMode ? '' : 'offset-md-7'}`}>
                            <div className="text-center">Sub Total</div>
                            <div>
                                <Field
                                    name={`${lineItem}.subTotal`}
                                    component={this.renderNewTextField}
                                    disabled={true}
                                    min="0"
                                    type="number"
                                    step="any"
                                />
                            </div>
                        </div>
                        <div>
                            <Modal
                                isOpen={this.state.serviceAddModal}
                                toggle={this.cancelModal}
                                style={{ minWidth: '55vw' }}
                            >
                                <ModalHeader toggle={this.cancelModal}>Add New Service task</ModalHeader>
                                <ModalBody>
                                    <AddEditServiceTaskComponent cancelModal={this.cancelModal} isServiceLine={true} />
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}
const mapStateToProps = (state, props) => {
    return {
        hasLaborCost: props.selector(state, `${props.lineItem}.laborCost`),
        itemType: props.selector(state, `${props.lineItem}.itemType`),
        issuesList: state.issues.issuesList,
        serviceParts: props.selector(state, `${props.lineItem}.serviceParts`),

        /*  old code start */
        hasPartsCost: props.selector(state, `${props.lineItem}.partsCost`),
        tax1: props.selector(state, `${props.lineItem}.tax1`),
        tax1Type: props.selector(state, `${props.lineItem}.tax1Type`),
        tax1Percentage: props.selector(state, `${props.lineItem}.tax1Percentage`),
        tax2: props.selector(state, `${props.lineItem}.tax2`),
        itemCount: props.selector(state, `${props.lineItem}.itemCount`),
        unit: props.selector(state, `${props.lineItem}.unit`),
        tax2Percentage: props.selector(state, `${props.lineItem}.tax2Percentage`),
        taxSelect: props.selector(state, `${props.lineItem}.taxSelect`),
        tax2Select: props.selector(state, `${props.lineItem}.tax2Select`),
        discountSelect: props.selector(state, `${props.lineItem}.discountSelect`),
        discount: props.selector(state, `${props.lineItem}.discount`),
        discountPercentage: props.selector(state, `${props.lineItem}.discountPercentage`),
        /*  old code ends */
    };
};

export default connect(mapStateToProps)(ServiceLineItem);
