import { handle } from 'redux-pack';

import {
    FETCH_NOTIFICATION_SUMMARY,
    FETCH_NOTIFICATION_LIST,
} from '../../actions/dashboard/notification_module/notificationAction';

const initialState = {
    isLoading: false,
    data: [],
    error: null,
    notificationSummaryError: null,
    notificationSummaryLoading: false,
    notificationListLoading: false,
    notificationListError: null,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_NOTIFICATION_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    notificationSummaryError: null,
                    notificationSummaryLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, notificationSummaryLoading: false }),
                failure: (prevState) => ({ ...prevState, notificationSummaryError: payload }),
                success: (prevState) => ({
                    ...prevState,
                    notificationSummary: payload.data.data,
                }),
            });
        case FETCH_NOTIFICATION_LIST:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    notificationListLoading: true,
                    notificationListError: null,
                }),
                finish: (prevState) => ({ ...prevState, notificationListLoading: false }),
                failure: (prevState) => ({ ...prevState, notificationListError: payload }),
                success: (prevState) => ({
                    ...prevState,
                    notificationList: payload.data,
                }),
            });
        default:
            return state;
    }
};
