import { handle } from 'redux-pack';
import { FETCH_USER_TYPES } from '../../actions/dashboard/user-types/actions';
import { reduce } from 'lodash';

const initialState = {
    isLoading: false,
    error: null,
    userTypesList: [],
    data: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_USER_TYPES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const data = payload.data;
                    const userTypesAsMap = reduce(
                        data,
                        (acc, user) => {
                            acc[user.id] = user;
                            return acc;
                        },
                        {}
                    );
                    return {
                        ...prevState,
                        userTypesList: data,
                        userTypesAsMap,
                    };
                },
            });
        default:
            return state;
    }
}
