import { handle } from 'redux-pack';
import { CREATE_INTERNAL_COMMENT, UPDATE_INTERNAL_COMMENT, DELETE_INTERNAL_COMMENT, SEARCH_INTERNAL_COMMENTS, FETCH_INTERNAL_COMMENT_BY_ID, } from '../../../actions/dashboard/internal_tools/comments_actions';
const initialState = {
    isLoading: false,
    error: null,
    commentsList: [],
    isCommentListLoading: false,
    isCommentLoading: false,
    comment: {},
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_INTERNAL_COMMENT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_INTERNAL_COMMENT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_INTERNAL_COMMENT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case SEARCH_INTERNAL_COMMENTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isCommentListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isCommentListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    commentsList: payload.data.content,
                }),
            });
        case FETCH_INTERNAL_COMMENT_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isCommentLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isCommentLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, comment: payload.data }),
            });
        default:
            return state;
    }
}
