import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import * as Yup from 'yup';
import { getSanitizedHtml } from './dom_utils';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const TOOLBAR_CONFIG = {
    options: [
        'inline',
        'blockType',
        'fontSize',
        'fontFamily',
        'list',
        'textAlign',
        'colorPicker',
        'link',
        // 'embedded',
        // 'emoji',
        'history',
    ],
    fontFamily: {
        options: [
            'Arial',
            'Georgia',
            'Impact',
            'Tahoma',
            'Times New Roman',
            'Verdana',
            'Inter',
            'Montserrat',
            'Roboto',
            'HelveticaNeue',
            '-apple-system',
            'BlinkMacSystemFont',
        ],
    },
};

/***
 *
 * @param rawStateString : String
 * @param html : String
 * @returns {Draft.Model.ImmutableData.EditorState}
 */
export function getInitialEditorState(html = '', rawStateString = '') {
    if (html) {
        return htmlToEditorState(html);
    }
    if (rawStateString) {
        return rawStateStringToEditorState(rawStateString);
    }
    return EditorState.createEmpty();
}

/**
 *
 * @param contentState : Draft.Model.ImmutableData.ContentState
 * @returns {Draft.Model.ImmutableData.EditorState}
 */
export function contentStateToEditorState(contentState) {
    return EditorState.createWithContent(contentState);
}

/***
 *
 * @param editorState : EditorState
 * @returns {Draft.Model.ImmutableData.ContentState | ContentState | *}
 */
export function editorStateToContentState(editorState) {
    return editorState ? editorState.getCurrentContent() : null;
}

/**
 *
 * @param editorState : EditorState
 * @returns {string}
 */
export function editorStateToHtml(editorState) {
    return getSanitizedHtml(draftToHtml(editorStateToRawState(editorState)));
}

/***
 *
 * @param html : String
 * @returns {Draft.Model.ImmutableData.EditorState}
 */
export function htmlToEditorState(html) {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return contentStateToEditorState(contentState);
}

/***
 *
 * @param rawState : RawDraftContentState
 * @returns {Draft.Model.ImmutableData.ContentState}
 */
export function rawStateToContentState(rawState) {
    return convertFromRaw(rawState);
}

/***
 *
 * @param contentState : ContentState
 * @returns {Draft.Model.Encoding.RawDraftContentState}
 */
export function contentStateToRawState(contentState) {
    return convertToRaw(contentState);
}

/***
 *
 * @param editorState : EditorState
 * @returns {Draft.Model.Encoding.RawDraftContentState}
 */
export function editorStateToRawState(editorState) {
    return convertToRaw(editorStateToContentState(editorState));
}

/***
 *
 * @param rawState : Draft.Model.Encoding.RawDraftContentState
 * @returns {Draft.Model.ImmutableData.EditorState}
 */
export function rawStateToEditorState(rawState) {
    return contentStateToEditorState(rawStateToContentState(rawState));
}

/***
 *
 * @param editorState : EditorState
 * @returns {string}
 */
export function editorStateToRawStateString(editorState) {
    return JSON.stringify(editorStateToRawState(editorState));
}

export function rawStateStringToEditorState(rawStateString) {
    return rawStateToEditorState(rawStateStringToRawState(rawStateString));
}

/***
 *
 * @param rawStateString : String
 * @returns {Draft.Model.Encoding.RawDraftContentState}
 */
export function rawStateStringToRawState(rawStateString) {
    return JSON.parse(rawStateString);
}

export function getYupRequiredValidatorForEditorState(message = 'Field Required') {
    return Yup.object()
        .test('has text', message, (value) => {
            return editorStateToContentState(value).hasText();
        })
        .required(message);
}
