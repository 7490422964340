import { handle } from 'redux-pack';
import {
    FETCH_CUSTOMER_SUMMARY,
    FETCH_CUSTOMERS,
    CREATE_CUSTOMER,
} from '../../../actions/dashboard/finances/customer_action';
import { get, map } from 'lodash';

const initialState = {
    isLoading: false,
    error: null,
    customerSummary: null,
    customers: [],
    isFetchCustomersLoading: false,
    isFetchCustomerSummaryLoading: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_CUSTOMER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    customers: [...state.customers, payload.data],
                }),
            });
        case FETCH_CUSTOMERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isFetchCustomersLoading: true,
                    error: null,
                    customers: [],
                }),
                finish: (prevState) => ({ ...prevState, isFetchCustomersLoading: false, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    customers: get(payload, 'data.content', []),
                    customersAsMap: new Map(
                        map(get(payload, 'data.content', []), (customer) => [customer.id, customer])
                    ),
                }),
            });
        case FETCH_CUSTOMER_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isFetchCustomerSummaryLoading: true,
                    error: null,
                    customerSummary: null,
                }),
                finish: (prevState) => ({ ...prevState, isFetchCustomerSummaryLoading: false, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    customerSummary: payload.data,
                }),
            });
        default:
            return state;
    }
}
