import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { map } from 'lodash';
const _FxStandalonePaginationControlComponent = (props) => {
    const totalDataSize = props.totalDataSize || 0;
    const currentPage = props.currentPage || 1;
    const sizePerPage = props.sizePerPage || 10;
    const totalPages = Math.ceil(totalDataSize / sizePerPage);
    const numberToDisplay = [];
    let startFrom = currentPage >= 3 ? currentPage - 2 : currentPage >= 2 ? currentPage - 1 : currentPage;
    for (; numberToDisplay.length < 5 && startFrom <= totalPages; startFrom++) {
        //total five number max any time to show
        numberToDisplay.push(startFrom);
    }
    return (_jsxs(Pagination, { "aria-label": "standalone pagination", ...props.paginationProps, children: [currentPage !== 1 && (_jsx(PaginationItem, { ...props.paginationItemProps, children: _jsx(PaginationLink, { first: true, onClick: () => {
                        props.onPageChange(1, sizePerPage);
                    }, ...props.paginationLinkProps }) })), currentPage !== 1 && (_jsx(PaginationItem, { ...props.paginationItemProps, children: _jsx(PaginationLink, { previous: true, onClick: () => {
                        props.onPageChange(currentPage - 1, sizePerPage);
                    }, ...props.paginationLinkProps }) })), map(numberToDisplay, (value, index) => {
                return (_jsx(PaginationItem, { active: currentPage === value, ...props.paginationItemProps, children: _jsx(PaginationLink, { onClick: () => {
                            props.onPageChange(value, sizePerPage);
                        }, ...props.paginationLinkProps, children: value }) }, index));
            }), currentPage !== totalPages && (_jsx(PaginationItem, { ...props.paginationItemProps, children: _jsx(PaginationLink, { next: true, onClick: () => {
                        props.onPageChange(currentPage + 1, sizePerPage);
                    }, ...props.paginationLinkProps }) })), currentPage !== totalPages && (_jsx(PaginationItem, { ...props.paginationItemProps, children: _jsx(PaginationLink, { last: true, onClick: () => {
                        props.onPageChange(totalPages, sizePerPage);
                    }, ...props.paginationLinkProps }) }))] }));
};
export default _FxStandalonePaginationControlComponent;
