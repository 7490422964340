import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FxCloseOutlined } from 'sharedV2/FxIcons';
import { FxButton, FxStatistic } from 'sharedV2/index';
import styled from 'styled-components';
const Hover = styled.div `
    position: relative;
    border-radius: 3px;
    min-width: ${(props) => (props.minWidth ? props.minWidth.sm : 0)}px;
    padding: 0.3rem;
    transition: 0.4s;
    cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
    background: ${(props) => (props.active ? 'rgba(0, 0, 0, 0.1)' : 'none')};

    &:hover {
        background: ${(props) => (props.hover ? 'rgba(0, 0, 0, 0.15)' : 'none')};
    }
    @media (min-width: 576px) {
        min-width: ${(props) => (props.minWidth ? props.minWidth.md : 0)}px;
    }
    @media (min-width: 768px) {
        min-width: ${(props) => (props.minWidth ? props.minWidth.lg : 0)}px;
    }
    @media (min-width: 992px) {
        min-width: ${(props) => (props.minWidth ? props.minWidth.xl : 0)}px;
    }
`;
const RemoveFilter = styled(FxButton) `
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
`;
const FxSummary = (props) => {
    const { hover = true, active, onClick, minWidth } = props;
    return (_jsxs(Hover, { active: active, hover: hover, onClick: onClick, minWidth: minWidth, children: [_jsx(FxStatistic, { ...props, title: props.title, value: props.value }), active && (_jsx(RemoveFilter, { size: "small", type: "text", icon: _jsx(FxCloseOutlined, {}), onClick: (e) => {
                    e.stopPropagation();
                    if (onClick)
                        onClick();
                } }))] }));
};
export default FxSummary;
