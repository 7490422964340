import {handle} from "redux-pack";
import {
    CREATE_SERVICE_ENTRY, DELETE_SERVICE_ENTRY, FETCH_SERVICE_ENTRY, FETCH_SERVICE_ENTRIES, UPDATE_SERVICE_ENTRY,FETCH_SERVICE_ENTRIES_IN_BETWEEN
} from "../../../actions/dashboard/easy/service_entry_action";
import {FETCH_FUEL_ENTRIES_IN_BETWEEN} from "../../../actions/dashboard/easy/fuel_entry_action";


const initialState = {
    isLoading: false, error: null, data: {}, serviceEntryList: [], isTimeOnSiteLoading: false
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case CREATE_SERVICE_ENTRY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case UPDATE_SERVICE_ENTRY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });

        case DELETE_SERVICE_ENTRY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });

        case FETCH_SERVICE_ENTRIES :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, serviceEntryList: payload.data.content
                }),
            });

        case FETCH_SERVICE_ENTRIES_IN_BETWEEN :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, serviceEntryList: payload.data.content
                }),
            });

        case FETCH_SERVICE_ENTRY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({...prevState, data: {...prevState.data, [payload.data.id]: payload.data}})
            });

        default:
            return state;
    }

}