import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ConfigProvider } from 'antd';
import { FxCloseOutlined } from 'sharedV2/FxIcons';
import { FxButton, FxStatistic } from 'sharedV2/index';
import styled from 'styled-components';
const Hover = styled.div `
    display: flex;
    position: relative;
    border-radius: 3px;
    width: 100%;
    padding: 0.1rem 0.1rem 0.1rem 0.3rem;
    transition: 0.4s;
    cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
    background: ${(props) => (props.active ? 'rgba(0, 0, 0, 0.1)' : 'none')};
    border-left: 4px solid ${(props) => props.color};

    &:hover {
        background: ${(props) => (props.hover ? 'rgba(0, 0, 0, 0.15)' : 'none')};
    }
`;
const RemoveFilter = styled(FxButton) `
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
`;
const FxColorSummary = (props) => {
    const { color, hover = true, active, onClick } = props;
    return (_jsxs(Hover, { active: active, hover: hover, onClick: onClick, color: color, children: [_jsx(ConfigProvider, { theme: {
                    components: {
                        Statistic: {
                            contentFontSize: 14,
                            titleFontSize: 12,
                        },
                    },
                }, children: _jsx(FxStatistic, { ...props, title: props.title, value: props.value }) }), active && (_jsx(RemoveFilter, { size: "small", type: "text", icon: _jsx(FxCloseOutlined, {}), onClick: (e) => {
                    e.stopPropagation();
                    if (onClick)
                        onClick();
                } }))] }));
};
export default FxColorSummary;
