import { handle } from 'redux-pack';
import { CREATE_INDENT, UPDATE_INDENT, FETCH_INDENT, FETCH_INDENTS, FETCH_INDENT_VEHICLES, FETCH_INDENT_USERS, } from '../../actions/dashboard/vendor_management/indents_action';
import { FETCH_AUCTION_BY_ID, FETCH_AUCTIONS } from '../../actions/dashboard/vendor_management/auctions_action';
const initialState = {
    isLoading: false,
    error: null,
    indentList: [],
    indentUsers: [],
    indentVehicles: [],
    isIndentListLoading: false,
    isIndentVehiclesLoading: false,
    isIndentUsersLoading: false,
    auctionList: [],
    auction: {},
    isAuctionLoading: false,
    isAuctionListLoading: false,
    totalAuctionListElements: 0,
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_INDENT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_INDENT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_INDENT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_INDENTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isIndentListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isIndentListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    indentList: payload.data,
                }),
            });
        case FETCH_INDENT_VEHICLES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isIndentVehiclesLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isIndentVehiclesLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    indentVehicles: payload.data,
                }),
            });
        case FETCH_INDENT_USERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isIndentUsersLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isIndentUsersLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    indentUsers: payload.data,
                }),
            });
        //auctions
        case FETCH_AUCTION_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAuctionLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isAuctionLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    auction: payload.data,
                }),
            });
        case FETCH_AUCTIONS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isAuctionListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isAuctionListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    auctionList: payload.data.content,
                    totalAuctionListElements: payload.data.totalElements,
                }),
            });
        default:
            return state;
    }
}
