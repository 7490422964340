import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
const P = styled.p `
    font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
    text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
    color: ${(props) => (props.color ? props.color : '')};
    cursor: ${(props) => (props.cursor ? props.cursor : '')};
`;
const Icon = styled.i `
    color: ${(props) => (props.color ? props.color : '')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
`;
class TemplateStatusModal extends Component {
    mounted = false;
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() { }
    componentDidUpdate(prevProps, prevState) { }
    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }
    render() {
        return (_jsxs(Modal, { isOpen: this.props.isOpen, toggle: () => this.props.toggle(), children: [_jsx(ModalHeader, { toggle: () => this.props.toggle() }), _jsx(ModalBody, { children: _jsxs("div", { className: "d-flex flex-column justify-content-center align-items-center", children: [_jsx(Icon, { className: "fa fa-check-circle fa-3x", color: "#4dbd74" }), _jsx(P, { fontSize: "1.125rem", color: "#1e3254", className: "mt-4 mb-0", children: this.props.text })] }) })] }));
    }
    componentWillUnmount() { }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplateStatusModal);
