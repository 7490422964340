import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputRange from 'react-input-range';
export default (passedProps) => {
    const { form, field, max, min, step, disabled } = passedProps;
    const { t } = useTranslation();
    const onChange = (value, rangeId) => {
        form.setFieldValue(field.name, value);
    };
    return (_jsx(_Fragment, { children: _jsx(InputRange, { step: step, maxValue: max, minValue: min, value: get(form.values, field.name, 0), onChange: (value) => onChange(value, passedProps.rangeId), disabled: disabled }) }));
};
