import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from 'antd';
import { get } from 'lodash';
import FxSelect from './FxSelect';
import { DEFAULT_SIZE } from '../../src/theme/utils';
import FxToolTip from './FxTooltip';
const FxFormikSelect = ({ field, form, isRequired, ...props }) => {
    const { name } = field;
    const { errors, touched } = form;
    const errorMsg = get(errors, name);
    const isTouched = get(touched, name, false);
    const error = isTouched && errorMsg;
    const getLabel = () => {
        return (_jsxs("div", { className: "d-flex", children: [_jsx("div", { children: props.label }), props.info && (_jsx(FxToolTip, { placement: "right", title: props.info, children: _jsx("i", { className: "fa fa-info-circle cursor-pointer ml-2 mt-1" }) }))] }));
    };
    return (_jsx(Form.Item, { label: props.info ? getLabel() : props.label, required: isRequired, validateStatus: touched && error ? 'error' : '', help: (isTouched && error) || props.help, labelCol: { span: 24 }, children: _jsx(FxSelect, { allowClear: props.clearable, size: DEFAULT_SIZE, style: { width: '100%' }, error: error, "data-testid": field.name, helperText: error ? errorMsg : undefined, ...field, ...props, onChange: (value, option) => {
                form.setFieldValue(name, value);
                if (props.onChange) {
                    props.onChange(value, option);
                }
            }, onBlur: () => {
                form.setFieldTouched(field.name);
            }, innerRef: props.innerRef }) }));
};
export default FxFormikSelect;
