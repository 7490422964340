import { handle } from 'redux-pack';
import { FETCH_ROLES } from '../../actions/dashboard/roles_action';

const initialState = {
    isRolesListLoading: false,
    error: null,
    isFinished: false,
    rolesList: [],
};

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ROLES:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isRolesListLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isRolesListLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    rolesList: payload.data,
                    isRolesListLoading: false,
                }),
            });
        default:
            return state;
    }
}
