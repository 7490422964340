import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FxDivider } from 'sharedV2/index';
import styled from 'styled-components';
const Wrapper = styled.div `
    margin-top: { (props) => props.mb || '0' };
    display: flex;
    align-items: center;
    column-gap: 1rem;
    .ant-divider-horizontal {
        min-width: unset;
    }
`;
const FxCustomDivider = (props) => {
    const { left, right, mb, ...rest } = props;
    return (_jsxs(Wrapper, { mb: mb, children: [left, _jsx(FxDivider, { ...rest }), right] }));
};
export default FxCustomDivider;
