import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const FETCH_LEDGERS_GROUPS = 'FETCH_LEDGERS_GROUPS';
export const CREATE_LEDGER_GROUP = 'CREATE_LEDGER_GROUP';
export const FETCH_LEDGER_GROUP = 'FETCH_LEDGER_GROUP';
export const UPDATE_LEDGER_GROUP = 'UPDATE_LEDGER_GROUP';
export const DELETE_LEDGER_GROUP = 'DELETE_LEDGER_GROUP';
export function fetchLedgersGroups(accesstoken, params = {}) {
    const { baseGroupIds, code, groupName, primaryGroup, accountGroupId, accountGroupName } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            size: 3000,
            baseGroupIds,
            code,
            groupName,
            primaryGroup,
            accountGroupId,
            accountGroupName,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}ledger-group`, config);
    return {
        type: FETCH_LEDGERS_GROUPS,
        promise: request,
    };
}
export function createLedgerGroup(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}ledger-group`, data, config);
    return {
        type: CREATE_LEDGER_GROUP,
        promise: request,
    };
}
export function fetchLedgerGroup(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}ledger-group/${id}`, config);
    return {
        type: FETCH_LEDGER_GROUP,
        promise: request,
    };
}
export function deleteLedgerGroup(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}ledger-group/${id}`, config);
    return {
        type: DELETE_LEDGER_GROUP,
        promise: request,
    };
}
export function updateLedgerGroup(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}ledger-group`, data, config);
    return {
        type: UPDATE_LEDGER_GROUP,
        promise: request,
    };
}
