import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { FxSkeleton } from './index';
import { map } from 'lodash';
import styled from 'styled-components';
const LoaderContainer = styled.div `
    .app {
        min-height: 70vh;
    }
`;
const FxSkeletonTable = (props) => {
    const [tableData, setTableData] = useState([...Array(props.rowSize || 2).keys()].map((i) => ({ id: i + 1 })));
    const [tableHeader, setTableHeader] = useState([...Array(props.HeaderSize || 7).keys()].map((i) => ({ id: i + 1 })));
    const showFxSkeletonAsDataField = () => {
        return (_jsx("div", { className: "py-3", children: _jsx(FxSkeleton, { width: "100%" }) }));
    };
    return (_jsx("div", { className: "fleetx-table-parent-container", children: _jsx(BootstrapTable, { tableContainerClass: "card fleetx-table-container white-table-background pos-static", tableHeaderClass: "fleetx-table grey-table-background font-size-mid text-center", tableBodyClass: "fleetx-table white-table-background", trClassName: "tr-user-height table-row-style", version: "4", remote: true, options: { noDataText: 's' }, children: map(tableHeader, (item, index) => (_jsx(TableHeaderColumn, { isKey: index === 0 ? true : false, width: "150", dataField: "id", dataFormat: showFxSkeletonAsDataField, children: _jsx(FxSkeleton, { width: "100%" }) }))) }) }));
};
export default FxSkeletonTable;
