import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

class SuccessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });

        this.props.onModalToggle();
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalBody>{this.props.message}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle}>
                            Close
                        </Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

SuccessModal.propTypes = {
    message: PropTypes.string.isRequired,
    onModalToggle: PropTypes.func.isRequired,
};

export default SuccessModal;
