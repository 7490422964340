import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { find, get, map } from 'lodash';

class ChannelSalesPaging extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupModalOpen: false,
            groupsData: [],
            clientModal: false,
            listOfAccounts: [],
        };
    }
    showName = (name) => {
        return <div>{name}</div>;
    };
    showRegions = (region) => {
        return <div>{region}</div>;
    };
    showOnboarding = (onboarding) => {
        return <div>{onboarding ? onboarding : 0}</div>;
    };

    clientsClicked = (listOfAccounts) => {
        this.setState({
            clientModal: true,
            listOfAccounts: listOfAccounts,
        });
    };

    showClients = (id) => {
        const rowData = find(this.props.data, (v) => v.id === id);
        let clientsCount = get(rowData, 'noOfClients', 0);
        let listOfAccounts = get(rowData, 'listOfAccounts', 0);
        return (
            <div
                className=""
                role="presentation"
                onClick={() => (clientsCount > 0 ? this.clientsClicked(listOfAccounts) : () => {})}
            >
                {clientsCount ? <div className="text-primary">{clientsCount}</div> : 0}
            </div>
        );
    };
    showActivation = (activationsCount) => {
        return <div>{activationsCount ? activationsCount : 0}</div>;
    };

    showInstallation = (installation) => {
        return <div>{installation ? installation : 0}</div>;
    };
    groupModalClick = (groupsData) => {
        this.setState({
            groupModalOpen: !this.state.groupModalOpen,
            groupsData,
        });
    };
    clientModalClick = (listOfAccounts) => {
        this.setState({
            clientModal: !this.state.clientModal,
            listOfAccounts: listOfAccounts,
        });
    };
    showGroups = (groups) => {
        const renderGroups = groups.slice(0, 2);
        const groupsLength = get(groups, 'length', 0);
        return (
            <div title={groups.join(',')}>
                {renderGroups.join(',')}
                {groupsLength > 2 ? (
                    <div role="presentation" className="font-weight-bold" onClick={() => this.groupModalClick(groups)}>
                        ...Show More
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };
    showVisits = (visitsCount) => {
        return <div>{visitsCount ? visitsCount : 0}</div>;
    };

    showEmail = (email) => {
        return <div>{email}</div>;
    };

    showActivationStatus = (activationStatus) => {
        return <div>{activationStatus}</div>;
    };

    redirectToAccountDetails = (id) => {
        const url = `/dashboard/settings/users/view/${id}`;
        window.open(url, '_blank');
    };

    showViewDetail = (id) => {
        return (
            <i className="fa fa-external-link" onClick={() => this.redirectToAccountDetails(id)} aria-hidden="true"></i>
        );
    };

    renderList = () => {
        return map(this.state.groupsData, (v) => <li style={{ float: 'left', width: '10em' }}>{v}</li>);
    };

    renderClientList = () => {
        const options = {
            noDataText: 'No Data Found.',
            // onRowClick: isViewportMDOrAbove() ? null : this.onRowClick,
            defaultSortName: 'name',
            // onSortChange: this.props.onSortChange,
        };

        return this.state.clientModal ? (
            <BootstrapTable
                containerClass="white-background"
                tableContainerClass="card fleetx-table-container white-table-background"
                tableHeaderClass="fleetx-table grey-table-background font-size-mid text-center"
                tableBodyClass="fleetx-table white-table-background"
                trClassName="tr-user-height table-row-style"
                // hover
                options={options}
                version="4"
                tableStyle={{ 'width': '100%' }}
                ignoreSinglePage={true}
                data={get(this.state, 'listOfAccounts', [])}
                // expandableRow={this.isExpandableRow}
                // expandComponent={this.expandComponent}
            >
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    width="20"
                    // dataFormat={this.showIndex}
                >
                    Id
                </TableHeaderColumn>
                <TableHeaderColumn dataField="name" width="20" dataFormat={this.showName}>
                    Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField="email" width="50" dataFormat={this.showEmail}>
                    Email
                </TableHeaderColumn>
            </BootstrapTable>
        ) : (
            ''
        );
    };
    render() {
        const options = {
            noDataText: 'No Data Found.',
            // onRowClick: isViewportMDOrAbove() ? null : this.onRowClick,
            defaultSortName: 'name',
            defaultSortOrder: 'asc',
            hideSizePerPage: true,
            sizePerPage: this.props.sizePerPage,
            onPageChange: this.props.onPageChange,
            page: this.props.page,
            alwaysShowAllBtns: true,
            paginationPosition: 'both',
            // onSortChange: this.props.onSortChange,
        };
        return (
            <>
                <BootstrapTable
                    containerClass="white-background"
                    tableContainerClass="card fleetx-table-container white-table-background"
                    tableHeaderClass="fleetx-table grey-table-background font-size-mid text-center"
                    tableBodyClass="fleetx-table white-table-background"
                    trClassName="tr-user-height table-row-style"
                    hover
                    options={options}
                    version="4"
                    remote={true}
                    pagination={true}
                    tableStyle={{ 'width': '100%' }}
                    ignoreSinglePage={true}
                    fetchInfo={{ dataTotalSize: this.props.totalDataSize }}
                    data={this.props.data}
                    // expandableRow={this.isExpandableRow}
                    // expandComponent={this.expandComponent}
                >
                    <TableHeaderColumn
                        hidden={true}
                        isKey
                        dataField="id"
                        export={false}
                        width="140"
                        tdStyle={{ verticalAlign: 'middle' }}
                    />
                    <TableHeaderColumn
                        dataField="id"
                        width="30"
                        // dataFormat={this.showIndex}
                    >
                        Id
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="name" width="40" dataFormat={this.showName}>
                        Name
                    </TableHeaderColumn>
                    {!(this.props.activeTab === 'CSP') && (
                        <TableHeaderColumn dataField="noOfOnBoardings" width="30" dataFormat={this.showOnboarding}>
                            Onboarding
                        </TableHeaderColumn>
                    )}
                    <TableHeaderColumn dataField="id" width="30" dataFormat={this.showClients}>
                        Accounts
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="installation" width="30" dataFormat={this.showInstallation}>
                        Installation
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="purchaseOrderCount" width="50">
                        Purchase Order Count
                    </TableHeaderColumn>
                    {!(this.props.activeTab === 'CSP') && (
                        <TableHeaderColumn dataField="activations" width="30" dataFormat={this.showActivation}>
                            Activations
                        </TableHeaderColumn>
                    )}
                    <TableHeaderColumn dataField="groupNames" width="80" dataFormat={this.showGroups}>
                        Groups
                    </TableHeaderColumn>
                    {this.props.activeTab === 'CSP' && (
                        <TableHeaderColumn dataField="visit" width="40" dataFormat={this.showVisits}>
                            Visits
                        </TableHeaderColumn>
                    )}
                    {this.props.activeTab === 'CSP' && (
                        <TableHeaderColumn
                            dataField="activationStatus"
                            width="40"
                            dataFormat={this.showActivationStatus}
                        >
                            Activations Status
                        </TableHeaderColumn>
                    )}
                    <TableHeaderColumn dataField="id" width="30" dataFormat={this.showViewDetail}>
                        View Details
                    </TableHeaderColumn>
                </BootstrapTable>
                <Modal isOpen={this.state.groupModalOpen} toggle={this.groupModalClick}>
                    <ModalHeader toggle={this.props.toggle}>Groups</ModalHeader>
                    <ModalBody>
                        <div className="group-wrapper">
                            <ol style={{ listStyle: 'none' }}>{this.renderList()}</ol>
                        </div>
                    </ModalBody>
                    <ModalFooter className="d-block">
                        <Button onClick={this.groupModalClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal width={800} isOpen={this.state.clientModal} toggle={this.clientModalClick}>
                    <ModalHeader toggle={this.clientModalClick}>Accounts</ModalHeader>
                    <ModalBody>
                        <div className="accounts-wrapper">{this.renderClientList()}</div>
                    </ModalBody>
                    <ModalFooter className="d-block">
                        <Button onClick={this.clientModalClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

ChannelSalesPaging.propTypes = {};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        loginUserRole: get(state, 'login.data.role', ''),
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChannelSalesPaging));
