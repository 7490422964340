import axios from 'axios';
import { ROOT_API_ERP } from '../../../constant';
export const CREATE_EWAY_BILL_PROFILE = 'CREATE_EWAY_BILL_PROFILE';
export const UPDATE_EWAY_BILL_PROFILE = 'UPDATE_EWAY_BILL_PROFILE';
export const FETCH_EWAY_BILL_PROFILE = 'FETCH_EWAY_BILL_PROFILE';
export function createEwayBillProfile(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}eway-bill/profile`, data, config);
    return {
        type: CREATE_EWAY_BILL_PROFILE,
        promise: request,
    };
}
export function updateEwayBillProfile(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.patch(`${ROOT_API_ERP}eway-bill/profile`, data, config);
    return {
        type: UPDATE_EWAY_BILL_PROFILE,
        promise: request,
    };
}
export function fetchEwayBillProfile(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}eway-bill/profile`, config);
    return {
        type: FETCH_EWAY_BILL_PROFILE,
        promise: request,
    };
}
