import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';
export const CREATE_INTERNAL_COMMENT = 'create_internal_comments';
export const UPDATE_INTERNAL_COMMENT = 'update_internal_COMMENT';
export const DELETE_INTERNAL_COMMENT = 'delete_internal_comment';
export const SEARCH_INTERNAL_COMMENTS = 'search_internal_comments';
export const FETCH_INTERNAL_COMMENT_BY_ID = 'fetch_internal_comment_by_id';
export function createInternalComment(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}internal/comment/`, data, config);
    return {
        type: CREATE_INTERNAL_COMMENT,
        promise: request,
    };
}
export function updateInternalComment(accesstoken, id, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}internal/comment/${id}`, data, config);
    return {
        type: UPDATE_INTERNAL_COMMENT,
        promise: request,
    };
}
export function deleteInternalComment(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}internal/comment/${id}`, config);
    return {
        type: DELETE_INTERNAL_COMMENT,
        promise: request,
    };
}
export function searchInternalComments(accesstoken, params, all = false) {
    const { commentableType, commentableId } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            commentableType,
            commentableId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}internal/comment/search${all ? '/all' : ''}`, config);
    return {
        type: SEARCH_INTERNAL_COMMENTS,
        promise: request,
    };
}
export function fetchInternalCommentById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}internal/comment/${id}`, config);
    return {
        type: FETCH_INTERNAL_COMMENT_BY_ID,
        promise: request,
    };
}
