import { handle } from 'redux-pack';
import { FETCH_LOGIN, FORGOT_PASSWORD, RESET_PASSWORD } from 'actions/login/login_action';
import { SWITCH_BY_EMAIL, SWITCH_EXIT } from 'actions/dashboard/admin_actions';
import { get, set, isEmpty, reduce } from 'lodash';
import { FETCH_LOGGED_IN_USER } from '../../actions/dashboard/setting_action';
import { sendAppAlertToSlack } from '../../utils/analytics_utils';
import { getLoggingDetails } from '..';
import { isShellAccount } from '../../../white_label_constants';
import { getMomentTime } from 'utils/date_utils';

const initialState = {
    isLoading: false,
    error: null,
    data: null,
    resetPasswordToken: null,
};

export default function (state = initialState, action, root = {}) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_LOGIN:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    window.FLEETX_LOGGED_IN_DATA = payload.data;
                    window.FX_MODULE_MAP = reduce(
                        payload.data?.modules || [],
                        (obj, item) => {
                            obj[item] = true;
                            return obj;
                        },
                        {}
                    );
                    window.FX_SCOPE_MAP = reduce(
                        payload.data?.scope || [],
                        (obj, item) => {
                            obj[item] = true;
                            return obj;
                        },
                        {}
                    );
                    if (isShellAccount()) {
                        window.SESSION_EXPIRE_TIME = getMomentTime().add(30, 'm').valueOf();
                    }
                    return { ...prevState, data: payload.data };
                },
            });
        case FORGOT_PASSWORD:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, resetPasswordToken: payload.data.data }),
            });
        case RESET_PASSWORD:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case SWITCH_BY_EMAIL:
            return handle(state, action, {
                start: (prevState) => {
                    const SWITCHED_USER_ORIGINAL_DATA = {
                        switchedUserOriginalRoleId: get(root, 'settings.loggedInUser.role.id', 0),
                        switchedUserOriginalEmailId: get(root, 'settings.loggedInUser.email', null),
                        switchedUserOriginalId: get(root, 'settings.loggedInUser.id', 0),
                        switchedUserOriginalAccessToken: get(prevState, 'data.access_token', null),
                    };
                    return {
                        ...prevState,
                        isLoading: true,
                        error: null,
                        ...SWITCHED_USER_ORIGINAL_DATA,
                    };
                },
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return { ...prevState, data: { ...payload.data, isSwitchedUser: true }, isSwitchedUser: true };
                },
            });
        case SWITCH_EXIT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...payload.data, isSwitchedUser: false },
                    isSwitchedUser: false,
                }),
            });
        case FETCH_LOGGED_IN_USER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const oldData = get(prevState, 'data');
                    const newData = set(oldData, 'scope', get(payload, 'data.scopes', ''));
                    set(newData, 'vendor', get(payload, 'data.vendor'));
                    set(newData, 'tagIdsSet', get(payload, 'data.tagIdsSet', []));
                    if (!get(payload, 'data.scopes') || isEmpty(get(payload, 'data.scopes'))) {
                        sendAppAlertToSlack(
                            'No scopes returned \n```' +
                                'type              : ' +
                                get(action, 'type') +
                                '\n' +
                                'method            : ' +
                                get(action, 'payload.config.method') +
                                '\n' +
                                'url               : ' +
                                get(action, 'payload.config.url') +
                                '\n' +
                                'scope            : ' +
                                get(action, 'payload.data.scopes') +
                                '\n' +
                                'token        : ' +
                                get(state, 'data.access_token') +
                                '\n' +
                                'email        : ' +
                                get(action, 'payload.data.email') +
                                ' ' +
                                get(action, 'payload.data.username') +
                                '\n' +
                                'url          : ' +
                                get(window, 'location.href') +
                                '```',
                            '#alerts-logout'
                        );
                        return {
                            ...prevState,
                            data: oldData,
                        };
                    }
                    return {
                        ...prevState,
                        data: newData,
                    };
                },
            });
        default:
            return state;
    }
}
