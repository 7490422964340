import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../constant';
import {
    isViewGroupsAllowedForUser,
    isViewGroupsSummaryAllowedForUser,
} from '../../components/dashboard/roles/permission_utils';

export const CREATE_GROUP = 'create_group';
export const FETCH_GROUPS = 'fetch_groups';
export const FETCH_GROUP = 'fetch_group';
export const FETCH_ALL_GROUPS = 'fetch_all_groups';
export const UPDATE_GROUP = 'update_group';
export const DELETE_GROUP = 'delete_group';

export function createGroup(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}groups/`, values, config);
    return {
        type: CREATE_GROUP,
        promise: request,
    };
}

export function updateGroup(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}groups/${id}`, values, config);

    return {
        type: UPDATE_GROUP,
        promise: request,
    };
}

export function fetchGroups(accesstoken, accountId = null) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewGroupsSummaryAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_GROUPS,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    //need to add in pagination in the future
    const request = axios.get(`${ROOT_API_URL}groups/?page=0&size=3000`, config);

    return {
        type: FETCH_GROUPS,
        promise: request,
    };
}

export function fetchGroup(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}groups/${id}`, config);

    return {
        type: FETCH_GROUP,
        promise: request,
    };
}

export function fetchAllGroups(accesstoken) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewGroupsAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_GROUPS,
            promise: Promise.resolve({}),
        };
    }
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}groups/all`, config);

    return {
        type: FETCH_ALL_GROUPS,
        promise: request,
    };
}

export function deleteGroup(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}groups/${id}`, config);
    return {
        type: DELETE_GROUP,
        promise: request,
    };
}
