import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
const ErrorImageContainer = styled.div `
    background-image: url(/public/img/error.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    width: 108px;
    margin: auto;
`;
const FxErrorStateContainer = ({ text }) => {
    return (_jsx("div", { className: "app flex-row align-items-center", children: _jsxs("div", { className: "container", children: [_jsx(ErrorImageContainer, {}), _jsx("div", { className: "text-center font-2xl mt-2", children: text || 'Something went wrong!' })] }) }));
};
export default FxErrorStateContainer;
