import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stringifyUrlThroughState } from '../../../../constant';
import { fetchRecentTransactions } from './payment_actions';
import { FxCard, FxFlex } from '../../../../shared';
import { get, orderBy, map } from 'lodash';
import { DEFAULT_JOB_START_DATE_DAYS, getMomentTime } from '../../../../utils/date_utils';
import { TableSkeleton } from '../../skeletons/tableSkeleton';
import CalendarComponent from '../../../utils/calendar_component';
import styled from 'styled-components';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
const AmountText = styled.div `
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #404040;
`;
const StatusText = styled.div `
    margin-top: 8px;
    font-size: 0.6rem;
    color: ${(props) => (props.status === 'SUCCESS' ? '#4dbd74' : props.status === 'FAILED' ? '#f86d6b' : '#151b1e')};
`;
const TimeStampText = styled.div `
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151a1d;
`;
const TransationIdText = styled.div `
    font-size: 12px;
    font-weight: 300;
    color: #151a1d;
`;
class RecentTransacticonsCard extends Component {
    sizePerPage = 20;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: true,
            currentPage: 1,
            sizePerPage: this.sizePerPage,
            transactions: [],
            sortOrder: 'desc',
            sortName: 'createdDate',
            startDate: getMomentTime().subtract(DEFAULT_JOB_START_DATE_DAYS, 'days').startOf('day'),
            endDate: getMomentTime().add(DEFAULT_JOB_START_DATE_DAYS, 'days').endOf('day'),
        };
    }
    componentDidMount = async () => {
        this.fetchData();
    };
    onPageChange = (page, sizePerPage) => {
        this.setState({
            currentPage: page,
            sizePerPage: sizePerPage,
        }, () => {
            this.fetchData();
            stringifyUrlThroughState(this.state);
        });
    };
    onSortChange = (sortName, sortOrder) => {
        this.setState({
            sortName,
            sortOrder,
        }, () => {
            this.fetchData();
            stringifyUrlThroughState(this.state);
        });
    };
    fetchData = async () => {
        const { startDate, endDate, currentPage } = this.state;
        const sort = `${this.state.sortName},${this.state.sortOrder}`;
        const resp = await this.props.fetchRecentTransactions(this.props.accesstoken, startDate.valueOf(), endDate.valueOf(), currentPage - 1, this.sizePerPage, sort);
        const transactions = get(resp, 'payload.data.content', []);
        const sortedTxns = orderBy(transactions, ['createdDate'], ['desc']);
        this.setState({ transactions: sortedTxns, loading: false });
    };
    handleFilterChange = (key, value) => {
        const newState = {
            currentPage: 1,
        };
        if (key === 'timeFilter') {
            newState['startDate'] = get(value, '[0]', null);
            newState['endDate'] = get(value, '[1]', null);
        }
        else {
            newState[key] = value;
        }
        this.setState(newState, () => {
            this.fetchData();
            stringifyUrlThroughState(this.state);
        });
    };
    render() {
        const { sizePerPage, currentPage, transactions, sortOrder, sortName, loading, startDate, endDate } = this.state;
        if (loading) {
            return _jsx(TableSkeleton, { rowclassName: 'mt-4' });
        }
        return (_jsxs(_Fragment, { children: [_jsx(FxFlex, { direction: "row", justifyContent: "flex-end", alignItmes: "center", viewMargin: "8px 0px 8px 0px", children: _jsx("div", { className: `calendar-component`, "data-gacategory": "Internal Issues Filters", "data-gaaction": "Click", "data-galabel": "Issues-Date Range", children: _jsx(CalendarComponent, { onDateSelected: (sDate, eDate) => {
                                this.handleFilterChange('timeFilter', [sDate, eDate]);
                            }, startDate: startDate, endDate: endDate }) }) }), map(transactions, (transaction) => (_jsx(FxCard, { className: "m-2", children: _jsxs(FxFlex, { direction: "row p-4", children: [_jsxs("div", { className: "w-25 p-2", children: [_jsxs(AmountText, { children: [getCurrencyFromCountry(this.props.loggedInUser, true), get(transaction, 'amount', 0)] }), _jsx(StatusText, { status: get(transaction, 'status', 0), children: get(transaction, 'status', 0) })] }), _jsxs("div", { className: "w-75 p-2", children: [_jsx(TimeStampText, { children: get(transaction, 'createdDate', 0) }), _jsx(TransationIdText, { children: "Transaction Id" }), _jsx(TransationIdText, { children: get(transaction, 'orderId', 0) })] })] }) })))] }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchRecentTransactions }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: state.login.data.access_token,
        login: state.login.data,
        loggedInUser: state.settings.loggedInUser,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RecentTransacticonsCard);
