import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const StagesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-stages-component" */ 'components/dashboard/stages/stages_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditStages = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditStages" */ 'components/dashboard/stages/addEditStages'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const stagesRoutes = [
    {
        path: 'add',
        lazy: AddEditStages,
    },
    {
        path: 'edit/:id',
        lazy: AddEditStages,
    },
    {
        index: true,
        lazy: StagesComponent,
    },
];
export default stagesRoutes;
