import axios from 'axios';
import { ROOT_API_NMS, parseQueryParams } from '../../../constant';
export const FETCH_NOTIFICATION_SUMMARY = 'FETCH_NOTIFICATION_SUMMARY';
export const FETCH_NOTIFICATION_LIST = 'FETCH_NOTIFICATION_LIST';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
export function fetchNotificationSummary(accesstoken, params = {}) {
    const { date, consignorGstin, filterUnmapped } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_NMS}notification/summary`, config);
    return {
        type: FETCH_NOTIFICATION_SUMMARY,
        promise: request,
    };
}
export function fetchNotificationList(accesstoken, params = {}) {
    const { date, consignorGstin, filterUnmapped } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_NMS}notification/listing`, config);
    return {
        type: FETCH_NOTIFICATION_LIST,
        promise: request,
    };
}
export function updateNotification(accesstoken, params = {}, body = {}) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.put(`${ROOT_API_NMS}notification/update`, body, config);
    return {
        type: NOTIFICATION_UPDATE,
        promise: request,
    };
}
