import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditBusinessVerticals = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditChargeHead" */ 'components/dashboard/BusinessVerticals/AddEditBusinessVerticals'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const BusinessVerticals = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-chargeHeads" */ 'components/dashboard/BusinessVerticals/BusinessVerticals'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const bussinessVerticalRoutes = [
    {
        index: true,
        lazy: BusinessVerticals,
    },
    {
        path: 'add',
        lazy: AddEditBusinessVerticals,
    },
    {
        path: 'edit/:id',
        lazy: AddEditBusinessVerticals,
    },
];
export default bussinessVerticalRoutes;
