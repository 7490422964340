import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';
import { FETCH_FUEL_ENTRIES_IN_BETWEEN, FETCH_FUEL_ENTRIES_IN_BETWEEN_SUMMARY } from './fuel_entry_action';
import { DATE_FORMAT_HUMANIZE_DAY, DATE_FORMAT_TIMESTAMP_DAY, getMomentTime } from '../../../utils/date_utils';

export const CREATE_SERVICE_ENTRY = 'create_service_entry';
export const FETCH_SERVICE_ENTRIES = 'fetch_service_entries';
export const FETCH_SERVICE_ENTRY = 'fetch_service_entry';
export const UPDATE_SERVICE_ENTRY = 'update_service_entry';
export const DELETE_SERVICE_ENTRY = 'delete_service_entry';
export const FETCH_SERVICE_ENTRIES_IN_BETWEEN_SUMMARY = 'fetch_service_entries_in_between_summary';
export const FETCH_SERVICE_ENTRIES_IN_BETWEEN = 'fetch_service_entries_in_between';
export const FETCH_SERVICE_ENTRIES_DAY_WISE_IN_BETWEEN = 'FETCH_SERVICE_ENTRIES_DAY_WISE_IN_BETWEEN';

export function createServiceEntry(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}service_entries/`, values, config);
    return {
        type: CREATE_SERVICE_ENTRY,
        promise: request,
    };
}

export function updateServiceEntry(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}service_entries/${id}`, values, config);

    return {
        type: UPDATE_SERVICE_ENTRY,
        promise: request,
    };
}

export function fetchServiceEntries(accesstoken, page = 1, size = 1000, groupId, vehicleId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}service_entries/?page=${page - 1}&size=${size}${vehicleQuery}${groupQuery}`,
        config
    );

    return {
        type: FETCH_SERVICE_ENTRIES,
        promise: request,
    };
}

export function fetchServiceEntry(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}service_entries/${id}`, config);

    return {
        type: FETCH_SERVICE_ENTRY,
        promise: request,
    };
}

export function deleteServiceEntry(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}service_entries/${id}`, config);
    return {
        type: DELETE_SERVICE_ENTRY,
        promise: request,
    };
}

export function fetchServiceEntriesInBetween(
    accesstoken,
    page = 1,
    size = 1000,
    vehicle,
    groupId,
    from,
    to,
    sortByDateAsc,
    vendorId,
    lineItem,
    vehicleDomain,
    sparePartEntry,
    ids = null,
    settled
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleDomain,
            sparePartEntry,
            settled,
        }),
    };
    let query = vehicle ? `&vehicleId=${vehicle}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    let toQuery = to ? `&to=${to}` : '';
    let sortQuery = sortByDateAsc ? `&sort=date,asc` : '&sort=date,desc';
    const vendorQuery = vendorId ? `&vendorId=${vendorId}` : '';
    const lineItemQuery = lineItem ? `&lineItem=${lineItem}` : '';
    const idsQuery = ids ? `&ids=${ids}` : '';
    const request = axios.get(
        `${ROOT_API_URL}service_entries/between?from=${from}${toQuery}${query}${groupQuery}${lineItemQuery}${idsQuery}&page=${
            page - 1
        }&size=${size}${sortQuery}${vendorQuery}`,
        config
    );
    return {
        type: FETCH_SERVICE_ENTRIES_IN_BETWEEN,
        promise: request,
    };
}

export function fetchServiceEntriesDayWiseInBetween(accesstoken, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).format(DATE_FORMAT_TIMESTAMP_DAY),
            to: getMomentTime(to).format(DATE_FORMAT_TIMESTAMP_DAY),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}service_entries/day-wise`, config);
    return {
        type: FETCH_SERVICE_ENTRIES_DAY_WISE_IN_BETWEEN,
        promise: request,
    };
}

export function fetchServiceEntriesInBetweenSummary(
    accesstoken,
    vehicle,
    groupId,
    from,
    to,
    vendorId,
    lineItem,
    vehicleDomain,
    sparePartEntry,
    settled
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleDomain,
            sparePartEntry,
            settled,
        }),
    };
    let query = vehicle ? `&vehicleId=${vehicle}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    let toQuery = to ? `&to=${to}` : '';
    const vendorQuery = vendorId ? `&vendorId=${vendorId}` : '';
    const lineItemQuery = lineItem ? `&lineItem=${lineItem}` : '';
    const request = axios.get(
        `${ROOT_API_URL}service_entries/between/summary?from=${from}${toQuery}${query}${groupQuery}${vendorQuery}${lineItemQuery}`,
        config
    );
    return {
        type: FETCH_SERVICE_ENTRIES_IN_BETWEEN_SUMMARY,
        promise: request,
    };
}
