import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider, Modal } from 'antd';
const FxModal = (props) => {
    const { themeModify, ...rest } = props;
    return (_jsx(ConfigProvider, { theme: {
            token: {
                ...themeModify,
            },
        }, children: _jsx(Modal, { ...rest }) }));
};
export const fxConfirm = Modal.confirm;
export default FxModal;
