import { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Progress } from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { fetchImagekitSHAV2, getServerTime, setUploadLoading } from '../../actions/dashboard/utils_action';
import { IMAGEKIT_PUBLIC_API_KEY, getImageKitLink } from '../../constant';
import { getMomentTime, MINUTES_1 } from '../../utils/date_utils';
import { filter, round, get, map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getValidUploadFileTypes, validateFileBeforeUpload } from 'utils/fileUploadutils';

class MultiFileDropzoneComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploadedFiles: this.props.uploadedFiles || [],
        };
    }

    async onDrop(acceptedFiles, rejectedFiles) {
        this.props.onDropCB && this.props.onDropCB();
        const myUploadProgress = (myFileId) => (progress) => {
            let percentage = Math.floor((progress.loaded * 100) / progress.total);
            const updateState = {};

            updateState[myFileId] = percentage;
            this.setState(updateState);
        };

        let timeStamp = Math.floor(getMomentTime().valueOf());

        try {
            const result = await this.props.getServerTime(this.props.accesstoken);
            timeStamp = get(result, 'payload.data.timestamp', timeStamp);
        } catch (e) {}

        acceptedFiles.forEach((file) => {
            const fileValidationObj = validateFileBeforeUpload(file);
            if (get(fileValidationObj, 'error')) {
                alert(get(fileValidationObj, 'errorMsg'));
                return;
            }

            const token = uuidv4();
            const expire = round((timeStamp + MINUTES_1 * 30) / 1000, 0);

            let fileObj = {
                id: timeStamp + file.name,
                file: file,
                progress: 'progress_' + timeStamp + file.name,
                uploadedUrl: 'uploadedUrl_' + timeStamp + file.name,
                error: 'error_' + timeStamp + file.name,
            };
            if (this.props.imageCategory) {
                fileObj.imageCategory = this.props.imageCategory;
            }
            this.setState((prevState) => ({
                files: [...prevState.files, fileObj],
            }));

            this.props.fetchImagekitSHAV2(this.props.accesstoken, file.name, token, expire).then((result) => {
                var config = {
                    onUploadProgress: myUploadProgress('progress_' + timeStamp + file.name),
                };
                if (!result.error) {
                    const reader = new FileReader();
                    const signature = get(result, 'payload.data.signature');
                    const expireAPI = get(result, 'payload.data.expire');

                    reader.onload = (event) => {
                        const data = new FormData();
                        data.append('file', event.target.result.substring(event.target.result.indexOf(',') + 1));
                        data.append('fileName', file.name);
                        data.append('publicKey', IMAGEKIT_PUBLIC_API_KEY);
                        data.append('signature', signature);
                        data.append('token', token);
                        data.append('expire', expireAPI);
                        data.append('useUniqueFilename', true);
                        if (this.props.customFolderPath) {
                            data.append('folder', this.props.customFolderPath);
                        } else {
                            data.append(
                                'folder',
                                `clients/${this.props.loggedInUser.accountId}/${this.props.folderPath}`
                            );
                        }
                        this.props.setUploadLoading(true);
                        axios
                            .post('https://upload.imagekit.io/api/v1/files/upload', data, config)
                            .then(
                                function (res) {
                                    const update = {};
                                    update['uploadedUrl_' + timeStamp + file.name] = res.data.filePath;
                                    this.setState(update);
                                    this.props.fileSuccessCallback(
                                        this.props.id,
                                        res.data.filePath,
                                        timeStamp + file.name
                                    );
                                    this.props.setUploadLoading(false);
                                }.bind(this)
                            )
                            .catch(
                                function (err) {
                                    const update = {};
                                    update['error_' + timeStamp + file.name] = true;
                                    this.props.setUploadLoading(false);
                                }.bind(this)
                            );
                    };
                    reader.onabort = () => console.log('file reading was aborted');
                    reader.onerror = () => console.log('file reading has failed');

                    reader.readAsDataURL(file);
                }
            });
        });
    }

    deleteImage(event) {
        const uploadedFiles = filter(this.state.uploadedFiles, (item) => item.url !== event.currentTarget.dataset.url);
        this.setState({ uploadedFiles: uploadedFiles });
        this.props.fileDeleteCallback(event.currentTarget.dataset.url, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.uploadedFiles !== this.props.uploadedFiles) {
            this.setState({ uploadedFiles: this.props.uploadedFiles });
        }
    }

    deleteImageFromAcceptFiles(event) {
        const files = filter(this.state.files, (item) => item.id !== event.currentTarget.dataset.id);
        this.setState({ files: files });
        this.props.fileDeleteCallback(event.currentTarget.dataset.url, false, event.currentTarget.dataset.id);
    }

    render() {
        const { name, accept, message, previewIcon = true } = this.props;
        return (
            <div>
                <Dropzone
                    name={name}
                    onDrop={this.onDrop.bind(this)}
                    accept={getValidUploadFileTypes(accept)}
                    multiple={true}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div
                                {...getRootProps({
                                    className: 'dropzone',
                                    style: this.props.style || {
                                        width: '100%',
                                        height: '160px',
                                    },
                                })}
                            >
                                <input {...getInputProps()} />
                                <span className="text-center h6">{message}</span>
                            </div>
                        </section>
                    )}
                </Dropzone>

                {(this.state.files?.length > 0 || this.state.uploadedFiles?.length > 0) && (
                    <div className="mt-2">
                        {!this.props.hideTitle ? (
                            <h2>{this.props.uploadedText ? this.props.uploadedText : 'Uploaded files'}</h2>
                        ) : null}

                        {this.state.files?.length > 0 && (
                            <div>
                                {this.state.files.map((item) => (
                                    <div key={item.id} className="border p-2 mt-2" style={{ background: '#EBEBEB' }}>
                                        <div className="pull-left">
                                            {!this.state.error && (
                                                <span className="d-inline-block">
                                                    <a
                                                        target="_blank"
                                                        href={`https://ik.imagekit.io/fx${
                                                            this.state[item.uploadedUrl]
                                                                ? this.state[item.uploadedUrl]
                                                                : ''
                                                        }`}
                                                    >
                                                        {item.file.name}
                                                    </a>
                                                    <b className="ml-2" style={{ fontSize: '0.7rem' }}>
                                                        {get(item, 'imageCategory', '')}
                                                    </b>
                                                </span>
                                            )}
                                            {this.state.error && (
                                                <span className="d-inline-block text-danger">
                                                    Some error in uploading the file.
                                                </span>
                                            )}
                                            {this.state[item.progress] < 100 && (
                                                <span
                                                    className="mx-4 d-inline-block"
                                                    style={{ width: '240px', marginTop: '4px' }}
                                                >
                                                    <Progress value={this.state[item.progress]} />
                                                </span>
                                            )}
                                        </div>
                                        {this.props.deleteOption &&
                                            !this.state.error &&
                                            this.state[item.progress] == 100 && (
                                                <div
                                                    className="pull-right hover"
                                                    data-url={this.state[item.uploadedUrl]}
                                                    data-id={item.id}
                                                    onClick={this.deleteImageFromAcceptFiles.bind(this)}
                                                >
                                                    <i className="fa fa-remove" />
                                                </div>
                                            )}
                                        <div className="clearfix" />
                                    </div>
                                ))}
                            </div>
                        )}
                        {this.state.uploadedFiles?.length > 0 && previewIcon && (
                            <div>
                                {map(this.state.uploadedFiles, (item) => (
                                    <div key={item.id} className="border p-2 mt-2" style={{ background: '#EBEBEB' }}>
                                        <div className="pull-left">
                                            {/* <a target="_blank" href={`https://ik.imagekit.io/fx${item.uploadedUrl}`}>
                                                {item.uploadedUrl}
                                            </a> */}
                                            <img src={getImageKitLink(item.uploadedUrl)} width="100px" />
                                        </div>
                                        {this.props.deleteOption && (
                                            <div
                                                className="pull-right hover"
                                                data-url={item.uploadedUrl}
                                                onClick={this.deleteImage.bind(this)}
                                            >
                                                <i className="fa fa-remove" />
                                            </div>
                                        )}
                                        <div className="clearfix" />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        accesstoken: state.login.data.access_token,
        loggedInUser: state.settings.loggedInUser,
    };
}

export default connect(mapStateToProps, { fetchImagekitSHAV2, getServerTime, setUploadLoading })(
    MultiFileDropzoneComponent
);
