import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_MATERIAL = 'CREATE_MATERIAL';
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const DELETE_MATERIAL = 'DELETE_MATERIAL';
export const FETCH_MATERIALS = 'FETCH_MATERIALS';
export const FETCH_MATERIAL = 'FETCH_MATERIAL';
export function createMaterial(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}material`, data, config);
    return {
        type: CREATE_MATERIAL,
        promise: request,
    };
}
export function updateMaterial(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}material`, data, config);
    return {
        type: UPDATE_MATERIAL,
        promise: request,
    };
}
export function fetchMaterials(accesstoken, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}material`, config);
    return {
        type: FETCH_MATERIALS,
        promise: request,
    };
}
export function fetchMaterial(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}material/${id}`, config);
    return {
        type: FETCH_MATERIAL,
        promise: request,
    };
}
export function deleteMaterial(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}material/${id}`, config);
    return {
        type: DELETE_MATERIAL,
        promise: request,
    };
}
