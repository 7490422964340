import { jsx as _jsx } from "react/jsx-runtime";
import { FxDivider, FxBadge } from './index';
const FxBadgeDivider = (props) => {
    return (_jsx(FxDivider, { className: props.className, children: _jsx(FxBadge, { count: props.text, color: "white", style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#000',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
                padding: '0.8rem',
                fontSize: '0.9rem',
                borderRadius: '1rem',
            } }) }));
};
export default FxBadgeDivider;
