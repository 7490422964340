import { dropRight, get } from 'lodash';
import { ORIENTATION, REPORT_FORMAT, startReportCreation } from 'utils/report_utils';

export const UPLOADER_TYPES = [
    { value: 'Consignment', label: 'Consignment' },
    { value: 'Freight bill', label: 'Freight bill' },
    { value: 'Fuel entry', label: 'Fuel entry' },
    { value: 'Service entry', label: 'Service entry' },
    { value: 'Part', label: 'Part' },
    { value: 'Part entry', label: 'Part entry' },
    { value: 'Part entry multiple', label: 'Part entry multiple' },
    { value: 'Part maker', label: 'Part maker' },
    { value: 'Part model', label: 'Part model' },
    { value: 'Part category', label: 'Part category' },
    { value: 'Ledger', label: 'Ledger' },
    { value: 'Voucher', label: 'Voucher' },
    { value: 'Eway', label: 'Eway' },
    { value: 'Jobs', label: 'Jobs' },
];

export const UPLOADER_STATUSES = [
    { value: 'UPLOADED', label: 'Uploaded' },
    { value: 'FAILED', label: 'Failed' },
    { value: 'UPLOADING', label: 'Uploading' },
];
export const uploaderMessages = {
    FAILED: 'Something Went wrong. Please try again later!!',
    SUCCESS: 'Your request for upload has been successfully submitted.',
};

export const downloadErrorReport = (currentRow) => {
    const filename = get(currentRow, 'name', '');
    const name = dropRight(filename.split('.'), 1)?.join('.');
    const createdDate = get(currentRow, 'createdDate');
    const reportName = `error report -${name}-${createdDate}`;

    const headers = [
        {
            name: 'lineNo',
            prompt: 'Line No',
        },
        {
            name: 'description',
            prompt: 'Description',
        },
    ];

    const data = get(currentRow, 'errorResponse', []);

    //total row
    data.push({
        lineNo: `TOTAL ${data.length}`,
        description: '',
    });
    startReportCreation(ORIENTATION.POTRAIT, headers, data, REPORT_FORMAT.CSV, '', null, null, null, reportName, 2);
};
