import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import { Navigate } from 'react-router-dom';
const RealtimeShareLinksListComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-realtime-share-links" */ 'components/dashboard/realtime/realtime_share_list_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const RealtimeVehicleComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-realtime-vehicle" */ 'components/dashboard/realtime/realtime_vehicle_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const RealtimeRouteWrapper = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-realtime-route-wrapper" */ 'components/dashboard/realtime/realtimeRouteWrapper'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const realtimeRoutes = [
    {
        path: 'overview',
        lazy: RealtimeRouteWrapper,
    },
    {
        path: 'overview/:id',
        lazy: RealtimeVehicleComponent,
    },
    {
        path: 'overviewVehicle/',
        lazy: RealtimeVehicleComponent,
    },
    {
        path: 'shares',
        lazy: RealtimeShareLinksListComponent,
    },
    {
        path: '*',
        element: _jsx(Navigate, { to: "/dashboard/realtime/overview", replace: true }),
    },
];
export default realtimeRoutes;
