import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get, map } from 'lodash';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import { mappedCommodities, mappedConsignmentMaterial, mappedMaterial } from '../../components/utils/mapping_utils';
import { FxSelectField, FxTextFieldStackedLabel } from '../index';
const CommoditiesFormComponent = (props) => {
    const { name, commodityList, commodities, materialList } = props;
    const commodityListSize = get(commodities, 'length');
    const { setFieldValue } = useFormikContext();
    return (_jsxs("div", { children: [_jsx("h6", { children: "Commodities" }), _jsx(FieldArray, { name: name, children: (commoditiesArrayHelpers) => {
                    return (_jsxs("div", { children: [map(commodities, (commodity, commodityIndex) => {
                                const commodityKey = `${name}.[${commodityIndex}]`;
                                const isFieldVisible = get(commodity, `commodityId`) ||
                                    get(commodity, `materialId`) ||
                                    get(commodity, `consignmentMaterial.materialId`);
                                const isFieldDisable = !get(commodity, `commodityId`);
                                return (_jsxs(Row, { children: [_jsxs(Col, { md: 3, sm: 12, children: [_jsx(Field, { name: `${commodityKey}.commodityId`, onSelected: (val) => {
                                                        setFieldValue(`${commodityKey}.commodity.id`, val);
                                                    }, label: 'Commodity', component: FxSelectField, options: mappedCommodities(commodityList, commodity, commodities), isRequired: true, disabled: false }), get(commodity, `materialId`) && (_jsx(Field, { name: `${commodityKey}.materialId`, label: 'Commodity', component: FxSelectField, options: mappedMaterial(materialList, commodity, commodities), isRequired: true, disabled: true })), get(commodity, `consignmentMaterial.materialId`) && (_jsx(Field, { name: `${commodityKey}.consignmentMaterial.materialId`, label: 'Commodity', component: FxSelectField, options: mappedConsignmentMaterial(materialList, commodity, commodities), isRequired: true, disabled: true }))] }), _jsx(Col, { md: 4, sm: 12, children: isFieldVisible && (_jsx(Field, { name: `${commodityKey}.materialCount`, label: 'Scheduled Count', component: FxTextFieldStackedLabel, min: "0", type: "number", step: 1, isReqauired: !commodity.scheduledQuantity, disabled: isFieldDisable })) }), _jsxs(Col, { md: 4, sm: 12, className: "d-flex", children: [isFieldVisible && (_jsx(Field, { name: `${commodityKey}.materialQuantity`, label: 'Scheduled Material', component: FxTextFieldStackedLabel, min: "0", type: "number", step: "any", isRequired: !commodity.scheduledCount, disabled: isFieldDisable })), isFieldVisible && (_jsx(Button, { className: "ml-2", color: "link", onClick: () => commoditiesArrayHelpers.remove(commodityIndex), children: "Remove" }))] })] }, commodityKey));
                            }), _jsx("div", { children: _jsxs("button", { type: "button", className: "btn", style: {
                                        borderRadius: '4px',
                                        'border': 'solid 1px #20a8d8',
                                        'color': '#20a8d8',
                                        height: '2.5rem',
                                    }, onClick: () => commoditiesArrayHelpers.push({
                                        type: 'DROP',
                                    }), children: ["Add ", commodityListSize ? 'More' : '', " Commodity"] }) })] }));
                } })] }));
};
export default CommoditiesFormComponent;
