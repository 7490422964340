import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useReducer } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, toSafeInteger, groupBy } from 'lodash';
import { handleError } from '../../../constant';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import CalendarComponent from '../../utils/calendar_component';
import Loader from '../../utils/loader';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';
import ArrivalListingComponent from './ArrivalListingComponent';
import { fetchArrivalsPaginated, deleteArrival } from '../../../actions/dashboard/arrival/arrivalActions';
import { fetchBranches } from '../../../actions/dashboard/branch/actions';
import { fetchRoutesMiniList } from '../../../actions/dashboard/route_dispatch/routes_action';
import { fetchVehicleListMini } from '../../../actions/dashboard/vehicles_action';
import { filtersReducer } from '../../../utils/form_utils';
import { getMomentTime } from '../../../utils/date_utils';
import { queryString } from '../../../utils/string_utils';
import SidenavOverlayComponent from '../../utils/sidenav_overlay';
import ArrivalFiltersComponent from './ArrivalFiltersComponent';
import { stringifyUrlThroughState } from '../../../constant';
import { isCreateArrivalAllowedForUser, isViewBranchAllowedForUser } from '../roles/permission_utils';
import NavBarCustomPills from 'components/utils/nav_bar_custom_pills';
import GenerateReportForSelectedTemplateModal from '../reportTemplates/GenerateReportForSelectedTemplateModal';
import { REPORT_TEMPLATE_TYPES } from 'utils/reportTemplatesUtils';
const initialFiltersState = {
    startDate: getMomentTime().subtract(1, 'days').startOf('day'),
    endDate: getMomentTime().subtract(1, 'days').endOf('day'),
    type: null,
    includeDataReachRange: false,
    includeDataLoadingRange: false,
    branchId: null,
    routeId: null,
    vehicleId: null,
    lorryArrival: null,
};
const ArrivalComponent = (props) => {
    const { accesstoken, login, groupId, routeList, miniVehicleList, loggedInUser } = props;
    const location = useLocation();
    const [filters, dispatchFilters] = useReducer(filtersReducer, initialFiltersState, (state) => {
        const parsed = queryString.parse(location.hash);
        const parsedFilters = {};
        if (parsed.startDate) {
            parsedFilters.startDate = getMomentTime(parsed.startDate);
        }
        if (parsed.endDate) {
            parsedFilters.endDate = getMomentTime(parsed.endDate);
        }
        if (parsed.currentPage) {
            parsedFilters.currentPage = toSafeInteger(parsed.currentPage);
        }
        if (parsed.lorryArrival) {
            parsedFilters.lorryArrival = true;
        }
        if (parsed.includeDataReachRange) {
            parsedFilters.includeDataReachRange = true;
        }
        if (parsed.includeDataLoadingRange) {
            parsedFilters.includeDataLoadingRange = true;
        }
        return { ...state, ...parsedFilters };
    });
    const [openFilters, setOpenFilters] = useState(false);
    const [allBranches, setAllBranches] = useState([]);
    const [arrivals, setArrivals] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isGenerateReportForTemplateModalOpen, setIsGenerateReportForTemplateModalOpen] = useState(false);
    const [metaDataForTemplateGeneration, setMetaDataForTemplateGeneration] = useState({});
    const [arrivalDataTotalSize, setArrivalDataTotalSize] = useState(0);
    const scope = login.scope;
    const { startDate, endDate, currentPage, sizePerPage, routeId, vehicleId, branchId, type, lorryArrival, includeDataLoadingRange, includeDataReachRange, } = filters;
    useEffect(() => {
        if (isViewBranchAllowedForUser(props.login.scope)) {
            props.fetchBranches(accesstoken).then((res) => {
                setAllBranches(get(res, 'payload.data'));
            });
        }
        props.fetchRoutesMiniList(accesstoken, groupId);
        props.fetchVehicleListMini(accesstoken, true, groupId);
    }, []);
    useEffect(() => {
        handleFetchArrivals();
    }, [
        startDate.valueOf(),
        endDate.valueOf(),
        routeId,
        vehicleId,
        branchId,
        type,
        lorryArrival,
        includeDataReachRange,
        includeDataLoadingRange,
        currentPage,
    ]);
    useEffect(() => {
        stringifyUrlThroughState({
            startDate,
            endDate,
            currentPage,
            lorryArrival,
            includeDataLoadingRange,
            includeDataReachRange,
        });
    }, [
        startDate.valueOf(),
        endDate.valueOf(),
        currentPage,
        lorryArrival,
        includeDataLoadingRange,
        includeDataReachRange,
    ]);
    const onPageChange = (page, sizePerPage) => {
        dispatchFilters({
            payload: {
                sizePerPage,
                currentPage: page,
            },
        });
    };
    const handleFilterChange = (key, value) => {
        const newFilersState = {};
        if (key === 'timeFilter') {
            newFilersState.startDate = get(value, '[0]', null);
            newFilersState.endDate = get(value, '[1]', null);
        }
        else if (key === 'lorryArrival') {
            newFilersState.currentPage = 1;
            newFilersState[key] = value;
        }
        else {
            newFilersState[key] = value;
        }
        dispatchFilters({ payload: newFilersState });
    };
    const handleFetchArrivals = () => {
        const params = {
            startDate: startDate.valueOf(),
            endDate: endDate.valueOf(),
            loadingFrom: includeDataLoadingRange ? startDate.valueOf() : null,
            loadingTo: includeDataLoadingRange ? endDate.valueOf() : null,
            reachFrom: includeDataReachRange ? startDate.valueOf() : null,
            reachTo: includeDataReachRange ? endDate.valueOf() : null,
            routeId,
            vehicleId,
            branchId,
            type,
            isLorryArrival: lorryArrival,
            page: currentPage,
            size: 10,
        };
        props
            .fetchArrivalsPaginated(params)
            .then((res) => {
            const arrivals = get(res, 'payload.data.content');
            const totalSize = get(res, 'payload.data.totalElements');
            setArrivalDataTotalSize(totalSize);
            setArrivals(arrivals);
            setIsInitialLoad(false);
        })
            .catch(() => {
            setIsInitialLoad(false);
        });
    };
    const onDeleteArrival = (id) => {
        props
            .deleteArrival(accesstoken, id)
            .then((result) => {
            if (result.error) {
                throw new Error(handleError(result.payload.response));
            }
            else {
                handleFetchArrivals();
            }
        })
            .catch((err) => {
            alert(handleError(err));
        });
    };
    const onOpenReportForTemplate = (row) => {
        setMetaDataForTemplateGeneration({
            id: row.id,
            formatType: REPORT_TEMPLATE_TYPES.ERP_ARRIVAL,
        });
        setIsGenerateReportForTemplateModalOpen(true);
    };
    return (_jsxs("div", { className: "pt-4", children: [_jsx(Helmet, { title: `Arrivals - fleetx.io` }), _jsxs("div", { children: [_jsx("div", { className: "pull-left", children: _jsx("h4", { children: "Arrivals" }) }), _jsxs("div", { className: "pull-right", children: [_jsx("div", { className: `calendar-component  pull-left ml-2 mb-2`, "data-gacategory": "Arrivals", "data-gaaction": "Click", "data-galabel": "Date Range", children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                                        handleFilterChange('timeFilter', [startDate, endDate]);
                                    }, startDate: startDate, endDate: endDate }) }), isCreateArrivalAllowedForUser(scope) && (_jsx("div", { className: "pull-left ml-2 mb-2", children: _jsxs(Link, { to: lorryArrival ? '/dashboard/arrival/lorry/add' : '/dashboard/arrival/add', className: "btn btn-success", children: [_jsx("i", { className: "fa fa-plus" }), ' ', _jsx("span", { "data-gacategory": "Arrival", "data-gaaction": "Click", "data-galabel": "Name-dropdown-arrival-add", children: lorryArrival ? 'New Lorry Arrival' : 'New Arrival' })] }) })), _jsx("div", { className: "pull-left ml-2 mb-2", children: _jsxs(Button, { onClick: () => setOpenFilters(!openFilters), "data-gacategory": "Arrivals", "data-gaaction": "Click", "data-galabel": "Arrivals-Filter", children: [_jsx("i", { title: "Filters", style: { fontSize: '16px' }, className: "mr-1 fa fxicon-filter" }), ' ', _jsx("span", { style: { verticalAlign: 'top' }, children: "Filter" })] }) })] }), _jsx("div", { className: "clearfix" }), _jsx(NavBarCustomPills, { children: _jsxs(Nav, { justified: true, pills: true, children: [_jsx(NavItem, { children: _jsx("div", { children: _jsx(NavLink, { href: "#", active: !lorryArrival, onClick: () => {
                                                handleFilterChange('lorryArrival', null);
                                            }, children: "Arrival" }) }) }), _jsx(NavItem, { children: _jsx(NavLink, { href: "#", active: lorryArrival, onClick: () => {
                                            handleFilterChange('lorryArrival', true);
                                        }, children: "Lorry Arrival" }) })] }) })] }), _jsx("div", { children: isInitialLoad ? (_jsx(Loader, {})) : (_jsx(ArrivalListingComponent, { onPageChange: onPageChange, currentPage: currentPage, sizePerPage: sizePerPage, routeList: routeList, data: arrivals, onDeleteArrival: onDeleteArrival, groupedBranches: groupBy(allBranches, 'id'), groupedVehicles: groupBy(miniVehicleList, 'id'), isLorryArrival: lorryArrival, onOpenReportForTemplate: onOpenReportForTemplate, totalDataSize: arrivalDataTotalSize })) }), _jsx(SidenavOverlayComponent, { title: "Filters", open: openFilters, onClose: () => setOpenFilters(!openFilters), children: _jsx(ArrivalFiltersComponent, { handleFilterChange: handleFilterChange, branches: allBranches, routeList: routeList, branchId: branchId, type: type, routeId: routeId, vehicleId: vehicleId, vehicleList: miniVehicleList, loggedInUser: loggedInUser }) }), isGenerateReportForTemplateModalOpen && (_jsx(GenerateReportForSelectedTemplateModal, { isOpen: isGenerateReportForTemplateModalOpen, onClose: () => {
                    setIsGenerateReportForTemplateModalOpen((v) => !v);
                }, formatType: metaDataForTemplateGeneration.formatType, itemId: metaDataForTemplateGeneration.id, onSuccess: () => {
                    setIsGenerateReportForTemplateModalOpen((v) => !v);
                }, label: 'Print' }))] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchArrivalsPaginated,
        fetchBranches,
        fetchRoutesMiniList,
        deleteArrival,
        fetchVehicleListMini,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        groupId: state.local.defaultGroupId,
        routeList: state.routes.routeListMiniNew,
        miniVehicleList: get(state, 'vehicles.vehicleListMini', []),
        loggedInUser: state.settings.loggedInUser,
        addressBook: state.addressBook.addressBook,
        transporters: state.transporters.transporterList,
        customers: get(state, 'customers.customers', []),
        consignments: state.consignments.consignments,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ArrivalComponent);
