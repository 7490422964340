import axios from "axios";
import {ROOT_API_URL} from "../../constant";
import {split} from "lodash";

export const CREATE_TIMEFENCE = 'create_timefence';
export const FETCH_TIMEFENCES = 'fetch_timefences';
export const FETCH_TIMEFENCE = 'fetch_timefence';
export const UPDATE_TIMEFENCE = 'update_timefence';
export const DELETE_TIMEFENCE = 'delete_timefence';

export function createTimefence(accesstoken, values) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.post(`${ROOT_API_URL}timefence/`, values, config);
    return {
        type: CREATE_TIMEFENCE, promise: request,
    }
}

export function updateTimefence(accesstoken, values, id) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.put(`${ROOT_API_URL}timefence/${id}`, values, config);

    return {
        type: UPDATE_TIMEFENCE, promise: request
    }
}

export function fetchTimefences(accesstoken, vehicle, group) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    let query = vehicle ? `?vehicleId=${vehicle}` : "";

    if (!vehicle) {
        query = group ? `?groupId=${group}` : "";
    }
    const request = axios.get(`${ROOT_API_URL}timefence/${query}`, config);
    return {
        type: FETCH_TIMEFENCES, promise: request,
    }

}

export function fetchTimefence(id, accesstoken) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.get(`${ROOT_API_URL}timefence/${id}`, config);

    return {
        type: FETCH_TIMEFENCE, promise: request,
    }

}


export function deleteTimefence(accesstoken, id) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.delete(`${ROOT_API_URL}timefence/${id}`, config);
    return {
        type: DELETE_TIMEFENCE, promise: request
    }
}
