import { get } from 'lodash';
import { handle } from 'redux-pack';
import { FETCH_EXISTING_CLONED_VEHICLES } from 'actions/dashboard/cloneVehicles/clone_action';

const initialState = {
    isLoading: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_EXISTING_CLONED_VEHICLES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicles: get(payload, 'data.srcAccountInfo', []),
                    isLoading: false,
                }),
            });
        default:
            return state;
    }
}
