import { jsx as _jsx } from "react/jsx-runtime";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import * as React from 'react';
import { useMemo } from 'react';
import { getISOCountryCode } from '../utils/internationalization_utils';
import { toUpper } from 'lodash';
import styled from 'styled-components';
import { Input } from 'reactstrap';
const PhoneInputWrapper = styled.div `
    .PhoneInputInput {
        border-radius: 0.25rem !important;
    }
`;
const CustomInput = React.forwardRef((props, ref) => (_jsx(Input, { ...props, innerRef: ref, children: props.children })));
const FxReactPhoneNumberInput = (props) => {
    const defaultCountry = useMemo(() => {
        let defaultCnCode = getISOCountryCode();
        defaultCnCode = defaultCnCode ? toUpper(defaultCnCode) : defaultCnCode;
        return defaultCnCode;
    }, []);
    return (_jsx(PhoneInputWrapper, { children: _jsx(PhoneInput, { international: true, inputComponent: CustomInput, defaultCountry: defaultCountry, withCountryCallingCode: true, countryCallingCodeEditable: false, ...props }) }));
};
export default FxReactPhoneNumberInput;
