import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Video = (props) => {
    return (_jsx("svg", { width: "20", height: "15", viewBox: "0 0 20 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M11 2.5C11 1.83696 10.7366 1.20107 10.2678 0.732233C9.79893 0.263392 9.16304 0 8.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V9.5C0 10.163 0.263392 10.7989 0.732233 11.2678C1.20107 11.7366 1.83696 12 2.5 12H8.5C9.16304 12 9.79893 11.7366 10.2678 11.2678C10.7366 10.7989 11 10.163 11 9.5V2.5ZM12 3.93V8.082L14.764 10.432C14.8731 10.5248 15.0065 10.5845 15.1485 10.6039C15.2904 10.6233 15.435 10.6016 15.565 10.5414C15.695 10.4812 15.8051 10.3851 15.8822 10.2643C15.9593 10.1436 16.0001 10.0033 16 9.86V2.193C15.9998 2.05057 15.959 1.91116 15.8824 1.79107C15.8058 1.67098 15.6966 1.57517 15.5676 1.51485C15.4386 1.45454 15.2951 1.43221 15.1538 1.45047C15.0126 1.46874 14.8794 1.52685 14.77 1.618L12 3.93Z", fill: "#6DC8EC" }) }));
};
const VideoIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Video, ...props });
};
export default VideoIcon;
