const FxSkeleton = (props) => {
    const className = props.className ? `${props.className}` : '';
    const style = {
        'width': `${props.width ? props.width : '30%'}`,
        'height': `${props.height ? props.height : '1rem'}`,
        'backgroundImage': 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
        'backgroundRepeat': 'no-repeat',
        'background': `${props.background ? props.background : '#f5f6f7'}`,
        'borderRadius': `${!props.noBorderRadius ? '8px' : '0'}`,
        'border': `${props.withBorder ? '1px solid #ddd' : 'none'}`,
        'borderTop': '0',
        'backgroundSize': `${props.width ? props.width : '800px'} ${props.height ? props.height : '40px'}`,
        'display': 'inline-block',
        'position': 'relative',
        'animationDuration': '0.8s',
        'animationFillMode': 'forwards',
        'animationIterationCount': 'infinite',
        'animationName': 'placeholderShimmer',
        'animationTimingFunction': 'linear',
        'WebkitAnimationDuration': '0.8s',
        'WebkitAnimationFillMode': 'forwards',
        'WebkitAnimationIterationCount': 'infinite',
        'WebkitAnimationName': 'placeholder-shimmer',
        'WebkitAnimationTimingFunction': 'linear',
    };
    return (
        <div
            width={props.width ? props.width : '100px'}
            height={props.height ? props.height : '40px'}
            className={className}
            style={style}
        >
            {props.children}
        </div>
    );
};

export default FxSkeleton;
