import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleError } from '../../../../constant';
import { createPaymentOrder, authorizePayment } from './payment_actions';
import { Formik, Form, Field } from 'formik';
import { FxTextFieldStackedLabel, FxFlex } from '../../../../shared';
import { get } from 'lodash';
import styled from 'styled-components';
import { PAYMENT_STATUS, PAYMENT_TABS } from '../../../../utils/payment_utils';
import { sendAppAlertToSlack } from '../../../../utils/analytics_utils';
import * as Yup from 'yup';
import { queryString } from 'utils/string_utils';
const Icon = styled.i `
    color: ${(props) => (props.color ? props.color : '')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
    padding: 30px 22px 29px;
`;
const ThankYouBackDrop = styled.div `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1051;
    background-color: rgba(255, 255, 255, 0.95);
`;
const ThankYouContainer = styled.div `
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 45px 0 rgba(199, 204, 199, 0.51);
    background-color: #ffffff;
    height: 300px;
    max-height: 300px;
`;
const NiceThankYou = styled.div `
    font-size: 38.7px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #141b1e;
`;
const PaymentStatus = styled.div `
    margin: 5px 0 0 14px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #404040;
`;
const RecentTransaction = styled.div `
    margin: 4rem 1rem 0rem 1rem;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #404040;
`;
const CloseButton = styled.div `
    padding: 10px;
    box-shadow: 0 2px 10px 0 rgba(199, 204, 199, 0.64);
    background-color: #ffffff;
    position: absolute;
    border-radius: 50%;
    top: 5px;
    right: 5px;
`;
const PaySubmit = styled.button `
    margin-top: 0.8rem;
    margin-left: ${(props) => (props.isMobile ? '' : '1rem')};
    width: 150px;
    height: 39px;
`;
export const orderSchema = Yup.object().shape({
    amount: Yup.number().min(1, 'Value has to greater than 0').nullable().required('Please enter amount'),
});
export const RAZORPAY_KEY_TEST = 'rzp_test_rx1IEj6iphCGlI';
export const RAZORPAY_KEY_LIVE = 'rzp_live_HlQvQIomwyrgLj';
class MakePayment extends Component {
    mounted = false;
    constructor(props) {
        super(props);
        const parsed = queryString.parse(location.hash);
        this.state = {
            error: null,
            showThankYou: false,
            paymentResponse: {},
            orderData: {},
            initialValues: { amount: parsed.amount ? parsed.amount : null, currency: 'INR' },
        };
        this.addCheckoutScriptScript();
    }
    addCheckoutScriptScript() {
        if (typeof window.Razorpay !== 'undefined') {
            // callback(true);
        }
        else {
            const s = document.createElement('script');
            s.setAttribute('src', 'https://checkout.razorpay.com/v1/checkout.js');
            s.onload = () => {
                // callback(false);
            };
            document.body.appendChild(s);
        }
    }
    componentDidMount() {
        if (this.state.initialValues?.amount) {
            setTimeout(() => {
                this.onCreateOrder(this.state.initialValues);
            }, 30);
        }
    }
    onCreateOrder = async (values) => {
        try {
            const resp = await this.props.createPaymentOrder(this.props.accesstoken, values);
            const orderData = get(resp, 'payload.data');
            const razorPayOptions = this.getRazorpayPayload(orderData, this.handleRazorPayResponse);
            this.setState({
                orderData,
            }, () => this.initiateRazorPay(razorPayOptions, this.sendPaymentAuthorize));
        }
        catch (err) {
            sendAppAlertToSlack(handleError(`error: ${err}`) + `\n order: ${JSON.stringify(get(this.state, 'orderData', {}))} \n`, '#payment-alert');
            alert(handleError(err));
        }
    };
    initiateRazorPay = (razorPayOptions, sendPaymentAuthorize) => {
        if (window.Razorpay === undefined) {
            return;
        }
        //@ts-ignore
        var rzp1 = new window.Razorpay(razorPayOptions);
        const receipt = get(this.state, 'orderData.receipt');
        rzp1.on('payment.failed', (response) => {
            const paymentId = get(response, 'error.metadata.payment_id');
            const orderId = get(response, 'error.metadata.order_id');
            const errorReason = get(response, 'error.reason', '');
            const errorStep = get(response, 'error.step', '');
            const paymentData = { paymentId, orderId, receipt, attributes: { errorReason, errorStep } };
            sendPaymentAuthorize(paymentData, response);
            sendAppAlertToSlack(`\n code: ${get(response, 'error.code', '')} \n   description: ${get(response, 'error.description', '')} \n + source: ${get(response, 'error.source', '')} \n  step: ${get(response, 'error.step', '')} \n  reason: ${get(response, 'error.reason', '')} \n  order_id: ${get(response, 'error.metadata.order_id', '')} \n `, '#payment-alert');
        });
        rzp1.open();
    };
    getRazorpayPayload = (data, handler) => {
        const orderId = get(data, 'orderId');
        const customerId = get(data, 'accountId');
        const amount = get(data, 'amount');
        const vendorName = get(data, 'vendorName');
        const description = get(data, 'description');
        return {
            'key': RAZORPAY_KEY_LIVE,
            'amount': amount,
            'currency': 'INR',
            'name': vendorName,
            'description': description,
            'image': 'https://ik.imagekit.io/fx/logos/fleetx/fleetx-logo-final-full-header-icon.png?v=5',
            'order_id': orderId,
            'notes': {
                'accountId': customerId,
            },
            'handler': (response) => {
                handler(response);
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
            },
            'theme': {
                'color': '#3399cc',
            },
            'modal': {
                'ondismiss': () => {
                    // const paymentId = '';
                    // const orderId = get(this.state, 'orderData.orderId');
                    // const receipt = get(this.state, 'orderData.receipt');
                    // const errorReason = 'payment_cancelled';
                    // const errorStep = 'user_cancellation';
                    // const paymentData = { paymentId, orderId, receipt, attributes: { errorReason, errorStep } };
                    //this.sendPaymentAuthorize(paymentData, {});
                },
            },
        };
    };
    handleRazorPayResponse = async (paymentResponse) => {
        const paymentId = get(paymentResponse, 'razorpay_payment_id');
        const orderId = get(paymentResponse, 'razorpay_order_id');
        const signature = get(paymentResponse, 'razorpay_signature');
        const receipt = get(this.state, 'orderData.receipt');
        const paymentData = { paymentId, orderId, signature, receipt };
        this.sendPaymentAuthorize(paymentData, paymentResponse);
    };
    sendPaymentAuthorize = async (paymentData, paymentResponse) => {
        try {
            const resp = await this.props.authorizePayment(this.props.accesstoken, paymentData);
            const finalPaymentStatus = get(resp, 'payload.data.status');
            if (finalPaymentStatus === PAYMENT_STATUS.SUCCESS) {
                this.setState({
                    paymentResponse,
                    showThankYou: true,
                }, () => sendAppAlertToSlack(`Payment Success: ${JSON.stringify(paymentResponse)} \n ${JSON.stringify(paymentData)}`, '#payment-alert'));
            }
            else if (finalPaymentStatus === PAYMENT_STATUS.FAILED) {
                alert('Payment failed. Any money deducted will be refunded within 7 days.');
                this.setState({
                    paymentResponse,
                    showThankYou: false,
                }, () => sendAppAlertToSlack(`Payment Error: ${JSON.stringify(paymentResponse)}`, '#payment-alert'));
            }
        }
        catch (err) {
            sendAppAlertToSlack(handleError(err), '#payment-alert');
            alert(handleError(err));
        }
    };
    toggleThankYou = () => {
        this.setState({
            showThankYou: !this.state.showThankYou,
        });
    };
    render() {
        const { showThankYou } = this.state;
        const { isMobile } = this.props;
        return (_jsxs(_Fragment, { children: [!showThankYou && (_jsx("div", { style: { borderRadius: '5px' }, className: "border w-100 my-4", children: _jsx(Formik, { validationSchema: orderSchema, onSubmit: this.onCreateOrder, initialValues: this.state.initialValues, children: ({ handleSubmit, handleChange, handleBlur, values, errors, isSubmitting }) => (_jsx(Form, { children: _jsx("div", { children: _jsx(FxFlex, { direction: isMobile ? 'column' : 'row', className: "p-4", children: _jsxs(FxFlex, { direction: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center', className: "p-md-4", style: { borderRadius: '5px' }, children: [_jsx("div", { className: "d-flex flex-column", children: _jsx(Field, { name: "amount", type: "number", className: "p-2 flex-column", label: "Amount To Be Paid", style: {
                                                        width: '300px',
                                                        border: 'solid 1px #b5b5b5',
                                                        borderRadius: '5px',
                                                    }, colClass: 'd-flex flex-column', component: FxTextFieldStackedLabel }) }), _jsx(PaySubmit, { className: "btn btn-primary", color: 'primary', type: "submit", isMobile: isMobile, "data-gacategory": "Payment", "data-gaaction": "Click", "data-galabel": "Payment-Pay Off", children: "PAY NOW" })] }) }) }) })) }) })), showThankYou && (_jsx(_Fragment, { children: _jsx(ThankYouBackDrop, { onClick: (e) => {
                            e.stopPropagation();
                        }, children: _jsx(ThankYouContainer, { children: _jsxs("div", { style: { position: 'relative' }, children: [_jsxs(FxFlex, { className: "z-index-search", viewMargin: "0 auto", direction: "column", justifyContent: "center", alignItems: "center", children: [_jsx(Icon, { className: "fa fa-check-circle-o fa-5x", color: "#4dbd74" }), _jsx(NiceThankYou, { children: "Thank You" }), _jsx(PaymentStatus, { children: "Your payment status will be updated shortly" }), _jsxs(RecentTransaction, { children: ["PLEASE CHECK", ' ', _jsx("span", { style: { color: '#21a8d8' }, onClick: () => this.props.setActiveTab(PAYMENT_TABS.RECENT_TRANSACTIONS), children: "RECENT TRANSACTIONS" }), ' ', "PAGE FOR PAYMENT HISTORY & CONFIRMATION"] })] }), _jsx(CloseButton, { className: "fa fa-close align-self", onClick: this.toggleThankYou })] }) }) }) }))] }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ createPaymentOrder, authorizePayment }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: state.login.data.access_token,
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MakePayment);
