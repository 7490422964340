import { get, find } from 'lodash';
export const WEIGHING_LOGIC = [
    { value: 'MINIMUM_OF_LOADED_UNLOADED', label: 'Minimum of Loaded, Unloaded' },
    { value: 'LOADED', label: 'Loaded' },
    { value: 'UNLOADED', label: 'Unloaded' },
];
export const TAX_SLABS = [
    { value: 'SLAB_0', label: '0%', numericValue: 0 },
    { value: 'SLAB_5', label: '5%', numericValue: 5 },
    { value: 'SLAB_12', label: '12%', numericValue: 12 },
    { value: 'SLAB_18', label: '18%', numericValue: 18 },
    { value: 'SLAB_28', label: '28%', numericValue: 28 },
];

export const getTaxSlabValue = value => {
    return get(
        find(TAX_SLABS, {
            value: value,
        }),
        'numericValue',
        0
    );
};
