import axios from 'axios';
import { ROOT_API_URL } from '../../../constant';
export const CREATE_PART_MODEL = 'create_part_model';
export const UPDATE_PART_MODEL = 'update_part_model';
export const DELETE_PART_MODEL = 'delete_part_model';
export const FETCH_PART_MODELS = 'fetch_part_models';
export const FETCH_PART_MODEL_BY_ID = 'fetch_part_model_by_id';
export function createPartModel(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}part-model`, data, config);
    return {
        type: CREATE_PART_MODEL,
        promise: request,
    };
}
export function updatePartModel(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}part-model`, data, config);
    return {
        type: UPDATE_PART_MODEL,
        promise: request,
    };
}
export function fetchPartModels(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}part-model`, config);
    return {
        type: FETCH_PART_MODELS,
        promise: request,
    };
}
export function fetchPartModelById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}part-model/${id}`, config);
    return {
        type: FETCH_PART_MODEL_BY_ID,
        promise: request,
    };
}
export function deletePartModel(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}part-model/${id}`, config);
    return {
        type: DELETE_PART_MODEL,
        promise: request,
    };
}
