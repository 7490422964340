import { handle } from 'redux-pack';
import { FETCH_TAGS, FETCH_MULTIPLE_TAGS } from 'actions/dashboard/tags_action';
import { orderBy, get, isEmpty } from 'lodash';
import { arrayToObject } from 'utils/list_utils';

const initialState = {
    isLoading: false,
    error: null,
    tags: [],
    tagsAsMap: {},
    isFetchingTags: false,
    isFetchingMultipleTags: false,
    multipleTags: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_TAGS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFetchingTags: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const tags = get(payload, 'data.tags') ? orderBy(payload.data.tags, 'jointName', 'asc') : [];
                    const tagsAsMap = !isEmpty(tags) ? arrayToObject(tags, 'id') : {};
                    return { ...prevState, tags: tags, tagsAsMap };
                },
            });
        case FETCH_MULTIPLE_TAGS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isFetchingTags: true,
                }),
                finish: (prevState) => ({ ...prevState, isFetchingTags: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const tags = get(payload, 'data.tags') ? orderBy(payload.data.tags, 'jointName', 'asc') : [];
                    const multipleTags = !isEmpty(tags) ? arrayToObject(tags, 'id') : {};
                    return { ...prevState, multipleTags, isFetchingMultipleTags: false };
                },
            });
        default:
            return state;
    }
}
