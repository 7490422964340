import { handle } from 'redux-pack';
import { FETCH_BRANCHES } from 'actions/dashboard/branch/actions';

const initialState = {
    branches: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_BRANCHES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        branches: payload.data,
                        branchesAsMap: new Map(payload.data.map((branch) => [branch.id, branch])),
                    };
                },
            });
        default:
            return state;
    }
}
