import axios from 'axios';
import { assign, get } from 'lodash';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';
export const FETCH_PRODUCT_BY_ID = 'fetch_product_by_id';
export const CREATE_PRODUCT = 'create_product';
export const FETCH_ALL_PRODUCTS = 'fetch_all_products';
export const UPDATE_PRODUCT = 'update_product';
export function fetchProductById(accesstoken, productId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}/product/type/${productId}`, config);
    return {
        type: FETCH_PRODUCT_BY_ID,
        promise: request,
    };
}
export function fetchAllProducts(accesstoken, page = 1, size = 2000) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            page: page - 1,
            size: size,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}product/type`, config);
    return {
        type: FETCH_ALL_PRODUCTS,
        promise: request,
    };
}
export function createProduct(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const data = assign({}, values);
    const request = axios.post(`${ROOT_API_URL}product/type`, data, config);
    return {
        type: CREATE_PRODUCT,
        promise: request,
    };
}
export function updateProduct(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const id = get(data, 'id', '');
    const request = axios.put(`${ROOT_API_URL}product/type/${id}`, data, config);
    return {
        type: UPDATE_PRODUCT,
        promise: request,
    };
}
