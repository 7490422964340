import { getDaysRangeInFreightBill } from 'components/dashboard/freightbill/forms/freight_bill_account_utils';
import { getMomentTime } from './date_utils';
import { ADDITIONAL_CHARGE_FIELD_CONFIG, CHARGE_CALCULATION_TYPES, RATE_OPTIONS } from './rateChartsUtils';
import { filter, omit, sumBy } from 'lodash';
export const CHARGE_HEAD_NATURE = {
    REVENUE: 'REVENUE',
    COST: 'COST',
};
export const ADDRESS_TYPES = {
    DOOR: 'DOOR',
    GODOWN: 'GODOWN',
};
export const CONSIGNMENT_TYPES = {
    BILLED: 'BILLED',
    PAID: 'PAID',
    TO_PAY: 'TO_PAY',
    MARKET_MATERIAL: 'MARKET_MATERIAL',
};
export const CONSIGNMENT_TYPES_TO_LABELS = {
    BILLED: 'Billed',
    PAID: 'Paid',
    TO_PAY: 'TO Pay',
    MARKET_MATERIAL: 'Market Material',
};
export const WITH_ADVANCE_UNPAID_LABELS = {
    'false': 'Advance Received',
    'true': 'Advance Pending',
};
export const WITH_FREIGHT_BILL_LABELS = {
    'true': 'Prepared Freight Bill',
    'false': 'Pending Freight Bill',
};
export const PAYMENT_COMPLETED_LABELS = {
    'true': 'Settled Payments',
    'false': 'Unsettled payments',
};
export const DELIVERY_TYPE_LABELS = {
    [ADDRESS_TYPES.DOOR]: 'Door Delivery',
    [ADDRESS_TYPES.GODOWN]: 'GoDown Deliveries',
};
export const VEHICLE_OWNERSHIP_LABELS = {
    'OWNED': 'Owned',
    'HIRED': 'Hired',
};
export const VEHICLE_OWNERSHIP_TYPE = {
    OWNED: 'OWNED',
    HIRED: 'HIRED',
};
export const CONSIGNMENT_NATURES = {
    PTL: 'PTL',
    FTL: 'FTL',
    LTL: 'LTL',
};
export const CARGO_TYPES = {
    NORMAL: 'NORMAL',
    OVERLOAD: 'OVERLOAD',
    EXPRESS: 'EXPRESS',
};
export const GST_NATURES = {
    FCM: 'FCM',
    RCM: 'RCM',
    EXEMPTED: 'EXEMPTED',
};
export const GST_NATURES_INTERNATIONAL = {
    INSIDE_COUNTRY: 'INSIDE_COUNTRY',
    OUTSIDE_COUNTRY: 'OUTSIDE_COUNTRY',
};
export const GST_NATURES_INTERNATIONAL_LABELS = {
    INSIDE_COUNTRY: 'Inside Country',
    OUTSIDE_COUNTRY: 'Outside Country',
};
export const SERVICE_TYPE = {
    B2B: 'B2B',
    B2C: 'B2C',
};
export const INSTRUMENT_TYPES = {
    CHEQUE: 'CHEQUE',
    RTGS: 'RTGS',
    NEFT: 'NEFT',
    IMPS: 'IMPS',
};
export const CONSIGNMENT_STATUS = {
    CREATED: 'CREATED',
    // DRAFT: 'DRAFT',
    PARTIALLY_MAPPED_TO_CHALLAN: 'PARTIALLY_MAPPED_TO_CHALLAN',
    MAPPED_TO_CHALLAN: 'MAPPED_TO_CHALLAN',
    // ARRIVAL_COMPLETED: 'ARRIVAL_COMPLETED',
    POD_COMPLETED: 'POD_COMPLETED',
    CANCELLED: 'CANCELLED',
};
export const CONSIGNMENT_STATUS_DRAFT = 'DRAFT';
export const ADVANCE_STATUS = [
    // sets the values of withAdvanceUnpaid flag
    {
        value: 'BOTH',
        label: 'BOTH',
    },
    {
        value: 'PENDING',
        label: 'PENDING',
    },
    {
        value: 'COMPLETED',
        label: 'Completed',
    },
];
export const LEDGER_GROUP_TYPES = {
    CURRENT_ASSETS: 'CURRENT_ASSETS',
    FIXED_ASSETS: 'FIXED_ASSETS',
    INVESTMENTS: 'INVESTMENTS',
    LOANS_AND_ADVANCES: 'LOANS_AND_ADVANCES',
    BANK_ACCOUNTS: 'BANK_ACCOUNTS',
    CASH_IN_HAND: 'CASH_IN_HAND',
    DEPOSITS: 'DEPOSITS',
    STOCK_IN_HAND: 'STOCK_IN_HAND',
    SUNDRY_DEBTORS: 'SUNDRY_DEBTORS',
    TAX_RECEIVABLE: 'TAX_RECEIVABLE',
    DIRECT_EXPENSES: 'DIRECT_EXPENSES',
    INCOME_TAX: 'INCOME_TAX',
    INDIRECT_EXPENSES: 'INDIRECT_EXPENSES',
    PURCHASE_ACCOUNTS: 'PURCHASE_ACCOUNTS',
    DIRECT_INCOMES: 'DIRECT_INCOMES',
    INDIRECT_INCOMES: 'INDIRECT_INCOMES',
    SALES_ACCOUNTS: 'SALES_ACCOUNTS',
    BRANCH_DIVISIONS: 'BRANCH_DIVISIONS',
    CAPITAL_ACCOUNT: 'CAPITAL_ACCOUNT',
    CURRENT_LIABILITIES: 'CURRENT_LIABILITIES',
    LOANS: 'LOANS',
    PROFIT_LOSS_AC: 'PROFIT_LOSS_AC',
    RESERVES_SURPLUS: 'RESERVES_SURPLUS',
    SUSPENSE_AC: 'SUSPENSE_AC',
    MISC_EXPENSES: 'MISC_EXPENSES',
};
export const SERVICE_TYPES = {
    B2B: 'B2B',
    B2C: 'B2C',
};
export const getDiscount = (amount = 0, discount = 0, calculationType = CHARGE_CALCULATION_TYPES.PERCENTAGE, quantity = 0, pieces = 0) => {
    switch (calculationType) {
        case CHARGE_CALCULATION_TYPES.FIXED:
            return discount;
        case CHARGE_CALCULATION_TYPES.PER_UNIT:
            return discount * quantity;
        case CHARGE_CALCULATION_TYPES.PER_PIECES:
            return discount * pieces;
        case CHARGE_CALCULATION_TYPES.PERCENTAGE:
            return amount * discount * 0.01;
        default:
            return 0;
    }
};
export const getCalculatedCharge = (amount = 0, charge = 0, calculationType = CHARGE_CALCULATION_TYPES.PERCENTAGE, quantity = 0, totalPieces = 0) => {
    switch (calculationType) {
        case CHARGE_CALCULATION_TYPES.FIXED:
            return charge;
        case CHARGE_CALCULATION_TYPES.PER_UNIT:
            return charge * quantity;
        case CHARGE_CALCULATION_TYPES.PERCENTAGE:
            return amount * charge * 0.01;
        case CHARGE_CALCULATION_TYPES.PER_PIECES:
            return charge * totalPieces;
        default:
            return 0;
    }
};
export const billsType = {
    SUPPLEMENTARY_NON_LOGISTICS_INVOICE: 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE',
    SUPPLEMENTARY_LOGISTICS_INVOICE: 'SUPPLEMENTARY_LOGISTICS_INVOICE',
    FREIGHT_INVOICE: 'FREIGHT_INVOICE',
    OPERATIONS_INVOICE: 'OPERATIONS_INVOICE',
};
export function getOtherChargesFields() {
    return filter(ADDITIONAL_CHARGE_FIELD_CONFIG, (item) => {
        return item.value !== 'discount';
    });
}
export function calculateFreight(rateOption, chargedQuantity = 0, rate = 0, extras = {}) {
    const { chargedRouteDistance = 0, pieces = 0 } = extras;
    let calculatedFreight = 0;
    switch (rateOption) {
        case RATE_OPTIONS.WEIGHT:
        case RATE_OPTIONS.LENGTH:
        case RATE_OPTIONS.PIECES:
        case RATE_OPTIONS.CHARGE_QTY:
            calculatedFreight = chargedQuantity * rate;
            break;
        case RATE_OPTIONS.PIECES_QTY:
            calculatedFreight = pieces * rate;
            break;
        case RATE_OPTIONS.WEIGHT_KM:
        case RATE_OPTIONS.PIECES_KM:
        case RATE_OPTIONS.CHARGE_QTY_KM:
            calculatedFreight = chargedQuantity * rate * chargedRouteDistance;
            break;
        case RATE_OPTIONS.FIXED:
            calculatedFreight = rate;
            break;
        case RATE_OPTIONS.KM:
            calculatedFreight = chargedRouteDistance * rate;
            break;
        case RATE_OPTIONS.PIECES_QTY_KM:
            calculatedFreight = pieces * rate * chargedRouteDistance;
            break;
    }
    return calculatedFreight;
}
export const LTL_RATE_OPTIONS = [
    RATE_OPTIONS.WEIGHT,
    RATE_OPTIONS.LENGTH,
    RATE_OPTIONS.PIECES,
    RATE_OPTIONS.CHARGE_QTY,
    RATE_OPTIONS.PIECES_QTY,
    RATE_OPTIONS.FIXED,
];
export const adjustmentFactors = {
    FIXED: 'FIXED',
    WEIGHT: 'WEIGHT',
    COUNT: 'COUNT',
    VOLUME: 'VOLUME',
    AREA: 'AREA',
    LENGTH: 'LENGTH',
    KM: 'KM',
    WEIGHT_KM: 'WEIGHT_KM',
    COUNT_KM: 'COUNT_KM',
    VOLUME_KM: 'VOLUME_KM',
    AREA_KM: 'AREA_KM',
    LENGTH_KM: 'LENGTH_KM',
    MANUAL: 'MANUAL',
};
export const extraChargesAdjustmentFactors = {
    FIXED: 'FIXED',
    MANUAL: 'MANUAL',
};
export const CN_FORM_TABS = {
    BASIC_DETAILS: {
        name: '1. Basic Details',
        order: 1,
        key: 'BASIC_DETAILS',
        isRequired: true,
    },
    ADVANCE_DETAILS: {
        name: '2. Advance Details',
        order: 2,
        key: 'ADVANCE_DETAILS',
        isRequired: true,
    },
};
export const CN_FORM_MATERIAL_SELECTION_TYPE = {
    WITH_RATE_CHART: 'WITH_RATE_CHART',
    WITHOUT_RATE_CHART: 'WITHOUT_RATE_CHART',
};
export const getDefaultCNNature = (isEdit, autoChallanType) => {
    if (!isEdit) {
        switch (autoChallanType) {
            case 'PTL':
                return 'PTL';
            case 'FTL':
                return 'FTL';
            case 'FTL_PTL':
                return '';
            case 'NONE':
                return '';
            default:
                return '';
        }
    }
    return '';
};
export const filterAdjustmentFactors = (filteredAdjustmentFactors, unitType) => {
    if (unitType == 'COUNT' || unitType == 'COUNT_KM') {
        filteredAdjustmentFactors = omit(filteredAdjustmentFactors, [
            'WEIGHT',
            'VOLUME',
            'AREA',
            'LENGTH',
            'WEIGHT_KM',
            'VOLUME_KM',
            'AREA_KM',
            'LENGTH_KM',
        ]);
    }
    else if (unitType == 'WEIGHT' || unitType == 'WEIGHT_KM') {
        filteredAdjustmentFactors = omit(filteredAdjustmentFactors, [
            'COUNT',
            'VOLUME',
            'AREA',
            'LENGTH',
            'COUNT_KM',
            'VOLUME_KM',
            'AREA_KM',
            'LENGTH_KM',
        ]);
    }
    else if (unitType == 'LENGTH' || unitType == 'LENGTH_KM') {
        filteredAdjustmentFactors = omit(filteredAdjustmentFactors, [
            'COUNT',
            'VOLUME',
            'AREA',
            'WEIGHT',
            'COUNT_KM',
            'VOLUME_KM',
            'AREA_KM',
            'WEIGHT_KM',
        ]);
    }
    else if (unitType == 'VOLUME' || unitType == 'VOLUME_KM') {
        filteredAdjustmentFactors = omit(filteredAdjustmentFactors, [
            'COUNT',
            'LENGTH',
            'AREA',
            'WEIGHT',
            'COUNT_KM',
            'LENGTH_KM',
            'AREA_KM',
            'WEIGHT_KM',
        ]);
    }
    else if (unitType == 'AREA' || unitType == 'AREA_KM') {
        filteredAdjustmentFactors = omit(filteredAdjustmentFactors, [
            'COUNT',
            'LENGTH',
            'VOLUME',
            'WEIGHT',
            'COUNT_KM',
            'LENGTH_KM',
            'VOLUME_KM',
            'WEIGHT_KM',
        ]);
    }
    else {
    }
    return filteredAdjustmentFactors;
};
export const getBillsTypesOptions = () => {
    return [
        {
            label: 'Freight Invoice',
            value: 'FREIGHT_INVOICE',
        },
        {
            label: 'Supplementary Logistics Invoice',
            value: 'SUPPLEMENTARY_LOGISTICS_INVOICE',
        },
        {
            label: 'Supplementary Non Logistics Invoice',
            value: 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE',
        },
    ];
};
export const getServiceTypeOptions = () => {
    return [
        {
            label: 'B2B',
            value: 'B2B',
        },
        {
            label: 'B2C',
            value: 'B2C',
        },
    ];
};
export const getPaymentStatusOptions = () => {
    return [
        {
            label: 'Paid',
            value: 'PAID',
        },
        {
            label: 'Unpaid',
            value: 'UNPAID',
        },
        {
            label: 'Partially Paid',
            value: 'PARTIALLY_PAID',
        },
    ];
};
export const BILL_TYPES = {
    FREIGHT_INVOICE: 'FREIGHT_INVOICE',
    SUPPLEMENTARY_LOGISTICS_INVOICE: 'SUPPLEMENTARY_LOGISTICS_INVOICE',
    SUPPLEMENTARY_NON_LOGISTICS_INVOICE: 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE',
    OPERATIONS_INVOICE: 'OPERATIONS_INVOICE',
};
export const SORT_CONSIGNMENTS_OPTIONS = [
    {
        label: 'CN Date wise (desc)',
        value: 'consignmentDate',
    },
    {
        label: 'CN Date wise (asc)',
        value: 'consignmentDateAesc',
    },
    {
        label: 'CN Number wise (asc)',
        value: 'consignmentNumber',
    },
    {
        label: 'Invoice Number wise (asc)',
        value: 'invoiceNumber',
    },
];
export const OTHER_CHARGES_CRITERIA_OPTIONS = [
    {
        label: 'Charged weight sum',
        value: 'CHARGED_WEIGHT_SUM',
    },
    {
        label: 'Actual weight sum',
        value: 'ACTUAL_WEIGHT_SUM',
    },
    {
        label: 'Pieces sum',
        value: 'PIECES_SUM',
    },
    {
        label: 'Invoice Value sum',
        value: 'INVOICE_VALUE_SUM',
    },
    {
        label: 'Basic Freight',
        value: 'BASIC_FREIGHT',
    },
    {
        label: 'Total Freight',
        value: 'TOTAL_FREIGHT',
    },
    {
        label: 'Actual-Charge Qty',
        value: 'ACTUAL_CHARGE_QTY',
    },
];
export const getValueBasedOnCriteria = ({ materials, criteria, chargedRouteDistance }) => {
    switch (criteria) {
        case 'CHARGED_WEIGHT_SUM':
            return sumBy(materials, `chargedQuantity`) || 0;
        case 'ACTUAL_WEIGHT_SUM':
            return sumBy(materials, `actualQuantity`) || 0;
        case 'PIECES_SUM':
            return sumBy(materials, `totalPieces`) || 0;
        case 'INVOICE_VALUE_SUM':
            return sumBy(materials, `invoiceValue`) || 0;
        case 'BASIC_FREIGHT':
            return sumBy(materials, `beforeDiscountFreight`) || 0;
        case 'TOTAL_FREIGHT':
            return sumBy(materials, `freight`) || 0;
        case 'ROUTE_LENGTH':
            return chargedRouteDistance;
        case 'ACTUAL_CHARGE_QTY':
            return sumBy(materials, `actualQuantity`) - sumBy(materials, 'chargedQuantity') || 0;
        default:
            return 0;
    }
};
export const CN_FILTER_TABS = {
    CN_STATUS: 'CN_STATUS',
    FINANCE: 'FINANCE',
    BREAKDOWN: 'BREAKDOWN',
};
export const CN_SUMMARY_GROUPS = {
    FINANCE: {
        ADVANCED_RECEIVED: {
            key: 'withAdvanceUnpaid',
        },
        ADVANCED_PENDING: {
            key: 'withAdvanceUnpaid',
        },
        WITH_FREIGHT_BILL: {
            key: 'withFreightBill',
        },
        PENDING_FREIGHT_BILL: {
            key: 'withFreightBill',
        },
        SETTLED_PAYMENTS: {
            key: 'paymentCompleted',
        },
        UNSETTLED_PAYMENTS: {
            key: 'paymentCompleted',
        },
    },
    BREAKDOWN_1: {
        [ADDRESS_TYPES.DOOR]: {
            key: 'deliveryType',
        },
        [ADDRESS_TYPES.GODOWN]: {
            key: 'deliveryType',
        },
    },
    BREAKDOWN_2: {
        [VEHICLE_OWNERSHIP_TYPE.OWNED]: {
            key: 'vehicleOwnership',
        },
        [VEHICLE_OWNERSHIP_TYPE.HIRED]: {
            key: 'vehicleOwnership',
        },
    },
    BREAKDOWN_3: {
        [CONSIGNMENT_TYPES.TO_PAY]: {
            key: 'consignmentType',
        },
        [CONSIGNMENT_TYPES.BILLED]: {
            key: 'consignmentType',
        },
        [CONSIGNMENT_TYPES.MARKET_MATERIAL]: {
            key: 'consignmentType',
        },
        [CONSIGNMENT_TYPES.PAID]: {
            key: 'consignmentType',
        },
    },
};
export const CN_SUMMARY_SELECT_KEYS_FROM_KEYS = {
    consignmentType: [
        CONSIGNMENT_TYPES.TO_PAY,
        CONSIGNMENT_TYPES.BILLED,
        CONSIGNMENT_TYPES.MARKET_MATERIAL,
        CONSIGNMENT_TYPES.PAID,
    ],
    vehicleOwnership: [VEHICLE_OWNERSHIP_TYPE.OWNED, VEHICLE_OWNERSHIP_TYPE.HIRED],
    deliveryType: [ADDRESS_TYPES.DOOR, ADDRESS_TYPES.GODOWN],
    withFreightBill: ['WITH_FREIGHT_BILL', 'PENDING_FREIGHT_BILL'],
    withAdvanceUnpaid: ['ADVANCED_RECEIVED', 'ADVANCED_PENDING'],
    paymentCompleted: ['SETTLED_PAYMENTS', 'UNSETTLED_PAYMENTS'],
};
export const isTimeRangeAllowedForCN = (startDate, endDate) => {
    let allowedDays = getDaysRangeInFreightBill();
    return [
        getMomentTime(endDate).diff(startDate, 'days') <= allowedDays,
        `Period can not be greater than ${allowedDays} days.Please contact support for longer duration.`,
    ];
};
export const materilUnitMapping = {
    'BAGS': 'BAG',
    'BALE': 'BAL',
    'BUNDLES': 'BDL',
    'BUCKLES': 'BKL',
    'BILLION_OF_UNITS': 'BOU',
    'BOX': 'BOX',
    'BOTTLES': 'BTL',
    'BUNCHES': 'BUN',
    'CANS': 'CAN',
    'CUBIC_METERS': 'CBM',
    'CUBIC_CENTIMETERS': 'CCM',
    'CENTI_METERS': 'CMS',
    'CARTONS': 'CTN',
    'DOZENS': 'DOZ',
    'DRUMS': 'DRM',
    'GREAT GROSS': 'GGK',
    'GRAMMES': 'GMS',
    'GROSS': 'GRS',
    'GROSS_YARDS': 'GYD',
    'KILOGRAMS': 'KGS',
    'KILOLITRE': 'KLR',
    'KILOMETRE': 'KME',
    'LITRES': 'LTR',
    'METERS': 'MTR',
    'MILILITRE': 'MLT',
    'METRIC_TON': 'MTS',
    'NUMBERS': 'NOS',
    'OTHERS': 'OTH',
    'PACKS': 'PAC',
    'PIECES': 'PCS',
    'PAIRS': 'PRS',
    'QUINTAL': 'QTL',
    'ROLLS': 'ROL',
    'SETS': 'SET',
    'SQUARE_FEET': 'SQF',
    'SQUARE_METERS': 'SQM',
    'SQUARE_YARDS': 'SQY',
    'TABLETS': 'TBS',
    'TEN_GROSS': 'TGM',
    'THOUSANDS': 'THD',
    'TONNES': 'TON',
    'TUBES': 'TUB',
    'US_GALLONS': 'UGS',
    'UNITS': 'UNT',
    'YARDS': 'YDS',
    'COUNT': 'UNT',
};
export const QUANTITY_TYPES = {
    PIECES: 'PIECES',
    QUANTITY: 'QUANTITY',
};
