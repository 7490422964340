import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getWhiteLabelLogo, image } from 'constant';
import { Field, Form } from 'formik';
import { FxButton, FxCard, FxSpace, FxTextFieldStackedLabelV2, FxTypography } from 'sharedV2/index';
const ForgotPasswordForm = (props) => {
    const { formikProps, step } = props;
    const hostName = window.location.hostname;
    const whiteLabelLogo = getWhiteLabelLogo(hostName);
    return (_jsx(Form, { onSubmit: formikProps.handleSubmit, children: _jsx(FxCard, { className: "mb-10", style: {
                width: '500px',
                boxShadow: '0px 4px 24px 0px #0000001A',
            }, themeModify: {
                boxShadowTertiary: '0px 4px 24px 0px #0000001A',
            }, children: _jsxs(FxSpace, { direction: "vertical", size: 20, className: "px-3 w-100", children: [_jsxs(FxSpace, { className: "w-100", direction: "vertical", align: "center", size: 20, children: [_jsx("img", { alt: "logo", src: whiteLabelLogo
                                    ? image(whiteLabelLogo)
                                    : image('/public/img/logos/fleetx/fleetx-logo-final-full.svg'), style: {
                                    maxWidth: '125px',
                                } }), _jsx(FxTypography.Title, { level: 4, children: step === 1 ? 'Forgot Password?' : 'Set New Password' }), _jsx(FxTypography.Text, { className: "text-center d-flex justify-content-center", children: step === 1 ? (_jsxs(_Fragment, { children: ["Please enter your email address or mobile number ", _jsx("br", {}), "& we will send a confirmation code."] })) : (_jsxs(_Fragment, { children: ["Please enter the confirmation code that has ", _jsx("br", {}), "been sent to your email or phone number."] })) })] }), _jsxs(FxSpace, { direction: "vertical", size: 20, className: "w-100", children: [step === 1 && (_jsx(Field, { name: "email", placeholder: "Enter email or number", label: "Your email or phone number", component: FxTextFieldStackedLabelV2, size: "large", mb: 0 })), step === 2 && (_jsx(Field, { name: "otp", placeholder: "Enter Verification Code", label: "Verification Code", component: FxTextFieldStackedLabelV2, size: "large", mb: 0 })), step === 2 && (_jsx(Field, { name: "newPassword", placeholder: "Enter New Password", label: "Password", type: "password", component: FxTextFieldStackedLabelV2, size: "large", mb: 0 })), _jsx(FxButton, { className: "w-100", size: "large", type: "primary", color: "success", htmlType: "submit", children: step === 1 ? 'Send OTP' : 'Reset Password' })] })] }) }) }));
};
export default ForgotPasswordForm;
