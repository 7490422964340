import axios, { CancelToken } from 'axios';
import {
    getDeviceTypeObj,
    ROOT_API_ERP,
    parseQueryParams,
    ROOT_API_ALFRED,
    ROOT_API_URL,
    ROOT_API_URL_V2,
    ROOT_NODE_API_URL,
    ROOT_REPORTING_NODE_API_URL,
} from '../../../constant';
import { queryString } from '../../../utils/string_utils';
import {
    DATE_FORMAT_LOCAL_DATE_TIME,
    DATE_FORMAT_TIMESTAMP,
    DATE_FORMAT_TIMESTAMP_DAY,
    getMomentTime,
    MINUTES_1,
} from '../../../utils/date_utils';
import { get, isArray, isEmpty, join } from 'lodash';
import { getBooleanTrueOnlyFlag, getEwayBillDayFilterToFilterParams } from '../../../utils/job_route_utils';
import { isUseLatestJobSummaryAPIEnabled } from 'utils/accountUtils/jobs';
import { FETCH_TMS_DATA_BY_BILL_NUMBER } from 'actions/dashboard/admin_actions';

export const CREATE_JOB = 'create_job';
export const CREATE_MANUAL_ODOMETER_READING = 'create_manual_odometer_reading';
export const FETCH_JOBS = 'fetch_jobs';
export const FETCH_JOBS_CONFIG_LISTING = 'fetch_jobs_config_listing';
export const FETCH_JOBS_SUMMARY = 'fetch_jobs_summary';
export const FETCH_JOB = 'fetch_job';
export const FETCH_JOB_BY_ID = 'fetch_job_by_id';
export const UPDATE_JOB = 'update_job';
export const FETCH_NEAREST_VEHICLE_FOR_DISPATCH = 'fetch_nearest_vehicle_for_dispatch';
export const FETCH_TIME_ON_SITE_ANALYTICS = 'fetch_time_on_site_analytics';
export const FETCH_TIME_ON_SITE_ANALYTICS_TVS = 'fetch_time_on_site_analytics_tvs';
export const FETCH_VEHICLE_ON_SITE_ANALYTICS = 'fetch_vehicle_on_site_analytics';
export const FETCH_VEHICLE_ON_SITE_ANALYTICS_REALTIME = 'fetch_vehicle_on_site_analytics_realtime';
export const FETCH_VEHICLE_ON_SITE_ANALYTICS_SUMMARY = 'fetch_vehicle_on_site_analytics_summary';
export const DELETE_JOB = 'delete_job';
export const CANCEL_JOB = 'cancel_job';
export const CLOSE_JOB = 'close_job';
export const FETCH_JOB_TRIP_BY_VEHICLE = 'fetch_job_trip_by_vehicle';
export const FETCH_FINANCE_BY_JOB_ID = 'fetch_finance_by_job_id';
export const FETCH_VEHICLES_ON_JOB = 'fetch_vehicles_on_job';
export const FETCH_JOBS_FINANCE = 'fetch_jobs_finance';
export const FETCH_JOB_AUDIT_HISTORY = 'fetch_job_audit_history';
export const SANCTION_JOB_AMOUNT = 'sanction_job_amount';
export const END_JOB = 'end_job';
export const FETCH_ASSIGNABLE_VEHICLES = 'fetch_assignable_vehicles';
export const FETCH_LAST_JOBS_ALL_VEHICLES = 'fetch_last_job_all_vehicles';
export const DISPATCH_LR_PRINT = 'dispatch_lr_print';
export const DISPATCH_LR_EMAIL = 'dispatch_lr_email';
export const DISPATCH_LR_EMAIL_FROM_NODE = 'dispatch_lr_email_from_node';
export const FETCH_UNSETTLED_JOBS_AUTO_COMPLETE = 'fetch_unsettled_jobs_auto_complete';
export const FETCH_JOB_BY_TOKEN = 'fetch_job_by_token';
export const FETCH_JOB_BY_TOKEN_V2 = 'fetch_job_by_token_v2';
export const FETCH_JOB_SHARE_URL = 'fetch_job_share_url';
export const FETCH_VEHICLES_LATEST_JOB_BY_TOKEN = 'fetch_vehicles_latest_job_by_token';
export const FETCH_VEHICLE_MERGED_TRIPS_BY_TOKEN = 'fetch_vehicle_merged_trips_by_token';
export const FETCH_VEHICLES_LATEST_JOB_SHARE_URL = 'fetch_vehicles_latest_job_share_url';
export const SHARE_REALTIME_VEHICLE_JOB_EMAIL_MOBILE = 'share_realtime_vehicle_job_email_mobile';
export const FETCH_REAL_TIME_JOB_SHARES = 'fetch_real_time_job_shares';
export const SET_REAL_TIME_JOB_SHARE_INACTIVE = 'set_real_time_job_share_inactive';
export const FETCH_REALTIME_LINKED_SHARED_JOBS = 'fetch_realtime_linked_shared_jobs';
export const FETCH_JOB_IMAGES = 'fetch_job_images';
export const UPDATE_CLOSING_ODOMETER = 'update_closing_odometer';
export const FETCH_JOB_DEVIATIONS = 'fetch_job_deviation';
export const FETCH_EPOD_DATA = 'fetch_epod_data';
export const DELETE_EPOD = 'delete_epod';
export const FETCH_ALL_EPOD = 'fetch_all_epod';
export const FETCH_CUSTOMER_EPOD_ID = 'fetch_customer_epod_id';
export const FETCH_TRANSPORTER_EPOD_ID = 'fetch_transporter_epod_id';
export const UPDATE_EPOD_DETAILS = 'update_epod_details';
export const SUBMIT_EPOD_DETAILS = 'submit_epod_details';
export const UPDATE_EPOD = 'update_epod';
export const UPDATE_EPOD_CUSTOMER = 'update_epod_customer';
export const UPDATE_EPOD_TRANSPORTER = 'update_epod_transporter';
export const UPDATE_EPOD_SHIPPER = 'update_epod_shipper';
export const FETCH_WAITING_JOBS = 'fetch_waiting_jobs';
export const DOWNLOAD_JOB_SUMMARY_REPORT = 'download_job_summary_report';
export const ADD_REMOVE_CONSIGNMENT_FROM_JOB = 'download_job_summary_report';
export const DOWNLOAD_JOB_PERFORMANCE_REPORT = 'download_job_performance_report';
export const DOWNLOAD_LATEST_JOB_REPORT = 'download_latest_job_report';
export const DOWNLOAD_JOB_REPORT = 'download_job_report';
export const DOWNLOAD_JOB_REPORT_CASTROL = 'download_job_report_castrol';
export const DOWNLOAD_JOB_BOOKING_REPORT = 'download_booking_job_report';
export const DOWNLOAD_END_POINT_JOB_REPORT = 'download_end_point_job_report';
export const FETCH_JOB_LIST_NEW = 'fetch_job_list_new';
export const DOWNLOAD_JOB_RISK_REPORT = 'download_job_risk_report';
export const DOWNLOAD_JOBS_NOT_CREATED_REPORT = 'download_jobs_not_created_report';
export const DOWNLOAD_BARCODE_REPORT = 'download_barcode_report';
export const LOST_KPI_REPORT = 'LOST_KPI_REPORT';
export const UPLOAD_IMAGES_BY_CLIENT = 'upload_job_images_by_client';
export const JOB_REPORT = 'job_report';
export const JOB_ALARM_REPORT = 'job_alarm_report';
export const JOB_REPORT_COL_MAPPINGS = 'job_report_col_mapping';
export const FETCH_REPORT_S3_LINK = 'fetch_report_s3_link';
export const SET_EPOD_STATUS = 'set_epod_status';
export const UPLOAD_JOB_RFID_OR_BARCODE_INFO = 'upload_job_rfid_or_barcode_info';
export const BHARTI_CEMENT_JOB_BULKERS_REPORT = 'bharti_cement_job_bulkers_report';
export const DOWNLOAD_JOB_REPORT_BHARATHI = 'download_bharati_job_report';
export const DOWNLOAD_JOB_STOPPAGE_REPORT = 'download_job_stoppage_report';
export const FETCH_DUPLICATE_DRIVER = 'fetch_duplicate_driver';
export const FETCH_JOB_LAST_ODO = 'FETCH_JOB_LAST_ODO';
export const DOWNLOAD_MIS_REPORT = 'download_mis_report';
export const CREATE_AUTO_JOB = 'create_auto_job';
export const FETCH_AUTO_JOBS = 'fetch_auto_jobs';
export const UPDATE_ADDRESS_CONFIG_BY_ID = 'update_address_config_by_id';
export const UPDATE_AUTO_JOB_CONFIG = 'update_auto_job_config';
export const GET_ADDRESS_CONFIG_BY_ID = 'get_address_config_by_id';
export const ADD_AUTO_JOB_ADDRESS_CONFIG = 'add_auto_job_address_config';
export const DOWNLOAD_MANUAL_JOB_REPORT = 'download_manual_job_report';
export const FETCH_RISK_REPORT = 'fetch_risk_report';
export const CREATE_RISK_REPORT = 'create_risk_report';
export const UPDATE_RISK_REPORT = 'update_risk_report';
export const DELETE_RISK_REPORT = 'delete_risk_report';
export const FETCH_CHECK_LIST_ENTRY = 'fetch_check_list_entry';
export const DELETE_AUTO_JOB = 'delete_auto_job';
export const FETCH_LATEST_JOB = 'fetch_latest_job';
export const FETCH_JOB_STOPPAGES = 'fetch_job_stoppages';
export const FETCH_JOB_RISK_DETAIL = 'fetch_job_risk_detail';
export const DOWNLOAD_GROFERS_MILK_RUN_REPORT = 'DOWNLOAD_GROFERS_MILK_RUN_REPORT';
export const DOWNLOAD_JOB_DETAILS_REPORT_IN_PDF = 'DOWNLOAD_JOB_DETAILS_REPORT_IN_PDF';
export const DOWNLOAD_EMPTY_JOB_KM_REPORT = 'DOWNLOAD_EMPTY_JOB_KM_REPORT';
export const DOWNLOAD_CHANDERIA_MINES_REPORT = 'DOWNLOAD_CHANDERIA_MINES_REPORT';
export const DOWNLOAD_WEIGHMENT_RFID_REPORT = 'DOWNLOAD_WEIGHMENT_RFID_REPORT';
export const DOWNLOAD_WEIGHMENT_TRANSACTION_REPORT = 'DOWNLOAD_WEIGHMENT_TRANSACTION_REPORT';
export const DOWNLOAD_BILLING_REPORT = 'DOWNLOAD_BILLING_REPORT';
export const BHARTI_CEMENT_DETENTION_REPORT = 'BHARTI_CEMENT_DETENTION_REPORT';
export const TRACK_CONSIGNMENT_BY_EWAY = 'TRACK_CONSIGNMENT_BY_EWAY';
export const TRACK_CONSIGNMENT_BY_WAYBILL = 'TRACK_CONSIGNMENT_BY_WAYBILL';
export const EPOD_REPORT_DOWNLOAD = 'EPOD_REPORT_DOWNLOAD';
export const FETCH_JOBS_REPORT = 'FETCH_JOBS_REPORT';
export const DOWNLOAD_JOB_REPORT_WITH_TRIP_DATA = 'DOWNLOAD_JOB_REPORT_WITH_TRIP_DATA';
export const DOWNLOAD_JOB_REPORT_WITH_DISTANCE_TIME = 'DOWNLOAD_JOB_REPORT_WITH_DISTANCE_TIME';
export const DOWNLOAD_JOB_REPORT_WITH_TRIPS_HALT = 'DOWNLOAD_JOB_REPORT_WITH_TRIPS_HALT';
export const JOB_STATISTICS_REPORT = 'JOB_STATISTICS_REPORT';
export const FETCH_VEHICLE_LATEST_JOB = 'FETCH_VEHICLE_LATEST_JOB';
export const FETCH_JOBS_SUMMARY_LATEST = 'fetch_jobs_summary_latest';
export const ADD_CLIENT_CONTRACT_CHART = 'add_client_contract_chart';
export const DOWNLOAD_HORIZONTAL_JOB_REPORT = 'download_horizontal_job_report';
export const DOWNLOAD_JOB_ANOMALY_REPORT = 'DOWNLOAD_JOB_ANOMALY_REPORT';
export const CREATE_TRANS_SHIPMENT_JOB = 'CREATE_TRANS_SHIPMENT_JOB';
export const FETCH_JOB_LIST_NEW_TRANS_SHIPMENT = 'FETCH_JOB_LIST_NEW_TRANS_SHIPMENT';
export const NOT_ON_JOB_REPORT = 'NOT_ON_JOB_REPORT';
export const DOWNLOAD_CUSTOMER_DETENTION_REPORT = 'DOWNLOAD_CUSTOMER_DETENTION_REPORT';
export const FETCH_CONTAINER_TRACKING = 'FETCH_CONTAINER_TRACKING';
export const DOWNLOAD_PAY_AS_YOU_GO_REPORT = 'DOWNLOAD_PAY_AS_YOU_GO_REPORT';
export const DOWNLOAD_ROUTE_SEQUENCE_REPORT = 'DOWNLOAD_ROUTE_SEQUENCE_REPORT';
export const EPOD_PENDING_REPORT = 'EPOD_PENDING_REPORT';

export const FETCH_STOPPAGE_HEATMAP_DATA = 'FETCH_STOPPAGE_HEATMAP_DATA';

export const FETCH_STOPPAGE_ADDRESS_DATA = 'FETCH_STOPPAGE_ADDRESS_DATA';
export const REFRESH_JOB = 'REFRESH_JOB';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const FETCH_TOLL_REPORT = 'FETCH_TOLL_REPORT';
export const FETCH_LIVE_FOR_JOB = 'FETCH_LIVE_FOR_JOB';
export const MARK_UNLOADING_POINT = 'MARK_UNLOADING_POINT';
export const FETCH_ADDRESS_LEVEL_REPORT = 'FETCH_ADDRESS_LEVEL_REPORT';
export const FETCH_VEHICLE_LEVEL_REPORT = 'FETCH_VEHICLE_LEVEL_REPORT';
export const DISABLE_SCANNING = 'DISABLE_SCANNING';
export const JOB_BUDGET_REPORT = 'JOB_BUDGET_REPORT';
export const FETCH_ALL_UNLOADING_POINT = 'FETCH_ALL_UNLOADING_POINT';
export const FETCH_ALL_UNLOADING_POINT_EXCLUDING = 'FETCH_ALL_UNLOADING_POINT_EXCLUDING';
export const CREATE_MULTI_JOB_FUEL_FILL = 'CREATE_MULTI_JOB_FUEL_FILL';

export const UPDATE_JOB_CUSTOM_FIELDS = 'UPDATE_JOB_CUSTOM_FIELDS';
export const CREATE_EWAY_FROM_CN = 'CREATE_EWAY_FROM_CN';

export function updateJobCustomField(accesstoken, customFields) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({}),
    };
    const request = axios.patch(`${ROOT_API_URL}dispatch/custom-fields`, customFields, config);

    return {
        type: UPDATE_JOB_CUSTOM_FIELDS,
        promise: request,
    };
}

// REDO after api creation in consignment action file
export const UPDATE_CONSIGNMENT_JOB_CUSTOM_FIELDS = 'UPDATE_CONSIGNMENT_JOB_CUSTOM_FIELDS';

export function updateConsignmentJobCustomField(accesstoken, customFields) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({}),
    };
    const request = axios.patch(`${ROOT_API_ERP}consignment/custom-fields`, customFields, config);

    return {
        type: UPDATE_CONSIGNMENT_JOB_CUSTOM_FIELDS,
        promise: request,
    };
}

export function createJob(accesstoken, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}dispatch/`, values, config);
    return {
        type: CREATE_JOB,
        promise: request,
    };
}

export function createMutiJobFuelFill(accesstoken, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}dispatch/multi-job/fuel-fill`, values, config);
    return {
        type: CREATE_MULTI_JOB_FUEL_FILL,
        promise: request,
    };
}

export function createManualOdometer(accesstoken, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}meter_entries/`, values, config);
    return {
        type: CREATE_MANUAL_ODOMETER_READING,
        promise: request,
    };
}

export function updateJob(accesstoken, values, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            oldTripUpdate: get(params, 'oldTripUpdate', null),
        }),
    };
    const request = axios.put(`${ROOT_API_URL}dispatch/`, values, config);

    return {
        type: UPDATE_JOB,
        promise: request,
    };
}

export function fetchJobsWithInvoiceType(
    accesstoken,
    vehicle,
    groupId,
    from,
    to,
    sortByDateAsc,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    selectedCustomerId,
    invoiceType,
    page = 1,
    size = 10000,
    rfidMismatch,
    sourceFlag,
    destinationFlag,
    riskLevel,
    isEdit = false
) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchJobs';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {}),
        params: parseQueryParams({
            ...(invoiceType === 'FREIGHT_INVOICE' && !isEdit && { freightInvoice: false }),
            ...(invoiceType === 'DETENTION_INVOICE' && !isEdit && { detentionInvoice: false }),
        }),
    };

    const query = vehicle ? `&vehicleId=${vehicle}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const toQuery = to ? `&to=${to}` : '';
    const sortQuery = sortByDateAsc ? `&sort=startDate,asc` : '&sort=startDate,desc';
    const currentStatusFilterQuery = currentStatusFilter ? `&status=${currentStatusFilter}` : '';
    const routeIdFilterQuery = routeIdFilter ? `&routeId=${routeIdFilter}` : '';
    const pointStatusFilterQuery = pointStatusFilter ? `&pointStatus=${pointStatusFilter}` : '';
    const notesFilterQuery = notesFilter ? `&notes=${encodeURIComponent(notesFilter)}` : '';
    const onlyPointsFilterQuery = onlyPointsFilter ? `&onlyPoints=${onlyPointsFilter}` : '';
    const selectedCustomerIdQuery = selectedCustomerId ? `&customerId=${selectedCustomerId}` : '';
    const rfidMismatchQuery = rfidMismatch ? `&rfidMismatch=${rfidMismatch}` : '';
    const sourceFlagQuery = sourceFlag ? `&sourceFlag=${sourceFlag}` : '';
    const destinationFlagQuery = destinationFlag ? `&destinationFlag=${destinationFlag}` : '';
    const riskLevelQuery = riskLevel ? `&riskLevel=${riskLevel}` : '';

    const request = axios.get(
        `${ROOT_API_URL}dispatch/?from=${from}${toQuery}${query}${groupQuery}&page=${
            page - 1
        }&size=${size}${sortQuery}${currentStatusFilterQuery}${pointStatusFilterQuery}${routeIdFilterQuery}${notesFilterQuery}${onlyPointsFilterQuery}${selectedCustomerIdQuery}${rfidMismatchQuery}${sourceFlagQuery}${destinationFlagQuery}${riskLevelQuery}`,
        config
    );

    return {
        type: FETCH_JOBS,
        promise: request,
    };
}

export function fetchJobs(
    accesstoken,
    vehicle,
    groupId,
    from,
    to,
    sortByDateAsc,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    selectedCustomerId,
    page = 1,
    size = 10000,
    rfidMismatch,
    sourceFlag,
    destinationFlag,
    riskLevel,
    distanceBetweenScheduledAndUnloadingPoint,
    includePastRunning,
    includeEndsBetween,
    manualEntry,
    ewayExpired,
    epodUploaded,
    byCreatedDate,
    weighmentJobs,
    byEndDate,
    thresholdTimeSpent,
    geofenceStatus,
    powerDisconnectRiskyPoints,
    updatedScheduledDestinationAddress,
    slabFields
) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchJobs';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            distanceBetweenScheduledAndUnloadingPoint,
            includePastRunning,
            includeEndsBetween,
            manualEntry,
            ewayExpired,
            epodUploaded,
            byCreatedDate,
            weighmentJobs,
            byEndDate,
            thresholdTimeSpent,
            geofenceStatus,
            powerDisconnectRiskyPoints,
            updatedScheduledDestinationAddress,
            slabFields,
        }),
    };

    const query = vehicle ? `&vehicleId=${vehicle}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const toQuery = to ? `&to=${to}` : '';
    const sortQuery = sortByDateAsc ? `&sort=startDate,asc` : '&sort=startDate,desc';
    const currentStatusFilterQuery = currentStatusFilter ? `&status=${currentStatusFilter}` : '';
    const routeIdFilterQuery = routeIdFilter ? `&routeId=${routeIdFilter}` : '';
    const pointStatusFilterQuery = pointStatusFilter ? `&pointStatus=${pointStatusFilter}` : '';
    const notesFilterQuery = notesFilter ? `&notes=${encodeURIComponent(notesFilter)}` : '';
    const onlyPointsFilterQuery = onlyPointsFilter ? `&onlyPoints=${onlyPointsFilter}` : '';
    const selectedCustomerIdQuery = selectedCustomerId ? `&customerId=${selectedCustomerId}` : '';
    const rfidMismatchQuery = rfidMismatch ? `&rfidMismatch=${rfidMismatch}` : '';
    const sourceFlagQuery = sourceFlag ? `&sourceFlag=${sourceFlag}` : '';
    const destinationFlagQuery = destinationFlag ? `&destinationFlag=${destinationFlag}` : '';
    const riskLevelQuery = riskLevel ? `&riskLevel=${riskLevel}` : '';

    const request = axios.get(
        `${ROOT_API_URL}dispatch/?from=${from}${toQuery}${query}${groupQuery}&page=${
            page - 1
        }&size=${size}${sortQuery}${currentStatusFilterQuery}${pointStatusFilterQuery}${routeIdFilterQuery}${notesFilterQuery}${onlyPointsFilterQuery}${selectedCustomerIdQuery}${rfidMismatchQuery}${sourceFlagQuery}${destinationFlagQuery}${riskLevelQuery}`,
        config
    );

    return {
        type: FETCH_JOBS,
        promise: request,
    };
}

export function fetchJobsV2(accesstoken, params) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchJobs';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const {
        vehicleId,
        groupId,
        from,
        to,
        sortByDateAsc,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        selectedCustomerId,
        page = 1,
        size = 10000,
        rfidMismatch,
        sourceFlag,
        destinationFlag,
        riskLevel,
        distanceBetweenScheduledAndUnloadingPoint,
        includePastRunning,
        includeEndsBetween,
        manualEntry,
        ewayExpired,
        epodUploaded,
        byCreatedDate,
        weighmentJobs,
        byEndDate,
        thresholdTimeSpent,
        geofenceStatus,
        powerDisconnectRiskyPoints,
        updatedScheduledDestinationAddress,
        slabFields,
    } = params;

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            distanceBetweenScheduledAndUnloadingPoint,
            includePastRunning,
            includeEndsBetween,
            manualEntry,
            ewayExpired,
            epodUploaded,
            byCreatedDate,
            weighmentJobs,
            byEndDate,
            thresholdTimeSpent,
            geofenceStatus,
            powerDisconnectRiskyPoints,
            updatedScheduledDestinationAddress,
            slabFields,
        }),
    };

    const query = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const toQuery = to ? `&to=${to}` : '';
    const sortQuery = sortByDateAsc ? `&sort=startDate,asc` : '&sort=startDate,desc';
    const currentStatusFilterQuery = currentStatusFilter ? `&status=${currentStatusFilter}` : '';
    const routeIdFilterQuery = routeIdFilter ? `&routeId=${routeIdFilter}` : '';
    const pointStatusFilterQuery = pointStatusFilter ? `&pointStatus=${pointStatusFilter}` : '';
    const notesFilterQuery = notesFilter ? `&notes=${encodeURIComponent(notesFilter)}` : '';
    const onlyPointsFilterQuery = onlyPointsFilter ? `&onlyPoints=${onlyPointsFilter}` : '';
    const selectedCustomerIdQuery = selectedCustomerId ? `&customerId=${selectedCustomerId}` : '';
    const rfidMismatchQuery = rfidMismatch ? `&rfidMismatch=${rfidMismatch}` : '';
    const sourceFlagQuery = sourceFlag ? `&sourceFlag=${sourceFlag}` : '';
    const destinationFlagQuery = destinationFlag ? `&destinationFlag=${destinationFlag}` : '';
    const riskLevelQuery = riskLevel ? `&riskLevel=${riskLevel}` : '';

    const request = axios.get(
        `${ROOT_API_URL}dispatch/?from=${from}${toQuery}${query}${groupQuery}&page=${
            page - 1
        }&size=${size}${sortQuery}${currentStatusFilterQuery}${pointStatusFilterQuery}${routeIdFilterQuery}${notesFilterQuery}${onlyPointsFilterQuery}${selectedCustomerIdQuery}${rfidMismatchQuery}${sourceFlagQuery}${destinationFlagQuery}${riskLevelQuery}`,
        config
    );

    return {
        type: FETCH_JOBS,
        promise: request,
    };
}

export function fetchSlabListing(accesstoken) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_job_config_listing';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            size: 500,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}slab/listing/`, config);

    return {
        type: FETCH_JOBS_CONFIG_LISTING,
        promise: request,
    };
}

export function fetchJobsReport(
    accesstoken,
    vehicle,
    groupId,
    from,
    to,
    sortByDateAsc,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    selectedCustomerId,
    page,
    size,
    rfidMismatch,
    sourceFlag,
    destinationFlag,
    riskLevel,
    isExpenseData = true,
    includeCompleteDuring,
    weighmentJob,
    movementType,
    otherParams = {}
) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchJobs';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const {
        clientContractChartJob = false,
        jobGroupId,
        currentStatus,
        includePastRunning,
        includeEndsBetween,
        minUnloadingTime,
        maxUnloadingTime,
        minDeviationKm,
        maxDeviationKm,
        minLoadingTime,
        maxLoadingTime,
        minDelay,
        maxDelay,
        manualEntry,
        ewayExpired,
        epodUploaded,
        vehicleTagIds,
        driverId,
        invoiceNumber,
        byCreatedDate,
        orderNumber,
        stationaryNumber,
        name,
        filterEpodAcceptedJobs,
        consigneeId,
        trackingValue,
        freightInvoice,
        detentionInvoice,
        isRouteDeviationCount,
    } = otherParams;

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            // freightInvoice: freightInvoiceFilter === 1 ? true : freightInvoiceFilter === 0 ? false : null,
            // detentionInvoice: detentionInvoiceFilter === 1 ? true : detentionInvoiceFilter === 0 ? false : null,
            clientContractChartJob,
            jobGroupId,
            currentStatus,
            includePastRunning,
            includeEndsBetween,
            minUnloadingTime,
            maxUnloadingTime,
            minDeviationKm,
            maxDeviationKm,
            minLoadingTime,
            maxLoadingTime,
            minDelay,
            maxDelay,
            manualEntry,
            ewayExpired,
            epodUploaded,
            vehicleTagIds,
            driverId,
            invoiceNumber,
            byCreatedDate,
            orderNumber,
            stationaryNumber,
            name,
            weighmentJob,
            filterEpodAcceptedJobs,
            movementType,
            consigneeId,
            trackingValue,
            freightInvoice,
            detentionInvoice,
            isRouteDeviationCount,
        }),
    };

    const query = vehicle ? `&vehicleId=${vehicle}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const toQuery = to ? `&to=${to}` : '';
    const sortQuery = sortByDateAsc ? `&sort=startDate,asc` : '&sort=startDate,desc';
    const currentStatusFilterQuery = currentStatusFilter ? `&status=${currentStatusFilter}` : '';
    const routeIdFilterQuery = routeIdFilter ? `&routeId=${routeIdFilter}` : '';
    const pointStatusFilterQuery = pointStatusFilter ? `&pointStatus=${pointStatusFilter}` : '';
    const notesFilterQuery = notesFilter ? `&notes=${encodeURIComponent(notesFilter)}` : '';
    const onlyPointsFilterQuery = onlyPointsFilter ? `&onlyPoints=${onlyPointsFilter}` : '';
    const selectedCustomerIdQuery = selectedCustomerId ? `&customerId=${selectedCustomerId}` : '';
    const rfidMismatchQuery = rfidMismatch ? `&rfidMismatch=${rfidMismatch}` : '';
    const sourceFlagQuery = sourceFlag ? `&sourceFlag=${sourceFlag}` : '';
    const destinationFlagQuery = destinationFlag ? `&destinationFlag=${destinationFlag}` : '';
    const riskLevelQuery = riskLevel ? `&riskLevel=${riskLevel}` : '';
    const expenseDataQuery = isExpenseData != null ? `&isExpenseData=${isExpenseData}` : '';

    const request = axios.get(
        `${ROOT_API_URL}dispatch/report?from=${from}${toQuery}${query}${groupQuery}&page=${
            page - 1
        }&size=${size}${sortQuery}${currentStatusFilterQuery}${pointStatusFilterQuery}${routeIdFilterQuery}${notesFilterQuery}${onlyPointsFilterQuery}${selectedCustomerIdQuery}${rfidMismatchQuery}${sourceFlagQuery}${destinationFlagQuery}${riskLevelQuery}${expenseDataQuery}`,
        config
    );

    return {
        type: FETCH_JOBS_REPORT,
        promise: request,
    };
}

export function fetchJobsReportV2(params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_report_action_fetchJobs';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const {
        accesstoken,
        vehicle,
        groupId,
        from,
        to,
        sortByDateAsc,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        selectedCustomerId,
        page,
        size,
        rfidMismatch,
        sourceFlag,
        destinationFlag,
        riskLevel,
        isExpenseData = true,
        includeCompleteDuring,
        weighmentJob,
        movementType,
        clientContractChartJob = false,
        jobGroupId,
        currentStatus,
        includePastRunning,
        includeEndsBetween,
        minUnloadingTime,
        maxUnloadingTime,
        minDeviationKm,
        maxDeviationKm,
        minLoadingTime,
        maxLoadingTime,
        minDelay,
        maxDelay,
        manualEntry,
        ewayExpired,
        epodUploaded,
        vehicleTagIds,
        driverId,
        invoiceNumber,
        byCreatedDate,
        orderNumber,
        stationaryNumber,
        name,
        filterEpodAcceptedJobs,
        consigneeId,
        trackingValue,
        freightInvoice,
        detentionInvoice,
        isRouteDeviationCount,
    } = params;

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            // freightInvoice: freightInvoiceFilter === 1 ? true : freightInvoiceFilter === 0 ? false : null,
            // detentionInvoice: detentionInvoiceFilter === 1 ? true : detentionInvoiceFilter === 0 ? false : null,
            clientContractChartJob,
            jobGroupId,
            currentStatus,
            includePastRunning,
            includeEndsBetween,
            minUnloadingTime,
            maxUnloadingTime,
            minDeviationKm,
            maxDeviationKm,
            minLoadingTime,
            maxLoadingTime,
            minDelay,
            maxDelay,
            manualEntry,
            ewayExpired,
            epodUploaded,
            vehicleTagIds,
            driverId,
            invoiceNumber,
            byCreatedDate,
            orderNumber,
            stationaryNumber,
            name,
            weighmentJob,
            filterEpodAcceptedJobs,
            movementType,
            consigneeId,
            trackingValue,
            freightInvoice,
            detentionInvoice,
            isRouteDeviationCount,
        }),
    };

    const query = vehicle ? `&vehicleId=${vehicle}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const toQuery = to ? `&to=${to}` : '';
    const sortQuery = sortByDateAsc ? `&sort=startDate,asc` : '&sort=startDate,desc';
    const currentStatusFilterQuery = currentStatusFilter ? `&status=${currentStatusFilter}` : '';
    const routeIdFilterQuery = routeIdFilter ? `&routeId=${routeIdFilter}` : '';
    const pointStatusFilterQuery = pointStatusFilter ? `&pointStatus=${pointStatusFilter}` : '';
    const notesFilterQuery = notesFilter ? `&notes=${encodeURIComponent(notesFilter)}` : '';
    const onlyPointsFilterQuery = onlyPointsFilter ? `&onlyPoints=${onlyPointsFilter}` : '';
    const selectedCustomerIdQuery = selectedCustomerId ? `&customerId=${selectedCustomerId}` : '';
    const rfidMismatchQuery = rfidMismatch ? `&rfidMismatch=${rfidMismatch}` : '';
    const sourceFlagQuery = sourceFlag ? `&sourceFlag=${sourceFlag}` : '';
    const destinationFlagQuery = destinationFlag ? `&destinationFlag=${destinationFlag}` : '';
    const riskLevelQuery = riskLevel ? `&riskLevel=${riskLevel}` : '';
    const expenseDataQuery = isExpenseData != null ? `&isExpenseData=${isExpenseData}` : '';

    const request = axios.get(
        `${ROOT_API_URL}dispatch/report?from=${from}${toQuery}${query}${groupQuery}&page=${
            page - 1
        }&size=${size}${sortQuery}${currentStatusFilterQuery}${pointStatusFilterQuery}${routeIdFilterQuery}${notesFilterQuery}${onlyPointsFilterQuery}${selectedCustomerIdQuery}${rfidMismatchQuery}${sourceFlagQuery}${destinationFlagQuery}${riskLevelQuery}${expenseDataQuery}`,
        config
    );

    return {
        type: FETCH_JOBS_REPORT,
        promise: request,
    };
}

export function jobBudgetReportDownload(accesstoken, params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_budget_report';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const {
        vehicleId,
        groupId,
        jobGroupId,
        from,
        to,
        sortByDateAsc,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        selectedCustomerId,
        page = 1,
        size = 10000,
        rfidMismatch,
        sourceFlag,
        destinationFlag,
        includePastRunning,
        includeEndsBetween,
        riskLevel,
        minUnloadingTime,
        maxUnloadingTime,
        minDeviationKm,
        maxDeviationKm,
        minLoadingTime,
        maxLoadingTime,
        minDelay,
        maxDelay,
        jobRunningStatus,
        manualEntry,
        jobBookingId,
        ewayExpiredDayFilter,
        isEwayExpiredFilter,
        epodUploaded = false,
        vehicleTagIds,
        destinationExit,
        deviceType,
        driverId,
        invoiceNumber,
        accountId,
        byCreatedDate,
        orderNumber,
        stationaryNumber,
        zigZagStop,
        byEndDate,
        billSummaryPresent,
        jobName,
        laneIds,
        movementType,
        billNumber,
        weighmentJob,
        filterEpodAcceptedJobs = false,
        freightInvoiceFilter,
        detentionInvoiceFilter,
        clientContractChartJob,
        transporterId,
        segmentValue,
        trackingValue,
        destinationDeviationType,
        clientContractChartId,
        withoutFreightBill,
        firmId,
        clientContractChartIds,
        consigneeId,
        lrNumber,
        jobClosingReason,
        innerGeofenceStatus,
        outerGeofenceStatus,
        isTransShipmentChildJob,
        parentJobId,
        ptl,
        status,
        lastPointAddress,
        lastPointAddressBookId,
        ids,
        containerNumber,
        referenceNumber,
        segment,
        includeTransShipmentChildJob,
        vehicleTagIdsMatchAll,
        tagIds,
    } = params;
    const deviceTypeObj = getDeviceTypeObj(deviceType);

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),

        params: parseQueryParams({
            vehicleId: vehicleId,
            groupId: groupId,
            jobGroupId: jobGroupId,
            from: from ? getMomentTime(from).valueOf() : from,
            to: to ? getMomentTime(to).valueOf() : to,
            sort: sortByDateAsc ? `startDate,asc` : 'startDate,desc',
            status: status ? status : currentStatusFilter,
            delayStatus: jobRunningStatus,
            routeId: routeIdFilter,
            notes: notesFilter,
            onlyPoints: onlyPointsFilter,
            customerId: selectedCustomerId,
            rfidMismatch: rfidMismatch,
            sourceFlag,
            destinationFlag,
            includePastRunning,
            includeEndsBetween,
            riskLevel,
            minUnloadingTime,
            maxUnloadingTime,
            minDeviationKm,
            maxDeviationKm,
            minLoadingTime,
            maxLoadingTime,
            minDelay,
            maxDelay,
            manualEntry,
            jobBookingId,
            ewayExpired: isEwayExpiredFilter,
            ...getEwayBillDayFilterToFilterParams(ewayExpiredDayFilter),
            epodUploaded,
            vehicleTagIds,
            tagIds,
            destinationExit,
            ...deviceTypeObj,
            driverId,
            invoiceNumber,
            accountId,
            byCreatedDate,
            orderNumber,
            stationaryNumber,
            name: jobName,
            ...getBooleanTrueOnlyFlag(zigZagStop, 'zigZagStop'),
            ...getBooleanTrueOnlyFlag(byEndDate, 'byEndDate'),
            billSummaryPresent,
            weighmentJob: weighmentJob,
            filterEpodAcceptedJobs,
            movementType,
            billNumber,
            freightInvoice: freightInvoiceFilter === 1 ? true : freightInvoiceFilter === 0 ? false : null,
            detentionInvoice: detentionInvoiceFilter === 1 ? true : detentionInvoiceFilter === 0 ? false : null,
            clientContractChartJob,
            transporterId: transporterId,
            ...(segmentValue != null && {
                analyticsCustomFieldId: 354,
                analyticsCustomFieldValue: segmentValue,
            }),
            ...(trackingValue != null && {
                customFieldId: 521,
                customFieldValue: trackingValue === 1 ? true : trackingValue === 0 ? false : null,
            }),
            destinationDeviationType,
            clientContractChartId,
            withoutFreightBill,
            firm: firmId,
            clientContractChartIds,
            consigneeId,
            lrNumber,
            laneIds,
            jobClosingReason,
            innerGeofenceStatus,
            outerGeofenceStatus,
            isTransShipmentChildJob,
            parentJobId,
            ptl,
            lastPointAddress,
            lastPointAddressBookId,
            ids,
            containerNumber,
            referenceNumber,
            segment,
            includeTransShipmentChildJob,
            vehicleTagIdsMatchAll,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}reports/all-job-budget`, config);

    return {
        type: JOB_BUDGET_REPORT,
        promise: request,
    };
}

export function fetchJobListNewV2(accesstoken, params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchJobListNew';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const {
        vehicleId,
        groupId,
        jobGroupId,
        from,
        to,
        sortByDateAsc,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        selectedCustomerId,
        page = 1,
        size = 10000,
        rfidMismatch,
        sourceFlag,
        destinationFlag,
        includePastRunning,
        includeEndsBetween,
        riskLevel,
        minUnloadingTime,
        maxUnloadingTime,
        minDeviationKm,
        maxDeviationKm,
        minLoadingTime,
        maxLoadingTime,
        minDelay,
        maxDelay,
        jobRunningStatus,
        manualEntry,
        jobBookingId,
        ewayExpiredDayFilter,
        isEwayExpiredFilter,
        epodUploaded = false,
        vehicleTagIds,
        destinationExit,
        deviceType,
        driverId,
        invoiceNumber,
        accountId,
        byCreatedDate,
        orderNumber,
        stationaryNumber,
        zigZagStop,
        byEndDate,
        billSummaryPresent,
        jobName,
        laneIds,
        movementType,
        billNumber,
        weighmentJob,
        filterEpodAcceptedJobs = false,
        freightInvoiceFilter,
        detentionInvoiceFilter,
        clientContractChartJob,
        transporterId,
        segmentValue,
        trackingValue,
        destinationDeviationType,
        clientContractChartId,
        withoutFreightBill,
        firmId,
        clientContractChartIds,
        consigneeId,
        lrNumber,
        jobClosingReason,
        innerGeofenceStatus,
        outerGeofenceStatus,
        isTransShipmentChildJob,
        parentJobId,
        modifiedRequestType,
        ptl,
        status,
        lastPointAddress,
        lastPointAddressBookId,
        ids,
        containerNumber,
        referenceNumber,
        segment,
        includeTransShipmentChildJob,
        vehicleTagIdsMatchAll,
        tagIds,
        customerId,
        getERPBillData,
    } = params;
    const deviceTypeObj = getDeviceTypeObj(deviceType);

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),

        params: parseQueryParams({
            page: page - 1,
            size: size,
            vehicleId: vehicleId,
            groupId: groupId,
            jobGroupId: jobGroupId,
            from: from ? getMomentTime(from).valueOf() : from,
            to: to ? getMomentTime(to).valueOf() : to,
            sort: sortByDateAsc ? `startDate,asc` : 'startDate,desc',
            status: status ? status : currentStatusFilter,
            delayStatus: jobRunningStatus,
            routeId: routeIdFilter,
            notes: notesFilter,
            onlyPoints: onlyPointsFilter,
            customerId: selectedCustomerId || customerId,
            rfidMismatch: rfidMismatch,
            sourceFlag,
            destinationFlag,
            includePastRunning,
            includeEndsBetween,
            riskLevel,
            minUnloadingTime,
            maxUnloadingTime,
            minDeviationKm,
            maxDeviationKm,
            minLoadingTime,
            maxLoadingTime,
            minDelay,
            maxDelay,
            manualEntry,
            jobBookingId,
            ewayExpired: isEwayExpiredFilter,
            ...getEwayBillDayFilterToFilterParams(ewayExpiredDayFilter),
            epodUploaded,
            vehicleTagIds,
            tagIds,
            destinationExit,
            ...deviceTypeObj,
            driverId,
            invoiceNumber,
            accountId,
            byCreatedDate,
            orderNumber,
            stationaryNumber,
            name: jobName,
            ...getBooleanTrueOnlyFlag(zigZagStop, 'zigZagStop'),
            ...getBooleanTrueOnlyFlag(byEndDate, 'byEndDate'),
            billSummaryPresent,
            weighmentJob: weighmentJob,
            filterEpodAcceptedJobs,
            movementType,
            billNumber,
            freightInvoice: freightInvoiceFilter === 1 ? true : freightInvoiceFilter === 0 ? false : null,
            detentionInvoice: detentionInvoiceFilter === 1 ? true : detentionInvoiceFilter === 0 ? false : null,
            clientContractChartJob,
            transporterId: transporterId,
            ...(segmentValue != null && {
                analyticsCustomFieldId: 354,
                analyticsCustomFieldValue: segmentValue,
            }),
            ...(trackingValue != null && {
                customFieldId: 521,
                customFieldValue: trackingValue === 1 ? true : trackingValue === 0 ? false : null,
            }),
            destinationDeviationType,
            clientContractChartId,
            withoutFreightBill,
            firm: firmId,
            clientContractChartIds,
            consigneeId,
            lrNumber,
            laneIds,
            jobClosingReason,
            innerGeofenceStatus,
            outerGeofenceStatus,
            isTransShipmentChildJob,
            parentJobId,
            ptl,
            lastPointAddress,
            lastPointAddressBookId,
            ids,
            containerNumber,
            referenceNumber,
            segment,
            includeTransShipmentChildJob,
            vehicleTagIdsMatchAll,
            getERPBillData,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/listing`, config);

    return {
        type: !!modifiedRequestType ? modifiedRequestType : FETCH_JOB_LIST_NEW,
        promise: request,
    };
}

export function fetchJobsSummary(
    accesstoken,
    vehicle,
    groupId,
    from,
    to,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    customerId,
    rfidMismatch,
    includePastRunning = true,
    includeEndsBetween,
    riskLevel,
    jobRunningStatus,
    ewayExpiredDayFilter,
    isEwayExpiredFilter,
    vehicleTagIds,
    destinationExit,
    deviceType,
    driverId,
    invoiceNumber,
    byCreatedDate,
    orderNumber,
    stationaryNumber,
    zigZagStop,
    byEndDate,
    movementType,
    weighmentJob,
    freightInvoiceFilter,
    detentionInvoiceFilter,
    transporterId,
    segmentValue,
    trackingValue,
    consigneeId
) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchJobsSummary';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const deviceTypeObj = getDeviceTypeObj(deviceType);
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            ewayExpired: isEwayExpiredFilter,
            ...getEwayBillDayFilterToFilterParams(ewayExpiredDayFilter),
            ...deviceTypeObj,
            driverId,
            invoiceNumber,
            byCreatedDate,
            orderNumber,
            stationaryNumber,
            movementType,
            ...getBooleanTrueOnlyFlag(zigZagStop, 'zigZagStop'),
            ...getBooleanTrueOnlyFlag(byEndDate, 'byEndDate'),
            ...getBooleanTrueOnlyFlag(weighmentJob, 'weighmentJob'),
            freightInvoice: freightInvoiceFilter === 1 ? true : freightInvoiceFilter === 0 ? false : null,
            detentionInvoice: detentionInvoiceFilter === 1 ? true : detentionInvoiceFilter === 0 ? false : null,
            transporterId,
            ...(segmentValue != null && {
                analyticsCustomFieldId: 354,
                analyticsCustomFieldValue: segmentValue,
            }),
            ...(trackingValue != null && {
                customFieldId: 521,
                customFieldValue: trackingValue === 1 ? true : trackingValue === 0 ? false : null,
            }),
            consigneeId,
        }),
    };

    const query = vehicle ? `&vehicleId=${vehicle}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const toQuery = to ? `&to=${to}` : '';
    const currentStatusFilterQuery = currentStatusFilter ? `&status=${currentStatusFilter}` : '';
    const pointStatusFilterQuery = pointStatusFilter ? `&pointStatus=${pointStatusFilter}` : '';
    const routeIdFilterQuery = routeIdFilter ? `&routeId=${routeIdFilter}` : '';
    const notesFilterQuery = notesFilter ? `&notes=${encodeURIComponent(notesFilter)}` : '';
    const onlyPointsFilterQuery = onlyPointsFilter ? `&onlyPoints=${onlyPointsFilter}` : '';
    const customerQuery = customerId ? `&customerId=${customerId}` : '';
    const rfidMismatchQuery = rfidMismatch ? `&rfidMismatch=${rfidMismatch}` : '';
    const includePastRunningQuery = includePastRunning ? `&includePastRunning=true` : `&includePastRunning=false`;
    const includeEndsBetweenQuery = includeEndsBetween ? `&includeEndsBetween=true` : `&includeEndsBetween=false`;
    const includeRiskLevelQuery = riskLevel ? `&riskLevel=${riskLevel}` : '';
    const currentStatusQuery = jobRunningStatus ? `&delayStatus=${jobRunningStatus}` : '';
    const vehicleTagIdsQuery = vehicleTagIds ? `&vehicleTagIds=${vehicleTagIds}` : '';
    const destinationExitQuery = destinationExit ? `&destinationExit=${destinationExit}` : '';
    const movementTypeQuery = movementType ? `&movementType=${movementType}` : '';
    const request = axios.get(
        `${ROOT_API_URL}dispatch/summary?from=${from}${toQuery}${query}${groupQuery}${currentStatusFilterQuery}${pointStatusFilterQuery}${routeIdFilterQuery}${notesFilterQuery}${onlyPointsFilterQuery}${customerQuery}${rfidMismatchQuery}${includePastRunningQuery}${includeEndsBetweenQuery}${includeRiskLevelQuery}${currentStatusQuery}${vehicleTagIdsQuery}${destinationExitQuery}${movementTypeQuery}`,
        config
    );

    return {
        type: FETCH_JOBS_SUMMARY,
        promise: request,
    };
}

export function fetchJobsSummaryV2(accesstoken, params) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchJobsSummary';
    if (!params.isCancelledDisable && window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const {
        vehicleId,
        groupId,
        jobGroupId,
        from,
        to,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        consigneeId,
        rfidMismatch,
        includePastRunning = true,
        includeEndsBetween,
        riskLevel,
        jobRunningStatus,
        ewayExpiredDayFilter,
        isEwayExpiredFilter,
        vehicleTagIds,
        tagIds,
        destinationExit,
        deviceType,
        driverId,
        invoiceNumber,
        byCreatedDate,
        orderNumber,
        stationaryNumber,
        zigZagStop,
        byEndDate,
        movementType,
        billNumber,
        weighmentJob,
        freightInvoiceFilter,
        detentionInvoiceFilter,
        transporterId,
        segmentValue,
        trackingValue,
        destinationDeviationType,
        clientContractChartJob,
        customerId,
        laneIds,
        jobClosingReason,
        innerGeofenceStatus,
        outerGeofenceStatus,
        isTransShipmentChildJob,
        ptl,
        minDeviationKm,
        maxDeviationKm,
        minUnloadingTime,
        maxUnloadingTime,
        minLoadingTime,
        maxLoadingTime,
        lastPointAddress,
        lastPointAddressBookId,
        containerNumber,
        referenceNumber,
        minDelay,
        maxDelay,
        segment,
        vehicleTagIdsMatchAll,
        withoutFreightBill,
    } = params;
    const deviceTypeObj = getDeviceTypeObj(deviceType);
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            ewayExpired: isEwayExpiredFilter,
            ...getEwayBillDayFilterToFilterParams(ewayExpiredDayFilter),
            ...deviceTypeObj,
            driverId,
            invoiceNumber,
            byCreatedDate,
            orderNumber,
            stationaryNumber,
            movementType,
            billNumber,
            ...getBooleanTrueOnlyFlag(zigZagStop, 'zigZagStop'),
            ...getBooleanTrueOnlyFlag(byEndDate, 'byEndDate'),
            ...getBooleanTrueOnlyFlag(weighmentJob, 'weighmentJob'),
            freightInvoice: freightInvoiceFilter === 1 ? true : freightInvoiceFilter === 0 ? false : null,
            detentionInvoice: detentionInvoiceFilter === 1 ? true : detentionInvoiceFilter === 0 ? false : null,
            transporterId,
            ...(segmentValue != null && {
                analyticsCustomFieldId: 354,
                analyticsCustomFieldValue: segmentValue,
            }),
            ...(trackingValue != null && {
                customFieldId: 521,
                customFieldValue: trackingValue === 1 ? true : trackingValue === 0 ? false : null,
            }),
            destinationDeviationType,
            clientContractChartJob,
            customerId,
            laneIds,
            jobClosingReason,
            innerGeofenceStatus,
            outerGeofenceStatus,
            isTransShipmentChildJob,
            ptl,
            minDeviationKm,
            maxDeviationKm,
            minUnloadingTime,
            maxUnloadingTime,
            minLoadingTime,
            maxLoadingTime,
            lastPointAddress,
            lastPointAddressBookId,
            containerNumber,
            referenceNumber,
            minDelay,
            maxDelay,
            segment,
            vehicleTagIdsMatchAll,
            withoutFreightBill,
        }),
    };

    const query = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const jobGroupQuery = jobGroupId && jobGroupId != -1 ? `&jobGroupId=${jobGroupId}` : '';
    const toQuery = to ? `&to=${to}` : '';
    const currentStatusFilterQuery = currentStatusFilter ? `&status=${currentStatusFilter}` : '';
    const pointStatusFilterQuery = pointStatusFilter ? `&pointStatus=${pointStatusFilter}` : '';
    const routeIdFilterQuery = routeIdFilter ? `&routeId=${routeIdFilter}` : '';
    const notesFilterQuery = notesFilter ? `&notes=${encodeURIComponent(notesFilter)}` : '';
    const onlyPointsFilterQuery = onlyPointsFilter ? `&onlyPoints=${onlyPointsFilter}` : '';
    const customerQuery = consigneeId ? `&consigneeId=${consigneeId}` : '';
    const rfidMismatchQuery = rfidMismatch ? `&rfidMismatch=${rfidMismatch}` : '';
    const includePastRunningQuery = includePastRunning ? `&includePastRunning=true` : `&includePastRunning=false`;
    const includeEndsBetweenQuery = includeEndsBetween ? `&includeEndsBetween=true` : `&includeEndsBetween=false`;
    const includeRiskLevelQuery = riskLevel ? `&riskLevel=${riskLevel}` : '';
    const currentStatusQuery = jobRunningStatus ? `&delayStatus=${jobRunningStatus}` : '';
    const vehicleTagIdsQuery = vehicleTagIds ? `&vehicleTagIds=${vehicleTagIds}` : '';
    const tagIdsQuery = !isEmpty(tagIds) ? `&tagIds=${tagIds}` : '';
    const destinationExitQuery = destinationExit ? `&destinationExit=${destinationExit}` : '';
    const movementTypeQuery = movementType ? `&movementType=${movementType}` : '';
    const billNumberQuery = billNumber ? `&billNumber=${billNumber}` : '';

    const endPoint = isUseLatestJobSummaryAPIEnabled()
        ? `${ROOT_API_URL}dispatch/summary/latest`
        : `${ROOT_API_URL}dispatch/summary`;
    const request = axios.get(
        `${endPoint}?from=${from}${toQuery}${query}${groupQuery}${currentStatusFilterQuery}${pointStatusFilterQuery}${routeIdFilterQuery}${notesFilterQuery}${onlyPointsFilterQuery}${customerQuery}${rfidMismatchQuery}${includePastRunningQuery}${includeEndsBetweenQuery}${includeRiskLevelQuery}${currentStatusQuery}${vehicleTagIdsQuery}${tagIdsQuery}${destinationExitQuery}${movementTypeQuery}${billNumberQuery}${jobGroupQuery}`,
        config
    );

    return {
        type: FETCH_JOBS_SUMMARY,
        promise: request,
    };
}

export function fetchJob(id, accesstoken, accountId, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/${id}`, config);

    return {
        type: FETCH_JOB,
        promise: request,
    };
}

export function fetchJobById(id, accesstoken, accountId, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/${id}`, config);

    return {
        type: FETCH_JOB_BY_ID,
        promise: request,
    };
}

export function fetchVehicleLatestJob(accesstoken, vehicleId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        // params: parseQueryParams({
        //     vehicleId,
        // }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/latest-job/${vehicleId}`, config);

    return {
        type: FETCH_VEHICLE_LATEST_JOB,
        promise: request,
    };
}

export function fetchTimeOnSiteAnalytics(accesstoken, groupId, vehicleId, from, to, tagIds = []) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    let vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const tagIdsParams = tagIds.length ? `&tagIds=${tagIds}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    let toQuery = to ? `&to=${getMomentTime(to).valueOf()}` : '';
    const request = axios.get(
        `${ROOT_API_URL}timeonsite/?from=${getMomentTime(
            from
        ).valueOf()}${toQuery}${vehicleQuery}${groupQuery}${tagIdsParams}`,
        config
    );

    return {
        type: FETCH_TIME_ON_SITE_ANALYTICS,
        promise: request,
    };
}

export function fetchStoppageHeatmapData(accesstoken, params = {}) {
    const {
        vehicleId,
        startDate,
        endDate,
        jobStoppageHeatMap,
        riskyStoppageHeatMap,
        groupIds,
        vehicleIds,
        tagIds,
        stoppageDuration,
    } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            jobStoppageHeatMap,
            riskyStoppageHeatMap,
            groupIds: isArray(groupIds) ? join(groupIds) : groupIds,
            vehicleIds: isArray(vehicleIds) ? join(vehicleIds) : vehicleIds,
            tagIds: isArray(tagIds) ? join(tagIds) : tagIds,
            stoppageDuration: stoppageDuration,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}stoppage/heatmap`, config);

    return {
        type: FETCH_STOPPAGE_HEATMAP_DATA,
        promise: request,
    };
}

export function fetchStoppageAddressData(accesstoken, params = {}) {
    const {
        vehicleId,
        startDate,
        endDate,
        jobStoppageHeatMap,
        riskyStoppageHeatMap,
        groupIds,
        vehicleIds,
        tagIds,
        stoppageDuration,
    } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            jobStoppageHeatMap,
            riskyStoppageHeatMap,
            groupIds: isArray(groupIds) ? join(groupIds) : groupIds,
            vehicleIds: isArray(vehicleIds) ? join(vehicleIds) : vehicleIds,
            tagIds: isArray(tagIds) ? join(tagIds) : tagIds,
            stoppageDuration: stoppageDuration,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}stoppage/address`, config);

    return {
        type: FETCH_STOPPAGE_ADDRESS_DATA,
        promise: request,
    };
}

export function fetchDownloadAddressLevelReport(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/stoppage-data`, config);

    return {
        type: FETCH_ADDRESS_LEVEL_REPORT,
        promise: request,
    };
}

export function fetchDownloadVehicleLevelReport(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/stoppage-data/vehicle`, config);

    return {
        type: FETCH_VEHICLE_LEVEL_REPORT,
        promise: request,
    };
}

export function fetchTimeOnSiteAnalyticsTvs(accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}timeonsite/tvs`, config);

    return {
        type: FETCH_TIME_ON_SITE_ANALYTICS_TVS,
        promise: request,
    };
}

export function fetchVehicleOnSiteAnalytics(
    accesstoken,
    groupId,
    vehicleId,
    addressBookId,
    from,
    to,
    category,
    type,
    frequency,
    showIdlePoints,
    tagIds = []
) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            category,
            vehicleId,
            groupId,
            to: to ? getMomentTime(to).valueOf() : null,
            from: getMomentTime(from).valueOf(),
            addressBookId,
            type,
            frequency,
            showIdlePoints,
            tagIds: tagIds.length ? `${tagIds}` : null,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}timeonsite/vehicles`, config);

    return {
        type: FETCH_VEHICLE_ON_SITE_ANALYTICS,
        promise: request,
    };
}

export function fetchVehicleOnSiteAnalyticsReatime(accesstoken, category, groupId, vehicleId) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchVehicleOnSiteAnalyticsReatime';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            category,
            vehicleId,
            groupId,
        }),
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}timeonsite/realtime`, config);

    return {
        type: FETCH_VEHICLE_ON_SITE_ANALYTICS_REALTIME,
        promise: request,
    };
}

export function fetchNearestVehicleForDispatch(accesstoken, latitude, longitude) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(
        `${ROOT_API_URL}analytics/live/dispatch?latitude=${latitude}&longitude=${longitude}`,
        config
    );

    return {
        type: FETCH_NEAREST_VEHICLE_FOR_DISPATCH,
        promise: request,
    };
}

export function getLiveDataForVehicleStatusInJob(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}analytics/live`, config);
    return {
        type: FETCH_LIVE_FOR_JOB,
        promise: request,
    };
}

export function deleteJob(accesstoken, ids) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ids,
        }),
    };
    const request = axios.delete(`${ROOT_API_URL}dispatch/`, config);
    return {
        type: DELETE_JOB,
        promise: request,
    };
}

export function cancelJob(accesstoken, id, reason) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const data = new FormData();
    data.append('jobId', id);
    if (reason) {
        data.append('reason', reason);
    }
    const request = axios.post(`${ROOT_API_URL}dispatch/cancel`, data, config);

    return {
        type: CANCEL_JOB,
        promise: request,
    };
}

export function closeJob(accesstoken, jobId, endDate, openingOdo = null, closingOdo = null) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
            endDate,
            openingOdo,
            closingOdo,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL}dispatch/close`, null, config);

    return {
        type: CLOSE_JOB,
        promise: request,
    };
}

export function fetchJobTripByVehicleId(vehicleId, tripId, accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/trip/${tripId}/?vehicleId=${vehicleId}`, config);
    return {
        type: FETCH_JOB_TRIP_BY_VEHICLE,
        promise: request,
    };
}

export function fetchVehiclesOnJobs(accesstoken, groupId, tagIds) {
    const groupQuery = groupId && groupId != -1 ? `groupId=${groupId}` : '';
    const tagIdQuery = tagIds ? `tagIds=${tagIds}` : '';

    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/vehicles?${groupQuery}${tagIdQuery}`, config);
    return {
        type: FETCH_VEHICLES_ON_JOB,
        promise: request,
    };
}

export function fetchFinanceByJobId(accesstoken, jobId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({}),
    };
    const request = axios.get(`${ROOT_API_URL}finance/job/?jobId=${jobId}`, config);
    return {
        type: FETCH_FINANCE_BY_JOB_ID,
        promise: request,
    };
}

export function fetchJobAuditHistoryById(accesstoken, jobIds, type) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({}),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/job/audit?jobIds=${jobIds}&type=${type}`, config);

    return {
        type: FETCH_JOB_AUDIT_HISTORY,
        promise: request,
    };
}

export function fetchJobsFinance(accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/budgets`, config);
    return {
        type: FETCH_JOBS_FINANCE,
        promise: request,
    };
}

export function sanctionJobAmount(accesstoken, jobId, sanctionAmount) {
    const data = {
        jobId,
        sanctionAmount,
    };
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const request = axios.post(`${ROOT_API_URL}dispatch/payment/sanction`, queryString.stringify(data), config);
    return {
        type: SANCTION_JOB_AMOUNT,
        promise: request,
    };
}

export function endJob(
    accesstoken,
    jobId,
    jobAnalyticsId,
    vehicleId,
    time,
    endTime,
    address,
    lat,
    lon,
    closingOdo,
    addressBookId,
    jobPointStatus,
    distance,
    extraData = {}
) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const data = new FormData();
    const overrideActualDistance = get(extraData, 'overrideActualDistance', false);
    data.append('jobId', jobId);
    if (jobAnalyticsId) {
        data.append('jobAnalyticsId', jobAnalyticsId);
    }
    data.append('vehicleId', vehicleId);
    data.append('time', time);
    if (endTime) {
        data.append('endTime', endTime);
    }
    if (address) {
        data.append('address', address);
    }
    if (lat) {
        data.append('lat', lat);
    }
    if (lon) {
        data.append('lon', lon);
    }
    if (closingOdo) {
        data.append('closingOdo', closingOdo);
    }
    if (addressBookId) {
        data.append('addressBookId', addressBookId);
    }
    if (jobPointStatus) {
        data.append('jobPointStatus', jobPointStatus);
    }
    if (distance) {
        data.append('distance', distance);
    }
    if (overrideActualDistance) {
        data.append('overrideActualDistance', overrideActualDistance);
    }
    const request = axios.post(`${ROOT_API_URL}dispatch/update/job`, data, config);
    return {
        type: END_JOB,
        promise: request,
    };
}

export function fetchAssignableVehicles(accesstoken, groupId, lat, lon, dispatchTime, vehicleSizeId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const latQuery = lat ? `lat=${lat}` : '';
    const lonQuery = lon ? `&lon=${lon}` : '';
    const dispatchTimeQuery = dispatchTime ? `&dispatchTime=${dispatchTime}` : '';
    const vehicleSizeQuery = vehicleSizeId ? `&vehicleSizeId=${vehicleSizeId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}dispatch/assignable/vehicles?${latQuery}${lonQuery}${dispatchTimeQuery}${vehicleSizeQuery}${groupQuery}`,
        config
    );

    return {
        type: FETCH_ASSIGNABLE_VEHICLES,
        promise: request,
    };
}

export function fetchLastJobAllVehicles(accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const request = axios.get(`${ROOT_API_URL}dispatch/latest/byVehicle`, config);

    return {
        type: FETCH_LAST_JOBS_ALL_VEHICLES,
        promise: request,
    };
}

export function dispatchLRPrint(accesstoken, id, analyticsId, type, firmId, indentId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'arraybuffer',
        params: parseQueryParams({
            id: id,
            jobAnalyticsId: analyticsId,
            copyType: type,
            firmId: firmId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/lr/pdf`, config);
    return {
        type: DISPATCH_LR_PRINT,
        promise: request,
    };
}

export function dispatchLREmail(accesstoken, id, analyticsId, type, emails) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'arraybuffer',
        params: parseQueryParams({
            id: id,
            jobAnalyticsId: analyticsId,
            copyType: type,
            emails,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/lr/pdf/notify`, config);
    return {
        type: DISPATCH_LR_EMAIL,
        promise: request,
    };
}

export function dispatchLREmailFromNode(accesstoken, values) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...values,
        }),
    };
    const request = axios.put(`${ROOT_NODE_API_URL}napp/dispatch/lr/pdf/notify`, null, config);
    return {
        type: DISPATCH_LR_EMAIL_FROM_NODE,
        promise: request,
    };
}

export function fetchUnsettledJobsForTheVehicle(accesstoken, vehicleIid) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const request = axios.get(`${ROOT_API_URL}dispatch/autocomplete?vehicleId=${vehicleIid}`, config);

    return {
        type: FETCH_UNSETTLED_JOBS_AUTO_COMPLETE,
        promise: request,
    };
}

export function fetchJobByToken(jobToken) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/share/${jobToken}`, config);
    return {
        type: FETCH_JOB_BY_TOKEN,
        promise: request,
    };
}

export function fetchJobByTokenV2(jobToken) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL_V2}dispatch/share/${jobToken}`, config);
    return {
        type: FETCH_JOB_BY_TOKEN_V2,
        promise: request,
    };
}

export function fetchJobShareUrl(accesstoken, jobId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.post(`${ROOT_API_URL}dispatch/share/url?jobId=${jobId}`, null, config);
    return {
        type: FETCH_JOB_SHARE_URL,
        promise: request,
    };
}

export function createAutoJob(accesstoken, data) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.post(`${ROOT_API_URL}auto-jobs`, data, config);
    return {
        type: CREATE_AUTO_JOB,
        promise: request,
    };
}

export function addAutoJobAddressConfig(accesstoken, data) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.post(`${ROOT_API_URL}auto-jobs/address-config`, data, config);
    return {
        type: ADD_AUTO_JOB_ADDRESS_CONFIG,
        promise: request,
    };
}

export function fetchAutoJobs(accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}auto-jobs`, config);
    return {
        type: FETCH_AUTO_JOBS,
        promise: request,
    };
}

export function updateAutoJobConfig(accesstoken, values, id) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.put(`${ROOT_API_URL}auto-jobs/${id}`, values, config);
    return {
        type: UPDATE_AUTO_JOB_CONFIG,
        promise: request,
    };
}

export function onDeleteAutoConfigRow(accesstoken, id) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.delete(`${ROOT_API_URL}auto-jobs/address-config/${id}`, config);
    return {
        type: DELETE_AUTO_JOB,
        promise: request,
    };
}

export function updateAutoJobAddressConfigById(accesstoken, values, id) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.put(`${ROOT_API_URL}auto-jobs/address-config/${id}`, values, config);
    return {
        type: UPDATE_ADDRESS_CONFIG_BY_ID,
        promise: request,
    };
}

export function getAutoJobAddressConfigById(accesstoken, id) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}auto-jobs/address-config/${id}`, config);
    return {
        type: GET_ADDRESS_CONFIG_BY_ID,
        promise: request,
    };
}

export function fetchVehiclesJobByToken(token) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/vehicles/share/${token}`, config);
    return {
        type: FETCH_VEHICLES_LATEST_JOB_BY_TOKEN,
        promise: request,
    };
}

export function fetchVehicleMergedTripsByToken(token, jobId) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({ jobId }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}dispatch/trips/merged/vehicles/share/${token}`, config);
    return {
        type: FETCH_VEHICLE_MERGED_TRIPS_BY_TOKEN,
        promise: request,
    };
}

export function fetchVehiclesJobShareUrl(
    accesstoken,
    name,
    vehicleIds,
    startDate,
    validityAfterJobEnd,
    jobDetail = false
) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };

    const data = {
        name,
        vehicleIds,
        startDate: getMomentTime(startDate).format(DATE_FORMAT_LOCAL_DATE_TIME),
        validityAfterJobEnd: validityAfterJobEnd,
        jobDetail: jobDetail,
    };

    const request = axios.post(`${ROOT_API_URL}dispatch/vehicles/share/url`, data, config);
    return {
        type: FETCH_VEHICLES_LATEST_JOB_SHARE_URL,
        promise: request,
    };
}

export function shareRealtimeVehicleJobEmailMobile(accesstoken, shareJobLocationId, url, contacts, sendAt) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const data = {
        users: contacts,
        shareJobLocationId,
        url,
    };
    if (sendAt) {
        data.sendAt = getMomentTime(sendAt).format(DATE_FORMAT_TIMESTAMP);
    }
    const request = axios.post(`${ROOT_API_URL}notify/jobs/live`, data, config);
    return {
        type: SHARE_REALTIME_VEHICLE_JOB_EMAIL_MOBILE,
        promise: request,
    };
}

export function fetchRealtimeJobShares(accesstoken, vehicleId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    let url = `${ROOT_API_URL}dispatch/vehicles/shares`;
    if (vehicleId) {
        url += `?vehicleId=${vehicleId}`;
    }
    const request = axios.get(url, config);

    return {
        type: FETCH_REAL_TIME_JOB_SHARES,
        promise: request,
    };
}

export function setRealtimeJobShareInactive(accesstoken, shareId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };

    let url = `${ROOT_API_URL}dispatch/vehicles/share/${shareId}`;
    const request = axios.delete(url, config);

    return {
        type: SET_REAL_TIME_JOB_SHARE_INACTIVE,
        promise: request,
    };
}

export function fetchRealtimeLinkedSharedJobs(accesstoken, jobName, jobStartDate) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobName,
            jobStartDate,
        }),
    };
    let url = `${ROOT_API_URL}dispatch/name`;

    const request = axios.get(url, config);

    return {
        type: FETCH_REALTIME_LINKED_SHARED_JOBS,
        promise: request,
    };
}

export function fetchJobImages(accesstoken, jobId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
        }),
    };
    let url = `${ROOT_API_URL}dispatch/images`;

    const request = axios.get(url, config);

    return {
        type: FETCH_JOB_IMAGES,
        promise: request,
    };
}

export function updateClosingOdometer(accesstoken, jobId, closingOdo) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
            closingOdo,
        }),
    };

    const request = axios.post(`${ROOT_API_URL}dispatch/update/job/closingOdo`, null, config);

    return {
        type: UPDATE_CLOSING_ODOMETER,
        promise: request,
    };
}

export function fetchJobDeviations(accesstoken, jobId, vehicleId, accountId = null) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
            vehicleId,
            accountId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/deviations`, config);

    return {
        type: FETCH_JOB_DEVIATIONS,
        promise: request,
    };
}

export function deleteEPODById(id) {
    const config = {
        params: parseQueryParams({
            id,
        }),
    };

    const request = axios.delete(`${ROOT_API_URL}dispatch/epod-form`, config);

    return {
        type: DELETE_EPOD,
        promise: request,
    };
}

export function fetchEpodData(accesstoken, jobId, epodFilter = {}) {
    const { ownership = null, userStatus = null } = epodFilter;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
            ownership,
            userStatus,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/epod-details`, config);

    return {
        type: FETCH_EPOD_DATA,
        promise: request,
    };
}

export function fetchEpodsShipper(
    accesstoken,
    from,
    to,
    accountId,
    transporterStatus,
    customerStatus,
    shipperTransporterStatus,
    shipperCustomerStatus,
    status,
    customerId,
    transporterId,
    billNumber,
    moreFilters = {}
) {
    const { ptl, tagIds } = moreFilters;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            accountId,
            transporterStatus,
            customerStatus,
            shipperTransporterStatus,
            shipperCustomerStatus,
            status,
            customerId,
            transporterId,
            billNumber,
            ptl,
            tagIds,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/epods/shipper`, config);

    return {
        type: FETCH_ALL_EPOD,
        promise: request,
    };
}

export function fetchEpodsTransporter(
    accesstoken,
    from,
    to,
    accountId,
    transporterStatus,
    shipperTransporterStatus,
    shipperCustomerStatus,
    status,
    customerId,
    transporterId,
    billNumber,
    moreFilters = {}
) {
    const { ptl } = moreFilters;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            accountId,
            transporterStatus,
            shipperTransporterStatus,
            shipperCustomerStatus,
            status,
            customerId,
            transporterId,
            billNumber,
            ptl,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/epods/transporter`, config);

    return {
        type: FETCH_ALL_EPOD,
        promise: request,
    };
}

export function fetchEpodsCustomer(
    accesstoken,
    from,
    to,
    transporterStatus,
    shipperTransporterStatus,
    shipperCustomerStatus,
    status,
    customerId,
    transporterId,
    customerStatus,
    billNumber,
    moreFilters = {}
) {
    const { ptl } = moreFilters;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            transporterStatus,
            shipperTransporterStatus,
            shipperCustomerStatus,
            status,
            customerId,
            transporterId,
            customerStatus,
            billNumber,
            ptl,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/epods/customer`, config);

    return {
        type: FETCH_ALL_EPOD,
        promise: request,
    };
}

export function fetchCustomerEpodById(accesstoken, id, customerId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            customerId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/customer-epod/${id}`, config);

    return {
        type: FETCH_CUSTOMER_EPOD_ID,
        promise: request,
    };
}

export function fetchTransporterEpodById(accesstoken, id, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/transporter-epod/${id}`, config);

    return {
        type: FETCH_TRANSPORTER_EPOD_ID,
        promise: request,
    };
}

export function updateEpod(accesstoken, id, values, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };

    const request = axios.put(`${ROOT_API_URL}dispatch/epod/shipper`, values, config);

    return {
        type: UPDATE_EPOD,
        promise: request,
    };
}

export function updateEpodTransporter(accesstoken, id, values, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };

    const request = axios.put(`${ROOT_API_URL}dispatch/epod/transporter`, values, config);

    return {
        type: UPDATE_EPOD_TRANSPORTER,
        promise: request,
    };
}

export function updateEpodCustomer(accesstoken, id, values, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };

    const request = axios.put(`${ROOT_API_URL}dispatch/epod/customer`, values, config);

    return {
        type: UPDATE_EPOD_CUSTOMER,
        promise: request,
    };
}

export function fetchWaitingJobs(accesstoken, startDate, endDate, waitingType) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            waitingType,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/waiting`, config);

    return {
        type: FETCH_WAITING_JOBS,
        promise: request,
    };
}

export function fetchNodeJobSummaryReport(
    accesstoken,
    reportFormat,
    filterConfig,
    jobSize,
    vehicleId,
    groupId,
    startDate,
    endDate,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    groupName,
    vehicleNumber,
    customerName,
    customerId,
    rfidMismatch,
    includePastRunning,
    includeEndsBetween,
    manualEntry,
    ewayExpired,
    epodUploaded,
    byCreatedDate,
    weighmentJobs
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'arraybuffer',
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            filterConfig,
            jobSize,
            vehicleId,
            groupId,
            startDate,
            endDate,
            currentStatusFilter,
            pointStatusFilter,
            routeIdFilter,
            notesFilter,
            onlyPointsFilter,
            groupName,
            vehicleNumber,
            customerName,
            customerId,
            rfidMismatch,
            includePastRunning,
            includeEndsBetween,
            manualEntry,
            ewayExpired,
            epodUploaded,
            byCreatedDate,
            weighmentJobs,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getDTDCJobSummaryReport`, config);

    return {
        type: DOWNLOAD_JOB_SUMMARY_REPORT,
        promise: request,
    };
}

export function fetchNodeJobPerformanceReport(
    accesstoken,
    reportFormat,
    filterConfig,
    jobSize,
    vehicleId,
    groupId,
    startDate,
    endDate,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    groupName,
    vehicleNumber,
    customerName,
    customerId,
    type
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'arraybuffer',
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            filterConfig,
            jobSize,
            vehicleId,
            groupId,
            startDate,
            endDate,
            currentStatusFilter,
            pointStatusFilter,
            routeIdFilter,
            notesFilter,
            onlyPointsFilter,
            groupName,
            vehicleNumber,
            customerName,
            customerId,
            type,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getDTDCJobPerformanceReport`, config);

    return {
        type: DOWNLOAD_JOB_PERFORMANCE_REPORT,
        promise: request,
    };
}

export function fetchNodeLatestJobReport(
    accesstoken,
    reportFormat,
    filterConfig,
    jobSize,
    vehicleId,
    groupId,
    startDate,
    endDate,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    groupName,
    vehicleNumber,
    customerName,
    customerId,
    type
) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            filterConfig,
            jobSize,
            vehicleId,
            groupId,
            startDate,
            endDate,
            currentStatusFilter,
            pointStatusFilter,
            routeIdFilter,
            notesFilter,
            onlyPointsFilter,
            groupName,
            vehicleNumber,
            customerName,
            customerId,
            type,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getLatestJobReport`, config);

    return {
        type: DOWNLOAD_LATEST_JOB_REPORT,
        promise: request,
    };
}

export function fetchNotOnJobReport(accesstoken, startDate, endDate) {
    let format = {};
    format = { responseType: 'blob' };
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            from: startDate,
            to: endDate,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/not-on-job/report`, config);

    return {
        type: NOT_ON_JOB_REPORT,
        promise: request,
    };
}

export function fetchTollReport(accesstoken, startDate, endDate) {
    let format = {};
    format = { responseType: 'blob' };
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            from: startDate,
            to: endDate,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/detail-toll`, config);

    return {
        type: FETCH_TOLL_REPORT,
        promise: request,
    };
}

export function fetchNodeEndPointJobReport(
    accesstoken,
    loggedInUser,
    reportFormat,
    filterConfig,
    jobSize,
    vehicleId,
    groupId,
    startDate,
    endDate,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    groupName,
    vehicleNumber,
    type
) {
    let format = {};
    if (type === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            filterConfig,
            jobSize,
            vehicleId,
            groupId,
            startDate,
            endDate,
            currentStatusFilter,
            pointStatusFilter,
            routeIdFilter,
            notesFilter,
            onlyPointsFilter,
            groupName,
            vehicleNumber,
            type,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getEndPointJobReport`, config);

    return {
        type: DOWNLOAD_END_POINT_JOB_REPORT,
        promise: request,
    };
}

export function fetchNodeJobReportData(accesstoken, reportFormat, startDate, endDate, plantId) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            startDate,
            endDate,
            plantId,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getJobReport`, config);

    return {
        type: DOWNLOAD_JOB_REPORT,
        promise: request,
    };
}

export function fetchCastrolJobReport(accesstoken, reportFormat, startDate, endDate, groupId, vehicleId, status) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            startDate,
            endDate,
            groupId,
            vehicleId,
            status,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/castrolJobReport`, config);

    return {
        type: DOWNLOAD_JOB_REPORT_CASTROL,
        promise: request,
    };
}

export function fetchNodeJobSpeedReportData(accesstoken, loggedInUser, reportFormat, startDate, endDate) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            startDate,
            endDate,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getJobSpeedReport`, config);

    return {
        type: DOWNLOAD_JOB_REPORT,
        promise: request,
    };
}

export function fetchNodeVehiclePerformanceReport(accesstoken, reportFormat, source, startDate, endDate, plantTagId) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            source,
            startDate,
            endDate,
            plantTagId,
        }),
    };
    //'responseType': 'arraybuffer',
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getVehiclePerformanceReport`, config);

    return {
        type: DOWNLOAD_JOB_REPORT,
        promise: request,
    };
}

export function fetchNodeJobBookingReport(accesstoken, reportFormat, startDate, endDate) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            reportFormat,
            startDate,
            endDate,
        }),
    };
    //'responseType': 'arraybuffer',
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getJobBookings`, config);

    return {
        type: DOWNLOAD_JOB_BOOKING_REPORT,
        promise: request,
    };
}

export function fetchDownloadableJobRiskReport(
    accesstoken,
    {
        vehicleId,
        groupId,
        startDate,
        endDate,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        customerId,
        rfidMismatch,
        sourceFlag,
        destinationFlag,
        includePastRunning,
        includeCompleteDuring,
        riskLevel,
        minUnloadingTime,
        maxUnloadingTime,
        minDeviationKm,
        maxDeviationKm,
        minLoadingTime,
        maxLoadingTime,
        minDelay,
        maxDelay,
        driverId,
    }
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'arraybuffer',
        params: parseQueryParams({
            vehicleId: vehicleId,
            groupId: groupId,
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            status: currentStatusFilter,
            routeId: routeIdFilter,
            notes: notesFilter,
            onlyPoints: onlyPointsFilter,
            customerId,
            rfidMismatch,
            pointStatus: pointStatusFilter,
            sourceFlag,
            destinationFlag,
            includePastRunning,
            includeEndsBetween: includeCompleteDuring,
            riskLevel,
            minUnloadingTime,
            maxUnloadingTime,
            minDeviationKm,
            maxDeviationKm,
            minLoadingTime,
            maxLoadingTime,
            minDelay,
            maxDelay,
            driverId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/risk`, config);

    return {
        type: DOWNLOAD_JOB_RISK_REPORT,
        promise: request,
    };
}

export function fetchDownloadableJobsNotCreatedReport(accesstoken, { startDate, endDate }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'arraybuffer',
        params: parseQueryParams({
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/job-not-created-report`, config);

    return {
        type: DOWNLOAD_JOBS_NOT_CREATED_REPORT,
        promise: request,
    };
}

export function fetchDownloadableBarcodeReport(accesstoken, { from, to, status, vehicleId, routeId }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
            status,
            vehicleId,
            routeId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/images/tags/report`, config);

    return {
        type: DOWNLOAD_BARCODE_REPORT,
        promise: request,
    };
}

export function fetchDownloadableLossKPIReport(accesstoken, { from, to, status }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/blr/kpi`, config);

    return {
        type: LOST_KPI_REPORT,
        promise: request,
    };
}

export function uploadJobImagesByClient(accesstoken, vehicleId, jobId, images) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            jobId,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}dispatch/client/images`, images, config);

    return {
        type: UPLOAD_IMAGES_BY_CLIENT,
        promise: request,
    };
}

export function triggerJobReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    if (get(options, 'getJobReportFromS3', false)) {
        delete config.responseType;
    }
    const data = parseQueryParams(options);

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerJobReport`, data, config);

    return {
        type: JOB_REPORT,
        promise: request,
    };
}

export function triggerEPODPendingReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    if (get(options, 'getJobReportFromS3', false)) {
        delete config.responseType;
    }
    const data = parseQueryParams(options);

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerEPODPendingReport`, data, config);

    return {
        type: EPOD_PENDING_REPORT,
        promise: request,
    };
}

export function fetchJobReportData(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const data = parseQueryParams({
        ...options,
        dataOnly: true,
    });

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerJobReport`, data, config);

    return {
        type: JOB_REPORT,
        promise: request,
    };
}

export function triggerJobAlarmsReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const data = parseQueryParams(options);

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerJobAlarmReport`, data, config);

    return {
        type: JOB_ALARM_REPORT,
        promise: request,
    };
}

export function fetchJobReportColMapping(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({ accountId }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getjobreportcolmapping`, config);

    return {
        type: JOB_REPORT_COL_MAPPINGS,
        promise: request,
    };
}

export function setEPODStatus(accesstoken, jobAnalyticsId, status, reason) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobAnalyticsId,
            status,
            reason,
        }),
    };

    const request = axios.put(`${ROOT_API_URL}dispatch/epods/status`, null, config);

    return {
        type: SET_EPOD_STATUS,
        promise: request,
    };
}

export function updateEpodDetail(accesstoken, jobAnalyticsId, status, reason) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobAnalyticsId,
            status,
            reason,
        }),
    };

    const request = axios.put(`${ROOT_API_URL}dispatch/epods`, null, config);

    return {
        type: UPDATE_EPOD_DETAILS,
        promise: request,
    };
}

export function submitEpodDetail(accesstoken, data, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };

    const request = axios.post(`${ROOT_API_URL}dispatch/epods`, data, config);

    return {
        type: SUBMIT_EPOD_DETAILS,
        promise: request,
    };
}

export function fetchReportS3Link(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };

    const request = axios.post(`${ROOT_NODE_API_URL}napp/S3/fetchS3FileLink/`, data, config);

    return {
        type: FETCH_REPORT_S3_LINK,
        promise: request,
    };
}

export function fetchVehicleOnSiteAnalyticsSummary(accesstoken, from, to, addressBookIds) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            addressBookIds,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}timeonsite/summary`, config);

    return {
        type: FETCH_VEHICLE_ON_SITE_ANALYTICS_SUMMARY,
        promise: request,
    };
}

export function uploadJobRFIDOrBarcodeInfo(accesstoken, data, { vehicleId, jobId }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({ vehicleId, jobId }),
    };

    const request = axios.post(`${ROOT_API_URL}dispatch/client/images`, data, config);

    return {
        type: UPLOAD_JOB_RFID_OR_BARCODE_INFO,
        promise: request,
    };
}

export function downloadBhartiCementDententionReport(
    accesstoken,
    {
        vehicleId,
        groupId,
        startDate,
        endDate,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        customerId,
        rfidMismatch,
        sourceFlag,
        destinationFlag,
        includePastRunning,
        includeCompleteDuring,
        riskLevel,
        minUnloadingTime,
        maxUnloadingTime,
        minDeviationKm,
        maxDeviationKm,
        minLoadingTime,
        maxLoadingTime,
        minDelay,
        maxDelay,
        driverId,
        exceptionReport,
    }
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            vehicleId: vehicleId,
            groupId: groupId,
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            status: currentStatusFilter,
            routeId: routeIdFilter,
            notes: notesFilter,
            onlyPoints: onlyPointsFilter,
            customerId,
            rfidMismatch,
            pointStatus: pointStatusFilter,
            sourceFlag,
            destinationFlag,
            includePastRunning,
            includeEndsBetween: includeCompleteDuring,
            riskLevel,
            minUnloadingTime,
            maxUnloadingTime,
            minDeviationKm,
            maxDeviationKm,
            minLoadingTime,
            maxLoadingTime,
            minDelay,
            maxDelay,
            driverId,
            exceptionReport,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}reports/bharathi-bulker-report`, config);

    return {
        type: BHARTI_CEMENT_DETENTION_REPORT,
        promise: request,
    };
}

export function downloadBhartiCementJobBulkersReport(
    accesstoken,
    {
        vehicleId,
        groupId,
        startDate,
        endDate,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        customerId,
        rfidMismatch,
        sourceFlag,
        destinationFlag,
        includePastRunning,
        includeCompleteDuring,
        riskLevel,
        minUnloadingTime,
        maxUnloadingTime,
        minDeviationKm,
        maxDeviationKm,
        minLoadingTime,
        maxLoadingTime,
        minDelay,
        maxDelay,
        driverId,
        exceptionReport,
    }
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            vehicleId: vehicleId,
            groupId: groupId,
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            status: currentStatusFilter,
            routeId: routeIdFilter,
            notes: notesFilter,
            onlyPoints: onlyPointsFilter,
            customerId,
            rfidMismatch,
            pointStatus: pointStatusFilter,
            sourceFlag,
            destinationFlag,
            includePastRunning,
            includeEndsBetween: includeCompleteDuring,
            riskLevel,
            minUnloadingTime,
            maxUnloadingTime,
            minDeviationKm,
            maxDeviationKm,
            minLoadingTime,
            maxLoadingTime,
            minDelay,
            maxDelay,
            driverId,
            exceptionReport,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}/tp/dispatch/bharathi-bulkers/report`, config);

    return {
        type: BHARTI_CEMENT_JOB_BULKERS_REPORT,
        promise: request,
    };
}

export function fetchBharathiJobReport(
    accesstoken,
    reportFormat,
    startDate,
    endDate,
    groupId,
    vehicleId,
    status,
    exceptionReport = false
) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            startDate,
            endDate,
            groupId,
            vehicleId,
            status,
            exceptionReport,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/bharathi`, config);

    return {
        type: DOWNLOAD_JOB_REPORT_BHARATHI,
        promise: request,
    };
}

export function fetchManualJobReport(
    accesstoken,
    reportFormat,
    startDate,
    endDate,
    groupId,
    vehicleId,
    status,
    exceptionReport = false
) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            startDate,
            endDate,
            groupId,
            vehicleId,
            status,
            exceptionReport,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/manual`, config);

    return {
        type: DOWNLOAD_MANUAL_JOB_REPORT,
        promise: request,
    };
}

export function fetchJobStoppageReport(accesstoken, params) {
    const { from, to, vehicleId, stoppageDuration, actualDepartureStartTime, unloadingPointEndTime } = params;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
            vehicleId,
            stoppageDuration: stoppageDuration * MINUTES_1,
            actualDepartureStartTime: actualDepartureStartTime,
            unloadingPointEndTime: unloadingPointEndTime,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/stoppage/report`, config);

    return {
        type: DOWNLOAD_JOB_STOPPAGE_REPORT,
        promise: request,
    };
}

export function fetchDuplicateDriver(accesstoken, driverId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            enabled: true,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}users/driver/${driverId}`, config);

    return {
        type: FETCH_DUPLICATE_DRIVER,
        promise: request,
    };
}

export function fetchJobLastOdo(accesstoken, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/last-odo`, config);

    return {
        type: FETCH_JOB_LAST_ODO,
        promise: request,
    };
}

export function fetchMISReport(accesstoken, { from, to, vehicleId }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/download/mis/report`, config);

    return {
        type: DOWNLOAD_MIS_REPORT,
        promise: request,
    };
}

export function fetchRiskReport(accesstoken, id) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}risk-report-config/${id ? id : ''}`, config);

    return {
        type: FETCH_RISK_REPORT,
        promise: request,
    };
}

export function createRiskReport(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        'Content-Type': 'application/json',
    };
    const request = axios.post(`${ROOT_API_URL}risk-report-config/`, data, config);

    return {
        type: CREATE_RISK_REPORT,
        promise: request,
    };
}

export function updateRiskReport(accesstoken, id, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        'Content-Type': 'application/json',
    };
    const request = axios.put(`${ROOT_API_URL}risk-report-config/${id}`, data, config);

    return {
        type: UPDATE_RISK_REPORT,
        promise: request,
    };
}

export function deleteRiskReport(accesstoken, id) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}risk-report-config/${id}`, config);

    return {
        type: DELETE_RISK_REPORT,
        promise: request,
    };
}

export function fetchCheckListEntryListing(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}check-list-entry/listing`, config);

    return {
        type: FETCH_CHECK_LIST_ENTRY,
        promise: request,
    };
}

export function fetchLatestJob(accesstoken, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/latest-job/${vehicleId}`, config);

    return {
        type: FETCH_LATEST_JOB,
        promise: request,
    };
}

export function fetchJobStoppage(accesstoken, from, to, jobId, trackingMode, timeDurationThreshold, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            jobId,
            trackingMode,
            timeDurationThreshold,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/stoppages`, config);

    return {
        type: FETCH_JOB_STOPPAGES,
        promise: request,
    };
}

export function fetchJobRiskDetail(accesstoken, jobId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/risk-detail`, config);

    return {
        type: FETCH_JOB_RISK_DETAIL,
        promise: request,
    };
}

export function fetchDownloadableGrofersMilkRunReport(accesstoken, { from, to, vehicleId, status, routeId }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleId,
            status,
            routeId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/grofers/milk-run`, config);

    return {
        type: DOWNLOAD_GROFERS_MILK_RUN_REPORT,
        promise: request,
    };
}

export function fetchDownloadableWeighmentTransactionReport(accesstoken, { from, to }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).format('YYYY-MM-DD'),
            to: getMomentTime(to).format('YYYY-MM-DD'),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/weighment/transaction`, config);

    return {
        type: DOWNLOAD_WEIGHMENT_TRANSACTION_REPORT,
        promise: request,
    };
}

export function fetchDownloadableWeighmentRFIDReport(accesstoken, { from, to }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).format('YYYY-MM-DD'),
            to: getMomentTime(to).format('YYYY-MM-DD'),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/weighment/rfid`, config);

    return {
        type: DOWNLOAD_WEIGHMENT_RFID_REPORT,
        promise: request,
    };
}

export function fetchDownloadableChanderiaMinesReport(accesstoken, { from, to, vehicleId, groupId }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleId,
            groupId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/chanderia-mines`, config);

    return {
        type: DOWNLOAD_CHANDERIA_MINES_REPORT,
        promise: request,
    };
}

export function fetchDownloadableBillingReport(accesstoken, { from, to, vehicleId, groupId }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).format(DATE_FORMAT_TIMESTAMP_DAY),
            to: getMomentTime(to).format(DATE_FORMAT_TIMESTAMP_DAY),
            vehicleId,
            groupId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/birla/billing`, config);

    return {
        type: DOWNLOAD_BILLING_REPORT,
        promise: request,
    };
}

export function fetchDownloadableJobDetailsReportInPDF(accesstoken, jobId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            jobId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/job-report/pdf`, config);

    return {
        type: DOWNLOAD_JOB_DETAILS_REPORT_IN_PDF,
        promise: request,
    };
}

export function fetchDownloadableEmptyJobKMReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.post(
        `${ROOT_REPORTING_NODE_API_URL}reports/triggerEmptyJobKmReport`,
        parseQueryParams(options),
        config
    );

    return {
        type: DOWNLOAD_EMPTY_JOB_KM_REPORT,
        promise: request,
    };
}

export function fetchDownloadableJobReportWithTripData(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(options),
    };
    const request = axios.get(`${ROOT_API_URL}reports/express-roadways/trip-summary`, config);

    return {
        type: DOWNLOAD_JOB_REPORT_WITH_TRIP_DATA,
        promise: request,
    };
}

export function fetchDownloadableJobReportWithDistanceTime(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(options),
    };
    const request = axios.get(`${ROOT_API_URL}reports/express-roadways/distance-time`, config);

    return {
        type: DOWNLOAD_JOB_REPORT_WITH_DISTANCE_TIME,
        promise: request,
    };
}

export function fetchDownloadableJobReportWithTripsHalt(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(options),
    };
    const request = axios.get(`${ROOT_API_URL}reports/express-roadways/trips-halt`, config);

    return {
        type: DOWNLOAD_JOB_REPORT_WITH_TRIPS_HALT,
        promise: request,
    };
}

export function getConsignmentTrackingDetailsByEwayBill(accesstoken, { consignmentId, gstin, username, password }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            consignmentId,
            gstin,
            username,
            password,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/ewaybill/tracking`, config);

    return {
        type: TRACK_CONSIGNMENT_BY_EWAY,
        promise: request,
    };
}

export function triggerJobStatisticsReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams(options),
    };

    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/jobStatisticsReport`, config);

    return {
        type: JOB_STATISTICS_REPORT,
        promise: request,
    };
}

export function getConsignmentWayBillTrackingDetails(accesstoken, { consignmentId }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            consignmentId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/tracking-consignment`, config);

    return {
        type: TRACK_CONSIGNMENT_BY_WAYBILL,
        promise: request,
    };
}

export function downloadEPODReport(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/epod/report`, config);

    return {
        type: EPOD_REPORT_DOWNLOAD,
        promise: request,
    };
}

export function fetchJobsSummaryLatest(
    accesstoken,
    {
        vehicleId,
        groupId,
        from,
        to,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        customerId,
        rfidMismatch,
        includePastRunning = true,
        includeEndsBetween,
        riskLevel,
        ewayExpiredDayFilter,
        isEwayExpiredFilter,
        vehicleTagIds,
        destinationExit,
        deviceType,
        driverId,
        invoiceNumber,
        byCreatedDate,
        delayStatus,
        jobLoadingStatus,
        noEwayBillJobs,
        hideUnloadingJobs,
        movementType,
        minDelay,
        maxDelay,
        consigneeId,
    }
) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_jobs_action_fetchJobsSummaryLatest';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const deviceTypeObj = getDeviceTypeObj(deviceType);
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            vehicleId,
            ewayExpired: isEwayExpiredFilter,
            ...getEwayBillDayFilterToFilterParams(ewayExpiredDayFilter),
            ...deviceTypeObj,
            driverId,
            invoiceNumber,
            byCreatedDate,
            groupId: groupId && groupId !== -1 ? groupId : null,
            to: to ? getMomentTime(to).valueOf() : '',
            from: from ? getMomentTime(from).valueOf() : '',
            status: currentStatusFilter,
            pointStatus: pointStatusFilter,
            routeId: routeIdFilter,
            notes: notesFilter ? encodeURIComponent(notesFilter) : '',
            onlyPoints: onlyPointsFilter,
            customerId,
            rfidMismatch,
            includePastRunning,
            includeEndsBetween,
            riskLevel,
            vehicleTagIds,
            destinationExit,
            delayStatus,
            jobLoadingStatus,
            noEwayBillJobs,
            hideUnloadingJobs,
            movementType,
            minDelay,
            maxDelay,
            consigneeId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/summary/latest`, config);

    return {
        type: FETCH_JOBS_SUMMARY_LATEST,
        promise: request,
    };
}

export function triggerJobSummaryReport(accesstoken, data = {}) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/jobSummaryReport`, data, config);

    return {
        type: DOWNLOAD_JOB_SUMMARY_REPORT,
        promise: request,
    };
}

export function AddRemoveConsignmentFromJob(params = {}, data = []) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.put(`${ROOT_API_URL}dispatch/commodity`, data, config);

    return {
        type: ADD_REMOVE_CONSIGNMENT_FROM_JOB,
        promise: request,
    };
}

export function AddClientContractChart(accesstoken, jobId, clientContractCharttId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
            clientContractCharttId,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}dispatch/client-contract-chart`, null, config);

    return {
        type: ADD_CLIENT_CONTRACT_CHART,
        promise: request,
    };
}

export function horizontalJobReportDownload(accesstoken, data = {}) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/horizontalJobReport`, data, config);

    return {
        type: DOWNLOAD_HORIZONTAL_JOB_REPORT,
        promise: request,
    };
}

export function triggerJobAnomalyReport(accesstoken, startDate, endDate, plantId, sendToEmail) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate.valueOf(),
            to: endDate.valueOf(),
            tagIds: plantId,
            tos: sendToEmail,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/rmc/anomaly`, config);

    return {
        type: DOWNLOAD_JOB_ANOMALY_REPORT,
        promise: request,
    };
}

export function createTransShipmentJob(accesstoken, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}dispatch/trans-shipment/`, values, config);
    return {
        type: CREATE_TRANS_SHIPMENT_JOB,
        promise: request,
    };
}

export function deleteImageUsingId(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };

    const request = axios.delete(`${ROOT_API_URL}dispatch/job-image?id=${id}`, config);

    return {
        type: DELETE_IMAGE,
        promise: request,
    };
}

export function triggerCustomerDetentionReport(accesstoken, startDate, endDate, accountId, sendToEmail) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate.valueOf(),
            to: endDate.valueOf(),
            accountId: accountId,
            tos: sendToEmail,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/customer/detention`, config);

    return {
        type: DOWNLOAD_CUSTOMER_DETENTION_REPORT,
        promise: request,
    };
}

export function fetchContainerTracking(params = {}) {
    const { containerNumber } = params;
    const config = {
        params: parseQueryParams({
            containerNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}webhook/container-tracking`, config);

    return {
        type: FETCH_CONTAINER_TRACKING,
        promise: request,
    };
}

export function fetchAllMarkUnloading(params = {}) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}stoppage/designated`, config);

    return {
        type: FETCH_ALL_UNLOADING_POINT,
        promise: request,
    };
}

export function fetchAllExcludeUnloadingPoint(params = {}) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}stoppage/service`, config);

    return {
        type: FETCH_ALL_UNLOADING_POINT_EXCLUDING,
        promise: request,
    };
}

export function deleteUnloadingMarks(accesstoken, id, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };

    const request = axios.delete(`${ROOT_API_URL}stoppage/${id}`, config);

    return {
        type: DELETE_IMAGE,
        promise: request,
    };
}

export function refreshUnloadingJob(jobId) {
    const request = axios.get(`${ROOT_API_URL}stoppage/set/unloading/stoppage?jobIds=${jobId}`);

    return {
        type: REFRESH_JOB,
        promise: request,
    };
}

export function downloadPayAsYouGoReport(payload) {
    const config = {
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/payAsYouGoReport`, payload, config);
    return {
        type: DOWNLOAD_PAY_AS_YOU_GO_REPORT,
        promise: request,
    };
}

export function onRouteSequenceReportDownload(payload) {
    const config = {
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/routeSequenceReport`, payload, config);
    return {
        type: DOWNLOAD_ROUTE_SEQUENCE_REPORT,
        promise: request,
    };
}

export function markUnloadingAction(values, accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}stoppage/mark`, values, config);
    return {
        type: MARK_UNLOADING_POINT,
        promise: request,
    };
}

export function disableScanning(jobId, isScanningDisbaled) {
    const data = {
        scanningDisabled: isScanningDisbaled,
    };

    const request = axios.put(`${ROOT_API_URL}dispatch/disable-scanning/${jobId}`, data);

    return {
        type: DISABLE_SCANNING,
        promise: request,
    };
}

export function createEWAYBillFromCN(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_ALFRED}/consignment/eway-bill`, {}, config);
    return {
        type: CREATE_EWAY_FROM_CN,
        promise: request,
    };
}
