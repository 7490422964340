import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SortOptionSvg = (props) => {
    return (_jsxs("svg", { width: "20px", height: "20px", viewBox: "0 0 20 20", version: "1.1", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("title", { children: "sort-24px" }), _jsx("g", { id: "Page-1", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", children: _jsx("g", { id: "vehcile-listing-page", transform: "translate(-81.000000, -176.000000)", children: _jsx("g", { id: "Group-17", transform: "translate(76.000000, 174.000000)", children: _jsxs("g", { id: "sort-24px", transform: "translate(5.000000, 2.000000)", children: [_jsx("polygon", { id: "Path", points: "0 0 20 0 20 20 0 20" }), _jsx("path", { d: "M2.5,15 L7.5,15 L7.5,13.3333333 L2.5,13.3333333 L2.5,15 Z M2.5,5 L2.5,6.66666667 L17.5,6.66666667 L17.5,5 L2.5,5 Z M2.5,10.8333333 L12.5,10.8333333 L12.5,9.16666667 L2.5,9.16666667 L2.5,10.8333333 Z", id: "Shape", fill: props.fill, fillRule: "nonzero" })] }) }) }) })] }));
};
const SortOptionsIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SortOptionSvg, ...props });
};
export default SortOptionsIcon;
