//warning: mutates original list
import { each, filter, isEmpty, toSafeInteger, get, reduce, keys, forEach, concat, map, isArray } from 'lodash';

export function sortList(list, sortOrder, sortKey, secondSortKey) {
    if (sortOrder === 'asc') {
        list.sort(function (a, b) {
            if (a[sortKey] === null || typeof a[sortKey] !== 'object') {
                if (a[sortKey] > b[sortKey]) {
                    return 1;
                } else if (b[sortKey] > a[sortKey]) {
                    return -1;
                }
            } else if (!!a[sortKey][secondSortKey]) {
                if (a[sortKey][secondSortKey] > b[sortKey][secondSortKey]) {
                    return 1;
                } else if (b[sortKey][secondSortKey] > a[sortKey][secondSortKey]) {
                    return -1;
                }
            }
            return 0;
        });
    } else {
        list.sort(function (a, b) {
            if (a[sortKey] === null || typeof a[sortKey] !== 'object') {
                if (a[sortKey] > b[sortKey]) {
                    return -1;
                } else if (b[sortKey] > a[sortKey]) {
                    return 1;
                }
            } else if (!!a[sortKey][secondSortKey]) {
                if (a[sortKey][secondSortKey] > b[sortKey][secondSortKey]) {
                    return -1;
                } else if (b[sortKey][secondSortKey] > a[sortKey][secondSortKey]) {
                    return 1;
                }
            }
            return 0;
        });
    }
    return list;
}

export function sortListNew(list, sortOrder, sortKey) {
    const greatThanReturn = sortOrder === 'asc' ? 1 : -1;
    const lessThanReturn = sortOrder === 'asc' ? -1 : 1;
    if (!isArray(list)) {
        return [];
    }
    list.sort(function (a, b) {
        if (get(a, sortKey, '') > get(b, sortKey, '')) {
            return greatThanReturn;
        } else if (get(b, sortKey, '') > get(a, sortKey, '')) {
            return lessThanReturn;
        }
        return 0;
    });
    return list;
}

export function listToTree(itemList, parent, tree) {
    tree = typeof tree !== 'undefined' ? tree : [];
    parent = typeof parent !== 'undefined' ? parent : { id: 0 };

    let children = filter(itemList, (child) => {
        return toSafeInteger(child.parentId) === parent.id;
    });

    if (!isEmpty(children)) {
        if (parent.id === 0) {
            tree = children;
        } else {
            parent['children'] = children;
        }
        each(children, (child) => {
            listToTree(itemList, child);
        });
    }

    return tree;
}

export const arrayToObject = (array, keyField = 'id') => {
    return reduce(
        array,
        (obj, item) => {
            obj[get(item, keyField)] = item;
            return obj;
        },
        {}
    );
};

export function collectAllUniqueKeysFromListWithMissingKeysInObject(list) {
    let allUniqueKeys = [];
    forEach(list, (item) => {
        allUniqueKeys = [...new Set(concat(allUniqueKeys, keys(item)))];
    });

    return allUniqueKeys;
}

export const arrayToObjectOfArraysKeyField = (array, keyField) => {
    let newArray = {};
    map(array, (item) => {
        if (newArray[item.vehicleId]) {
            newArray[`${get(item, keyField)}`].push(item);
        } else {
            newArray[`${get(item, keyField)}`] = [item];
        }
    });
    return newArray;
};

export const sortListAlphabetically = (arr, key, optionalKey) => {
    return arr.sort((a, b) => {
        const nameA = (a[key] || a[optionalKey])?.toLowerCase();
        const nameB = (b[key] || b[optionalKey])?.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
};
