import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const ContractManagementComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-contractManagement" */ 'components/dashboard/vendor_management/contractManagement/ContractManagementComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditContractComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-addEditContract" */ 'components/dashboard/vendor_management/contractManagement/contractForm/AddEditContractComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditPtlContractComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-addEditPtlContract" */ 'components/dashboard/vendor_management/contractManagement/ptl/contractForm/AddEditPtlContractComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const contractManagementRoutes = [
    {
        path: 'contract/add',
        lazy: AddEditContractComponent,
    },
    {
        path: 'contract/edit/:id',
        lazy: AddEditContractComponent,
    },
    {
        path: 'ptl/*',
        children: [
            {
                path: 'contract/add',
                lazy: AddEditPtlContractComponent,
            },
            {
                path: 'contract/edit/:id/:annexureId',
                lazy: AddEditPtlContractComponent,
            },
        ],
    },
    {
        index: true,
        lazy: ContractManagementComponent,
    },
];
export default contractManagementRoutes;
