import { jsx as _jsx } from "react/jsx-runtime";
import { Tabs } from 'antd';
import styled from 'styled-components';
const FxTab = (props) => {
    return _jsx(Tabs, { ...props });
};
const FxTabPane = Tabs.TabPane;
const FxTabsWrapper = styled.div `
    .ant-tabs {
        width: ${(props) => (props.width ? props.width : 'auto')};
        margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '-1rem')};
    }

    .ant-tabs-tab {
        font-weight: normal !important;
    }

    .ant-tabs-tab-active {
        font-weight: bold !important;
    }

    .ant-tabs-nav {
        margin: 0 !important;
    }
`;
export { FxTab, FxTabPane, FxTabsWrapper };
