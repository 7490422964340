import { jsx as _jsx } from "react/jsx-runtime";
import { FxDatePicker } from './';
import styled from 'styled-components';
const { RangePicker } = FxDatePicker;
const DateRangePickerWrapper = styled.div `
    display: inline-block;
`;
const FxDateRangePicker = (props) => {
    return (_jsx(DateRangePickerWrapper, { children: _jsx(RangePicker, { ...props }) }));
};
export default FxDateRangePicker;
