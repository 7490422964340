import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP, ROOT_REPORTING_NODE_API_URL } from '../../../constant';
export const CREATE_LEDGER = 'CREATE_LEDGER';
export const UPDATE_LEDGER = 'UPDATE_LEDGER';
export const DELETE_LEDGER = 'DELETE_LEDGER';
export const FETCH_LEDGERS = 'FETCH_LEDGERS';
export const FETCH_LEDGER = 'FETCH_LEDGER';
export const DOWNLOAD_LEDGER = 'DOWNLOAD_LEDGER';
export const FETCH_OPENING_BALANCE = 'FETCH_OPENING_BALANCE';
export const FETCH_TRIAL_BALANCE = 'FETCH_TRIAL_BALANCE';
export const GET_LEDGERS_REPORT = 'GET_LEDGERS_REPORT';
export function createLedger(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}ledger`, data, config);
    return {
        type: CREATE_LEDGER,
        promise: request,
    };
}
export function updateLedger(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}ledger`, data, config);
    return {
        type: UPDATE_LEDGER,
        promise: request,
    };
}
export function fetchLedgers(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}ledger`, config);
    return {
        type: FETCH_LEDGERS,
        promise: request,
    };
}
export function fetchLedger(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}ledger/${id}`, config);
    return {
        type: FETCH_LEDGER,
        promise: request,
    };
}
export function deleteLedger(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}ledger/${id}`, config);
    return {
        type: DELETE_LEDGER,
        promise: request,
    };
}
export function downloadLedgers(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_ERP}report/ledger`, config);
    return {
        type: DOWNLOAD_LEDGER,
        promise: request,
    };
}
export function fetchLedgerOpeningBalance(accesstoken, { date }) {
    const config = {
        headers: { 'Authorization': `${accesstoken}`, 'clientId': 'fleetxweb' },
        params: parseQueryParams({
            date,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}ledger/opening-balance`, config);
    return {
        type: FETCH_OPENING_BALANCE,
        promise: request,
    };
}
export function fetchTrialBalance(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `${accesstoken}`, 'clientId': 'fleetxweb' },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}ledger/trail-balance`, config);
    return {
        type: FETCH_TRIAL_BALANCE,
        promise: request,
    };
}
export function getLedgersReport(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getLedgersReport`, config);
    return {
        type: GET_LEDGERS_REPORT,
        promise: request,
    };
}
