import axios from 'axios';
import { ROOT_API_ALFRED, parseQueryParams } from '../../../../constant';
export const CREATE_SEGMENT = 'CREATE_SEGMENT';
export const UPDATE_SEGMENT = 'UPDATE_SEGMENT';
export const FETCH_SEGMENT_BY_ID = 'FETCH_SEGMENT_BY_ID';
export const FETCH_SEGMENTS = 'FETCH_SEGMENTS';
export const DELETE_SEGMENT = 'DELETE_SEGMENT';
export function createSegment(accesstoken, data) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ALFRED}/segment`, data, config);
    return {
        type: CREATE_SEGMENT,
        promise: request,
    };
}
export function updateSegment(accesstoken, data) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_ALFRED}/segment`, data, config);
    return {
        type: UPDATE_SEGMENT,
        promise: request,
    };
}
export function fetchSegment(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ALFRED}/segment/${id}`, config);
    return {
        type: FETCH_SEGMENT_BY_ID,
        promise: request,
    };
}
export function fetchSegments(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ALFRED}/segment`, config);
    return {
        type: FETCH_SEGMENTS,
        promise: request,
    };
}
export function deleteSegment(accesstoken, id) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
        }),
    };
    const request = axios.delete(`${ROOT_API_ALFRED}/segment`, config);
    return {
        type: DELETE_SEGMENT,
        promise: request,
    };
}
