import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'formik';
import { get, map, filter, includes } from 'lodash';
import { Button } from 'reactstrap';
import { FxSelectField, FxTextFieldStackedLabel } from '../../../../shared';
import { mappedMaterials } from 'components/utils/mapping_utils';
import { getIncentiveAndPenalty, getMisplacedCount } from 'utils/epod_utils';
const EPODMaterialsComponent = (props) => {
    const { editId, challanMaterials, formikProps } = props;
    const { setFieldValue, values } = formikProps;
    return (_jsx(FieldArray, { name: "materials", children: ({ remove, push }) => {
            const materials = get(formikProps.values, 'materials');
            const materialsAdded = !!(materials && materials.length);
            const allSelectedMaterialsId = map(materials, (m) => m.id);
            return (_jsxs(_Fragment, { children: [map(materials, (containerNumber, index) => {
                        const dispatchQuantity = get(values, `materials.[${index}].dispatchQuantity`);
                        const unloadedCount = get(values, `materials.[${index}].unloadedCount`);
                        const damagedCount = get(values, `materials.[${index}].damagedCount`);
                        const tolerance = get(values, `materials.[${index}].tolerance`);
                        const itemRate = get(values, `materials.[${index}].itemRate`);
                        return (_jsxs("div", { children: [_jsxs("div", { className: "d-flex align-items-center justify-content-between", children: [_jsxs("h6", { className: "mb-0", children: ["# ", index + 1] }), !editId && (_jsx("div", { className: "px-0 text-right", children: _jsx(Button, { type: "button", color: "link", onClick: () => {
                                                    remove(index);
                                                }, children: _jsx("i", { className: "fa fa-close" }) }) }))] }), _jsxs("div", { className: "row mt-2 mb-5 align-items-start", children: [_jsx("div", { className: "col-md-2", children: _jsx(Field, { name: `materials.[${index}].id`, component: FxSelectField, options: mappedMaterials(!editId
                                                    ? filter(challanMaterials, (cm) => !includes(allSelectedMaterialsId, cm.id))
                                                    : challanMaterials), label: "Material", onOptionSelected: (val) => {
                                                    setFieldValue(`materials.[${index}].dispatchQuantity`, get(val, 'count'));
                                                    setFieldValue(`materials.[${index}].unloadedCount`, null);
                                                    setFieldValue(`materials.[${index}].unloadedValue`, null);
                                                    setFieldValue(`materials.[${index}].misplacedCount`, null);
                                                    setFieldValue(`materials.[${index}].misplacedValue`, null);
                                                    setFieldValue(`materials.[${index}].damagedCount`, null);
                                                    setFieldValue(`materials.[${index}].damagedValue`, null);
                                                    setFieldValue(`materials.[${index}].tolerance`, get(val, 'tolerance'));
                                                    setFieldValue(`materials.[${index}].itemRate`, get(val, 'itemRate'));
                                                    const { penalty, incentive } = getIncentiveAndPenalty({
                                                        unloadedCount,
                                                        dispatchQuantity: get(val, 'count'),
                                                        tolerance: get(val, 'tolerance'),
                                                        itemRate: get(val, 'itemRate'),
                                                    });
                                                    setFieldValue(`materials.[${index}].penalty`, penalty);
                                                    setFieldValue(`materials.[${index}].incentive`, incentive);
                                                }, isRequired: true, disabled: !!editId }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { isRequired: true, type: "number", name: `materials.[${index}].dispatchQuantity`, label: `Total Items`, component: FxTextFieldStackedLabel, disabled: true }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { isRequired: true, type: "number", name: `materials.[${index}].unloadedCount`, label: `Unloaded Count`, component: FxTextFieldStackedLabel, errorStyle: {
                                                    position: 'absolute',
                                                    marginBottom: '5px',
                                                }, onChange: (val) => {
                                                    const misplacedCount = getMisplacedCount({
                                                        dispatchQuantity,
                                                        unloadedCount: val,
                                                        damagedCount,
                                                    });
                                                    setFieldValue(`materials.[${index}].misplacedCount`, misplacedCount);
                                                    const { penalty, incentive } = getIncentiveAndPenalty({
                                                        unloadedCount: val,
                                                        dispatchQuantity,
                                                        tolerance,
                                                        itemRate,
                                                    });
                                                    setFieldValue(`materials.[${index}].penalty`, penalty);
                                                    setFieldValue(`materials.[${index}].incentive`, incentive);
                                                } }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { type: "number", name: `materials.[${index}].unloadedValue`, label: `Unloaded Value`, component: FxTextFieldStackedLabel }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { isRequired: true, type: "number", name: `materials.[${index}].misplacedCount`, label: `Misplaced Count`, component: FxTextFieldStackedLabel, errorStyle: {
                                                    position: 'absolute',
                                                    marginBottom: '5px',
                                                }, disabled: true }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { type: "number", name: `materials.[${index}].misplacedValue`, label: `Misplaced Value`, component: FxTextFieldStackedLabel }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { isRequired: true, type: "number", name: `materials.[${index}].damagedCount`, label: `Damaged Count`, component: FxTextFieldStackedLabel, onChange: (val) => {
                                                    const misplacedCount = getMisplacedCount({
                                                        dispatchQuantity,
                                                        unloadedCount,
                                                        damagedCount: val,
                                                    });
                                                    setFieldValue(`materials.[${index}].misplacedCount`, misplacedCount);
                                                } }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { type: "number", name: `materials.[${index}].damagedValue`, label: `Damaged Value`, component: FxTextFieldStackedLabel }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { isRequired: true, type: "number", name: `materials.[${index}].tolerance`, label: `Tolerance(%)`, component: FxTextFieldStackedLabel, onChange: (val) => {
                                                    const { penalty, incentive } = getIncentiveAndPenalty({
                                                        unloadedCount,
                                                        dispatchQuantity,
                                                        tolerance: val,
                                                        itemRate,
                                                    });
                                                    setFieldValue(`materials.[${index}].penalty`, penalty);
                                                    setFieldValue(`materials.[${index}].incentive`, incentive);
                                                } }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { isRequired: true, type: "number", name: `materials.[${index}].itemRate`, label: `Item Rate`, component: FxTextFieldStackedLabel, onChange: (val) => {
                                                    const { penalty, incentive } = getIncentiveAndPenalty({
                                                        unloadedCount,
                                                        dispatchQuantity,
                                                        tolerance,
                                                        itemRate: val,
                                                    });
                                                    setFieldValue(`materials.[${index}].penalty`, penalty);
                                                    setFieldValue(`materials.[${index}].incentive`, incentive);
                                                } }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { type: "number", name: `materials.[${index}].penalty`, label: `Penalty`, component: FxTextFieldStackedLabel, disabled: true }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { type: "number", name: `materials.[${index}].incentive`, label: `Incentive`, component: FxTextFieldStackedLabel, disabled: true }) })] })] }, index));
                    }), !editId && (_jsx("div", { className: "text-center mt-5", children: _jsx(Button, { type: "button", color: "secondary", onClick: () => {
                                push({
                                    id: null,
                                    misplacedCount: '',
                                    misplacedValue: '',
                                    damagedCount: '',
                                    damagedValue: '',
                                });
                            }, children: materialsAdded ? ' Add More' : 'Add' }) }))] }));
        } }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(EPODMaterialsComponent);
