import axios from 'axios';
import { ROOT_API_URL, ROOT_API, parseQueryParams } from '../../constant';

export const CREATE_PO = 'create_purchase_order';
export const UPDATE_PO = 'update_purchase_order';
export const DELETE_PO = 'delete_purchase_order';
export const FETCH_POS = 'fetch_purchase_orders';

export function createPO(accesstoken, data) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}accounts/purchase-orders`, data, config);
    return {
        type: CREATE_PO,
        promise: request,
    };
}

export function updatePO(accesstoken, data) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}accounts/purchase-orders`, data, config);
    return {
        type: UPDATE_PO,
        promise: request,
    };
}

export function deletePO(accesstoken, accountId, PoId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id: PoId,
            accountId,
        }),
    };
    const request = axios.delete(`${ROOT_API_URL}accounts/purchase-orders`, config);
    return {
        type: DELETE_PO,
        promise: request,
    };
}

export function fetchPOsByAccountId(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}accounts/purchase-orders/${accountId}`, config);
    return {
        type: FETCH_POS,
        promise: request,
    };
}
