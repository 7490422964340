import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditMaterialGroupComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditMaterialGroup" */ 'components/dashboard/rateCharts/materialGroups/AddEditMaterialGroupComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const MaterialGroupsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-materialGroups" */ 'components/dashboard/rateCharts/materialGroups/MaterialGroupsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const materialGroupsRoutes = [
    {
        index: true,
        lazy: MaterialGroupsComponent,
    },
    {
        path: 'add',
        lazy: AddEditMaterialGroupComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditMaterialGroupComponent,
    },
];
export default materialGroupsRoutes;
