import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';
import { toSafeInteger } from 'lodash';

export const CREATE_COMMENT = 'create_comment';
export const UPDATE_COMMENT = 'update_comment';
export const DELETE_COMMENT = 'delete_comment';
export const FETCH_COMMENTS_BY_TYPE_ID = 'fetch_comments_by_type_id';
export const FETCH_LATEST_COMMENTS = 'fetch_latest_comments';
export const FETCH_COMMENT_BY_ID = 'fetch_comment_by_id';
export const SEARCH_COMMENTS = 'search_comments';
export function createComment(accesstoken, values, localTransporterAccount) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId: localTransporterAccount,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}comments/`, values, config);
    return {
        type: CREATE_COMMENT,
        promise: request,
    };
}

export function updateComment(accesstoken, id, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}comments/${id}`, values, config);

    return {
        type: UPDATE_COMMENT,
        promise: request,
    };
}

export function deleteComment(accesstoken, id, accountId = null) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.delete(`${ROOT_API_URL}comments/${id}`, config);

    return {
        type: DELETE_COMMENT,
        promise: request,
    };
}

export function fetchComments(accesstoken, commentType, commentableId, vehicleId, startDate, endDate, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const commentableIdQuery = commentableId ? `&commentableId=${commentableId}` : '';
    const vehicleIdQuery = vehicleId ? `&vehicleId=${toSafeInteger(vehicleId)}` : '';
    const startDateQuery = startDate ? `&startDate=${startDate.valueOf()}` : '';
    const endDateQuery = endDate ? `&endDate=${endDate.valueOf()}` : '';
    const request = axios.get(
        `${ROOT_API_URL}comments/commentablType?commentableType=${commentType}${commentableIdQuery}${vehicleIdQuery}${startDateQuery}${endDateQuery}`,
        config
    );

    return {
        type: FETCH_COMMENTS_BY_TYPE_ID,
        promise: request,
    };
}

export function fetchLatestComments(accesstoken, commentType, subType) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    let queryParams = '?openComment=true';

    queryParams += commentType ? `&commentableType=${commentType}` : '';

    queryParams += subType ? `&subType=${subType}` : '';

    const request = axios.get(`${ROOT_API_URL}comments/latest${queryParams}`, config);

    return {
        type: FETCH_LATEST_COMMENTS,
        promise: request,
    };
}

export function fetchCommentById(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };

    const request = axios.get(`${ROOT_API_URL}comments/${id}`, config);

    return {
        type: FETCH_COMMENT_BY_ID,
        promise: request,
    };
}

export function searchComments(accesstoken, commentableType, commentableId, vehicleId, subType, page = 1, size = 3000) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            commentableId,
            commentableType,
            vehicleId,
            subType,
            page: page - 1,
            size,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}comments/search`, config);

    return {
        type: SEARCH_COMMENTS,
        promise: request,
    };
}
