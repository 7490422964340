import { get } from 'lodash';
export default (passedProps) => {
    const {
        field,
        form,
        input,
        name,
        label,
        isRequired,
        helptext,
        colClass,
        disabled,
        style,
        placeholder,
        numberOfRow,
        showLabelonTop,
        inputClassNames = '',
        ...props
    } = passedProps;
    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');

    const className = `form-group row ${isTouched && error ? 'has-danger' : ''}`;
    let { labelClass } = passedProps;
    labelClass = `${isTouched && error ? 'text-danger' : labelClass ? labelClass : ''}`;
    return (
        <div className={`${className} ${showLabelonTop ? `flex-col d-flex mt-3` : ''}`}>
            {label && (
                <div className={`col-md-12 ${colClass}`}>
                    <label htmlFor={name} className={labelClass}>
                        {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    </label>
                </div>
            )}
            <div className={`${!showLabelonTop ? 'col-md-12' : 'col-md-9'} ${colClass}`}>
                <textarea
                    style={{ width: '100%', ...style }}
                    className={`form-control ${inputClassNames}`}
                    rows={`${numberOfRow ? numberOfRow : '2'}`}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...field}
                />
                {isTouched && error ? (
                    <span className="help-block text-danger">{error}</span>
                ) : (
                    helptext && <span className="help-block">{helptext}</span>
                )}
            </div>
        </div>
    );
};
