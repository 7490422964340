import axios from 'axios';
import { ROOT_API_ERP } from '../../../constant';
export const CREATE_MATERIAL_GROUP = 'CREATE_MATERIAL_GROUP';
export const UPDATE_MATERIAL_GROUP = 'UPDATE_MATERIAL_GROUP';
export const DELETE_MATERIAL_GROUP = 'DELETE_MATERIAL_GROUP';
export const FETCH_MATERIAL_GROUPS = 'FETCH_MATERIAL_GROUPS';
export const FETCH_MATERIAL_GROUP = 'FETCH_MATERIAL_GROUP';
export function createMaterialGroup(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}material/group`, data, config);
    return {
        type: CREATE_MATERIAL_GROUP,
        promise: request,
    };
}
export function updateMaterialGroup(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}material/group`, data, config);
    return {
        type: UPDATE_MATERIAL_GROUP,
        promise: request,
    };
}
export function fetchMaterialGroups(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}material/group`, config);
    return {
        type: FETCH_MATERIAL_GROUPS,
        promise: request,
    };
}
export function fetchMaterialGroup(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}material/group/${id}`, config);
    return {
        type: FETCH_MATERIAL_GROUP,
        promise: request,
    };
}
export function deleteMaterialGroup(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}material/group/${id}`, config);
    return {
        type: DELETE_MATERIAL_GROUP,
        promise: request,
    };
}
