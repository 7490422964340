import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const VehicleRenewalReminder = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-vehicleReminder" */ 'components/dashboard/settings/vehicleReminderConfig/vehicle_reminder_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditVehicleReminderConfig = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditVehicleReminderConfig" */ 'components/dashboard/settings/vehicleReminderConfig/add_edit_vehicle_reminder_config'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vehiceReminderRoutes = [
    {
        index: true,
        lazy: VehicleRenewalReminder,
    },
    {
        path: 'add',
        lazy: AddEditVehicleReminderConfig,
    },
    {
        path: 'edit/:id',
        lazy: AddEditVehicleReminderConfig,
    },
];
export default vehiceReminderRoutes;
