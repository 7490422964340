import { get, map } from 'lodash';
export const PAYMENT_NATURES = {
    ON_ACCOUNT_PAYMENT: 'ON_ACCOUNT_PAYMENT',
    ON_ACCOUNT_RECEIPT: 'ON_ACCOUNT_RECEIPT',
};
export const PAYMENT_NATURES_OPTIONS = [
    {
        value: PAYMENT_NATURES.ON_ACCOUNT_PAYMENT,
        label: 'On Account Payment',
    },
    {
        value: PAYMENT_NATURES.ON_ACCOUNT_RECEIPT,
        label: 'On Account Receipt',
    },
];
export function mappedOnAccountPaymentOptions(payments, vehicles = {}) {
    return map(payments, (p) => {
        const amountAvailable = get(p, 'adviceAmount', 0) - get(p, 'closingAmount', 0);
        const vehicleId = get(p, 'onAccountVehicleId', '');
        const vehicleName = get(vehicles, `${vehicleId}.licensePlate`, '');
        return {
            value: p.id,
            label: `${get(p, 'adviceNumber', '')}(Available Amount: ${amountAvailable}) ${vehicleName ? `- ${vehicleName}` : ''}`,
        };
    });
}
