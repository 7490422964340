import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const InternalIssuesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-internal-issues" */ 'components/dashboard/internal_tools/issues/issues_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditInternalIssueComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-internal-issues-add-edit" */ 'components/dashboard/internal_tools/issues/add_edit_issue_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewInternalIssueComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-internal-issues-view" */ 'components/dashboard/internal_tools/issues/view_issue_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const internalIssuesRoutes = [
    {
        path: 'add',
        lazy: AddEditInternalIssueComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditInternalIssueComponent,
    },
    {
        path: 'view/:id',
        lazy: ViewInternalIssueComponent,
    },
    {
        index: true,
        lazy: InternalIssuesComponent,
    },
];
export default internalIssuesRoutes;
