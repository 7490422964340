import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SmartPhone = (props) => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "15", height: "15", viewBox: "0 0 15 15", fill: "none", children: _jsx("path", { d: "M5.1423 13.7108C4.93456 13.7108 4.75279 13.6329 4.59699 13.477C4.44118 13.3212 4.36328 13.1395 4.36328 12.9317V3.06417C4.36328 2.85644 4.44118 2.67467 4.59699 2.51886C4.75279 2.36306 4.93456 2.28516 5.1423 2.28516H10.8551C11.0628 2.28516 11.2446 2.36306 11.4004 2.51886C11.5562 2.67467 11.6341 2.85644 11.6341 3.06417V12.9317C11.6341 13.1395 11.5562 13.3212 11.4004 13.477C11.2446 13.6329 11.0628 13.7108 10.8551 13.7108H5.1423ZM5.1423 11.7632V12.9317H10.8551V11.7632H5.1423ZM8.00097 12.737C8.11198 12.737 8.20427 12.6994 8.27785 12.6243C8.35142 12.5492 8.38821 12.4562 8.38821 12.3452C8.38821 12.2342 8.35066 12.1419 8.27557 12.0683C8.20048 11.9947 8.10743 11.958 7.99643 11.958C7.88542 11.958 7.79312 11.9955 7.71955 12.0706C7.64598 12.1457 7.60919 12.2387 7.60919 12.3497C7.60919 12.4608 7.64673 12.553 7.72182 12.6266C7.79692 12.7002 7.88997 12.737 8.00097 12.737ZM5.1423 10.9842H10.8551V4.2327H5.1423V10.9842ZM5.1423 3.45368H10.8551V3.06417H5.1423V3.45368Z", fill: "#333333" }) }));
};
const SmartPhoneIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SmartPhone, ...props });
};
export default SmartPhoneIcon;
