import { jsx as _jsx } from "react/jsx-runtime";
import { Form, Switch } from 'antd';
import { isUndefined } from 'lodash';
const FxSwitchField = ({ field, form, mb, ...props }) => {
    const { name, value } = field;
    const { errors, touched } = form;
    const errorMsg = errors[name];
    const error = errorMsg && touched[name];
    return (_jsx(Form.Item, { label: props.label, required: props.required, validateStatus: touched && error ? 'error' : '', help: touched && error, labelCol: { span: 24 }, className: !isUndefined(mb) ? `mb-${mb}` : '', children: _jsx(Switch, { ...field, ...props, onChange: (value) => {
                form.setFieldValue(name, value);
                if (props.onChange) {
                    props.onChange(value);
                }
            }, disabled: props.disabled || false, checked: value }) }));
};
export default FxSwitchField;
