import { useTranslation } from 'react-i18next';
import { useApp } from '../../../theme';
import { useCallback } from 'react';
const useFxMessage = () => {
    const { t } = useTranslation();
    const { message } = useApp();
    return useCallback((msg, options) => {
        const { type = 'success' } = options || {};
        if (message[type]) {
            message[type]({
                content: msg,
                ...options,
            });
        }
    }, []);
};
export default useFxMessage;
