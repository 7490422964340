import { Card } from 'reactstrap';

const FxCard = (passedProps) => {
    const { cardBlockClass, ...props } = passedProps;
    const cardBlockClassName = cardBlockClass ? `${cardBlockClass} card-block` : 'card-block px-1 px-md-4';
    return (
        <Card className={props.className ? props.className : ''} {...props}>
            <div className={`${cardBlockClassName}`}>
                {props.title && <h4>{props.title}</h4>}
                {props.children}
            </div>
        </Card>
    );
};

export default FxCard;
