import { handle } from 'redux-pack';
import { GET_BRANCH_AUDIT_TRAIL, GET_INVENTORY, GET_OUTSTANDING, } from 'actions/dashboard/material_stock/material_stock_action';
const initialState = {
    inventory: [],
    isInventoryLoading: false,
    outstanding: [],
    isOutstandingLoading: false,
    branchAuditTrail: [],
    isBranchAuditTrailLoading: false,
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_INVENTORY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isInventoryLoading: true,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, error: payload, isInventoryLoading: false }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        inventory: payload.data,
                        isInventoryLoading: false,
                    };
                },
            });
        case GET_OUTSTANDING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isOutstandingLoading: true,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, error: payload, isOutstandingLoading: false }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        outstanding: payload.data,
                        isOutstandingLoading: false,
                    };
                },
            });
        case GET_BRANCH_AUDIT_TRAIL:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isBranchAuditTrailLoading: true,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, error: payload, isBranchAuditTrailLoading: false }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        branchAuditTrail: payload.data.content,
                        isBranchAuditTrailLoading: false,
                    };
                },
            });
        default:
            return state;
    }
}
