import styled from 'styled-components';
const _FxFlex = styled.div `
    display: flex;
    flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-start')};
    margin: ${(props) => (props.viewMargin ? props.viewMargin : '0px')};
    padding: ${(props) => (props.viewPadding ? props.viewPadding : '0px')};
    flex-basis: ${(props) => (props.flexBasis ? props.flexBasis : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    width: ${(props) => (props.width ? props.width : '100%')};
    border: ${(props) => (props.border ? props.border : 'none')};
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
    flex-wrap: ${(props) => (props.wrap ? props.wrap : 'no-wrap')};
`;
export default _FxFlex;
