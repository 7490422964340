import {handle} from "redux-pack";
import {
    CREATE_TIMEFENCE, DELETE_TIMEFENCE, FETCH_TIMEFENCE, FETCH_TIMEFENCES, UPDATE_TIMEFENCE
} from "../../actions/dashboard/timefence_action";

const initialState = {
    isLoading: false, error: null, timefenceList: [], data: {}, isFetchTimeFenceLoading: false
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case CREATE_TIMEFENCE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case UPDATE_TIMEFENCE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),

            });
        case DELETE_TIMEFENCE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})
            });
        case FETCH_TIMEFENCES :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null, isFetchTimeFenceLoading: true
                }), finish: prevState => ({...prevState, isLoading: false, isFetchTimeFenceLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, timefenceList: payload.data
                }),
            });

        case FETCH_TIMEFENCE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({...prevState, data: {...prevState.data, [payload.data.id]: payload.data}})
            });
        default:
            return state;
    }
}

