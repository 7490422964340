import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const AddEditGatePassComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-gate-pass-add-edit" */ 'components/dashboard/gate_pass/forms/AddEditGatePassComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const GatePassComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-gate-pass-gate-pass" */ 'components/dashboard/gate_pass/GatePassComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const gatepassRoutes = [
    {
        path: 'all',
        lazy: GatePassComponent,
    },
    {
        path: 'add',
        lazy: AddEditGatePassComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditGatePassComponent,
    },
];
export default gatepassRoutes;
