import { filter, get, includes } from 'lodash';
import { mappedInternalIssuesTypes } from 'components/utils/mapping_utils';

export const INTERNAL_ISSUE_CATEGORY = {
    FLEETX: 'FLEETX',
    CLIENT: 'CLIENT',
};

export const INTERNAL_ISSUE_SINGULAR_LABEL = 'Ticket';
export const INTERNAL_ISSUE_LABEL = 'Tickets';

export const CLIENT_ISSUES_TYPES = {
    REPAIR: 'REPAIR',
    CUSTOMIZATION: 'CUSTOMIZATION',
    REMOVAL: 'REMOVAL',
    REPLACEMENT: 'REPLACEMENT',
    KM_ISSUE: 'KM_ISSUE',
    DATA_ENTRY: 'DATA_ENTRY',
    LOCATION_ISSUE: 'LOCATION_ISSUE',
    MILEAGE_ISSUE: 'MILEAGE_ISSUE',
    TRAINING: 'TRAINING',
    INVOICE_REVISION: 'INVOICE_REVISION',
    INSTALLATION: 'INSTALLATION',
    REQUEST_NEW_FASTAG: 'REQUEST_NEW_FASTAG',
};
export const CLIENT_ISSUES_TYPES_LABEL = {
    REPAIR: 'Repair',
    CUSTOMIZATION: 'Customisation',
    REMOVAL: 'Removal',
    REPLACEMENT: 'Replacement',
    KM_ISSUE: 'KM Issue',
    DATA_ENTRY: 'Data Entry',
    LOCATION_ISSUE: 'Location Issue',
    MILEAGE_ISSUE: 'Mileage Issue',
    TRAINING: 'Training',
    INVOICE_REVISION: 'Invoice Revision',
    INSTALLATION: 'Installation',
    REQUEST_NEW_FASTAG: 'Request New FAStag',
};

export const INTERNAL_ISSUES_DOCUMENT_TAGS = {
    AADHAR_CARD: 'AADHAR_CARD',
    GST_CERTIFICATE: 'GST_CERTIFICATE',
    CANCELLED_CHEQUE: 'CANCELLED_CHEQUE',
};

export const INTERNAL_ISSUES_DOCUMENT_TAGS_LABEL = {
    AADHAR_CARD: 'Aadhar Card',
    GST_CERTIFICATE: 'GST Certificate',
    CANCELLED_CHEQUE: 'Cancelled Cheque',
};

export const FLEETX_ISSUES_TYPES = {
    REPAIR: 'REPAIR',
    CUSTOMIZATION: 'CUSTOMIZATION',
    REMOVAL: 'REMOVAL',
    REPLACEMENT: 'REPLACEMENT',
    KM_ISSUE: 'KM_ISSUE',
    DATA_ENTRY: 'DATA_ENTRY',
    LOCATION_ISSUE: 'LOCATION_ISSUE',
    MILEAGE_ISSUE: 'MILEAGE_ISSUE',
    INVOICE_REVISION: 'INVOICE_REVISION',
    WELCOME_CALL: 'WELCOME_CALL',
    INSTALLATION: 'INSTALLATION',
    BUG: 'BUG',
    TRAINING_INTERNAL: 'TRAINING_INTERNAL',
    TRAINING_EXTERNAL: 'TRAINING_EXTERNAL',
    INVOICE_GENERATION: 'INVOICE_GENERATION',
    DEACTIVATION: 'DEACTIVATION',
    PRICING_ISSUE: 'PRICING_ISSUE',
    BILLING_CHANGE: 'BILLING_CHANGE',
    DEVICE_SHIFTING: 'DEVICE_SHIFTING',
    UPGRADE_DOWNGRADE: 'UPGRADE_DOWNGRADE',
    NO_DEMAND: 'NO_DEMAND',
    PARTIAL_DEMAND: 'PARTIAL_DEMAND',
    INSTALLATION_ON_HOLD: 'INSTALLATION_ON_HOLD',
    REQUEST_NEW_FASTAG: 'REQUEST_NEW_FASTAG',
    SIM_TRACKING_ISSUE: 'SIM_TRACKING_ISSUE',
    PORTABLE_GPS_ISSUE: 'PORTABLE_GPS_ISSUE',
    WIRED_GPS_ISSUE: 'WIRED_GPS_ISSUE',
    REEFER_ISSUE: 'REEFER_ISSUE',
    API_ISSUE: 'API_ISSUE',
    INDENT_ISSUE: 'INDENT_ISSUE',
    SWAPPING: 'SWAPPING',
    ERROR: 'ERROR',
    INVENTORY_REQUEST: 'INVENTORY_REQUEST',
    PORTAL_ISSUES: 'PORTAL_ISSUES',
};

export const FLEETX_ISSUES_TYPES_LABEL = {
    REPAIR: 'Repair',
    CUSTOMIZATION: 'Customisation',
    REMOVAL: 'Removal',
    REPLACEMENT: 'Replacement',
    KM_ISSUE: 'KM Issue',
    DATA_ENTRY: 'Data Entry',
    LOCATION_ISSUE: 'Location Issue',
    MILEAGE_ISSUE: 'Mileage Issue',
    INVOICE_REVISION: 'Invoice Revision',
    WELCOME_CALL: 'Welcome Call',
    INSTALLATION: 'Installation',
    BUG: 'Bug',
    TRAINING_INTERNAL: 'Training Internal',
    TRAINING_EXTERNAL: 'Training External',
    INVOICE_GENERATION: 'Invoice Generation',
    DEACTIVATION: 'Deactivation',
    PRICING_ISSUE: 'Pricing Issue',
    BILLING_CHANGE: 'Billing Change',
    DEVICE_SHIFTING: 'Device Shifting',
    UPGRADE_DOWNGRADE: 'Upgrade Downgrade',
    NO_DEMAND: 'No Demand',
    PARTIAL_DEMAND: 'Partial Demand',
    INSTALLATION_ON_HOLD: 'Installation On Hold',
    REQUEST_NEW_FASTAG: 'Request New FAStag',
    SIM_TRACKING_ISSUE: 'SIM Tracking Issue',
    PORTABLE_GPS_ISSUE: 'Portable GPS Issue',
    WIRED_GPS_ISSUE: 'Wired GPS Issue',
    REEFER_ISSUE: 'Reefer Issue',
    API_ISSUE: 'API Issue',
    INDENT_ISSUE: 'Indent Issue',
    SWAPPING: 'Swapping',
    ERROR: 'Error',
    INVENTORY_REQUEST: 'Inventory Request',
    PORTAL_ISSUES: 'Portal Issues',
};

export function getDescriptionPlaceholder(issueType) {
    switch (issueType) {
        case CLIENT_ISSUES_TYPES.REPAIR:
        case CLIENT_ISSUES_TYPES.REMOVAL:
            return 'Tell us the driver phone number and vehicle location.';
        default:
            return 'Please describe your issue here...';
    }
}

export function isIssueCreatedByFleetxPerson(loggedInUser, reportedByAccountId) {
    return get(loggedInUser, 'accountId') !== reportedByAccountId;
}

export function isFleetxPersonCreatingIssue(isSwitchUserAllowed) {
    return isSwitchUserAllowed;
}

export function isVehicleRequiredInTicketForm(type) {
    return includes(
        [
            FLEETX_ISSUES_TYPES.REPAIR,
            FLEETX_ISSUES_TYPES.REMOVAL,
            FLEETX_ISSUES_TYPES.REPLACEMENT,
            FLEETX_ISSUES_TYPES.KM_ISSUE,
            FLEETX_ISSUES_TYPES.LOCATION_ISSUE,
        ],
        type
    );
}

export const InternalIssuesAccounts = [12925];

export function getTicketFormFieldConfig({ localTransporterAccount }) {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    const isTransporter = !!localTransporterAccount;
    const finalAccountId = localTransporterAccount || accountId;
    return {
        summary: {
            show: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true,
            required: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true,
            disabled: isTransporter ? includes(InternalIssuesAccounts, finalAccountId) : false,
        },
        description: {
            show: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true,
            required: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true,
            disabled: isTransporter ? includes(InternalIssuesAccounts, finalAccountId) : false,
        },
        companyName: {
            show: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true,
            required: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : includes([1570], accountId),
            disabled: false,
        },
        username: {
            show: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true,
            required: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : includes([1570], accountId),
            disabled: false,
        },
        transporterId: {
            show: isTransporter ? false : includes([12925], accountId),
            required: false,
            disabled: false,
        },
    };
}

export function getTicketFormFieldItemConfig(formFieldConfig, key) {
    return get(formFieldConfig, key, {});
}

export function getTicketTypeOptions(category, localTransporterAccount) {
    if (localTransporterAccount && includes(InternalIssuesAccounts, localTransporterAccount)) {
        return filter(mappedInternalIssuesTypes(category), (option) =>
            includes(
                [
                    FLEETX_ISSUES_TYPES.INSTALLATION,
                    FLEETX_ISSUES_TYPES.REPAIR,
                    FLEETX_ISSUES_TYPES.REMOVAL,
                    FLEETX_ISSUES_TYPES.REPLACEMENT,
                ],
                option.value
            )
        );
    }
    return mappedInternalIssuesTypes(category);
}
