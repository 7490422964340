import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import auctionRoutes from './auctions';
import contractManagementRoutes from './contractmanagement';
import customerContractManagementRoutes from './customerContractManagement';
import tmsConsignmentsRoutes from './tmsConsignment';
import rfqRoutes from 'components/dashboard/TMS/RFQ/rfqRoutes';
const VendorManagementComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement" */ 'components/dashboard/vendor_management/vendor_management_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditIndentComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-add-edit-indent" */ 'components/dashboard/vendor_management/add_edit_indent_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditIndentVehiclesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-add-edit-vehicles" */ 'components/dashboard/vendor_management/add_edit_indent_vehicles_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditIndentUsersComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-add-edit-users" */ 'components/dashboard/vendor_management/add_edit_indent_users_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditIndentEmailComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-add-edit-email" */ 'components/dashboard/vendor_management/add_edit_indent_email_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TransporterDriverList = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-transporter-driver-list" */ 'components/dashboard/vendor_management/transporter_driver_list'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TransporterVehicleList = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-transporter-vehicle-list" */ 'components/dashboard/vendor_management/transporter_vehicle_list'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewIndent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-view-indent" */ 'components/dashboard/vendor_management/Indent/ViewIndent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditFreightSettlementInvoice = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-freightsettlement" */ 'components/dashboard/vendor_management/FreightSettlement/add_edit_freight_settlement_invoice'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewFreightSettlementInvoice = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-freightsettlement" */ 'components/dashboard/vendor_management/FreightSettlement/view_freight_settlement_invoice'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const EPODManagementComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-epod" */ 'components/dashboard/vendor_management/epodNegotiation/epodManagementComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const FreightSettlementInvoicesListingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-freightsettlement" */ 'components/dashboard/vendor_management/freight_settlement_invoices'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const BillSummaryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-bill-summary" */ 'components/dashboard/vendor_management/BillSummary/billSummary'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TMSAnalyticsDashboardComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-tsmanalytics" */ 'components/dashboard/vendor_management/tmsAnalytics/TMSAnalyticsDashboardComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ManualPlanRoute = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-manual-plan-dispatch" */ 'components/dashboard/vendor_management/manualPlanDispatch/index'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DispatchPlannerRoute = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-dispatch-planner" */ 'components/dashboard/vendor_management/dispatchPlanner/index'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AutoDispatchPlanRoute = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-auto-dispatch-planner" */ 'components/dashboard/vendor_management/tmsConsignments/AutoDispatchPlan/index'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const EwayBillRoute = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-ewaybill-listing" */ 'components/dashboard/vendor_management/ewayBillListing/index'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const EwayBillPartARoute = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-ewaybill-parta" */ 'components/dashboard/vendor_management/ewayBillListing/ewayBillPartA'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const CreditDebitModuleRoute = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-credit-debit-module" */ 'components/dashboard/vendor_management/creditDebitModule/creditDebitModuleComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const JobReportListModule = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorManagement-job-report-list-module" */ 'components/dashboard/vendor_management/JobReportList/index'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const vendorsRoutes = [
    {
        index: true,
        lazy: VendorManagementComponent,
    },
    {
        path: 'freightsettlementinvoices/add',
        lazy: AddEditFreightSettlementInvoice,
    },
    {
        path: 'freightsettlementinvoices/edit/:id',
        lazy: AddEditFreightSettlementInvoice,
    },
    {
        path: 'freightsettlementinvoices/view/:id',
        lazy: ViewFreightSettlementInvoice,
    },
    {
        path: 'vehicles/add',
        lazy: AddEditIndentVehiclesComponent,
    },
    {
        path: 'vehicles/edit/:id',
        lazy: AddEditIndentVehiclesComponent,
    },
    {
        path: 'vehicles',
        lazy: TransporterVehicleList,
    },
    {
        path: 'users/add',
        lazy: AddEditIndentUsersComponent,
    },
    {
        path: 'users/edit/:id',
        lazy: AddEditIndentUsersComponent,
    },
    {
        path: 'users',
        lazy: TransporterDriverList,
    },
    {
        path: 'email/add',
        lazy: AddEditIndentEmailComponent,
    },
    {
        path: 'email/edit/:id',
        lazy: AddEditIndentEmailComponent,
    },
    {
        path: 'add',
        lazy: AddEditIndentComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditIndentComponent,
    },
    {
        path: 'epods',
        lazy: EPODManagementComponent,
    },
    {
        path: 'auto-dispatch-plan',
        lazy: AutoDispatchPlanRoute,
    },
    {
        path: ':id',
        lazy: ViewIndent,
    },
    {
        path: 'dispatchplanner',
        lazy: DispatchPlannerRoute,
    },
    {
        path: 'cndnmodule',
        lazy: CreditDebitModuleRoute,
    },
    {
        path: 'job-report-list',
        lazy: JobReportListModule,
    },
    {
        path: 'manualplandispatch',
        lazy: ManualPlanRoute,
    },
    {
        path: 'ewaybillparta',
        lazy: EwayBillPartARoute,
    },
    {
        path: 'ewaybill',
        lazy: EwayBillRoute,
    },
    {
        path: 'tsmanalytics',
        lazy: TMSAnalyticsDashboardComponent,
    },
    {
        path: 'freightsettlementinvoices',
        lazy: FreightSettlementInvoicesListingComponent,
    },
    {
        path: 'bill-summary',
        lazy: BillSummaryComponent,
    },
    {
        path: 'consignments/*',
        children: tmsConsignmentsRoutes,
    },
    {
        path: 'auctions/*',
        children: auctionRoutes,
    },
    {
        path: 'cms/*',
        children: contractManagementRoutes,
    },
    {
        path: 'customer/cms/*',
        children: customerContractManagementRoutes,
    },
    {
        path: 'rfq/*',
        children: rfqRoutes,
    },
];
export default vendorsRoutes;
