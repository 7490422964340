import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const StationaryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-stationary" */ 'components/dashboard/stationary/stationary_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditStationaryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditStationary" */ 'components/dashboard/stationary/add_edit_stationary_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const stationaryRoutes = [
    {
        path: 'all',
        lazy: StationaryComponent,
    },
    {
        path: 'add',
        lazy: AddEditStationaryComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditStationaryComponent,
    },
];
export default stationaryRoutes;
