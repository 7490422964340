import axios from 'axios';
import { get, join } from 'lodash';
import { parseQueryParams, ROOT_API_ALFRED, ROOT_API_ALFRED_V2, ROOT_REPORTING_NODE_API_URL } from '../../../constant';
import { getMomentTime } from '../../../utils/date_utils';
export const CREATE_TMS_CONSIGNMENT = 'CREATE_TMS_CONSIGNMENT';
export const UPDATE_TMS_CONSIGNMENT = 'UPDATE_TMS_CONSIGNMENT';
export const DELETE_TMS_CONSIGNMENT = 'DELETE_TMS_CONSIGNMENT';
export const FETCH_TMS_CONSIGNMENTS = 'FETCH_TMS_CONSIGNMENTS';
export const FETCH_TMS_CONSIGNMENT = 'FETCH_TMS_CONSIGNMENT';
export const CREATE_MULTI_CONSIGNMENT_INDENT = 'CREATE_MULTI_CONSIGNMENT_INDENT';
export const UPDATE_CONSIGNMENT_TRANSPORTERS = 'UPDATE_CONSIGNMENT_TRANSPORTERS';
export const CREATE_DISPATCH_PLANS = 'CREATE_DISPATCH_PLANS';
export const UPDATE_DISPATCH_PLANS = 'UPDATE_DISPATCH_PLANS';
export const UPDATE_DISPATCH_PLANS_ID = 'UPDATE_DISPATCH_PLANS_ID';
export const UPDATE_DISPATCH_PLANS_VALIDATE = 'UPDATE_DISPATCH_PLANS_VALIDATE';
export const UPDATE_DISPATCH_PLANS_REJECT = 'UPDATE_DISPATCH_PLANS_REJECT';
export const DOWNLOAD_TMS_CONSIGNMENT_REPORT = 'DOWNLOAD_TMS_CONSIGNMENT_REPORT';
export const FETCH_TMS_CONSIGNMENT_SUMMARY = 'FETCH_TMS_CONSIGNMENT_SUMMARY';
export const DOWNLOAD_AGGREGATE_TMS_CONSIGNMENT_REPORT = 'DOWNLOAD_AGGREGATE_TMS_CONSIGNMENT_REPORT';
export const FETCH_TMS_CONSIGNMENT_AUDIT_DIFF = 'FETCH_TMS_CONSIGNMENT_AUDIT_DIFF';
export const DOWNLOAD_TMS_CONSIGNMENT_SUMMARY_REPORT = 'DOWNLOAD_TMS_CONSIGNMENT_SUMMARY_REPORT';
export const UPDATE_CONSIGNMENT_PRIORITY = 'UPDATE_CONSIGNMENT_PRIORITY';
export const FETCH_TMS_CONSIGNMENT_DURATION = 'FETCH_TMS_CONSIGNMENT_DURATION';
export const FETCH_TMS_CONSIGNMENT_GRAPH = 'FETCH_TMS_CONSIGNMENT_GRAPH';
export function createConsignment(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ALFRED}/consignment`, data, config);
    return {
        type: CREATE_TMS_CONSIGNMENT,
        promise: request,
    };
}
export function updateConsignmentTMS(data, moreParams) {
    const config = {
        params: parseQueryParams({
            accountId: get(moreParams, 'transporterAccountId'),
            manageConsignment: get(moreParams, 'manageConsignment'),
        }),
    };
    const request = axios.put(`${ROOT_API_ALFRED}/consignment`, data, config);
    return {
        type: UPDATE_TMS_CONSIGNMENT,
        promise: request,
    };
}
export function fetchConsignmentAuditDiff(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/consignment/audit/diff/${id}`, config);
    return {
        type: FETCH_TMS_CONSIGNMENT_AUDIT_DIFF,
        promise: request,
    };
}
export function fetchConsignments(accesstoken, params = {}) {
    const { page = 1, size = 20, startDate, endDate, sourceAddressBookId, destinationAddressBookId, consignorId, consigneeId, status, consignmentType, consignmentNature, lrNumber, invoiceNumber, documentNumber, shipperLr, billNumber, sort = 'id,desc', groupId, sourceParentId, destinationParentId, sourceCity, destinationCity, sourcePincode, destinationPincode, sourceCode, destinationCode, consignmentIds, accountId, customFieldId, customFieldValue, customKeyName, materialCustomFieldId, materialCustomFieldValue, materialCustomKeyName, transporterId, containerNumber, containerType, containerSize, referenceNumber, tagIds, lrDate, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            size,
            page: page - 1,
            from: startDate ? getMomentTime(startDate).valueOf() : '',
            to: endDate ? getMomentTime(endDate).valueOf() : '',
            sourceAddressBookId,
            destinationAddressBookId,
            consignorId,
            consigneeId,
            consignmentStatus: status,
            consignmentType,
            consignmentNature,
            lrNumber,
            invoiceNumber,
            documentNumber,
            shipperLr,
            billNumber,
            sort,
            groupId,
            sourceParentId,
            destinationParentId,
            sourceCity,
            destinationCity,
            sourcePincode,
            destinationPincode,
            sourceCode,
            destinationCode,
            consignmentIds,
            accountId,
            customFieldId,
            customFieldValue,
            customKeyName,
            materialCustomFieldId,
            materialCustomFieldValue,
            materialCustomKeyName,
            transporterId,
            containerNumber,
            containerType,
            containerSize,
            referenceNumber,
            tagIds: join(tagIds, ','),
            lrDate,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/consignment`, config);
    return {
        type: FETCH_TMS_CONSIGNMENTS,
        promise: request,
    };
}
export function fetchConsignment(accesstoken, id, transporterAccountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId: transporterAccountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/consignment/${id}`, config);
    return {
        type: FETCH_TMS_CONSIGNMENT,
        promise: request,
    };
}
export function deleteConsignment(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.patch(`${ROOT_API_ALFRED}/consignment/disable-consignment`, [id], config);
    return {
        type: DELETE_TMS_CONSIGNMENT,
        promise: request,
    };
}
export function createMultiConsignmentIndent(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ALFRED_V2}/indent/consignment/bulk`, data, config);
    return {
        type: CREATE_MULTI_CONSIGNMENT_INDENT,
        promise: request,
    };
}
export function updateConsignmentTransporters(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ALFRED}/consignment/transporter`, data, config);
    return {
        type: UPDATE_CONSIGNMENT_TRANSPORTERS,
        promise: request,
    };
}
export function updateConsignmentBulk(accesstoken, data, transporterAccountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId: transporterAccountId,
        }),
    };
    const request = axios.put(`${ROOT_API_ALFRED}/consignment/bulk`, data, config);
    return {
        type: UPDATE_TMS_CONSIGNMENT,
        promise: request,
    };
}
export function createDispatchPlans(data, params) {
    const config = {
        params: params,
    };
    const request = axios.post(`${ROOT_API_ALFRED}/dispatch-planner`, data, config);
    return {
        type: CREATE_DISPATCH_PLANS,
        promise: request,
    };
}
export function updateDispatchPlans(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ALFRED}/dispatch-planner/add-dispatch`, data, config);
    return {
        type: UPDATE_DISPATCH_PLANS,
        promise: request,
    };
}
export function updateDispatchPlansById(accesstoken, data) {
    const dId = data.dispatchId;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ALFRED}/dispatch-planner/update-dispatch/${dId}`, data, config);
    return {
        type: UPDATE_DISPATCH_PLANS_ID,
        promise: request,
    };
}
export function validateDispatch(accesstoken, dId) {
    const data = {};
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.patch(`${ROOT_API_ALFRED}/dispatch-planner/validate-dispatch?dispatchId=${dId}`, data, config);
    return {
        type: UPDATE_DISPATCH_PLANS_VALIDATE,
        promise: request,
    };
}
export function rejectDispatch(accesstoken, dId) {
    const data = {};
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.patch(`${ROOT_API_ALFRED}/dispatch-planner/reject-dispatch?dispatchId=${dId}`, data, config);
    return {
        type: UPDATE_DISPATCH_PLANS_REJECT,
        promise: request,
    };
}
export function fetchNodeTMSConsignmentReport(accesstoken, reportFormat, data) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'blob' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            reportFormat,
        }),
        ...format,
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerTMSConsignmentReport`, data, config);
    return {
        type: DOWNLOAD_TMS_CONSIGNMENT_REPORT,
        promise: request,
    };
}
export function fetchVendorsConsignmentSummary(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params,
    };
    const request = axios.get(`${ROOT_API_ALFRED}/consignment/summary`, config);
    return {
        type: FETCH_TMS_CONSIGNMENT_SUMMARY,
        promise: request,
    };
}
export function fetchAggregateTMSConsignmentReport(accesstoken, params = {}, data = {}) {
    const { page = 1, size = 20, from, to, sourceAddressBookId, destinationAddressBookId, consignorId, consigneeId, status, consignmentType, consignmentNature, lrNumber, invoiceNumber, documentNumber, shipperLr, billNumber, sort = 'id, desc', groupId, sourceParentId, destinationParentId, sourceCity, destinationCity, sourcePincode, destinationPincode, sourceCode, destinationCode, consignmentIds, accountId, } = params;
    let format = { responseType: 'blob' };
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            page: page - 1,
            size,
            sourceAddressBookId,
            destinationAddressBookId,
            consignorId,
            consigneeId,
            consignmentStatus: status,
            consignmentType,
            consignmentNature,
            lrNumber,
            invoiceNumber,
            documentNumber,
            shipperLr,
            billNumber,
            sort,
            groupId,
            sourceParentId,
            destinationParentId,
            sourceCity,
            destinationCity,
            sourcePincode,
            destinationPincode,
            sourceCode,
            destinationCode,
            consignmentIds,
            accountId,
        }),
        ...format,
    };
    const request = axios.post(`${ROOT_API_ALFRED}/consignment/tms-report`, data, config);
    return {
        type: DOWNLOAD_AGGREGATE_TMS_CONSIGNMENT_REPORT,
        promise: request,
    };
}
export function fetchTmsConsignmentSummaryReport(accesstoken, params = {}) {
    const { from, to, dispatchPlanStatus, groupId, tagIds, lrDate, transporterId } = params;
    let format = { responseType: 'blob' };
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            dispatchPlanStatus,
            groupId,
            tagIds,
            lrDate,
            transporterId,
        }),
        ...format,
    };
    const request = axios.get(`${ROOT_API_ALFRED}/dispatch-planner/analytics/report`, config);
    return {
        type: DOWNLOAD_TMS_CONSIGNMENT_SUMMARY_REPORT,
        promise: request,
    };
}
export function updateConsignmentPriority(data) {
    const request = axios.post(`${ROOT_API_ALFRED}/consignment/bulk-set-priority`, data, {});
    return {
        type: UPDATE_CONSIGNMENT_PRIORITY,
        promise: request,
    };
}
export function fetchVendorsConsignmentStatusDuration(params) {
    const config = {
        params,
    };
    const request = axios.get(`${ROOT_API_ALFRED}/consignment/status/analytics`, config);
    return {
        type: FETCH_TMS_CONSIGNMENT_DURATION,
        promise: request,
    };
}
export function fetchVendorsConsignmentGraphData(params) {
    const config = {
        params,
    };
    const request = axios.get(`${ROOT_API_ALFRED}/consignment/date/analytics`, config);
    return {
        type: FETCH_TMS_CONSIGNMENT_GRAPH,
        promise: request,
    };
}
