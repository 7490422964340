import { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/login/login_action';
import SuccessModal from '../utils/success_modal';
import { formErrors, image } from '../../constant';
import LandingHeader from '../landing/landing_header_container';
import { withRoute } from '../dashboard/customHocs/withRoute';

class PasswordReset extends Component {
    mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            passwordUpdate: false,
        };
        this.onModalToggle = this.onModalToggle.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
    }
    onSubmit(values) {
        return this.props.resetPassword(values).then((result) => {
            if (!this.mounted) {
                return;
            }

            if (!result.error) {
                this.setState({ passwordUpdate: true });
            } else {
                if (result.payload.response && result.payload.response.data.error_description) {
                    throw new SubmissionError({
                        _error: result.payload.response.data.error_description,
                    });
                } else {
                    throw new SubmissionError({
                        _error: 'Something went wrong, please try again later!',
                    });
                }
            }
        });
    }

    renderField(field) {
        const {
            input,
            label,
            type,
            mbcls,
            meta: { touched, error },
        } = field;

        const className = `form-group ${touched && error ? 'has-danger' : ''} ${mbcls}`;
        return (
            <div>
                <div className="text-help text-danger">{touched ? error : ''}</div>
                <div className={className}>
                    <input className="form-control" placeholder={label} type={type} {...input} />
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onModalToggle() {
        this.props.navigate('/login', { replace: true });
    }

    render() {
        const { handleSubmit, error, pristine, submitting } = this.props;
        return (
            <div className="signin">
                <LandingHeader />
                <div className="container">
                    {this.state.passwordUpdate && (
                        <SuccessModal
                            message="Your password has been successfully updated. Please login."
                            onModalToggle={this.onModalToggle}
                        />
                    )}
                    <div className="space-top-2 row">
                        <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 offset-1 col-10">
                            <img
                                alt="fleetx"
                                width="60"
                                className="mx-auto"
                                style={{ display: 'block' }}
                                src={image('/public/img/logos/fleetx/fleetx-logo-short.svg?v=2')}
                            />
                        </div>
                    </div>
                    <div className="space-top-1 row">
                        <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3 col-10 offset-1">
                            <div>
                                <h2 className=" text-center">Set new password</h2>
                                <p className="text-center space-bottom-2">
                                    Please enter the confirmation code that has been sent to your Email address
                                </p>
                                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                    <Field
                                        name="otp"
                                        type="number"
                                        mbcls="mb-3"
                                        component={this.renderField}
                                        label="Enter verification code"
                                    />
                                    <Field
                                        name="newPassword"
                                        type="password"
                                        mbcls="mb-4"
                                        component={this.renderField}
                                        label="Enter new password"
                                    />

                                    <button
                                        type="submit"
                                        className="btn btn-block btn-primary"
                                        disabled={pristine || submitting}
                                    >
                                        Change Password
                                    </button>
                                </form>
                                {error && <div className="my-2 text-danger text-center">{formErrors(error)}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function validate(values) {
    const errors = {};

    if (!values.otp) {
        errors.otp = 'Required';
    }
    if (!values.newPassword) {
        errors.newPassword = 'Required';
    }

    if (values.newPassword) {
        if (!/^(?=.*[A-Z])/i.test(values.newPassword)) {
            errors.newPassword = 'Password must contain at least 1 uppercase character';
        } else if (!/^(?=.*[0-9])/i.test(values.newPassword)) {
            errors.newPassword = 'Password must contain at least 1 numeric character';
        } else if (!/^(?=.*[!@#\$%\^&\*])/i.test(values.newPassword)) {
            errors.newPassword = 'Password must contain at least 1 special character';
        } else if (!/^(?=.{8,30})/i.test(values.newPassword)) {
            errors.newPassword = 'Password must be eight characters or longer';
        }
    }

    return errors;
}

PasswordReset = reduxForm({
    validate,
    form: 'PasswordResetForm',
})(PasswordReset);

PasswordReset = withRoute(
    connect(
        (state) => ({
            initialValues: {
                token: state.login.resetPasswordToken,
            },
        }),
        { resetPassword }
    )(PasswordReset)
);

export default PasswordReset;
