import { createGlobalStyle } from './utils';
// -------- SHELL account(8806)) theme -------------
// primary
const SHELL_PRIMARY = '#E01F26';
const SHELL_PRIMARY_2 = 'rgba(224, 31, 38, 0.90)';
const SHELL_PRIMARY_3 = 'rgba(224, 31, 38, 0.50)';
const SHELL_PRIMARY_HOVER = '#ee8162d6';
function getPrimaryColor(factor) {
    return factor ? `rgba(224, 31, 38, ${factor})` : SHELL_PRIMARY;
}
const SHELL_PRIMARY2 = '#FED307';
const SHELL_PRIMARY2_2 = 'rgba(254,211,7,0.90)';
const SHELL_PRIMARY2_3 = 'rgba(254,211,7,0.50)';
const SHELL_PRIMARY2_HOVER = 'rgba(112,111,89,0.25)';
// secondary
const SHELL_SECONDARY = '#FFD945';
const SHELL_SECONDARY_2 = '#FEE27F';
const SHELL_SECONDARY_3 = '#FFEDAE';
const ShellCssBaseLine = createGlobalStyle `
  html {


    // changes at logo
    .app-header.navbar .navbar-brand {
      height: 40px;
      border-bottom: none;
    }

    //change font
    body, .fleetx-default-font-family, .daterangepicker, .container, .input-range__label, .map-traffic-button, .text, .sidebaricon-text {
      font-family: 'Futura Lt BT', Arial, sans-serif !important;
    }

    body {
      .text-primary, h1, h2, h3, h4, h5, h6, .h1, .h2 {
        color: ${SHELL_PRIMARY} !important;
      }


      .text-danger {
        color: ${SHELL_PRIMARY} !important;
      }

      .text-warning {
        color: ${SHELL_PRIMARY2} !important;
      }

      .custom-alert-box {
        .fxicon-info {
          &:before {
            color: ${SHELL_PRIMARY} !important;
          }
        }

        border-left-color: ${SHELL_PRIMARY} !important;
      }

      .bg-primary {
        background-color: ${SHELL_PRIMARY2} !important;
        border-color: ${SHELL_PRIMARY2} !important;;
        color: ${SHELL_PRIMARY} !important;
      }

      .btn-success {
        background-color: ${SHELL_PRIMARY2};
        border-color: ${SHELL_PRIMARY2};

        color: ${SHELL_PRIMARY} !important;

        .fa[class^='fxicon-']:before, .fa[class*=' fxicon-']:before {
          color: ${SHELL_PRIMARY} !important;
        }


        &:hover, &:active, &:focus {
          background-color: ${SHELL_PRIMARY2_2};
          border-color: ${SHELL_PRIMARY2_2};
        }

        &:focus {
          box-shadow: 0 0 0 3px ${SHELL_PRIMARY2_3};
        }

        &:disabled {
          background-color: ${SHELL_PRIMARY2_2};
          border-color: ${SHELL_PRIMARY2_2};
        }
      }

      .btn-primary {
        background-color: ${SHELL_PRIMARY};
        border-color: ${SHELL_PRIMARY};

        &:hover, &:active, &:focus {
          background-color: ${SHELL_PRIMARY_2};
          border-color: ${SHELL_PRIMARY_2};
        }

        &:focus {
          box-shadow: 0 0 0 3px ${SHELL_PRIMARY_3};
        }

        &:disabled {
          background-color: ${SHELL_PRIMARY_2};
          border-color: ${SHELL_PRIMARY_2};
        }
      }
    }

    .show {
      & > .btn-success {
        &.dropdown-toggle {
          background-color: ${SHELL_PRIMARY2_2};
          border-color: ${SHELL_PRIMARY2_2};
        }
      }

      & > .btn-primary {
        &.dropdown-toggle {
          background-color: ${SHELL_PRIMARY_2};
          border-color: ${SHELL_PRIMARY_2};
        }
      }
    }


    .nav-pills {
      .nav-link {
        &.active {
          background-color: ${SHELL_PRIMARY};
        }
      }
    }

    .nav-tabs {
      // nav-link
      .nav-link {
        &.active {
          color: ${SHELL_PRIMARY};
          border-bottom-color: ${SHELL_PRIMARY};
        }
      }
    }

    .vehicle-nav-pills li a.active {
      color: ${SHELL_PRIMARY} !important;
      border-bottom-color: ${SHELL_PRIMARY} !important;
    }


    // dashboard header
    .app-header {
      &.dashboard-header-v2 {
        .dashboard-header-view-selected {
          border-bottom-color: ${SHELL_PRIMARY};
        }

        .dashboard-header-view-selected-on-hover {
          &:hover {
            border-bottom-color: ${SHELL_PRIMARY_3};
          }
        }
      }
    }


    //sidebar chnages

    .sidebar-toggler, .mobile-sidebar-toggler {
      color: ${SHELL_PRIMARY_2} !important;

      &:hover {
        color: ${SHELL_PRIMARY} !important;
      }

    }

    .sidebar-new {
      background-color: ${SHELL_PRIMARY2} !important;

      &.sidebar .nav-dropdown.open {
        background-color: ${SHELL_PRIMARY2} !important;
      }

      .settings-wrapper {
        background-color: ${SHELL_PRIMARY2} !important
      }

      &.sidebar {
        .nav-item {
          color: ${SHELL_PRIMARY};

          &:hover {
            > .nav-link {
              background-color: ${SHELL_PRIMARY2_HOVER} !important;
            }
          }
        }

        .nav-link {
          &:hover {
            background-color: ${SHELL_PRIMARY2_HOVER} !important;
          }

          &.active {
            background-color: ${SHELL_PRIMARY2_HOVER} !important;
          }
        }

        .nav-dropdown-items {
          .nav-item {
            .nav-link {
              color: ${SHELL_PRIMARY} !important;

              .text-white {
                color: ${SHELL_PRIMARY} !important;
              }
            }

            &:hover {
              .nav-link {
                background-color: ${SHELL_PRIMARY2_HOVER} !important;
              }
            }
          }
        }

        .nav > .nav-dropdown {
          > .nav-dropdown-items {
            background-color: ${SHELL_PRIMARY2} !important;
          }
        }
      }

      .grouping-title {
        color: ${SHELL_PRIMARY} !important;
      }

      .sidebar-icon-text {
        color: ${SHELL_PRIMARY};
      }
    }

    // side bar icons
    .realtime-icon {
      background-image: url('/public/img/icons/shellAccount/realtime-icon.svg') !important;
    }

    .overview-icon {
      background-image: url('/public/img/icons/shellAccount/overview-icon.svg') !important;
    }

    .history-icon {
      background-image: url('/public/img/icons/shellAccount/history-icon.svg') !important;
    }

    .vehicle-icon {
      background-image: url('/public/img/icons/shellAccount/vehicle-icon.svg') !important;
    }

    .jobs-icon {
      background-image: url('/public/img/icons/shellAccount/jobs-icon.svg') !important;
    }

    .reminder-icon {
      background-image: url('/public/img/icons/shellAccount/reminder-icon.svg') !important;
    }

    .finance-icon {
      background-image: url('/public/img/icons/shellAccount/finance-icon.svg') !important;
    }

    .vendor-icon {
      background-image: url('/public/img/icons/shellAccount/vendor-icon.svg') !important;
    }

    .vehicle-records-icon {
      background-image: url('/public/img/icons/shellAccount/vehicle-records.svg') !important;
    }

    .reports-icon {
      background-image: url('/public/img/icons/shellAccount/reports.svg') !important;
    }

    .alarm-icon {
      background-image: url('/public/img/icons/shellAccount/alarm.svg') !important;
    }

    .issues-icon {
      background-image: url('/public/img/icons/shellAccount/issues.svg') !important;
    }

    .settings-icon {
      background-image: url('/public/img/icons/shellAccount/settings.svg') !important;
    }

    .quick-tour-guide-icon {
      background-image: url('/public/img/icons/shellAccount/quick-tour-guide.svg') !important;
      cursor: pointer;
    }

  }`;
const shellThemeOverrider = {
    token: {
        colorPrimary: '#E01F26',
        colorWarning: '#ffc107',
        colorError: '#f86c6b',
        colorSuccess: '#E01F26',
        fontFamily: "'Futura Lt BT', Arial, sans-serif !important",
    },
};
export default {
    CssBaseline: ShellCssBaseLine,
    theme: shellThemeOverrider,
};
