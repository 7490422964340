import {handle} from "redux-pack";

import {
    ADD_VEHICLE_IMAGE, DELETE_VEHICLE_IMAGE, FETCH_VEHICLE_IMAGES
} from "../../actions/dashboard/vehicle_image_action";

const initialState = {
    isVehicleImageLoading: false, error: null, vehicleImageList: []
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case ADD_VEHICLE_IMAGE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case DELETE_VEHICLE_IMAGE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, error: null
                }),
                finish: prevState => ({...prevState}),
                failure: prevState => ({...prevState, error: payload})
            });

        case FETCH_VEHICLE_IMAGES :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isVehicleImageLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isVehicleImageLoading : false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, vehicleImageList: payload.data.content
                }),
            });

        default:
            return state;
    }
}
