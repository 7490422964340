import { handle } from 'redux-pack';
import { FETCH_EWAY_BILL_PROFILE } from '../../actions/dashboard/ewayBill/ewayBillProfileActions';
const initialState = {
    ewayBillProfile: {},
    isEwayBillProfileLoading: false,
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_EWAY_BILL_PROFILE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isEwayBillProfileLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isEwayBillProfileLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    ewayBillProfile: payload.data,
                }),
            });
        default:
            return state;
    }
}
