import { Component } from 'react';
import { map, filter } from 'lodash';
import { FxPreviewGroup, FxSpace } from '../sharedV2';
import { getImageKitLink } from 'constant';
import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { saveAsRemote } from './report_utils';
import { getFileExtFromUrl, getFileNameFromUrl } from './fileUploadutils';

const StyledLightBoxToolBar = styled.div`
    .toolbar-wrapper {
        position: fixed;
        bottom: 5px;
        left: 50%;
        padding: 0px 24px;
        color: #fff;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 100px;
        transform: translateX(-50%);
        margin-top: 12px;
        pagging: 12px;
    }

    .toolbar-wrapper .anticon {
        padding: 12px;
        cursor: pointer;
    }

    .toolbar-wrapper .anticon[disabled] {
        cursor: not-allowed;
        opacity: 0.3;
    }

    .toolbar-wrapper .anticon:hover {
        opacity: 0.3;
    }
`;
class LightBoxUtils extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: props.photoIndex || 0,
        };
    }

    onCloseRequest = () => {
        this.props.onCloseRequest();
    };

    render() {
        const { photoIndex } = this.state;
        const { images, imagesMetaData = [] } = this.props;
        const parsedImages = map(
            filter(images, (img) => !!img),
            (img) => {
                return getImageKitLink(img);
            }
        );

        const visible = !!parsedImages.length;

        const setVisible = () => {
            this.props.onCloseRequest();
        };

        const onDownload = () => {
            const image = parsedImages[photoIndex];
            if (!image) return;
            saveAsRemote(
                image,
                `${this.props.getFileName ? this.props.getFileName(image) : getFileNameFromUrl(image)}`
            );
        };

        return (
            <FxPreviewGroup
                preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                    current: photoIndex,
                    onChange: (current, prevCurrent) => {
                        this.setState({ photoIndex: current });
                    },
                    toolbarRender: (
                        _,
                        {
                            transform: { scale },
                            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                        }
                    ) => (
                        <StyledLightBoxToolBar>
                            <FxSpace size={12} className="toolbar-wrapper">
                                <DownloadOutlined onClick={onDownload} />
                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                <SwapOutlined onClick={onFlipX} />
                                <RotateLeftOutlined onClick={onRotateLeft} />
                                <RotateRightOutlined onClick={onRotateRight} />
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                            </FxSpace>
                        </StyledLightBoxToolBar>
                    ),
                }}
                items={parsedImages}
            />
        );
    }
}

export default LightBoxUtils;
