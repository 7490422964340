import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP, ROOT_REPORTING_NODE_API_URL } from 'constant';
export const PART_ISSUANCE_LIST = 'PART_ISSUANCE_LIST';
export const CREATE_PART_ISSUANCE = 'CREATE_PART_ISSUANCE';
export const FETCH_ISSUANCE_BY_ID = 'FETCH_ISSUANCE_BY_ID';
export const DELETE_ISSUANCE_BY_ID = 'DELETE_ISSUANCE_BY_ID';
export const DOWNLOAD_PART_ISSUANCE_REPORT = 'DOWNLOAD_PART_ISSUANCE_REPORT';
export function getPartIssuanceList(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}part-issuance-vehicle/listing`, config);
    return {
        type: PART_ISSUANCE_LIST,
        promise: request,
    };
}
export function createPartIssuance(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}part-issuance-vehicle`, data, config);
    return {
        type: CREATE_PART_ISSUANCE,
        promise: request,
    };
}
export function fetchIssuanceById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}part-issuance-vehicle/${id}`, config);
    return {
        type: FETCH_ISSUANCE_BY_ID,
        promise: request,
    };
}
export function deleteIssuanceById(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_ERP}part-issuance-vehicle/${id}`, config);
    return {
        type: DELETE_ISSUANCE_BY_ID,
        promise: request,
    };
}
export function fetchNodePartIssuanceReport(accesstoken, reportFormat, params) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            reportFormat,
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getPartIssuanceReport`, config);
    return {
        type: DOWNLOAD_PART_ISSUANCE_REPORT,
        promise: request,
    };
}
