import { jsx as _jsx } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRoute } from 'components/dashboard/customHocs/withRoute';
import { isSwitchVehicles } from '../../roles/permission_utils';
import { get } from 'lodash';
import ServiceEntriesComponentOld from 'components/dashboard/maintenance/serviceentries/service_entries_component_old';
import ServiceEntriesComponent from 'components/dashboard/maintenance/serviceentries/service_entries_component';
import { showOldServiceEntryForAccount } from 'utils/account_utils';
const ServiceEntryWrapper = (props) => {
    return showOldServiceEntryForAccount() ? _jsx(ServiceEntriesComponentOld, {}) : _jsx(ServiceEntriesComponent, {});
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    const isSwitchUserAllowed = isSwitchVehicles(get(state.login, 'data.scope'));
    return {
        isSwitchUserAllowed,
    };
}
export default withRoute(connect(mapStateToProps, mapDispatchToProps)(ServiceEntryWrapper));
