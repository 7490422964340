import { handle } from 'redux-pack';

import {
    FETCH_ACTIVE_DEVICE_INSTALLATIONS,
    FETCH_TECHNICIAN_DUTIES,
    FETCH_VEHICLE_LIST_MASTER_BY_ACCOUNT_ID,
    FETCH_TECHNICIAN_DUTY_REASONS,
    FETCH_TECHNICIAN_DUTY_TRACKING_REASONS,
    TECHNICIAN_RUNNING_REPORT,
} from '../../../actions/dashboard/internal_tools/operations_action';

const initialState = {
    isLoading: false,
    error: null,
    isFinished: false,
    activeDeviceInstallationsData: [],
    technicianDuties: {},
    fetchingTechnicianDuties: false,
    vehiclesListMasterByAccountId: [],
    dutyReasons: [],
    dutyTrackingReasons: [],
    technicianRunningReport: [],
    totalElementsTechnicianRunningReport: 0,
    isFetchingTechnicianRunningReport: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_ACTIVE_DEVICE_INSTALLATIONS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload.data }),
                success: (prevState) => ({
                    ...prevState,
                    activeDeviceInstallationsData: payload.data.queuedSettings,
                }),
            });
        case FETCH_TECHNICIAN_DUTIES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    fetchingTechnicianDuties: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, fetchingTechnicianDuties: false }),
                failure: (prevState) => ({ ...prevState, error: payload.data }),
                success: (prevState) => ({
                    ...prevState,
                    technicianDuties: payload.data,
                }),
            });
        case FETCH_VEHICLE_LIST_MASTER_BY_ACCOUNT_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    fetchingVehiclesListMasterByAccountId: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    fetchingVehiclesListMasterByAccountId: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload.data }),
                success: (prevState) => ({
                    ...prevState,
                    vehiclesListMasterByAccountId: payload.data,
                }),
            });
        case FETCH_TECHNICIAN_DUTY_REASONS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isFetchingTechnicianDutyReasons: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFetchingTechnicianDutyReasons: false }),
                failure: (prevState) => ({ ...prevState, error: payload.data }),
                success: (prevState) => ({
                    ...prevState,
                    dutyReasons: payload.data,
                }),
            });
        case FETCH_TECHNICIAN_DUTY_TRACKING_REASONS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isFetchingTechnicianDutyTrackingReasons: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFetchingTechnicianDutyTrackingReasons: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload.data }),
                success: (prevState) => ({
                    ...prevState,
                    dutyTrackingReasons: payload.data,
                }),
            });
        case TECHNICIAN_RUNNING_REPORT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isFetchingTechnicianRunningReport: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFetchingTechnicianRunningReport: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload.data }),
                success: (prevState) => ({
                    ...prevState,
                    technicianRunningReport: payload.data.content,
                    totalElementsTechnicianRunningReport: payload.data.totalElements,
                }),
            });
        default:
            return state;
    }
}
