import { handle } from 'redux-pack';
import {
    FETCH_INVOICE_SUMMARY,
    FETCH_INVOICES,
    FETCH_INVOICES_SUMMARY,
} from '../../../actions/dashboard/finances/invoice_action';

const initialState = {
    isLoading: false,
    error: null,
    invoiceSummary: null,
    invoices: [],
    isFetchInvoicesLoading: false,
    isFetchInvoiceSummaryLoading: false,
    isFetchInvoicesSummaryLoading: false,
    invoicesSummary: {},
};

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_INVOICES:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    isFetchInvoicesLoading: true,
                    error: null,
                    invoices: [],
                }),
                finish: prevState => ({ ...prevState, isFetchInvoicesLoading: false, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    invoices: payload.data.content,
                }),
            });
        case FETCH_INVOICE_SUMMARY:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    isFetchInvoiceSummaryLoading: true,
                    error: null,
                    invoiceSummary: null,
                }),
                finish: prevState => ({ ...prevState, isFetchInvoiceSummaryLoading: false, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    invoiceSummary: payload.data,
                }),
            });
        case FETCH_INVOICES_SUMMARY:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    isFetchInvoicesSummaryLoading: true,
                    error: null,
                    invoicesSummary: {},
                }),
                finish: prevState => ({ ...prevState, isFetchInvoicesSummaryLoading: false, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    invoicesSummary: payload.data,
                }),
            });
        default:
            return state;
    }
}
