import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const AddEditHireSlipComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-hire-slip-add-edit" */ 'components/dashboard/hire_slip/forms/AddEditHireSlipComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditHireSlipAdvanceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-hire-slip-advance-add-edit" */ 'components/dashboard/hire_slip/forms/advance/AddEditHireSlipAdvanceComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditHireSlipDetentionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-hire-slip-detention-add-edit" */ 'components/dashboard/hire_slip/forms/detention/AddEditHireSlipDetentionComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const HireSlipsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-hire-slip-hire-slips" */ 'components/dashboard/hire_slip/HireSlipsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const HireSlipFormsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-hire-slip-hire-slip-forms" */ 'components/dashboard/hire_slip/forms/HireSlipFormsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const hireslipRoutes = [
    {
        path: 'add',
        lazy: AddEditHireSlipComponent,
    },
    {
        path: 'edit/:id',
        lazy: HireSlipFormsComponent,
    },
    {
        path: 'all',
        lazy: HireSlipsComponent,
    },
    {
        path: 'advance/edit/:id',
        lazy: AddEditHireSlipAdvanceComponent,
    },
    {
        path: 'detention/edit/:id',
        lazy: AddEditHireSlipDetentionComponent,
    },
];
export default hireslipRoutes;
