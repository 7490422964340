import { jsx as _jsx } from "react/jsx-runtime";
import LoginComponent from '../../components/login/LoginComponent';
import AutoLoginComponent from '../../components/login/auto_login_component';
import SignUp from '../../components/login/sign_up';
import ForgotPasswordNew from '../../components/login/ForgotPasswordNew';
import PasswordReset from '../../components/login/password_reset';
const userRoutes = [
    { path: 'login', element: _jsx(LoginComponent, {}) },
    { path: 'autologin', element: _jsx(AutoLoginComponent, {}) },
    { path: 'signupwithfleetxmaster', element: _jsx(SignUp, {}) },
    { path: 'forgotpassword', element: _jsx(ForgotPasswordNew, {}) },
    { path: 'passwordreset', element: _jsx(PasswordReset, {}) },
];
export default userRoutes;
