import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { DispatchState } from './constant';
import { App, ConfigProvider, getTheme } from './theme';

import i18n from './i18n';
import configureStore from './configureStore';

import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import '../scss/core/_dropdown-menu-right.scss';
import(/* webpackPreload: true */ '../scss/style.scss');

import { router } from './routes';

//https://gist.github.com/kentaromiura/6bb9d0061c92087792f289dfde4989cd
// The following code patches google.maps API since in `onRemove` they don't check for null
// sometimes this conflicts with the way React handle DOM.
const PATCH_ONREMOVE = Symbol.for('Patch onRemove');

function waitForGoogleThenPatch() {
    /* global google */
    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
        window.requestAnimationFrame(waitForGoogleThenPatch);
    } else {
        const Marker = google.maps.Marker;
        if (Marker[PATCH_ONREMOVE]) return;
        Marker[PATCH_ONREMOVE] = true;
        const patch = (onRemove) => {
            return function onRemovePatched(...args) {
                let temp = document.createElement('div');

                if (!this.labelDiv_.parentNode) {
                    temp.appendChild(this.labelDiv_);
                }
                if (!this.eventDiv_.parentNode) {
                    temp.appendChild(this.eventDiv_);
                }
                if (!this.listeners_) {
                    this.listeners_ = [];
                }
                onRemove.call(this, ...args);
                temp = null;
            };
        };

        Marker.prototype.setMap = ((setMap) => {
            return function setMapPatched(...args) {
                if (this.label) {
                    const proto = Object.getPrototypeOf(this.label);
                    if (!proto[PATCH_ONREMOVE]) {
                        proto[PATCH_ONREMOVE] = true;
                        proto.onRemove = patch(proto.onRemove);
                    }
                }

                setMap.call(this, ...args);
            };
        })(Marker.prototype.setMap);
    }
}

if (typeof window !== 'undefined' && 'requestAnimationFrame' in window) {
    window.requestAnimationFrame(waitForGoogleThenPatch);
}

async function init() {
    const appStore = await configureStore();
    window.FX_APP_STORE_GET_STATE = appStore.getState;
    window[DispatchState] = appStore.dispatch;

    const theme = getTheme();

    const container = document.querySelector('.root');
    const root = createRoot(container);

    root.render(
        <I18nextProvider i18n={i18n}>
            <ConfigProvider theme={theme}>
                <App>
                    <Provider store={appStore}>
                        <Suspense fallback={<div />}>
                            <RouterProvider router={router} />
                        </Suspense>
                    </Provider>{' '}
                </App>
            </ConfigProvider>
        </I18nextProvider>
    );
}

init();
