import { handle } from 'redux-pack';
import { PART_STOCK_SUMMARY, COMPLETE_PART_INVENTORY, FETCH_PARTS_LIST, COMPLETE_PART_ENTRY_BY_ID, PART_STORE_HISTORY_BY_ID, COMPLETE_PART_LIST, } from 'actions/dashboard/part_stock_inventory/parts_entry_action';
import { get } from 'lodash';
import { PART_ISSUANCE_LIST } from 'actions/dashboard/part_stock_inventory/parts_issuance_action';
const initialState = {
    isLoading: false,
    error: null,
    data: [],
};
export function partStockReducer(state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case PART_STOCK_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartModelsListLoading: false }),
                failure: (prevState) => ({ ...prevState, isLoading: false, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: payload.data,
                    isLoading: false,
                }),
            });
        default:
            return state;
    }
}
const initialStateComplete = {
    isLoading: false,
    error: null,
    data: [],
    totalElements: 0,
    size: 0,
};
export function completePartInventoryReducer(state = initialStateComplete, action, root) {
    const { type, payload } = action;
    switch (type) {
        case COMPLETE_PART_INVENTORY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartModelsListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartModelsListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        data: get(payload, 'data.content'),
                        isLoading: false,
                        totalElements: get(payload, 'data.totalElements'),
                        size: get(payload, 'data.size'),
                    };
                },
            });
        case COMPLETE_PART_LIST:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        data: payload.data.content,
                        isLoading: false,
                        totalElements: payload.data.totalElements,
                    };
                },
            });
        default:
            return state;
    }
}
export function fetchPartListReducer(state = initialStateComplete, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_PARTS_LIST:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartModelsListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        data: get(payload, 'data.content'),
                        isLoading: false,
                        totalElements: get(payload, 'data.totalElements'),
                        size: get(payload, 'data.size'),
                    };
                },
            });
        default:
            return state;
    }
}
export function completePartInventoryByIdReducer(state = initialStateComplete, action, root) {
    const { type, payload } = action;
    switch (type) {
        case COMPLETE_PART_ENTRY_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartModelsListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartModelsListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        data: get(payload, 'data.content'),
                        isLoading: false,
                        totalElements: get(payload, 'data.totalElements'),
                        size: get(payload, 'data.size'),
                    };
                },
            });
        default:
            return state;
    }
}
export function partStoreHistoryByIdReducer(state = initialStateComplete, action, root) {
    const { type, payload } = action;
    switch (type) {
        case PART_STORE_HISTORY_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartModelsListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartModelsListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        data: get(payload, 'data.content'),
                        isLoading: false,
                        totalElements: get(payload, 'data.totalElements'),
                        size: get(payload, 'data.size'),
                    };
                },
            });
        default:
            return state;
    }
}
export function partIssuanceListReducer(state = initialStateComplete, action, root) {
    const { type, payload } = action;
    switch (type) {
        case PART_ISSUANCE_LIST:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartModelsListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartModelsListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        data: get(payload, 'data.content'),
                        isLoading: false,
                        totalElements: get(payload, 'data.totalElements'),
                        size: get(payload, 'data.size'),
                    };
                },
            });
        default:
            return state;
    }
}
