import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils'; // Update with actual import path
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
const JobAnalyticsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-job-analytics" */ 'components/dashboard/route_dispatch/analytics/job_analytics_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewJobBookingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-jobbookings-view" */ 'components/dashboard/route_dispatch/jobbookings/view_job_booking_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const JobBookingsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-jobbookings" */ 'components/dashboard/route_dispatch/jobbookings/job_bookings_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditExpressRoadwaysJobComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-route-add-edit-expressroadways" */ 'components/dashboard/route_dispatch/jobs/expressroadways/add_edit_express_roadways_form'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const JobsComponentWrapper = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-jobs" */ 'components/dashboard/route_dispatch/jobs/JobsComponentWrapper'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditJobComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-job-add-edit" */ 'components/dashboard/route_dispatch/jobs/add_edit_job_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditJobComponentV2 = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-job-add-edit_v2" */ 'components/dashboard/route_dispatch/jobs/add_edit_job_component_v2'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditManualJobComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-route-add-edit-manual" */ 'components/dashboard/route_dispatch/jobs/expressroadways/add_edit_express_roadways_form'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewVendorListComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-venodor-list" */ 'components/dashboard/route_dispatch/route/vendor_list'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const JobDetailComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-job-view" */ 'components/dashboard/route_dispatch/jobs/job_detail_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const RouteJobDispatchWrapper = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-job-dispatch-job-view" */ 'components/dashboard/route_dispatch/routeJobDispatchWrapper'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const routeJobDispatchRoutes = [
    {
        // Index routes render into their parent's Outlet at their parent's URL (like a default child route).
        index: true,
        lazy: JobsComponentWrapper,
    },
    {
        path: 'analytics',
        lazy: JobAnalyticsComponent,
    },
    {
        path: 'bookings',
        lazy: JobBookingsComponent,
    },
    {
        path: 'bookings/add',
        lazy: RouteJobDispatchWrapper,
    },
    {
        path: 'bookings/edit/:id',
        lazy: RouteJobDispatchWrapper,
    },
    {
        path: 'add',
        lazy: AddEditJobComponent,
    },
    {
        path: 'v2/add',
        lazy: AddEditJobComponentV2,
    },
    {
        path: 'v2/edit/:id',
        lazy: AddEditJobComponentV2,
    },
    {
        path: 'v2/:id/:activeTab?',
        lazy: AddEditJobComponentV2,
    },
    {
        path: 'v2/template/add',
        lazy: AddEditJobComponentV2,
    },
    {
        path: 'v2/template/edit/:templateId',
        lazy: AddEditJobComponentV2,
    },
    {
        path: 'manual/add',
        lazy: AddEditManualJobComponent,
    },
    {
        path: 'manual/edit/:id',
        lazy: AddEditManualJobComponent,
    },
    {
        path: 'vendorlist',
        lazy: ViewVendorListComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditJobComponent,
    },
    {
        path: ':id/:activeTab?',
        lazy: JobDetailComponent,
    },
    {
        path: 'expressroadways/add',
        lazy: AddEditExpressRoadwaysJobComponent,
    },
    {
        path: 'expressroadways/edit/:id',
        lazy: AddEditExpressRoadwaysJobComponent,
    },
    {
        path: 'bookings/view/:id',
        lazy: ViewJobBookingComponent,
    },
];
export default routeJobDispatchRoutes;
