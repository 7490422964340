import axios, { CancelToken } from 'axios';
import { FREQUENCY, ROOT_API_URL, parseQueryParams, ROOT_REPORTING_NODE_API_URL } from '../../constant';
import { REPORT_FORMAT } from '../../utils/report_utils';

export const FETCH_DTCS = 'fetch_dtcs';
export const FETCH_DTCS_GRAPH = 'fetch_dtcs_graph';
export const FETCH_DTCS_REPORT = 'fetch_dtcs_report';
export const UPDATE_DTC = 'update_dtc';
export const DTCS_REPORT = 'DTCS_REPORT';
export const DTCS_VEHICLE_MAKERS = 'DTCS_VEHICLE_MAKERS';
export const DTCS_VEHICLE_MODALS = 'DTCS_VEHICLE_MODALS';

export function fetchDTCs(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}dtc/`, config);

    return {
        type: FETCH_DTCS,
        promise: request,
    };
}
export function triggerDTCsReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams(options),
    };

    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/dtcReport`, config);

    return {
        type: DTCS_REPORT,
        promise: request,
    };
}

export function fetchDTCsGraph(accesstoken, from, to, vehicleId, dtcType, groupId, frequency = FREQUENCY.DAYS) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const dtcTypeQuery = dtcType ? `&type=${encodeURIComponent(dtcType)}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}dtc/graph?from=${from}&to=${to}${vehicleQuery}${groupQuery}${dtcTypeQuery}&frequency=${frequency}`,
        config
    );

    return {
        type: FETCH_DTCS_GRAPH,
        promise: request,
    };
}

export function fetchDTCsReport(accesstoken, from, to, vehicleId, dtcType, groupId, reportFormat) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` }, responseType: 'arraybuffer' };
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const format = reportFormat ? `&format=${reportFormat}` : '&format=' + REPORT_FORMAT.PDF;
    const dtcTypeQuery = dtcType ? `&type=${encodeURIComponent(dtcType)}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}alarms/report?from=${from}&to=${to}${vehicleQuery}${groupQuery}${dtcTypeQuery}${format}`,
        config
    );

    return {
        type: FETCH_DTCS_REPORT,
        promise: request,
    };
}

export function updateDTC(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}dtc/update`, values, config);
    return {
        type: UPDATE_DTC,
        promise: request,
    };
}

export function fetchVehicleMakersForDtcs(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/maker`, config);

    return {
        type: DTCS_VEHICLE_MAKERS,
        promise: request,
    };
}

export function fetchVehicleModelsForDtcs(accesstoken, makers) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_dtcs';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            makers: makers,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/models`, config);

    return {
        type: DTCS_VEHICLE_MODALS,
        promise: request,
    };
}
