import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { isUpdateHireSlipAllowedForUser } from '../../roles/permission_utils';
import { getHumanizeTime } from '../../../../utils/date_utils';
import { get, groupBy } from 'lodash';
const HireSlipListingComponent = (props) => {
    const { isEdit, login, currentPage, sizePerPage, branches, groupedVehicles, formikProps, selectRowProp, entityList, tableOptions, renderAdvanceQuantityField, renderRemarksField, renderOnAccountSettledAmountField, } = props;
    const scope = login.scope;
    const isUpdateAllowed = isUpdateHireSlipAllowedForUser(scope);
    const showBillOffice = (cell) => {
        return (_jsx("div", { children: get(groupBy(branches, 'id')[get(cell, 'branchId')], `0.officeName`, get(cell, 'branch', '-')) }));
    };
    const showBillDate = (cell) => {
        return _jsx("div", { children: getHumanizeTime(get(cell, 'date')) });
    };
    const showRefNo = (cell, row) => {
        return (_jsx("a", { target: "_blank", href: `/dashboard/hire-slip/edit/${row.id}`, children: get(cell, 'refNo', '-') }));
    };
    const showTotalHireCharge = (cell) => {
        return _jsx("div", { children: get(cell, 'totalHireCharge', '-') });
    };
    const showBalanceHireCharge = (cell) => {
        return _jsx("div", { children: get(cell, 'balanceHireCharge', '-') });
    };
    return (_jsx("div", { className: "fleetx-table-parent-container", children: _jsxs(BootstrapTable, { tableContainerClass: "card fleetx-table-container white-table-background erp-table-listing", tableHeaderClass: "fleetx-table grey-table-background font-size-mid text-center", tableBodyClass: "fleetx-table white-table-background", trClassName: "tr-user-height table-row-style", hover: true, data: entityList, options: tableOptions, remote: false, pagination: false, selectRow: selectRowProp, version: "4", children: [_jsx(TableHeaderColumn, { width: "130", dataField: "basicDetails", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showRefNo, children: "Ref No." }), _jsx(TableHeaderColumn, { width: "130", dataField: "basicDetails", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showBillOffice, children: "Bill Office" }), _jsx(TableHeaderColumn, { width: "130", dataField: "basicDetails", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showBillDate, children: "Bill Date" }), _jsx(TableHeaderColumn, { width: "120", dataField: "freightDetails", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showTotalHireCharge, children: "Total Hire Charge" }), _jsx(TableHeaderColumn, { width: "120", dataField: "freightDetails", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showBalanceHireCharge, children: "Total Pending Amount" }), _jsx(TableHeaderColumn, { width: "200", dataField: "advanceAmountEntry", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: renderAdvanceQuantityField, formatExtraData: { dataField: formikProps }, children: "Amount Paid" }), _jsx(TableHeaderColumn, { width: "200", dataField: "onAccountSettledAmount", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: renderOnAccountSettledAmountField, formatExtraData: { dataField: formikProps }, children: "On Account Settled Amount" }), _jsx(TableHeaderColumn, { width: "200", dataField: "remarks", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: renderRemarksField, children: "Remark" }), _jsx(TableHeaderColumn, { hidden: true, columnClassName: "td-column-center", width: "100", isKey: true, dataField: "id" })] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HireSlipListingComponent);
