import { jsx as _jsx } from "react/jsx-runtime";
import { Space, ConfigProvider } from 'antd';
const FxSpace = (props) => {
    const { style, themeModify, block, ...rest } = props;
    const themeLocal = {
        token: { ...themeModify },
    };
    const styleFinal = { ...style };
    if (block) {
        styleFinal.display = 'flex';
    }
    return (_jsx(ConfigProvider, { theme: themeLocal, children: _jsx(Space, { style: styleFinal, ...rest }) }));
};
export const FxSpaceCompact = Space.Compact;
export default FxSpace;
