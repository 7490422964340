import axios from 'axios';
import { isEnvLive, isViewportSMOrAbove, ROOT_LOGGING_NODE_API_URL } from '../constant';
import { get, round, toLower, toSafeInteger } from 'lodash';
import storage from 'utils/storage_utils';
// import { initSmartKarrot } from './smart_karrot_utils';
import { initDelighted } from 'utils/delighted_utils';
import { initCSBox } from 'utils/CSBox_utils';
import { initFiboTalk } from 'utils/fibotalk_utils';
//import LogRocket from 'logrocket';
import { isLogRocketOpen, isCSBoxTrackingEnabled, showChatBtnForAccount, salesDemoUser } from './account_utils';
import { getPhonePrefixFromCountry } from 'utils/internationalization_utils';
import { initZapscale } from 'utils/zapscaleUtil';
import { isWithinScope } from 'utils/permissionUtils/utils';
import { isOperationsERPEnabled } from 'components/dashboard/roles/permission_utils';

export const CRISP_API_KEY = 'c169a6a5-de02-43db-8b7b-b258191e6189';

export const PAGE_INFO = {
    'CS_BOX': {
        'dashboard/alarms': {
            module: 'Alarms',
            feature: 'Alarms',
            view: 'ALARMS',
        },
        'dashboard/dtc': {
            module: 'Alarms',
            feature: 'DTC',
            view: 'ALARMS',
        },
        'dashboard/issues': {
            module: 'Issues',
            feature: 'Issues',
            view: 'ISSUES',
        },
        'dashboard/finance/invoices': {
            module: 'Finance',
            feature: 'Invoices',
            view: 'FINANCE',
        },
        'dashboard/finance/payments': {
            module: 'Finance',
            feature: 'Payment Receipts',
            view: 'FINANCE',
        },
        'dashboard/finance/transactions': {
            module: 'Finance',
            feature: 'Transactions',
            view: 'FINANCE',
        },
        'dashboard/finance/tripsheet': {
            module: 'Finance',
            feature: 'Trip Sheets',
            view: 'FINANCE',
        },
        'dashboard/finance/duties': {
            view: 'FINANCE',
        },
        'dashboard/trips': {
            module: 'History',
            feature: 'History',
            view: 'HISTORY',
        },
        'dashboard/vendors': {
            module: 'Vendor Management',
            feature: 'Indents',
            view: 'VENDOR_MANAGEMENT',
        },
        'dashboard/vendors/auctions': {
            module: 'Vendor Management',
            feature: 'Auctions',
            view: 'VENDOR_MANAGEMENT',
        },
        'dashboard/controlroom': {
            module: 'Jobs & Routes',
            feature: 'Control Room',
            view: 'JOBS',
        },
        'dashboard/routes/jobs/analytics': {
            module: 'Jobs & Routes',
            feature: 'Jobs Performance',
            view: 'JOBS',
        },
        'dashboard/routes/jobs': {
            module: 'Jobs & Routes',
            feature: 'All Jobs',
            view: 'JOBS',
        },
        'dashboard/routes/addressbook': {
            module: 'Jobs & Routes',
            feature: 'Address Book',
            view: 'JOBS',
        },
        'dashboard/routes/route': {
            module: 'Jobs & Routes',
            feature: 'Routes',
            view: 'JOBS',
        },
        'dashboard/routes/vehicleonsite': {
            module: 'Jobs & Routes',
            feature: 'Site Analytics',
            view: 'JOBS',
        },
        'dashboard/routes/jobs/bookings': {
            module: 'Jobs & Routes',
            feature: 'Job booking',
            view: 'JOBS',
        },
        'dashboard/routes/timeonsite': {
            view: 'JOBS',
        },
        'dashboard/overview': {
            module: 'Overview',
            feature: 'Overview',
            view: 'OVERVIEW',
        },
        'dashboard/realtime/overview': {
            module: 'Realtime',
            feature: 'Realtime',
            view: 'REALTIME',
        },
        'dashboard/vehicles': {
            view: 'VEHICLES',
        },
        'dashboard/reminders/servicereminders': {
            module: 'Reminders',
            feature: 'Service reminder',
            view: 'REMINDERS',
        },
        'dashboard/reminders/contactrenewals': {
            module: 'Reminders',
            feature: 'Contact renewal reminder',
            view: 'REMINDERS',
        },
        'dashboard/reminders/vehiclerenewals': {
            module: 'Reminders',
            feature: 'Vehicle renewal reminder',
            view: 'REMINDERS',
        },
        'dashboard/maintenance/serviceentries': {
            module: 'Vehicle Records',
            feature: 'Service History',
            view: 'RECORDS',
        },
        'dashboard/fuelentries': {
            module: 'Vehicle Records',
            feature: 'Fuel Log',
            view: 'RECORDS',
        },
        'dashboard/tyres': {
            module: 'Vehicle Records',
            feature: 'Tyre Management',
            view: 'RECORDS',
        },
        'dashboard/partentries': {
            view: 'RECORDS',
        },
        'dashboard/reports/documents': {
            module: 'Reports',
            feature: 'Documents',
            view: 'REPORTS',
        },
        'dashboard/reports/download': {
            module: 'Reports',
            feature: 'Download Reports',
            view: 'REPORTS',
        },
        'dashboard/reports/assetdata': {
            module: 'Reports',
            feature: 'Asset data',
            view: 'REPORTS',
        },
        'dashboard/reports/livefuel': {
            module: 'Reports',
            feature: 'Live fuel rate',
            view: 'REPORTS',
        },
        'dashboard/reports/vehicletemp': {
            module: 'Reports',
            feature: 'Vehicle temperature',
            view: 'REPORTS',
        },
        'dashboard/reports/fuellevel': {
            module: 'Reports',
            feature: 'Fuel level',
            view: 'REPORTS',
        },
        'dashboard/reports/plannedvsactual': {
            module: 'Reports',
            feature: 'Planned Vs actual',
            view: 'REPORTS',
        },
        'dashboard/reports/customreports': {
            module: 'Reports',
            feature: 'Custom reports',
            view: 'REPORTS',
        },
        'dashboard/reports/vehiclebatterypower': {
            view: 'REPORTS',
        },
        'dashboard/reports/videos': {
            view: 'REPORTS',
        },
        'dashboard/reports/playback': {
            view: 'REPORTS',
        },
        'dashboard/settings/users': {
            module: 'Settings',
            feature: 'People',
            view: 'SETTINGS',
        },
        'dashboard/settings/alerts': {
            module: 'Settings',
            feature: 'Alarm settings',
        },
        'dashboard/settings/geofences': {
            module: 'Settings',
            feature: 'Geofence',
        },
        'dashboard/settings/language': {
            module: 'Settings',
            feature: 'Language',
        },
        'dashboard/settings/roles': {
            module: 'Settings',
            feature: 'Roles',
        },
        'dashboard/settings/groups': {
            module: 'Settings',
            feature: 'Groups',
        },
        'dashboard/settings/tags': {
            module: 'Settings',
            feature: 'Tags',
        },
        'dashboard/settings/timefences': {
            module: 'Settings',
            feature: 'Misuse Alert',
        },
        'dashboard/settings/vendors': {
            module: 'Settings',
            feature: 'Vendor',
        },
        'dashboard/settings/transporters': {
            module: 'Settings',
            feature: 'Transporter',
        },
        'dashboard/settings/vehiclerenewaltypes': {
            module: 'Settings',
            feature: 'Vehicle Renewal Type',
        },
        'dashboard/settings/servicetasks': {
            module: 'Settings',
            feature: 'Service task',
        },
        'dashboard/settings/contactrenewaltypes': {
            module: 'Settings',
            feature: 'Contact Renewal type',
        },
        'dashboard/settings/customfields': {
            module: 'Settings',
            feature: 'Custom Details',
        },
    },
};

export async function sendAppAlertToSlack(alertText, forceChannel, username = 'Web Alert') {
    //Send to Slack
    const channel = forceChannel ? forceChannel : isEnvLive() ? '#alerts-web' : '#alerts-development';
    const d = {
        text: alertText,
        channel: channel,
        username,
        icon_url:
            'https://slack-files2.s3-us-west-2.amazonaws.com/avatars/2017-09-09/238643129682_a6a75d2d410d29d480a9_48.jpg',
    };
    const slackData = 'payload=' + encodeURIComponent(JSON.stringify(d));

    const useLappHook = false;

    if (useLappHook) {
        // to avoid cors error
        try {
            await axios.post(`${ROOT_LOGGING_NODE_API_URL}misc/slackwebhook`, d);
        } catch (err) {
            console.log(err);
        }
    } else {
        try {
            await axios.post(
                `https://hooks.slack.com/services/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`,
                slackData
            );
        } catch (error) {
            // if (get(error,"response.status") === 404) {
            //     //do nothing
            // } else if (window.Sentry) {
            //     Sentry.captureException(error);
            // }
        }
    }

    /*
    //Alert Development
    let flockChannel = isEnvLive() ? "g:8c664c6bfdb544f0af06c18ec36f7250" : "g:748e8725ac6c4d98a56c90aed8392850";
    if (forceChannel === "#test") {
        flockChannel = "g:1abf601b428a4d9b91cac0f2ad581501";
    }
    //Send to Flock
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const flockData = {
        "to": flockChannel, "text": alertText.replace(/`/g, ''),
        "token": "0cb01706-4aff-4222-9e44-ee489f5e5ccf",
        "onBehalfOf": "u:nggw1gw6ww1g0w0y"
    };
    axios.post(`https://api.flock.co/v1/chat.sendMessage`, flockData, config);
    */
}

/****** CRISP UTILITY METHODS -- START ******/

function crisp(toShow, reverse, noRepeat) {
    if (window.$crisp) {
        window.$crisp.push(['do', 'chat:' + (toShow ? 'show' : 'hide') + '']);
        window.$crisp.push(['config', 'position:reverse', [reverse]]);
    } else if (!noRepeat) {
        setTimeout(crisp, 5000, toShow, reverse, true);
    }
}

function crispDataSet(key, value) {
    if (window.$crisp) {
        window.$crisp.push(['set', key, value]);
    }
}

/****** CRISP UTILITY METHODS -- END ******/

/****** FRESHCHAT UTILITY METHODS -- START ******/

function freshChat(toShow, noRepeat) {
    if (window.fcWidget) {
        window.fcWidget.setConfig({
            headerProperty: {
                //Hide the chat button on load
                hideChatButton: !toShow,
            },
        });
    } else if (!noRepeat) {
        setTimeout(freshChat, 5000, toShow, true);
    }
}

function freshChatDirection(reverse, noRepeat) {
    if (window.fcWidget) {
        window.fcWidget.setConfig({
            headerProperty: {
                direction: reverse ? 'ltr' : 'rtl',
            },
        });
    } else if (!noRepeat) {
        setTimeout(freshChat, 5000, reverse, true);
    }
}

export function reInitializeFreshChat(
    id,
    name,
    email,
    phoneNumber,
    accountId,
    fleetType,
    firstName,
    lastName,
    accountStatus,
    accountName,
    accountManager,
    hideFAQ = false
) {
    if (window.fcWidget) {
        setTimeout(() => window.fcWidget.destroy(), 5000);
        setTimeout(
            () =>
                initializeFreshChat(
                    id,
                    name,
                    email,
                    phoneNumber,
                    accountId,
                    fleetType,
                    firstName,
                    lastName,
                    accountStatus,
                    accountName,
                    accountManager,
                    hideFAQ
                ),
            7000
        );
        setTimeout(
            () => setFreshChatProperties(id, accountId, fleetType, accountStatus, accountName, accountManager),
            12000
        );
    } else {
        initializeFreshChat(
            id,
            name,
            email,
            phoneNumber,
            accountId,
            fleetType,
            firstName,
            lastName,
            accountStatus,
            accountName,
            accountManager,
            hideFAQ
        );
    }
}

function setFreshChatProperties(id, accountId, fleetType, accountStatus, accountName, accountManager) {
    if (window.fcWidget) {
        window.fcWidget.user.setProperties({
            externalId: id,
            'accountId': accountId,
            'fleetType': fleetType,
            'accountStatus': accountStatus,
            'accountName': accountName,
            'accountManager': accountManager,
        });
    }
}

function initializeFreshChat(
    id,
    name,
    email,
    phoneNumber,
    accountId,
    fleetType,
    firstName,
    lastName,
    accountStatus,
    accountName,
    accountManager,
    hideFAQ = false
) {
    let phone = '';
    let phoneCountry = '';

    if (phoneNumber) {
        const phoneNumberPrefix = getPhonePrefixFromCountry();
        if (phoneNumber.startsWith(`${phoneNumberPrefix}-`)) {
            phone = phoneNumber.substr(4);
            phoneCountry = phoneNumberPrefix;
        } else if (phoneNumber.startsWith(`+91-`) && phoneNumberPrefix == '+62') {
            phone = phoneNumber.substr(4);
            phoneCountry = phoneNumberPrefix;
        } else if (phoneNumber.startsWith(`+91-`) && phoneNumberPrefix == '+254') {
            phone = phoneNumber.substr(4);
            phoneCountry = phoneNumberPrefix;
        } else if (phoneNumber.startsWith(`+91-`) && phoneNumberPrefix == '+255') {
            phone = phoneNumber.substr(4);
            phoneCountry = phoneNumberPrefix;
        } else if (phoneNumber.startsWith(`+91-`) && phoneNumberPrefix == '+256') {
            phone = phoneNumber.substr(4);
            phoneCountry = phoneNumberPrefix;
        } else if (phoneNumber.startsWith(`+91-`) && phoneNumberPrefix == '+267') {
            phone = phoneNumber.substr(4);
            phoneCountry = phoneNumberPrefix;
        } else if (phoneNumber.startsWith(`+91-`) && phoneNumberPrefix == '+971') {
            phone = phoneNumber.substr(4);
            phoneCountry = phoneNumberPrefix;
        } else if (!phoneNumber.startsWith(`+`)) {
            phone = phoneNumber;
            phoneCountry = phoneNumberPrefix;
        } else if (phoneNumberPrefix == '+91' && phoneNumber.startsWith('91') && phoneNumber.length() == 12) {
            phone = '+' + phoneNumber;
            phoneCountry = phoneNumberPrefix;
        } else {
            sendAppAlertToSlack(
                'invalid phoneNumber ' + phoneNumber + ', phoneNumberPrefix:' + phoneNumberPrefix,
                '@udbhav3000'
            );
        }
    }
    if (window.fcWidget && showChatBtnForAccount()) {
        window.fcWidget.init({
            token: '559392e7-ba4f-49e5-9de9-3c57a7b609fe',
            host: 'https://wchat.freshchat.com',
            config: {
                agent: {
                    hideName: false,
                    hidePic: false,
                    hideBio: true,
                },
                content: {
                    headers: {
                        chat: 'Chat with us',
                        chat_help: 'Message us for any queries',
                    },
                },
                hideFAQ: hideFAQ,
            },
            firstName: name,
            email: email,
            phone: phone,
            phoneCountry: phoneCountry,
            externalId: id,
            'accountId': accountId,
            'fleetType': fleetType,
            'accountStatus': accountStatus,
            'accountName': accountName,
            'accountManager': accountManager,
        });
    }
}

/****** FRESHCHAT UTILITY METHODS -- END ******/

/****** SENTRY UTILITY METHODS -- START ******/

export function sentryDataSet(key, value) {
    if (window.Sentry) {
        window.Sentry.configureScope((scope) => {
            scope.setExtra(key, value);
        });
    }
}

export function sentryUserSet({ username, email }) {
    if (window.Sentry) {
        window.Sentry.configureScope((scope) => {
            scope.setUser({
                username,
                email,
            });
        });
    }
}

/****** SENTRY UTILITY METHODS -- END ******/

/****** CLEVERTAP UTILITY METHODS -- START ******/

export function clevertapOnUserLogin(name, id, email, phoneNo) {
    const profileObject = {
        Name: name,
        Identity: id,
        Email: email,
        'MSG-email': false, // Disable email notifications
        'MSG-push': false, // Disable push notifications
        'MSG-sms': false, // Disable sms notifications
    };
    if (phoneNo) {
        profileObject.Phone = phoneNo.replace(/-/g, '');
    }
    if (window.clevertap) {
        window.clevertap.onUserLogin.push({
            Site: profileObject,
        });
    }
}

export function csBoxLogout() {
    if (window.analytics) {
        window.analytics.logout();
    }
}

export function clevertapLogout() {
    if (window.clevertap) {
        window.clevertap.logout();
    }
}

/****** CLEVERTAP UTILITY METHODS -- END ******/

/****** HEAPANALYTICS UTILITY METHODS -- START ******/

export function heapSetIdentity(name, id, email, phoneNumber, accountId) {
    const profileObject = {
        Name: name,
        Id: id,
        Email: email,
        email: email,
        AccountId: accountId,
    };
    if (phoneNumber) {
        profileObject.Phone = phoneNumber;
    }
    if (window.heap) {
        window.heap.identify(email);
        window.heap.addUserProperties(profileObject);
    }
}

export function setMsClarityMeta({ id, email, accountId, enterprise }) {
    if (window.clarity) {
        const isSalesDemoUser = salesDemoUser();
        if ((!enterprise && !isOperationsERPEnabled()) || isSalesDemoUser || accountId === 6525) {
            window.clarity('set', 'email', email);
            window.clarity('set', 'accountId', `${accountId}`);
            window.clarity('set', 'userId', email);
            window.clarity('set', 'fxUserId', id);
            window.clarity('set', 'isSalesDemoUser', isSalesDemoUser ? 'yes' : 'no');
            window.clarity('start');
        } else {
            window.clarity('stop');
        }
    }
}

/****** HEAPANALYTICS UTILITY METHODS -- END ******/

/****** GTM UTILITY METHODS -- START ******/

export function gtmSetDataLayer(name, id, email, phoneNumber, accountId, fleetType) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        name: name,
        id: id,
        email: email,
        phoneNumber: phoneNumber,
        accountId: accountId,
        fleetType: fleetType,
        dimension5: id,
    });
}

/****** GTM UTILITY METHODS -- END ******/

/****** MoEngage UTILITY METHODS -- START ******/
export function moEngageSetUser(name, id, email, phoneNumber, accountId, fleetType, firstName, lastName) {
    if (window.Moengage) {
        if (id) {
            Moengage.add_unique_user_id(toSafeInteger(id));
        }
        if (firstName) {
            Moengage.add_first_name(firstName);
        }
        if (lastName) {
            Moengage.add_last_name(lastName);
        }
        if (email) {
            Moengage.add_email(email);
        }
        if (phoneNumber) {
            Moengage.add_mobile(toSafeInteger(phoneNumber));
        }
        if (accountId) {
            Moengage.add_user_attribute('ACCOUNT_ID', toSafeInteger(accountId));
        }
        if (fleetType) {
            Moengage.add_user_attribute('FLEET_TYPE', fleetType);
        }
        if (name) {
            Moengage.add_user_name(name);
        }
    }
}

export function moEngageLogOut() {
    if (window.Moengage) {
        window.Moengage.destroy_session();
    }
}

/****** MoEngage UTILITY METHODS -- END ******/
/****** LogRocket UTILITY METHODS -- START ******/
export function initLogRocket(accountId, accountName, accountStatus, id, name, email, phoneNumber) {
    // LogRocket.init('7czrqv/fleetx');
    //  LogRocket.identify(id, {
    //      name,
    //      email,
    //      accountId,
    //      accountName,
    //      accountStatus,
    //      phoneNumber,
    //  });
}

/****** LogRocket UTILITY METHODS -- END ******/

export function chatWidget(toShow) {
    //crisp(toShow, reverse);

    if (toShow) {
        if (isViewportSMOrAbove()) {
            freshChat(toShow);
        }
    } else {
        freshChat(toShow);
    }
}

export function chatWidgetDirection(reverse) {
    //crisp(toShow, reverse);
    freshChatDirection(reverse);
}

export function setAnalyticsUser({
    name,
    id,
    email,
    phoneNumber,
    accountId,
    fleetType,
    firstName,
    lastName,
    accountStatus,
    accountName,
    accountManager,
    repairManager,
    enterprise,
    typeofDevice,
    fleetSize,
    address,
    billingCycle,
    installedVehicles,
    roleDescription,
    title,
    user_created_on,
    account_created_on,
    roleName,
    roleId,
    isSwitchedUser,
}) {
    if (isSwitchedUser) {
        const switchedEmail = toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId', ''));
        const switchedName = toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId', ''));
        const switchedId = toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalId', ''));

        setMsClarityMeta({
            id: switchedId,
            email: switchedEmail,
            accountId,
            enterprise,
            isFleetxUser: true,
        });
        gtmSetDataLayer(switchedName, switchedId, switchedEmail, '', accountId, fleetType);
        return;
    } else {
        setMsClarityMeta({
            id,
            email,
            accountId,
            enterprise,
            isFleetxUser: accountId === 113,
        });
        //gtm data layer
        gtmSetDataLayer(name, id, email, phoneNumber, accountId, fleetType);
    }

    sentryDataSet('accountId', accountId);
    sentryDataSet('userId', id);
    sentryUserSet({
        username: name,
        email: email,
    });

    //Crisp
    // crispDataSet('user:nickname', name);
    // crispDataSet('user:email', email);
    // if (phoneNumber) {
    //     crispDataSet('user:phone', phoneNumber)
    // }

    //Freshchat
    // reInitializeFreshChat(
    //     id,
    //     name,
    //     email,
    //     phoneNumber,
    //     accountId,
    //     fleetType,
    //     firstName,
    //     lastName,
    //     accountStatus,
    //     accountName,
    //     accountManager
    // );
    //freshChatDataSet(id, name, email, phoneNumber);

    //Clevertap
    clevertapOnUserLogin(name, id, email, phoneNumber);

    //heap analytics
    heapSetIdentity(name, id, email, phoneNumber, accountId);

    //smartKarrot
    // initSmartKarrot(accountId, accountName, accountStatus, id, name, email, phoneNumber, 'Active');

    //delighted
    initDelighted(accountId, accountName, accountStatus, id, name, email, phoneNumber, 'Active', accountManager);

    //logRocket
    if (isLogRocketOpen()) {
        initLogRocket(accountId, accountName, accountStatus, id, name, email, phoneNumber);
    }

    initCSBox({
        account_id: accountId,
        email,
        user_id: id,
        phone: phoneNumber,
        name: accountName,
        customer_success_manager: accountManager,
        repairManager,
        enterprise,
        typeofDevice,
        fleetSize,
        fleetType,
        address,
        accountStatus,
        billingCycle,
        installedVehicles,
        firstName,
        lastName,
        roleDescription,
        title,
        user_created_on,
        account_created_on,
    });

    if (isCSBoxTrackingEnabled()) {
        initFiboTalk({
            account_id: accountId,
            email,
            user_id: id,
            phone: phoneNumber,
            name: accountName,
            customer_success_manager: accountManager,
        });
    }

    initZapscale({ name, id, email, accountId, accountName, roleName, roleId });

    hideHubSpotChatFlow();
}

export const hideHubSpotChatFlow = () => {
    if (window && window.HubSpotConversations) {
        window.HubSpotConversations.clear({ resetWidget: true });
    }
};

export function historyListener() {
    const LAST_HISTORY_CHANGED_INTERVAL = 60 * 60 * 1000; // 60 minutes
    const LOCAL_STORAGE_LAST_JS_VERSION_CHECK_TIMESTAMP = 'LAST_JS_VERSION_CHECK_TIMESTAMP';
    const now = Date.now();
    if (window && window.localStorage) {
        const last = storage.getItem(LOCAL_STORAGE_LAST_JS_VERSION_CHECK_TIMESTAMP);
        if (!last || now - parseInt(last) > LAST_HISTORY_CHANGED_INTERVAL) {
            try {
                storage.setItem(LOCAL_STORAGE_LAST_JS_VERSION_CHECK_TIMESTAMP, now + '');
                checkJsVersion();
            } catch (e) {}
        }
    }
}

function checkJsVersion() {
    const LOCAL_STORAGE_LOCAL_JS_VERSION = 'LOCAL_JS_VERSION';
    if (isEnvLive() && window && window.localStorage) {
        const localjsVersion = storage.getItem(LOCAL_STORAGE_LOCAL_JS_VERSION);
        getJsVersionServer((jsVersion) => {
            if (jsVersion && jsVersion != localjsVersion) {
                storage.setItem(LOCAL_STORAGE_LOCAL_JS_VERSION, jsVersion);
                window.location.reload(true);
            }
        });
    }
}

function getJsVersionServer(callback) {
    axios.get(`https://app.fleetx.io/public/static/jsversion.txt?v=${round(100000 * Math.random())}`).then((result) => {
        if (result.error) {
            if (callback) {
                callback(null);
            }
        } else {
            const buildId = get(result, 'data');
            if (buildId && callback) {
                callback(buildId);
            }
        }
    });
}
