import { FEATURE_COLORS, image } from './constant';

export const TOOLTIP_UTILIZATION =
    'Calculated on running time of vehicles per day and the utilization setting as per your account. Default is 12 hours as 100%';
export const TOOLTIP_JOB_UTILIZATION =
    'Calculated on running time of a vehicle when on job against the running time when not on job.';
export const TOOLTIP_RUNNING_JOB_UTILIZATION =
    'Calculated on running time of a vehicle when on job against the stopped time when on job';
export const TOOLTIP_FUEL_WASTAGE = 'Calculated on fuel wasted when vehicle is idling or overspeeding';
export const TOOLTIP_FUEL_WASTAGE_VALUE =
    ' of Fuel was wasted in vehicle idling or overspeeding, based average fuel price of ';
export const TOOLTIP_FUEL_PRICE_VALUE = 'Based average fuel price of ';
export const TOOLTIP_GLOBAL_MILEAGE = 'Calculated on Total KM / Total Fuel of all the vehicles combined.';
export const TOOLTIP_GLOBAL_COST_PER_KM =
    'Calculated on Fuel Price / Total KM for all running vehicles, based average fuel price of ';
export const TOOLTIP_ACCOUNT_ANALYTICS = 'Data can be delayed by upto 1 hour';
export const TOOLTIP_SPEEDING = 'No. of times the vehicle crossed the speed limit.';
export const TOOLTIP_TAMPERED = 'Possible odometer tampering may have been done.';
export const TOOLTIP_TAMPERED_LONG = 'Possible odometer tampering may have been done. See trip details for more info.';
export const TOOLTIP_NETWORK_OUTAGE =
    'Device is not online. It could be possibly due to tampering or the vehicle is a not in cellular range.';
export const TOOLTIP_DEVICE_INTERNAL_BATTERY =
    'Device is running on internal device battery. It could possibly de due to being disconnected from vehicle power or if device is removed.';
export const TOOLTIP_DEVICE_BATTERY_DISCHARGED =
    'Internel battery of device has been discharged. It could possibly be due to not getting power from vehicle for prolonged time.';
export const TOOLTIP_EXCESS_RUNNING =
    'Exclude the running for vehicle beyond the specified times even if vehicle was moving.';
export const TOOLTIP_JOB_PAST_RUNNING = 'Include only those jobs, which started in the selected time period';
export const TOOLTIP_CREATED_BETWEEN_JOB = 'Show jobs created between the date range';
export const TOOLTIP_MODIFIED_BETWEEN_JOB = 'Show jobs modified between the date range';
export const TOOLTIP_CREATED_BETWEEN_ISSUES = 'Show tickets created between the date range';
export const TOOLTIP_MODIFIED_BETWEEN_ISSUES = 'Show tickets modified between the date range';
export const TOOLTIP_SHOW_COMPLETE_JOB = 'Includes jobs which were completed during the selected time period';
export const TOOLTIP_ONLY_COMPLETE_JOB =
    'Include only those jobs, which were completed during the selected time period';
export const TOOLTIP_TRANS_SHIPMENT_JOB = 'Include only trans shipment jobs';
export const TOOLTIP_INCLUDE_ARCHIVE_PEOPLE = 'Include only those users that are archived';
export const TRIPS_TIMELINE_REPORT_DOWNLOAD_ERROR =
    'Timeline report can only be downloaded for one vehicle and with maximum time frame of 30 days.';
export const TRIPS_RPM_REPORT_DOWNLOAD_ERROR =
    'RPM report can only be downloaded for one vehicle and with maximum time frame of 15 days.';
export const TRIPS_SPLIT_DISTANCE_REPORT_DOWNLOAD_ERROR =
    'History By Distance report can only be downloaded for one vehicle and with maximum time frame of 30 days.';
export const TRIPS_STOPPAGE_REPORT_DOWNLOAD_ERROR = 'Stoppage report can only be downloaded for one vehicle.';
export const TRIPS_MOVEMENT_REPORT_DOWNLOAD_ERROR =
    'Movement report can only be downloaded for one vehicle and with maximum time frame of 30 days';
export const BULK_MOVEMENT_REPORT_DOWNLOAD_ERROR =
    'Bulk Movement report can only be downloaded for maximum time frame of 24 hrs';
export const TRIPS_MINUTE_LOCATION_REPORT_DOWNLOAD_ERROR =
    'Location by Time report can only be downloaded for one vehicle and with maximum time frame of 30 days';
export const TRIPS_SPEED_REPORT_DOWNLOAD_ERROR =
    'Speed Violations report can only be downloaded for one vehicle and with maximum time frame of 1 week';
export const NIGHT_DRIVING_REPORT_DOWNLOAD_ERROR =
    'For one vehicle, night driving report can only be downloaded with maximum time frame of 1 month';
export const NIGHT_DRIVING_REPORT_DOWNLOAD_ALL_VEHICLE_ERROR =
    'For more than one vehicle, night driving report can only be downloaded with maximum time frame of 1 day';
export const NIGHT_DRIVING_REPORT_TIME_ERROR = 'Please check the date and times for Night Driving report';
export const UPTIME_DETAILS_TIME_ERROR = 'Uptime details can only be download for a maximum time frame of 31 days';
export const UPTIME_DETAILS_VEHICLE_ERROR = 'Uptime details can only be download for one vehicle.';
export const UPTIME_REPORT_DOWNLOAD_ERROR = 'Uptime report can only be  with maximum time frame of 31 days';
export const UPTIME_REPORT_VEHICLE_DOWNLOAD_ERROR =
    'Uptime report can only be downloaded for one vehicle and with maximum time frame of 30 days';
export const TIMELINE_TAB_SWITCH_ERROR = 'Please switch to Timeline before downloading this report.';
export const SPEEDING_TAB_SWITCH_ERROR = 'Please switch to Speed Violations before downloading this report.';
export const TIME_FENCE_INFO = 'Get notified via CALL, SMS & EMAIL when your vehicle is being misused.';
export const IMMOBILISER_TERMS =
    'Client is solely responsible the usage of the Immobiliser (Engine Lock) service and fleetx is not responsible for any kind of damage, accident or any issue while using the Immobiliser (Engine Lock) service. Fleetx shall not be responsible for any accident/damage of vehicle if client asks for Immobilise(Engine Lock) the respective vehicle.';
export const PULSE_TITLE = 'Track, Monitor & Analyse your vehicles and drivers';
export const PULSE_SUB_TITLE =
    "Track all your vehicles and drivers accur ately and analyse driving behaviour, vehicle's health in real time.";
export const FINANCE_TITLE = 'Control all your fleet expenses under one roof';
export const FINANCE_SUB_TITLE =
    'Add Invoices, payment receipts, download payments report and much more with the finance module in our robust fleet management dashboard.';
export const EASY_TITLE = 'A digital record of all your fleet operations';
export const EASY_SUB_TITLE =
    'Bring your fleet operations online with fleetx easy, the all-in-one fleet management software for fleets of all sizes.';
export const DRIVE_TITLE = 'An intelligent tool to optimize your routes and dispatch';
export const DRIVE_SUB_TITLE =
    'Find best possible routes and dispatch vehicles with real time ETA, analytics and anomaly alerts';
export const RESTRICT_DOWNLOAD_MESSAGE =
    'Download has been disabled due to non-payment of dues. Please contact fleetx support.';

export const FASTAG_TITLE = 'Enjoy automatic & cashless toll payments on highways with FASTag';
export const FASTAG_SUBTITLE = `Get FASTag from Fleetx for a hassle-free trip on national highway toll plaza.`;
export const FASTAG_ABOUT = `Fleetx’s  FASTag is a simple & reusable tag that works on the Radio Frequency Identification Technology (RFID). It needs to be affixed on the vehicle’s windscreen. Each tag is linked to a registered prepaid account  to facilitate instant automatic deduction of toll charges for your vehicles. This program is part of the National Electronic Toll Collection (NETC) initiative, rolled out by NPCI under the guidelines of NHAI & IHMCL.`;
export const AIS140_TITLE = 'Power Your Fleet with Latest AIS 140 GPS Devices';
export const AIS140_SUB_TITLE = 'Get AIS140 certified GPS Tracking device, BSNL certificate and Form 17 at best prices';
export const WHY_AIS_140 = `Automotive Industry Standard 140 (AIS 140) is a set of standards published by the Automotive Research Association of India ( ARAI ) designed to build an Intelligent Transportation System (ITS). ITS is an advanced application which aims to provide innovative services relating to different modes of transport and traffic management and enable users to be better informed and make safer, more coordinated, and smarter use of transport networks.`;
export const HOW_AIS_140 = `All new and old vehicles in the above categories will require Fitment, Vahaan and BSNL/DIMTS certificate provided by fleetx when they are due for passing. These certificates shall be provided by fleetx after installation of AIS 140 certified devices.`;

export const PULSE_FEATURES = {
    REALTIME_TRACKING: {
        title: 'Realtime Tracking',
        subtitle:
            'Fleetx pulse provides fleet managers with an easy-to-use vehicle tracking dashboard. Vehicle status is updated in real-time, together with their speed, violations and traffic information.',
        color: FEATURE_COLORS.GREY,
        tiles: [
            {
                icon: image('/public/img/pulse/pulse_live_update.svg'),
                text: 'Live Update',
            },
            {
                icon: image('/public/img/pulse/pulse_violation.svg'),
                text: 'Instant Violation detection',
            },
            {
                icon: image('/public/img/pulse/pulse_share_location.svg'),
                text: 'Share location of vehicle',
            },
            {
                icon: image('/public/img/pulse/pulse_map_view.svg'),
                text: 'Map View with live traffic',
            },
        ],
    },
    TRIPS_MANAGEMENT: {
        title: 'History Management',
        subtitle:
            'Fleetx pulse gives you detailed information of all your vehicle’s trips. It also allows you to quickly identify anomalies within your trips.',
        color: FEATURE_COLORS.WHITE,
        tiles: [
            {
                icon: image('/public/img/pulse/pulse_trips_snapshot.svg'),
                text: 'History Snapshot',
            },
            {
                icon: image('/public/img/pulse/pulse_anomaly.svg'),
                text: 'Industry first Anomaly Detection',
            },
            {
                icon: image('/public/img/pulse/pulse_trip_timeline.svg'),
                text: 'Timeline & Route Playback',
            },
            {
                icon: image('/public/img/pulse/pulse_multi_trip_view.svg'),
                text: 'Industry first Multi-History View',
            },
        ],
    },
    VEHICLE_MANAGEMENT: {
        title: 'Vehicle / Driver Management',
        subtitle:
            'Fleetx pulse helps you in identifying your best performing vehicle and drivers using intelligent driving score algorithm and real time fuel and mileage management.',
        color: FEATURE_COLORS.GREY,
        tiles: [
            {
                icon: image('/public/img/pulse/pulse_fuel_management.svg'),
                text: 'Fuel Management',
            },
            {
                icon: image('/public/img/pulse/pulse_fuel_wastage.svg'),
                text: 'Industry first Fuel Wastage detection',
            },
            {
                icon: image('/public/img/pulse/pulse_driver_score.svg'),
                text: 'Advanced Driver Score',
            },
            {
                icon: image('/public/img/pulse/pulse_share_trips.svg'),
                text: 'Share with anyone',
            },
        ],
    },
    ALARMS: {
        title: 'Alarms & Instant Notifications ',
        subtitle:
            'Fleetx pulse will give you instant notifications (email, sms, call) of every violation and incident happening with your fleet.',
        color: FEATURE_COLORS.WHITE,
        tiles: [
            {
                icon: image('/public/img/pulse/pulse_overspeed.svg'),
                text: 'Industry first Overspeed duration violation',
            },
            {
                icon: image('/public/img/pulse/pulse_theft_alarms.svg'),
                text: 'Industry first Theft Alarm',
            },
            {
                icon: image('/public/img/pulse/pulse_analytics.svg'),
                text: 'Powerful Analytics & reports',
            },
            {
                icon: image('/public/img/pulse/pulse_sos_alarms.svg'),
                text: 'SOS Alarm',
            },
        ],
    },
};
export const EASY_FEATURES = {
    ASSET_MANAGEMENT: {
        title: 'Assets & Users Management',
        subtitle:
            "Fleetx easy helps you to manage all your assets' and user's data, documents and important information online in a user friendly way. It allows you to upload pictures, documents and all important files on the cloud which can be accessible from anywhere.",
        color: FEATURE_COLORS.GREY,
        tiles: [
            {
                icon: image('/public/img/easy/easy_advanced_vehicle.svg'),
                text: 'Advanced Vehicle / Contact Profiles',
            },
            {
                icon: image('/public/img/easy/easy_online_documents.svg'),
                text: 'Online Documents & Files',
            },
            {
                icon: image('/public/img/easy/easy_vehicle_odometer.svg'),
                text: 'Vehicle Odometer & Utilisation History',
            },
            {
                icon: image('/public/img/easy/easy_unlimited_contact.svg'),
                text: 'Unlimited Contact Management',
            },
        ],
    },
    RENEWAL_REMINDERS: {
        title: 'Renewal Management',
        subtitle:
            'Set reminders for all renewal tasks of important documents and government compliances to ensure your employees and drivers are always up-to-date on documentation.',
        color: FEATURE_COLORS.WHITE,
        tiles: [
            {
                icon: image('/public/img/easy/easy_service_maintainence.jpg?v=1'),
                text: 'Service / Maintenance Prediction',
            },
            {
                icon: image('/public/img/easy/easy_contact_renewals.svg'),
                text: 'Contact Renewals',
            },
            {
                icon: image('/public/img/easy/easy_vehicle_renewals.svg'),
                text: 'Vehicle Renewals',
            },
            {
                icon: image('/public/img/easy/easy_maintenence_renewal.svg'),
                text: 'Maintenance Renewals',
            },
        ],
    },
    SERVICE_PREVENTIVE: {
        title: 'Service & Preventive Maintenance',
        subtitle:
            'Keep an eye on all your service items such as oil changes, mileage service intervals, brakes and more. Set reminders for any service item and fleetx easy will remind you when service is due, helping you to maximize vehicle uptime and road readiness. Maintain detailed service records and identify which vehicles are regularly in the shop as well as their associated maintenence costs.',
        color: FEATURE_COLORS.GREY,
        tiles: [
            {
                icon: image('/public/img/easy/easy_manage_measure.svg'),
                text: 'Manage & Measure Service cost',
            },
            {
                icon: image('/public/img/easy/easy_schedule.svg'),
                text: 'Schedule Service & Maintenance',
            },
            {
                icon: image('/public/img/easy/easy_maintenance_history.svg'),
                text: 'Maintenance History',
            },
            {
                icon: image('/public/img/easy/easy_issue_management.svg'),
                text: 'Issue Management',
            },
        ],
    },
    INVOICE_BILLING: {
        title: 'Cost & Revenue Management',
        subtitle:
            'Fleetx easy allows you to be up to date on your financials by helping you to create automatic trips invoices, create and manage work & purchase orders. Have a real time visibility of your P&L through dashboard',
        color: FEATURE_COLORS.WHITE,
        tiles: [
            {
                icon: image('/public/img/easy/easy_trip_invoicing.svg'),
                text: 'Trip Invoicing',
            },
            {
                icon: image('/public/img/easy/easy_purchase_orders.svg'),
                text: 'Purchase Orders',
            },
            {
                icon: image('/public/img/easy/easy_work_orders.svg'),
                text: 'Work Orders',
            },
            {
                icon: image('/public/img/easy/easy_expense_management.svg'),
                text: 'Expense Management',
            },
        ],
    },
    TYRE_MANAGEMENT: {
        title: 'Tyre Management',
        subtitle:
            'Helps in tracking tyre servicing time, thereby reducing service costs, maintenance time & increases life expectancy. Improves fuel efficiency, along with the run time of the vehicle thereby increasing profitability. Tagging the tyre also reduces the chances of tyre-theft.',
        color: FEATURE_COLORS.WHITE,
        tiles: [
            {
                icon: image('/public/img/easy/easy_historical_analysis.svg'),
                text: 'Historical Analysis',
            },
            {
                icon: image('/public/img/easy/easy_track_inventory.svg'),
                text: 'Track Inventory',
            },
            {
                icon: image('/public/img/easy/easy_tread_history.svg'),
                text: 'Tread History',
            },
            {
                icon: image('/public/img/easy/easy_tyre_utilization.svg'),
                text: 'Tyre Utilization',
            },
        ],
    },
};
export const DRIVE_FEATURES = {
    ROUTE_MANAGEMENT: {
        title: 'Route Management',
        subtitle:
            'Save money with best possible routes for your deliveries in real time condition like traffic, loading/unloading points, tolls etc. Automatically detects the delivery delays and completion with exact drop up point address without any manual intervention',
        color: FEATURE_COLORS.GREY,
        icon: image('/public/img/drive/drive_route_management.svg'),
    },
    ROUTE_ANALYTICS: {
        title: 'Route Analytics',
        subtitle:
            "Want to know which routes or drivers is performing well and which are losing money? Get complete insights about your routes and driver's performance, deviations and missed points with detailed analytics",
        color: FEATURE_COLORS.WHITE,
        icon: image('/public/img/drive/drive_route_analytics.svg'),
    },
    JOB_SHARING: {
        title: 'Job sharing & ETA alerts',
        subtitle:
            'Want to give best service to your client? Give them complete visibility with our one click job share and real time ETA functionality and reduce your phone queries dramatically',
        color: FEATURE_COLORS.GREY,
        icon: image('/public/img/drive/drive_job_sharing.svg'),
    },
    TIME_ON_SITE: {
        title: 'Time on Site',
        subtitle:
            'Is your vehicle spending more time on unauthorised locations? Know detailed analytics about your vehicles parking duration on authorised and unauthorised locations.',
        color: FEATURE_COLORS.WHITE,
        icon: image('/public/img/drive/drive_time_site.svg'),
    },
    INTELLIGENT_ALERTS: {
        title: 'Intelligent Alerts',
        subtitle:
            'Want to know if you driver has deviated from a route or missed a scheduled delivery? Get instant and intelligent alerts and make quick decisions.',
        color: FEATURE_COLORS.GREY,
        icon: image('/public/img/drive/drive_intelligent_alerts.svg'),
    },
};
export const FINANCE_FEATURES = {
    DRIVER_SETTLEMENT: {
        title: 'Driver Settlement',
        subtitle:
            'Driver settlements in Fleetx is created to manage their salary processing keeping into account their trip expenses, advance payments, pay per mile & driver contracts. It also allows to include deductions to the settlement. One-click tracking for the trips settled with the drivers & reduce the risks of paying twice for the trips.',
        color: FEATURE_COLORS.GREY,
        icon: image('/public/img/finance/finance_driver_settlement.png'),
    },
    EXPENSE_MANAGEMNT: {
        title: 'Expense Management',
        subtitle:
            'Expense Management module is designed to develop holistic understanding of your fleet expenditure and improve asset life-cycle replacement decisions on the basis of cost trends or current utilization levels. Track periodical payments, advances, guarantees and gain insights on expenditure through custom reports.',
        color: FEATURE_COLORS.WHITE,
        icon: image('/public/img/finance/finance_expense_management.png'),
    },
    INVOICING: {
        title: 'Invoicing',
        subtitle:
            'Real-time accountancy and payment information per customer. Advanced pricing engine based on contract duration, distance and fuel levels. Get notifications for outstanding invoices and relieve your administration workload for invoices workflow using Fleetx.',
        color: FEATURE_COLORS.WHITE,
        icon: image('/public/img/finance/finance_invoicing.png'),
    },
    PAYMENT_RECEIPT: {
        title: 'Payment Receipt',
        subtitle:
            'Keeping the record of payments receipts is a cumbersome task. Finance module of Fleetx allows you to digitally keep the record of all payments receipts, including toll-tax receipts, fuel receipts, advance payments receipts. NO EXTRA COST for additional finance softwares is required.',
        color: FEATURE_COLORS.GREY,
        icon: image('/public/img/finance/finance_payment_receipt.png'),
    },
};

// vehicle form tooltips

export const TOOLTIP_VEHICLE_WIDTH = 'Measurement of the widest part of the vehicle.';
export const TOOLTIP_VEHICLE_HEIGHT = 'Measurement from the ground to the highest part of the vehicle.';
export const TOOLTIP_VEHICLE_LENGTH = 'Total length of the vehicle including bumpers.';
export const TOOLTIP_VEHICLE_INTERIOR_VOLUME =
    'The volume within the vehicles main chambers. Interior volume = total passenger volume + volume of the cargo, if the cargo is not isolated from the designated passenger area.';
export const TOOLTIP_VEHICLE_PASSENGER_VOLUME =
    'The volume for the area designated solely for the passengers. This will not include any interior volume design for cargo.';
export const TOOLTIP_VEHICLE_CARGO_VOLUME =
    'The volume of the area designated as cargo space. In an SUV, this is located behind the last row of passenger seating.Passenger cars typically have an isolated cargo area.';
export const TOOLTIP_VEHICLE_GROUND_CLEARANCE = 'Measurement of the distance between the ground and the lowest.';
export const TOOLTIP_VEHICLE_BED_LENGTH = 'The length of the bed, from front to back, of a pickup truck.';
export const TOOLTIP_VEHICLE_CURB_WEIGHT =
    'The weight of a vehicle with standard options and features in addition to the weight of a single driver and fuel.';
export const TOOLTIP_VEHICLE_GROSS_VEHICLE_WEIGHT_RATING =
    'The combination of the GVWR of a vehicle and the towing capacity.';
export const TOOLTIP_VEHICLE_TOWING_CAPACITY =
    'The towing ability of the vehicle as it comes standard without the addition of optional suspensions, engine or transmission';
export const TOOLTIP_VEHICLE_MAX_PAYLOAD =
    'The maximum allowable weight that the vehicle can hold. This includes cargo and any additional options but exclude the driver and fuel.';
export const TOOLTIP_VEHICLE_ENGINE_SUMMARY = 'Basic engine summary.';
export const TOOLTIP_VEHICLE_ENGINE_BRAND = 'Brand-specific engine name.';
export const TOOLTIP_VEHICLE_ASPIRATIONS = 'Method for drawing air into the engine.';
export const TOOLTIP_VEHICLE_BLOCK_TYPE = 'Engine block type: F,H,I,R,V,W';
export const TOOLTIP_VEHICLE_SENSOR_TYPE = 'Sensor Attached to Analog/Digital Input/Output Line';
export const TOOLTIP_VEHICLE_ENGINE_BORE = 'Diameter of the holes in the engine block used for cylinders.';
export const TOOLTIP_VEHICLE_CAM_TYPE = 'Type of cam, Example include DOHC, SOHC, etc...';
export const TOOLTIP_VEHICLE_ENGINE_COMPRESSION = 'Compression ratio.';
export const TOOLTIP_VEHICLE_ENGINE_CYLINDERS = 'Number of cylinders present in the engine.';
export const TOOLTIP_VEHICLE_ENGINE_DISPLACEMENT = 'Total volume displaced during one firing cycle of the engine.';
export const TOOLTIP_VEHICLE_FUEL_INDUCTION = 'Method for drawing fuel into the engine.';
export const TOOLTIP_VEHICLE_FUEL_QUALITY = 'Recommended Octane rating.';
export const TOOLTIP_VEHICLE_MAX_HP = 'Maximum power achieved by engine. Expressed in the units of Horsepower.';
export const TOOLTIP_VEHICLE_MAX_TORQUE = 'Maximum torque delivered by engine. Expressed in the units of foot-pounds.';
export const TOOLTIP_VEHICLE_REDLINE_RPM = 'Maximum speed at which engine can operate without risking damage.';
export const TOOLTIP_VEHICLE_STROKE = 'Distance travelled by the piston during a combustion cycle.';
export const TOOLTIP_VEHICLE_ENGINE_VALVES = 'Total number of valves in the engine.';
export const TOOLTIP_VEHICLE_TRANSMISSION_SUMMARY = 'Basic transmission summary.';
export const TOOLTIP_VEHICLE_TRANSMISSION_BRAND = 'Brand-specific engine name. For example: "Allison"';
export const TOOLTIP_VEHICLE_ENGINE_TRANSMISSION_GEARS = 'Number of gears/speeds available.';
export const TOOLTIP_VEHICLE_DRIVE_TYPE = 'Refers to how many or which vehicles are powered by the engine.';
export const TOOLTIP_VEHICLE_BRAKE_SYSTEM = 'Description of brake type.';
export const TOOLTIP_VEHICLE_FRONT_TRACK_WIDTH =
    'The distance between the front tyres. Distance are measured from the center of the tyre width.';
export const TOOLTIP_VEHICLE_REAR_TRACK_WIDTH =
    'The distance between the rear tyres. Distance are measured from the center of the tyre width.';
export const TOOLTIP_VEHICLE_WHEEL_BASE =
    'The length from the center of the front wheel to the center of the rear wheel.';
export const TOOLTIP_VEHICLE_FRONT_WHEEL_DIAMETER =
    'The front wheel diameter. Width displayed when available(Displayed as "length x width") ';
export const TOOLTIP_VEHICLE_REAR_WHEEL_DIAMETER =
    'The distance between the rear tyres. Distance are measured from the center of the tyre width.';
export const TOOLTIP_VEHICLE_FRONT_TIRE_TYPE = 'The front tyre information.';
export const TOOLTIP_VEHICLE_REAR_TIRE_TYPE = 'The rear tyre information if it is different than the front.';
export const TOOLTIP_SEC_FUEL_PRICE_VALUE = 'Based average fuel price of ';
export const TOOLTIP_FREIGHT_BILL_PREPARED = 'Show Only Jobs with Freight Bill Prepared';
