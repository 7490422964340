const Singleton = (function() {
    let storageInstance;

    // Check feature availability
    function createStorageInstance() {
        const uid = new Date();
        let result;
        try {
            localStorage.setItem('uid', uid);
            result = localStorage.getItem('uid') == uid;
            localStorage.removeItem('uid');
            return result && localStorage;
        } catch (exception) {
            console.log('=>', exception);
        }
    }

    return {
        getStorageInstance: function() {
            if (!storageInstance) {
                storageInstance = createStorageInstance();
            }
            return storageInstance;
        },
    };
})();

const storage = {
    getStorage() {
        return Singleton.getStorageInstance();
    },
    getItem(key, defaultValue) {
        const storage = this.getStorage();
        if (storage && storage.getItem(key) !== null) {
            return storage.getItem(key);
        } else if (defaultValue) {
            return defaultValue;
        }
        return null;
    },
    setItem(key, value) {
        const storage = this.getStorage();
        if (storage) {
            return storage.setItem(key, value);
        }
    },
    clear() {
        const storage = this.getStorage();
        if (storage) {
            return storage.clear();
        }
    },
};

export default storage;
