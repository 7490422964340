import { useEffect, useState } from 'react';
import { handleError } from '../../../constant';
import { fetchTemplateByFormType } from 'actions/dashboard/form_templates_action';
import { get } from 'lodash';
function useFormTemplate(accesstoken, type, isDisabled) {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!isDisabled) {
            setLoading(true);
            fetchTemplateByFormType(accesstoken, type)
                .promise.then((resp) => {
                if (!resp.error) {
                    setResponse(get(resp, 'data[0].data', {}));
                    setLoading(false);
                }
                else {
                    throw new Error(handleError(resp.response));
                    setLoading(false);
                }
            })
                .catch((e) => {
                setLoading(false);
                alert(handleError(e));
            });
        }
    }, [type]);
    return { templateData: response, templateFetchLoading: loading };
}
export default useFormTemplate;
