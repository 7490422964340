import { Component } from 'react';

export default class FxCircularProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // Size of the enclosing square
        const sqSize = this.props.sqSize;
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        const dashOffset = dashArray - (dashArray * this.props.percentage) / 100;
        //stroke color
        const strokeColor = this.props.strokeColor;
        return (
            <svg width={this.props.sqSize} height={this.props.sqSize} viewBox={viewBox}>
                {this.props.centerText && (
                    <text
                        style={{ fontWeight: 'bold', fontSize: '24px' }}
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                    >
                        {this.props.centerText}
                    </text>
                )}

                <circle
                    className="circle-background"
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${this.props.strokeWidth}px`}
                />

                <circle
                    className="circle-progress"
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${this.props.strokeWidth}px`}
                    // Start progress marker at 12 O'Clock
                    transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset,
                        stroke: strokeColor,
                    }}
                />
            </svg>
        );
    }
}

FxCircularProgressBar.defaultProps = {
    sqSize: 80,
    percentage: 2,
    strokeWidth: 7,
};
