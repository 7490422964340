import { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import FxRipple from './FxRipple';

const TitleIcon = styled.div`
    display: flex;
    align-self: center;
    span {
        display: block;
        line-height: 25px;
    }
    img {
        margin-left: 10px;
    }
`;

const Wrapper = styled.button`
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : 38)}px;
    border-radius: ${(props) => (props.radius ? props.radius : '0.25rem')};
    border: ${(props) => (props.border ? props.border : 'none')};
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#20c7ca')};
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    letter-spacing: normal;
    color: #ffffff;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
    box-shadow: ${(props) => (props.shadow ? props.shadow : 'none')};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${(props) => (props.marginView ? props.marginView : '0 auto')};
    /* margin: 0 auto; */
    &:focus {
        outline: none;
    }
`;
const spin = keyframes`
  0% { transform: rotate(0deg) }
  100% {transform: rotate(360deg) }
`;
const Circle = styled.div`
    border: 3px solid ${(props) => (props.loadingBgColor ? props.loadingBgColor : '#fff')};
    border-radius: 50%;
    border-top: 3px solid ${(props) => (props.loadingFgColor ? props.loadingFgColor : '#20c7ca')};
    margin: auto;
    width: 20px;
    height: 20px;
    animation: ${spin} 2s linear infinite;
`;
export default class _FxButton extends Component {
    render() {
        const { title, isLoading, loadingBgColor, loadingFgColor, icon, selfie, ...props } = this.props;
        return (
            <FxRipple width="100%">
                <Wrapper {...props}>
                    {icon && <i className={icon} />}
                    {isLoading ? (
                        <Circle loadingBgColor={loadingBgColor} loadingFgColor={loadingFgColor} />
                    ) : (
                        <TitleIcon>
                            <span>{title}</span>
                        </TitleIcon>
                    )}
                </Wrapper>
            </FxRipple>
        );
    }
}
