import axios from 'axios';
import { ROOT_API_ERP } from '../../../constant';
export const FETCH_DIVISIONS = 'FETCH_DIVISIONS';
export const CREATE_DIVISION = 'CREATE_DIVISION';
export const FETCH_DIVISION = 'FETCH_DIVISION';
export const UPDATE_DIVISION = 'UPDATE_DIVISION';
export const DELETE_DIVISION = 'DELETE_DIVISION';
export function fetchDivisions(accesstoken, params = {}) {
    const { baseGroupIds } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}division`, config);
    return {
        type: FETCH_DIVISIONS,
        promise: request,
    };
}
export function createDivision(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}division`, data, config);
    return {
        type: CREATE_DIVISION,
        promise: request,
    };
}
export function fetchDivision(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}division/${id}`, config);
    return {
        type: FETCH_DIVISION,
        promise: request,
    };
}
export function deleteDivision(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}division/${id}`, config);
    return {
        type: DELETE_DIVISION,
        promise: request,
    };
}
export function updateDivision(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}division`, data, config);
    return {
        type: UPDATE_DIVISION,
        promise: request,
    };
}
