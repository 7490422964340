import { get, map, isEmpty, includes, filter, entries } from 'lodash';

export const COUNTRIES = {
    BOTSWANA: 'BOTSWANA',
    BANGLADESH: 'BANGLADESH',
    EGYPT: 'EGYPT',
    GABON: 'GABON',
    GHANA: 'GHANA',
    INDIA: 'INDIA',
    INDONESIA: 'INDONESIA',
    KENYA: 'KENYA',
    MOZAMBIQUE: 'MOZAMBIQUE',
    NIGERIA: 'NIGERIA',
    OMAN: 'OMAN',
    PHILIPPINES: 'PHILIPPINES',
    RWANDA: 'RWANDA',
    SAUDI_ARABIA: 'SAUDI_ARABIA',
    SOUTH_AFRICA: 'SOUTH_AFRICA',
    TANZANIA: 'TANZANIA',
    UAE: 'UAE',
    UGANDA: 'UGANDA',
    VIETNAM: 'VIETNAM',
    ZAMBIA: 'ZAMBIA',
    SRILANKA: 'SRILANKA',
    ANGOLA: 'ANGOLA',
};

export function mappedCountries() {
    return map(COUNTRIES, (o) => {
        return {
            value: o,
            label: `${o}`,
        };
    });
}

export const COUNTRIES_ISO_CODE = {
    BWA: 'BWA (BOTSWANA)',
    BGD: 'BGD (BANGLADESH)',
    EGY: 'EGY (EGYPT)',
    GAB: 'GAB (GABON)',
    GHA: 'GHA (GHANA)',
    IN: 'IN (INDIA)',
    ID: 'ID (INDONESIA)',
    KEN: 'KEN (KENYA)',
    MOZ: 'MOZ (MOZAMBIQUE)',
    NGA: 'NGA (NIGERIA)',
    OM: 'OM (OMAN)',
    PHL: 'PHL (PHILIPPINES)',
    RWA: 'RWA (RWANDA)',
    SAU: 'SAU (SAUDI_ARABIA)',
    SAF: 'SAF (SOUTH_AFRICA)',
    TZA: 'TZA (TANZANIA)',
    AE: 'AE (UAE)',
    UGA: 'UGA (UGANDA)',
    VNM: 'VNM (VIETNAM)',
    ZMB: 'ZMB (ZAMBIA)',
    LKA: 'LKA (SRILANKA)',
    AGO: 'AGO (ANGOLA)',
};

export const COUNTRIES_ACC_TO_CODE = {
    BWA: 'BOTSWANA',
    BGD: 'BANGLADESH',
    EGY: 'EGYPT',
    GAB: 'GABON',
    GHA: 'GHANA',
    IN: 'INDIA',
    ID: 'INDONESIA',
    KEN: 'KENYA',
    MOZ: 'MOZAMBIQUE',
    NGA: 'NIGERIA',
    OM: 'OMAN',
    PHL: 'PHILIPPINES',
    RWA: 'RWANDA',
    SAU: 'SAUDI_ARABIA',
    SAF: 'SOUTH_AFRICA',
    TZA: 'TANZANIA',
    AE: 'UAE',
    UGA: 'UGANDA',
    VNM: 'VIETNAM',
    ZMB: 'ZAMBIA',
    LKA: 'SRILANKA',
    AGO: 'ANGOLA',
};

export function mappedCountriesISOcode() {
    return map(entries(COUNTRIES_ISO_CODE), ([key, value]) => {
        return {
            value: key,
            label: value,
        };
    });
}

export const countryToDetailsMap = {
    [COUNTRIES.GHANA]: {
        currencySymbol: '₵',
        'font-class': null,
        fallBackText: 'GHS',
        phoneNumberPrefix: '+233',
        timeZone: 'Africa/Accra',
        isoCountryCode: 'GHA',
        mapCenter: {
            lat: 5.614818,
            lng: -0.205874,
        },
    },
    [COUNTRIES.INDIA]: {
        currencySymbol: '₹',
        'font-class': 'fa fa-inr',
        fallBackText: 'INR',
        phoneNumberPrefix: '+91',
        timeZone: 'Asia/Kolkata',
        isoCountryCode: 'IN',
        mapCenter: {
            lat: 28.614019,
            lng: 77.207932,
        },
    },
    [COUNTRIES.INDONESIA]: {
        currencySymbol: 'Rp',
        'font-class': null,
        fallBackText: 'IDR',
        phoneNumberPrefix: '+62',
        timeZone: 'Asia/Jakarta',
        isoCountryCode: 'ID',
        mapCenter: {
            lat: -6.166627,
            lng: 106.816667,
        },
    },
    [COUNTRIES.BOTSWANA]: {
        currencySymbol: 'P',
        'font-class': null,
        fallBackText: 'BWP',
        phoneNumberPrefix: '+267',
        timeZone: 'Africa/Gaborone',
        isoCountryCode: 'BWA',
        mapCenter: {
            lat: -24.653257,
            lng: 25.906792,
        },
    },
    [COUNTRIES.MOZAMBIQUE]: {
        currencySymbol: 'MT',
        'font-class': null,
        fallBackText: 'MZN',
        phoneNumberPrefix: '+258',
        timeZone: 'Africa/Maputo',
        isoCountryCode: 'MOZ',
        mapCenter: {
            lat: -25.96553,
            lng: 32.58322,
        },
    },
    [COUNTRIES.NIGERIA]: {
        currencySymbol: '₦',
        'font-class': null,
        fallBackText: 'NGN',
        phoneNumberPrefix: '+234',
        timeZone: 'Africa/Lagos',
        isoCountryCode: 'NGA',
        mapCenter: {
            lat: 9.05785,
            lng: 7.49508,
        },
    },
    [COUNTRIES.PHILIPPINES]: {
        currencySymbol: '₱',
        'font-class': null,
        fallBackText: 'PHP',
        phoneNumberPrefix: '+63',
        timeZone: 'Asia/Manila',
        isoCountryCode: 'PHL',
        mapCenter: {
            lat: 14.599512,
            lng: 120.984222,
        },
    },
    [COUNTRIES.RWANDA]: {
        currencySymbol: 'FRw',
        'font-class': null,
        fallBackText: 'RWF',
        phoneNumberPrefix: '+250',
        timeZone: 'Africa/Kigali',
        isoCountryCode: 'RWA',
        mapCenter: {
            lat: -1.935114,
            lng: 30.082111,
        },
    },
    [COUNTRIES.KENYA]: {
        currencySymbol: 'KSh',
        'font-class': null,
        fallBackText: 'KES',
        phoneNumberPrefix: '+254',
        timeZone: 'Africa/Nairobi',
        isoCountryCode: 'KEN',
        mapCenter: {
            lat: -1.286389,
            lng: 36.817223,
        },
    },
    [COUNTRIES.OMAN]: {
        currencySymbol: '﷼',
        'font-class': null,
        fallBackText: 'OMR',
        phoneNumberPrefix: '+968',
        timeZone: 'Asia/Muscat',
        isoCountryCode: 'OM',
        mapCenter: {
            lat: 23.614328,
            lng: 58.545284,
        },
    },
    [COUNTRIES.SAUDI_ARABIA]: {
        currencySymbol: '﷼',
        'font-class': null,
        fallBackText: 'SAR',
        phoneNumberPrefix: '+966',
        timeZone: 'Asia/Riyadh',
        isoCountryCode: 'SAU',
        mapCenter: {
            lat: 24.774265,
            lng: 46.738586,
        },
    },
    [COUNTRIES.SOUTH_AFRICA]: {
        currencySymbol: 'R',
        'font-class': null,
        fallBackText: 'ZAR',
        phoneNumberPrefix: '+27',
        timeZone: 'Africa/Johannesburg',
        isoCountryCode: 'SAF',
        mapCenter: {
            lat: -33.918861,
            lng: 18.4233,
        },
    },
    [COUNTRIES.EGYPT]: {
        currencySymbol: '£',
        'font-class': null,
        fallBackText: 'EGP',
        phoneNumberPrefix: '+20',
        timeZone: 'Africa/Cairo',
        isoCountryCode: 'EGY',
        mapCenter: {
            lat: 30.0444,
            lng: 31.2357,
        },
    },
    [COUNTRIES.TANZANIA]: {
        currencySymbol: 'TSh',
        'font-class': null,
        fallBackText: 'TZS',
        phoneNumberPrefix: '+255',
        timeZone: 'Africa/Dar_es_Salaam',
        isoCountryCode: 'TZA',
        mapCenter: {
            lat: -6.161184,
            lng: 35.745426,
        },
    },
    [COUNTRIES.UAE]: {
        currencySymbol: 'د.إ',
        'font-class': null,
        fallBackText: 'AED',
        phoneNumberPrefix: '+971',
        timeZone: 'Asia/Dubai',
        isoCountryCode: 'AE',
        mapCenter: {
            lat: 24.466667,
            lng: 54.366669,
        },
    },
    [COUNTRIES.UGANDA]: {
        currencySymbol: 'USh',
        'font-class': null,
        fallBackText: 'UGX',
        phoneNumberPrefix: '+256',
        timeZone: 'Africa/Nairobi',
        isoCountryCode: 'UGA',
        mapCenter: {
            lat: 0.347596,
            lng: 32.58252,
        },
    },
    [COUNTRIES.VIETNAM]: {
        currencySymbol: '₫',
        'font-class': null,
        fallBackText: 'VND',
        phoneNumberPrefix: '+84',
        timeZone: 'Asia/Ho_Chi_Minh',
        isoCountryCode: 'VNM',
        mapCenter: {
            lat: 21.028511,
            lng: 105.804817,
        },
    },
    [COUNTRIES.ZAMBIA]: {
        currencySymbol: 'ZK',
        'font-class': null,
        fallBackText: 'ZMW',
        phoneNumberPrefix: '+260',
        timeZone: 'Africa/Lusaka',
        isoCountryCode: 'ZMB',
        mapCenter: {
            lat: -15.416786,
            lng: 28.2036666,
        },
    },
    [COUNTRIES.BANGLADESH]: {
        currencySymbol: '৳',
        'font-class': null,
        fallBackText: 'BDT',
        phoneNumberPrefix: '+88',
        timeZone: 'Asia/Dhaka',
        isoCountryCode: 'BGD',
        mapCenter: {
            lat: 23.8103,
            lng: 90.4125,
        },
    },
    [COUNTRIES.SRILANKA]: {
        currencySymbol: 'රු',
        'font-class': null,
        fallBackText: 'LKR',
        phoneNumberPrefix: '+94',
        timeZone: 'Asia/Colombo',
        isoCountryCode: 'LKA',
        mapCenter: {
            lat: 6.9272221455,
            lng: 79.8589199262,
        },
    },
    [COUNTRIES.GABON]: {
        currencySymbol: 'FCFA',
        'font-class': null,
        fallBackText: 'XAF',
        phoneNumberPrefix: '+241',
        timeZone: 'Asia/Dar_es_Salaam',
        isoCountryCode: 'GAB',
        mapCenter: {
            lat: 0.4162,
            lng: 9.4673,
        },
    },
    [COUNTRIES.ANGOLA]: {
        currencySymbol: 'Kz',
        'font-class': null,
        fallBackText: 'AOA',
        phoneNumberPrefix: '+244',
        timeZone: 'Africa/libreville',
        isoCountryCode: 'AGO',
        mapCenter: {
            lat: -8.8535324,
            lng: 13.2841045,
        },
    },
};
export const ISO_LANGUAGE_CODES = {
    en: 'en',
    hi: 'hi',
    id: 'id',
    pt: 'pt',
};
export const ISO_LANGUAGE_LABEL = {
    en: 'English',
    hi: 'Hindi',
    id: 'Indonesian',
    pt: 'Portuguese',
};

export function getCurrencyFromCountry(loggedInUser, getAsIcon = false, getAsFontClassName = false) {
    let newLoggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const currencySymbol = get(newLoggedInUser, 'currencySymbol');
    const currencyText = get(newLoggedInUser, 'currency');

    if (currencySymbol) {
        return currencySymbol;
    }
    if (currencyText) {
        return currencyText;
    }

    const country = get(newLoggedInUser, 'country');
    const countryCode = country ? country : 'INDIA';
    const fontClass = get(countryToDetailsMap[countryCode], 'font-class');
    if (getAsFontClassName) {
        return fontClass;
    }
    if (fontClass && getAsIcon) {
        return <i className={fontClass} />;
    }
    return get(countryToDetailsMap[countryCode], 'fallBackText', '');
}

export function getPhonePrefixFromCountry() {
    const data = window.FLEETX_LOGGED_IN_USER || {};

    const countryCallingCode = get(data, 'countryCode');
    if (countryCallingCode) {
        return countryCallingCode;
    }

    const country = get(data, 'country');
    const countryName = country ? country : 'INDIA';
    const phoneNumberPrefix = get(countryToDetailsMap[countryName], 'phoneNumberPrefix');

    return phoneNumberPrefix;
}

// export const ISO_COUNTRY_CODES = {
//     INDIA: 'in',
//     INDONESIA: 'id',
//     BOTSWANA: 'bwa',
//     KENYA: 'ken',
//     UAE: 'ae',
//     OMAN: 'om',
//     SAUDI_ARABIA: 'sau',
//     NIGERIA: 'nga',
//     GHANA: 'gha',
//     MOZAMBIQUE: 'moz',
//     SOUTH_AFRICA: 'saf',
//     TANZANIA: 'tza',
//     RWANDA: 'rwa',
//     UGANDA: 'uga',
//     PHILLIPIINES: 'phl',
//     VIETNAM: 'vnm',
// };

// export const ISO_COUNTRY_CODE_TO_DEFAULT_MAP_CENTER = {
//     ae: {
//         lat: 24.466667,
//         lng: 54.366669,
//     },
//     bwa: {
//         lat: -24.653257,
//         lng: 25.906792,
//     },
//     gha: {
//         lat: 5.614818,
//         lng: -0.205874,
//     },
//     id: {
//         lat: -6.166627,
//         lng: 106.816667,
//     },
//     in: {
//         lat: 28.614019,
//         lng: 77.207932,
//     },
//     ken: {
//         lat: -1.286389,
//         lng: 36.817223,
//     },
//     om: {
//         lat: 23.614328,
//         lng: 58.545284,
//     },
//     nga: {
//         lat: 9.05785,
//         lng: 7.49508,
//     },
//     moz: {
//         lat: -25.96553,
//         lng: 32.58322,
//     },
//     rwa: {
//         lat: -1.935114,
//         lng: 30.082111,
//     },
//     phl: {
//         lat: 14.599512,
//         lng: 120.984222,
//     },
//     saf: {
//         lat: -33.918861,
//         lng: 18.4233,
//     },
//     sau: {
//         lat: 24.774265,
//         lng: 46.738586,
//     },
//     tza: {
//         lat: -6.161184,
//         lng: 35.745426,
//     },
//     uga: {
//         lat: 0.347596,
//         lng: 32.58252,
//     },
//     vnm: {
//         lat: 21.028511,
//         lng: 105.804817,
//     },
// };

export function getISOCountryCode(loggedInUser = window.FLEETX_LOGGED_IN_USER) {
    const countryISOCode = get(loggedInUser, 'isoCountryCode');
    if (countryISOCode) {
        return countryISOCode;
    }
    const country = get(loggedInUser, 'country', COUNTRIES.INDIA);
    return get(countryToDetailsMap[country], 'isoCountryCode');
    //return ISO_COUNTRY_CODES[country];
}

export function getLocationForPlacePrediction(loggedInUser = window.FLEETX_LOGGED_IN_USER) {
    const country = get(loggedInUser, 'country', COUNTRIES.INDIA);
    return get(countryToDetailsMap[country], 'mapCenter', {
        lat: 28.614019,
        lng: 77.207932,
    });
    //const countryCode = getISOCountryCode(loggedInUser);
    //return ISO_COUNTRY_CODE_TO_DEFAULT_MAP_CENTER[countryCode];
}

export function getCountryDefaultMapCenter(loggedInUser = window.FLEETX_LOGGED_IN_USER) {
    const country = get(loggedInUser, 'country', COUNTRIES.INDIA);
    return get(countryToDetailsMap[country], 'mapCenter', {
        lat: 28.614019,
        lng: 77.207932,
    });
    //return getLocationForPlacePrediction(loggedInUser);
}

export const mappedCurrencyOptions = [
    {
        value: 'DKK',
        label: 'DKK (Danish krone)',
    },
    {
        value: 'SAR',
        label: 'SAR (Saudi riyal)',
    },
    {
        value: 'XAF',
        label: 'XAF (Central African CFA franc)',
    },
    {
        value: 'JYP',
        label: 'JPY (Japanese yen)',
    },
    {
        value: 'CHF',
        label: 'CHF (Swiss franc)',
    },
    {
        value: 'EUR',
        label: 'EUR (Euro)',
    },
    {
        value: 'GBP',
        label: 'GBP (Pound sterling)',
    },
    {
        value: 'USD',
        label: 'USD (United States dollar)',
    },
    {
        value: 'NGN',
        label: 'NGN (Nigerian naira)',
    },
    {
        value: 'ZAR',
        label: 'ZAR (South African rand)',
    },
    {
        value: 'CNY',
        label: 'CNY (Renminbi)',
    },
    {
        value: 'XDR',
        label: 'XDR (Special drawing rights)',
    },
    {
        value: 'UAH',
        label: 'UAH (Ukrainian hryvnia)',
    },
    {
        value: 'TRY',
        label: 'TRY (Turkish lira)',
    },
    {
        value: 'RON',
        label: 'RON (Romanian leu)',
    },
    {
        value: 'CAD',
        label: 'CAD (Canadian dollar)',
    },
    {
        value: 'CZK',
        label: 'CZK (Czech koruna)',
    },
    {
        value: 'AED',
        label: 'AED (United Arab Emirates dirham)',
    },
    {
        value: 'AUD',
        label: 'AUD (Australian dollar)',
    },
    {
        value: 'BRL',
        label: 'BRL (Brazilian real)',
    },
    {
        value: 'HUF',
        label: 'HUF (Hungarian forint)',
    },
    {
        value: 'INR',
        label: 'INR (Indian rupee)',
    },
    {
        value: 'MDL',
        label: 'MDL (Moldovan leu)',
    },
    {
        value: 'MXN',
        label: 'MXN (Mexican peso)',
    },
    {
        value: 'NOK',
        label: 'NOK (Norwegian krone)',
    },
    {
        value: 'PLN',
        label: 'PLN (Polish złoty)',
    },
    {
        value: 'RSD',
        label: 'RSD (Serbian dinar)',
    },
    {
        value: 'RUB',
        label: 'RUB (Russian ruble)',
    },
    {
        value: 'THB',
        label: 'THB (Thai baht)',
    },
    {
        value: 'KRW',
        label: 'KRW (South Korean won)',
    },
    {
        value: 'EGP',
        label: 'EGP (Egyptian pound)',
    },
    {
        value: 'NZD',
        label: 'NZD (New Zealand dollar)',
    },
    {
        value: 'BGN',
        label: 'BGN (Bulgarian lev)',
    },
    {
        value: 'SEK',
        label: 'SEK (Swedish krona)',
    },
    {
        value: 'SGD',
        label: 'SGD (Singapore dollar)',
    },
    {
        value: 'HKD',
        label: 'HKD (Hong Kong dollar)',
    },
    {
        value: 'BIF',
        label: 'BIF (Burundian franc)',
    },
    {
        value: 'RWF',
        label: 'RWF (Rwandan franc)',
    },
    {
        value: 'TZS',
        label: 'TZS (Tanzanian shilling)',
    },
    {
        value: 'UGX',
        label: 'UGX (Ugandan shilling)',
    },
    {
        value: 'KES',
        label: 'KES (Kenyan shilling)',
    },
    {
        value: 'VES',
        label: 'VES (Venezuelan bolívar)',
    },
    {
        value: 'LAK',
        label: 'LAK (Lao kip)',
    },
    {
        value: 'XCD',
        label: 'XCD (Eastern Caribbean dollar)',
    },
    {
        value: 'JOD',
        label: 'JOD (Jordanian dinar)',
    },
    {
        value: 'ZMW',
        label: 'ZMW (Zambian kwacha)',
    },
    {
        value: 'SBD',
        label: 'SBD (Solomon Islands dollar)',
    },
    {
        value: 'AFN',
        label: 'AFN (Afghan afghani)',
    },
    {
        value: 'FJD',
        label: 'FJD (Fijian dollar)',
    },
    {
        value: 'NPR',
        label: 'NPR (Nepalese rupee)',
    },
    {
        value: 'ISK',
        label: 'ISK (Icelandic króna)',
    },
    {
        value: 'CUP',
        label: 'CUP (Cuban peso)',
    },
    {
        value: 'BHD',
        label: 'BHD (Bahraini dinar)',
    },
    {
        value: 'MNT',
        label: 'MNT (Mongolian tögrög)',
    },
    {
        value: 'PYG',
        label: 'PYG (Paraguayan guaraní)',
    },
    {
        value: 'MWK',
        label: 'MWK (Malawian kwacha)',
    },
    {
        value: 'MKD',
        label: 'MKD (Macedonian denar)',
    },
    {
        value: 'MMK',
        label: 'MMK (Burmese kyat)',
    },
    {
        value: 'KMF',
        label: 'KMF (Comorian franc)',
    },
    {
        value: 'MOP',
        label: 'MOP (Macanese pataca)',
    },
    {
        value: 'AZN',
        label: 'AZN (Azerbaijani manat)',
    },
    {
        value: 'GTQ',
        label: 'GTQ (Guatemalan quetzal)',
    },
    {
        value: 'SVC',
        label: 'SVC (Salvadoran colón)',
    },
    {
        value: 'IRR',
        label: 'IRR (Iranian rial)',
    },
    {
        value: 'BBD',
        label: 'BBD (Barbadian dollar)',
    },
    {
        value: 'YER',
        label: 'YER (Yemeni rial)',
    },
    {
        value: 'GHS',
        label: 'GHS (Ghanaian cedi)',
    },
    {
        value: 'ERN',
        label: 'ERN (Eritrean nakfa)',
    },
    {
        value: 'PAB',
        label: 'PAB (Panamanian balboa)',
    },
    {
        value: 'DJF',
        label: 'DJF (Djiboutian franc)',
    },
    {
        value: 'BMD',
        label: 'BMD (Bermudian dollar)',
    },
    {
        value: 'BZD',
        label: 'BZD (Belize dollar)',
    },
    {
        value: 'IDR',
        label: 'IDR (Indonesian rupiah)',
    },
    {
        value: 'BND',
        label: 'BND (Brunei dollar)',
    },
    {
        value: 'PEN',
        label: 'PEN (Peruvian sol)',
    },
    {
        value: 'TTD',
        label: 'TTD (Trinidad and Tobago dollar)',
    },
    {
        value: 'UYU',
        label: 'UYU (Uruguayan peso)',
    },
    {
        value: 'LBP',
        label: 'LBP (Lebanese pound)',
    },
    {
        value: 'PHP',
        label: 'PHP (Philippine peso)',
    },
    {
        value: 'AMD',
        label: 'AMD (Armenian dram)',
    },
    {
        value: 'LYD',
        label: 'LYD (Libyan dinar)',
    },
    {
        value: 'TJS',
        label: 'TJS (Tajikistani somoni)',
    },
    {
        value: 'CDF',
        label: 'CDF (Congolese franc)',
    },
    {
        value: 'TMT',
        label: 'TMT (Turkmenistan manat)',
    },
    {
        value: 'IQD',
        label: 'IQD (Iraqi dinar)',
    },
    {
        value: 'STN',
        label: 'STN (São Tomé and Príncipe dobra)',
    },
    {
        value: 'AWG',
        label: 'AWG (Aruban florin)',
    },
    {
        value: 'BTN',
        label: 'BTN (Bhutanese ngultrum)',
    },
    {
        value: 'NAD',
        label: 'NAD (Namibian dollar)',
    },
    {
        value: 'TOP',
        label: 'TOP (Tongan paʻanga)',
    },
    {
        value: 'MRU',
        label: 'MRU (Mauritanian ouguiya)',
    },
    {
        value: 'MVR',
        label: 'MVR (Maldivian rufiyaa)',
    },
    {
        value: 'GYD',
        label: 'GYD (Guyanese dollar)',
    },
    {
        value: 'OMR',
        label: 'OMR (Omani rial)',
    },
    {
        value: 'DZD',
        label: 'DZD (Algerian dinar)',
    },
    {
        value: 'KYD',
        label: 'KYD (Cayman Islands dollar)',
    },
    {
        value: 'GIP',
        label: 'GIP (Gibraltar pound)',
    },
    {
        value: 'LSL',
        label: 'LSL (Lesotho loti)',
    },
    {
        value: 'PGK',
        label: 'PGK (Papua New Guinean kina)',
    },
    {
        value: 'SZL',
        label: 'SZL (Swazi lilangeni)',
    },
    {
        value: 'SRD',
        label: 'SRD (Surinamese dollar)',
    },
    {
        value: 'ALL',
        label: 'ALL (Albanian lek)',
    },
    {
        value: 'KHR',
        label: 'KHR (Cambodian riel)',
    },
    {
        value: 'CVE',
        label: 'CVE (Cape Verdean escudo)',
    },
    {
        value: 'ANG',
        label: 'ANG (Netherlands Antillean guilder)',
    },
    {
        value: 'BSD',
        label: 'BSD (Bahamian dollar)',
    },
    {
        value: 'HNL',
        label: 'HNL (Honduran lempira)',
    },
    {
        value: 'HTG',
        label: 'HTG (Haitian gourde)',
    },
    {
        value: 'SLL',
        label: 'SLL (Sierra Leonean leone)',
    },
    {
        value: 'CUC',
        label: 'CUC (Cuban convertible peso)',
    },
    {
        value: 'CLP',
        label: 'CLP (Chilean peso)',
    },
    {
        value: 'ILS',
        label: 'ILS (Israeli new shekel)',
    },
    {
        value: 'COP',
        label: 'COP (Colombian peso)',
    },
    {
        value: 'BAM',
        label: 'BAM (Bosnia and Herzegovina convertible mark)',
    },
    {
        value: 'BWP',
        label: 'BWP (Botswana pula)',
    },
    {
        value: 'KWD',
        label: 'KWD (Kuwaiti dinar)',
    },
    {
        value: 'CRC',
        label: 'CRC (Costa Rican colón)',
    },
    {
        value: 'NIO',
        label: 'NIO (Nicaraguan córdoba)',
    },
    {
        value: 'KZT',
        label: 'KZT (Kazakhstani tenge)',
    },
    {
        value: 'SYP',
        label: 'SYP (Syrian pound)',
    },
    {
        value: 'MGA',
        label: 'MGA (Malagasy ariary)',
    },
    {
        value: 'UZS',
        label: 'UZS (Uzbekistani soʻm)',
    },
    {
        value: 'LKR',
        label: 'LKR (Sri Lankan rupee)',
    },
    {
        value: 'AOA',
        label: 'AOA (Angolan kwanza)',
    },
    {
        value: 'GEL',
        label: 'GEL (Georgian lari)',
    },
    {
        value: 'VUV',
        label: 'VUV (Vanuatu vatu)',
    },
    {
        value: 'GMD',
        label: 'GMD (Gambian dalasi)',
    },
    {
        value: 'KGS',
        label: 'KGS (Kyrgyzstani som)',
    },
    {
        value: 'SOS',
        label: 'SOS (Somali shilling)',
    },
    {
        value: 'MRO',
        label: 'MRO (Mauritanian ouguiya)',
    },
    {
        value: 'SSP',
        label: 'SSP (South Sudanese pound)',
    },
    {
        value: 'GNF',
        label: 'GNF (Guinean franc)',
    },
    {
        value: 'KID',
        label: 'KID (Kiribati dollar)',
    },
    {
        value: 'XPF',
        label: 'XPF (CFP franc)',
    },
    {
        value: 'FKP',
        label: 'FKP (Falkland Islands pound)',
    },
    {
        value: 'SHP',
        label: 'SHP (Saint Helena pound)',
    },
    {
        value: 'GGP',
        label: 'GGP (Guernsey pound)',
    },
    {
        value: 'IMP',
        label: 'IMP (Isle of Man pound)',
    },
    {
        value: 'MZN',
        label: 'Mozambican metical',
    },
    {
        value: 'BDT',
        label: 'BDT (Bangladeshi Taka)',
    },
    {
        value: 'VND',
        label: 'VND (Vietnamese Dong)',
    },
];

export const getCurrencyOptionsFromCurrencyCodes = (currencyCodes) => {
    return filter(mappedCurrencyOptions, (o) => includes(currencyCodes, get(o, 'value')));
};
