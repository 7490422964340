/**
 * Created by abhay on 2017-07-12.
 */
import { handle } from 'redux-pack';
import {
    FETCH_LOADING_POINT,
    FETCH_INCOMING_VEHICLES,
} from '../../actions/dashboard/internal_tools/transporter_action';

const initialState = {
    isLoading: false,
    error: null,
    incomingVehicles: [],
    loadingPoints: {},
    isFinished: false,
    isFetchingIncomingVehicle: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_LOADING_POINT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    loadingPoints: payload.data,
                }),
            });
        case FETCH_INCOMING_VEHICLES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isFetchingIncomingVehicle: true,
                    error: null,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isFetchingIncomingVehicle: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    incomingVehicles: payload.data.content,
                }),
            });
        default:
            return state;
    }
}
