import { includes } from 'lodash';
import { ADDRESS_TYPES } from '../utils/consignmentsUtils';
export const CHALAN_TYPES = [
    {
        value: 'PICKUP',
        label: 'Pick Up',
    },
    {
        value: 'DISPATCH',
        label: 'Dispatch',
    },
    {
        value: 'DELIVERY',
        label: 'Delivery',
    },
];
const CHALLAN_TYPE_DISABLED_FIELDS = {
    'PICKUP': ['deliveryType', 'ewayBill', 'remarkLog'],
    'DELIVERY': ['deliveryType', 'ewayBill', 'remarkLog'],
};
export const isChallanFieldDisabled = (fieldName, challanType) => {
    return includes(CHALLAN_TYPE_DISABLED_FIELDS[challanType], fieldName);
};
export const prepareConsignmentFetchQuery = ({ challanType, challanOfficeId, deliveryType }) => {
    switch (challanType) {
        case 'PICKUP':
            return {
                pickupType: ADDRESS_TYPES.DOOR,
                // nature: CONSIGNMENT_NATURES.PTL,
                fromBranchId: challanOfficeId,
                pickupChallanCompleted: false,
            };
        case 'DISPATCH':
            const data = {
                toBranchIdExcept: challanOfficeId,
                deliveryType: ADDRESS_TYPES.DOOR,
                withBalanceMaterialsAtBranch: challanOfficeId,
            };
            if (deliveryType === ADDRESS_TYPES.GODOWN) {
                delete data.deliveryType;
            }
            return data;
        case 'DELIVERY':
            return {
                // nature: CONSIGNMENT_NATURES.PTL,
                toBranchId: challanOfficeId,
                // deliveryType: ADDRESS_TYPES.GODOWN,
                withBalanceMaterialsAtBranch: challanOfficeId,
            };
        default:
            return {};
    }
};
export const prepareRouteFetchQuery = ({ challanType, challanOfficeId }) => {
    switch (challanType) {
        case 'PICKUP':
            return {
                destinationBranchId: challanOfficeId,
            };
        case 'DISPATCH':
        case 'DELIVERY':
            return {
                originBranchId: challanOfficeId,
            };
        default:
            return {};
    }
};
