import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const AddEditChallanComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-challan-challan-add-edit" */ 'components/dashboard/challan/forms/AddEditChallanComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ChallansComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-challan-challans" */ 'components/dashboard/challan/ChallansComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const challansRoutes = [
    {
        path: 'add',
        lazy: AddEditChallanComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditChallanComponent,
    },
    {
        index: true,
        lazy: ChallansComponent,
    },
];
export default challansRoutes;
