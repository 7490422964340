import { toString } from 'lodash';

export async function initFiboTalk({ account_id, user_id, email, phone, name, customer_success_manager }) {
    if (window.fibo) {
        const obj = {
            'user': {
                'userId': user_id,
                'name': toString(name),
                'email': toString(email),
                'phone': toString(phone),
            },
            'account': {
                'accountId': toString(account_id),
            },
        };
        console.log('FiboTalk loaded', obj);
        window.fibo.signup(obj);
        window.fibo.setUserInfo(obj);

        window.trackFiboTalkEvent = (feature, module, product) => {
            if (
                (module == 'Top Header' && feature == 'Jobs') ||
                (module == 'Top Header' && feature == 'Routes') ||
                (module == 'Top Header' && feature == 'Vehicles') ||
                (module == 'Jobs & Routes' && feature == 'Jobs-Add Job') ||
                (module == 'Jobs & Routes' && feature == 'Routes Submit')
            ) {
                window.fibo.setEvent(module, feature, { product: 'Web' });
            }
        };
    } else {
        window.fibotalkSettings = {
            'user': {
                'userId': user_id,
                'name': toString(name),
                'email': toString(email),
                'phone': toString(phone),
            },
            'account': {
                'accountId': toString(account_id),
            },
        };
    }
}
