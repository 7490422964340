import axios from 'axios';
import {
    ROOT_API,
    ROOT_API_URL,
    ROOT_API_URL_API_PROXY,
    ROOT_API_STAGING,
    startUsingApiProxyUrl,
    USE_API_PROXY_KEY,
    ROOT_NODE_API_URL,
    ROOT_API_URL_V2,
} from '../../constant';
import { assign, set, trim } from 'lodash';
import storage from 'utils/storage_utils';

export const FETCH_LOGIN = 'fetch_login';
export const FORGOT_PASSWORD = 'forgot_password';
export const RESET_PASSWORD = 'reset_password';
export const LOGOUT_USER = 'logout_user';
export const REQUEST_A_DEMO = 'request_a_demo';
export const VALIDATE_DATA_AND_GET_FRESHDESK_SSO_HASH = 'validate_data_and_get_freshdesk_sso_hash';

export const GENERATE_LOGIN_OTP = 'GENERATE_LOGIN_OTP';

export function fetchLogin(values) {
    const data = new FormData();

    data.append('username', trim(values.email));
    data.append('password', values.password);
    if (values.login_type) {
        data.append('login_type', values.login_type);
    }
    data.append('grant_type', 'password');

    let url = ROOT_API_URL;
    // if (
    //     values.email.match('blackrock') ||
    //     values.email.match('gobain') ||
    //     values.email.match('beersingh') ||
    //     values.email.match('golcha')
    // ) {
    //     startUsingApiProxyUrl();
    //     url = ROOT_API_URL_API_PROXY;
    // } else
    if (storage.getItem(USE_API_PROXY_KEY)) {
        startUsingApiProxyUrl();
        url = ROOT_API_URL_API_PROXY;
    }

    const config = { headers: { 'Authorization': 'Basic ZmxlZXR4d2ViOnNlY3JldEAxMjM=', 'clientId': 'fleetxweb' } };
    const request = axios.post(`${url}login`, data, config);

    return {
        type: FETCH_LOGIN,
        promise: request,
    };
}

export function requestDemo(values) {
    let data = assign({}, values);
    //set(data, 'phoneNumber', '+91-' + data.phoneNumber);
    const request = axios.post(`${ROOT_API_URL}demo/`, data);

    return {
        type: REQUEST_A_DEMO,
        promise: request,
    };
}

export function logout(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API}logout`, config);

    return {
        type: LOGOUT_USER,
        promise: request,
    };
}

export function fetchLoginByToken(token, validator) {
    let dataJson = {
        authCode: token,
        validator: validator,
    };

    let url = ROOT_API_URL_V2;

    const config = {
        headers: {
            'Authorization': 'Basic ZmxlZXR4d2ViOnNlY3JldEAxMjM=',
            'clientId': 'fleetxweb',
            'Content-Type': 'application/json',
        },
    };
    const request = axios.post(`${url}login/by-code`, dataJson, config);

    return {
        type: FETCH_LOGIN,
        promise: request,
    };
}

export function logoutState() {
    return {
        type: LOGOUT_USER,
    };
}

export function forgotPassword(values) {
    const data = new FormData();
    data.append('email', values.email);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const request = axios.post(`${ROOT_API_URL}users/forgot/password`, data, config);

    return {
        type: FORGOT_PASSWORD,
        promise: request,
    };
}

export function resetPassword(values) {
    const request = axios.post(`${ROOT_API_URL}users/reset/password`, values);

    return {
        type: RESET_PASSWORD,
        promise: request,
    };
}

export function validateUserAndGenerateFreshdeskSSOHash(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/misc/freshdeskssohash`, config);

    return {
        type: VALIDATE_DATA_AND_GET_FRESHDESK_SSO_HASH,
        promise: request,
    };
}

export function generateLoginOtp(username) {
    const data = new FormData();
    data.append('username', username);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const request = axios.post(`${ROOT_API_URL}login/otp/generate`.replace('v1', 'v2'), data, config);

    return {
        type: GENERATE_LOGIN_OTP,
        promise: request,
    };
}

export function fetchLoginByOtp(values) {
    const data = new FormData();

    data.append('username', trim(values.email));
    data.append('otp', values.otp);
    data.append('sessionId', values.sessionId);

    if (values.login_type) {
        data.append('login_type', values.login_type);
    }

    let url = ROOT_API_URL;

    if (storage.getItem(USE_API_PROXY_KEY)) {
        startUsingApiProxyUrl();
        url = ROOT_API_URL_API_PROXY;
    }

    const config = { headers: { 'Authorization': 'Basic ZmxlZXR4d2ViOnNlY3JldEAxMjM=', 'clientId': 'fleetxweb' } };
    const request = axios.post(`${url}login/otp`.replace('v1', 'v2'), data, config);

    return {
        type: FETCH_LOGIN,
        promise: request,
    };
}
