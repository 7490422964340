import axios from 'axios';
import { ROOT_API_URL } from '../../../constant';
export const CREATE_INTERNAL_TAGS_BULK = 'create_internal_tags_in_bulk';
export const CREATE_INTERNAL_TAG = 'create_internal_tag';
export const UPDATE_INTERNAL_TAG = 'update_internal_tag';
export const DELETE_INTERNAL_TAG = 'delete_internal_tag';
export const FETCH_INTERNAL_TAGS = 'fetch_internal_tags';
export const FETCH_INTERNAL_TAG_BY_ID = 'fetch_internal_tag_by_id';
export function createInternalTag(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}internal/tag`, data, config);
    return {
        type: CREATE_INTERNAL_TAG,
        promise: request,
    };
}
export function createInternalTagsInBulk(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}internal/tag/bulk`, data, config);
    return {
        type: CREATE_INTERNAL_TAGS_BULK,
        promise: request,
    };
}
export function updateInternalTag(accesstoken, data, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}internal/tag/${id}`, data, config);
    return {
        type: UPDATE_INTERNAL_TAG,
        promise: request,
    };
}
export function deleteInternalTag(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}internal/tag/${id}`, config);
    return {
        type: DELETE_INTERNAL_TAG,
        promise: request,
    };
}
export function fetchInternalTags(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}internal/tag/`, config);
    return {
        type: FETCH_INTERNAL_TAGS,
        promise: request,
    };
}
export function fetchInternalTagById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}internal/tag/${id}`, config);
    return {
        type: FETCH_INTERNAL_TAG_BY_ID,
        promise: request,
    };
}
