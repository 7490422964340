import axios from 'axios';
import { ROOT_API_URL } from '../../constant';
export const CREATE_FIRM = 'create_firm';
export const FETCH_FIRMS = 'fetch_firms';
export const FETCH_FIRM = 'fetch_firm';
export const UPDATE_FIRM = 'update_firm';
export const DELETE_FIRM = 'delete_firm';
export function createFirm(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}accounts/firms`, values, config);
    return {
        type: CREATE_FIRM,
        promise: request,
    };
}
export function updateFirm(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}accounts/firms`, values, config);
    return {
        type: UPDATE_FIRM,
        promise: request,
    };
}
export function fetchFirms(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: {
            accountId,
        },
    };
    const request = axios.get(`${ROOT_API_URL}accounts/firms`, config);
    return {
        type: FETCH_FIRMS,
        promise: request,
    };
}
export function fetchFirm(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}accounts/firms/${id}`, config);
    return {
        type: FETCH_FIRM,
        promise: request,
    };
}
export function deleteFirm(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}accounts/firms/${id}`, config);
    return {
        type: DELETE_FIRM,
        promise: request,
    };
}
