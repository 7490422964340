import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, get, includes, map, toNumber } from 'lodash';
import { Button, Table } from 'reactstrap';
import { mappedOptionsFromArray } from '../../utils/mapping_utils';
import { Field, FieldArray } from 'formik';
import { FxSelectField, FxTextField } from '../../../shared';
import { mappedConsignmentsList } from '../../../utils/form_utils';
const DeductionComponent = (props) => {
    const { ledgers, consignments, formikProps, newConsignmentOptions = [], preSelectedRows } = props;
    const { setFieldValue } = formikProps;
    const deductionMappings = get(formikProps, 'values.deductionMappings', []);
    const filteredConsignments = useMemo(() => {
        const selectedCNIds = map(preSelectedRows, (r) => toNumber(r?.split('-')?.[0]));
        let filteredConsignments = filter(consignments, (consignment) => includes(selectedCNIds, consignment.id));
        return filteredConsignments;
    }, [consignments, preSelectedRows]);
    return (_jsx(FieldArray, { name: 'deductionMappings', children: (arrayHelpers) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "d-flex justify-content-between align-items-center mt-4 mb-3", children: [_jsx("h6", { className: "mb-0", children: "Deduction Details" }), _jsxs(Button, { type: "button", color: "primary", className: "ml-2", onClick: () => arrayHelpers.push({}), children: [_jsx("i", { className: "fa fa-plus" }), " Add Deduction"] })] }), _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { style: { border: 'none' }, children: "Consignment Number" }), _jsx("th", { style: { border: 'none' }, children: "Account Nature" }), _jsx("th", { style: { border: 'none' }, children: "Amount" }), _jsx("th", { style: { border: 'none' }, children: "Remarks" }), _jsx("th", { style: { border: 'none' }, children: "Remove" })] }) }), _jsx("tbody", { children: map(deductionMappings, (deductionMapping, index) => {
                                return (_jsxs("tr", { children: [_jsx("td", { children: _jsx(Field, { name: `deductionMappings[${index}].entityId`, component: FxSelectField, options: [
                                                    ...mappedConsignmentsList(filteredConsignments),
                                                    ...newConsignmentOptions,
                                                ], className: "mb-0" }) }), _jsx("td", { children: _jsx(Field, { name: `deductionMappings[${index}].deductionLedger.ledgerId`, component: FxSelectField, options: mappedOptionsFromArray(ledgers, 'ledgerName', 'id'), className: "mb-0" }) }), _jsx("td", { children: _jsx(Field, { name: `deductionMappings[${index}].deductionAmount`, component: FxTextField, placeholder: "Amount", inputClassNames: "w-100", onChange: (val) => {
                                                    setFieldValue(`deductionMappings[${index}].deductionLedger.amount`, val);
                                                } }) }), _jsx("td", { children: _jsx(Field, { name: `deductionMappings[${index}].remark`, component: FxTextField, placeholder: "Remark", inputClassNames: "w-100", onChange: (val) => {
                                                    setFieldValue(`deductionMappings[${index}].deductionLedger.remark`, val);
                                                } }) }), _jsx("td", { width: "10%", children: _jsx(Button, { type: "button", color: "danger", onClick: () => arrayHelpers.remove(index), children: _jsx("i", { className: "fa fa-trash" }) }) })] }, index));
                            }) })] })] })) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DeductionComponent);
