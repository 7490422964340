import axios from 'axios';
import { ROOT_API_ALFRED } from '../../../../constant';
export const FETCH_TMS_ANALYTICS_DATA = 'FETCH_TMS_ANALYTICS_DATA';
export function fetchTMSAnalyticsData(accesstoken, filterData) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ALFRED}/analytics/count`, filterData, config);
    return {
        type: FETCH_TMS_ANALYTICS_DATA,
        promise: request,
    };
}
