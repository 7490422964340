import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditEntityReminder = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-addEditEntityReminder" */ 'components/dashboard/reminders/entityreminders/AddEditEntityReminder'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditContactRenewalReminderComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-addEditContactRenewalReminder" */ 'components/dashboard/reminders/contactrenewalreminders/add_edit_contact_renewal_reminder_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ContactRenewalsReminderComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-contactRenewalsReminder" */ 'components/dashboard/reminders/contactrenewalreminders/contact_renewal_reminders_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditServiceReminderComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-addEditServiceReminder" */ 'components/dashboard/reminders/servicereminders/add_edit_service_reminder_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddMultipleServiceRemindersComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-addMultipleServiceReminders" */ 'components/dashboard/reminders/servicereminders/add_multiple_service_reminders_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ServiceRemindersComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-serviceReminders" */ 'components/dashboard/reminders/servicereminders/service_reminders_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const VehicleRenewalReminder = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-vehicleRenewalReminder" */ 'components/dashboard/reminders/vehiclerenewalreminders/VehicleRenewalReminder'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditVehicleRenewalReminderComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-addEditVehicleRenewalReminder" */ 'components/dashboard/reminders/vehiclerenewalreminders/add_edit_vehicle_renewal_reminder_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddMultipleVehicleRenewalRemindersComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-addMultipleVehicleRenewalReminders" */ 'components/dashboard/reminders/vehiclerenewalreminders/add_multiple_vehicle_renewal_reminders_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const EntityReminder = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-reminders-entityReminder" */ 'components/dashboard/reminders/entityreminders/EntityReminder'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const remindersRoutes = [
    {
        path: 'contactrenewals/edit/:userId/:id',
        lazy: AddEditContactRenewalReminderComponent,
    },
    {
        path: 'contactrenewals/add/:userId',
        lazy: AddEditContactRenewalReminderComponent,
    },
    {
        path: 'contactrenewals/add/',
        lazy: AddEditContactRenewalReminderComponent,
    },
    {
        path: 'contactrenewals',
        lazy: ContactRenewalsReminderComponent,
    },
    {
        path: 'vehiclerenewals/edit/:vehicleId/:id',
        lazy: AddEditVehicleRenewalReminderComponent,
    },
    {
        path: 'vehiclerenewals/add/:vehicleId',
        lazy: AddEditVehicleRenewalReminderComponent,
    },
    {
        path: 'vehiclerenewals/addMultiple',
        lazy: AddMultipleVehicleRenewalRemindersComponent,
    },
    {
        path: 'servicereminders/edit/:vehicleId/:id',
        lazy: AddEditServiceReminderComponent,
    },
    {
        path: 'servicereminders/add/:vehicleId',
        lazy: AddEditServiceReminderComponent,
    },
    {
        path: 'servicereminders/addMultiple',
        lazy: AddMultipleServiceRemindersComponent,
    },
    {
        path: 'servicereminders',
        lazy: ServiceRemindersComponent,
    },
    {
        path: 'vehiclerenewals',
        lazy: VehicleRenewalReminder,
    },
    {
        path: 'entityreminders/add',
        lazy: AddEditEntityReminder,
    },
    {
        path: 'entityreminders/edit/:id',
        lazy: AddEditEntityReminder,
    },
    {
        path: 'entityreminders',
        lazy: EntityReminder,
    },
];
export default remindersRoutes;
