import { handle } from 'redux-pack';
import { FETCH_ACCOUNT_CONFIG, FETCH_APP_CONFIG, FETCH_USER_PERSONALISATION } from 'actions/dashboard/config_actions';
import { get, isEmpty } from 'lodash';
import { getInitialWidgetConfig, initial_account_config } from 'utils/initial_account_config';
import { reconcileNewAndInitialConfig } from 'utils/config_utils';

const initialState = {
    isLoading: false,
    error: null,
    config: [],
    isFinished: false,
    isConfigLoading: false,
    globalAppConfig: {},
    globalConfigLoading: false,
    globalConfigError: null,
    accountConfig: initial_account_config,
    widgetConfig: {},
};

export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ACCOUNT_CONFIG:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isConfigLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true, isConfigLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const initial_widget_config = getInitialWidgetConfig(root.login?.data?.scope || []);
                    let newConfig = initial_account_config;
                    let newWidgetConfig = initial_widget_config;
                    if (get(payload, 'data.account_config') && !isEmpty(get(payload, 'data.account_config'))) {
                        newConfig = get(payload, 'data.account_config');
                    }
                    if (get(payload, 'data.widget_config') && !isEmpty(get(payload, 'data.widget_config'))) {
                        newWidgetConfig = get(payload, 'data.widget_config');
                    }
                    let account_config = reconcileNewAndInitialConfig(newConfig, initial_account_config);
                    let widget_config = reconcileNewAndInitialConfig(newWidgetConfig, initial_widget_config);
                    return {
                        ...prevState,
                        accountConfig: account_config,
                        widgetConfig: !isEmpty(widget_config) ? widget_config : initial_widget_config,
                        config: get(payload, 'data.account_config'),
                    };
                },
            });
        case FETCH_APP_CONFIG:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    globalAppConfig: {},
                    globalConfigLoading: true,
                    globalConfigError: null,
                }),
                finish: (prevState) => ({ ...prevState, globalConfigLoading: false }),
                failure: (prevState) => ({ ...prevState, globalConfigError: payload }),
                success: (prevState) => ({
                    ...prevState,
                    globalAppConfig: get(payload, 'data'),
                }),
            });
        case FETCH_USER_PERSONALISATION:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isUserPersonalisationLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isUserPersonalisationLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    userPersonalisation: get(payload, 'data.user_personalisation'),
                }),
            });
        default:
            return state;
    }
}
