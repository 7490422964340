import axios from 'axios';
import { ROOT_NODE_API_URL } from '../../constant';

export const FETCH_CONCOX_LOCATION_CHANGE = 'fetch_concox_location_change';
export const FETCH_IDLE_TRIP_DELETES = 'fetch_idle_trip_deletes';
export const FETCH_OBD_ZERO_KM_TRIPS = 'fetch_obd_zero_km_trips';
export const FETCH_OBD_NO_LOCATION_CHANGED = 'fetch_obd_no_location_changed';
export const FETCH_METABASE_DASHBOARD_EMEBED_URL = 'fetch_metabase_dashboard_embed_url';

export function fetchConcoxLocationChange() {
    const data = {
        token: 'udbhav',
    };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/metabase/getconcoxlocationchanged`, data);
    return {
        type: FETCH_CONCOX_LOCATION_CHANGE,
        promise: request,
    };
}

export function fetchObdZeroKmTrips() {
    const data = {
        token: 'udbhav',
    };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/metabase/getobdzerokmtrips`, data);
    return {
        type: FETCH_OBD_ZERO_KM_TRIPS,
        promise: request,
    };
}

export function fetchIdleTripDeletesChange() {
    const data = {
        token: 'udbhav',
    };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/metabase/getidletripdeletes`, data);
    return {
        type: FETCH_IDLE_TRIP_DELETES,
        promise: request,
    };
}

export function fetchObdNoLocationChanged() {
    const data = {
        token: 'udbhav',
    };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/metabase/getobdnolocationchange`, data);
    return {
        type: FETCH_OBD_NO_LOCATION_CHANGED,
        promise: request,
    };
}

export function fetchMetabaseDashboardEmbedUrl(accesstoken, dashboardId, type) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };

    const typeParam = type ? `type=${type}` : '';
    const request = axios.get(`${ROOT_NODE_API_URL}napp/metabase/signeddashboard/${dashboardId}?${typeParam}`, config);
    return {
        type: FETCH_METABASE_DASHBOARD_EMEBED_URL,
        promise: request,
    };
}
