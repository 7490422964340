import { handle } from 'redux-pack';
import {
    CREATE_VEHICLE_RENEWAL_REMINDER,
    DELETE_VEHICLE_RENEWAL_REMINDER,
    FETCH_VEHICLE_RENEWAL_REMINDER,
    FETCH_VEHICLE_RENEWAL_REMINDERS,
    FETCH_VEHICLE_RENEWAL_REMINDERS_COUNT,
    FETCH_VEHICLE_RENEWAL_REMINDERS_NOTIFICATIONS,
    UPDATE_VEHICLE_RENEWAL_REMINDER,
} from '../../../actions/dashboard/easy/vehicle_renewal_reminder_action';
import { get, map } from 'lodash';

const initialState = {
    isLoading: false,
    error: null,
    vehicleRenewalReminderList: [],
    data: {},
    isCountLoading: false,
    summary: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_VEHICLE_RENEWAL_REMINDER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_VEHICLE_RENEWAL_REMINDER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_VEHICLE_RENEWAL_REMINDER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_VEHICLE_RENEWAL_REMINDERS_COUNT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isCountLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isCountLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    summary: get(payload, 'data', {}),
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_VEHICLE_RENEWAL_REMINDERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoadingData: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoadingData: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleRenewalReminderList: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });

        case FETCH_VEHICLE_RENEWAL_REMINDER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        case FETCH_VEHICLE_RENEWAL_REMINDERS_NOTIFICATIONS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoadingNotificationData: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoadingNotificationData: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    notificationsAsMap: new Map(
                        map(payload.data, (notification) => [
                            notification?.notificationEntityId,
                            notification?.createdDate,
                        ])
                    ),
                }),
            });

        default:
            return state;
    }
}
