import { useCallback, Component } from 'react';
import {
    GOOGLE_MAP_STYLES,
    GOOGLE_MAPS_API_KEY_PREMIUM,
    GOOGLE_MAPS_API_LANGUAGE,
    GOOGLE_MAPS_API_LIBRARIES,
    GOOGLE_MAPS_API_REGION,
    GOOGLE_MAPS_API_VERSION,
    INPUT_STYLE,
} from './map_utils';
import { Circle, GoogleMap, Marker, StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import { isEqual, round, toSafeInteger } from 'lodash';

import { AREA_TYPE } from './geocode_utils';
import { getCountryDefaultMapCenter } from '../internationalization_utils';

const SearchBoxGoogleMap = (props) => {
    const {
        googleMapsApiKey,
        googleMapsClientId,
        language,
        libraries,
        preventGoogleFontsLoading,
        region,
        version,
    } = props;

    const { isLoaded, loadError } = useLoadScript({
        id: 'useLoadScript-google-map',
        googleMapsApiKey,
        googleMapsClientId,
        language,
        libraries,
        preventGoogleFontsLoading,
        region,
        version,
    });

    const onLoad = useCallback((mapInstance) => {
        props.onMapMounted(mapInstance);
    });

    const renderMap = () => {
        const options = {
            streetViewControl: false,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM,
            },
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
            },
            styles: GOOGLE_MAP_STYLES,
        };

        return (
            <GoogleMap
                id="google-map-standalone-search"
                onLoad={onLoad}
                zoom={15}
                onClick={props.onMapClick}
                center={props.center}
                onBoundsChanged={props.onBoundsChanged}
                options={options}
                mapContainerStyle={props.mapContainerStyle}
                mapContainerClassName={props.mapContainerClassName}
            >
                <StandaloneSearchBox
                    onLoad={props.onSearchBoxMounted}
                    bounds={props.bounds}
                    onPlacesChanged={props.onPlacesChanged}
                >
                    <input type="text" placeholder="Search place" style={INPUT_STYLE} />
                </StandaloneSearchBox>
                {props.marker != null && <Marker position={props.marker.position} />}

                {props.areaType === AREA_TYPE.CIRCLE && (
                    <Circle
                        onLoad={props.onCircleMounted}
                        center={props.center}
                        radius={toSafeInteger(props.radius)}
                        onRadiusChanged={props.onRadiusChanged}
                        options={{
                            fillColor: `#ffffff`,
                            fillOpacity: 0.3,
                            strokeWeight: 1,
                            clickable: false,
                            editable: true,
                            draggable: true,
                            zIndex: 1,
                        }}
                    />
                )}
            </GoogleMap>
        );
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : props.loadingElement;
};

class GoogleMapSearchBoxComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bounds: null,
            center: props.center ? props.center : getCountryDefaultMapCenter(),
            marker: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps, this.props)) {
            this.setState({
                center: this.props.center ? this.props.center : this.state.center,
                marker: this.props.marker ? this.props.marker : this.state.marker,
            });
        }
    }

    handleMapMounted = (map) => {
        this._map = map;
    };

    handleBoundsChanged = () => {
        this.setState({
            bounds: this._map.getBounds(),
            center: this._map.getCenter(),
        });
    };

    handleSearchBoxMounted = (searchBox) => {
        this._searchBox = searchBox;
    };

    handlePlacesChanged = () => {
        const places = this._searchBox.getPlaces();
        const bounds = new google.maps.LatLngBounds();

        places.map((place) => {
            if (place.geometry) {
                place.geometry.viewport
                    ? bounds.union(place.geometry.viewport)
                    : bounds.extend(place.geometry.location);
            }
        });

        // Add a marker for each place returned from search bar
        const markers = places.map((place) => ({
            position: place.geometry.location,
        }));

        // Set markers; set map center to first search result
        const mapCenter = markers.length > 0 ? markers[0].position : this.state.center;

        this.setState({
            center: mapCenter,
            marker: markers[0],
        });

        this._map.fitBounds(bounds);
        this.props.onPlaceSelected(places);
    };

    handleMapClick = (event) => {
        const marker = {};
        marker.position = event.latLng;
        this.setState({
            marker: marker,
        });
        this.props.onMapClicked(event.latLng);
    };

    render() {
        return (
            <SearchBoxGoogleMap
                {...this.state}
                googleMapsApiKey={GOOGLE_MAPS_API_KEY_PREMIUM}
                libraries={GOOGLE_MAPS_API_LIBRARIES}
                language={GOOGLE_MAPS_API_LANGUAGE}
                region={GOOGLE_MAPS_API_REGION}
                version={GOOGLE_MAPS_API_VERSION}
                loadingElement={<div style={{ height: `100%` }}>loading...</div>}
                mapContainerStyle={{
                    height: `100%`,
                    minHeight: `40vh`,
                }}
                center={this.state.center}
                marker={this.state.marker}
                onMapMounted={this.handleMapMounted}
                onBoundsChanged={this.handleBoundsChanged}
                onSearchBoxMounted={this.handleSearchBoxMounted}
                bounds={this.state.bounds}
                onMapClick={this.handleMapClick}
                onPlacesChanged={this.handlePlacesChanged}
                radius={!!this.props.radius ? this.props.radius : 100}
                areaType={this.props.allowRadius && this.state.center ? AREA_TYPE.CIRCLE : ''}
                onCircleMounted={this.handleCircleMounted}
                onRadiusChanged={this.handleCircleRadiusChanged}
            />
        );
    }

    handleCircleMounted = (circle) => {
        this.setState({ circle: circle, areaType: AREA_TYPE.CIRCLE });
    };

    handleCircleRadiusChanged = () => {
        if (this.state.circle && this.props.onRadiusChanged) {
            this.props.onRadiusChanged(round(this.state.circle.getRadius()));
        }
    };
}

export default GoogleMapSearchBoxComponent;
