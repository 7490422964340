import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditProjectComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-finances-projects-add-edit" */ 'components/dashboard/finances/projects/add_edit_projects_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewCustomerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-finances-customers-view" */ 'components/dashboard/finances/customers/view_customer_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditCustomerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-finances-customers-add-edit" */ 'components/dashboard/finances/customers/add_edit_customer_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const CustomersComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-finances-customers" */ 'components/dashboard/finances/customers/customers_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
import clientRateChartsRoutes from './clientRateCharts';
import vendorRateChartsRoutes from './vendorRateCharts';
const ratechartsRoutes = [
    {
        path: 'project/add/:id',
        lazy: AddEditProjectComponent,
    },
    {
        path: 'project/edit/:id',
        lazy: AddEditProjectComponent,
    },
    {
        path: 'project/view/:id',
        lazy: ViewCustomerComponent,
    },
    {
        path: 'add',
        lazy: AddEditCustomerComponent,
    },
    {
        path: 'view/:id',
        lazy: ViewCustomerComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditCustomerComponent,
    },
    {
        index: true,
        lazy: CustomersComponent,
    },
    {
        path: 'client/*',
        children: clientRateChartsRoutes,
    },
    {
        path: 'vendor',
        children: vendorRateChartsRoutes,
    },
];
export default ratechartsRoutes;
