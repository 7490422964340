import { useTranslation } from 'react-i18next';
import { useApp } from '../../../theme';
import { useCallback } from 'react';
const useFxAlert = () => {
    const { t } = useTranslation();
    const { modal } = useApp();
    return useCallback((message, options) => {
        const { type = 'error' } = options || {};
        if (modal[type]) {
            modal[type]({
                title: t(type),
                content: message,
                ...options,
            });
        }
    }, []);
};
export default useFxAlert;
