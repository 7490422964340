import axios from 'axios';
import { ROOT_API_ERP } from '../../../constant';
export const CREATE_VENDOR_CHART = 'CREATE_VENDOR_CHART';
export const UPDATE_VENDOR_CHART = 'UPDATE_VENDOR_CHART';
export const FETCH_VENDOR_CHART_BY_ID = 'FETCH_VENDOR_CHART_BY_ID';
export const FETCH_VENDOR_CHARTS = 'FETCH_VENDOR_CHARTS';
export function createVendorChart(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}rate-chart/vendor`, data, config);
    return {
        type: CREATE_VENDOR_CHART,
        promise: request,
    };
}
export function updateVendorChart(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}rate-chart/vendor`, data, config);
    return {
        type: UPDATE_VENDOR_CHART,
        promise: request,
    };
}
export function fetchVendorCharts(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}rate-chart/vendor`, config);
    return {
        type: FETCH_VENDOR_CHARTS,
        promise: request,
    };
}
export function fetchVendorChartById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}rate-chart/vendor/${id}`, config);
    return {
        type: FETCH_VENDOR_CHART_BY_ID,
        promise: request,
    };
}
