import { handle } from 'redux-pack';
import { GET_STATIONARY, FETCH_STATIONARIES } from '../../../actions/dashboard/stationary/actions';

const initialState = {
    stationaryData: {},
    stationariesList: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_STATIONARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        stationaryData: payload.data,
                    };
                },
            });
        case FETCH_STATIONARIES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        stationariesList: payload.data,
                    };
                },
            });
        default:
            return state;
    }
}
