import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mappedBranches, mappedVehicleListMiniOptions } from '../../../utils/mapping_utils';
import { FxReactSelect as Select } from '../../../../shared';
import { debounce } from 'lodash';
import CalendarComponent from '../../../utils/calendar_component';
const space = 'mb-3';
const HireSlipMappingFilterComponent = (props) => {
    const { startDate, endDate, hireSlipOffice, hireSlipNumber, handleFilterChange, branches, vehicleId, vehicleList, } = props;
    const setTextFilter = debounce((key, term) => {
        handleFilterChange(key, term);
    }, 1000);
    const handleOnTextFilterChange = (event) => {
        setTextFilter(event.target.dataset.datafield, event.target.value);
    };
    return (_jsx("div", { children: _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "HS Office", value: hireSlipOffice, options: mappedBranches(branches), onChange: (value) => {
                            handleFilterChange('hireSlipOffice', value);
                        } }) }), _jsx("div", { className: "col-md-4", children: _jsx("div", { className: `${space}`, children: _jsx("input", { type: "text", name: "hireSlipNumber", placeholder: `HS Number`, className: "form-control rounded Select-control grey-placeholder", onChange: handleOnTextFilterChange, "data-datafield": 'hireSlipNumber' }) }) }), _jsx("div", { className: "col-md-4", children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                            handleFilterChange('timeFilter', [startDate, endDate]);
                        }, startDate: startDate, endDate: endDate }) }), _jsx("div", { className: "col-md-4", children: _jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "Vehicle", value: vehicleId, options: mappedVehicleListMiniOptions(vehicleList), onChange: (value) => {
                            handleFilterChange('vehicleId', value);
                        } }) })] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(HireSlipMappingFilterComponent);
