import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_CHALLAN = 'CREATE_CHALLAN';
export const FETCH_CHALLANS = 'FETCH_CHALLANS';
export const FETCH_CHALLAN = 'FETCH_CHALLAN';
export const FETCH_CHALLAN_MATERIAL = 'FETCH_CHALLAN_MATERIAL';
export const UPDATE_CHALLAN = 'UPDATE_CHALLAN';
export const DELETE_CHALLAN = 'DELETE_CHALLAN';
export const FETCH_CHALLAN_REPORT = 'FETCH_CHALLAN_REPORT';
export const FETCH_CHALLANS_PAGINATED = 'FETCH_CHALLANS_PAGINATED';
export function createChallan(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}challan`, data, config);
    return {
        type: CREATE_CHALLAN,
        promise: request,
    };
}
export function updateChallan(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}challan`, data, config);
    return {
        type: UPDATE_CHALLAN,
        promise: request,
    };
}
export function deleteChallan(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}challan/${id}`, config);
    return {
        type: DELETE_CHALLAN,
        promise: request,
    };
}
export function fetchChallans(accesstoken, params = {}) {
    const { startDate, endDate, branchId, type, routeId, vehicleId, hasArrived, deliveryType, toBranch, ids, mappedToJob, autoChallan, allUnitType, anyUnitType, mappedToHireSlip, consignmentNumber, consignmentConsignorId, consignmentConsigneeId, fromBranch, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            challanBranchId: branchId,
            toBranch,
            type,
            routeId,
            vehicleId,
            hasArrived,
            deliveryType,
            ids,
            mappedToJob,
            autoChallan,
            allUnitType,
            anyUnitType,
            mappedToHireSlip,
            consignmentNumber,
            consignmentConsignorId,
            consignmentConsigneeId,
            fromBranch,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}challan`, config);
    return {
        type: FETCH_CHALLANS,
        promise: request,
    };
}
export function fetchChallansPaginated(params = {}) {
    const { startDate, endDate, branchId, type, routeId, vehicleId, hasArrived, deliveryType, toBranch, ids, mappedToJob, autoChallan, allUnitType, anyUnitType, mappedToHireSlip, consignmentNumber, consignmentConsignorId, consignmentConsigneeId, fromBranch, page, size = 20, } = params;
    const config = {
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            challanBranchId: branchId,
            toBranch,
            type,
            routeId,
            vehicleId,
            hasArrived,
            deliveryType,
            ids,
            mappedToJob,
            autoChallan,
            allUnitType,
            anyUnitType,
            mappedToHireSlip,
            consignmentNumber,
            consignmentConsignorId,
            consignmentConsigneeId,
            fromBranch,
            page: page - 1,
            size,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}challan/search`, config);
    return {
        type: FETCH_CHALLANS_PAGINATED,
        promise: request,
    };
}
export function fetchChallan(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}challan/${id}`, config);
    return {
        type: FETCH_CHALLAN,
        promise: request,
    };
}
export function fetchChallanMaterial(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}challan/material/${id}`, config);
    return {
        type: FETCH_CHALLAN_MATERIAL,
        promise: request,
    };
}
export function fetchChallanReport(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params,
    };
    const request = axios.get(`${ROOT_API_ERP}report/challan`, config);
    return {
        type: FETCH_CHALLAN_REPORT,
        promise: request,
    };
}
