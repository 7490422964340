import axios from 'axios';
import { ROOT_API_ERP } from '../../../constant';
export const CREATE_CHARGE_HEAD = 'CREATE_CHARGE_HEAD';
export const UPDATE_CHARGE_HEAD = 'UPDATE_CHARGE_HEAD';
export const DELETE_CHARGE_HEAD = 'DELETE_CHARGE_HEAD';
export const FETCH_CHARGE_HEADS = 'FETCH_CHARGE_HEADS';
export const FETCH_CHARGE_HEAD = 'FETCH_CHARGE_HEAD';
export function createChargeHead(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}charge-head`, data, config);
    return {
        type: CREATE_CHARGE_HEAD,
        promise: request,
    };
}
export function updateChargeHead(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}charge-head`, data, config);
    return {
        type: UPDATE_CHARGE_HEAD,
        promise: request,
    };
}
export function fetchChargeHeads(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}charge-head`, config);
    return {
        type: FETCH_CHARGE_HEADS,
        promise: request,
    };
}
export function fetchChargeHead(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}charge-head/${id}`, config);
    return {
        type: FETCH_CHARGE_HEAD,
        promise: request,
    };
}
export function deleteChargeHead(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}charge-head/${id}`, config);
    return {
        type: DELETE_CHARGE_HEAD,
        promise: request,
    };
}
