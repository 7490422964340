import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Vehicle = (props) => {
    return (_jsx("svg", { width: "18", height: "18", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M5.60295 19.975C4.78432 19.975 4.0875 19.6882 3.5125 19.1146C2.9375 18.541 2.65 17.8444 2.65 17.025H1V5.5C1 5.1 1.15 4.75 1.45 4.45C1.75 4.15 2.1 4 2.5 4H16.975V8.175H19.6L23 12.7V17.025H21.225C21.225 17.8444 20.9385 18.541 20.3654 19.1146C19.7924 19.6882 19.0966 19.975 18.278 19.975C17.4593 19.975 16.7625 19.6882 16.1875 19.1146C15.6125 18.541 15.325 17.8444 15.325 17.025H8.55C8.55 17.8417 8.26348 18.5375 7.69045 19.1125C7.1174 19.6875 6.42157 19.975 5.60295 19.975ZM5.6 18.475C6 18.475 6.34167 18.3333 6.625 18.05C6.90833 17.7667 7.05 17.425 7.05 17.025C7.05 16.625 6.90833 16.2833 6.625 16C6.34167 15.7167 6 15.575 5.6 15.575C5.2 15.575 4.85833 15.7167 4.575 16C4.29167 16.2833 4.15 16.625 4.15 17.025C4.15 17.425 4.29167 17.7667 4.575 18.05C4.85833 18.3333 5.2 18.475 5.6 18.475ZM2.5 15.525H3.05C3.33333 15.075 3.69201 14.7167 4.12602 14.45C4.56004 14.1833 5.04338 14.05 5.57603 14.05C6.10868 14.05 6.59583 14.1875 7.0375 14.4625C7.47917 14.7375 7.84167 15.0917 8.125 15.525H15.475V5.5H2.5V15.525ZM18.275 18.475C18.675 18.475 19.0167 18.3333 19.3 18.05C19.5833 17.7667 19.725 17.425 19.725 17.025C19.725 16.625 19.5833 16.2833 19.3 16C19.0167 15.7167 18.675 15.575 18.275 15.575C17.875 15.575 17.5333 15.7167 17.25 16C16.9667 16.2833 16.825 16.625 16.825 17.025C16.825 17.425 16.9667 17.7667 17.25 18.05C17.5333 18.3333 17.875 18.475 18.275 18.475ZM16.975 13.375H21.625L18.85 9.675H16.975V13.375Z", fill: "#333333" }) }));
};
const VehicleIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Vehicle, ...props });
};
export default VehicleIcon;
