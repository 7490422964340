import { map } from 'lodash';
import { handle } from 'redux-pack';
import {
    CREATE_VENDOR,
    DELETE_VENDOR,
    FETCH_VENDOR,
    FETCH_VENDORS,
    UPDATE_VENDOR,
} from 'actions/dashboard/vendors_action';

const initialState = {
    isLoading: false,
    error: null,
    vendorList: [],
    data: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_VENDOR:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_VENDOR:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_VENDOR:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_VENDORS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vendorList: payload.data.content,
                    vendorListAsMap: new Map(map(payload.data.content, (vendor) => [vendor.id, vendor])),
                }),
            });

        case FETCH_VENDOR:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        default:
            return state;
    }
}
