/**
 * Created by abhay on 2017-07-12.
 */
import { handle } from 'redux-pack';
import {
    FETCH_ALL_TECHNICIANS,
    FETCH_ELIGIBLE_USERS,
    FETCH_USER,
    FETCH_USER_LIST_MINI,
    FETCH_USERS,
} from '../../actions/dashboard/users_action';
import { omit, orderBy, reduce } from 'lodash';
import { arrayToObject } from '../../utils/list_utils';

const initialState = {
    isLoading: false,
    error: null,
    data: {},
    userList: [],
    metaData: null,
    isFinished: false,
    eligibleUsers: [],
    userListMini: [],
    isUserListMiniLoading: false,
    userListMiniAsMap: {},
    allTechniciansAsMap: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_USER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });

        case FETCH_USERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isLoadingUsers: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload, isLoadingUsers: false }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        userList: orderBy(payload.data.content, ['name'], ['asc']),
                        userListAsMap: arrayToObject(payload.data.content, 'id'),
                        metaData: omit(payload.data, 'content'),
                        isLoadingUsers: false,
                    };
                },
            });
        case FETCH_ELIGIBLE_USERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    eligibleUsers: payload.data,
                }),
            });
        case FETCH_ALL_TECHNICIANS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    allTechnicians: payload.data,
                    allTechniciansAsMap: arrayToObject(payload.data, 'id'),
                }),
            });
        case FETCH_USER_LIST_MINI:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isUserListMiniLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isFinished: true,
                    isUserListMiniLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const data = payload.data;
                    const userListMiniAsMap = reduce(
                        data,
                        (acc, user) => {
                            acc[user[0]] = user;
                            return acc;
                        },
                        {}
                    );
                    return {
                        ...prevState,
                        userListMini: data,
                        userListMiniAsMap,
                    };
                },
            });
        default:
            return state;
    }
}
