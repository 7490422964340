import { jsx as _jsx } from "react/jsx-runtime";
import { Drawer, ConfigProvider } from 'antd';
const FxDrawer = (props) => {
    const { themeModify = {}, ...rest } = props;
    return (_jsx(ConfigProvider, { theme: {
            token: {
                ...themeModify,
            },
        }, children: _jsx(Drawer, { zIndex: 1020, ...rest }) }));
};
export default FxDrawer;
