import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const AddEditEPODComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-epod-add-edit" */ 'components/dashboard/epodForm/forms/AddEditEPODComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const EPODByJobIdComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-epod-by-job-id" */ 'components/dashboard/epodForm/EPODByJobIdComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const epodRoutes = [
    {
        path: 'epod-form/add/:jobId/:epodId?',
        lazy: AddEditEPODComponent,
    },
    {
        path: 'epod-form/add',
        lazy: AddEditEPODComponent,
    },
    {
        path: 'epod-form/edit-template/:epodId',
        lazy: AddEditEPODComponent,
    },
    {
        path: 'epod-form/edit/:jobId/:epodId?',
        lazy: AddEditEPODComponent,
    },
    {
        path: 'epod-form/edit/:id',
        lazy: AddEditEPODComponent,
    },
    {
        path: 'listing/:jobId',
        lazy: EPODByJobIdComponent,
    },
];
export default epodRoutes;
