import axios from "axios";
import {ROOT_API_URL} from "../../constant";
import {split} from "lodash";

export const CREATE_GEOFENCE = 'create_geofence';
export const FETCH_GEOFENCES = 'fetch_geofences';
export const FETCH_GEOFENCE = 'fetch_geofence';
export const UPDATE_GEOFENCE = 'update_geofence';
export const DELETE_GEOFENCE = 'delete_geofence';

export function createGeofence(accesstoken, values) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.post(`${ROOT_API_URL}geofence/`, values, config);
    return {
        type: CREATE_GEOFENCE, promise: request,
    }
}

export function updateGeofence(accesstoken, values, id) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.put(`${ROOT_API_URL}geofence/${id}`, values, config);

    return {
        type: UPDATE_GEOFENCE, promise: request
    }
}

export function fetchGeofences(accesstoken, vehicle, group) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    let query = vehicle ? `?vehicleId=${vehicle}` : "";

    if(!vehicle){
        query = group && group!=-1 ?`?groupId=${group}` : "";
    }
    const request = axios.get(`${ROOT_API_URL}geofence/${query}`, config);
    return {
        type: FETCH_GEOFENCES, promise: request,
    }

}

export function fetchGeofence(id, accesstoken) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.get(`${ROOT_API_URL}geofence/${id}`, config);

    return {
        type: FETCH_GEOFENCE, promise: request,
    }

}


export function deleteGeofence(accesstoken, id) {
    const config = {headers: {'Authorization': `Bearer ${accesstoken}`}};
    const request = axios.delete(`${ROOT_API_URL}geofence/${id}`, config);
    return {
        type: DELETE_GEOFENCE, promise: request
    }
}
