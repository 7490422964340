import { jsx as _jsx } from "react/jsx-runtime";
import { merge } from 'lodash';
import { isShellThemeOpenToAccount } from '../utils/account_utils';
import shellTheme from './shellAccount';
import defaultTheme from './default';
import { theme, App } from 'antd';
export { ConfigProvider, App } from 'antd';
export const useApp = App.useApp;
export const { useToken } = theme;
export function getCssBaseline(options) {
    const isShellTheme = isShellThemeOpenToAccount();
    let BaselineComponent = defaultTheme.CssBaseline;
    if (isShellTheme) {
        BaselineComponent = shellTheme.CssBaseline;
    }
    return BaselineComponent;
}
export function getTheme(options) {
    let themeToMerge = {};
    const isShellTheme = isShellThemeOpenToAccount();
    if (isShellTheme) {
        themeToMerge = shellTheme.theme;
    }
    return merge(defaultTheme.theme, themeToMerge);
}
export function withToken(Component) {
    return (props) => {
        const { token } = useToken();
        return _jsx(Component, { ...props, token: token });
    };
}
