import { get } from 'lodash';
import { Label } from 'reactstrap';

const _FxSwitchField = (passedProps) => {
    const {
        field,
        form,
        label,
        className,
        colClass,
        labelClass,
        isRequired,
        options,
        prependText,
        checked,
        showLabelFirst,
        switchInputWrapperClass = '',
        customChangeCallback,
        leftLabel,
        showLeftLabel,
        leftLabelClass,
        ...props
    } = passedProps;

    const error = get(form.errors, field.name, '');
    const touched = get(form.touched, field.name, '');

    const handleChange = (e) => {
        field.onChange(e);
        if (props.onChange) {
            props.onChange(e.currentTarget.checked);
        }
    };

    const updateBlur = () => {
        form.setFieldTouched(props.name, true);
    };

    return (
        <div className={className}>
            <div className={`${colClass}`}>
                {label && showLabelFirst && (
                    <label htmlFor={field.name} className={labelClass}>
                        {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    </label>
                )}
                {leftLabel && showLeftLabel && (
                    <label htmlFor={field.name} className={leftLabelClass}>
                        {leftLabel}
                    </label>
                )}
                <Label className={`switch switch-3d switch-primary ${label ? 'mx-3' : ''} ${switchInputWrapperClass}`}>
                    <input
                        type="checkbox"
                        className="switch-input"
                        {...field}
                        {...props}
                        onChange={handleChange}
                        checked={checked}
                    />
                    <span className="switch-label" />
                    <span className="switch-handle" />
                </Label>
                {label && !showLabelFirst && (
                    <label htmlFor={name} className={labelClass}>
                        {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    </label>
                )}
                {touched && error && <span className="help-block text-danger">{error}</span>}
            </div>
        </div>
    );
};

export default _FxSwitchField;
