import { get } from 'lodash';
import { useSelector } from 'react-redux';

const DashboardRestrictAllComponent = (props) => {
    const onlyRealtime = useSelector(
        (state) => !get(state, 'login.isSwitchedUser') && !!get(state, 'settings.loggedInUser.onlyRealtime')
    );
    const fullRestrict = useSelector(
        (state) => !get(state, 'login.isSwitchedUser') && !!get(state, 'settings.loggedInUser.fullRestrict')
    );

    if (!onlyRealtime && !fullRestrict) {
        window.location = '/';
        return null;
    }
    return (
        <div className=" text-center " style={{ marginTop: '7rem' }}>
            <div className="text-danger font-weight-bold large mt-4" style={{ fontSize: '20px' }}>
                Your fleetx account has been restricted. Please contact support@fleetx.io
            </div>
        </div>
    );
};

export default DashboardRestrictAllComponent;
