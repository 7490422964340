import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';

export const CREATE_CONTACT_RENEWAL_REMINDER = 'create_contact_renewal_reminder';
export const FETCH_CONTACT_RENEWAL_REMINDERS = 'fetch_contact_renewal_reminders';
export const FETCH_CONTACT_RENEWAL_REMINDER = 'fetch_contact_renewal_reminder';
export const UPDATE_CONTACT_RENEWAL_REMINDER = 'update_contact_renewal_reminder';
export const DELETE_CONTACT_RENEWAL_REMINDER = 'delete_contact_renewal_reminder';
export const FETCH_CONTACT_RENEWAL_REMINDERS_COUNT = 'fetch_contact_renewal_reminders_count';
export const FETCH_CONTACT_RENEWAL_REMINDERS_NOTIFICATIONS = 'fetch_contact_renewal_reminders_notifications';
export const SEND_CONTACT_RENEWAL_REMINDER_NOTIFICATION = 'send_contact_renewal_reminders_notification';
export const DELETE_DOCUMENT_FROM_CONTACT_RENEWAL_REMINDER = 'DELETE_DOCUMENT_FROM_CONTACT_RENEWAL_REMINDER';

export function createContactRenewalReminder(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}contact_renewal_reminders/`, values, config);
    return {
        type: CREATE_CONTACT_RENEWAL_REMINDER,
        promise: request,
    };
}

export function updateContactRenewalReminder(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}contact_renewal_reminders/update`, values, config);

    return {
        type: UPDATE_CONTACT_RENEWAL_REMINDER,
        promise: request,
    };
}

export function fetchContactRenewalRemindersCount(accesstoken, groupId, userId, contactRenewalTypeId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const userQuery = userId ? `&userId=${userId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const contactRenewalTypeQuery = contactRenewalTypeId ? `&contactRenewalType.id=${contactRenewalTypeId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}contact_renewal_reminders/count?${userQuery}${groupQuery}${contactRenewalTypeQuery}`,
        config
    );

    return {
        type: FETCH_CONTACT_RENEWAL_REMINDERS_COUNT,
        promise: request,
    };
}

export function fetchContactRenewalReminders(
    accesstoken,
    page = 1,
    size = 1000,
    groupId,
    userId,
    contactRenewalTypeId,
    overdue,
    dueSoon
) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const userQuery = userId ? `&userId=${userId}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const dueSoonQuery = dueSoon ? `&dueSoon=true` : '';
    const overdueQuery = overdue ? `&overdue=true` : '';
    const contactRenewalTypeQuery = contactRenewalTypeId ? `&contactRenewalType.id=${contactRenewalTypeId}` : '';

    const request = axios.get(
        `${ROOT_API_URL}contact_renewal_reminders/?page=${
            page - 1
        }&size=${size}${userQuery}${groupQuery}${contactRenewalTypeQuery}${dueSoonQuery}${overdueQuery}`,
        config
    );

    return {
        type: FETCH_CONTACT_RENEWAL_REMINDERS,
        promise: request,
    };
}

export function fetchContactRenewalReminder(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}contact_renewal_reminders/${id}`, config);

    return {
        type: FETCH_CONTACT_RENEWAL_REMINDER,
        promise: request,
    };
}

export function fetchContactRenewalRemindersNotifications(accesstoken, ids) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}contact_renewal_reminders/notifications?ids=${ids}`, config);
    return {
        type: FETCH_CONTACT_RENEWAL_REMINDERS_NOTIFICATIONS,
        promise: request,
    };
}

export function sendContactRenewalReminderNotification(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}contact_renewal_reminders/${id}/sendNotifications`, config);
    return {
        type: SEND_CONTACT_RENEWAL_REMINDER_NOTIFICATION,
        promise: request,
    };
}

export function deleteContactRenewalReminder(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}contact_renewal_reminders/${id}`, config);
    return {
        type: DELETE_CONTACT_RENEWAL_REMINDER,
        promise: request,
    };
}

export function deleteDocumentFromContactRenewalReminder(accesstoken, imageId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({ imageId }),
    };
    const request = axios.delete(`${ROOT_API_URL}contact_renewal_reminders/delete-document`, config);
    return {
        type: DELETE_DOCUMENT_FROM_CONTACT_RENEWAL_REMINDER,
        promise: request,
    };
}
