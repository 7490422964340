import { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { parseImageNameFromUrl } from '../constant';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { map } from 'lodash';

class DocumentListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDocumentModalOpen: true,
        };

        this.toggle = this.toggle.bind(this);
        this.pressedOk = this.pressedOk.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
    }

    toggle() {
        this.setState({
            isDocumentModalOpen: !this.state.isDocumentModalOpen,
        });
        this.props.onCloseRequest();
    }

    pressedOk() {
        this.toggle();
        this.props.onCloseRequest();
    }

    showImageDetail = (url) => {
        return (
            <div className="whitespace-normal">
                <div>{parseImageNameFromUrl(url)}</div>
            </div>
        );
    };

    downloadDocument(event) {
        const url = event.currentTarget.dataset.url;
        axios
            .get(`${url}`, {
                responseType: 'arraybuffer',
            })
            .then((response) => {
                fileDownload(response.data, parseImageNameFromUrl(url));
            });
    }

    showButtons(url) {
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <span
                    data-url={url}
                    className="text-dark p-2 d-inline-block hover"
                    title="Download Document"
                    onClick={this.downloadDocument}
                >
                    <i className="fa fa-download" />
                </span>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.isDocumentModalOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
                    <ModalBody className="p-0">
                        <div className="pre-scrollable">
                            <table className="table table-responsive borderless">
                                <tbody>{this.renderDocumentList()}</tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.pressedOk}>
                            Ok
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDocumentList() {
        return map(this.props.documents, (document) => {
            return (
                <tr key={document.id} style={{ borderBottom: '1px solid #e3e8ed' }}>
                    <td>{this.showImageDetail(document.url)}</td>
                    <td>{this.showButtons(document.url)}</td>
                </tr>
            );
        });
    }
}

export default DocumentListModal;
