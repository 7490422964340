import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Down = () => {
    return (_jsx("svg", { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6 9.3575L0.346252 3.70375L1.4 2.65L6 7.25L10.6 2.65L11.6538 3.70375L6 9.3575Z", fill: "#333333" }) }));
};
const DownIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Down, ...props });
};
export default DownIcon;
