import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mappedBranches, mappedVehicleListMiniOptions, mappedObjectToOptions } from '../../utils/mapping_utils';
import { FxReactSelect as Select } from '../../../shared';
import { mappedRoutes } from '../../../utils/form_utils';
import { ADDRESS_TYPES } from '../../../utils/consignmentsUtils';
import { Input, Label } from 'reactstrap';
const space = 'mb-3';
const ChallanFiltersComponent = (props) => {
    const { handleFilterChange, branches, branchId, type, routeId, vehicleId, routeList, vehicleList, loggedInUser, } = props;
    return (_jsx("div", { children: _jsxs("div", { children: [_jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "Arrival Office", value: branchId, options: mappedBranches(branches), onChange: (value) => {
                        handleFilterChange('branchId', value);
                    } }), _jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "Arrival Type", value: type, options: mappedObjectToOptions(ADDRESS_TYPES), onChange: (value) => {
                        handleFilterChange('type', value);
                    } }), _jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "Route", value: routeId, options: mappedRoutes(routeList), onChange: (value) => {
                        handleFilterChange('routeId', value);
                    } }), _jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "Vehicle No.", value: vehicleId, options: mappedVehicleListMiniOptions(vehicleList, loggedInUser, true), onChange: (value) => {
                        handleFilterChange('vehicleId', value);
                    } }), _jsxs("div", { className: `mt-2`, children: [_jsxs(Label, { className: "switch switch-3d switch-success mr-2", children: [_jsx(Input, { type: "checkbox", className: "switch-input", onChange: (e) => {
                                        handleFilterChange('includeDataLoadingRange', e.target.checked);
                                    } }), _jsx("span", { className: "switch-label" }), _jsx("span", { className: "switch-handle" })] }), _jsx("span", { className: "", children: "Include data in Loading range " })] }), _jsxs("div", { className: `mt-2`, children: [_jsxs(Label, { className: "switch switch-3d switch-success mr-2", children: [_jsx(Input, { type: "checkbox", className: "switch-input", onChange: (e) => {
                                        handleFilterChange('includeDataReachRange', e.target.checked);
                                    } }), _jsx("span", { className: "switch-label" }), _jsx("span", { className: "switch-handle" })] }), _jsx("span", { className: "", children: "Include data in Reach range " })] })] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ChallanFiltersComponent);
