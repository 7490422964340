/**
 * Created by udbhav on 14/07/17.
 */

import { handle } from 'redux-pack';
import {
    FETCH_ACCOUNT_VEHICLE_ANALYTICS,
    FETCH_ACCOUNT_VEHICLE_REPORT,
    FETCH_MULTI_VEHICLE_ANALYTICS,
    FETCH_MULTI_VEHICLE_ODO_ANALYTICS,
    FETCH_UPTIME_DETAILS,
    FETCH_VEHICLE_ANALYTICS,
    FETCH_VEHICLE_ID,
    FETCH_VEHICLE_LIST,
    FETCH_VEHICLE_LIST_MINI,
    FETCH_ALL_CACHED_VEHICLES,
    FETCH_ALL_CACHED_VEHICLES_SUMMARY,
    FETCH_SIM_TRACKING_HISTORY,
    FETCH_SIM_PING_ERRORS,
    FETCH_DEVICE_DETAILS_HISTORY,
    FETCH_VEHICLE_LIST_V2,
    GET_VEHICLE_ATTENDANCE,
    FETCH_VEHICLE_TYPES,
    FETCH_VEHICLE_MAKE,
    FETCH_VEHICLE_MODEL,
    FETCH_VEHICLE_STATUS,
} from '../../actions/dashboard/vehicles_action';
import { FETCH_VEHICLE_SIZES } from '../../actions/dashboard/vehicle_size_actions';
import { map, omit, reduce, get, groupBy, orderBy, toLower } from 'lodash';
import { appendDataInVehicleAnalytics } from '../../utils/vehicle_utils';
import { getMomentTime } from 'utils/date_utils';

const initialState = {
    isLoading: false,
    error: null,
    vehicleList: [],
    vehicleListV2: [],
    data: {},
    metaData: null,
    metaDataV2: null,
    isFinished: false,
    isVehicleListLoading: false,
    isVehicleAccountAnalyticsLoading: false,
    isVehicleAnalyticsLoading: false,
    isMultiVehicleAnalyticsLoading: false,
    isUptimeAnalyticsLoading: false,
    all_vehicle_cached_data: [],
    all_vehicle_cached_summary: {},
    isAllVehiclesCachedDataLoading: false,
    isAllVehiclesCachedSummaryLoading: false,
    vehicleListMiniAsMap: {},
    vehicleListMini: [],
    multiVehicleOdoAnalytics: {},
    isMultiVehicleOdoAnalyticsLoading: false,
    vehicleSimTrackingHistoryData: [],
    isVehicleSimTrackingHistoryDataLoading: false,
    vehicleSimPingErrorData: [],
    vehicleSimPingErrorDataStatus: '',
    vehicleDeviceDetailsHistoryData: [],
    isVehicleDeviceDetailsHistoryDataLoading: false,
    isVehicleListV2Loading: false,
    vehicleSizesList: [],
    isVehicleAttendanceLoading: false,
    vehicleAttendance: [],
    vehicleAttendanceAsMap: {},
};

export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ACCOUNT_VEHICLE_ANALYTICS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isVehicleAccountAnalyticsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isVehicleAccountAnalyticsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        // allVehicleAnalytics: map(payload.data, (data) =>
                        //     appendDataInVehicleAnalytics(data, root.addressBook.addressBook)
                        // ),
                    };
                },
            });
        case FETCH_VEHICLE_TYPES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isVehicleTypesLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                }),
                failure: (prevState) => ({ ...prevState, error: payload, isVehicleTypesLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleTypeList: payload.data,
                    isVehicleTypesLoading: false,
                }),
            });
        case FETCH_VEHICLE_MAKE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isVehicleMakerLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                }),
                failure: (prevState) => ({ ...prevState, error: payload, isVehicleMakeLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleMakerList: payload.data,
                    isVehicleMakerLoading: false,
                }),
            });
        case FETCH_VEHICLE_MODEL:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isVehicleModelLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                }),
                failure: (prevState) => ({ ...prevState, error: payload, isVehicleModelLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleModelList: payload.data,
                    isVehicleModelLoading: false,
                }),
            });
        case FETCH_VEHICLE_STATUS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isVehicleStatusLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                }),
                failure: (prevState) => ({ ...prevState, error: payload, isVehicleStatusLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleStatusList: orderBy(payload.data, function (item) {
                        switch (toLower(item.name)) {
                            case 'active':
                                return -2;
                            case 'inactive':
                                return -1;
                            default:
                                return item.id;
                        }
                    }),
                    isVehicleStatusLoading: false,
                }),
            });
        case FETCH_VEHICLE_ANALYTICS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isVehicleAnalyticsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isVehicleAnalyticsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        allVehicleAnalytics: map(payload.data, (data) =>
                            appendDataInVehicleAnalytics(data, root.addressBook.addressBook)
                        ),
                    };
                },
            });
        case FETCH_MULTI_VEHICLE_ANALYTICS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isMultiVehicleAnalyticsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isMultiVehicleAnalyticsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    multiVehicleAnalytics: payload.data,
                }),
            });
        case FETCH_MULTI_VEHICLE_ODO_ANALYTICS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isMultiVehicleOdoAnalyticsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isMultiVehicleOdoAnalyticsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    multiVehicleOdoAnalytics: payload.data,
                }),
            });
        case FETCH_ALL_CACHED_VEHICLES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAllVehiclesCachedDataLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isAllVehiclesCachedDataLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    all_vehicle_cached_data: payload.data,
                }),
            });
        case FETCH_ALL_CACHED_VEHICLES_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAllVehiclesCachedSummaryLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isAllVehiclesCachedSummaryLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    all_vehicle_cached_summary: payload.data,
                }),
            });
        case FETCH_VEHICLE_LIST:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isVehicleListLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isVehicleListLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleList: payload.data.content,
                    metaData: omit(payload.data, 'content'),
                }),
            });
        case FETCH_VEHICLE_LIST_V2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isVehicleListV2Loading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isVehicleListV2Loading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleListV2: payload.data.content,
                    metaDataV2: omit(payload.data, 'content'),
                }),
            });
        case FETCH_VEHICLE_LIST_MINI:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isVehicleListMiniLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isVehicleListMiniLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleListMini: payload.data,
                    vehicleListMiniAsMap: reduce(
                        payload.data,
                        (acc, v) => {
                            acc[v.id] = v;
                            return acc;
                        },
                        {}
                    ),
                }),
            });
        case FETCH_VEHICLE_SIZES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isVehicleSizesListLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isVehicleSizesListLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleSizesList: get(payload, 'data.content', []),
                }),
            });
        case FETCH_ACCOUNT_VEHICLE_REPORT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_VEHICLE_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        case FETCH_UPTIME_DETAILS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isUptimeAnalyticsLoading: true,
                    uptimeAnalytics: null,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isUptimeAnalyticsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    uptimeAnalytics: payload.data,
                }),
            });
        case FETCH_SIM_TRACKING_HISTORY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isVehicleSimTrackingHistoryDataLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isVehicleSimTrackingHistoryDataLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleSimTrackingHistoryData: payload.data,
                }),
            });
        case FETCH_SIM_PING_ERRORS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    vehicleSimPingErrorDataStatus: 'loading',
                }),
                failure: (prevState) => ({
                    ...prevState,
                    vehicleSimPingErrorDataStatus: 'error',
                }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleSimPingErrorData: payload.data,
                    vehicleSimPingErrorDataStatus: 'success',
                }),
            });
        case FETCH_DEVICE_DETAILS_HISTORY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isVehicleDeviceDetailsHistoryDataLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isVehicleDeviceDetailsHistoryDataLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleDeviceDetailsHistoryData: payload.data,
                }),
            });
        case GET_VEHICLE_ATTENDANCE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isVehicleAttendanceLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isVehicleAttendanceLoading: false,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    error: payload,
                    isVehicleAttendanceLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleAttendance: payload.data,
                    vehicleAttendanceAsMap: groupBy(payload.data, (item) => {
                        item.attendanceDate = getMomentTime(item.attendanceDate).format('YYYY-MM-DD');
                        return item.attendanceDate;
                    }),
                }),
            });
        default:
            return state;
    }
}
