import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const BillingInfoComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-billing-info-BillingInfoComponent" */ 'components/dashboard/BillingInfo/BillingInfoComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditTyresAndPartsBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-billing-info-AddEditTyresAndPartsBillComponent" */ 'components/dashboard/tyresAndPartsBilling/AddEditTyresAndPartsBill'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const billingInfoRoutes = [
    {
        path: 'add',
        lazy: AddEditTyresAndPartsBillComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditTyresAndPartsBillComponent,
    },
    {
        index: true,
        lazy: BillingInfoComponent,
    },
];
export default billingInfoRoutes;
