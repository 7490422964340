import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNumber } from 'lodash';
import CountUp from 'react-countup';
import { Skeleton, Typography, Statistic, ConfigProvider } from 'antd';
const formatter = (value) => _jsx(CountUp, { end: value, separator: "," });
const FxStatistic = (props) => {
    const { themeModify, ...rest } = props;
    const themeLocal = {
        token: {
            ...themeModify,
        },
    };
    return (_jsx(ConfigProvider, { theme: themeLocal, children: _jsx(Statistic, { formatter: isNumber(props.value) ? formatter : undefined, ...rest }) }));
};
export const FxStatisticText = (props) => {
    const { themeModify, ...rest } = props;
    return props.loading ? (_jsx(Skeleton.Button, { size: "small" })) : (_jsx(ConfigProvider, { theme: {
            token: {
                ...themeModify,
            },
        }, children: _jsxs(Typography.Text, { type: props.type, ...rest, children: [_jsx(CountUp, { end: props.value, separator: "," }), " ", props.suffix] }) }));
};
export default FxStatistic;
