import { difference, isEmpty, map } from 'lodash';

export function reconcileNewAndInitialConfig(newConfig, config) {
    if (!isEmpty(newConfig) && !isEmpty(config)) {
        const newKeys = Object.keys(newConfig);
        const oldKeys = Object.keys(config);
        const diffKeys = difference(oldKeys, newKeys);
        map(diffKeys, (k) => {
            newConfig[`${k}`] = config[`${k}`];
        });
    }
    return newConfig;
}
