import { useFormikContext, useField } from 'formik';
import passedProps from './asyncSelectFormik';
import { get } from 'lodash';
import FxSelectCreatable from './FxSelectCreatable';

const _FxSelectCreateableField = (passedProps) => {
    const {
        field,
        form,
        label,
        className,
        colClass,
        labelClass,
        isRequired,
        options,
        prependText,
        simpleValue,
        ...props
    } = passedProps;

    const error = get(form.errors, field.name, '');
    const touched = get(form.touched, field.name, '');

    const handleOnChange = (v) => {
        form.setFieldValue(field.name, v);
        if (props.onSelected) {
            props.onSelected(v);
        }
        if (props.onChange) {
            props.onChange(v);
        }
    };

    const handleInputChange = (inputValue, actionMeta) => {
        // form.setFieldValue(field.name, newValue);
        // form.setFieldTouched(props.name, true);
    };

    const updateBlur = () => {
        form.setFieldTouched(field.name, true);
    };

    return (
        <div className={className}>
            <div className={`${props.labelDiv}`}>
                {label && (
                    <div className={`pl-0 col-md-12 ${colClass}`}>
                        <label htmlFor={name} className={labelClass}>
                            {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                        </label>
                    </div>
                )}
            </div>
            <div className={`${props.selectDiv}`}>
                <FxSelectCreatable
                    {...props}
                    isClearable
                    onChange={handleOnChange}
                    onInputChange={handleInputChange}
                    onBlur={updateBlur}
                    options={options}
                    value={field.value}
                    simpleValue={simpleValue}
                />
                {touched && error ? <span className="custom-input-error text-danger">{error}</span> : null}
            </div>
        </div>
    );
};

export default _FxSelectCreateableField;
