import { FLEETX_ACCOUNTS, getFlagValueFromAccountConfig, isShreeCement8000, isShreeCementsAccounts, } from 'utils/account_utils';
import { get, includes, toSafeInteger } from 'lodash';
export function isTransporterAnalyticsEnabled() {
    return true;
}
export function isTransporterAnalyticsDrillDownEnabled() {
    return getFlagValueFromAccountConfig('isTransporterAnalyticsDrillDownFlagEnabled');
}
export function isTransporterFilterMandatoryOnMapView() {
    const accounts = [1505, 7573, 7575, 2034, 7571, 7572, 7573, 7575, 8199, 8493, 1505, 914, 3017, 13179];
    return !includes([...isShreeCementsAccounts, ...accounts], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}
export function isSalesVisualizationEnabled() {
    return getFlagValueFromAccountConfig('isSalesVisualizationEnabled') || isShreeCement8000();
}
export function showScoreBifurcation() {
    return includes([FLEETX_ACCOUNTS.HUL], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}
export function isUnloadingClusterInTransporterAnalyticsMapViewEnabled() {
    return getFlagValueFromAccountConfig('showUnloadingClusterInTransporterAnalyticsMapView');
}
export function isDeviationAnalyticsEnabledForAccount() {
    return getFlagValueFromAccountConfig('showDeviationAnalyticsForAccount');
}
export function getShowLocationOnTransporterAnalyticsMapViewWithinKMRadius() {
    return toSafeInteger(getFlagValueFromAccountConfig('showLocationOnTransporterAnalyticsMapViewWithinKMRadius'));
}
