import axios from 'axios';
import { ROOT_API_URL } from '../../constant';
import { isViewRolesAllowedForUser } from '../../components/dashboard/roles/permission_utils';
export const CREATE_CUSTOM_ROLE = 'create_custom_role';
export const FETCH_ROLES = 'fetch_roles';
export const FETCH_ROLE = 'fetch_role';
export const DELETE_ROLE = 'delete_role';
export const UPDATE_CUSTOM_ROLE = 'update_custom_role';
export function createCustomRole(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}roles`, values, config);
    return {
        type: CREATE_CUSTOM_ROLE,
        promise: request,
    };
}
export function updateCustomRole(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}roles/${id}`, values, config);
    return {
        type: UPDATE_CUSTOM_ROLE,
        promise: request,
    };
}
export function fetchRoles(accesstoken) {
    //@ts-ignore
    const { scope, role } = window.FLEETX_LOGGED_IN_DATA || {};
    //@ts-ignore
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    //@ts-ignore
    if (!isViewRolesAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ROLES,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
    };
    const request = axios.get(`${ROOT_API_URL}roles`, config);
    return {
        type: FETCH_ROLES,
        promise: request,
    };
}
export function fetchRole(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}roles/${id}`, config);
    return {
        type: FETCH_ROLE,
        promise: request,
    };
}
export function deleteRole(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}roles/${id}`, config);
    return {
        type: DELETE_ROLE,
        promise: request,
    };
}
