import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { DATE_FORMAT, getMomentTime } from 'utils/date_utils';
const JobListingComponent = (props) => {
    const { accesstoken, entityList, renderJobFreightPaymentField, renderRemarksField, selectRowProp, tableOptions, } = props;
    const { t } = useTranslation();
    const renderBillNumber = (cell, row) => {
        return get(row, 'basicDetails.billNumber', '');
    };
    const renderBillAmount = (cell, row) => {
        return get(row, 'voucher.pendingAmount', 0) + get(row, 'voucher.settledAmount', 0);
    };
    const renderBillingDate = (cell, row) => {
        return getMomentTime(get(row, 'basicDetails.billingDate')).format(DATE_FORMAT);
    };
    const renderBillSettledAmount = (cell, row) => {
        return get(row, 'voucher.settledAmount', 0);
    };
    return (_jsx("div", { className: "fleetx-table-parent-container", children: _jsxs(BootstrapTable, { tableContainerClass: "card fleetx-table-container white-table-background", tableHeaderClass: "fleetx-table grey-table-background font-size-mid text-center", tableBodyClass: "fleetx-table white-table-background", trClassName: "tr-user-height table-row-style", data: entityList, version: "4", hover: true, selectRow: selectRowProp, options: tableOptions, pagination: false, children: [_jsx(TableHeaderColumn, { width: "0", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, hidden: true, dataField: "id", isKey: true }), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderBillNumber, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Bill Number" }), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderBillAmount, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Bill Amount" }), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderBillingDate, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Billing Date" }), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderBillSettledAmount, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Bill Settled Amount" }), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderJobFreightPaymentField, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Payment Amount" }), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderRemarksField, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Remarks" })] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(JobListingComponent);
