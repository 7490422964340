import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_API_URL_V2 } from '../../constant';

export const FETCH_ALERTS_SETTINGS = 'fetch_alerts_settings';
export const FETCH_ALERTS_SETTINGS_BY_MASTER_ID = 'fetch_alerts_settings_by_master_id';
export const CREATE_ALERTS_SETTINGS = 'create_alerts_settings';
export const UPDATE_ALERTS_SETTINGS = 'update_alerts_settings';
export const DELETE_ALERTS_SETTINGS = 'delete_alerts_settings';
export const FETCH_ALERTS_SETTINGS_TYPE = 'fetch_alerts_settings_type';
export const FETCH_ALERT_SETTING = 'FETCH_ALERT_SETTING';
export const FETCH_ALERT_SETTING_V2 = 'FETCH_ALERT_SETTING_V2';
export const UPDATE_PART_CATEGORY_ALERTS_SETTINGS = 'update_part_category_alerts_settings';
export const UPDATE_ADDRESS_BOOK_ALERT_SETTINGS = 'update_address_book_alerts_settings';
export const FETCH_ADDRESS_BOOK_ALERT_SETTINGS = 'update_address_book_alerts_settings';

export function fetchAlertsSettings(accesstoken, groupId) {
    const groupQuery = groupId && groupId != -1 ? `?groupId=${groupId}` : '';
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL_V2}accounts/alerts/settings${groupQuery}`, config);

    return {
        type: FETCH_ALERTS_SETTINGS,
        promise: request,
    };
}
export function fetchAlertsSettingsV2(accesstoken, groupId) {
    const groupQuery = groupId && groupId != -1 ? `?groupId=${groupId}` : '';
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL_V2}alerts/settings${groupQuery}`, config);

    return {
        type: FETCH_ALERTS_SETTINGS,
        promise: request,
    };
}

export function fetchAlertSetting(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}accounts/alerts/settings/${id}`, config);

    return {
        type: FETCH_ALERT_SETTING,
        promise: request,
    };
}
export function fetchAlertSettingV2(accesstoken, id) {
    if (!id) {
        return {
            type: FETCH_ALERT_SETTING_V2,
            promise: Promise.resolve(),
        };
    }
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL_V2}alerts/settings/${id}`, config);

    return {
        type: FETCH_ALERT_SETTING_V2,
        promise: request,
    };
}

export function fetchAlertsSettingsType(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}accounts/alerts`, config);

    return {
        type: FETCH_ALERTS_SETTINGS_TYPE,
        promise: request,
    };
}

export function fetchSettingByMasterId(accesstoken, id, groupId) {
    const groupQuery = groupId && groupId != -1 ? `?groupId=${groupId}` : '';
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}accounts/alerts/${id}/settings${groupQuery}`, config);

    return {
        type: FETCH_ALERTS_SETTINGS_BY_MASTER_ID,
        promise: request,
    };
}

export function updateAlertsSettings(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}accounts/alerts/settings`, values, config);
    return {
        type: UPDATE_ALERTS_SETTINGS,
        promise: request,
    };
}
export function updateBulkCategorySettings(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}address_book/category/alerts/bulk/${id}`, values, config);
    return {
        type: UPDATE_ALERTS_SETTINGS,
        promise: request,
    };
}
export function updateBulkPartCategorySettings(accesstoken, values, alertSettingId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            alertSettingId,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}alerts/settings/part-category`.replace('v1', 'v2'), values, config);
    return {
        type: UPDATE_PART_CATEGORY_ALERTS_SETTINGS,
        promise: request,
    };
}
export function updateBulkAddressBookSettings(accesstoken, values, alertsSettingIds) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            alertsSettingIds,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}address_book/alerts/bulk`, values, config);
    return {
        type: UPDATE_ADDRESS_BOOK_ALERT_SETTINGS,
        promise: request,
    };
}

export function fetchBulkAddressBookSettings(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}address_book/alerts/all`, config);
    return {
        type: FETCH_ADDRESS_BOOK_ALERT_SETTINGS,
        promise: request,
    };
}

export function createAlertsSettings(accesstoken, values) {}

export function deleteAlertsSettings(accesstoken, id) {}
