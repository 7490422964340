import { isViewLedgersAllowedForUser } from 'components/dashboard/roles/permission_utils';
import InfoToolTip from 'components/utils/info_tool_tip';
import { mappedOptionsFromArray } from 'components/utils/mapping_utils';
import SelectInput from 'components/utils/SelectInput';
import { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { assign, get, set, startsWith } from 'lodash';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { isValidMobileNo, isValidGst } from 'constant';
import { createVendor } from 'actions/dashboard/vendors_action';
import { FxButton, FxFlex } from 'sharedV2/index';
import { getPhonePrefixFromCountry } from 'utils/internationalization_utils';
import { fetchLedgers } from 'actions/dashboard/consignments/ledgersActions';

class AddNewVendorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    addVendor = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isOpen: true,
        });
    };

    cancelModal = () => {
        this.setState({
            isOpen: false,
        });
    };

    renderField(field) {
        const {
            input,
            name,
            type,
            label,
            isRequired,
            helptext,
            placeholder,
            prependText,
            infoTooltipText,
            sup,
            meta: { touched, error },
        } = field;
        return (
            <div>
                <label htmlFor={name} className="form-control-label">
                    {label}
                    {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    {infoTooltipText && <InfoToolTip text={infoTooltipText} />}
                </label>
                <div className="input-group">
                    {prependText && <span className="input-group-addon">{prependText}</span>}
                    <input placeholder={placeholder} type={type} className="form-control rounded " {...input} />
                </div>
                {touched && error ? (
                    <div className="text-danger mt-1">{error}</div>
                ) : (
                    helptext && <div className="text-muted">{helptext}</div>
                )}
            </div>
        );
    }

    onSubmit = (values) => {
        let data = assign({}, values);
        const phoneNumberPrefix = getPhonePrefixFromCountry();
        if (values.phoneNumber) {
            set(data, 'phoneNumber', `${phoneNumberPrefix}-` + data.phoneNumber);
        }

        if (values.contactPersonPhone) {
            set(data, 'contactPersonPhone', `${phoneNumberPrefix}-` + data.contactPersonPhone);
        }

        if (this.props.vendorType) {
            set(data, 'vendorType', this.props.vendorType);
        }

        this.props.createVendor(this.props.accesstoken, data).then((result) => {
            if (!result.error) {
                if (this.props.vendorType) {
                    this.props.onSuccess(result.payload.data.id);
                    this.setState({
                        isOpen: false,
                    });
                } else {
                    this.props.onSuccess(result.payload.data.id);
                    this.setState({
                        isOpen: false,
                    });
                }
                this.cancelModal();
            } else {
                if (
                    result.payload.response &&
                    (result.payload.response.data.error_description || result.payload.response.data.message)
                ) {
                    let errorMessage = '';
                    if (result.payload.response.data.error_description) {
                        errorMessage = result.payload.response.data.error_description;
                    } else {
                        errorMessage = result.payload.response.data.message;
                    }
                    this.setState({
                        error: errorMessage,
                    });
                    throw new SubmissionError({
                        _error: errorMessage,
                    });
                } else {
                    this.setState({
                        error: 'Something went wrong, please try again later!',
                    });
                    throw new SubmissionError({
                        _error: 'Something went wrong, please try again later!',
                    });
                }
            }
        });
    };

    componentDidMount() {
        const { scope, accesstoken, fetchLedgers } = this.props;
        if (isViewLedgersAllowedForUser(scope)) {
            fetchLedgers(accesstoken);
        }
    }

    render() {
        const { isOpen } = this.state;
        const { handleSubmit, error, ledgers, scope } = this.props;

        return (
            <>
                <FxFlex justify="flex-end">
                    <FxButton type="link" className="p-0" onClick={this.addVendor}>
                        Add New Vendor
                    </FxButton>
                </FxFlex>
                <Modal isOpen={isOpen} toggle={this.cancelModal} style={{ minWidth: '55vw' }}>
                    <ModalHeader toggle={this.cancelModal}>Add Vendor</ModalHeader>
                    <ModalBody>
                        <form className="px-2" onSubmit={handleSubmit(this.onSubmit)}>
                            {this.state.error && (
                                <div className="my-2 text-danger text-center">
                                    <strong>{this.state.error}</strong>
                                </div>
                            )}
                            <div className="form-group row">
                                <div className="col">
                                    <Field
                                        name="name"
                                        type="text"
                                        isRequired="true"
                                        component={this.renderField}
                                        label="Name"
                                    />
                                </div>
                                <div className="col">
                                    <Field
                                        name="gstNumber"
                                        type="text"
                                        component={this.renderField}
                                        label="GST Number"
                                    />
                                </div>
                                {isViewLedgersAllowedForUser(scope) && (
                                    <div className="col">
                                        <Field
                                            label="Ledger"
                                            name="ledgerId"
                                            options={mappedOptionsFromArray(ledgers, 'ledgerName')}
                                            component={SelectInput}
                                            isRequired={isViewLedgersAllowedForUser(scope)}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="text-muted h6 mt-2">Contact Person</div>
                            <hr />
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Field
                                        name="contactPersonName"
                                        type="text"
                                        component={this.renderField}
                                        label="Contact Name"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        prependText={getPhonePrefixFromCountry()}
                                        name="contactPersonPhone"
                                        type="number"
                                        component={this.renderField}
                                        label="Phone"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end my-2">
                                <Button color="primary" type="submit">
                                    Create and Save
                                </Button>
                            </div>
                            {error && (
                                <div className="my-2 text-danger text-center">
                                    <strong>{error}</strong>
                                </div>
                            )}
                        </form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

function validate(values) {
    const errors = {};

    if (!values.name) {
        errors.name = 'Required';
    }

    if (values.gstNumber && !isValidGst(values.gstNumber)) {
        errors.gstNumber = 'Invalid GST number';
    }

    if (values.contactPersonPhone && !isValidMobileNo(values.contactPersonPhone)) {
        errors.contactPersonPhone = 'Invalid contact person phone number';
    }

    if (!values.ledgerId && isViewLedgersAllowedForUser(values.scope)) {
        errors.ledgerId = 'Required';
    }

    return errors;
}

function mapStateToProps(state) {
    return {
        accesstoken: state.login.data.access_token,
        scope: state.login.data.scope,
        ledgers: state.consignments.ledgers,
    };
}

AddNewVendorComponent = reduxForm({
    validate,
    enableReinitialize: true,
    form: 'AddNewVendorComponent',
})(AddNewVendorComponent);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ createVendor, fetchLedgers }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewVendorComponent);
