import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AuctionsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-auctions" */ 'components/dashboard/vendor_management/auctions/auctions_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditAuctionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-addEditAuction" */ 'components/dashboard/vendor_management/auctions/add_edit_auction_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const auctionRoutes = [
    {
        path: 'add',
        lazy: AddEditAuctionComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditAuctionComponent,
    },
    {
        index: true,
        lazy: AuctionsComponent,
    },
];
export default auctionRoutes;
