import { SET_CURRENT_CURSOR, SET_SINGLE_UPLOAD_LOADING } from 'actions/dashboard/utils_action';

export default function (state = {}, action) {
    const { type, data } = action;
    switch (type) {
        case SET_SINGLE_UPLOAD_LOADING:
            return {
                ...state,
                isLoading: data,
            };
        case SET_CURRENT_CURSOR:
            return {
                ...state,
                cursorData: data,
            };
        default:
            return state;
    }
}
