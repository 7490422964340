import { useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';
import { find, get, map } from 'lodash';

const validateOption = (obj) => {
    return obj && obj.value;
};

const getSimpleValue = (option, props) => {
    if (option) {
        return props.multi ? map(option, (item) => item.value) : option && option.value;
    } else {
        return props.multi ? [] : null;
    }
};

const getSelectedOptionsFromSimpleValue = (selectedValue, props) => {
    if (selectedValue) {
        return props.multi
            ? map(selectedValue, (value) => {
                  return find(props.options, { value: value });
              })
            : find(props.options, { value: selectedValue });
    } else {
        return props.multi ? [] : null;
    }
};

const FxSelectCreatable = (passedProps) => {
    const {
        simpleValue,
        clearable,
        style,
        optionRenderer,
        disabled,
        valueRenderer,
        value,
        onChange,
        ...props
    } = passedProps;
    const handleOnChange = (option) => {
        if (simpleValue) {
            onChange(getSimpleValue(option, passedProps));
        } else {
            onChange(option ? option : props.multi ? [] : null);
        }
    };

    const getSelectedOption = (value) => {
        if (
            simpleValue ||
            typeof value !== 'object' ||
            (Array.isArray(value) && value.length > 0 && typeof value[0] !== 'object')
        ) {
            return getSelectedOptionsFromSimpleValue(value, passedProps);
        } else {
            return value;
        }
    };

    const customStyles = useMemo(() => {
        return {
            menu: (provided, state) => ({
                ...provided,
                zIndex: 5,
            }),
            container: (provided, state) => ({
                ...provided,
                ...style,
            }),
        };
    }, [style]);

    //issue in react-select doesn't handle this select
    const stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div onClick={stopPropagation}>
            <CreatableSelect
                {...props}
                styles={customStyles}
                isDisabled={disabled}
                getOptionValue={valueRenderer}
                formatOptionLabel={optionRenderer}
                isMulti={props.multi}
                isClearable={clearable !== false}
                value={getSelectedOption(value)}
                onChange={handleOnChange}
                //newOptionCreator={newOptionCreate}
                isValidateNewOption={validateOption}
            />
        </div>
    );
};

export default FxSelectCreatable;
