import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const ProcurementComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-procurement-procurement" */ 'components/dashboard/procurement/procurement_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ProcurementVendorComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-procurement-vendor" */ 'components/dashboard/procurement/procurement_vendor_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
// Routes
import indentRoutes from './indent';
import quotationRoutes from './quotation';
import purchaseRoutes from './purchase';
import ratechartsRoutes from './ratechart';
const procurementRoutes = [
    {
        path: 'indent/*',
        children: indentRoutes,
    },
    {
        path: 'quotation/*',
        children: quotationRoutes,
    },
    {
        path: 'purchase-order/*',
        children: purchaseRoutes,
    },
    {
        path: 'rate-chart/*',
        children: ratechartsRoutes,
    },
    {
        path: 'vendor',
        lazy: ProcurementVendorComponent,
    },
    {
        index: true,
        lazy: ProcurementComponent,
    },
];
export default procurementRoutes;
