import axios from 'axios';
import storage from 'utils/storage_utils';

import {
    ROOT_API_URL,
    startUsingApiProxyUrl,
    USE_API_PROXY_KEY,
    ESCALATION_MATRIX_CONFIG,
    ROOT_NODE_API_URL,
    parseQueryParams,
} from '../../constant';
import { getPhonePrefixFromCountry } from 'utils/internationalization_utils';
import { assign, omit, set, startsWith, isEmpty } from 'lodash';
export const UPDATE_ACCOUNT = 'update_account';
export const FETCH_ACCOUNT = 'fetch_account';
export const FETCH_ESCALATION_MATRIX = 'fetch_escalation_matrix';
export const FETCH_LOGGED_IN_USER = 'fetch_logged_in_user';
export const UPDATE_PASSWORD = 'update_password';
export const UPDATE_PROFILE_PASSWORD = 'update_profile_password';
export const CREATE_PROFILE_PASSWORD = 'create_profile_password';
export const FETCH_BLOGS_POSTS = 'fetch_blogs_posts';

export function updateAccount(values, accesstoken) {
    let data = assign({}, values);
    const phonePrefix = getPhonePrefixFromCountry();
    if (
        values.accountSubscription &&
        values.accountSubscription.phoneNumber &&
        !startsWith(values.accountSubscription.phoneNumber, `${phonePrefix}-`)
    ) {
        set(data, 'accountSubscription.phoneNumber', `${phonePrefix}-` + values.accountSubscription.phoneNumber);
    }

    if (values.accountSubscription && values.accountSubscription.plan) {
        data = omit(data, 'accountSubscription.plan');
    }
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}accounts/`, data, config);

    return {
        type: UPDATE_ACCOUNT,
        promise: request,
    };
}

export function updatePassword(values, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}users/change/password`, values, config);

    return {
        type: UPDATE_PASSWORD,
        promise: request,
    };
}

export function createProfilePassword(values, accesstoken) {
    const { newPassword, otp, key } = values;
    const data = {
        otp,
        newPassword: newPassword,
        token: key,
    };
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };

    const request = axios.put(`${ROOT_API_URL}accounts/password`, data, config);

    return {
        type: CREATE_PROFILE_PASSWORD,
        promise: request,
    };
}

export function updateProfilePassword(values, accesstoken) {
    const { currentProfilePassword, newProfilePassword } = values;
    const data = {
        currentPassword: currentProfilePassword,
        newPassword: newProfilePassword,
    };
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };

    const request = axios.post(`${ROOT_API_URL}accounts/change/password`, data, config);

    return {
        type: UPDATE_PROFILE_PASSWORD,
        promise: request,
    };
}

export function fetchAccount(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}accounts/`, config);

    return {
        type: FETCH_ACCOUNT,
        promise: request,
    };
}

export function fetchEscalationMatrix(accesstoken, force = false) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };

    let toFetch = force;

    // if force fetch is true then fetch the escalation matrix api
    if (toFetch) {
        return {
            type: FETCH_ESCALATION_MATRIX,
            promise: axios.get(`${ROOT_API_URL}accounts/overview/escalation-matrix`, config),
        };
    }
    // else if check if escalation matrix exists in localStorage or not
    // if yes then return the data from localStorage
    else if (storage.getItem(ESCALATION_MATRIX_CONFIG)) {
        const escalation_matrix = storage.getItem(ESCALATION_MATRIX_CONFIG);
        let data = {};
        if (!isEmpty(escalation_matrix)) {
            data = JSON.parse(escalation_matrix);
        }

        return {
            type: FETCH_ESCALATION_MATRIX,
            promise: Promise.resolve({ data }),
        };
    }
    // else fetch the escalation matrix api and store it into localStorage
    else {
        return {
            type: FETCH_ESCALATION_MATRIX,
            promise: axios.get(`${ROOT_API_URL}accounts/overview/escalation-matrix`, config),
        };
    }
}

export function fetchLoggedInUser(accesstoken) {
    if (storage.getItem(USE_API_PROXY_KEY)) {
        startUsingApiProxyUrl();
    }

    const config = { headers: { 'Authorization': `Bearer ${accesstoken}`, 'clientId': 'fleetxweb' } };
    const request = axios.get(`${ROOT_API_URL}users/me`, config);

    return {
        type: FETCH_LOGGED_IN_USER,
        promise: request,
    };
}

export function fetchBlogsPosts(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    //content/posts
    const request = axios.get(`${ROOT_NODE_API_URL}napp/ghost/content/posts`, config);

    return {
        type: FETCH_BLOGS_POSTS,
        promise: request,
    };
}
