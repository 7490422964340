import { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Progress, Col, Button } from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    fetchImagekitSHAV2,
    fetchImagekitSHAV2Public,
    getServerTime,
    setUploadLoading,
} from '../../actions/dashboard/utils_action';
import { getMomentTime, MINUTES_1 } from '../../utils/date_utils';
import { v4 as uuidv4 } from 'uuid';
import LightBoxUtils from '../../utils/light_box_utils';
import { round, get } from 'lodash';
import { IMAGEKIT_PUBLIC_API_KEY, getImageKitLink, image } from '../../constant';
import { Row } from 'reactstrap';
import { FxCircularProgressBar } from '../../shared';
import { getValidUploadFileTypes, validateFileBeforeUpload } from 'utils/fileUploadutils';
import { getPdfFileExtension } from 'utils/accountUtils/common';
import { FxFilePdfOutlined } from 'sharedV2/FxIcons';

class SingleFileDropzoneComponent extends Component {
    source = null;

    constructor(props) {
        super(props);
        this.state = {
            acceptedFile: null,
            progress: 0,
            isOpen: false,
            uploadedUrl: props.uploadedUrl,
            latitude: null,
            longitude: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.uploadedUrl !== this.props.uploadedUrl) {
            let uploadedUrl =
                this.props.uploadedUrl && this.props.uploadedUrl.match(/^http/)
                    ? this.props.uploadedUrl.split('fx')[1]
                    : this.props.uploadedUrl;
            this.setState({ uploadedUrl });
        }
    }

    onCloseRequest = () => {
        this.setState({
            isOpen: false,
        });
    };
    handleOnClick = () => {
        this.setState({
            isOpen: true,
        });
    };

    renderImageThumbnail(imageUrl, style) {
        const isPdf = getPdfFileExtension(imageUrl);
        return (
            <div className="d-inline-block mr-2 cursor-pointer">
                <div className="d-inline-block m-0 thumbnail">
                    {isPdf ? (
                        <div style={{ textAlign: 'left' }}>
                            <FxFilePdfOutlined style={{ fontSize: '2rem', color: '#ff4d4f' }} />
                        </div>
                    ) : (
                        <img
                            style={style}
                            src={imageUrl}
                            className="img-thumbnail"
                            alt="attachment"
                            onClick={this.handleOnClick}
                        />
                    )}
                </div>
            </div>
        );
    }

    onDrop = async (acceptedFiles, rejectedFiles) => {
        this.props.onDropCB && this.props.onDropCB();
        const acceptedFile = acceptedFiles[0];

        if (!acceptedFile) {
            return;
        }
        if (this.source) {
            this.source.cancel('');
        }

        const fileValidationObj = validateFileBeforeUpload(acceptedFile);
        if (get(fileValidationObj, 'error')) {
            alert(get(fileValidationObj, 'errorMsg'));
            return;
        }

        var CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        const config = {
            onUploadProgress: (progressEvent) =>
                this.setState({ progress: Math.round((progressEvent.loaded * 100) / progressEvent.total) }),
            cancelToken: this.source.token,
        };

        this.setState({
            acceptedFile: acceptedFile,
        });

        let timeStamp = Math.floor(getMomentTime().valueOf());

        try {
            const result = await this.props.getServerTime(this.props.accesstoken);
            timeStamp = get(result, 'payload.data.timestamp', timeStamp);
        } catch (e) {}

        const token = uuidv4();
        const expire = round((timeStamp + MINUTES_1 * 30) / 1000, 0);
        if (!this.props.publicEpod) {
            this.props.fetchImagekitSHAV2(this.props.accesstoken, acceptedFile.name, token, expire).then((result) => {
                if (!result.error) {
                    const reader = new FileReader();
                    const signature = get(result, 'payload.data.signature');
                    const expireAPI = get(result, 'payload.data.expire');

                    reader.onload = (event) => {
                        const data = new FormData();
                        data.append('file', event.target.result.substring(event.target.result.indexOf(',') + 1));
                        data.append('fileName', acceptedFile.name);
                        data.append('publicKey', IMAGEKIT_PUBLIC_API_KEY);
                        data.append('signature', signature);
                        data.append('token', token);
                        data.append('expire', expireAPI);
                        data.append('useUniqueFilename', true);
                        data.append(
                            'folder',
                            `clients/${
                                get(this.props, 'loggedInUser.accountId', '')
                                    ? get(this.props, 'loggedInUser.accountId', '')
                                    : this.props.localTransporterAccount || this.props.accountId
                            }/${this.props.folderPath}`
                        );
                        this.props.setUploadLoading(true);
                        axios
                            .post('https://upload.imagekit.io/api/v1/files/upload', data, config)
                            .then(
                                function (res) {
                                    this.setState({ uploadedUrl: res.data.filePath });
                                    this.props.fileSuccessCallback(res.data.filePath);
                                    this.props.setUploadLoading(false);
                                }.bind(this)
                            )
                            .catch(
                                function (err) {
                                    this.props.setUploadLoading(false);
                                    this.props.fileErrorCallback(get(err, 'response.data.message', ''));
                                }.bind(this)
                            );
                    };
                    reader.onabort = () => console.log('file reading was aborted');
                    reader.onerror = () => console.log('file reading has failed');

                    reader.readAsDataURL(acceptedFile);
                }
            });
        }
        if (this.props.publicEpod) {
            this.props.fetchImagekitSHAV2Public(acceptedFile.name, this.props.authToken, expire).then((result) => {
                if (!result.error) {
                    const reader = new FileReader();
                    const signature = get(result, 'payload.data.signature');
                    const publicToken = get(result, 'payload.data.token');
                    const expireAPI = get(result, 'payload.data.expire');

                    reader.onload = (event) => {
                        const data = new FormData();
                        data.append('file', event.target.result.substring(event.target.result.indexOf(',') + 1));
                        data.append('fileName', acceptedFile.name);
                        data.append('publicKey', IMAGEKIT_PUBLIC_API_KEY);
                        data.append('signature', signature);
                        data.append('token', publicToken);
                        data.append('expire', expireAPI);
                        data.append('useUniqueFilename', true);
                        data.append('folder', `clients${this.props.folderPath}`);
                        axios
                            .post('https://upload.imagekit.io/api/v1/files/upload', data, config)
                            .then(
                                function (res) {
                                    this.setState({ uploadedUrl: res.data.filePath });
                                    this.props.fileSuccessCallback(res.data.filePath);
                                }.bind(this)
                            )
                            .catch(
                                function (err) {
                                    this.fileErrorCallback(get(err, 'response.data.message', ''));
                                }.bind(this)
                            );
                    };
                    reader.onabort = () => console.log('file reading was aborted');
                    reader.onerror = () => console.log('file reading has failed');

                    reader.readAsDataURL(acceptedFile);
                }
            });
        }
    };

    deleteImage() {
        if (this.source) {
            this.source.cancel('');
        }

        this.setState({
            acceptedFile: null,
            uploadedUrl: null,
        });

        this.props.fileDeleteCallback();
    }

    componentWillUnmount() {
        this.source = null;
    }

    render() {
        const { name, accept, message, lgThumbnailView, onlyView, showOnlyButton, attachClickableText } = this.props;
        const { acceptedFile, uploadedUrl, progress } = this.state;
        const uploadedImageLink = getImageKitLink(uploadedUrl);

        if (showOnlyButton) {
            return (
                <Dropzone name={name} onDrop={this.onDrop} accept={getValidUploadFileTypes(accept)} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps({ style: this.props.style })}>
                                <input {...getInputProps()} />
                                {!attachClickableText && (
                                    <Button>
                                        <i className="fa fa-paperclip mr-1" />
                                        {progress < 100 && progress > 0 ? 'Attaching...' : 'Attach'}
                                    </Button>
                                )}
                                {!!attachClickableText && (
                                    <span className="text-primary cursor-pointer">
                                        {attachClickableText}
                                        <i className="fa fa-paperclip ml-2" />
                                    </span>
                                )}
                            </div>
                        </section>
                    )}
                </Dropzone>
            );
        }

        return lgThumbnailView ? (
            <div>
                {this.props.showImageThumbnail && this.state.isOpen && (
                    <LightBoxUtils images={[uploadedImageLink]} onCloseRequest={this.onCloseRequest} />
                )}

                {!uploadedUrl && !acceptedFile && (
                    <Dropzone
                        name={name}
                        onDrop={this.onDrop}
                        accept={getValidUploadFileTypes(accept)}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps({ className: 'dropzone', style: this.props.style })}>
                                    <input {...getInputProps()} />
                                    <p>{message}</p>
                                    <p>Or</p>
                                    <p
                                        className="p-2"
                                        style={{ 'borderRadius': '2.5rem', 'backgroundColor': '#959595' }}
                                    >
                                        Select Photos
                                    </p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )}
                <Row>
                    {!uploadedUrl && acceptedFile && (
                        <Row className="ml-2" style={{ ...this.props.thumbStyle }}>
                            <Col md={12} className="p-0 m-0 img-thumbnail">
                                <div
                                    className="text-center"
                                    style={{
                                        width: '10rem',
                                        height: '10rem',
                                        minWidth: '10rem',
                                        minHeight: '10rem',
                                        maxWidth: '10rem',
                                        maxHeight: '10rem',
                                        lineHeight: '10rem',
                                    }}
                                >
                                    {progress !== 0 && progress < 100 && (
                                        <FxCircularProgressBar
                                            sqSize={90}
                                            percentage={50}
                                            strokeWidth={8}
                                        ></FxCircularProgressBar>
                                    )}
                                    {progress === 0 && (
                                        <img
                                            style={{ width: '90px', height: '90px', zIndex: 2 }}
                                            src={image('/public/img/icons/image-upload-placeholder.svg')}
                                        ></img>
                                    )}
                                </div>
                                <div
                                    className="pos-abs"
                                    style={{ right: '-1rem', top: '-0.5rem' }}
                                    data-id={uploadedUrl}
                                    onClick={this.deleteImage.bind(this)}
                                ></div>
                            </Col>
                            <a target="_blank" href={uploadedImageLink}>
                                <Col md={12}>
                                    <div
                                        className="ml-1"
                                        style={{
                                            fontSize: '0.7rem',
                                            fontWeight: 'bold',
                                            color: '#303030',
                                        }}
                                    >
                                        {acceptedFile.name}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div
                                        className="ml-1"
                                        style={{
                                            fontSize: '0.6rem',
                                            fontWeight: '500',
                                            color: '#a8a8a8',
                                        }}
                                    >
                                        Uploading
                                    </div>
                                </Col>
                            </a>
                        </Row>
                    )}
                    {uploadedUrl && acceptedFile && (
                        <Row className="ml-2" style={{ ...this.props.thumbStyle }}>
                            {this.props.showImageThumbnail && (
                                <Col md={12} className="p-0 m-0">
                                    {this.renderImageThumbnail(uploadedImageLink, {
                                        width: '10rem',
                                        height: '10rem',
                                        minWidth: '10rem',
                                        minHeight: '10rem',
                                        maxWidth: '10rem',
                                        maxHeight: '10rem',
                                    })}
                                    {!onlyView && (
                                        <div
                                            className="pos-abs"
                                            style={{ right: '-1rem', top: '-0.5rem' }}
                                            data-id={uploadedUrl}
                                            onClick={this.deleteImage.bind(this)}
                                        >
                                            <i className="hover fa fa-times-circle" />
                                        </div>
                                    )}
                                </Col>
                            )}
                            <a target="_blank" href={uploadedImageLink}>
                                <Col md={12}>
                                    <div
                                        className="ml-1"
                                        style={{
                                            fontSize: '0.7rem',
                                            fontWeight: 'bold',
                                            color: '#303030',
                                        }}
                                    >
                                        {acceptedFile.name}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div
                                        className="ml-1"
                                        style={{
                                            fontSize: '0.6rem',
                                            fontWeight: '500',
                                            color: '#a8a8a8',
                                        }}
                                    >
                                        Uploaded
                                    </div>
                                </Col>
                            </a>
                        </Row>
                    )}
                    {acceptedFile && (
                        <Dropzone
                            name={name}
                            onDrop={this.onDrop}
                            accept={getValidUploadFileTypes(accept)}
                            multiple={false}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="ml-5 img-thumbnail">
                                    <div {...getRootProps({ className: 'dropzone', style: this.props.addMoreStyle })}>
                                        <input {...getInputProps()} disabled={onlyView} />
                                        <p className="text-center align-self-center mt-auto">
                                            <i className="fa fa-2x fa-plus" />
                                            <br />
                                            <span>{'Add More'}</span>
                                            <br />
                                            <span>{'Documents'}</span>
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    )}
                </Row>
            </div>
        ) : (
            <div>
                {this.props.showImageThumbnail && this.state.isOpen && (
                    <LightBoxUtils images={[uploadedImageLink]} onCloseRequest={this.onCloseRequest} />
                )}
                <Dropzone name={name} onDrop={this.onDrop} accept={getValidUploadFileTypes(accept)} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps({ className: 'dropzone', style: this.props.style })}>
                                <input {...getInputProps()} disabled={onlyView} />
                                <p>{message}</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
                {acceptedFile && (
                    <div className="mt-2 border row mx-0 align-items-center" style={{ background: '#EBEBEB' }}>
                        <div className="col-11 p-2">
                            <div className="pull-left">
                                {this.props.showImageThumbnail &&
                                    uploadedUrl &&
                                    this.renderImageThumbnail(uploadedImageLink, { height: '2rem', width: '2rem' })}
                                <a target="_blank" href={uploadedImageLink}>
                                    {acceptedFile.name}
                                </a>
                            </div>
                            {progress < 100 && (
                                <div className="pull-right mx-4" style={{ width: '240px', marginTop: '4px' }}>
                                    <Progress value={progress} />
                                </div>
                            )}
                            <div className="clearfix" />
                        </div>

                        <div
                            className="col-1 p-2"
                            style={{ textAlign: 'end' }}
                            data-id={uploadedUrl}
                            onClick={this.deleteImage.bind(this)}
                        >
                            <i className="fa fa-remove hover" />
                        </div>
                    </div>
                )}
                {uploadedUrl && !acceptedFile && (
                    <div className="mt-2 border row mx-0 align-items-center" style={{ background: '#EBEBEB' }}>
                        <div className="col-11 p-2" style={{ display: 'flex', alignItems: 'center' }}>
                            {this.props.showImageThumbnail &&
                                this.renderImageThumbnail(uploadedImageLink, { height: '2rem', width: '2rem' })}
                            <a
                                target="_blank"
                                href={uploadedImageLink}
                                style={{
                                    maxWidth: this.props?.style?.width || '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {uploadedUrl}
                            </a>
                        </div>

                        {!onlyView && (
                            <div
                                className="col-1 p-2"
                                style={{ textAlign: 'end' }}
                                data-id={uploadedUrl}
                                onClick={this.deleteImage.bind(this)}
                            >
                                <i className="hover fa fa-remove" />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

SingleFileDropzoneComponent.propTypes = {
    showImageThumbnail: PropTypes.bool,
    onlyView: PropTypes.bool,
    accept: PropTypes.string,
    message: PropTypes.string,
    name: PropTypes.string,
    folderPath: PropTypes.string,
    accountId: PropTypes.number,
    uploadedUrl: PropTypes.string,
    fileErrorCallback: PropTypes.func,
    fileSuccessCallback: PropTypes.func,
    fileDeleteCallback: PropTypes.func,
    style: PropTypes.object,
    showOnlyButton: PropTypes.bool,
    attachClickableText: PropTypes.string,
    id: PropTypes.string,
    wImageThumbnail: PropTypes.bool,
    onDropCB: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        loggedInUser: get(state, 'settings.loggedInUser'),
        localTransporterAccount: get(state, 'local.localTransporterAccount', null),
    };
}

export default connect(mapStateToProps, {
    fetchImagekitSHAV2,
    fetchImagekitSHAV2Public,
    getServerTime,
    setUploadLoading,
})(SingleFileDropzoneComponent);
