import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    createServiceTask,
    fetchServiceTask,
    updateServiceTask,
    fetchServiceTasks,
} from '../../../actions/dashboard/easy/service_tasks_action';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { get } from 'lodash';
import { FxSelectFieldRedux } from '../../../shared';
import { mappedObjectToOptions } from 'components/utils/mapping_utils';
import { VEHICLE_DOMAINS } from 'utils/workorderUtils';
import { SERVICE_TASK_TYPES_LABELS, SERVICE_TASK_TYPES } from 'utils/serviceTaskUtils';
import { withRoute } from '../customHocs/withRoute';

class AddEditServiceTaskComponent extends Component {
    goBack = () => {
        if (this.props.isServiceLine) {
            this.props.cancelModal();
        } else {
            this.props.navigate(-1);
        }
    };

    mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            editable: false,
            id: 0,
        };
    }

    componentDidMount() {
        this.mounted = true;
        if (!this.props.isServiceLine) {
            const { id } = this.props.params;
            if (id) {
                this.setState({
                    editable: true,
                    id: id,
                });

                return this.props.fetchServiceTask(id, this.props.accesstoken).then((result) => {
                    if (!this.mounted) {
                        return;
                    }
                    if (result.error) {
                        this.props.navigate('/dashboard');
                    }
                });
            }
        }
    }

    renderField(field) {
        const {
            input,
            type,
            name,
            label,
            isRequired,
            meta: { touched, error },
        } = field;

        const className = `form-group ${isRequired && touched && error ? 'has-danger' : ''}`;
        const labelClass = `${isRequired && touched && error ? 'text-danger' : ''}`;
        return (
            <div className={className}>
                <div className="">
                    <label htmlFor={name} className={labelClass}>
                        {label}
                        {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    </label>
                </div>
                <div className="">
                    <input type="text" className="form-control " {...input} />
                    <div className="text-help text-danger">{touched ? error : ''}</div>
                </div>
            </div>
        );
    }

    renderTextArea(field) {
        const { input } = field;

        return (
            <div className="form-group mt-3">
                <div className="">
                    <label htmlFor="description">Description</label>
                </div>
                <div className="">
                    <textarea
                        style={{ width: '100%' }}
                        className="form-control"
                        name="description"
                        {...input}
                        rows="4"
                    ></textarea>
                </div>
            </div>
        );
    }

    onSubmit(values) {
        if (this.state.editable) {
            return this.props.updateServiceTask(this.props.accesstoken, values, this.state.id).then((result) => {
                const response = get(result, 'payload.response');
                const error_description = get(response, 'data.error_description');
                const message = get(response, 'data.message');
                if (!this.mounted) {
                    return;
                }
                if (this.props.isServiceLine) {
                    this.props.fetchServiceTasks(this.props.accesstoken);
                }
                if (!result.error && !this.props.isServiceLine) {
                    this.props.navigate('/dashboard/settings/servicetasks/');
                } else {
                    if (response && (error_description || message)) {
                        let errorMessage = '';
                        if (error_description) {
                            errorMessage = error_description;
                        } else {
                            errorMessage = message;
                        }
                        throw new SubmissionError({
                            _error: errorMessage,
                        });
                    } else {
                        throw new SubmissionError({
                            _error: 'Something went wrong, please try again later!',
                        });
                    }
                }
            });
        } else {
            return this.props.createServiceTask(this.props.accesstoken, values).then((result) => {
                const response = get(result, 'payload.response');
                const error_description = get(response, 'data.error_description');
                const message = get(response, 'data.message');
                if (!this.mounted) {
                    return;
                }

                if (this.props.isServiceLine) {
                    this.props.fetchServiceTasks(this.props.accesstoken);
                    this.props.cancelModal();
                } else if (!result.error && !this.props.isServiceLine) {
                    this.props.navigate('/dashboard/settings/servicetasks/');
                } else {
                    if (response && (error_description || message)) {
                        let errorMessage = '';
                        if (error_description) {
                            errorMessage = error_description;
                        } else {
                            errorMessage = message;
                        }
                        throw new SubmissionError({
                            _error: errorMessage,
                        });
                    } else {
                        throw new SubmissionError({
                            _error: 'Something went wrong, please try again later!',
                        });
                    }
                }
            });
        }
    }

    render() {
        const { handleSubmit, submitting, error } = this.props;
        return (
            <div className="card-group mt-4 mb-0">
                <div className="card p-1">
                    <div className="card-block">
                        <div className="text-left">
                            {!this.props.isServiceLine && (
                                <>
                                    <h4>{this.state.isEdit ? 'Edit Service Task' : 'New Service Task'}</h4>
                                    <hr />
                                </>
                            )}
                            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                <Field
                                    name="name"
                                    type="text"
                                    component={this.renderField}
                                    label="Name"
                                    isRequired="true"
                                />
                                <Field
                                    name="type"
                                    component={FxSelectFieldRedux}
                                    label="Type"
                                    options={mappedObjectToOptions(SERVICE_TASK_TYPES, SERVICE_TASK_TYPES_LABELS)}
                                    outerDivStyle={'mt-3'}
                                    isRequired={true}
                                />
                                <Field
                                    name="vehicleDomain"
                                    component={FxSelectFieldRedux}
                                    label="Vehicle Domain"
                                    options={VEHICLE_DOMAINS}
                                    outerDivStyle={'mt-3'}
                                    isRequired={true}
                                />
                                <Field name="description" component={this.renderTextArea} />
                                {error && (
                                    <div className="my-2 text-danger text-center">
                                        <strong>{error}</strong>
                                    </div>
                                )}
                                <button
                                    type="submit"
                                    className="btn btn-primary mr-1"
                                    data-csbox="true"
                                    data-gacategory="Settings"
                                    data-galabel="Service task Submit"
                                    data-gaaction="Click"
                                >
                                    Submit
                                </button>
                                <button className="btn btn-secondary" onClick={this.goBack.bind(this)}>
                                    Cancel
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.mounted = false;
    }
}

function validate(values) {
    const errors = {};

    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.vehicleDomain) {
        errors.vehicleDomain = 'Required';
    }
    if (!values.type) {
        errors.type = 'Required';
    }
    return errors;
}

function mapStateToProps(state, ownProps) {
    const { data } = state.servicetasks;
    if (ownProps && data && data[ownProps?.params?.id]) {
        const servicetask = data[ownProps?.params?.id];
        return { initialValues: servicetask, accesstoken: state.login.data.access_token };
    } else {
        return {
            accesstoken: state.login.data.access_token,
        };
    }
}

AddEditServiceTaskComponent = reduxForm({
    validate,
    enableReinitialize: true,
    form: 'AddEditServiceTaskForm',
})(AddEditServiceTaskComponent);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ createServiceTask, fetchServiceTask, updateServiceTask, fetchServiceTasks }, dispatch);
}

AddEditServiceTaskComponent = connect(mapStateToProps, mapDispatchToProps)(AddEditServiceTaskComponent);
export default withRoute(AddEditServiceTaskComponent);
