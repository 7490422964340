import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { map, get, isEmpty } from 'lodash';
const space = 'mb-3';
const ArrivalSummary = styled.div `
    border: 1px solid #959595;
    width: 380px;
    padding: 15px;
`;
const ArrivalSummaryComponent = (props) => {
    const { materials } = props;
    if (isEmpty(materials)) {
        return null;
    }
    return (_jsxs("div", { className: "px-4 my-2", children: [_jsx("h6", { children: "Arrival Summary" }), _jsx(ArrivalSummary, { children: _jsxs("table", { children: [_jsxs("tr", { className: "pb-3", children: [_jsx("th", { className: "p-2", children: "Consignment Number" }), _jsx("th", { className: "p-2", children: "Material Name" }), _jsx("th", { className: "p-2", children: "Material Received" })] }), map(materials, (material, key) => {
                            return (_jsxs("tr", { className: "pb-2", children: [_jsx("td", { className: "p-2", children: get(material, 'consignmentNumber', '-') }), _jsx("td", { className: "p-2", children: get(material, 'materialName', '-') }), _jsx("td", { className: "p-2", children: get(material, 'materialReceived', '-') })] }, key));
                        })] }) })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ArrivalSummaryComponent);
