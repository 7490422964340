import {handle} from "redux-pack";
import {ACCOUNT_ANALYTICS, ACCOUNT_OVERVIEW} from "../../actions/dashboard/dashboard_overview_action";


const initialState = {
    isLoading: false, error: null, data: {}, analytics: []
};


export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case ACCOUNT_OVERVIEW :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, data: payload.data
                }),
            });

        case ACCOUNT_ANALYTICS :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, analytics: payload.data
                }),
            });


        default:
            return state;
    }
}