import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider, Steps } from 'antd';
const FxSteps = (props) => {
    const { themeModify, ...rest } = props;
    const themeLocal = {
        token: {
            ...themeModify,
        },
    };
    return (_jsx(ConfigProvider, { theme: themeLocal, children: _jsx(Steps, { ...rest }) }));
};
export default FxSteps;
