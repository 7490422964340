import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import FxTextFieldStackedLabel from 'shared/FxTextFieldStackedLabel';
const BalancesComponent = (props) => {
    const { accesstoken } = props;
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "col-md-3", children: _jsx(Field, { name: "remainingPayment", label: "Remaining Payment Amount", component: FxTextFieldStackedLabel, disabled: true }) }), _jsx("div", { className: "col-md-3", children: _jsx(Field, { name: "remainingOnAccountPayment", label: "Remaining OnAccount Payment", component: FxTextFieldStackedLabel, disabled: true }) })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BalancesComponent);
