import { map, set } from 'lodash';
import { getFlagValueFromAccountConfig } from 'utils/account_utils';
const priority = [
    {
        name: 'LOW',
        order: 1,
    },
    {
        name: 'MEDIUM',
        order: 2,
    },
    {
        name: 'HIGH',
        order: 3,
    },
    {
        name: 'URGENT',
        order: 4,
    },
    {
        name: 'CRITICAL',
        order: 5,
    },
];
export function isTransporterColInAlertEnabled() {
    return getFlagValueFromAccountConfig('showTransporterDetailsColInAlertList');
}
export function isJobColInAlertEnabled() {
    return getFlagValueFromAccountConfig('showJobDetailsColInAlertList');
}
export function sortAggregation(aggregations, aSettings) {
    let asMp = {};
    let Mappings = {
        'Hard Acceleration': 'Harsh Acceleration',
        'Hard Deceleration': 'Harsh Deceleration',
        'Ignition Off': 'IGNITION_OFF',
        'Ignition On': 'IGNITION_ON',
        'Crash': 'Impact Alarm',
        'Power On': 'Power On',
        'Quick Lane Change': 'Quick Turning',
        // 'Emergency': 'SOS',
        'Temperature': 'Temperature Above Threshold',
        'MinTemp': 'Temperature Below Threshold',
        'Sharp Turn': 'Zig Zag Stoppage',
        'Job Deviation': 'Route Deviation',
    };
    map(aSettings, (ob) => {
        const { alertSetting } = ob;
        const { priority, alerts } = alertSetting;
        if (alerts.name in Mappings) {
            set(asMp, Mappings[alerts.name], priority);
        }
        else {
            set(asMp, alerts.name, priority);
        }
    });
    let updatedAggregations = map(aggregations, (o) => {
        return {
            type: o.type,
            count: o.count,
            percentageChange: o.percentageChange,
            priority: asMp[o.type] || 'LOW',
        };
    });
    const priorityMap = priority.reduce((map, obj) => {
        map[obj.name] = obj.order;
        return map;
    }, {});
    const sortedAggregations = updatedAggregations.sort((a, b) => {
        if (a.type == 'total' || b.type == 'total')
            return Number.MAX_SAFE_INTEGER;
        const priorityA = priorityMap[a.priority] || Number.MAX_SAFE_INTEGER;
        const priorityB = priorityMap[b.priority] || Number.MAX_SAFE_INTEGER;
        return priorityB - priorityA;
    });
    return sortedAggregations;
}
