import DOMPurify from 'dompurify';
import { isString, min } from 'lodash';

export function getSanitizedHtml(html) {
    return DOMPurify.sanitize(html);
}

export function createSafeMarkup(html) {
    return {
        __html: getSanitizedHtml(html),
    };
}

export function scrollIntoView(element, selector = '', options = { behavior: 'smooth' }) {
    const el = element || !selector ? element : document.querySelector(selector);
    if (el && el.scrollIntoView) {
        el.scrollIntoView(options);
    }
}

export function convertHtmlStringToText(htmlString) {
    if (!htmlString) {
        return '-';
    }
    let str = `${htmlString}`;
    //-- remove BR tags and replace them with line break
    str = str.replace(/<br>/gi, '\n');
    str = str.replace(/<br\/>/gi, '\n');
    str = str.replace(/<br\s\/>/gi, '\n');

    //-- remove P and A tags but preserve what's inside of them
    str = str.replace(/<p[^>]*>/gi, '\n');

    // retain hyperlink and text
    str = str.replace(/<a.*href="(.*?)"[^>]*>(.*?)<\/a>/gi, ' $2($1) ');

    //-- remove all else
    str = str.replace(/<\/?[^>]+(>|$)/g, '');

    //-- get rid of html-encoded characters:
    str = str.replace(/&nbsp;/gi, ' ');
    str = str.replace(/&amp;/gi, '&');
    str = str.replace(/&quot;/gi, '"');
    str = str.replace(/&lt;/gi, '<');
    str = str.replace(/&gt;/gi, '>');

    return str;
}

export function scrollContentWithinDiv(selector = '', scrollToValue = 0, element = null) {
    const el = element || !selector ? element : document.querySelector(selector);
    if (el) {
        const scrollHeight = el.scrollHeight;
        if (scrollToValue <= scrollHeight) {
            el.scrollTop = scrollToValue;
        } else {
            return 'END_OF_SCROLL';
        }
        return scrollToValue;
    } else {
        return 0;
    }
}

export const renderJSON = (jsonData) => {
    let jsonString = null;
    if (jsonData) {
        if (isString(jsonData)) {
            jsonString = jsonData;
        } else {
            jsonString = JSON.stringify(jsonData, null, 4);
        }
    }
    return <pre>{jsonString}</pre>;
};
