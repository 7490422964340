import styled from 'styled-components';
import { getThresholdForGPSDeviceMandatory } from './account_utils';
import { toSafeInteger, get, map } from 'lodash';
export const TRACKING_DEVICE_TYPES = {
    GPS_BOX: 'GPS_BOX',
    DRIVER_APP: 'DRIVER_APP',
    SIM_BASED: 'SIM_BASED',
};
export const TRACKING_DEVICE_TYPE_LABELS = {
    GPS_BOX: 'GPS BOX',
    DRIVER_APP: 'DRIVER APP',
    SIM_BASED: 'SIM BASED',
};
export const GPS_BOX_STATUS = {
    NOT_INSTALLED: 'NOT_INSTALLED',
    PARKED: 'PARKED',
    RUNNING: 'RUNNING',
    UNREACHABLE: 'UNREACHABLE',
    DISCONNECTED: 'DISCONNECTED',
    REMOVED: 'REMOVED',
};
export const GPS_BOX_STATUS_LABEL = {
    NOT_INSTALLED: 'Not Installed',
    PARKED: 'Parked',
    RUNNING: 'Running',
    UNREACHABLE: 'Unreachable',
    DISCONNECTED: 'Disconnected',
    REMOVED: 'Removed',
};
export const DRIVER_APP_STATUS = {
    NOT_AVAILABLE: `NOT_AVAILABLE`,
    PARKED: 'PARKED',
    RUNNING: 'RUNNING',
    UNREACHABLE: 'UNREACHABLE',
    DISCONNECTED: 'DISCONNECTED',
    REMOVED: `REMOVED`,
};
export const DRIVER_APP_STATUS_LABEL = {
    NOT_AVAILABLE: `Not Available`,
    PARKED: 'Parked',
    RUNNING: 'Running',
    UNREACHABLE: 'Unreachable',
    DISCONNECTED: 'Disconnected',
    REMOVED: 'Removed',
};
export const SIM_BASED_STATUS = {
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    PARKED: 'PARKED',
    UNREACHABLE: 'UNREACHABLE',
    REMOVED: 'REMOVED',
    NOT_INSTALLED: 'NOT_INSTALLED',
};
export const SIM_BASED_STATUS_LABEL = {
    NOT_AVAILABLE: 'Not Available',
    PARKED: 'Parked',
    UNREACHABLE: 'Unreachable',
    REMOVED: 'Removed',
    NOT_INSTALLED: 'Not Installed',
};
export const SIM_CONSENT_STATUS = {
    PENDING: 'PENDING',
    NOTALLOWED: 'NOT_ALLOWED',
    ALLOWED: 'ALLOWED',
};
export const SIM_CONSENT_STATUS_LABEL = {
    PENDING: 'Pending',
    NOTALLOWED: 'Not Allowed',
    ALLOWED: 'Allowed',
};
export const redColor = '#f86c6b';
export const greenColor = '#4dbd74';
export const orangeColor = '#ffa500';
export function getColorForStatus(status) {
    switch (status) {
        case GPS_BOX_STATUS.DISCONNECTED:
        case GPS_BOX_STATUS.REMOVED:
        case GPS_BOX_STATUS.UNREACHABLE:
        case SIM_BASED_STATUS.REMOVED:
        case SIM_BASED_STATUS.UNREACHABLE:
        case DRIVER_APP_STATUS.DISCONNECTED:
        case DRIVER_APP_STATUS.REMOVED:
        case DRIVER_APP_STATUS.UNREACHABLE:
        case SIM_CONSENT_STATUS.PENDING:
            return redColor;
        case GPS_BOX_STATUS.NOT_INSTALLED:
        case SIM_BASED_STATUS.NOT_AVAILABLE:
        case DRIVER_APP_STATUS.NOT_AVAILABLE:
        case SIM_CONSENT_STATUS.NOTALLOWED:
            return orangeColor;
        default:
            return greenColor;
    }
}
export const GPS_BOX_STATUS_TO_DESC_MAPPING = {
    NOT_INSTALLED: 'Device Not Installed',
    PARKED: 'Data Healthy',
    RUNNING: 'Data Healthy',
    UNREACHABLE: 'Device Installed But Not Reachable',
    DISCONNECTED: 'Device Has Been Removed',
    REMOVED: 'Device Has Been Removed',
};
export const DRIVER_APP_STATUS_TO_DESC_MAPPING = {
    NOT_AVAILABLE: `App Not Installed On Driver's Phone`,
    PARKED: 'Data Received',
    RUNNING: 'Data Received',
    UNREACHABLE: 'App Installed But Not Reachable',
    DISCONNECTED: 'App Has Been Disconnected',
    REMOVED: `App Not Installed On Driver's Phone`,
};
export const SIM_BASED_STATUS_TO_DESC_MAPPING = {
    NOT_AVAILABLE: 'Sim Device Not Available',
    PARKED: 'Data Received',
    UNREACHABLE: 'Consent Approved But Device Not Reachable',
    REMOVED: 'Consent Expired',
    NOT_INSTALLED: 'Sim Device Not Available',
};
export const SIM_CONSENT_STATUS_TO_DESC_MAPPING = {
    PENDING: 'Consent Not Approved',
    NOTALLOWED: 'Consent Rejected',
    ALLOWED: 'Allowed',
};
export function getDesc(mapping, status) {
    return get(mapping, status, '');
}
export function getLabel(labels, status) {
    return get(labels, status, status);
}
export function getGPSBoxStatusData({ status }) {
    const data = {
        text: getLabel(GPS_BOX_STATUS_LABEL, status),
        reason: getDesc(GPS_BOX_STATUS_TO_DESC_MAPPING, status),
        color: getColorForStatus(status),
    };
    switch (status) {
        case GPS_BOX_STATUS.NOT_INSTALLED:
        case GPS_BOX_STATUS.DISCONNECTED:
        case GPS_BOX_STATUS.REMOVED:
        case GPS_BOX_STATUS.UNREACHABLE:
            break;
        default:
            data['color'] = greenColor;
            data['text'] = 'Working';
            break;
    }
    return data;
}
export function getDriverAppStatusData({ status }) {
    const data = {
        text: getLabel(DRIVER_APP_STATUS_LABEL, status),
        reason: getDesc(DRIVER_APP_STATUS_TO_DESC_MAPPING, status),
        color: getColorForStatus(status),
    };
    switch (status) {
        case DRIVER_APP_STATUS.NOT_AVAILABLE:
        case DRIVER_APP_STATUS.DISCONNECTED:
        case DRIVER_APP_STATUS.REMOVED:
        case DRIVER_APP_STATUS.UNREACHABLE:
            break;
        default:
            data['color'] = greenColor;
            data['text'] = 'Working';
            break;
    }
    return data;
}
export function getSimBasedStatusData({ status }, simConsentStatus) {
    if (!simConsentStatus || simConsentStatus === SIM_CONSENT_STATUS.ALLOWED) {
        const data = {
            text: getLabel(SIM_BASED_STATUS_LABEL, status),
            reason: getDesc(SIM_BASED_STATUS_TO_DESC_MAPPING, status),
            color: getColorForStatus(status),
            simConsentStatusText: getLabel(SIM_CONSENT_STATUS_LABEL, simConsentStatus),
            simConsentStatusColor: getColorForStatus(simConsentStatus),
        };
        switch (status) {
            case SIM_BASED_STATUS.NOT_AVAILABLE:
            case SIM_BASED_STATUS.REMOVED:
            case SIM_BASED_STATUS.UNREACHABLE:
            case SIM_BASED_STATUS.NOT_INSTALLED:
                break;
            default:
                data['color'] = greenColor;
                data['text'] = 'Working';
                break;
        }
        return data;
    }
    else {
        return {
            text: getLabel(SIM_CONSENT_STATUS_LABEL, simConsentStatus),
            reason: getDesc(SIM_CONSENT_STATUS_TO_DESC_MAPPING, simConsentStatus),
            color: getColorForStatus(status),
            simConsentStatusText: getLabel(SIM_CONSENT_STATUS_LABEL, simConsentStatus),
            simConsentStatusColor: getColorForStatus(simConsentStatus),
        };
    }
}
export function mappedVehicleListByNumber(vehicleList) {
    return map(vehicleList, (v) => {
        return {
            value: v.licensePlate,
            label: v.licensePlate,
        };
    });
}
export function isGPSMandatory(noOfJobs) {
    const threshold = toSafeInteger(getThresholdForGPSDeviceMandatory());
    return !!threshold && toSafeInteger(noOfJobs) > threshold;
}
export function getNewelyInstalledStatus(data) {
    const gpsData = get(data, 'gps', {});
    const appData = get(data, 'app', {});
    const simData = get(data, 'sim', {});
    const gps = {
        color: gpsData.success ? 'info' : 'danger',
        text: gpsData.success ? 'Not Available' : 'Failed',
    };
    const app = {
        color: appData.success ? (appData.newlyInstalled ? 'success' : 'info') : 'danger',
        text: appData.success ? (appData.newlyInstalled ? 'Entry Created' : 'Entry Found') : 'Failed',
    };
    const sim = {
        color: simData.success ? (simData.newlyInstalled ? 'success' : 'info') : 'danger',
        text: simData.success ? (simData.newlyInstalled ? 'Entry Created' : 'Entry Found') : 'Failed',
    };
    return {
        gps,
        app,
        sim,
    };
}
export function mapVehicleListToData(vehicleList) {
    return map(vehicleList, (v) => {
        return {
            vehicleId: v.id,
            vehicleNumber: v.licensePlate,
            driverNumber: get(v, 'activeAssignment.user.phoneNumber', '-'),
            gps: {
                status: get(v, 'gpsStatus', GPS_BOX_STATUS.NOT_INSTALLED),
            },
            app: {
                status: get(v, 'appStatus', DRIVER_APP_STATUS.NOT_AVAILABLE),
            },
            sim: {
                status: get(v, 'simStatus', SIM_BASED_STATUS.NOT_AVAILABLE),
            },
            jobId: v.jobId,
        };
    });
}
export const StyledStatusButton = styled.span `
    background-color: ${(props) => props.color};
    padding: 4px 10px;
    border-radius: 2px;
    color: white;
    font-weight: bold;
    font-size: 12px;
`;
export function getCurrentlyWorkingDevices(data) {
    const { vehicleId, vehicleAppId, vehicleSimId, gps, app, sim, simConsentStatus } = data;
    const workingDevices = [];
    const gpsStatusData = getGPSBoxStatusData(gps);
    const simStatusData = getSimBasedStatusData(sim, simConsentStatus);
    const driverAppStatusData = getDriverAppStatusData(app);
    if (gpsStatusData.color === greenColor) {
        workingDevices.push({
            type: TRACKING_DEVICE_TYPES.GPS_BOX,
            vehicleId: vehicleId,
        });
    }
    if (simStatusData.color === greenColor) {
        workingDevices.push({
            type: TRACKING_DEVICE_TYPES.SIM_BASED,
            vehicleId: vehicleSimId,
        });
    }
    if (driverAppStatusData.color === greenColor) {
        workingDevices.push({
            type: TRACKING_DEVICE_TYPES.DRIVER_APP,
            vehicleId: vehicleAppId,
        });
    }
    return workingDevices;
}
