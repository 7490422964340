import {handle} from "redux-pack";
import {
    CREATE_CONTACT_RENEWAL_REMINDER,
    DELETE_CONTACT_RENEWAL_REMINDER,
    FETCH_CONTACT_RENEWAL_REMINDER,
    FETCH_CONTACT_RENEWAL_REMINDERS,
    FETCH_CONTACT_RENEWAL_REMINDERS_COUNT,
    UPDATE_CONTACT_RENEWAL_REMINDER
} from "../../../actions/dashboard/easy/contact_renewal_reminder_action";

const initialState = {
    isLoading: false, error: null, contactRenewalReminderList: [], data: {},isCountLoading: false
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case CREATE_CONTACT_RENEWAL_REMINDER :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case UPDATE_CONTACT_RENEWAL_REMINDER :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),

            });
        case DELETE_CONTACT_RENEWAL_REMINDER :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, error: null
                }),
                finish: prevState => ({...prevState}),
                failure: prevState => ({...prevState, error: payload})
            });
        case FETCH_CONTACT_RENEWAL_REMINDERS_COUNT :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isCountLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isCountLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
            });
        case FETCH_CONTACT_RENEWAL_REMINDERS :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, contactRenewalReminderList: payload.data.content
                }),
            });

        case FETCH_CONTACT_RENEWAL_REMINDER :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({...prevState, data: {...prevState.data, [payload.data.id]: payload.data}})
            });
        default:
            return state;
    }
}
