import { get, includes } from 'lodash';
import { FLEETX_ACCOUNTS, getFlagValueFromAccountConfig, showDtcOnRealtime } from 'utils/account_utils';
import { getMomentTime } from 'utils/date_utils';
import { isDtcVisible } from 'components/dashboard/roles/permission_utils';
export function showIdleFilterAlso() {
    return includes([FLEETX_ACCOUNTS.INFRA_MARKET], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}
export function showParkeFilterAlso() {
    return includes([FLEETX_ACCOUNTS.INFRA_MARKET], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}
export function shouldBatteryDischargedBeMarkedDisconnectVehicle({ lastUpdatedAt }) {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (accountId) {
        case FLEETX_ACCOUNTS.ABT_PARCEL:
            const minutesDiff = getMomentTime().diff(getMomentTime(lastUpdatedAt), 'minutes');
            return minutesDiff >= 30;
        default:
            const hoursDiff = getMomentTime().diff(getMomentTime(lastUpdatedAt), 'hours');
            return hoursDiff >= 6;
    }
}
export function isMasterDataToggleEnabledForAccount() {
    return (get(window.FLEETX_LOGGED_IN_DATA, 'isSwitchedUser') ||
        includes([5851], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)));
}
export function isRealtimeFromRappEnabled() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return ((includes([FLEETX_ACCOUNTS.FLEETX, FLEETX_ACCOUNTS.MAERSK, 2034, 7571], accountId) || accountId > 8000) &&
        !includes([FLEETX_ACCOUNTS.HASTI_PETRO], accountId));
}
export function isFindNearestAddressWithMinDistanceEnabled() {
    return getFlagValueFromAccountConfig('findNearestAddressWithMinDistance');
}
export function enableMoveOneLocationToAnotherSmoothlyRealtimeShareMapHandler(accountId) {
    return (accountId === 11999 || getFlagValueFromAccountConfig('enableMoveOneLocationToAnotherSmoothlyRealtimeShareMap'));
}
export function showOpenIssuesOnRealtimeCardHandler() {
    return getFlagValueFromAccountConfig('showOpenIssuesOnRealtimeCard');
}
export function showVoiceVoiceCallOptionInRealtime() {
    return getFlagValueFromAccountConfig('showVoiceVoiceCallOptionInRealtime');
}
export function isDtcOnRealtimeEnabled() {
    return (isDtcVisible(get(window.FLEETX_LOGGED_IN_DATA, 'scope'), window.FLEETX_LOGGED_IN_USER) && showDtcOnRealtime());
}
export function showJobRouteDetailsForSelectedVehicleOnRealtimeEnabled() {
    return getFlagValueFromAccountConfig('showJobRouteDetailsForSelectedVehicleOnRealtime');
}
