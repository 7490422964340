export function getParsedLockData(assets) {
    if (!assets) {
        return [];
    }
    if (Array.isArray(assets)) {
        return assets;
    }
    try {
        const data = JSON.parse(assets);
        return data;
    }
    catch (e) {
        return [];
    }
}
export function getUnsealCardIds(productId, includeMasterCards = false) {
    const rfidUnsealCards = productId.match(/CARD_UNSEAL::([\d,xX]+)/);
    let finalRfidUnsealCards = '';
    if (!!rfidUnsealCards && !!rfidUnsealCards[1]) {
        let rfids = rfidUnsealCards[1].split(',');
        for (var i = 0; i < rfids.length; i++) {
            if (!rfids[i].match('X') &&
                rfids[i] != '11111111' &&
                rfids[i] != '22222222' &&
                rfids[i] != '33333333' &&
                rfids[i] != '44444444' &&
                rfids[i] != '55555555') {
                finalRfidUnsealCards += (finalRfidUnsealCards ? ',' : '') + rfids[i];
            }
        }
    }
    return finalRfidUnsealCards;
}
export function showBatteryForLockType(lockType) {
    return lockType != 'INTERNAL_LOCK' && lockType != 'WELDED_LOCK';
}
export function isLockKeypad(lockData) {
    const { type, model, supplier } = lockData || {};
    return type === 'INTERNAL_LOCK';
    // return model === 'TRACA_LOCK' && supplier === 'ATLANTA';
}
export function isLockModel(lockData, modelKey) {
    const { type, model, supplier } = lockData || {};
    return modelKey == model;
}
export const getMergedDeviceId = (assetId, productId) => {
    let mergedDeviceId = assetId;
    let rfidUnsealCards = '';
    let finalRfidUnsealCards = '';
    if (productId) {
        rfidUnsealCards = productId.match(/CARD_UNSEAL::([\d,xX]+)/);
        if (!!rfidUnsealCards && !!rfidUnsealCards[1]) {
            finalRfidUnsealCards = rfidUnsealCards[1];
            const rfidCount = rfidUnsealCards[1].split(',').length;
            for (let i = 5; i > rfidCount; i--) {
                const tempRfid = i * 11111111;
                finalRfidUnsealCards += ',' + tempRfid;
            }
            mergedDeviceId += '::' + finalRfidUnsealCards;
        }
    }
    return mergedDeviceId;
};
