import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { FxTypography, FxFlex } from 'sharedV2';
import { isMobileView } from 'constant';
import styled from 'styled-components';
const Filters = styled(FxFlex) `
    > *:not([class*='react-bootstrap-daterangepicker']):not(:has([class*='react-bootstrap-daterangepicker'])):not(.ignore-max-width) {
        max-width: 220px;
    }
`;
const PageControlPanelComponent = (props) => {
    const { pageLabel, pageSubLabel, tabs, icon, labelSuffix } = props;
    return (_jsxs("div", { style: {
            background: '#fff',
            padding: '1rem 1.6rem',
            borderBottom: '1px solid #e8e8e8',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
            zIndex: 1,
        }, children: [_jsxs(FxFlex, { gap: 12, wrap: "wrap", align: "center", justify: "space-between", children: [_jsxs("div", { children: [typeof pageLabel === 'string' ? (_jsxs(FxTypography.Title, { level: 4, className: "d-flex action-gap align-items-center mb-0", children: [!!icon && icon, " ", pageLabel, !!labelSuffix && labelSuffix] })) : (_jsxs("div", { className: "d-flex action-gap align-items-center", children: [!!icon && icon, " ", pageLabel] })), !!pageSubLabel && pageSubLabel] }), _jsx(Filters, { align: "center", wrap: isMobileView() ? 'wrap' : 'nowrap', gap: 8, children: props.children && props.children.props && props.children.props.children })] }), tabs] }));
};
export default PageControlPanelComponent;
