import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const VendorRateChartsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-rate-charts-vendor-rate-charts" */ 'components/dashboard/rateCharts/vendorRateCharts/VendorRateChartsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditVendorRateChartComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-rate-charts-vendor-rate-charts-add-edit" */ 'components/dashboard/rateCharts/vendorRateCharts/AddEditVendorRateChartComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vendorRateChartsRoutes = [
    {
        path: 'add',
        lazy: AddEditVendorRateChartComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditVendorRateChartComponent,
    },
    {
        index: true,
        lazy: VendorRateChartsComponent,
    },
];
export default vendorRateChartsRoutes;
