import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP, ROOT_API_URL } from '../../constant';
import { isViewStoresAllowedForUser } from '../../components/dashboard/roles/permission_utils';
export const CREATE_STORE = 'create_store';
export const FETCH_STORES = 'fetch_stores';
export const FETCH_STORES_AUTOCOMPLETE = 'fetch_stores_autocomplete';
export const UPDATE_STORE = 'update_store';
export const FETCH_STORE_BY_ID = 'fetch_store_by_id';
export const DELETE_STORE = 'delete_store';
export const FETCH_STORE_SUMMARY = 'fetch_store_summary';
export function createStore(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}stores/`, values, config);
    return {
        type: CREATE_STORE,
        promise: request,
    };
}
export function updateStore(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}stores/`, values, config);
    return {
        type: UPDATE_STORE,
        promise: request,
    };
}
export function fetchStoreById(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}stores/${id}`, config);
    return {
        type: FETCH_STORE_BY_ID,
        promise: request,
    };
}
export function fetchStoreSummary(accesstoken, storeId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}stores/?storeIds=${storeId}&withPartsSummary=true`, config);
    return {
        type: FETCH_STORE_SUMMARY,
        promise: request,
    };
}
export function fetchStores(accesstoken, size = 1000, page = 1, branchId = undefined, name = '', withPartsSummary, type) {
    // @ts-ignore
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    // @ts-ignore
    if (!isViewStoresAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_STORES,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({ size, page: page - 1, branchId: branchId, name, withPartsSummary, type }),
    };
    const request = axios.get(`${ROOT_API_URL}stores/`, config);
    return {
        type: FETCH_STORES,
        promise: request,
    };
}
export function fetchStoresAutoComplete(accesstoken) {
    // @ts-ignore
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    // @ts-ignore
    if (!isViewStoresAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_STORES_AUTOCOMPLETE,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
    };
    const request = axios.get(`${ROOT_API_ERP}stores/autocomplete`, config);
    return {
        type: FETCH_STORES_AUTOCOMPLETE,
        promise: request,
    };
}
export function deleteStore(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}stores/${id}`, config);
    return {
        type: DELETE_STORE,
        promise: request,
    };
}
