import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FxReactSelect as Select } from '../shared';
import { components } from 'react-select';
import { concat, map, get, last } from 'lodash';
const AllSelectOptions = [{ value: 'selectAllOption', label: 'Select All' }];
const Option = (props) => {
    return (_jsx("div", { children: _jsxs(components.Option, { ...props, children: [_jsx("input", { type: "checkbox", checked: props.isSelected, onChange: () => null }), " ", _jsx("label", { children: props.label })] }) }));
};
const getOptionValue = (option) => {
    return get(option, 'value', option);
};
const FxMultiDropdownWithCheckBox = (props) => {
    const { withCheckBoxOption, selectAllOption, ...rest } = props;
    const onChange = (option, ...restParams) => {
        const isAllOptionSelected = getOptionValue(last(option)) === 'selectAllOption';
        if (selectAllOption && isAllOptionSelected) {
            props.onChange(props.simpleValue ? map(props.options, (item) => getOptionValue(item)) : props.options);
        }
        else {
            props.onChange(option, ...restParams);
        }
    };
    const options = useMemo(() => {
        return selectAllOption ? concat(AllSelectOptions, props.options) : props.options;
    }, [props.options, selectAllOption]);
    return (_jsx(Select, { ...rest, options: options, components: { ...props.components, Option }, onChange: onChange, closeMenuOnSelect: false, hideSelectedOptions: false, multi: true }));
};
export default FxMultiDropdownWithCheckBox;
