import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { getISOCountryCode, getPhonePrefixFromCountry } from './internationalization_utils';
import { toUpper, get, forEach, set } from 'lodash';
// example phone number:  8123456789
// country calling code: IN
// format: INTERNATIONAL
// countryCallingCode:91
// will return undefined if no phone number entered(just country calling code) or  phoneNumber empty
export function getParsedPhoneNumber(phoneNumber) {
    return phoneNumber ? parsePhoneNumber(`${phoneNumber}`) : undefined;
}
export function isValidPhoneNumber(phoneNumber = '', options = {}) {
    const { isRequired, ...restOptions } = options;
    const parsedPhoneDetails = getParsedPhoneNumber(phoneNumber);
    const finalNumber = get(parsedPhoneDetails, 'number');
    if (!isRequired) {
        if (!finalNumber && `${phoneNumber}`.length <= 5) {
            return true;
        }
    }
    let defaultCnCode = getISOCountryCode();
    defaultCnCode = defaultCnCode ? toUpper(defaultCnCode) : defaultCnCode;
    return !!finalNumber && isPossiblePhoneNumber(`${phoneNumber}`, { defaultCountry: defaultCnCode, ...restOptions });
}
export function getNonFormattedPhoneNumber(phoneNumber = '') {
    // +918123456789
    return get(getParsedPhoneNumber(phoneNumber), 'number', '');
}
export function getFormattedPhoneNumber(phoneNumber = '', format = 'INTERNATIONAL', options) {
    // +91 81234 56789
    return getParsedPhoneNumber(phoneNumber)?.format(format, options);
}
export function getPhoneNumberWithoutCountryCallingCode(phoneNumber = '') {
    // 8123456789
    return get(getParsedPhoneNumber(phoneNumber), 'nationalNumber', '');
}
export function getPhoneNumberForAPIPayload(phoneNumber = '', defaultValue = '') {
    const parsedPhoneDetails = getParsedPhoneNumber(phoneNumber);
    return get(parsedPhoneDetails, 'number', defaultValue); // non formatted number with country calling code (+918123456789)
}
export function getParsedPhoneNumbersForAPIPayload(values, keys, defaultValue = '', merge = false, sep = '') {
    const parsedNumbers = {};
    forEach(keys, (key) => {
        let parsedNumber;
        const v = get(values, key);
        if (sep) {
            parsedNumber = getPhoneNumberWithDifferentSeparator(v, sep, defaultValue);
        }
        else {
            parsedNumber = getPhoneNumberForAPIPayload(v, defaultValue);
        }
        set(parsedNumbers, key, parsedNumber);
        if (merge) {
            set(values, key, parsedNumber);
        }
    });
    return parsedNumbers;
}
export function getPhoneNumberWithDifferentSeparator(phoneNumber, sep = '', defaultValue = '') {
    if (sep) {
        const parsedNumber = getParsedPhoneNumber(phoneNumber);
        if (parsedNumber) {
            return `+${parsedNumber.countryCallingCode}${sep}${parsedNumber.nationalNumber}`;
        }
        return defaultValue;
    }
    else {
        return phoneNumber;
    }
}
export function getPhoneNumberWithCountryCallingCode(phoneNumber, sep = '') {
    if (phoneNumber && phoneNumber[0] !== '+') {
        return `${getPhonePrefixFromCountry()}${sep}${phoneNumber}`;
    }
    return phoneNumber;
}
