import { jsx as _jsx } from "react/jsx-runtime";
import { get, includes, isEmpty, map } from 'lodash';
import { WORK_ORDER_STATUS } from './part_inventory_utils';
// WORK_ORDER_LINE_ITEM_TYPES is a sharable constant in both react project as well as napp
// please sync the value at both places if you are changing it at one place
export const WORK_ORDER_LINE_ITEM_TYPES = {
    GENERAL_MAINTENANCE_TASK: 'GENERAL_MAINTENANCE_TASK',
    PREVENTIVE_MAINTENANCE_TASK: 'PREVENTIVE_MAINTENANCE_TASK',
    // SERVICE_TASK: 'SERVICE_TASK',
    SERVICE_REMINDER: 'SERVICE_REMINDER',
    INSPECTION_CHECKLIST: 'INSPECTION_CHECKLIST',
    DRIVER_COMPLAINT: 'DRIVER_COMPLAINT',
    // ISSUE: 'ISSUE',
};
export const WORK_ORDER_LINE_ITEM_TYPE_LABELS = {
    GENERAL_MAINTENANCE_TASK: 'General Maintenance Tasks',
    PREVENTIVE_MAINTENANCE_TASK: 'Preventive Maintenance Tasks',
    SERVICE_TASK: 'Service Task',
    SERVICE_REMINDER: 'Service Reminder',
    INSPECTION_CHECKLIST: 'Inspection Checklist',
    DRIVER_COMPLAINT: 'Driver Complaint',
    ISSUE: 'Issue',
};
export const VEHICLE_DOMAINS = [
    {
        value: 'Accessories',
        label: 'Accessories',
    },
    {
        value: 'Body & Chassis',
        label: 'Body & Chassis',
    },
    {
        value: 'Brake',
        label: 'Brake',
    },
    {
        value: 'Clutch',
        label: 'Clutch',
    },
    {
        value: 'Cooling System',
        label: 'Cooling System',
    },
    {
        value: 'Crown',
        label: 'Crown',
    },
    {
        value: 'Electrical',
        label: 'Electrical',
    },
    {
        value: 'Engine',
        label: 'Engine',
    },
    {
        value: 'Gear',
        label: 'Gear',
    },
    {
        value: 'General',
        label: 'General',
    },
    {
        value: 'Hub',
        label: 'Hub',
    },
    {
        value: 'Hydraulic',
        label: 'Hydraulic',
    },
    {
        value: 'Lubricants',
        label: 'Lubricants',
    },
    {
        value: 'Propeller Shaft',
        label: 'Propeller Shaft',
    },
    {
        value: 'Pump',
        label: 'Pump',
    },
    {
        value: 'Steering',
        label: 'Steering',
    },
    {
        value: 'Suspension',
        label: 'Suspension',
    },
    {
        value: 'Tools',
        label: 'Tools',
    },
    {
        value: 'Tyre & Wheel',
        label: 'Tyre & Wheel',
    },
    {
        value: 'Others',
        label: 'Others',
    },
    {
        value: 'Engineering & Welding works',
        label: 'Engineering & Welding works',
    },
];
export function mappedWorkOrderLineItems() {
    return map(WORK_ORDER_LINE_ITEM_TYPES, (v, k) => {
        return {
            value: v,
            label: get(WORK_ORDER_LINE_ITEM_TYPE_LABELS, v, v),
            isDisabled: !includes([
                WORK_ORDER_LINE_ITEM_TYPES.PREVENTIVE_MAINTENANCE_TASK,
                WORK_ORDER_LINE_ITEM_TYPES.GENERAL_MAINTENANCE_TASK,
            ], v),
        };
    });
}
export function getWorkOrderLineItemFrom(data, itemType) {
    if (data.itemType === 'SERVICE_TASK') {
        // for backward compatability
        const item = {
            vehicleDomain: '',
            ...data,
            id2: data.id,
            itemType: WORK_ORDER_LINE_ITEM_TYPES.PREVENTIVE_MAINTENANCE_TASK,
        };
        delete item.id;
        return item;
    }
    switch (itemType) {
        case WORK_ORDER_LINE_ITEM_TYPES.INSPECTION_CHECKLIST:
            return {
                vehicleDomain: '',
                itemType,
                itemId: data.id,
                description: data.description,
            };
        case WORK_ORDER_LINE_ITEM_TYPES.SERVICE_REMINDER:
            return {
                vehicleDomain: '',
                itemType,
                itemId: data.id,
                description: ``,
            };
        case WORK_ORDER_LINE_ITEM_TYPES.DRIVER_COMPLAINT:
            return {
                vehicleDomain: '',
                itemType,
                description: data.description,
                driverComplaintLineItem: true,
            };
        default:
            const item = { vehicleDomain: '', itemType, ...data, id2: data.id };
            delete item.id; // while submitting selected lineItem with id having issues
            return item;
    }
}
export const WORK_ORDER_LINE_ITEM_STATUS = {
    OPEN: 'OPEN',
    RESOLVED: 'RESOLVED',
};
export const WORK_ORDER_LINE_ITEM_STATUS_LABEL = {
    OPEN: 'Open',
    RESOLVED: 'Resolved',
};
export function shouldResolveFlowShown(workOrderStatus) {
    return includes([WORK_ORDER_STATUS.OPEN, WORK_ORDER_STATUS.IN_PROGRESS], workOrderStatus);
}
export function isWorkOrderLineItemResolved(lineItemStatus) {
    return lineItemStatus === WORK_ORDER_LINE_ITEM_STATUS.RESOLVED;
}
export function isLineItemServiceTask(itemType) {
    return includes([WORK_ORDER_LINE_ITEM_TYPES.PREVENTIVE_MAINTENANCE_TASK, WORK_ORDER_LINE_ITEM_TYPES.GENERAL_MAINTENANCE_TASK], itemType);
}
export function noItemFoundRenderer(itemType, foundLineItems, vehicleNumber, isLoading) {
    let text = '';
    if (!vehicleNumber) {
        text = 'Please select a vehicle first.';
    }
    else if (isLoading) {
        text = 'Loading...';
    }
    else if (!isEmpty(foundLineItems)) {
        return null;
    }
    else {
        switch (itemType) {
            case WORK_ORDER_LINE_ITEM_TYPES.SERVICE_REMINDER:
                text = 'No Service Reminder in Overdue or DueSoon.';
                break;
            case WORK_ORDER_LINE_ITEM_TYPES.INSPECTION_CHECKLIST:
                text = 'No Inspection List for selected vehicle.';
                break;
            default:
                text = 'No backlogs for Selected Vehicle';
        }
    }
    return _jsx("div", { className: "text-center text-muted font-italic", children: text });
}
