import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditPartModelComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditPartModel" */ 'components/dashboard/parts_inventory/part_model/add_edit_part_model_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const PartModelsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-partModels" */ 'components/dashboard/parts_inventory/part_model/part_models_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const partsModelsRoutes = [
    {
        index: true,
        lazy: PartModelsComponent,
    },
    {
        path: 'add',
        lazy: AddEditPartModelComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditPartModelComponent,
    },
];
export default partsModelsRoutes;
