import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { FxSelect } from './';
import { debounce } from 'lodash';
import { isPromise } from 'formik';
const FxAsyncSelect = (props) => {
    const { loadOptions, ...rest } = props;
    const [options, setOptions] = React.useState(props.options || []);
    const [loading, setLoading] = React.useState(false);
    const handleSearch = debounce((newValue) => {
        setLoading(true);
        const optionPromise = loadOptions(newValue, (ops) => {
            setOptions(ops);
            setLoading(false);
        });
        if (optionPromise && isPromise(optionPromise)) {
            optionPromise
                .then((data) => {
                setOptions(data || []);
                setLoading(false);
            })
                .catch(() => {
                setLoading(false);
            });
        }
    }, 300);
    return _jsx(FxSelect, { ...rest, onSearch: handleSearch, filterOption: false, options: options, loading: loading });
};
export default FxAsyncSelect;
