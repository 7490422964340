import { map } from 'lodash';
import { handle } from 'redux-pack';
import { GET_ENTITY_RENEWAL_TYPES } from 'actions/dashboard/easy/entity_renewal_types_action';
import { GET_ENTITY_REMINDER_LISTING, GET_ENTITY_REMINDER_SUMMARY, GET_ENTITY_REMINDER_NOTIFICATION_DATA, } from 'actions/dashboard/easy/entity_renewal_reminder_action';
const initialState = {
    types: [],
    typesAsMap: new Map(),
    summary: {},
    data: [],
    notificationsAsMap: new Map(),
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ENTITY_RENEWAL_TYPES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    types: payload.data,
                    typesAsMap: new Map(map(payload.data, (type) => [type.id, type.name])),
                }),
            });
        case GET_ENTITY_REMINDER_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, summary: payload.data }),
            });
        case GET_ENTITY_REMINDER_LISTING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        case GET_ENTITY_REMINDER_NOTIFICATION_DATA:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoadingNotificationData: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoadingNotificationData: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    notificationsAsMap: new Map(map(payload.data, (notification) => [
                        notification?.notificationEntityId,
                        notification?.createdDate,
                    ])),
                }),
            });
        default:
            return state;
    }
}
