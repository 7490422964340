import { ROLE_MAP } from 'constant';
import { isWithinScope } from '../utils';
import { get } from 'lodash';
export function isViewERPConfigurationAllowedForUser(scopes) {
    return isWithinScope('VIEW_ERP_CONFIGURATION', scopes);
}
export function isViewTransporterAnalyticsAllowedForUser(scopes) {
    return isWithinScope('VIEW_TRANSPORTER_ANALYTICS', scopes);
}
export function isRoleEPODCustomer() {
    const loggedInUser = window.FLEETX_LOGGED_IN_DATA;
    const roleName = get(loggedInUser, 'role', {});
    return roleName === ROLE_MAP.ROLE_CUSTOMER_EPOD.name;
}
