import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditPurchaseOrderComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-procurement-purchase-order-add-edit" */ 'components/dashboard/procurement/purchase-order/add_edit_purchase_order_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const purchaseRoutes = [
    {
        path: 'add',
        lazy: AddEditPurchaseOrderComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditPurchaseOrderComponent,
    },
];
export default purchaseRoutes;
