import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider, Tag } from 'antd';
const FxTag = (props) => {
    return (_jsx(ConfigProvider, { theme: {
            token: {
                ...props.themeModify,
            },
        }, children: _jsx(Tag, { ...props }) }));
};
export default FxTag;
