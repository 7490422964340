import { get, map, floor, toNumber, round, forEach } from 'lodash';
import {
    addDataRow,
    addHeader,
    createReportFilename,
    ORIENTATION,
    REPORT_FORMAT,
    startReportCreation,
    getWOverFlow,
} from '../report_utils';
import { getHumanizeTime, getReportTime } from '../date_utils';
import { fetchServiceEntriesInBetween } from 'actions/dashboard/easy/service_entry_action';
import { formatOdometer } from 'constant';
import { fetchUserListMiniV2 } from 'actions/dashboard/users_action';

export async function triggerServiceHistoryPartLevelReportDownload(
    accessToken,
    reportFormat,
    filterConfig,
    stateObj,
    groupName,
    vehicleNumber,
    vehicleListMiniAsMap,
    vendorId,
    currentLineItemFilter,
    vehicleDomain,
    sparePartEntry
) {
    if (stateObj.size <= 0) {
        return;
    }

    const result = await fetchServiceEntriesInBetween(
        accessToken,
        1,
        stateObj.size,
        stateObj.vehicleId,
        stateObj.groupId,
        stateObj.startDate.valueOf(),
        stateObj.endDate.valueOf(),
        false,
        vendorId,
        currentLineItemFilter,
        vehicleDomain,
        sparePartEntry,
        null
    ).promise;

    const reportName = createReportFilename(
        reportFormat,
        'Service-History',
        vehicleNumber,
        groupName,
        stateObj.startDate,
        stateObj.endDate
    );

    const headers = [];
    const footerRow = {};

    const includeDate = get(filterConfig, 'includeDate', true);
    const includeOdometer = get(filterConfig, 'includeOdometer', true);
    const includeRunningHours = get(filterConfig, 'includeRunningHours', true);
    const includeLineItems = get(filterConfig, 'includeLineItems', true);
    const includeVendorName = get(filterConfig, 'includeVendorName', true);
    const includePartsCost = get(filterConfig, 'includePartsCost', true);
    const includeLabourCost = get(filterConfig, 'includeLabourCost', true);
    const includeDiscount = get(filterConfig, 'includeDiscount', true);
    const includeTax = get(filterConfig, 'includeTax', true);
    const includeWorkType = get(filterConfig, 'includeWorkType', true);
    const includeNotes = get(filterConfig, 'includeNotes', true);
    const includeReference = get(filterConfig, 'includeReference', true);
    const includeStationaryNumber = get(filterConfig, 'includeStationaryNumber', true);
    const includeTaxDetailsCol = includeTax;
    const isCSV = reportFormat === REPORT_FORMAT.CSV;

    let noOfCols = 0;

    //OverAll Level
    noOfCols += addHeader(true, headers, 'name', 'Vehicle Name', 150, footerRow);
    noOfCols += addHeader(true, headers, 'number', 'Vehicle Number', 150, footerRow);
    noOfCols += addHeader(includeDate, headers, 'date', 'Date', 100, footerRow);
    noOfCols += addHeader(includeOdometer, headers, 'odometer', `Odometer${isCSV ? ' (km)' : ''}`, 100, footerRow);
    noOfCols += addHeader(includeRunningHours, headers, 'jobName', `Job Name`, 120, footerRow);
    noOfCols += addHeader(includeRunningHours, headers, 'driverName', `DriverName`, 120, footerRow);
    noOfCols += addHeader(includeRunningHours, headers, 'driverNumber', `DriverNumber`, 120, footerRow);
    noOfCols += addHeader(includeRunningHours, headers, 'runningHours', `Running Hours`, 120, footerRow);
    noOfCols += addHeader(includeLineItems, headers, 'lineItems', 'Line Item Name', 100, footerRow);
    noOfCols += addHeader(true, headers, 'partType', 'Part Type', 100, footerRow);
    noOfCols += addHeader(includeVendorName, headers, 'vendorName', 'Vendor Name', 100, footerRow);
    noOfCols += addHeader(includeWorkType, headers, 'includeWorkType', 'Work Type', 100, footerRow);
    noOfCols += addHeader(includeNotes, headers, 'includeNotes', 'Notes', 100, footerRow);
    noOfCols += addHeader(includeReference, headers, 'reference', 'Reference No', 100, footerRow);
    noOfCols += addHeader(includeStationaryNumber, headers, 'stationaryNumber', 'Stationary No', 100, footerRow);
    //Part Level
    noOfCols += addHeader(includePartsCost, headers, 'unitType', 'Part Unit Type', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'itemUnit', 'Part Item Unit', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'itemCount', 'Part Item Count', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'partUnitCost', 'Part Unit cost ', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'partCost', 'Part Cost', 100, footerRow);
    noOfCols += addHeader(includeDiscount, headers, 'partDiscount', 'Part Discount %', 100, footerRow);
    noOfCols += addHeader(includeDiscount, headers, 'discount', 'Parts Discount Rs', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'partTaxType1', 'Part Tax Type 1', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'partTaxPercentage1', 'Part Tax % 1', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'partTaxType2', 'Part Tax Type 2', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'partTaxPercentage2', 'Part Tax % 2', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'partsSGSTTax', 'Parts SGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'partsCGSTTax', 'Parts CGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'partsIGSTTax', 'Parts IGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTax, headers, 'partsVATtax', 'Parts VAT Tax', 100, footerRow);
    noOfCols += addHeader(includeTax, headers, 'tax', 'Parts Tax', 100, footerRow);
    noOfCols += addHeader(true, headers, 'insuranceAmount', 'Insurance Amount', 130, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'partsCost', 'Total Parts Cost', 100, footerRow);
    //Service Item Level
    noOfCols += addHeader(includeLabourCost, headers, 'labourCost', 'Labour Cost', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'laborSGSTTax', 'Labor SGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'laborCGSTTax', 'Labor CGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'laborIGSTTax', 'Labor IGST Tax', 100, footerRow);
    // noOfCols += addHeader(includePartsCost, headers, 'subTotal', 'Sub Total', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'totalAmount', 'Total Bill [All Line Items]', 160, footerRow);
    noOfCols += addHeader(true, headers, 'workOrderNumber', 'Work Order', 100, footerRow);

    const data = [];
    const serviceEntries = result.data.content;

    let sumPartsAmount = 0;
    let sumLabourAmount = 0;
    let sumTotalAmount = 0;
    let rowStyles = {};
    let rowCount = 1;

    const getDiscount = (serviceLineItem) => {
        let discount = 0;
        if (serviceLineItem.serviceParts?.length) {
            return serviceLineItem.serviceParts.reduce(
                (sum, servicePart) => (sum += (servicePart.cost || 0) * 0.01 * (servicePart.discountPercent || 0)),
                0
            );
        }
        return round(discount, 2);
    };
    const getTax = (serviceLineItem) => {
        let tax = 0;
        if (serviceLineItem.serviceParts?.length) {
            serviceLineItem.serviceParts.forEach((servicePart) => {
                let itemCostAfterDiscount =
                    Number(servicePart.cost || 0) -
                    toNumber(servicePart.discountPercent || 0) * 0.01 * Number(servicePart.cost || 0);
                tax +=
                    toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) * 0.01 * itemCostAfterDiscount +
                    toNumber((servicePart.tax2Type ? servicePart.tax2Percent : 0) || 0) * 0.01 * itemCostAfterDiscount;
            });
        }
        return round(tax, 2);
    };

    const getPartCost = (serviceLineItem) => {
        if (serviceLineItem.serviceParts?.length) {
            return serviceLineItem.serviceParts.reduce((sum, servicePart) => (sum += servicePart.cost || 0), 0);
        }
        return 0;
    };

    map(
        serviceEntries,
        (
            {
                id,
                date,
                vehicleId,
                totalAmount,
                laborSubtotal,
                partsSubtotal,
                discount,
                discountPercentage,
                tax1,
                tax1Percentage,
                serviceLineItems,
                meterEntry,
                runningHours,
                vendor,
                laborTax1Type,
                laborTax1,
                laborTax1Percentage,
                laborTax2Type,
                laborTax2,
                laborTax2Percentage,
                reference,
                stationaryNumber,
                workOrderNumber,
                jobName,
                driverName,
                driverNumber,
                insuranceAmount,
            },
            index
        ) => {
            const dataRow = {};
            const vehicle = vehicleListMiniAsMap[vehicleId];
            let showBGColor = index % 2 === 0;
            addDataRow(true, dataRow, 'workOrderNumber', `${workOrderNumber || '-'}`);

            let allServiceLineItems = '';
            map(serviceLineItems, (serviceItem, lineIndex) => {
                if (showBGColor) {
                    rowStyles[rowCount + 1] = {
                        fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'F0F8FF' },
                        },
                    };
                } else {
                }
                allServiceLineItems += get(serviceItem, 'name', '') + '\n';
                addDataRow(true, dataRow, 'name', get(vehicle, 'name', '-'));
                addDataRow(true, dataRow, 'number', get(vehicle, 'licensePlate', '-'));
                addDataRow(includeDate, dataRow, 'date', `${getReportTime(date)}`);
                addDataRow(includeOdometer, dataRow, 'odometer', `${formatOdometer(get(meterEntry, 'value', ''))}`);
                addDataRow(includeRunningHours, dataRow, 'runningHours', runningHours);
                addDataRow(includeLineItems, dataRow, 'lineItems', get(serviceItem, 'name', ''));
                addDataRow(includeVendorName, dataRow, 'vendorName', get(vendor, 'name', '-'));
                addDataRow(includeLineItems, dataRow, 'partsCost', get(serviceItem, 'subTotal', ''));
                addDataRow(includeLineItems, dataRow, 'labourCost', get(serviceItem, 'laborCost', ''));
                // addDataRow(includeLineItems, dataRow, 'subTotal', get(serviceItem, 'subTotal', ''));
                addDataRow(true, dataRow, 'totalAmount', totalAmount);
                addDataRow(includeWorkType, dataRow, 'includeWorkType', get(serviceItem, 'workType', ''));
                addDataRow(includeNotes, dataRow, 'includeNotes', get(serviceItem, 'description', ''));
                addDataRow(includeReference, dataRow, 'reference', reference);
                addDataRow(includeStationaryNumber, dataRow, 'stationaryNumber', stationaryNumber);
                addDataRow(includeStationaryNumber, dataRow, 'jobName', jobName);
                addDataRow(includeStationaryNumber, dataRow, 'driverName', driverName);
                addDataRow(true, dataRow, 'insuranceAmount', insuranceAmount);
                addDataRow(includeStationaryNumber, dataRow, 'driverNumber', driverNumber);

                let laborSGSTTax = 0;
                let laborCGSTTax = 0;
                let laborIGSTTax = 0;

                const laborTax1Calculated = laborTax1Percentage ? `${laborTax1Percentage}%` : laborTax1;
                const laborTax2Calculated = laborTax2Percentage ? `${laborTax2Percentage}%` : laborTax2;
                switch (laborTax1Type) {
                    case 'SGST':
                        laborSGSTTax = laborTax1Calculated;
                        break;
                    case 'CGST':
                        laborCGSTTax = laborTax1Calculated;
                        break;
                    case 'IGST':
                        laborIGSTTax = laborTax1Calculated;
                        break;
                }

                switch (laborTax2Type) {
                    case 'SGST':
                        laborSGSTTax = laborTax2Calculated;
                        break;
                    case 'CGST':
                        laborCGSTTax = laborTax2Calculated;
                        break;
                    case 'IGST':
                        laborIGSTTax = laborTax2Calculated;
                        break;
                }

                if (serviceItem.serviceParts?.length) {
                    serviceItem.serviceParts.forEach((servicePart) => {
                        let partsSGSTTax = 0;
                        let partsCGSTTax = 0;
                        let partsIGSTTax = 0;
                        let partsVATTax = 0;
                        let manualDiscount =
                            get(servicePart, 'cost', '') * (get(servicePart, 'discountPercent', '') / 100);
                        const dataRowTemp = JSON.parse(JSON.stringify(dataRow));
                        addDataRow(true, dataRowTemp, 'unitType', get(servicePart, 'unitType', ''));
                        addDataRow(true, dataRowTemp, 'itemUnit', get(servicePart, 'unit', ''));
                        addDataRow(true, dataRowTemp, 'itemCount', get(servicePart, 'count', ''));
                        let unitCost = servicePart.cost / servicePart.count;
                        addDataRow(true, dataRowTemp, 'partUnitCost', unitCost);
                        addDataRow(true, dataRowTemp, 'partCost', get(servicePart, 'cost', ''));
                        addDataRow(true, dataRowTemp, 'partTaxType1', get(servicePart, 'tax1Type', ''));
                        addDataRow(true, dataRowTemp, 'partTaxPercentage1', get(servicePart, 'tax1Percent', ''));
                        addDataRow(true, dataRowTemp, 'partTaxType2', get(servicePart, 'tax2Type', ''));
                        addDataRow(true, dataRowTemp, 'partTaxPercentage2', get(servicePart, 'tax2Percent', ''));
                        addDataRow(true, dataRowTemp, 'discount', manualDiscount);
                        addDataRow(true, dataRowTemp, 'partDiscount', get(servicePart, 'discountPercent', ''));
                        addDataRow(true, dataRowTemp, 'partType', get(servicePart, 'part.name', ''));

                        let itemCostAfterDiscount =
                            Number(servicePart.cost || 0) -
                            toNumber(servicePart.discountPercent || 0) * 0.01 * Number(servicePart.cost || 0);
                        if (servicePart.tax1Type === 'IGST') {
                            partsIGSTTax +=
                                toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax1Type === 'CGST') {
                            partsCGSTTax +=
                                toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax1Type === 'SGST') {
                            partsSGSTTax +=
                                toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax2Type === 'CGST') {
                            partsCGSTTax +=
                                toNumber((servicePart.tax2Type ? servicePart.tax2Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax2Type === 'SGST') {
                            partsSGSTTax +=
                                toNumber((servicePart.tax2Type ? servicePart.tax2Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        //vat
                        if (servicePart.tax1Type === 'VAT') {
                            partsVATTax +=
                                toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax2Type === 'VAT') {
                            partsVATTax +=
                                toNumber((servicePart.tax2Type ? servicePart.tax2Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }

                        let totalTax = partsSGSTTax + partsCGSTTax + partsIGSTTax + partsVATTax;
                        addDataRow(true, dataRowTemp, 'partsSGSTTax', partsSGSTTax);
                        addDataRow(true, dataRowTemp, 'partsCGSTTax', partsCGSTTax);
                        addDataRow(true, dataRowTemp, 'partsIGSTTax', partsIGSTTax);
                        addDataRow(true, dataRowTemp, 'tax', totalTax.toFixed(2));
                        addDataRow(true, dataRowTemp, 'partsVATtax', partsVATTax);
                        addDataRow(true, dataRowTemp, 'laborSGSTTax', laborSGSTTax);
                        addDataRow(true, dataRowTemp, 'laborCGSTTax', laborCGSTTax);
                        addDataRow(true, dataRowTemp, 'laborIGSTTax', laborIGSTTax);
                        data.push(dataRowTemp);
                        rowCount = rowCount + 1;
                    });
                }
            });
            sumPartsAmount += partsSubtotal;
            sumLabourAmount += laborSubtotal;
            sumTotalAmount += totalAmount;
        }
    );

    const totalRow = {};

    addDataRow(true, totalRow, 'name', `TOTAL \n${data.length} Service History`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 'number', `-`);
    }
    addDataRow(includeDate, totalRow, 'date', `-`);
    addDataRow(includeOdometer, totalRow, 'odometer', `-`);
    addDataRow(includeRunningHours, totalRow, 'runningHours', `-`);
    addDataRow(includeLineItems, totalRow, 'lineItems', `-`);
    addDataRow(includeVendorName, totalRow, 'vendorName', `-`);
    addDataRow(includePartsCost, totalRow, 'partsCost', `${sumPartsAmount}`);
    addDataRow(includeLabourCost, totalRow, 'labourCost', `${sumLabourAmount}`);
    addDataRow(includeLabourCost, totalRow, 'subTotal', `-`);
    addDataRow(includeDiscount, totalRow, 'discount', `-`);
    addDataRow(includeTax, totalRow, 'tax', `-`);
    addDataRow(includeTax, totalRow, 'totalAmount', sumTotalAmount);
    addDataRow(includeTaxDetailsCol, totalRow, 'partsSGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'partsCGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'partsIGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'laborSGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'laborCGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'laborIGSTTax', '-');

    data.push(totalRow);

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Service History Report',
        stateObj.startDate,
        stateObj.endDate,
        vehicleNumber,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        '',
        '',
        '',
        '',
        '',
        rowStyles
    );
}

export async function triggerServiceHistoryItemLevelReportDownload(
    accessToken,
    reportFormat,
    filterConfig,
    stateObj,
    groupName,
    vehicleNumber,
    vehicleListMiniAsMap,
    vendorId,
    currentLineItemFilter,
    vehicleDomain,
    sparePartEntry
) {
    if (stateObj.size <= 0) {
        return;
    }

    const result = await fetchServiceEntriesInBetween(
        accessToken,
        1,
        stateObj.size,
        stateObj.vehicleId,
        stateObj.groupId,
        stateObj.startDate.valueOf(),
        stateObj.endDate.valueOf(),
        false,
        vendorId,
        currentLineItemFilter,
        vehicleDomain,
        sparePartEntry,
        null
    ).promise;

    let usersMap = {};

    try {
        const users = await fetchUserListMiniV2(accessToken, null).promise;
        forEach(get(users, 'data', []), (arr) => {
            usersMap[arr[0]] = arr[1] + ' ' + arr[2];
        });
    } catch (e) {
        console.log(e);
    }

    const reportName = createReportFilename(
        reportFormat,
        'Service-History',
        vehicleNumber,
        groupName,
        stateObj.startDate,
        stateObj.endDate
    );

    const headers = [];
    const footerRow = {};

    const includeDate = get(filterConfig, 'includeDate', true);
    const includeOdometer = get(filterConfig, 'includeOdometer', true);
    const includeRunningHours = get(filterConfig, 'includeRunningHours', true);
    const includeLineItems = get(filterConfig, 'includeLineItems', true);
    const includeVendorName = get(filterConfig, 'includeVendorName', true);
    const includePartsCost = get(filterConfig, 'includePartsCost', true);
    const includeLabourCost = get(filterConfig, 'includeLabourCost', true);
    const includeDiscount = get(filterConfig, 'includeDiscount', true);
    const includeTax = get(filterConfig, 'includeTax', true);
    const includeWorkType = get(filterConfig, 'includeWorkType', true);
    const includeNotes = get(filterConfig, 'includeNotes', true);
    const includeReference = get(filterConfig, 'includeReference', true);
    const includeStationaryNumber = get(filterConfig, 'includeStationaryNumber', true);

    const isCSV = reportFormat === REPORT_FORMAT.CSV;

    let noOfCols = 0;

    noOfCols += addHeader(true, headers, 'name', 'Name', 150, footerRow);
    noOfCols += addHeader(true, headers, 'number', 'Number', 150, footerRow);
    noOfCols += addHeader(includeDate, headers, 'date', 'Date', 100, footerRow);

    noOfCols += addHeader(includeOdometer, headers, 'odometer', `Odometer${isCSV ? ' (km)' : ''}`, 100, footerRow);
    noOfCols += addHeader(includeRunningHours, headers, 'runningHours', `Running Hours`, 120, footerRow);
    noOfCols += addHeader(includeRunningHours, headers, 'jobName', `Job Name`, 120, footerRow);
    noOfCols += addHeader(includeRunningHours, headers, 'driverName', `DriverName`, 120, footerRow);
    noOfCols += addHeader(includeRunningHours, headers, 'driverNumber', `DriverNumber`, 120, footerRow);
    noOfCols += addHeader(includeLineItems, headers, 'lineItems', 'Line Items', 100, footerRow);
    //noOfCols += addHeader(includeLineItems, headers, 'description', 'Item Description', 120, footerRow);
    noOfCols += addHeader(includeVendorName, headers, 'vendorName', 'Vendor Name', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'partsCost', 'Parts Cost', 100, footerRow);
    noOfCols += addHeader(includeLabourCost, headers, 'labourCost', 'Labour Cost', 100, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'subTotal', 'Sub Total', 100, footerRow);
    noOfCols += addHeader(includeDiscount, headers, 'discount', 'Parts Discount', 100, footerRow);
    noOfCols += addHeader(includeTax, headers, 'tax', 'Parts Tax', 100, footerRow);
    noOfCols += addHeader(includeLabourCost, headers, 'labourTax', 'Labour Tax', 100, footerRow);
    noOfCols += addHeader(true, headers, 'insuranceAmount', 'Insurance Amount', 130, footerRow);
    noOfCols += addHeader(includePartsCost, headers, 'totalAmount', 'Total Bill [All Line Items]', 100, footerRow);
    noOfCols += addHeader(true, headers, 'workOrderNumber', 'Work Order', 100, footerRow);

    noOfCols += addHeader(includeWorkType, headers, 'includeWorkType', 'Work Type', 100, footerRow);
    noOfCols += addHeader(includeNotes, headers, 'includeNotes', 'Notes', 100, footerRow);
    noOfCols += addHeader(includeReference, headers, 'reference', 'Reference No', 100, footerRow);
    noOfCols += addHeader(includeStationaryNumber, headers, 'stationaryNumber', 'Stationary No', 100, footerRow);
    //reference

    const includeTaxDetailsCol = includeTax;

    // tax details cols
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'partsSGSTTax', 'Parts SGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'partsCGSTTax', 'Parts CGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'partsIGSTTax', 'Parts IGST Tax', 100, footerRow);

    noOfCols += addHeader(includeTaxDetailsCol, headers, 'laborSGSTTax', 'Labor SGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'laborCGSTTax', 'Labor CGST Tax', 100, footerRow);
    noOfCols += addHeader(includeTaxDetailsCol, headers, 'laborIGSTTax', 'Labor IGST Tax', 100, footerRow);

    noOfCols += addHeader(true, headers, 'createdBy', 'Created By', 160, footerRow);
    noOfCols += addHeader(true, headers, 'createdDate', 'Created Date', 160, footerRow);
    noOfCols += addHeader(true, headers, 'lastModifiedBy', 'Last Modified By', 160, footerRow);

    const data = [];
    const serviceEntries = result.data.content;

    let sumPartsAmount = 0;
    let sumLabourAmount = 0;
    let sumTotalAmount = 0;
    let rowStyles = {};
    let rowCount = 1;

    const getDiscount = (serviceLineItem) => {
        let discount = 0;
        if (serviceLineItem.serviceParts?.length) {
            return serviceLineItem.serviceParts.reduce(
                (sum, servicePart) => (sum += (servicePart.cost || 0) * 0.01 * (servicePart.discountPercent || 0)),
                0
            );
        }
        return round(discount, 2);
    };
    const getTax = (serviceLineItem) => {
        let tax = 0;
        if (serviceLineItem.serviceParts?.length) {
            serviceLineItem.serviceParts.forEach((servicePart) => {
                let itemCostAfterDiscount =
                    Number(servicePart.cost || 0) -
                    toNumber(servicePart.discountPercent || 0) * 0.01 * Number(servicePart.cost || 0);
                tax +=
                    toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) * 0.01 * itemCostAfterDiscount +
                    toNumber((servicePart.tax2Type ? servicePart.tax2Percent : 0) || 0) * 0.01 * itemCostAfterDiscount;
            });
        }
        return round(tax, 2);
    };

    const getLabourTax = (tax, cost) => {
        let totalTax = toNumber(tax || 0) * 0.01 * toNumber(cost || 0);
        return round(totalTax, 2);
    };

    const getPartCost = (serviceLineItem) => {
        if (serviceLineItem.serviceParts?.length) {
            return serviceLineItem.serviceParts.reduce((sum, servicePart) => (sum += servicePart.cost || 0), 0);
        }
        return 0;
    };

    map(
        serviceEntries,
        (
            {
                id,
                date,
                vehicleId,
                insuranceAmount,
                totalAmount,
                laborSubtotal,
                partsSubtotal,
                discount,
                discountPercentage,
                tax1,
                tax1Percentage,
                serviceLineItems,
                meterEntry,
                runningHours,
                vendor,
                laborTax1Type,
                laborTax1,
                laborTax1Percentage,
                laborTax2Type,
                laborTax2,
                laborTax2Percentage,
                reference,
                stationaryNumber,
                jobName,
                driverName,
                driverNumber,
                workOrderNumber,
                lastModifiedBy,
                createdBy,
                createdDate,
            },
            index
        ) => {
            const dataRow = {};
            const vehicle = vehicleListMiniAsMap[vehicleId];
            let showBGColor = index % 2 === 0;

            let allServiceLineItems = '';
            map(serviceLineItems, (serviceItem, lineIndex) => {
                if (showBGColor) {
                    rowStyles[rowCount + 1] = {
                        fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'F0F8FF' },
                        },
                    };
                } else {
                }
                allServiceLineItems += get(serviceItem, 'name', '') + '\n';
                addDataRow(true, dataRow, 'name', get(vehicle, 'name', '-'));
                addDataRow(true, dataRow, 'number', get(vehicle, 'licensePlate', '-'));
                addDataRow(includeDate, dataRow, 'date', `${getReportTime(date)}`);
                addDataRow(includeOdometer, dataRow, 'odometer', `${formatOdometer(get(meterEntry, 'value', ''))}`);
                addDataRow(includeRunningHours, dataRow, 'runningHours', runningHours);
                addDataRow(includeLineItems, dataRow, 'lineItems', get(serviceItem, 'name', ''));
                addDataRow(includeVendorName, dataRow, 'vendorName', get(vendor, 'name', '-'));
                //addDataRow(includeLineItems, dataRow, 'description', get(serviceItem, 'description', ''));
                addDataRow(includeLineItems, dataRow, 'partsCost', getPartCost(serviceItem));
                addDataRow(includeLineItems, dataRow, 'labourCost', get(serviceItem, 'laborCost', ''));
                addDataRow(includeLineItems, dataRow, 'subTotal', get(serviceItem, 'subTotal', ''));
                addDataRow(includeDiscount, dataRow, 'discount', getDiscount(serviceItem));
                addDataRow(true, dataRow, 'workOrderNumber', `${workOrderNumber || '-'}`);
                addDataRow(includeTax, dataRow, 'tax', getTax(serviceItem));
                addDataRow(true, dataRow, 'insuranceAmount', insuranceAmount);
                addDataRow(true, dataRow, 'totalAmount', totalAmount);
                addDataRow(includeWorkType, dataRow, 'includeWorkType', get(serviceItem, 'workType', ''));
                addDataRow(includeNotes, dataRow, 'includeNotes', get(serviceItem, 'description', ''));
                addDataRow(includeReference, dataRow, 'reference', reference);
                addDataRow(includeStationaryNumber, dataRow, 'stationaryNumber', stationaryNumber);
                addDataRow(includeStationaryNumber, dataRow, 'jobName', jobName);
                addDataRow(includeStationaryNumber, dataRow, 'driverName', driverName);
                addDataRow(includeStationaryNumber, dataRow, 'driverNumber', driverNumber);

                addDataRow(true, dataRow, 'createdBy', usersMap[createdBy] || '');
                addDataRow(true, dataRow, 'createdDate', createdDate ? getHumanizeTime(createdDate) : createdDate);
                addDataRow(true, dataRow, 'lastModifiedBy', usersMap[lastModifiedBy] || '');

                // parts tax
                let partsSGSTTax = 0;
                let partsCGSTTax = 0;
                let partsIGSTTax = 0;
                if (serviceItem.serviceParts?.length) {
                    serviceItem.serviceParts.forEach((servicePart) => {
                        let itemCostAfterDiscount =
                            Number(servicePart.cost || 0) -
                            toNumber(servicePart.discountPercent || 0) * 0.01 * Number(servicePart.cost || 0);
                        if (servicePart.tax1Type === 'IGST') {
                            partsIGSTTax +=
                                toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax1Type === 'CGST') {
                            partsCGSTTax +=
                                toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax1Type === 'SGST') {
                            partsSGSTTax +=
                                toNumber((servicePart.tax1Type ? servicePart.tax1Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax2Type === 'CGST') {
                            partsCGSTTax +=
                                toNumber((servicePart.tax2Type ? servicePart.tax2Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                        if (servicePart.tax2Type === 'SGST') {
                            partsSGSTTax +=
                                toNumber((servicePart.tax2Type ? servicePart.tax2Percent : 0) || 0) *
                                0.01 *
                                itemCostAfterDiscount;
                        }
                    });
                }

                // labor tax
                let laborSGSTTax = 0;
                let laborCGSTTax = 0;
                let laborIGSTTax = 0;

                const laborTax1Calculated = laborTax1Percentage ? laborTax1Percentage : laborTax1;
                const laborTax2Calculated = laborTax2Percentage ? laborTax2Percentage : laborTax2;
                switch (laborTax1Type) {
                    case 'SGST':
                        laborSGSTTax = laborTax1Calculated;
                        break;
                    case 'CGST':
                        laborCGSTTax = laborTax1Calculated;
                        break;
                    case 'IGST':
                        laborIGSTTax = laborTax1Calculated;
                        break;
                }

                switch (laborTax2Type) {
                    case 'SGST':
                        laborSGSTTax = laborTax2Calculated;
                        break;
                    case 'CGST':
                        laborCGSTTax = laborTax2Calculated;
                        break;
                    case 'IGST':
                        laborIGSTTax = laborTax2Calculated;
                        break;
                }
                addDataRow(
                    includeLineItems,
                    dataRow,
                    'labourTax',
                    getLabourTax(laborSGSTTax + laborCGSTTax + laborIGSTTax, get(serviceItem, 'laborCost', 0))
                );
                addDataRow(includeTaxDetailsCol, dataRow, 'partsSGSTTax', partsSGSTTax);
                addDataRow(includeTaxDetailsCol, dataRow, 'partsCGSTTax', partsCGSTTax);
                addDataRow(includeTaxDetailsCol, dataRow, 'partsIGSTTax', partsIGSTTax);

                addDataRow(includeTaxDetailsCol, dataRow, 'laborSGSTTax', `${laborSGSTTax}%`);
                addDataRow(includeTaxDetailsCol, dataRow, 'laborCGSTTax', `${laborCGSTTax}%`);
                addDataRow(includeTaxDetailsCol, dataRow, 'laborIGSTTax', `${laborIGSTTax}%`);

                data.push(dataRow);
                rowCount = rowCount + 1;
            });
            sumPartsAmount += partsSubtotal;
            sumLabourAmount += laborSubtotal;
            sumTotalAmount += totalAmount;
        }
    );

    const totalRow = {};

    addDataRow(true, totalRow, 'name', `TOTAL \n${data.length} Service History`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 'number', `- `);
    }
    addDataRow(includeDate, totalRow, 'date', `- `);
    addDataRow(includeOdometer, totalRow, 'odometer', `- `);
    addDataRow(includeRunningHours, totalRow, 'runningHours', `- `);
    addDataRow(includeLineItems, totalRow, 'lineItems', `- `);
    addDataRow(includeVendorName, totalRow, 'vendorName', `- `);
    addDataRow(includePartsCost, totalRow, 'partsCost', `${sumPartsAmount}`);
    addDataRow(includeLabourCost, totalRow, 'labourCost', `${sumLabourAmount}`);
    addDataRow(includeLabourCost, totalRow, 'subTotal', `${sumTotalAmount}`);
    addDataRow(includeDiscount, totalRow, 'discount', `- `);
    addDataRow(includeTax, totalRow, 'tax', `- `);
    addDataRow(includeTax, totalRow, 'totalAmount', sumTotalAmount);
    addDataRow(includeTaxDetailsCol, totalRow, 'partsSGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'partsCGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'partsIGSTTax', '-');

    addDataRow(includeTaxDetailsCol, totalRow, 'laborSGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'laborCGSTTax', '-');
    addDataRow(includeTaxDetailsCol, totalRow, 'laborIGSTTax', '-');

    data.push(totalRow);

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Service History Report',
        stateObj.startDate,
        stateObj.endDate,
        vehicleNumber,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        '',
        '',
        '',
        '',
        '',
        rowStyles
    );
}
