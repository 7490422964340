import { handle } from 'redux-pack';
import { FETCH_SLOTS } from '../../actions/dashboard/shiftwiseDriverActions';
const initialState = {
    slots: null,
    isSlotsLoading: false,
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_SLOTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isSlotsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isSlotsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    slots: payload.data,
                }),
            });
        default:
            return state;
    }
}
