import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, get, includes, round } from 'lodash';
import { Field } from 'formik';
import { mappedOptionsFromArray, mappedObjectToOptions } from '../../../utils/mapping_utils';
import { INSTRUMENT_TYPES } from '../../../../utils/consignmentsUtils';
import { getMomentTime, DATE_FORMAT_TIMESTAMP } from '../../../../utils/date_utils';
import { FxSelectField, FxSelectSingleDate, FxTextFieldStackedLabel, } from '../../../../shared';
import LedgerDetailsComponent from 'components/dashboard/ledger_details/ledger_details_component';
import { getLabelForIndirectIncome } from 'utils/payment_utils';
import { FxRow } from 'sharedV2/FxGrid';
const RecoveryComponent = (props) => {
    const { formikProps, editId, loggedInUser, accesstoken, enableTransactionDetails, setEnableTransactionDetails, ledgers, } = props;
    const { values } = formikProps;
    const { paymentNature } = values;
    const { onAccountFormId } = values;
    const isDebitLedgerSelected = get(values, 'debitLedger.ledgerId');
    const isHSBalancePayment = paymentNature == 'HIRE_SLIP';
    const isPaymentReceipt = paymentNature == 'PAYMENT_RECEIPT';
    const getTdsLedgerOptions = (ledgers) => {
        return editId
            ? mappedOptionsFromArray(ledgers, 'ledgerName')
            : mappedOptionsFromArray(filter(ledgers, (ledger) => includes([11], get(ledger, 'group.baseGroupId'))), 'ledgerName');
    };
    const getDebitLedgersFilter = (ledgers) => {
        return editId || isHSBalancePayment
            ? mappedOptionsFromArray(ledgers, 'ledgerName')
            : mappedOptionsFromArray(filter(ledgers, (ledger) => includes([6, 7, 41], get(ledger, 'group.baseGroupId'))), 'ledgerName');
    };
    const getIndirectIncomeLedgerFilters = (ledgers) => {
        return editId
            ? mappedOptionsFromArray(ledgers, 'ledgerName')
            : mappedOptionsFromArray(filter(ledgers, (ledger) => includes([17], get(ledger, 'group.baseGroupId'))), 'ledgerName');
    };
    const indirectIncomeSibling = () => {
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: "col-md-3", children: _jsx(Field, { name: `transactionDetails.instrumentType`, component: FxSelectField, options: mappedObjectToOptions(INSTRUMENT_TYPES), label: "Payment Mode", onChange: (val) => { }, isRequired: isDebitLedgerSelected && enableTransactionDetails, disabled: !!editId || !isDebitLedgerSelected || !enableTransactionDetails }) }), _jsx("div", { className: "col-md-3", children: _jsx(Field, { type: 'text', name: `transactionDetails.instrumentNumber`, component: FxTextFieldStackedLabel, label: "Instrument No.", isRequired: isDebitLedgerSelected && enableTransactionDetails, disabled: !!editId || !isDebitLedgerSelected || !enableTransactionDetails }) }), _jsx("div", { className: "col-md-3", children: _jsx(Field, { name: `transactionDetails.recoveryDate`, timePicker: true, component: FxSelectSingleDate, label: "Instrument Date", isRequired: isDebitLedgerSelected && enableTransactionDetails, disabled: !!editId || !isDebitLedgerSelected || !enableTransactionDetails, valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP) }) }), _jsx("div", { className: "col-md-3", children: _jsx(Field, { type: 'text', name: `transactionDetails.bankName`, component: FxTextFieldStackedLabel, isRequired: isDebitLedgerSelected && enableTransactionDetails, disabled: !!editId || !isDebitLedgerSelected || !enableTransactionDetails, label: "Bank Name" }) })] }));
    };
    const debitLedgerOnChangeHandler = (ledgers, val) => {
        const selectedLedger = filter(ledgers, (ledger) => get(ledger, 'id') == val);
        const isCashInHandLedger = get(selectedLedger, '0.group.baseGroupId') == 7;
        setEnableTransactionDetails(!isCashInHandLedger);
    };
    const ledgerDetailsFields = [
        {
            name: 'debitLedger.ledgerId',
            onChange: debitLedgerOnChangeHandler,
            disabled: !!editId,
            label: isHSBalancePayment ? 'Credit Ledger' : 'Debit Ledger',
            optionsFilter: getDebitLedgersFilter,
            shouldShowAmountField: true,
            isRequired: true,
            amountFieldInfo: {
                label: 'Amount',
                name: 'debitLedger.amount',
                isAmountFieldRequired: true,
                onBlur: (val) => {
                    if (val) {
                        formikProps.setFieldValue('debitLedger.amount', round(+val, 2));
                    }
                },
            },
        },
        {
            name: 'indirectIncome.ledgerId',
            disabled: !!editId,
            label: getLabelForIndirectIncome(paymentNature),
            optionsFilter: getIndirectIncomeLedgerFilters,
            shouldShowField: () => !isHSBalancePayment,
            shouldShowAmountField: true,
            amountFieldInfo: {
                label: 'Amount',
                name: 'indirectIncome.amount',
                onBlur: (val) => {
                    if (val) {
                        formikProps.setFieldValue('indirectIncome.amount', round(+val, 2));
                    }
                },
            },
            sibling: indirectIncomeSibling,
            hasSibling: true,
            isRequired: get(values, 'indirectIncome.amount', false),
        },
        {
            name: 'tdsLedger.ledgerId',
            disabled: !!editId,
            label: 'TDS Ledger',
            optionsFilter: getTdsLedgerOptions,
            shouldShowField: () => !isHSBalancePayment,
            shouldShowAmountField: true,
            amountFieldInfo: {
                label: 'Amount',
                name: 'tdsLedger.amount',
                onBlur: (val) => {
                    if (val) {
                        formikProps.setFieldValue('tdsLedger.amount', round(+val, 2));
                    }
                },
            },
            isRequired: get(values, 'tdsLedger.amount', false),
        },
        {
            name: 'onAccountLedger.ledgerId',
            disabled: !!editId,
            label: 'On Account Ledger',
            shouldShowField: !!onAccountFormId,
            isRequired: !!onAccountFormId,
        },
    ];
    return (_jsx(FxRow, { children: _jsx(LedgerDetailsComponent, { ledgerDetailsFields: ledgerDetailsFields, ledgersFromParent: ledgers, isNewRow: false }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RecoveryComponent);
