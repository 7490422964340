import { DATE_FORMAT_TIMESTAMP_T, getMomentTime } from 'utils/date_utils';
import { get, last } from 'lodash';
import { getFlagValueFromAccountConfig } from 'utils/account_utils';

export const BID_TYPES = {
    PER_VEHICLE: 'per-vehicle',
    PER_TONNE: 'per-tonne',
    PER_KM: 'per-km',
};

export const BID_TYPE_LABELS = {
    'per-tonne': 'Per-Tonne',
    'per-km': 'Per-Km',
    'per-vehicle': 'Per-Vehicle',
};

export const MATERIAL_QUANTITY_UNIT = {
    TONNE: 'tonne',
    KGS: 'kgs',
    cubicMeters: 'cubic_meter',
};

export const MATERIAL_QUANTITY_UNIT_LABELS = {
    tonne: 'Tonne',
    kgs: 'Kgs',
    cubic_meter: 'Cubic Meter',
};

export const AUCTION_STATUS = {
    OPEN: 'OPEN',
    ONGOING: 'ONGOING',
    PAUSED: 'PAUSED',
    CLOSED: 'CLOSED',
    PARTIALLY_INDENTED: 'PARTIALLY_INDENTED',
    INDENTED: 'INDENTED',
};

export const AUCTION_STATUS_LABELS = {
    OPEN: 'Open',
    ONGOING: 'On Going',
    PAUSED: 'Paused',
    CLOSED: 'Closed',
    PARTIALLY_INDENTED: 'Partially Indented',
    INDENTED: 'Indented',
};

export const AUCTION_LOCATION_CATEGORY = {
    LOADING: 'loading',
    UNLAODING: 'unloading',
};

export const AUCTION_LOCATION_CATEGORY_LABELS = {
    loading: 'Loading',
    unloading: 'Unloading',
};

const bgColorSchemaForList = {
    OPEN: 'bg-open',
    ONGOING: 'bg-on-going',
    CLOSED: 'bg-closed',
    PAUSED: 'bg-paused',
    INDENTED: 'bg-indented',
    PARTIALLY_INDENTED: 'bg-partially-indented',
};

export const AUCTION_STATUS_COLOR_SCHEMA = {
    OPEN: '',
    ONGOING: '#FF9F98',
    CLOSED: 'rgb(43 223 151)',
    PAUSED: '#EBE8A7',
};

export function rowClassForAuctionList(row, rowIndex) {
    const { status } = row;
    return `${bgColorSchemaForList[status]} tr-user-height table-row-style cursor-pointer auction-row`;
}

export const INDENT_STATUSES = [
    'ACCEPTED',
    'ASSIGNED',
    'CHANGED',
    'DELIVERED',
    'EXPIRED',
    'INDENTED',
    'REJECTED',
    'STARTED',
    'COMPLETED',
    'ARRIVAL',
    'DEPARTURE',
    'ARRIVALDEST',
    'END',
    'CLOSED',
    'GEOFENCE_NOT_TRIGGERED',
    'CANCELLED',
    'LATEASSIGNMENT',
];

export const AUCTION_ACTIONS = {
    START: 'START',
    PAUSE: 'PAUSE',
    CLOSE: 'CLOSE',
    RESET: 'RESET',
};
export const AUCTION_ACTIONS_LABEL = {
    START: 'Start',
    PAUSE: 'Pause',
    CLOSE: 'Close',
    RESET: 'Reset',
};

export function getAuctionDataToIndentCreationData(auction) {
    const {
        chosenAuctionBid = {},
        materialType: productType,
        proposedLoadingDate: loadingDate,
        route,
        vehicleSize,
        auctionAnalytics,
        vehicleQuantity: requestedCount,
        comments,
    } = auction;
    const { currentBid: freightFare, transporterId } = chosenAuctionBid;

    const consignee = {
        id: get(last(auctionAnalytics), 'customer.id'),
    };

    const indentBody = {
        productType,
        loadingDate,
        requestedCount,
        freightFare,
        comments,
        consignee,
        route: { id: get(route, 'id') },
        transporter: { id: transporterId },
        vehicleSize,
        invoiceDate: getMomentTime().format(DATE_FORMAT_TIMESTAMP_T),
        scheduledAcceptanceDate: getMomentTime().add(3, 'hours').format(DATE_FORMAT_TIMESTAMP_T),
        scheduledVehicleAssignmentDate: getMomentTime().add(6, 'hours').format(DATE_FORMAT_TIMESTAMP_T),
    };

    return indentBody;
}

export const toggleAuctionFilter = [
    {
        label: 'true',
        value: 'true',
    },
    {
        label: 'false',
        value: 'false',
    },
];
export const maxTransporterAllowedForSpotAuction = () => {
    return getFlagValueFromAccountConfig('maxTransporterAllowedForAuction');
};
