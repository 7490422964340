import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const AddEditContractRateChartComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-rate-charts-client-contract-add-edit" */ 'components/dashboard/rateCharts/clientContractCharts/AddEditClientContractChartComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ContractRateChartsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-rate-charts-client-contract" */ 'components/dashboard/rateCharts/clientContractCharts/ClientContractChartsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const clientcontractchartsRoutes = [
    {
        index: true,
        lazy: ContractRateChartsComponent,
    },
    {
        path: 'add',
        lazy: AddEditContractRateChartComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditContractRateChartComponent,
    },
];
export default clientcontractchartsRoutes;
