import {
    FETCH_ROUTE,
    FETCH_ROUTE_ANALYTICS,
    FETCH_ROUTES,
    FETCH_ROUTES_MINI_LIST,
} from '../../../actions/dashboard/route_dispatch/routes_action';
import { handle } from 'redux-pack';
import { map } from 'lodash';

const initialState = {
    isLoading: false,
    error: null,
    routeList: [],
    isRouteListLoading: false,
    isRouteLoading: false,
    isRouteAnalyticsLoading: false,
    routeAnalytics: null,
    routeListMiniNew: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ROUTES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isRouteListLoading: true,
                    routeList: [],
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isRouteListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, routeList: [] }),
                success: (prevState) => ({
                    ...prevState,
                    routeList: payload.data,
                    routeListMini: map(payload.data, ({ id, name }) => ({ id, name })),
                }),
            });

        case FETCH_ROUTES_MINI_LIST:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isRouteListMiniLoading: true,
                    routeListMiniNew: [],
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isRouteListMiniLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, routeListMiniNew: [] }),
                success: (prevState) => ({
                    ...prevState,
                    routeListMiniNew: payload.data,
                }),
            });
        case FETCH_ROUTE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isRouteLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isRouteLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        case FETCH_ROUTE_ANALYTICS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isRouteAnalyticsLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isRouteAnalyticsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, routeAnalytics: payload.data }),
            });

        default:
            return state;
    }
}
