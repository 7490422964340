import { jsx as _jsx } from "react/jsx-runtime";
const VehicleBillingDetailsListComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-timefences" */ 'components/dashboard/internal_tools/finance/billing/vehicle_billing_details_list_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const BillingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-timefences" */ 'components/dashboard/internal_tools/finance/billing/billing_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const InvoicesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-timefences" */ 'components/dashboard/internal_tools/finance/invoices/invoices_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const InvoiceDetailComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-timefences" */ 'components/dashboard/internal_tools/finance/invoices/invoice_detail_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const CreditNotesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-timefences" */ 'components/dashboard/internal_tools/finance/credit_notes/credit_notes_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const LatestAnnexuresComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-timefences" */ 'components/dashboard/internal_tools/finance/annexures/LatestAnnexuresComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VehicleAnnexuresComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-timefences" */ 'components/dashboard/internal_tools/finance/annexures/VehicleAnnexuresComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const internalFinanceRoutes = [
    {
        path: 'billing/details/:vehicleId',
        lazy: VehicleBillingDetailsListComponent,
    },
    {
        path: 'billing',
        lazy: BillingComponent,
    },
    {
        path: 'invoices/details/:id',
        lazy: InvoiceDetailComponent,
    },
    {
        path: 'invoices',
        lazy: InvoicesComponent,
    },
    {
        path: 'annexures/vehicle',
        lazy: VehicleAnnexuresComponent,
    },
    {
        path: 'annexures',
        lazy: LatestAnnexuresComponent,
    },
    {
        path: 'creditnote',
        lazy: CreditNotesComponent,
    },
];
export default internalFinanceRoutes;
