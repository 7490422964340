import { ROOT_API_URL_V2 } from 'constant';
import axios from 'axios';
export const FETCH_EXISTING_CLONED_VEHICLES = 'FETCH_EXISTING_CLONED_VEHICLES';
export const CREATE_CLONE_VEHICLE = 'CREATE_CLONE_VEHICLE';
export function fetchExistingClonedVehicles(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL_V2}vehicles/clone/config/${accountId}`, config);
    return {
        type: FETCH_EXISTING_CLONED_VEHICLES,
        promise: request,
    };
}
export function createCloneVehicle(accesstoken, accountId, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL_V2}vehicles/clone/config/${accountId}`, data, config);
    return {
        type: CREATE_CLONE_VEHICLE,
        promise: request,
    };
}
