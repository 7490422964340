import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
import { getMomentTime } from '../../../utils/date_utils';
export const ADD_VEHICLE_DOCUMENT = 'ADD_VEHICLE_DOCUMENT';
export const FETCH_VEHICLE_DOCUMENTS = 'FETCH_VEHICLE_DOCUMENTS';
export const FETCH_VEHICLE_DOCUMENT = 'FETCH_VEHICLE_DOCUMENT';
export const UPDATE_VEHICLE_DOCUMENT = 'UPDATE_VEHICLE_DOCUMENT';
export const SET_VEHICLE_DOCUMENT_REMINDER = 'SET_VEHICLE_DOCUMENT_REMINDER';
export const FETCH_VEHICLE_DOCUMENTS_REPORT = 'FETCH_VEHICLE_DOCUMENTS_REPORT';
export const DELETE_DOCUMENT_FROM_VEHICLE_DOCUMENTATION = 'DELETE_DOCUMENT_FROM_VEHICLE_DOCUMENTATION';
export const DELETE_VEHICLE_DOCUMENT = 'DELETE_VEHICLE_DOCUMENT';
export function addVehicleDocument(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}vehicle-documentation`, data, config);
    return {
        type: ADD_VEHICLE_DOCUMENT,
        promise: request,
    };
}
export function updateVehicleDocument(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}vehicle-documentation`, data, config);
    return {
        type: UPDATE_VEHICLE_DOCUMENT,
        promise: request,
    };
}
export function deleteVehicleDocument(id) {
    const config = {
        headers: {},
    };
    const request = axios.delete(`${ROOT_API_ERP}vehicle-documentation/${id}`);
    return {
        type: DELETE_VEHICLE_DOCUMENT,
        promise: request,
    };
}
export function fetchVehicleDocuments(accesstoken, params = {}) {
    const { startDate, endDate, documentType, branchId, vendorId, vehicleId, policyNumber, pendingWithMeIds } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate.valueOf(),
            to: endDate.valueOf(),
            documentType,
            branchId,
            vendorId,
            vehicleId,
            size: 5000,
            policyNumber,
            pendingWithMeIds,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}vehicle-documentation`, config);
    return {
        type: FETCH_VEHICLE_DOCUMENTS,
        promise: request,
    };
}
export function fetchVehicleDocument(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}vehicle-documentation/${id}`, config);
    return {
        type: FETCH_VEHICLE_DOCUMENT,
        promise: request,
    };
}
export function setVehicleDocumentReminder(accesstoken, id, vehicleReminderId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleReminderId,
        }),
    };
    const request = axios.post(`${ROOT_API_ERP}vehicle-documentation/${id}/reminder`, null, config);
    return {
        type: ADD_VEHICLE_DOCUMENT,
        promise: request,
    };
}
export function triggerVehicleDocumentReport(accesstoken, params = {}) {
    const { startDate, endDate, documentType, branchId, vendorId, vehicleId } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            documentType,
            branchId,
            vendorId,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}report/vehicle-documentation`, config);
    return {
        type: FETCH_VEHICLE_DOCUMENTS_REPORT,
        promise: request,
    };
}
export function deleteDocumentFromVehicleDocumentation(accesstoken, imageId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            imageId,
        }),
    };
    const request = axios.delete(`${ROOT_API_ERP}vehicle-documentation/image/${imageId}`, config);
    return {
        type: DELETE_DOCUMENT_FROM_VEHICLE_DOCUMENTATION,
        promise: request,
    };
}
