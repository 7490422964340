import { FETCH_ALL_FEATURES } from 'actions/dashboard/newFeaturesAnnouncement/create_feature_actions';
import { handle } from 'redux-pack/lib';
const initialState = {
    features: [],
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ALL_FEATURES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    features: payload.data,
                }),
            });
        default:
            return state;
    }
}
