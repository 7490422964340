import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Up = () => {
    return (_jsx("svg", { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6 2.6425L0.346252 8.29625L1.4 9.35L6 4.75L10.6 9.35L11.6538 8.29625L6 2.6425Z", fill: "#333333" }) }));
};
const UpIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Up, ...props });
};
export default UpIcon;
