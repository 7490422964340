import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { handleError } from 'constant';
import { useEffect, useState } from 'react';
import { useRouteError } from 'react-router-dom';
import { FxButton, FxFlex } from 'sharedV2/index';
import styled from 'styled-components';
const ErrorBoundaryOops = styled.div `
    font-size: 44px;
    font-weight: bold;
    color: #8c8c8c;
`;
const ErrorBoundaryMsg = styled.div `
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #555555;
`;
const ErrorBoundaryReportCrashButton = styled(FxButton) `
    font-size: 14px;
    font-weight: bold;
    color: #20a8d8;
`;
const ErrorBoundaryReloadButton = styled(FxButton) `
    font-size: 16px;
`;
function RouterErrorBoundary(props) {
    const [eventId, setEventId] = useState('');
    const error = useRouteError();
    const onReloadPage = () => {
        window.location.reload();
    };
    const logErrorToSentry = (error, errorInfo) => {
        try {
            if (window.Sentry) {
                window.Sentry.withScope((scope) => {
                    scope.setTag('crash_type', 'ROUTE_ERROR_BOUNDARY_CRASH');
                    scope.setExtras(errorInfo);
                    const eId = window.Sentry.captureException(error);
                    setEventId(eId);
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    };
    const openCrashReportWindow = () => {
        try {
            if (window.Sentry) {
                window.Sentry.showReportDialog({ eventId: eventId });
            }
        }
        catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        logErrorToSentry(handleError(error), {});
        console.log('errorrouter ', error);
    }, [error]);
    const summaryMsg = props.summaryMsg || 'Something went wrong';
    return (_jsx(FxFlex, { className: "w-100 h-100 text-center", justify: "center", align: "center", children: _jsxs("div", { children: [!props.justMsg && _jsx(ErrorBoundaryOops, { children: "Oops!" }), _jsxs(ErrorBoundaryMsg, { children: [summaryMsg, ". Our software engineering team has been notified."] }), !props.dontAllowCrashReport && window.Sentry && !props.justMsg && (_jsx("div", { children: _jsx(ErrorBoundaryReportCrashButton, { className: "mb-3", onClick: openCrashReportWindow, type: "link", children: "Add a comment for this issue" }) })), !props.justMsg && (_jsx(ErrorBoundaryReloadButton, { onClick: onReloadPage, type: "primary", children: "Reload Page" }))] }) }));
}
export default RouterErrorBoundary;
