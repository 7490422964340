import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { map } from 'lodash';
import { getSearchByButtonByLabel } from 'utils/realtime_utils';
const ToggleButtonContainer = styled.div `
    cursor: pointer;

    .toggle-btn-wrapper {
        border-radius: 5px;
        border: solid 0 #ffffff;
        background-color: #f5f5f5;
        color: #151b1e;
        font-weight: 500;
        font-size: 0.8rem;
    }
    .toggle-btn {
        padding: 4px 8px 4px 8px;
    }
    .active-toggle-btn {
        border-radius: 5px;
        box-shadow: 0 1px 6px 0 rgba(106, 106, 106, 0.5);
        border: solid 0 #dddddd;
        background-color: #ffffff;
    }
`;
const FxToggleButton = (props) => {
    const { activeButton, onButtonClick, buttons } = props;
    return (_jsx(_Fragment, { children: _jsx(ToggleButtonContainer, { className: "d-flex h-100", children: _jsx("div", { className: "toggle-btn-wrapper p-1 h-100 d-flex", children: map(buttons, (button, index) => {
                    return (_jsx("div", { className: `toggle-btn d-flex align-items-center h-100 ${button.key === activeButton.key ? 'active-toggle-btn' : 'inactive-toggle-btn'}`, onClick: () => onButtonClick(button), children: _jsx("span", { children: getSearchByButtonByLabel(button.name) }) }, index));
                }) }) }) }));
};
export default FxToggleButton;
