import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { map } from 'lodash';
import { FxFlex } from '.';
import { isViewportMDOrAbove } from '../constant';
const _FxTabsList = (passedProps) => {
    const { tabs, style, activeTabStyle, activeTab, ...props } = passedProps;
    return (_jsx(FxFlex, { direction: "row", className: "ml-4", children: map(tabs, (tab) => {
            return (_jsxs("div", { className: `hover ${isViewportMDOrAbove() ? '' : 'row'} ${tab.key === activeTab.key ? 'active-hrz-tab' : 'inactive-hrz-tab'}`, style: tab.key === activeTab.key ? activeTabStyle : style, onClick: () => props.onTabClick(tab), children: [tab.name, tab.isRequired && _jsx("abbr", { className: "text-danger", children: "*" })] }, tab.order));
        }) }));
};
export default _FxTabsList;
