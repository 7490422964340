import { handle } from 'redux-pack';
import { FETCH_TMS_ANALYTICS_DATA } from '../../actions/dashboard/vendor_management/tmsAnalytics/tmsAnalyticsActions';
import { FETCH_SEGMENTS } from '../../actions/dashboard/vendor_management/tmsAnalytics/segmentsActions';
import { FETCH_MODULE_FILTERS } from '../../actions/dashboard/vendor_management/tmsAnalytics/moduleFiltersAction';
const initialState = {
    isAnalyticsDataLoading: false,
    tmsAnalyticsData: {},
    moduleFilters: [],
    isModuleFiltersLoading: false,
    segments: [],
    isSegmentsLoading: false,
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_TMS_ANALYTICS_DATA:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isAnalyticsDataLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isAnalyticsDataLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    tmsAnalyticsData: payload.data,
                }),
            });
        case FETCH_SEGMENTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isSegmentsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isSegmentsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    segments: payload.data,
                }),
            });
        case FETCH_MODULE_FILTERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isModuleFiltersLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isModuleFiltersLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    moduleFilters: payload.data,
                }),
            });
        default:
            return state;
    }
}
