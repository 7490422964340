import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
const CREATE_STATIONARY = 'create_stationary';
export const FETCH_STATIONARIES = 'fetch_stationaries';
const UPDATE_STATIONARY = 'update_stationary';
const GET_NEXT_STATIONARY = 'get_next_stationary';
const UPDATE_NEXT_STATIONARY = 'update_next_stationary';
export const GET_STATIONARY = 'get_stationary';
export const VALIDATE_STATIONARY = 'validate_stationary';
export const FETCH_DELETED_STATIONARIES = 'fetch_deleted_stationaries';
export function fetchStationaries(accesstoken, branchId, bookType, enabled, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            branchId,
            bookType,
            enabled,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}stationary/listing`, config);
    return {
        type: FETCH_STATIONARIES,
        promise: request,
    };
}
export function fetchStationary(accesstoken, id, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}stationary/${id}`, config);
    return {
        type: GET_STATIONARY,
        promise: request,
    };
}
export function createStationary(accesstoken, data, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.post(`${ROOT_API_ERP}stationary`, data, config);
    return {
        type: CREATE_STATIONARY,
        promise: request,
    };
}
export function updateStationary(accesstoken, data, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.put(`${ROOT_API_ERP}stationary`, data, config);
    return {
        type: UPDATE_STATIONARY,
        promise: request,
    };
}
export function validateStationary(accesstoken, stationaryId, stationaryNumber) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            stationaryId,
            stationaryNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}stationary/validate`, config);
    return {
        type: VALIDATE_STATIONARY,
        promise: request,
    };
}
export function getNextStationary(accesstoken, branchId, bookType, bookName, accountId) {
    const bookNameParam = bookName ? bookName.replace('[', '%5B').replace(']', '%5D') : bookName;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            branchId,
            bookType,
            bookName: bookNameParam,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}stationary/next`, config);
    return {
        type: GET_NEXT_STATIONARY,
        promise: request,
    };
}
export function fetchDeletedStationaries(accesstoken, { branchId, bookType, bookName }) {
    const bookNameParam = bookName ? bookName.replace('[', '%5B').replace(']', '%5D') : bookName;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            branchId,
            bookType,
            bookName: bookNameParam,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}stationary/listing-detached`, config);
    return {
        type: FETCH_DELETED_STATIONARIES,
        promise: request,
    };
}
