import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const ClientRateChartsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-rate-charts-client-rate-charts" */ 'components/dashboard/rateCharts/clientRateCharts/ClientRateChartsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditClientRateChartComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-rate-charts-client-rate-charts-add-edit" */ 'components/dashboard/rateCharts/clientRateCharts/form/AddEditClientRateChartComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const clientRateChartsRoutes = [
    {
        path: 'add',
        lazy: AddEditClientRateChartComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditClientRateChartComponent,
    },
    {
        index: true,
        lazy: ClientRateChartsComponent,
    },
];
export default clientRateChartsRoutes;
