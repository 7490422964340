import { FxReactSelect as Select } from '../../../shared';
import { orderBy } from 'lodash';

export default ({ onVendorSelected, options, ...props }) => {
    const handleSelectChange = (value) => {
        onVendorSelected(value);
    };

    return (
        <Select
            simpleValue
            placeholder="Select Vendor"
            options={orderBy(options, ['label', 'asc'])}
            onChange={handleSelectChange}
            {...props}
        />
    );
};
