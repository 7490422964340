import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
const RFQListing = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-rfq-list" */ './RFQ'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditRFQ = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-rfq-list" */ './AddEditRFQForm/index'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const rfqRoutes = [
    {
        index: true,
        lazy: RFQListing,
    },
    {
        path: 'add',
        lazy: AddEditRFQ,
    },
    {
        path: 'edit/:id',
        lazy: AddEditRFQ,
    },
];
export default rfqRoutes;
