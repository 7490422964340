import {handle} from "redux-pack";
import {map, pick} from "lodash"
import {FETCH_ALL_ACCOUNTS} from "../../actions/dashboard/admin_actions";


const initialState = {
    isLoading: false, error: null, isFinished: false,
};


export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case FETCH_ALL_ACCOUNTS :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null,
                }),
                finish: prevState => ({...prevState, isLoading: false, isFinished: true,}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, allAccounts: map(payload.data, a => (pick(a, ["id", "email", "name"])))
                }),
            });
        default:
            return state;
    }
}