import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import { Field } from 'formik';
import { mappedConsignee, mappedConsignors } from '../../utils/form_utils';
import CommoditiesFormComponent from './CommoditiesFormComponent';
import { Button } from 'reactstrap';
import { FxSelectField } from '../index';
import { renderAddressBookOption } from '../../utils/address_book_utils';
const RoutePointItemComponent = (props) => {
    const { accesstoken, index, customers, isExistingRouteSelected, addressBook, isEdit, commodityList, materialList, routePoint, selectLocationFromGoogleMap, baseKey, remove, commodityName = 'commodities', addressBookOptions, } = props;
    const isStartLocation = index === 0;
    return (_jsx("div", { className: "my-4 card", children: _jsxs("div", { className: "card-body", children: [_jsxs("h5", { children: [isStartLocation ? 'Loading' : 'Unloading', " Point ", isStartLocation ? '' : `#${index}`, !isExistingRouteSelected && (_jsx(Button, { className: "ml-2", color: "link", onClick: () => remove(index), children: "Remove" }))] }), _jsxs("div", { className: "row", children: [_jsxs("div", { className: "col-md-12", children: [_jsx(Field, { disabled: isExistingRouteSelected, name: `${baseKey}.routePoint.selectId`, placeholder: "Route Point", label: "Select Route Point", isRequired: true, options: addressBookOptions, component: FxSelectField, optionRenderer: renderAddressBookOption, onSelected: (id) => props.onRoutePointSelect(find(addressBook, { id: +id }), index) }), !isExistingRouteSelected && !isEdit && (_jsx("div", { style: { marginTop: '-1rem' }, children: _jsx("button", { disabled: isExistingRouteSelected, type: "button", onClick: () => selectLocationFromGoogleMap(index), className: "btn btn-link pl-0", children: "Select location using google map" }) }))] }), _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: `${baseKey}.customer.id`, placeholder: isStartLocation ? 'Consignor' : 'Consignee', options: isStartLocation ? mappedConsignors(customers) : mappedConsignee(customers), component: FxSelectField, label: isStartLocation ? 'Consignor' : 'Consignee' }) })] }), _jsx(CommoditiesFormComponent, { name: `${baseKey}.${commodityName}`, commodityList: commodityList, materialList: materialList, commodities: routePoint[commodityName] })] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RoutePointItemComponent);
