import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { concat, find, get, isUndefined, map } from 'lodash';
import { fetchServiceReminders } from '../../../actions/dashboard/easy/service_reminders_action';
import { handleError } from '../../../constant';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Loader from '../../utils/loader';
import moment from 'moment-timezone';
import { getMomentTime } from '../../../utils/date_utils';
import { isViewServiceRemindersAllowedForUser } from '../roles/permission_utils';
const renderStatus = ({ dueSoonAt, dueSoon, overdue, nextDueAt }) => {
    if (dueSoon) {
        return (_jsxs(_Fragment, { children: [_jsx("span", { className: 'text-warning', children: "Due Soon" }), " ", _jsx("span", { className: "mx-1", children: "|" }), _jsxs("span", { className: "text-muted", children: [moment.duration(getMomentTime(dueSoonAt).diff(getMomentTime())).humanize(), " from now"] })] }));
    }
    if (overdue) {
        return (_jsxs(_Fragment, { children: [_jsx("span", { className: 'text-danger', children: "Over Due" }), " ", _jsx("span", { className: "mx-1", children: "|" }), _jsxs("span", { className: "text-muted", children: [moment.duration(getMomentTime(nextDueAt).diff(getMomentTime())).humanize(), " ago"] })] }));
    }
    return '-';
};
const ServiceRemindersForVehicleComponent = (props) => {
    const { accesstoken, vehicleId, config } = props;
    const { serviceReminderDueSoon, vehicleReminderDueSoon } = config || {};
    const vehicleReminderOverdue = serviceReminderDueSoon === false;
    const serviceReminderOverdue = vehicleReminderDueSoon === false;
    const [data, setData] = useState([]);
    const [showData, setShowData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const vehicleWiseCount = find(get(props, 'vehicleWiseCount', []), { vehicleId });
    useEffect(() => {
        setShowData(false);
    }, [vehicleId]);
    useEffect(() => {
        if (isViewServiceRemindersAllowedForUser(props.login.scope)) {
            const fetchData = async () => {
                if (!vehicleId && isUndefined(vehicleWiseCount)) {
                    return;
                }
                setIsLoading(true);
                let overdueData = [];
                let dueSoonData = [];
                let result;
                try {
                    if (vehicleWiseCount && vehicleWiseCount.overdueCount) {
                        // overdue
                        result = await props.fetchServiceReminders(accesstoken, 1, 1000, null, vehicleId, null, true, null);
                        if (result.error) {
                            throw new Error(handleError(result.payload.response));
                        }
                        overdueData = get(result, 'payload.data.content', []);
                    }
                    if (vehicleWiseCount && vehicleWiseCount.dueSoonCount) {
                        // dueSoon
                        result = await props.fetchServiceReminders(accesstoken, 1, 1000, null, vehicleId, null, null, true);
                        if (result.error) {
                            throw new Error(handleError(result.payload.response));
                        }
                        dueSoonData = get(result, 'payload.data.content', []);
                    }
                    setData(concat(overdueData, dueSoonData));
                }
                catch (e) {
                    setData([]);
                    alert(handleError(e));
                }
                setIsLoading(false);
            };
            fetchData();
        }
    }, [vehicleId, showData, serviceReminderDueSoon, serviceReminderOverdue]);
    if (isLoading) {
        return _jsx(Loader, { inner: true });
    }
    const renderNoData = () => (_jsxs("div", { className: "text-muted font-italic", children: ["No Overdue/Due soon tasks for this vehicle.", _jsx(Link, { to: `/dashboard/reminders/servicereminders/addMultiple`, children: "Please add more reminders" }), "."] }));
    if (isUndefined(vehicleWiseCount)) {
        return renderNoData();
    }
    return (_jsxs("div", { style: { maxHeight: '8rem', overflowY: 'auto', overflowX: 'hidden' }, children: [!showData && vehicleWiseCount?.vehicleId && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "font-lg mb-1", children: ["Overdue: ", _jsx("span", { className: "text-danger", children: vehicleWiseCount.overdueCount }), "\u00A0\u00A0|\u00A0\u00A0Due Soon:", ' ', _jsx("span", { className: "text-warning", children: vehicleWiseCount.dueSoonCount })] }), _jsx(Button, { color: "primary", outline: true, size: "sm", onClick: () => setShowData(true), children: "View Details" })] })), !showData && !vehicleWiseCount?.vehicleId && renderNoData(), showData && (_jsx("div", { children: map(data, (d, index) => {
                    const serviceTaskId = get(d, 'serviceTask.id');
                    const link = `/dashboard/reminders/servicereminders#vehicleId=${vehicleId}&serviceTaskId=${serviceTaskId}`;
                    return (_jsxs("div", { className: "row mb-2", children: [_jsxs("div", { className: "col-md-1 whitespace-nowrap", children: [index + 1, "."] }), _jsxs("div", { className: "col-md-7", children: [_jsx("div", { children: get(d, 'serviceTask.name') }), _jsx("div", { className: "medium", children: renderStatus(d) })] }), _jsx("div", { className: "col-md-2 whitespace-nowrap", children: _jsx(Link, { to: link, style: { textDecoration: 'none' }, children: "Details" }) })] }, d.id));
                }) }))] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchServiceReminders,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        vehicleWiseCount: get(state, 'serviceReminders.vehicleWiseCount'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceRemindersForVehicleComponent);
