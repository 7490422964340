import {handle} from "redux-pack";
import {
    CREATE_GEOFENCE, DELETE_GEOFENCE, FETCH_GEOFENCE, FETCH_GEOFENCES, UPDATE_GEOFENCE
} from "../../actions/dashboard/geofence_action";

const initialState = {
    isLoading: false, error: null, geofenceList: [], data: {}
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case CREATE_GEOFENCE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case UPDATE_GEOFENCE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),

            });
        case DELETE_GEOFENCE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})
            });
        case FETCH_GEOFENCES :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, geofenceList: payload.data
                }),
            });

        case FETCH_GEOFENCE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({...prevState, data: {...prevState.data, [payload.data.id]: payload.data}})
            });
        default:
            return state;
    }
}

