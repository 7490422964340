import { get, map, orderBy, sumBy } from 'lodash';
import { getTimeDiff } from 'utils/date_utils';
export function getLat(item) {
    return get(item, '[0]');
}
export function getLng(item) {
    return get(item, '[1]');
}
export function getTotalTime(item, defaultValue = 1) {
    return get(item, '[2]', defaultValue);
}
export function getDurationString(duration) {
    const durationsAbbreviations = {
        minute: 'min',
        day: 'd',
        month: 'm',
    };
    return getTimeDiff(duration, false, true, false, false, true, { abbreviations: durationsAbbreviations });
}
export const getRowId = ({ longitude, latitude, timeOnSite }) => {
    return `${latitude}-${longitude}-${timeOnSite}`;
};
export function getPreparedHeatmapData(stoppageDataHeatMap) {
    return {
        stoppageDataHeatMap,
    };
}
export function getPreparedStoppageAddressData(data) {
    const stoppageDataAddressBookDTOS = get(data, '[0]', []);
    const stoppageDataUnknownAddressDTOS = get(data, '[1]', []);
    const totalTimeOnSite = sumBy(stoppageDataAddressBookDTOS, '[4]');
    const totalTimeOnUnknownSite = sumBy(stoppageDataUnknownAddressDTOS, '[2]');
    const totalTimeOnAnySite = totalTimeOnSite + totalTimeOnUnknownSite;
    const totalTimeOnSitePercentage = totalTimeOnAnySite ? (totalTimeOnSite / totalTimeOnAnySite) * 100 : 0;
    const totalTimeOnUnknownSitePercentage = totalTimeOnAnySite
        ? (totalTimeOnUnknownSite / totalTimeOnAnySite) * 100
        : 0;
    const vehicleStoppageMapping = (vehicleStoppageDataDTO) => {
        return map(vehicleStoppageDataDTO, (vd) => {
            return {
                vehicleNumber: get(vd, '[0]', ''),
                vehicleId: get(vd, '[1]', 0),
                duration: get(vd, '[2]', 0),
                totalNoOfVisits: get(vd, '[3]', 0),
            };
        });
    };
    return {
        knownSites: orderBy(map(stoppageDataAddressBookDTOS, (item, index) => {
            return {
                key: `known-${index}`,
                addressBookId: get(item, '[0]', ''),
                addressBookName: get(item, '[1]', ''),
                address: get(item, '[2]', ''),
                numberOfVisits: get(item, '[3]', 0),
                totalTime: get(item, '[4]', 0),
                latitude: get(item, '[5]', ''),
                longitude: get(item, '[6]', ''),
                vehicleStoppageDataDTO: vehicleStoppageMapping(get(item, '[7]', [])),
            };
        }), ['totalTime'], ['desc']),
        unknownSites: orderBy(map(stoppageDataUnknownAddressDTOS, (item, index) => {
            return {
                key: `unknown-${index}`,
                address: get(item, '[0]', ''),
                numberOfVisits: get(item, '[1]', 0),
                totalTime: get(item, '[2]', 0),
                latitude: get(item, '[3]', ''),
                longitude: get(item, '[4]', ''),
                vehicleStoppageDataDTO: vehicleStoppageMapping(get(item, '[5]', [])),
            };
        }), ['totalTime'], ['desc']),
        stoppageDataSummary: {
            totalTimeOnSite,
            totalTimeOnSitePercentage,
            totalTimeOnUnknownSite,
            totalTimeOnUnknownSitePercentage,
            totalTimeOnAnySite,
        },
    };
}
export function getSelectedRowId(row) {
    const latitude = get(row, 'lat', row.latitude);
    const longitude = get(row, 'lon', row.longitude);
    return `${latitude}-${longitude}`;
}
