import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditVendorComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditVendor" */ 'components/dashboard/vendors/add_edit_vendor_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewVendorComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-viewVendor" */ 'components/dashboard/vendors/view_vendor_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const VendorsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-vendors" */ 'components/dashboard/vendors/vendors_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vendorRoutes = [
    {
        index: true,
        lazy: VendorsComponent,
    },
    {
        path: 'add',
        lazy: AddEditVendorComponent,
    },
    {
        path: 'view/:id',
        lazy: ViewVendorComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditVendorComponent,
    },
];
export default vendorRoutes;
