import { map } from 'lodash';
import { handle } from 'redux-pack';
import {
    CREATE_VEHICLE_RENEWAL_TYPE,
    DELETE_VEHICLE_RENEWAL_TYPE,
    FETCH_VEHICLE_RENEWAL_TYPE,
    FETCH_VEHICLE_RENEWAL_TYPES,
    UPDATE_VEHICLE_RENEWAL_TYPE,
} from 'actions/dashboard/easy/vehicle_renewal_types_action';

const initialState = {
    isLoading: false,
    error: null,
    data: {},
    vehicleRenewalTypeList: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_VEHICLE_RENEWAL_TYPE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_VEHICLE_RENEWAL_TYPE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_VEHICLE_RENEWAL_TYPE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_VEHICLE_RENEWAL_TYPES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleRenewalTypeList: payload.data,
                    vehicleRenewalTypesAsMap: new Map(map(payload.data, (type) => [type.id, type.name])),
                }),
            });

        case FETCH_VEHICLE_RENEWAL_TYPE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        default:
            return state;
    }
}
