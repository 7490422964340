import axios from 'axios';
import { ROOT_API_URL, parseQueryParams } from '../../../constant';

export const CREATE_USER_TYPE = 'create_user_type';
export const FETCH_USER_TYPES = 'fetch_user_types';
export const FETCH_USER_TYPE = 'fetch_user_type';
export const UPDATE_USER_TYPE = 'update_user_type';
export const DELETE_USER_TYPE = 'delete_user_type';
export const FETCH_PARENTS = 'fetch_parents';
export const FETCH_USER_FOR_MIGRATE = 'fetch_user_for_migrate';
export const MIGRATE_USER = 'migrate_user';

export function createUserType(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}user-types`, values, config);
    return {
        type: CREATE_USER_TYPE,
        promise: request,
    };
}

export function updateUserType(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}user-types/${id}`, values, config);

    return {
        type: UPDATE_USER_TYPE,
        promise: request,
    };
}

export function fetchUserTypes(accesstoken, accountId = null) {
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}user-types`, config);

    return {
        type: FETCH_USER_TYPES,
        promise: request,
    };
}

export function fetchUserType(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}user-types/${id}`, config);

    return {
        type: FETCH_USER_TYPE,
        promise: request,
    };
}

export function deleteUserType(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}user-types/${id}`, config);
    return {
        type: DELETE_USER_TYPE,
        promise: request,
    };
}

export function fetchParents(accesstoken, roleId, groupIds) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}users/parents?roleID=${roleId}&groupIds=${groupIds}`, config);

    return {
        type: FETCH_PARENTS,
        promise: request,
    };
}

export function fetchUserForMigrate(accesstoken, roleId, groupIds) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}users/migrate-users?roleID=${roleId}&groupIds=${groupIds}`, config);

    return {
        type: FETCH_USER_FOR_MIGRATE,
        promise: request,
    };
}

export function migrateUser(accesstoken, values) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            migrateFromParentId: values.migrateFromParentId,
            migrateToParentId: values.migrateToParentId,
        }),
    };

    const request = axios.put(`${ROOT_API_URL}users/migrate`, {}, config);
    return {
        type: MIGRATE_USER,
        promise: request,
    };
}
