import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { RESTRICT_DOWNLOAD_MESSAGE } from '../../../strings';
import { FxButton } from '../../../../src/sharedV2';
import { withTranslation } from 'react-i18next';

class DownloadButtonsContainer extends Component {
    mounted = false;

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    render() {
        const { restrictDownload, disabledPDF, disabledEXCEL, gacategory, showPDFButton, t } = this.props;
        return (
            <div>
                {restrictDownload && <div className="text-danger font-weight-bold">{RESTRICT_DOWNLOAD_MESSAGE}</div>}
                <div className="pull-left">
                    {showPDFButton && (
                        <FxButton
                            type="primary"
                            size={'middle'}
                            disabled={!!disabledPDF || !!restrictDownload}
                            onClick={this.props.onDownloadPDF}
                            className="mb-2"
                            data-gacategory={gacategory ? gacategory : null}
                            data-gaaction={gacategory ? 'Click' : null}
                            data-galabel={gacategory ? `${this.props.galabelPrefix} download pdf` : null}
                        >
                            {t('downloadPDF')}
                        </FxButton>
                    )}
                    <FxButton
                        type="primary"
                        size={'middle'}
                        disabled={!!disabledEXCEL || !!restrictDownload}
                        onClick={this.props.onDownloadEXCEL}
                        className="ml-2 mb-2"
                        data-gacategory={gacategory ? gacategory : null}
                        data-gaaction={gacategory ? 'Click' : null}
                        data-galabel={gacategory ? `${this.props.galabelPrefix} download excel` : null}
                    >
                        {t('downloadEXCEL')}
                    </FxButton>
                </div>
                <div className="pull-right">
                    {this.props.showClearAll && (
                        <FxButton className={'mr-3'} onClick={this.props.onClearAll}>
                            {window.t('clearAll')}
                        </FxButton>
                    )}
                    <FxButton size={'middle'} onClick={this.props.onCloseClick}>
                        {t('close')}
                    </FxButton>
                </div>
                <div className="clearfix" />
            </div>
        );
    }

    componentDidMount() {
        this.mounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {}

    componentWillUnmount() {
        this.mounted = false;
    }
}

DownloadButtonsContainer.propTypes = {
    gacategory: PropTypes.string.isRequired,
    galabelPrefix: PropTypes.string.isRequired,
    onDownloadPDF: PropTypes.func.isRequired,
    onDownloadEXCEL: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    showPDFButton: PropTypes.bool,
    disabledPDF: PropTypes.bool,
    disabledEXCEL: PropTypes.bool,
    showClearAll: PropTypes.bool,
    onClearAll: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state, ownProps) {
    const isSwitchedUser = get(state, 'login.isSwitchedUser');
    return {
        isSwitchedUser,
        restrictDownload: !isSwitchedUser && get(state, 'settings.loggedInUser.restrictDownload'),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DownloadButtonsContainer));
