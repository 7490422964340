import { jsx as _jsx } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const NavWrapper = styled.div `
    border: 1px solid #dadada;
    border-radius: 4px;
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '')};
`;
const NavBarCustomPills = (props) => {
    const { accesstoken, maxWidth } = props;
    const { t } = useTranslation();
    return (_jsx(NavWrapper, { maxWidth: maxWidth, className: "mt-2 mb-4 nav-pills-V2", children: props.children }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NavBarCustomPills);
