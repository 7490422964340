import { get, toSafeInteger, forEach, map, ceil } from 'lodash';
import { recalculateRanges } from '../utils/trip_stratification_utils';
import InputRange from 'react-input-range';

export default (passedProps) => {
    const { field, form, ...props } = passedProps;
    const thresholdList = get(form, 'values.thresholdList');
    const onChange = (value, id) => {
        const newThresholdList = [...thresholdList];
        newThresholdList[props.currentIndex] = { minThreshold: value.min, maxThreshold: value.max, type: 'SPEED' };
        if (id) {
            newThresholdList[props.currentIndex].id = id;
        }
        recalculateRanges(newThresholdList, form, true);
    };

    const currentIndex = toSafeInteger(props.currentIndex);
    const min = currentIndex > 0 ? thresholdList[currentIndex - 1].maxThreshold : 0;
    const numberOfSteps = (80 - min) / 10 - 1;
    const totalRangeWidth = 100 / (numberOfSteps + 1);
    const stepArray = [];
    for (let i = 0; i < numberOfSteps; i++) {
        stepArray.push(
            <span className="d-flex justify-content-end slider-steps" style={{ 'width': `${totalRangeWidth}%` }}></span>
        );
    }

    const { minThreshold: minValue, maxThreshold: maxValue } = get(field, 'value');
    const value = { min: minValue, max: maxValue };
    return (
        <>
            {stepArray}
            <InputRange
                step={10}
                maxValue={80}
                minValue={min}
                formatLabel={props.formatValue}
                value={value}
                onChange={(value) => onChange(value, props.rangeID)}
            />
        </>
    );
};
