import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { dropRight, find, forEach, get, includes, map, remove, set, toSafeInteger } from 'lodash';
import { handleError, isViewportMDOrAbove } from '../../../constant';
import { FxIcon, FxReactSelect, FxSkeleton } from '../../../shared';
import { Link } from 'react-router-dom';
import { Button, Col, Collapse, Row, UncontrolledTooltip } from 'reactstrap';
import { attachTyreConfigurationToVehicle } from '../../../actions/dashboard/tyres_action';
import { isManageTyre, isUpdateTyresAllowedForUser } from '../roles/permission_utils';
import Loader from 'components/utils/loader';
const VehicleTyreConfigComponent = (props) => {
    const { vehicleDetails, vehicleId, tyres, selectedTyreId, setVehicleDetails, tyreListIndex, tyreFlow, setFieldValue, alreadySelectedTyres, onAddTyreCB, tyreConfigurationList, tyreConfigurationListOptions, isTyreInward = false, selectedTyreInward, } = props;
    let selectedTyre = find(tyres, { id: selectedTyreId });
    if (isTyreInward) {
        selectedTyre = { ...selectedTyreInward };
    }
    const currentVehicleDetails = get(vehicleDetails, vehicleId, {});
    const [collapse, setCollapse] = useState(false);
    const [tyreListConfigurationsSelections, setTyreListConfigurationsSelections] = useState({});
    const { noOfRows, tyreConfigObj, fetchingAttachedTyres } = currentVehicleDetails;
    const getGridHeight = (rows) => {
        let rowsHeight = rows ? rows : 5;
        let oneCellHeight = 100;
        return rowsHeight * oneCellHeight;
    };
    const collapseToggle = () => {
        setCollapse(!collapse);
    };
    const onTyreConfigurationSelected = (val) => {
        const currentConfig = find(tyreConfigurationList, { id: val });
        const currentVehicle = structuredClone(currentVehicleDetails);
        set(currentVehicle, 'tyreConfigObj', currentConfig);
        if (isTyreInward) {
            setVehicleDetails(vehicleId, currentVehicle);
        }
        else {
            setVehicleDetails((prev) => {
                return {
                    ...prev,
                    [vehicleId]: currentVehicle,
                };
            });
        }
    };
    const handleConfigAttach = (selectedConfiguration) => {
        if (!selectedConfiguration) {
            alert('Please select a configuration');
            return;
        }
        props
            .attachTyreConfigurationToVehicle({ vehicleId: vehicleId, configurationId: selectedConfiguration }, props.accesstoken)
            .then((res) => {
            if (!res.error) {
                onTyreConfigurationSelected(selectedConfiguration);
            }
        })
            .catch((e) => {
            alert(handleError(e));
        });
    };
    const getTyreDisplayDetails = (id, tyreDetails) => {
        const tyre = find(tyreDetails, { positionId: id });
        const tyrePosition = find(get(tyreConfigObj, 'positions', []), { id: id });
        const tyreId = `${get(tyre, 'id', '-')}`;
        const tyreName = `${get(tyre, 'model.brand.name', '-')} ${get(tyre, 'model.name', '-')} `;
        const tyreNo = `${get(tyre, 'tyreNo', '-')} `;
        const tyreTread = `${get(tyre, 'treadDepth', '-')}`.trim();
        const addedFromUIIndex = get(tyre, 'addedFromUI');
        let isTyreRemoved = false;
        if (selectedTyreId == toSafeInteger(tyreId) && tyreFlow === 'REMOVAL') {
            isTyreRemoved = true;
        }
        return (_jsxs("div", { className: "d-flex flex-row align-items-center justify-content-sm-around", children: [_jsxs("div", { className: "d-flex flex-column mr-auto align-items-start ml-2 flex-text-wrapper", children: [_jsx("div", { className: "mx-1 d-none d-sm-none d-md-block", children: tyreName }), _jsx("div", { className: "mx-1 text-muted d-none d-sm-none d-md-block", children: _jsx(Link, { to: `/dashboard/tyres/view/${tyreId}`, children: tyreNo }) }), _jsxs("div", { className: "mx-1 text-muted d-none d-sm-none d-md-block", children: ["Tread: ", tyreTread !== '-' ? `${tyreTread}mm` : '-'] }), _jsx("div", { className: 'text-gray medium mt-1', children: get(tyrePosition, 'name', '') })] }), _jsxs("div", { children: [addedFromUIIndex == tyreListIndex && (_jsx("div", { children: _jsx("i", { onClick: () => onClickRemoveTyre(id), className: "fa fa-close mx-2", style: { width: '20px' }, id: `remove-tyre-${id}`, children: _jsx(UncontrolledTooltip, { placement: "left", target: `remove-tyre-${id}`, delay: 100, children: "Remove Tyre" }) }) })), isTyreRemoved && (_jsx(_Fragment, { children: _jsx("div", { className: "mr-2 ", style: { color: '#f86c6b' }, children: "Removed" }) }))] })] }));
    };
    const onAddTyre = (position) => {
        if (!selectedTyre) {
            alert('Please Select a Tyre');
            return;
        }
        if (onAddTyreCB && typeof onAddTyreCB === 'function') {
            onAddTyreCB(position?.id);
        }
        const tempCurrentVehicleDetails = JSON.parse(JSON.stringify(currentVehicleDetails));
        selectedTyre['positionId'] = position?.id;
        selectedTyre['addedFromUI'] = tyreListIndex;
        tempCurrentVehicleDetails?.tyreDetails?.push(selectedTyre);
        tempCurrentVehicleDetails?.existingTyrePositions?.push(position?.id);
        if (isTyreInward) {
            setVehicleDetails(vehicleId, tempCurrentVehicleDetails);
        }
        else {
            setVehicleDetails((prev) => {
                return {
                    ...prev,
                    [vehicleId]: tempCurrentVehicleDetails,
                };
            });
        }
    };
    const onClickRemoveTyre = (id) => {
        const tempCurrentVehicleDetails = JSON.parse(JSON.stringify(currentVehicleDetails));
        remove(get(tempCurrentVehicleDetails, 'tyreDetails'), (tyre) => get(tyre, 'positionId') == id);
        remove(get(tempCurrentVehicleDetails, 'existingTyrePositions'), (position) => position == id);
        if (isTyreInward) {
            setVehicleDetails(vehicleId, tempCurrentVehicleDetails);
        }
        else {
            setVehicleDetails((prev) => {
                return {
                    ...prev,
                    [vehicleId]: tempCurrentVehicleDetails,
                };
            });
        }
        setFieldValue(`tyreRequestList[${tyreListIndex}].id`, '');
    };
    const getAddTyreCell = (id) => {
        const { tyreConfigObj, tyreDetails } = currentVehicleDetails;
        let isDisabled = false;
        forEach(tyreDetails, (tyre) => {
            if (tyre?.addedFromUI >= 0 && tyre?.addedFromUI == tyreListIndex) {
                isDisabled = true;
            }
        });
        return (_jsxs("div", { id: tyreConfigObj?.positions[id]?.id, onClick: (event) => !isDisabled && onAddTyre(tyreConfigObj?.positions[id]), style: { opacity: `${isDisabled ? '0.6' : '1'}` }, children: [_jsxs("div", { className: "d-flex flex-row justify-content-center", children: [_jsx("i", { id: tyreConfigObj?.positions[id]?.id, className: "fa fa-plus p-1" }), _jsxs("span", { id: tyreConfigObj?.positions[id]?.id, className: "d-none d-sm-none d-md-block", children: [' ', "Add Tyre"] })] }), _jsx("div", { className: 'text-gray medium mt-1', children: get(tyreConfigObj?.positions[id], 'name', '') })] }));
    };
    const renderHeader = (code, item) => {
        return (_jsxs("div", { className: "d-flex justify-content-around align-items-top", children: [_jsx("div", { style: { width: '1%' } }), _jsx("div", { className: "d-flex justify-content-center align-items-bottom header-border1 w-50" }, `rowHeader-${item}-${vehicleId}`), _jsx("div", { style: { width: '1%' } })] }, `rowHeader-${code}-${item}-${vehicleId}`));
    };
    const getTyreFill = (id) => {
        let fill = '';
        const { tyreDetails, existingTyrePositions } = currentVehicleDetails;
        const tyre = find(tyreDetails, { positionId: id });
        const tyreId = `${get(tyre, 'id', '-')}`;
        if (tyreFlow === 'REMOVAL' && includes(alreadySelectedTyres, toSafeInteger(tyreId)))
            return;
        if (includes(existingTyrePositions, id)) {
            const usePercentage = get(find(tyreDetails, { positionId: id }), 'usePercentage', '');
            if (usePercentage < 50) {
                fill = '#92eeae';
            }
            else if (usePercentage < 80) {
                fill = '#f8c145';
            }
            else if (find(tyreDetails, { positionId: id }).usePercentage > 80) {
                fill = '#ffe6e5';
            }
            else {
                fill = '#9c9a9a';
            }
        }
        return fill;
    };
    // Truck layout's Two Tyre Row
    const renderTwoTyre = (id, index) => {
        return (_jsxs("div", { className: "d-flex justify-content-between align-items-center height-60", children: [_jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [_jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsxs("div", { id: id[0], children: [_jsx(FxIcon, { id: id[0], 
                                    // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                    fill: getTyreFill(id[0]), icon: "tyre", className: 'mx-1' }), _jsx("div", {})] }), _jsx("div", { className: "height-60 truck-body" }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx("div", {})] }), _jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [_jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx("div", { className: "height-60 truck-body" }), _jsx("div", { id: id[1], children: _jsx(FxIcon, { id: id[1], 
                                // onClick={(event) => {
                                //     this.onSvgTyreClick(id[1], event);
                                // }}
                                fill: getTyreFill(id[1]), icon: "tyre", className: 'mx-1' }) }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' })] }), _jsx("div", { style: { width: '1%' } })] }, `rowTwo-${id[0]}-${index}-${vehicleId}`));
    };
    // Truck layout's Four Tyre Row
    const renderFourTyre = (id, index) => {
        return (_jsxs("div", { className: "d-flex justify-content-between align-items-center height-60", children: [_jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [_jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx("div", { id: id[0], children: _jsx(FxIcon, { id: id[0], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[0]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { className: "height-60 truck-body" }), _jsx("div", { id: id[1], children: _jsx(FxIcon, { id: id[1], 
                                // onClick={(event) => this.onSvgTyreClick(id[1], event)}
                                fill: getTyreFill(id[1]), icon: "tyre", className: 'mx-1' }) }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' })] }), _jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [_jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx("div", { id: id[2], children: _jsx(FxIcon, { id: id[2], 
                                // onClick={(event) => this.onSvgTyreClick(id[2], event)}
                                fill: getTyreFill(id[2]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { className: "height-60 truck-body" }), _jsx("div", { id: id[3], children: _jsx(FxIcon, { id: id[3], 
                                // onClick={(event) => this.onSvgTyreClick(id[3], event)}
                                fill: getTyreFill(id[3]), icon: "tyre", className: 'mx-1' }) }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' })] }), _jsx("div", { style: { width: '1%' } })] }, `rowFour-${id[0]}-${index}-${vehicleId}`));
    };
    const renderSixTyre = (id, index) => {
        return (_jsxs("div", { className: "d-flex justify-content-between align-items-center height-60", children: [_jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [_jsx("div", { id: id[0], children: _jsx(FxIcon, { id: id[0], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[0]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { id: id[1], children: _jsx(FxIcon, { id: id[1], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[1]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { className: "height-60 truck-body" }), _jsx("div", { id: id[2], children: _jsx(FxIcon, { id: id[2], 
                                // onClick={(event) => this.onSvgTyreClick(id[1], event)}
                                fill: getTyreFill(id[2]), icon: "tyre", className: 'mx-1' }) }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' })] }), _jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [_jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx("div", { id: id[3], children: _jsx(FxIcon, { id: id[3], 
                                // onClick={(event) => this.onSvgTyreClick(id[2], event)}
                                fill: getTyreFill(id[3]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { className: "height-60 truck-body" }), _jsx("div", { id: id[4], children: _jsx(FxIcon, { id: id[4], 
                                // onClick={(event) => this.onSvgTyreClick(id[3], event)}
                                fill: getTyreFill(id[4]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { id: id[5], children: _jsx(FxIcon, { id: id[5], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[5]), icon: "tyre", className: 'mx-1' }) })] }), _jsx("div", { style: { width: '1%' } })] }, `rowFour-${id[0]}-${index}-${vehicleId}`));
    };
    const renderEightTyre = (id, index) => {
        return (_jsxs("div", { className: "d-flex justify-content-between align-items-center height-60", children: [_jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [_jsx("div", { id: id[0], children: _jsx(FxIcon, { id: id[0], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[0]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { id: id[1], children: _jsx(FxIcon, { id: id[1], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[1]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { className: "height-60 truck-body" }), _jsx("div", { id: id[2], children: _jsx(FxIcon, { id: id[2], 
                                // onClick={(event) => this.onSvgTyreClick(id[1], event)}
                                fill: getTyreFill(id[2]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { id: id[3], children: _jsx(FxIcon, { id: id[3], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[3]), icon: "tyre", className: 'mx-1' }) })] }), _jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [_jsx("div", { id: id[4], children: _jsx(FxIcon, { id: id[4], 
                                // onClick={(event) => this.onSvgTyreClick(id[2], event)}
                                fill: getTyreFill(id[4]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { id: id[5], children: _jsx(FxIcon, { id: id[5], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[5]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { className: "height-60 truck-body" }), _jsx("div", { id: id[6], children: _jsx(FxIcon, { id: id[6], 
                                // onClick={(event) => this.onSvgTyreClick(id[3], event)}
                                fill: getTyreFill(id[6]), icon: "tyre", className: 'mx-1' }) }), _jsx("div", { id: id[7], children: _jsx(FxIcon, { id: id[7], 
                                // onClick={(event) => this.onSvgTyreClick(id[0], event)}
                                fill: getTyreFill(id[7]), icon: "tyre", className: 'mx-1' }) })] }), _jsx("div", { style: { width: '1%' } })] }, `rowFour-${id[0]}-${index}-${vehicleId}`));
    };
    const renderEmptyRow = (item, index) => {
        return (_jsxs("div", { className: "d-flex justify-content-between align-items-center height-25", children: [_jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-between align-items-center", children: [_jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx("div", { className: "height-25 truck-body" }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' })] }), _jsx("div", { style: { width: '1%' } }), _jsxs("div", { className: "d-flex justify-content-between align-items-center", children: [_jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' }), _jsx("div", { className: "height-25 truck-body" }), _jsx(FxIcon, { icon: "tyre", className: 'mx-1 invisible' })] }), _jsx("div", { style: { width: '1%' } })] }, `rowEmpty-${item}-${index}-${vehicleId}`));
    };
    const renderFooter = (code, item) => {
        return (_jsxs("div", { className: "d-flex justify-content-around align-items-top", children: [_jsx("div", { style: { width: '1%' } }), _jsx("div", { className: 'w-50 truck-footer' }), _jsx("div", { style: { width: '1%' } })] }, `rowFooter-${code}-${item}-${vehicleId}`));
    };
    const renderStepneyRow = (code, item) => {
        let stepneyTyre = [];
        const { tyreConfigObj, noOfStepney } = currentVehicleDetails;
        for (let i = 1; i <= noOfStepney; i++) {
            stepneyTyre.push(_jsx(FxIcon, { id: tyreConfigObj?.positions?.[item + i - 1]?.id, 
                // onClick={(event) => this.onSvgTyreClick(item + i, event)}
                fill: getTyreFill(item + i), icon: "tyre", stepney: true, className: 'mx-1' }, `stepney-icon-${tyreConfigObj.positions[item + i - 1].id}-${vehicleId}`));
        }
        return (_jsxs("div", { className: "d-flex justify-content-between align-items-center height-25", children: [_jsx("div", { style: { width: '1%' } }), _jsx("div", { className: "d-flex justify-content-between align-items-center", children: stepneyTyre }), _jsx("div", { style: { width: '1%' } })] }, `rowEmpty-${item}-${vehicleId}`));
    };
    const renderTruck = (code) => {
        const { tyreConfigObj } = currentVehicleDetails;
        let selectedTyreConfiguration = tyreConfigObj;
        if (code) {
            const rowsString = dropRight(code.split(/_/)).join();
            let truckRow = [], tyrePosId = 0;
            truckRow.push(renderHeader(code, tyrePosId));
            map([...rowsString], (c, index) => {
                if (c === '2') {
                    truckRow.push(renderTwoTyre([
                        selectedTyreConfiguration.positions[tyrePosId].id,
                        selectedTyreConfiguration.positions[tyrePosId + 1].id,
                    ], index));
                    tyrePosId = tyrePosId + 2;
                }
                if (c === '4') {
                    truckRow.push(renderFourTyre([
                        selectedTyreConfiguration.positions[tyrePosId].id,
                        selectedTyreConfiguration.positions[tyrePosId + 1].id,
                        selectedTyreConfiguration.positions[tyrePosId + 2].id,
                        selectedTyreConfiguration.positions[tyrePosId + 3].id,
                    ], index));
                    tyrePosId = tyrePosId + 4;
                }
                if (c == '6') {
                    truckRow.push(renderSixTyre([
                        selectedTyreConfiguration.positions[tyrePosId].id,
                        selectedTyreConfiguration.positions[tyrePosId + 1].id,
                        selectedTyreConfiguration.positions[tyrePosId + 2].id,
                        selectedTyreConfiguration.positions[tyrePosId + 3].id,
                        selectedTyreConfiguration.positions[tyrePosId + 4].id,
                        selectedTyreConfiguration.positions[tyrePosId + 5].id,
                    ], index));
                    tyrePosId = tyrePosId + 6;
                }
                if (c == '8') {
                    truckRow.push(renderEightTyre([
                        selectedTyreConfiguration.positions[tyrePosId].id,
                        selectedTyreConfiguration.positions[tyrePosId + 1].id,
                        selectedTyreConfiguration.positions[tyrePosId + 2].id,
                        selectedTyreConfiguration.positions[tyrePosId + 3].id,
                        selectedTyreConfiguration.positions[tyrePosId + 4].id,
                        selectedTyreConfiguration.positions[tyrePosId + 5].id,
                        selectedTyreConfiguration.positions[tyrePosId + 6].id,
                        selectedTyreConfiguration.positions[tyrePosId + 7].id,
                    ], index));
                    tyrePosId = tyrePosId + 8;
                }
                if (c === ',') {
                    truckRow.push(renderEmptyRow(tyrePosId, index));
                }
            });
            truckRow.push(renderFooter(code, tyrePosId));
            truckRow.push(renderStepneyRow(code, tyrePosId));
            return truckRow;
        }
        return _jsx("div", {});
    };
    const renderTyreConfig = () => {
        let showTyreGrid = false;
        const { tyreDetails, existingTyrePositions, tyreConfigObj, fetchingAttachedTyres, noOfStepney, } = currentVehicleDetails;
        if (!tyreConfigObj && !fetchingAttachedTyres) {
            showTyreGrid = false;
            return (_jsx("div", { className: "d-flex h-100 flex-column justify-content-center", children: _jsxs(Row, { className: "align-items-center", children: [_jsx(Col, { md: 6, children: "Hey, you haven't attached any configuration to this vehicle" }), _jsxs(Col, { md: 6, className: "justify-content-end text-right ", children: [_jsx(Link, { className: "align-self-center", style: { lineHeight: 2.5 }, to: "/dashboard/settings/tyreconfiguration/add", children: "Add New Configuration" }), _jsxs(Button, { color: !collapse ? 'success' : 'secondary', onClick: collapseToggle, className: "float-right ml-2", children: [_jsx("i", { className: !collapse ? 'fa fa-arrow-down pr-2' : 'fa fa-arrow-up pr-2' }), "Attach Tyre Configuration"] })] }), _jsxs(Collapse, { className: "w-100 p-3", isOpen: collapse, children: [_jsx(FxReactSelect, { className: "pb-3", simpleValue: true, value: get(tyreListConfigurationsSelections, tyreListIndex, null), onChange: (val) => setTyreListConfigurationsSelections({
                                        ...tyreListConfigurationsSelections,
                                        [tyreListIndex]: val,
                                    }), options: tyreConfigurationListOptions, clearable: true }), (isManageTyre(props.login.scope) || isUpdateTyresAllowedForUser(props.login.scope)) && (_jsx("div", { className: "d-flex flex-row justify-content-end align-items-center", children: _jsxs(Button, { color: 'success', className: "mr-2", onClick: () => handleConfigAttach(get(tyreListConfigurationsSelections, tyreListIndex, null)), children: [' ', "Attach", ' '] }) }))] })] }) }));
        }
        showTyreGrid = true;
        let rows = 0;
        let id = 0;
        const rowsString = tyreConfigObj ? dropRight(tyreConfigObj?.code.split(/_/)).join() : [];
        const isSixOrEightTyreAxle = !!(includes(rowsString, '6') || includes(rowsString, '8'));
        [...rowsString].map((c) => {
            if (c === ',') {
            }
            else {
                rows++;
            }
        });
        const noOfRows = rows + 1;
        let row = 1;
        let tyreRow = [];
        // flex property: order no. decides where the tyre-item[tyreRow] or the truck component[truckRow] will show.
        if (showTyreGrid) {
            const flexClass = `tyre-col-${noOfRows}`;
            const style = isViewportMDOrAbove() ? { width: '20%' } : { width: '25%' };
            if (rowsString && noOfRows) {
                [...rowsString].map((c, index) => {
                    if (c === '2') {
                        for (let i = 1; i < (isSixOrEightTyreAxle ? 9 : 5); i++) {
                            tyreRow.push(_jsx("div", { style: style, className: `${flexClass} o${(isSixOrEightTyreAxle && i > 4) || (!isSixOrEightTyreAxle && i > 2) ? i + 1 : i} `, children: _jsx("div", { className: "d-flex h-100 flex-column justify-content-center", children: (isSixOrEightTyreAxle && (i === 2 || i === 7)) ||
                                        (!isSixOrEightTyreAxle && (i === 1 || i === 4)) ? (_jsx("div", { id: tyreConfigObj?.positions[id]?.id, children: fetchingAttachedTyres ? (_jsx(FxSkeleton, { className: 'mt-1 ml-2  col-md-6' })) : includes(existingTyrePositions, toSafeInteger(tyreConfigObj?.positions?.[id]?.id)) ? (getTyreDisplayDetails(tyreConfigObj?.positions?.[id]?.id, tyreDetails)) : tyreFlow === 'ISSUANCE' ? (getAddTyreCell(id)) : (_jsx("div", { children: _jsx("i", { 
                                                //id={tyreConfigObj?.positions?.[id]?.id}
                                                className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) })) : (_jsx("div", { children: _jsx("i", { 
                                            //id={tyreConfigObj?.positions?.[id].id}
                                            className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) }, `flex-${id}-${i}-${vehicleId}`) }, `flexOuter-${id}-${i}-${vehicleId}`));
                            if ((isSixOrEightTyreAxle && (i == 2 || i == 7)) ||
                                (!isSixOrEightTyreAxle && (i == 1 || i == 4))) {
                                id++;
                            }
                        }
                        row++;
                    }
                    if (c === '4') {
                        for (var i = 1; i < (isSixOrEightTyreAxle ? 9 : 5); i++) {
                            tyreRow.push(_jsx("div", { style: style, className: `${flexClass} o${(isSixOrEightTyreAxle && i > 4) || (!isSixOrEightTyreAxle && i > 2) ? i + 1 : i}`, children: _jsx("div", { className: "d-flex h-100 flex-column justify-content-center", children: (isSixOrEightTyreAxle && (i === 2 || i === 3 || i === 6 || i === 7)) ||
                                        !isSixOrEightTyreAxle ? (_jsx("div", { id: tyreConfigObj?.positions?.[id]?.id, children: fetchingAttachedTyres ? (_jsx(FxSkeleton, { className: 'mt-1 ml-2  col-md-6' })) : includes(existingTyrePositions, parseInt(tyreConfigObj?.positions[id]?.id)) ? (getTyreDisplayDetails(tyreConfigObj?.positions?.[id]?.id, tyreDetails)) : tyreFlow === 'ISSUANCE' ? (getAddTyreCell(id)) : (_jsx("div", { children: _jsx("i", { 
                                                //id={tyreConfigObj?.positions?.[id]?.id}
                                                className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) })) : (_jsx("div", { children: _jsx("i", { 
                                            //id={tyreConfigObj?.positions?.[id].id}
                                            className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) }, `flex-${id}-${i}-${vehicleId}`) }, `flexOuter-${id}-${i}-${vehicleId}`));
                            if ((isSixOrEightTyreAxle && (i === 2 || i === 3 || i === 6 || i === 7)) ||
                                !isSixOrEightTyreAxle) {
                                id++;
                            }
                        }
                        row++;
                    }
                    if (c === '6') {
                        for (var i = 1; i < 9; i++) {
                            tyreRow.push(_jsx("div", { style: style, className: `${flexClass} o${(isSixOrEightTyreAxle && i > 4) || (!isSixOrEightTyreAxle && i > 2) ? i + 1 : i}`, children: _jsx("div", { className: "d-flex h-100 flex-column justify-content-center", children: i == 1 || i === 2 || i === 3 || i === 6 || i === 7 || i === 8 ? (_jsx("div", { id: tyreConfigObj?.positions?.[id]?.id, children: fetchingAttachedTyres ? (_jsx(FxSkeleton, { className: 'mt-1 ml-2  col-md-6' })) : includes(existingTyrePositions, parseInt(tyreConfigObj?.positions[id]?.id)) ? (getTyreDisplayDetails(tyreConfigObj?.positions?.[id]?.id, tyreDetails)) : tyreFlow === 'ISSUANCE' ? (getAddTyreCell(id)) : (_jsx("div", { children: _jsx("i", { 
                                                //id={tyreConfigObj?.positions?.[id]?.id}
                                                className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) })) : (_jsx("div", { children: _jsx("i", { 
                                            //id={tyreConfigObj?.positions?.[id].id}
                                            className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) }, `flex-${id}-${i}-${vehicleId}`) }, `flexOuter-${id}-${i}-${vehicleId}`));
                            if (i === 1 || i === 2 || i === 3 || i === 6 || i === 7 || i === 8) {
                                id++;
                            }
                        }
                        row++;
                    }
                    if (c === '8') {
                        for (var i = 1; i < 9; i++) {
                            tyreRow.push(_jsx("div", { style: style, className: `${flexClass} o${(isSixOrEightTyreAxle && i > 4) || (!isSixOrEightTyreAxle && i > 2) ? i + 1 : i}`, children: _jsx("div", { className: "d-flex h-100 flex-column justify-content-center", children: _jsx("div", { id: tyreConfigObj?.positions?.[id]?.id, children: fetchingAttachedTyres ? (_jsx(FxSkeleton, { className: 'mt-1 ml-2  col-md-6' })) : includes(existingTyrePositions, parseInt(tyreConfigObj?.positions[id]?.id)) ? (getTyreDisplayDetails(tyreConfigObj?.positions?.[id]?.id, tyreDetails)) : tyreFlow === 'ISSUANCE' ? (getAddTyreCell(id)) : (_jsx("div", { children: _jsx("i", { 
                                                //id={tyreConfigObj?.positions?.[id]?.id}
                                                className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) }) }, `flex-${id}-${i}-${vehicleId}`) }, `flexOuter-${id}-${i}-${vehicleId}`));
                            id++;
                        }
                        row++;
                    }
                });
            }
            for (var i = 1; i < (isSixOrEightTyreAxle ? 9 : 5) && noOfStepney > 0; i++) {
                tyreRow.push(_jsx("div", { style: style, className: `${flexClass} o${(isSixOrEightTyreAxle && i > 4) || (!isSixOrEightTyreAxle && i > 2) ? i + 1 : i}`, children: _jsx("div", { className: "d-flex h-100 flex-column justify-content-center", children: _jsx("div", { id: tyreConfigObj?.positions?.[id]?.id, children: (isSixOrEightTyreAxle &&
                                (i === 1 ||
                                    i === 3 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 8 ||
                                    (noOfStepney === 1 && i === 7))) ||
                                (!isSixOrEightTyreAxle && (i === 2 || i === 3 || (noOfStepney === 1 && i === 4))) ? (_jsx("div", { children: _jsx("i", { id: tyreConfigObj?.positions?.[id]?.id, className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) : fetchingAttachedTyres ? (_jsx(FxSkeleton, { className: 'mt-1 ml-2  col-md-6' })) : includes(existingTyrePositions, parseInt(tyreConfigObj?.positions?.[id]?.id)) ? (getTyreDisplayDetails(tyreConfigObj?.positions?.[id]?.id, tyreDetails)) : tyreFlow === 'ISSUANCE' ? (getAddTyreCell(id)) : (_jsx("div", { children: _jsx("i", { id: tyreConfigObj?.positions?.[id]?.id, className: "fa fa-ban text-muted" }) }, `button-${id}-${i}-${vehicleId}`)) }, `button-${id}-${vehicleId}`) }) }, `flexOuter-${id + i}-${i}-${vehicleId}`));
                if (((isSixOrEightTyreAxle && i === 2) || (!isSixOrEightTyreAxle && i === 1)) && noOfStepney > 1) {
                    id++;
                }
            }
        }
        if (showTyreGrid) {
            tyreRow.push(_jsx("div", { className: isSixOrEightTyreAxle
                    ? 'tyre-img o5 align-items-center d-none d-md-block d-sm-none'
                    : 'tyre-img o3 align-items-center d-none d-md-block d-sm-none', children: _jsx("div", { className: "d-flex h-100 flex-column justify-content-center", children: renderTruck(tyreConfigObj?.code) }) }, `tyreRow-truck-${tyreConfigObj?.code}-${vehicleId}`));
        }
        return tyreRow;
    };
    return (_jsx("div", { style: { height: `${getGridHeight(noOfRows)}px`, overflowX: 'scroll' }, className: "tyre-flex align-items-center", children: fetchingAttachedTyres ? _jsx(Loader, {}) : renderTyreConfig() }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        attachTyreConfigurationToVehicle,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(VehicleTyreConfigComponent);
