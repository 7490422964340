import axios from 'axios';
import { ROOT_API_ALFRED, parseQueryParams } from '../../../constant';
export const FETCH_UNITS = 'FETCH_UNITS';
export const CREATE_UNITS = 'CREATE_UNITS';
export const UPDATE_UNITS = 'UPDATE_UNITS';
export function fetchUnits(accesstoken, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/units`, config);
    return {
        type: FETCH_UNITS,
        promise: request,
    };
}
export function createUnits(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ALFRED}/units/bulk-create`, data, config);
    return {
        type: CREATE_UNITS,
        promise: request,
    };
}
export function updateUnits(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ALFRED}/units/bulk-update`, data, config);
    return {
        type: UPDATE_UNITS,
        promise: request,
    };
}
