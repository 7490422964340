import { handle } from 'redux-pack';
import {
    FETCH_COMMENTS_BY_TYPE_ID,
    FETCH_LATEST_COMMENTS,
    SEARCH_COMMENTS,
} from '../../actions/dashboard/easy/comment_action';

const initialState = {
    isLoading: false,
    error: null,
    isFinished: false,
    commentsBySearch: [],
    isCommentsBySearchLoading: false,
};

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_COMMENTS_BY_TYPE_ID:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    comments: payload.data,
                }),
            });
        case FETCH_LATEST_COMMENTS:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    latestComments: payload.data,
                }),
            });
        case SEARCH_COMMENTS:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isCommentsBySearchLoading: true,
                }),
                finish: prevState => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isCommentsBySearchLoading: false,
                }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    commentsBySearch: payload.data.content,
                }),
            });
        default:
            return state;
    }
}
