import { handle } from 'redux-pack';
import { CREATE_INTERNAL_TAGS_BULK, CREATE_INTERNAL_TAG, UPDATE_INTERNAL_TAG, DELETE_INTERNAL_TAG, FETCH_INTERNAL_TAGS, FETCH_INTERNAL_TAG_BY_ID, } from '../../../actions/dashboard/internal_tools/tags_action';
const initialState = {
    isLoading: false,
    error: null,
    list: [],
    isListLoading: false,
    isTagLoading: false,
    tag: {},
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_INTERNAL_TAG:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case CREATE_INTERNAL_TAGS_BULK:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_INTERNAL_TAG:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_INTERNAL_TAG:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_INTERNAL_TAGS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    list: payload.data.tags,
                }),
            });
        case FETCH_INTERNAL_TAG_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isTagLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isTagLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, tag: payload.data }),
            });
        default:
            return state;
    }
}
