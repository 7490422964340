import axios from 'axios';
import { ROOT_API_URL, parseQueryParams } from '../../../constant';
import { isViewCustomerAllowedForUser } from '../../../components/dashboard/roles/permission_utils';

export const CREATE_CUSTOMER = 'create_customer';
export const UPDATE_CUSTOMER = 'update_customer';
export const DELETE_CUSTOMER = 'delete_customer';
export const FETCH_CUSTOMERS = 'fetch_customers';
export const FETCH_CUSTOMER_SUMMARY = 'fetch_customer_summary';
export const FETCH_CUSTOMER_PARTY = 'fetch_customer_party';
export const UPDATE_CUSTOMER_PARTY = 'update_customer_party';
export const ARCHIVE_UNARCHIVE_CUSTOMER = 'archive_unarchive_customer';
export const FETCH_CUSTOMER_REPORT = 'fetch_customer_report';

export function createCustomer(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}customers/`, values, config);
    return {
        type: CREATE_CUSTOMER,
        promise: request,
    };
}

export function updateCustomer(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}customers/`, values, config);

    return {
        type: UPDATE_CUSTOMER,
        promise: request,
    };
}

export function deleteCustomer(accesstoken, customerId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}customers/${customerId}`, config);

    return {
        type: DELETE_CUSTOMER,
        promise: request,
    };
}

export function fetchCustomers(accesstoken, accountId, enabled, fetchAll = false) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewCustomerAllowedForUser(scope)) {
        return {
            type: FETCH_CUSTOMERS,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            enabled,
            fetchAll,
        }),
    };
    const size = 40000;
    const request = axios.get(`${ROOT_API_URL}customers/?size=${size}`, config);

    return {
        type: FETCH_CUSTOMERS,
        promise: request,
    };
}

export function fetchCustomerSummary(accesstoken, customerId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}customers/${customerId}`, config);

    return {
        type: FETCH_CUSTOMER_SUMMARY,
        promise: request,
    };
}
export function fetchCustomerParty(accesstoken, customerId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}customers/party/${customerId}`, config);

    return {
        type: FETCH_CUSTOMER_PARTY,
        promise: request,
    };
}

export function updateCustomerParty(accesstoken, values, customerId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}customers/party/`, values, config);

    return {
        type: UPDATE_CUSTOMER_PARTY,
        promise: request,
    };
}

export function archiveUnarchiveCustomer(accesstoken, id, enabled) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
            enabled,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL}customers/archive-unarchive/`, null, config);

    return {
        type: ARCHIVE_UNARCHIVE_CUSTOMER,
        promise: request,
    };
}
export function triggerCustomerReport(accesstoken, params) {
    const { enabled } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            enabled,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}reports/customer`, config);

    return {
        type: FETCH_CUSTOMER_REPORT,
        promise: request,
    };
}
