import { handle } from 'redux-pack';
import {
    ADD_JOB_BOOKING,
    UPDATE_JOB_BOOKING,
    FETCH_JOB_BOOKING,
    FETCH_JOB_BOOKING_WITH_SUMMARY,
    FETCH_JOB_BOOKING_AUTOCOMPLETE,
    DELETE_JOB_BOOKING,
    FETCH_JOB_BOOKING_SUMMARY,
    FETCH_JOB_BOOKING_LISTING,
} from '../../../actions/dashboard/route_dispatch/job_booking_action';

const initialState = {
    isLoading: false,
    error: null,
    data: {},
    jobBookingWithSummary: {},
    jobBookingSummary: {},
    jobBookingListing: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_JOB_BOOKING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_JOB_BOOKING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });

        case FETCH_JOB_BOOKING_WITH_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, isLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    jobBookingWithSummary: payload.data,
                }),
            });
        case FETCH_JOB_BOOKING_AUTOCOMPLETE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, isLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    jobBookingAutocomplete: payload.data,
                }),
            });
        case FETCH_JOB_BOOKING_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, isLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    jobBookingSummary: payload.data,
                }),
            });

        case FETCH_JOB_BOOKING_LISTING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, isLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    jobBookingListing: payload.data,
                }),
            });

        case FETCH_JOB_BOOKING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        case DELETE_JOB_BOOKING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });

        default:
            return state;
    }
}
