import axios from 'axios';
import { map } from 'lodash';
import { parseQueryParams, ROOT_API_URL } from '../../constant';
import { isRealtimeVisible } from '../../components/dashboard/roles/permission_utils';

export const FETCH_LIVE_ODOMETER = 'fetch_live_odometer';
export const FETCH_LIVE_VEHICLE_DATA = 'fetch_live_vehicle_data';

export function fetchLiveOdometer(accesstoken, groupId, vehicleId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };

    const groupQuery = !vehicleId && groupId && groupId != -1 ? `?groupId=${groupId}` : '';
    const vehicleQuery = vehicleId ? `?vehicleId=${vehicleId}` : '';
    const request = axios.get(`${ROOT_API_URL}analytics/live/odometer${groupQuery}${vehicleQuery}`, config);
    return {
        type: FETCH_LIVE_ODOMETER,
        promise: request,
    };
}
/**
 *
 * @param {*} accesstoken
 * @param {*} vehicleIds
 * @param {*} [accountId]
 * @returns
 */

export function fetchLiveVehicleData(accesstoken, vehicleIds, accountId = null) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isRealtimeVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_LIVE_VEHICLE_DATA,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const vehiclesQuery = vehicleIds ? `?vehicleIds=${vehicleIds}` : '';
    const request = axios.get(`${ROOT_API_URL}analytics/live/vehicles${vehiclesQuery}`, config);
    return {
        type: FETCH_LIVE_VEHICLE_DATA,
        promise: request,
    };
}
