import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FxEditor from './fx_editor';
import { get } from 'lodash';
const FxEditorFormik = (passedProps) => {
    const { field, form, onChange, label, editorHeight, editorMinHeight, className, isRequired, showLabelBySide, disabled, ...editorProps } = passedProps;
    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');
    const errorClass = isTouched && error ? 'form-control-label text-danger' : '';
    const labelClass = `${isTouched && error ? 'text-danger' : ''}`;
    const onChangeHandler = (editorState) => {
        form.setFieldValue(field.name, editorState);
        if (onChange) {
            onChange(editorState);
        }
    };
    const onBlurHandler = () => {
        form.setFieldTouched(field.name);
    };
    return (_jsxs("div", { className: `form-group ${className} ${showLabelBySide ? `flex-row d-flex mt-3 align-items-center` : ''}`, children: [label && (_jsx("div", { className: `${errorClass} ${showLabelBySide ? 'col-md-3 pl-0 mt-3' : ''} `, children: _jsxs("label", { htmlFor: field.name, className: labelClass, children: [label, " ", isRequired && _jsx("abbr", { className: "text-danger", children: "*" })] }) })), _jsxs("div", { className: `${showLabelBySide ? 'col-md-9' : ''}`, children: [_jsx(FxEditor, { editorState: field.value, onChange: onChangeHandler, editorHeight: editorHeight, editorMinHeight: editorMinHeight, editorProps: {
                            readOnly: disabled,
                            ...editorProps,
                            onBlur: onBlurHandler,
                        } }), isTouched && error && _jsx("div", { className: "text-help text-danger", children: error })] })] }));
};
export default FxEditorFormik;
