import { FETCH_STORES, FETCH_STORES_AUTOCOMPLETE, FETCH_STORE_SUMMARY } from 'actions/dashboard/client_stores_actions';
import { map } from 'lodash';
import { handle } from 'redux-pack';
const initialState = {
    isLoading: false,
    error: null,
    data: [],
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_STORES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    stores: payload?.data?.content,
                    totalElements: payload?.data?.totalElements,
                }),
            });
        case FETCH_STORES_AUTOCOMPLETE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    storeList: payload.data,
                    storeListAsMap: new Map(map(payload.data, (store) => [store.id, store])),
                }),
            });
        case FETCH_STORE_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    storeSummary: payload?.data?.content[0],
                }),
            });
        default:
            return state;
    }
}
