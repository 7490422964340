import CalendarSingleDatePicker from '../components/utils/calendar_single_date_picker';

export default (props) => {
    const {
        label,
        isRequired,
        meta: { touched, error },
        outerDivStyle,
        labelDivStyle = '',
        style = {
            width: '100%',
            height: '80px',
        },
    } = props;

    const onStartDateSelected = (startDate) => {
        props.input.onChange(startDate);
        if (props.handleOnDateSelected) {
            props.handleOnDateSelected(startDate);
        }
    };
    return (
        <div className={outerDivStyle}>
            {label && (
                <div className={error ? 'form-control-label text-danger' : `${labelDivStyle}`}>
                    <label>{label}</label>
                    {isRequired && <abbr className="text-danger">*</abbr>}
                </div>
            )}
            <CalendarSingleDatePicker
                {...props}
                onDateSelected={onStartDateSelected}
                startDate={props.input.value || props.input.value === 0 ? props.input.value : null}
            />
            {error && <div className="text-help text-danger">{error}</div>}
        </div>
    );
};
