import { forEach, toSafeInteger } from 'lodash';

const MAX_THRESHOLD_VALUE = 80;

export const recalculateRanges = (newThresholdList, form, updateValues = false) => {
    const newRange = [];
    forEach(newThresholdList, (elem, index) => {
        if (toSafeInteger(index) === 0) {
            newRange[0] = elem;
        } else {
            const prevMax = newRange[toSafeInteger(index) - 1].maxThreshold;
            if (prevMax != MAX_THRESHOLD_VALUE) {
                newRange[index] = {
                    minThreshold: prevMax,
                    maxThreshold: elem.maxThreshold <= prevMax ? prevMax + 10 : elem.maxThreshold,
                    type: 'SPEED',
                };
                if (elem.id) {
                    newRange[index].id = elem.id;
                }
            }
        }
    });
    if (updateValues) {
        form.setValues({ thresholdList: newRange });
    }
};
