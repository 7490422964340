import axios from 'axios';
import { ROOT_API_URL } from '../../../constant';
export const CREATE_PART_CATEGORY = 'create_part_category';
export const UPDATE_PART_CATEGORY = 'update_part_category';
export const DELETE_PART_CATEGORY = 'delete_part_category';
export const FETCH_PART_CATEGORIES = 'fetch_part_categories';
export const FETCH_PART_CATEGORY_BY_ID = 'fetch_part_category_by_id';
export function createPartCategory(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}part-category`, data, config);
    return {
        type: CREATE_PART_CATEGORY,
        promise: request,
    };
}
export function updatePartCategory(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}part-category`, data, config);
    return {
        type: UPDATE_PART_CATEGORY,
        promise: request,
    };
}
export function fetchPartCategories(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}part-category`, config);
    return {
        type: FETCH_PART_CATEGORIES,
        promise: request,
    };
}
export function fetchPartCategoryById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}part-category/${id}`, config);
    return {
        type: FETCH_PART_CATEGORY_BY_ID,
        promise: request,
    };
}
export function deletePartCategory(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}part-category/${id}`, config);
    return {
        type: DELETE_PART_CATEGORY,
        promise: request,
    };
}
