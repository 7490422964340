import axios from 'axios';
import { ROOT_API_URL } from '../../constant';
import { isViewAccountModulesAllowedForUser } from '../../components/dashboard/roles/permission_utils';
export const CREATE_CUSTOM_MODULE = 'create_custom_module';
export const FETCH_MODULES = 'fetch_modules';
export const UPDATE_CUSTOM_MODULE = 'update_custom_role';
export function createCustomModule(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}modules/bulk`, values, config);
    return {
        type: CREATE_CUSTOM_MODULE,
        promise: request,
    };
}
export function updateCustomModule(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}modules/bulk/${id}`, values, config);
    return {
        type: UPDATE_CUSTOM_MODULE,
        promise: request,
    };
}
export function fetchModules(accesstoken) {
    //@ts-ignore
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    //@ts-ignore
    if (!isViewAccountModulesAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_MODULES,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
    };
    const request = axios.get(`${ROOT_API_URL}modules`, config);
    return {
        type: FETCH_MODULES,
        promise: request,
    };
}
