import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../../constant';
import { sendAppAlertToSlack } from '../../../../utils/analytics_utils';

export const CREATE_PAYMENT_ORDER = 'create_payment_order';
export const AUTHORIZE_RAZORPAY_PAYMENT = 'authorize_razorpay_payment';
export const FETCH_RECENT_PAYMENTS = 'fetch_recent_payments';

export function createPaymentOrder(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}`, 'Content-Type': 'application/json' },
    };

    const request = axios.post(`${ROOT_API_URL}payments/initiate`, data, config);

    return {
        type: CREATE_PAYMENT_ORDER,
        promise: request,
    };
}
export function authorizePayment(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}`, 'Content-Type': 'application/json' },
    };

    sendAppAlertToSlack(`Authorizing Response: ${JSON.stringify(data)})}`, '#payment-alert');

    const request = axios.post(`${ROOT_API_URL}payments/authorize`, data, config);

    return {
        type: AUTHORIZE_RAZORPAY_PAYMENT,
        promise: request,
    };
}
export function fetchRecentTransactions(accesstoken, from, to, page, size, sort) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}`, 'Content-Type': 'application/json' },
        params: parseQueryParams({
            from,
            to,
            page,
            size,
            sort,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}payments/transactions`, config);

    return {
        type: FETCH_RECENT_PAYMENTS,
        promise: request,
    };
}
