import { jsx as _jsx } from "react/jsx-runtime";
import { FxFilterOutlined } from '../../../sharedV2/FxIcons';
import { FxButton } from '../../../sharedV2';
import { useTranslation } from 'react-i18next';
const MoreFiltersButtonComponent = (props) => {
    const { t } = useTranslation();
    const { label } = props;
    return (_jsx(FxButton, { icon: _jsx(FxFilterOutlined, {}), ...props, children: label || t('filters') }));
};
export default MoreFiltersButtonComponent;
