import { jsx as _jsx } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRoute } from 'components/dashboard/customHocs/withRoute';
import { isSwitchVehicles } from '../../roles/permission_utils';
import { get } from 'lodash';
import AddEditServiceEntryOld from 'components/dashboard/maintenance/serviceentries/add_edit_service_entry_component';
import AddEditServiceEntry from 'components/dashboard/maintenance/serviceentries/add_edit_service_entry';
import { showOldServiceEntryForAccount } from 'utils/account_utils';
const ServiceEntryAddEditWrapper = (props) => {
    return showOldServiceEntryForAccount() ? _jsx(AddEditServiceEntryOld, {}) : _jsx(AddEditServiceEntry, {});
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    const isSwitchUserAllowed = isSwitchVehicles(get(state.login, 'data.scope'));
    return {
        isSwitchUserAllowed,
    };
}
export default withRoute(connect(mapStateToProps, mapDispatchToProps)(ServiceEntryAddEditWrapper));
