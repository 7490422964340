import {handle} from "redux-pack";
import {
  FETCH_TRIP_SHEET_WITH_SUMMARY, ADD_TRIP_SHEET, UPDATE_TRIP_SHEET, FETCH_TRIP_SHEET
} from "../../../actions/dashboard/finances/trip_sheet_action";


const initialState = {
    isLoading: false, error: null, data: {}, tripSheetWithSummary: {}
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case ADD_TRIP_SHEET :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case UPDATE_TRIP_SHEET :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });

        case FETCH_TRIP_SHEET_WITH_SUMMARY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }), finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload, isLoading: false}),
                success: prevState => ({
                    ...prevState, tripSheetWithSummary: payload.data
                }),
            });

        case FETCH_TRIP_SHEET :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({...prevState, data: {...prevState.data, [payload.data.id]: payload.data}})
            });

        default:
            return state;
    }

}