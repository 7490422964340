import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const TripsComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-trips" */ 'components/dashboard/trips/trips_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TripDetailComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-trips-view-details" */ 'components/dashboard/trips/trip_detail_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const tripsRoutes = [
    {
        index: true,
        lazy: TripsComponent,
    },
    {
        path: ':vehicleId/:tripId',
        lazy: TripDetailComponent,
    },
];
export default tripsRoutes;
