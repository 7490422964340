import React, { Component } from 'react';
import { Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import { isEqual } from 'lodash';

class PolylineDecorator extends Component {
    decorators = null;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.addDecorator();
    }

    componentWillUnmount() {
        this.removeDecorator();
    }

    addDecorator = () => {
        const { map } = this.props; //get native Leaflet map
        if (map) {
            const patterns = [
                {
                    offset: 0,
                    repeat: 80,
                    symbol: L.Symbol.arrowHead({
                        polygon: true,
                        pixelSize: 15,
                        pathOptions: {
                            stroke: true,
                            weight: 1,
                            color: this.props.color,
                        },
                    }),
                },
            ];
            const polyline = L.polyline(this.props.positions);
            this.decorators = L.polylineDecorator(polyline, {
                patterns: patterns,
            }).addTo(map);
        }
    };
    removeDecorator = () => {
        if (this.props.map && this.decorators) {
            this.decorators.removeFrom(this.props.map);
        }
    };

    componentDidUpdate(props, state) {
        if (this.props.map && (!isEqual(props.positions, this.props.positions) || (!props.map && this.props.map))) {
            this.removeDecorator();
            this.addDecorator();
        }
    }

    render() {
        return <Polyline {...this.props} />;
    }
}

export default PolylineDecorator;
