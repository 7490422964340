import { handle } from 'redux-pack';
import { omit, get } from 'lodash';
import {
    FETCH_ALERT_SETTING,
    FETCH_ALERTS_SETTINGS,
    FETCH_ALERTS_SETTINGS_TYPE,
    FETCH_ALERT_SETTING_V2,
    FETCH_ADDRESS_BOOK_ALERT_SETTINGS,
} from '../../actions/dashboard/alerts_settings_action';

const initialState = {
    isLoading: false,
    error: null,
    alertSettingsList: [],
    alertsTypeList: [],
    data: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ALERTS_SETTINGS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    alertSettingsList: payload.data,
                }),
            });
        case FETCH_ALERTS_SETTINGS_TYPE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    alertsTypeList: payload.data,
                }),
            });
        case FETCH_ALERT_SETTING:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        case FETCH_ALERT_SETTING_V2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    let alertData = get(payload, 'data', []);
                    const alertSetting = get(alertData, 'alertSetting', {});
                    const id = get(alertSetting, 'id');
                    const vehicleState = get(alertSetting, 'vehicleState')
                        ? get(alertSetting, 'vehicleState.code')
                        : null;
                    if (vehicleState) {
                        alertSetting.vehicleState = vehicleState;
                    }
                    alertData = omit(alertData, 'alertSetting', []);
                    return {
                        ...prevState,
                        data: { ...prevState.data, [id]: { ...alertData, ...alertSetting } },
                    };
                },
            });
        case FETCH_ADDRESS_BOOK_ALERT_SETTINGS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    let addressBookWiseSpeeding = get(payload, 'data', []);
                    return {
                        ...prevState,
                        data: { ...prevState.data, addressBookWiseSpeeding },
                    };
                },
            });

        default:
            return state;
    }
}
