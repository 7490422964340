import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider, Menu } from 'antd';
const FxMenu = (props) => {
    const { themeModify, ...rest } = props;
    return (_jsx(ConfigProvider, { theme: {
            components: {
                Menu: {
                    ...themeModify,
                },
            },
        }, children: _jsx(Menu, { ...rest }) }));
};
export default FxMenu;
