import { fetchCustomFieldsForAccount } from 'actions/dashboard/custom_field_actions';
import { Field, FieldArray } from 'formik';
import { upperFirst, get, map, filter, set, isEqual, find, forEach, sortBy, toLower, some } from 'lodash';
import { Component } from 'react';
import { FxSelectField, FxTextFieldStackedLabel, FxSelectSingleDate } from '../../../../../shared';
import { CUSTOM_ACCOUNT_FIELD_TYPE, getInitialCustomFieldArray } from '../../../../../utils/custom_field_utils';
import { getMomentTime, DATE_FORMAT_TIMESTAMP } from '../../../../../utils/date_utils';
import FxFieldLabelWithEditButton from 'shared/FxFieldLabelWithEditButton';
import FxTemplateFieldContainer from 'shared/FxTemplateFieldContainer';

const getFieldName = (fieldPrefix, index, name) => {
    return `${fieldPrefix}.${index}.${name}`;
};

const getNameDropDownObject = (v) => {
    const finalArray = [];

    for (let i = 0; i < v.length; i++) {
        finalArray.push({
            value: v[i].answer,
            label: v[i].answer,
        });
    }
    return finalArray;
};

export default class CustomFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allRoutePointMandatoryFields: [],
            isFetchedCustom: [],
        };
    }

    arrangeCustomFields = (fields) => {
        return map(fields, (field) => {
            return { field: { ...field }, value: '' };
        });
    };

    componentDidMount() {
        this.fetchAllCustomFields();
    }

    // shouldComponentUpdate(nextProps, nextState){
    //     if(!isEqual(nextProps.initialValues, this.props.initialValues)){
    //         return false
    //     }
    //     else if (!isEqual(nextProps, this.props) || !isEqual(this.state, nextState)){
    //         return true
    //     }
    // }

    getMandatoryFieldsType = (fields, type) => {
        return filter(fields, (customField) => {
            return customField.field.mandatory && customField.field.type === type;
        });
    };

    fetchAllCustomFields = async () => {
        const { extraParams } = this.props || {};
        const { sortingKey = 'field.id' } = extraParams || {};
        const isEdit = this.props.isEdit;
        const resp = !this.props.isApiCall
            ? this.props.allCustomFieldResp
            : await fetchCustomFieldsForAccount(this.props.accesstoken).promise;
        const allCustomFields = this.arrangeCustomFields(get(resp, 'data.customFields'));
        let filteredCustomFields = [];
        if (isEdit || this.props.shouldUseEditLogicInCF) {
            const customFieldsFromInitialVal = get(this.props.initialValues, `${this.props.fieldPrefix}`, []);
            forEach(customFieldsFromInitialVal, (cf, index) => {
                const isCFPresentInMaster = find(allCustomFields, (cfInMaster) => {
                    return (
                        get(cfInMaster, 'field.id') === get(cf, 'field.id') &&
                        get(cfInMaster, 'field.type') === this.props.type
                    );
                });
                if (!isCFPresentInMaster) {
                    customFieldsFromInitialVal.splice(index, 1);
                }
            });

            forEach(allCustomFields, (cf) => {
                const isCFPresentInsideInitialVal = find(customFieldsFromInitialVal, (cfInIntialValue) => {
                    return get(cfInIntialValue, 'field.id') == get(cf, 'field.id');
                });
                if (!isCFPresentInsideInitialVal && this.props.type == get(cf, 'field.type')) {
                    customFieldsFromInitialVal.push(cf);
                }
            });

            filteredCustomFields = this.getMandatoryFieldsType(customFieldsFromInitialVal, this.props.type);
        } else {
            filteredCustomFields = this.getMandatoryFieldsType(allCustomFields, this.props.type);
        }

        // let filteredCustomFields =
        // // isEdit && get(this.props.initialValues, `${this.props.fieldPrefix}.length`) > 0
        // //     ? this.getMandatoryFieldsType(
        // //           get(this.props.initialValues, `${this.props.fieldPrefix}`),
        // //           this.props.type
        // //       )
        // //     : this.getMandatoryFieldsType(allCustomFields, this.props.type);
        if (!isEdit) {
            filteredCustomFields = getInitialCustomFieldArray(filteredCustomFields);
        }

        filteredCustomFields = sortBy(filteredCustomFields, (field) => {
            const sortingFactor = get(field, sortingKey);
            if (typeof sortingFactor === 'string') {
                return toLower(sortingFactor?.split(' ')?.join('')) || field?.field?.id;
            } else {
                return sortingFactor;
            }
        });
        this.setState(
            {
                allRoutePointMandatoryFields: filteredCustomFields,
            },
            () => {
                if (this.props.setFieldValue) {
                    this.props.setFieldValue(`${this.props.fieldPrefix}`, filteredCustomFields);
                }
            }
        );
    };

    renderCustomField = ({
        children,
        fieldsMetaData,
        fieldId,
        defaultLabel,
        isTemplate,
        handleBooleanUpdate,
        handleTextUpdate,
        isTemplateSupport,
    }) => {
        if (isTemplateSupport) {
            return (
                <FxFieldLabelWithEditButton
                    fieldsMetaData={fieldsMetaData}
                    fieldId={fieldId}
                    defaultLabel={defaultLabel}
                    handleBooleanUpdate={handleBooleanUpdate}
                    handleTextUpdate={handleTextUpdate}
                    isTemplate={isTemplate}
                    isCustomField={true}
                >
                    {children}
                </FxFieldLabelWithEditButton>
            );
        } else {
            return <>{children}</>;
        }
    };

    render() {
        const { className, fieldPrefix, userListOptions = [] } = this.props;
        const {
            isTemplate,
            fieldsMetaData,
            handleBooleanUpdate,
            handleTextUpdate,
            isTemplateSupport = false,
            fieldIdById = false,
        } = this.props.templateHelpers || {};
        return (
            <>
                <FieldArray name={fieldPrefix}>
                    {(arrayHelpers) => {
                        return (
                            <>
                                {map(this.state.allRoutePointMandatoryFields, (field, index) => {
                                    const fieldIdForTemplate = fieldIdById
                                        ? `customField-${get(field, 'field.id')}`
                                        : getFieldName(fieldPrefix, index, 'value');

                                    return get(field, 'field.type') === this.props.type ? (
                                        <FxTemplateFieldContainer
                                            fieldsMetaData={fieldsMetaData}
                                            fieldId={fieldIdForTemplate}
                                            isTemplate={isTemplate}
                                        >
                                            <div
                                                key={`field-outer-${index}-${get(field, 'field.id')}`}
                                                className={className ? className : 'col-md-2'}
                                            >
                                                <div className="text-left">
                                                    {!isTemplateSupport && upperFirst(get(field, 'field.keyName'))}
                                                    {!isTemplateSupport && (
                                                        <sup className="text-info" style={{ fontSize: 10 }}>
                                                            Custom
                                                        </sup>
                                                    )}{' '}
                                                </div>
                                                <Field
                                                    name={getFieldName(fieldPrefix, index, 'field.id')}
                                                    component={FxTextFieldStackedLabel}
                                                    style={{
                                                        visibility: 'hidden',
                                                        maxHeight: '1px',
                                                        height: '1px',
                                                        padding: '2px',
                                                    }}
                                                    labelClass={'d-block'}
                                                    type="text"
                                                    value={get(field, 'field.id')}
                                                    key={`${get(field, 'field.keyName')}-${index}-${get(
                                                        field,
                                                        'field.id'
                                                    )}`}
                                                    disabled={this.props.disabled || false}
                                                />
                                                {get(field, 'field.fieldType') === 'MCQ_DROPDOWN'
                                                    ? this.renderCustomField({
                                                          children: (
                                                              <Field
                                                                  name={getFieldName(fieldPrefix, index, 'value')}
                                                                  component={FxSelectField}
                                                                  options={getNameDropDownObject(
                                                                      get(field, 'field.answers', [])
                                                                  )}
                                                                  style={{ minHeight: '38px', height: '38px' }}
                                                                  labelClass={'d-block'}
                                                                  type="text"
                                                                  key={`${get(field, 'field.keyName')}-${index}-${get(
                                                                      field,
                                                                      'field.id'
                                                                  )}-value`}
                                                              />
                                                          ),
                                                          isTemplate,
                                                          fieldsMetaData,
                                                          handleBooleanUpdate,
                                                          handleTextUpdate,
                                                          isTemplateSupport,
                                                          defaultLabel: upperFirst(get(field, 'field.keyName')),
                                                          fieldId: fieldIdForTemplate,
                                                      })
                                                    : get(field, 'field.fieldType') === 'USER_TYPE'
                                                    ? this.renderCustomField({
                                                          children: (
                                                              <Field
                                                                  name={getFieldName(fieldPrefix, index, 'value')}
                                                                  component={FxSelectField}
                                                                  options={userListOptions}
                                                                  style={{ minHeight: '38px', height: '38px' }}
                                                                  labelClass={'d-block'}
                                                                  type="text"
                                                                  key={`${get(field, 'field.keyName')}-${index}-${get(
                                                                      field,
                                                                      'field.id'
                                                                  )}-value`}
                                                              />
                                                          ),
                                                          isTemplate,
                                                          fieldsMetaData,
                                                          handleBooleanUpdate,
                                                          handleTextUpdate,
                                                          isTemplateSupport,
                                                          defaultLabel: upperFirst(get(field, 'field.keyName')),
                                                          fieldId: fieldIdForTemplate,
                                                      })
                                                    : get(field, 'field.fieldType') === 'BOOLEAN'
                                                    ? this.renderCustomField({
                                                          children: (
                                                              <Field
                                                                  name={getFieldName(fieldPrefix, index, 'value')}
                                                                  component="input"
                                                                  type="checkbox"
                                                                  style={{ minHeight: '38px', height: '38px' }}
                                                                  labelClass={'d-block'}
                                                                  key={`${get(field, 'field.keyName')}-${index}-${get(
                                                                      field,
                                                                      'field.id'
                                                                  )}-value`}
                                                                  disabled={this.props.disabled || false}
                                                              />
                                                          ),
                                                          isTemplate,
                                                          fieldsMetaData,
                                                          handleBooleanUpdate,
                                                          handleTextUpdate,
                                                          isTemplateSupport,
                                                          defaultLabel: upperFirst(get(field, 'field.keyName')),
                                                          fieldId: fieldIdForTemplate,
                                                      })
                                                    : get(field, 'field.fieldType') === 'DATE'
                                                    ? this.renderCustomField({
                                                          children: (
                                                              <Field
                                                                  valueFormatter={(value) =>
                                                                      getMomentTime(value).format(DATE_FORMAT_TIMESTAMP)
                                                                  }
                                                                  name={getFieldName(fieldPrefix, index, 'value')}
                                                                  component={FxSelectSingleDate}
                                                                  startDate={get(field, `value`)}
                                                                  timePicker={true}
                                                                  style={{ minHeight: '38px', height: '38px' }}
                                                                  labelClass={'d-block'}
                                                                  key={`${get(field, 'field.keyName')}-${index}-${get(
                                                                      field,
                                                                      'field.id'
                                                                  )}-value`}
                                                                  disabled={this.props.disabled || false}
                                                              />
                                                          ),
                                                          isTemplate,
                                                          fieldsMetaData,
                                                          handleBooleanUpdate,
                                                          handleTextUpdate,
                                                          isTemplateSupport,
                                                          defaultLabel: upperFirst(get(field, 'field.keyName')),
                                                          fieldId: fieldIdForTemplate,
                                                      })
                                                    : this.renderCustomField({
                                                          children: (
                                                              <Field
                                                                  name={getFieldName(fieldPrefix, index, 'value')}
                                                                  component={FxTextFieldStackedLabel}
                                                                  style={{ minHeight: '38px', height: '38px' }}
                                                                  labelClass={'d-block'}
                                                                  type="text"
                                                                  key={`${get(field, 'field.keyName')}-${index}-${get(
                                                                      field,
                                                                      'field.id'
                                                                  )}-value`}
                                                                  disabled={this.props.disabled || false}
                                                              />
                                                          ),
                                                          isTemplate,
                                                          fieldsMetaData,
                                                          handleBooleanUpdate,
                                                          handleTextUpdate,
                                                          isTemplateSupport,
                                                          defaultLabel: upperFirst(get(field, 'field.keyName')),
                                                          fieldId: fieldIdForTemplate,
                                                      })}
                                            </div>
                                        </FxTemplateFieldContainer>
                                    ) : (
                                        <></>
                                    );
                                })}
                            </>
                        );
                    }}
                </FieldArray>
            </>
        );
    }
}
