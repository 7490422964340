import { handle } from 'redux-pack';
import { FETCH_LOAD_TYPES } from '../../actions/dashboard/rateCharts/loadTypesActions';
import { FETCH_MATERIAL_GROUPS } from '../../actions/dashboard/rateCharts/materialGroupsActions';
import { FETCH_MATERIALS } from '../../actions/dashboard/rateCharts/materialsActions';
import { FETCH_CLIENT_CHARTS } from '../../actions/dashboard/rateCharts/clientRateChartsActions';
import { FETCH_VENDOR_CHARTS } from '../../actions/dashboard/rateCharts/vendorRateChartsActions';
import { FETCH_CLIENT_CONTRACT_CHARTS } from '../../actions/dashboard/rateCharts/clientContractChartsActions';
const initialState = {
    isLoadTypesLoading: false,
    loadTypes: [],
    isMaterialGroupLoading: false,
    materialGroups: [],
    isMaterialsLoading: false,
    materials: [],
    isClientRateChartsLoading: false,
    clientRateCharts: [],
    isVendorRateChartsLoading: false,
    vendorRateCharts: [],
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        // load  types
        case FETCH_LOAD_TYPES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isLoadTypesLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isLoadTypesLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    loadTypes: payload.data,
                }),
            });
        // material groups
        case FETCH_MATERIAL_GROUPS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isMaterialGroupLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isMaterialGroupLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    materialGroups: payload.data,
                }),
            });
        // material
        case FETCH_MATERIALS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isMaterialsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isMaterialsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    materials: payload.data,
                }),
            });
        // client rate charts
        case FETCH_CLIENT_CHARTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isClientRateChartsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isClientRateChartsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    clientRateCharts: payload.data,
                }),
            });
        // client rate charts
        case FETCH_VENDOR_CHARTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isVendorRateChartsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isVendorRateChartsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vendorRateCharts: payload.data,
                }),
            });
        //contract charts
        case FETCH_CLIENT_CONTRACT_CHARTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isClientContractChartsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isClientContractChartsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    clientContractCharts: payload.data.content,
                }),
            });
        default:
            return state;
    }
}
