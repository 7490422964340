import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
import { isViewBranchAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { get } from 'lodash';
const CREATE_BRANCH = 'create_branch';
export const FETCH_BRANCHES = 'fetch_branches';
const UPDATE_BRANCH = 'update_branch';
const GET_BRANCH = 'get_branch';
const DELETE_BRANCH = 'delete_branch';
export function fetchBranches(accesstoken, withEnabledStationary, accountId) {
    if (!isViewBranchAllowedForUser(get(window.FLEETX_LOGGED_IN_DATA, 'scope'))) {
        return {
            type: FETCH_BRANCHES,
            promise: Promise.reject(),
        };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            withEnabledStationary,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}branch`, config);
    return {
        type: FETCH_BRANCHES,
        promise: request,
    };
}
export function createBranch(accesstoken, data, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.post(`${ROOT_API_ERP}branch`, data, config);
    return {
        type: CREATE_BRANCH,
        promise: request,
    };
}
export function updateBranch(accesstoken, data, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.put(`${ROOT_API_ERP}branch`, data, config);
    return {
        type: UPDATE_BRANCH,
        promise: request,
    };
}
export function fetchBranch(accesstoken, id, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}branch/${id}`, config);
    return {
        type: GET_BRANCH,
        promise: request,
    };
}
export function deleteBranch(accesstoken, id, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.delete(`${ROOT_API_ERP}branch/${id}`, config);
    return {
        type: DELETE_BRANCH,
        promise: request,
    };
}
