import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Styled from 'styled-components';
const MaskWrapper = Styled.div `
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: hsla(0, 0%, 100%, .5);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const FxMask = (props) => {
    const { hide, children, ...rest } = props;
    if (hide) {
        return _jsx(_Fragment, { children: children });
    }
    return _jsx(MaskWrapper, { ...rest, children: children });
};
export default FxMask;
