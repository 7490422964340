import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get } from 'lodash';
import { Form } from 'antd';
import CalendarSingleDatePicker from 'components/utils/calendar_single_date_picker';
import { getHumanizeTime } from 'utils/date_utils';
export default (passedProps) => {
    const { field, form, label, isRequired, disabled, valueFormatter, ...props } = passedProps;
    const error = get(form.errors, field.name, '');
    const touched = get(form.touched, field.name, '');
    const errorClass = error ? 'form-control-label text-danger' : '';
    const onStartDateSelected = (startDate) => {
        if (valueFormatter) {
            form.setFieldValue(field.name, startDate ? valueFormatter(startDate) : startDate);
        }
        else {
            form.setFieldValue(field.name, startDate);
        }
    };
    return (_jsxs("div", { className: props.className, children: [disabled && (_jsxs(_Fragment, { children: [_jsxs("div", { className: `mb-2 ${errorClass} label`, children: [label, isRequired && _jsx("abbr", { className: "text-danger", children: "*" })] }), _jsx("div", { style: { minHeight: '38px', height: '38px', lineHeight: '38px' }, children: field.value && getHumanizeTime(field.value) })] })), !disabled && (_jsx(Form.Item, { label: label, required: isRequired, validateStatus: touched && error ? 'error' : '', help: touched && error, labelCol: { span: 24 }, children: _jsx(CalendarSingleDatePicker, { onDateSelected: onStartDateSelected, startDate: field.value, ...props, ...field, containerStyles: { minHeight: '38px', height: '38px' } }) }))] }));
};
