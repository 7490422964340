import {handle} from "redux-pack";
import {FETCH_DTCS, FETCH_DTCS_GRAPH, FETCH_DTCS_REPORT} from "../../actions/dashboard/dtc_action";


const initialState = {
    isLoading: false,
    error: null,
    aggregations: [],
    isFinished: false,
    graphData: [],
    isDtcsLoading: false,
    isDtcGraphsLoading: false
};


export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case FETCH_DTCS :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null, isDtcsLoading: true
                }), finish: prevState => ({...prevState, isLoading: false, isFinished: true, isDtcsLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, aggregations: payload.data.aggregations
                }),
            });
        case FETCH_DTCS_GRAPH :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null, isDtcGraphsLoading: true
                }), finish: prevState => ({...prevState, isLoading: false, isDtcGraphsLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, graphData: payload.data
                })
            });
        case FETCH_DTCS_REPORT :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})
            });
        default:
            return state;
    }
}