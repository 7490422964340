import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forEach, get, map, round, toSafeInteger } from 'lodash';
import { getMomentTime } from 'utils/date_utils';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
const ServiceEntriesSummary = (props) => {
    const { serviceEntrySummary } = props;
    const currency = getCurrencyFromCountry(props.loggedInUser);
    const getGraphData = (daysCost) => {
        const graphData = [];
        forEach(daysCost, (value, key) => {
            graphData.push({ date: getMomentTime(toSafeInteger(key)).format('DD MMM'), value });
        });
        return graphData;
    };
    const renderTooltip = (props) => {
        const { active } = props;
        if (active) {
            const { payload } = props;
            return (_jsxs("div", { className: "grph-tooltip-con p-2", children: [_jsx("div", { className: "text-white", children: get(payload, '[0].payload.date') }), _jsxs("div", { className: "text-white", children: [_jsx("span", { className: "text-muted", children: "Cost: " }), _jsxs("span", { children: [currency, " ", round(get(payload, '[0].payload.value'), 2)] })] })] }));
        }
        return null;
    };
    const formatYAxisTick = (tickValue) => {
        if (tickValue >= 100000000) {
            return `${currency} ${(tickValue / 100000000).toFixed(0)}Cr`;
        }
        if (tickValue >= 100000) {
            return `${currency} ${(tickValue / 100000).toFixed(0)}L`;
        }
        if (tickValue >= 1000) {
            return `${currency} ${(tickValue / 1000).toFixed(0)}k`;
        }
        return `${currency} ${tickValue}`;
    };
    const graphData = getGraphData(props.daysCost);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "card service-entry-listing mb-0", style: { border: 'solid 1px #dadada', borderRadius: '4px', minWidth: '350px', minHeight: '150px' }, children: [_jsx("div", { className: "summary-title", children: "Summary" }), props.isFetchingSummary ? (_jsx("div", { className: "fa fa-spinner fa-spin d-flex  justify-content-center m-2" })) : (_jsx("div", { className: "item-wrapper", children: serviceEntrySummary &&
                            map(serviceEntrySummary, (v, i) => {
                                return (_jsxs("div", { className: "service-item-container", children: [_jsxs("div", { children: [_jsx("div", { className: "item-count", children: get(v, 'value') }), ' ', _jsx("div", { className: "item-label", children: get(v, 'label') })] }), i !== serviceEntrySummary.length - 1 && (_jsx("div", { style: { backgroundColor: '#d0d0d0' }, className: "vertical-line " }))] }, i));
                            }) }))] }), _jsx("div", { className: "card mb-0 p-3", style: { border: 'solid 1px #dadada', borderRadius: '4px', flex: 1 }, children: props.isFetchingSummary ? (_jsx("div", { className: "d-flex justify-content-center align-items-center", style: { minHeight: '150px' }, children: _jsx("div", { className: "fa fa-spinner fa-spin m-2" }) })) : (_jsxs(_Fragment, { children: [graphData?.length > 0 && (_jsx(ResponsiveContainer, { minHeight: 150, children: _jsxs(BarChart, { data: graphData, children: [_jsx(XAxis, { dataKey: "date" }), _jsx(YAxis, { tickLine: false, tickFormatter: formatYAxisTick }), _jsx(Tooltip, { content: renderTooltip, cursor: { fill: 'grey', fillOpacity: 0.07 } }), _jsx(Bar, { type: "monotone", dataKey: "value", fill: "#76c09d", barSize: 25 })] }) })), graphData?.length === 0 && (_jsx("div", { className: "d-flex justify-content-center align-items-center", style: { height: '150px' }, children: _jsx("div", { className: "text-muted", children: "No graph data available" }) }))] })) })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceEntriesSummary);
