import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Field, FieldArray } from 'formik';
import { FxSelectField, SingleFileDropzoneFormik } from '../shared';
import { Button, Col, Row } from 'reactstrap';
import { map } from 'lodash';
import { mappedDocumentType } from '../components/utils/mapping_utils';
const UploadDocumentsComponentFormik = (props) => {
    const { documents, name, onlyView, customDocumentType, isTagTypeRequired, type, tagTypeOptions } = props;
    const itemsUploaded = !!(documents && documents.length);
    return (_jsx("div", { children: _jsx(FieldArray, { name: name, children: ({ push, remove }) => {
                return (_jsxs(_Fragment, { children: [itemsUploaded &&
                            map(documents, (doc, index) => {
                                if (props.hideDocWithId && doc.id) {
                                    return null;
                                }
                                const baseKey = `${name}.[${index}]`;
                                return (_jsxs("div", { children: [_jsxs(Row, { children: [_jsx(Col, { md: isTagTypeRequired ? 6 : 12, sm: isTagTypeRequired ? 6 : 12, children: _jsx(Field, { name: `${baseKey}.documentType`, options: !!customDocumentType
                                                            ? customDocumentType()
                                                            : mappedDocumentType(), component: FxSelectField, label: "Type", isRequired: true, disabled: !!type }) }), !!isTagTypeRequired && (_jsx(Col, { md: 6, sm: 6, children: _jsx(Field, { name: `${baseKey}.tagType`, options: !!tagTypeOptions ? tagTypeOptions : [], component: FxSelectField, label: "Document Tag", disabled: !!type }) }))] }), _jsx(Field, { accept: "image/jpeg, image/png, image/jpg, image/gif,application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel", message: props.lessText
                                                ? 'Click/Drop Document to upload'
                                                : 'Try dropping some photo/pdf here, or click to select photo/pdf to upload.', name: `${baseKey}.url`, showImageThumbnail: true, component: SingleFileDropzoneFormik, folderPath: "/contracts/uploaded", onlyView: onlyView }), !onlyView && (_jsx("div", { className: "text-right", children: _jsx(Button, { type: "button", className: "p-0", color: "link", onClick: () => {
                                                    remove(index);
                                                }, children: "Remove Document" }) })), _jsx("hr", {})] }, index));
                            }), !onlyView && (_jsx("div", { className: "row justify-content-center", children: _jsx(Button, { type: "button", color: "link", onClick: () => {
                                    if (type === 'IMAGE') {
                                        push({ documentType: 'IMAGE' });
                                    }
                                    else if (type === 'DOCUMENT') {
                                        push({ documentType: 'DOCUMENT' });
                                    }
                                    else {
                                        push({});
                                    }
                                }, children: itemsUploaded ? 'Attach More Documents' : 'Attach Documents' }) }))] }));
            } }) }));
};
export default UploadDocumentsComponentFormik;
