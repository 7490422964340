import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const CommoditiesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-commodities" */ 'components/dashboard/commodity/commodities_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditCommodityComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditCommodity" */ 'components/dashboard/commodity/add_edit_comodity_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const commoditiesRoutes = [
    {
        index: true,
        lazy: CommoditiesComponent,
    },
    {
        path: 'add',
        lazy: AddEditCommodityComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditCommodityComponent,
    },
];
export default commoditiesRoutes;
