import { handle } from 'redux-pack';
import { FETCH_MODULES } from '../../actions/dashboard/modules_action';

const initialState = {
    isModulesListLoading: false,
    error: null,
    isFinished: false,
    modulesList: [],
};

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_MODULES:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isModulesListLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isModulesListLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    modulesList: payload.data,
                    isModulesListLoading: false,
                }),
            });
        default:
            return state;
    }
}
