import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import {
    DATE_FORMAT,
    DATE_FORMAT_HUMANIZE_TIME,
    DATE_FORMAT_TIME,
    getDateRangeFromInputString,
    getInputStringFromDateRange,
    getMomentTime,
} from 'utils/date_utils';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import jquery from 'jquery';
import {
    accountLevelMinDateSelection,
    enableUserInputBoxForCalendar,
    show24HoursCalenderFormatForAccount,
} from 'utils/account_utils';
import { stopPropagation } from 'constant';
import { get } from 'lodash';

const CalendarSingleDatePicker = (props) => {
    let dateRangePicker = null;
    const [startDate, setStartDate] = useState(null);
    const [minDate, setMinDate] = useState(accountLevelMinDateSelection());
    const [maxDate, setMaxDate] = useState(getMomentTime().add('20', 'years'));
    const [opens] = useState('center');
    const [drops] = useState(props.drops ? props.drops : 'down');
    const [userInput, setUserInput] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [invalidDateError, setInvalidDateError] = useState('');

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        const newState = {};
        if (props.startDate) {
            newState.startDate = getMomentTime(props.startDate);
        }
        if (props.minDate && !accountLevelMinDateSelection()) {
            newState.minDate = getMomentTime(props.minDate);
        }
        if (props.maxDate) {
            newState.maxDate = getMomentTime(props.maxDate);
        }
        if (enableUserInputBoxForCalendar()) {
            const userInput = getInputStringFromDateRange(
                newState.startDate ? newState.startDate : startDate,
                null,
                true,
                !!props.timePicker
            );
            if (userInput) {
                newState.userInput = userInput;
            }
        }
        setStartDate(newState.startDate);
        setMinDate(newState.minDate);
        setMaxDate(newState.maxDate);
        setUserInput(newState.userInput);

        const hideDate = !!props.hideDate;
        if (hideDate) {
            jquery('.calendar-cancel-custom-class').parents('.daterangepicker').addClass('hide-calendar-date-custom');
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const newState = {};
        newState.startDate = props.startDate ? getMomentTime(props.startDate) : null;
        if (props.minDate && !accountLevelMinDateSelection()) {
            newState.minDate = getMomentTime(props.minDate);
        }
        if (props.maxDate) {
            newState.maxDate = getMomentTime(props.maxDate);
        }
        if (enableUserInputBoxForCalendar()) {
            const userInput = getInputStringFromDateRange(
                newState.startDate ? newState.startDate : startDate,
                null,
                true,
                !!props.timePicker
            );
            if (userInput) {
                newState.userInput = userInput;
            }
        }
        setStartDate(newState.startDate);
        setMinDate(newState.minDate);
        setMaxDate(newState.maxDate);
        setUserInput(newState.userInput);
    }, [props.startDate, props.minDate, props.maxDate]);

    function handleEvent() {}

    const handleKeyDown = (event) => {
        const nextFocusedField = event.target;
        if (nextFocusedField.type !== 'button' && dateRangePicker) {
            dateRangePicker.hide();
        }
    };

    const onApplyEvent = (event, picker) => {
        setStartDate(picker.startDate);
        setUserInput(getInputStringFromDateRange(picker.startDate, null, true, !!props.timePicker));
        setInvalidDateError('');
        props.onDateSelected(picker.startDate, props.index);
    };

    const onInputFocus = () => {
        setIsFocused(true);
    };
    const onDateClear = (event) => {
        stopPropagation(event);
        setStartDate(null);
        props.onDateSelected('', props.index);
    };

    const onInputChange = () => {
        setInvalidDateError('');
        setIsFocused(false);
        const parsedData = getDateRangeFromInputString(userInput, true);

        if (parsedData.error) {
            setInvalidDateError(parsedData.error);
            // if (dateRangePicker) dateRangePicker.hide();
            return;
        }

        const { startDate } = parsedData;
        const startDateObj = getMomentTime(startDate);
        setStartDate(startDateObj);
        props.onDateSelected(startDateObj, props.index);
        // if (dateRangePicker) dateRangePicker.hide();
    };

    const timePicker = !!props.timePicker;
    const timePicker24Hour = props.timePicker24Hour || show24HoursCalenderFormatForAccount();
    const hideDate = !!props.hideDate;
    const placeholder = props.placeholder;
    const format = timePicker ? (hideDate ? DATE_FORMAT_HUMANIZE_TIME : DATE_FORMAT_TIME) : DATE_FORMAT;
    const start = startDate ? getMomentTime(startDate).format(format) : '';
    const isMaxDate = !!maxDate;
    const isMinDate = !!minDate;
    const isStartDate = !!startDate;
    const buttonWidth = props.buttonWidth ? props.buttonWidth : '100%';
    const containerStyles = props.containerStyles ? props.containerStyles : {};
    const showDateLeft = props.showDateLeft ? props.showDateLeft : '';
    const inputValue = isFocused ? userInput : start;
    const inputWidth = '11rem';
    const dontShowClearButton = get(props, 'dontShowClearButton', false);

    if (props.disabled) {
        return (
            <div style={{ width: buttonWidth, borderRadius: '4px' }} className="border p-2">
                <span className="whitespace-normal text-muted ">{start}</span>
            </div>
        );
    } else {
        return (
            <DateRangePicker
                startDate={props.initialStartDate}
                // locale={{ 'format': format }}
                opens={opens}
                {...(isStartDate ? { startDate } : {})}
                {...(isMaxDate ? { maxDate } : {})}
                {...(isMinDate ? { minDate } : {})}
                onEvent={handleEvent}
                drops={drops}
                singleDatePicker={true}
                autoUpdateInput={false}
                showDropdowns={true}
                {...(timePicker ? { timePicker: true } : {})}
                {...(timePicker24Hour ? { timePicker24Hour: true } : {})}
                {...(timePicker
                    ? { timePickerIncrement: props.timePickerIncrement ? props.timePickerIncrement : 1 }
                    : {})}
                containerStyles={containerStyles}
                onApply={onApplyEvent}
                cancelClass="calendar-cancel-custom-class"
                onShow={(event, picker) => {
                    dateRangePicker = picker;
                }}
                onHide={() => {
                    dateRangePicker = null;
                }}
            >
                <>
                    {enableUserInputBoxForCalendar() && (
                        <InputGroup>
                            <InputGroupText
                                style={{
                                    marginTop: '.45rem',
                                    paddingLeft: '.6rem',
                                    marginRight: '-1.6rem',
                                    zIndex: 4,
                                }}
                            >
                                <i className="fa fa-calendar" />
                            </InputGroupText>
                            <Input
                                disabled={props.disabled}
                                style={{ width: inputWidth, paddingLeft: '2rem' }}
                                onChange={(e) => {
                                    setUserInput(e.currentTarget.value);
                                }}
                                onClick={onInputFocus}
                                onFocus={onInputFocus}
                                onBlur={onInputChange}
                                type="text"
                                className={`rounded form-control ${invalidDateError ? 'text-danger' : ''}`}
                                value={inputValue}
                            />
                        </InputGroup>
                    )}

                    {!enableUserInputBoxForCalendar() && (
                        <Button
                            className="selected-date-range-btn"
                            style={{ width: buttonWidth, borderRadius: '4px', minHeight: '38px', height: '38px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div className="pull-left">
                                <i className="fa fa-calendar mr-1" />
                            </div>
                            <div className={showDateLeft ? 'pull-left' : 'pull-right'}>
                                <span className="whitespace-normal">{start}</span>
                                {!start && placeholder && (
                                    <span className="whitespace-normal text-muted">{placeholder}</span>
                                )}
                                {!!start && !dontShowClearButton && (
                                    <span
                                        title={'Clear Date'}
                                        className={'fa fa-close mx-1 single-date-clear-icon'}
                                        onClick={onDateClear}
                                    />
                                )}
                                {!showDateLeft && <span className="ml-1 fa fa-caret-down" />}
                            </div>
                            {showDateLeft ? <span className="ml-1 fa fa-caret-down pull-right" /> : ''}
                            <div className="clearfix" />
                        </Button>
                    )}
                </>
            </DateRangePicker>
        );
    }
};

export default CalendarSingleDatePicker;
