import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const ReasonMasterComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-reasonMaster" */ 'components/dashboard/settings/reasonmaster/reason_master'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditReasonMaster = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditReasonMaster" */ 'components/dashboard/settings/reasonmaster/add_edit_reason_master'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const reasonMasterRoutes = [
    {
        index: true,
        lazy: ReasonMasterComponent,
    },
    {
        path: 'add',
        lazy: AddEditReasonMaster,
    },
    {
        path: 'edit/:id',
        lazy: AddEditReasonMaster,
    },
];
export default reasonMasterRoutes;
