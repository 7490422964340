import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditTyresAndPartsBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-tyresAndPartsBill-add-edit" */ 'components/dashboard/tyresAndPartsBilling/AddEditTyresAndPartsBill'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TyresAndPartsBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-tyresAndPartsBill" */ 'components/dashboard/tyresAndPartsBilling/TyresAndPartsBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const tyresAndPartsBillsRoutes = [
    {
        path: 'add',
        lazy: AddEditTyresAndPartsBillComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditTyresAndPartsBillComponent,
    },
    {
        index: true,
        lazy: TyresAndPartsBillComponent,
    },
];
export default tyresAndPartsBillsRoutes;
