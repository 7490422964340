import { Component } from 'react';
import { forEach, get, has, round, toSafeInteger } from 'lodash';
import { getMomentTime } from '../../../../utils/date_utils';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';

class ServiceEntrySummaryComponent extends Component {
    constructor(props) {
        super(props);
    }

    getGraphData(daysCost) {
        const graphData = [];
        forEach(daysCost, function (value, key) {
            graphData.push({ date: getMomentTime(toSafeInteger(key)).format('DD MMM'), value: value });
        });
        return graphData;
    }

    renderTooltip(props) {
        const { active } = props;

        if (active) {
            const { payload, label } = props;
            return (
                <div className="grph-tooltip-con p-2">
                    <div className="text-white">{payload[0].payload.date}</div>
                    <div className="text-white">
                        <span className="text-muted">Cost: </span>
                        <span>{round(payload[0].payload.value, 2)} </span>
                    </div>
                </div>
            );
        }
        return null;
    }

    render() {
        const { serviceEntrySummary, vehicleDetail } = this.props;
        const graphData = this.getGraphData(serviceEntrySummary.daysCost);

        return (
            <div className="card mt-2 mb-4">
                <div className="card-block">
                    <span className="h4 mr-2 mb-0">Summary</span>
                    <div className="row mt-2">
                        <div className="col-6">
                            <div className={`h4 mb-0 mr-2`}>{serviceEntrySummary.count}</div>

                            <div className="text-muted text-capitalize">Total</div>
                        </div>
                        <div className="col-6">
                            <div className={`h4 mb-0 mr-2`}>
                                {round(serviceEntrySummary.totalamount, 2)}{' '}
                                {getCurrencyFromCountry(this.props.loggedInUser, true)}
                            </div>

                            <div className="text-muted text-capitalize">Cost</div>
                        </div>
                    </div>
                    {!vehicleDetail && graphData && graphData.length > 0 && (
                        <div className="mt-5">
                            <div>Cost</div>
                            <div className="mt-2">
                                <ResponsiveContainer minHeight={150}>
                                    <LineChart data={graphData}>
                                        <XAxis dataKey="date" />
                                        <YAxis
                                            unit={`${getCurrencyFromCountry(this.props.loggedInUser)}`}
                                            axisLine={false}
                                            tickLine={false}
                                        />
                                        <CartesianGrid stroke="#DCE4EC" vertical={false} />
                                        <Tooltip
                                            content={this.renderTooltip}
                                            cursor={{ fill: 'grey', fillOpacity: 0.07 }}
                                        />
                                        <Line type="monotone" dataKey="value" stroke="#15bed4" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default ServiceEntrySummaryComponent;
