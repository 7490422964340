import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

class InfoToolTip extends Component {
    closingTimeout = 0;

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false || props.isOpen,
        };
    }

    closeInfoWindow = (event) => {
        clearTimeout(this.closingTimeout);
        this.setState({
            isOpen: false,
        });
        event.stopPropagation();
    };

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidUpdate(prevProps, prevState) {}

    componentWillUnmount() {}

    onMouseEnter = () => {
        clearTimeout(this.closingTimeout);
        this.setState({
            isOpen: true,
        });
    };
    onMouseLeave = () => {
        clearTimeout(this.closingTimeout);
        this.closingTimeout = setTimeout(() => {
            this.setState({
                isOpen: false,
            });
        }, 3000);
    };
    getType = () => {
        if (!this.props.type) {
            return 'info';
        }
        return this.props.type;
    };
    getPosition = () => {
        if (!this.props.position) {
            return 'top';
        }
        return this.props.position;
    };

    render() {
        if (isEmpty(this.props.text)) {
            return null;
        }
        return (
            <div
                className="d-inline pos-rel info-tool-tip-container"
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
            >
                <span
                    className={`px-1 ${this.props.iconSize ? this.props.iconSize : ''} ${
                        this.props.noHover ? '' : 'hover'
                    }`}
                >
                    {this.getType() === 'info' && <i className="fa fa-info-circle" />}
                    {this.getType() === 'error' && <i className="fa fa-warning text-danger" />}
                </span>
                <div
                    style={{ zIndex: '100' }}
                    className={`pos-abs info-tool-tip-text ${this.getPosition()} ${this.state.isOpen ? 'shown' : ''}`}
                >
                    <i
                        className="fa fa-times-circle cursor-pointer pos-abs info-tool-tip-close-icon"
                        onClick={this.closeInfoWindow.bind(this)}
                    />
                    <span className="">{this.props.text}</span>
                </div>
            </div>
        );
    }
}

InfoToolTip.propTypes = {
    text: PropTypes.string,
    position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
    type: PropTypes.oneOf(['info', 'error']),
    iconSize: PropTypes.oneOf(['small', 'medium']),
    noHover: PropTypes.bool,
    isOpen: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoToolTip);
