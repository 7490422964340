import { includes, toLower } from 'lodash';
export const PING_FREQUENCY = [
    {
        value: 60, // seconds
        label: '1 Min',
    },
    {
        value: 300,
        label: '5 Mins',
    },
    {
        value: 900,
        label: '15 Mins',
    },
    {
        value: 1800,
        label: '30 Mins',
    },
    {
        value: 3600,
        label: '1 Hr',
    },
    {
        value: 10800,
        label: '3 Hrs',
    },
    {
        value: 21600,
        label: '6 Hrs',
    },
    {
        value: 28800,
        label: '8 Hrs',
    },
    {
        value: 43200,
        label: '12 Hrs',
    },
    {
        value: 86400,
        label: '24 Hrs',
    },
];

export function pingFrequencyIsValidForNetwork(network) {
    return network && includes(['airtel', 'vodafone', 'idea', 'jio'], toLower(network));
}

export const DEVICE_SOURCE_OPTIONS = [
    { value: 'DEVICE', label: 'GPS' },
    { value: 'SIM', label: 'SIM' },
    { value: 'APP', label: 'APP' },
];

export const DEVICE_SOURCE_OPTIONS_FOR_JKLC = [
    { value: 'DEVICE', label: 'GPS' },
    { value: 'SIM', label: 'SIM' },
    { value: 'NO', label: 'NO TRACKING' },
];

export const DEVICE_SOURCES = {
    DEVICE: 'DEVICE',
    SIM: 'SIM',
    APP: 'APP',
    NO: 'NO',
};

export const MOVEMENT_TYPE = [
    { value: 'INBOUND', label: 'INBOUND' },
    { value: 'OUTBOUND', label: 'OUTBOUND' },
];

export const UNLOADING_LOGIC = [
    { value: 'Backward', label: 'Backward' },
    { value: 'Forward', label: 'Forward' },
    { value: 'DESTINATION_DELIVERY', label: 'Destination Delivery' },
];
