/**
 * Created by udbhav on 18/07/17.
 */

import { handle } from 'redux-pack';
import {
    FETCH_ANALYTICS_LIVE,
    FETCH_HISTORICAL_VEHICLE_LOCATION,
    FETCH_REALTIME_SHARES,
} from '../../actions/dashboard/realtime_action';
import { forEach, get, map, omit, reduce, sortBy } from 'lodash';
import { getMomentTime } from '../../utils/date_utils';
import { refactorCurrentStatus, isRemoved } from '../../utils/vehicle_utils';
import {
    FETCH_CONCOX_LOCATION_CHANGE,
    FETCH_IDLE_TRIP_DELETES,
    FETCH_OBD_NO_LOCATION_CHANGED,
    FETCH_OBD_ZERO_KM_TRIPS,
} from '../../actions/dashboard/metabase_action';
import {
    isBrahamaputraAccount,
    isExpressRoadwaysAccount,
    showRemovedVehiclesForAccount,
    isQuadTreeSearchOpenForAccount,
} from '../../utils/account_utils';
import {
    isTagsFilterConditionMatched,
    concatTagsForFiltering,
    getTagsListFromAttachedTags,
} from '../../utils/tag_utils';
import { getFilteredAddressBookByTag } from '../../constant';
import {
    isInsideNearestAddress,
    getNearestAddressFromAddressbook,
    createQuadTreeFromAddressBook,
    getNearestAddressFromWithinQTNode,
    normalizeLatitude,
    normalizeLongitude,
} from '../../utils/map/geocode_utils';
import { Circle } from '../../vendors/quadtree';
import { isEmpty } from 'lodash';
import { FETCH_DVR_AND_LIVE_VIEW_CAMERA_CONFIG } from '../../actions/dashboard/telematics_action';
import { GET_DAY_WISE_AGGREGATION, GET_VEHICLE_WISE_AGGREGATION } from 'actions/dashboard/easy/fuel_entry_action';

const initialState = {
    isLoading: false,
    error: null,
    isFinished: false,
    isRealtimeSharesLoading: false,
    isRealtimeSharesFinished: false,
    isHistoricalVehicleLocationLoading: false,
    concoxLocationChanges: {},
    obdZeroKmWithFuel: {},
    idleTripDeletes: {},
    obdNoLocationChanged: {},
    fetchAnalyticsLiveStatus: '',
    cameraConfig: '',
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ANALYTICS_LIVE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    fetchAnalyticsLiveStatus: 'loading',
                }),
                finish: (prevState) => ({ ...prevState, isFinished: true }),
                failure: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    error: payload,
                    fetchAnalyticsLiveStatus: 'error',
                }),
                success: (prevState) => {
                    let vehicles = [];
                    const showRemoved = showRemovedVehiclesForAccount(window.FLEETX_LOGGED_IN_USER);
                    map(get(payload, 'data.vehicles'), (v) => {
                        const vehicle = refactorCurrentStatus(v, window.FLEETX_LOGGED_IN_USER);
                        const tagIds = get(vehicle, 'tagIds', []);

                        if (
                            isBrahamaputraAccount(window.FLEETX_LOGGED_IN_USER) ||
                            isExpressRoadwaysAccount(window.FLEETX_LOGGED_IN_USER)
                        ) {
                            const jobTagId = get(get(root, 'jobs.vehiclesOnJob.jobTags', {}), v.vehicleId, null);
                            if (jobTagId) {
                                tagIds.push(jobTagId);
                            }
                        }
                        vehicle.tagIds = tagIds;

                        if (showRemoved) {
                            vehicles.push(vehicle);
                        } else if (!isRemoved(get(v, 'currentStatus'))) {
                            vehicles.push(vehicle);
                        }
                    });
                    return {
                        ...prevState,
                        isLoading: false,
                        vehicles: vehicles,
                        metaData: omit(payload.data, 'vehicles'),
                        lastRequestTime: getMomentTime().valueOf(),
                        fetchAnalyticsLiveStatus: 'success',
                    };
                },
            });
        case FETCH_CONCOX_LOCATION_CHANGE:
            return handle(state, action, {
                start: (prevState) => ({ ...prevState }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState }),
                success: (prevState) => {
                    const devices = get(payload, 'data.concoxLocationChanges.data.rows', []);
                    const devicesObject = reduce(devices, (acc, device) => ({ ...acc, [device[2]]: device }), {});
                    return {
                        ...prevState,
                        concoxLocationChanges: devicesObject,
                    };
                },
            });
        case FETCH_OBD_ZERO_KM_TRIPS:
            return handle(state, action, {
                start: (prevState) => ({ ...prevState }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState }),
                success: (prevState) => {
                    const devices = get(payload, 'data.obdZeroKmWithFuel.data.rows', []);
                    const devicesObject = reduce(devices, (acc, device) => ({ ...acc, [device[2]]: device }), {});
                    return {
                        ...prevState,
                        obdZeroKmWithFuel: devicesObject,
                    };
                },
            });
        case FETCH_IDLE_TRIP_DELETES:
            return handle(state, action, {
                start: (prevState) => ({ ...prevState }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState }),
                success: (prevState) => {
                    const devices = get(payload, 'data.idleTripDeletes.data.rows', []);
                    const devicesObject = reduce(devices, (acc, device) => ({ ...acc, [device[2]]: device }), {});
                    return {
                        ...prevState,
                        idleTripDeletes: devicesObject,
                    };
                },
            });
        case FETCH_OBD_NO_LOCATION_CHANGED:
            return handle(state, action, {
                start: (prevState) => ({ ...prevState }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState }),
                success: (prevState) => {
                    const devices = get(payload, 'data.obdNoLocationChanged.data.rows', []);
                    const devicesObject = reduce(devices, (acc, device) => ({ ...acc, [device[2]]: device }), {});
                    return {
                        ...prevState,
                        obdNoLocationChanged: devicesObject,
                    };
                },
            });
        case FETCH_REALTIME_SHARES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isRealtimeSharesLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isRealtimeSharesLoading: false,
                    isRealtimeSharesFinished: true,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    realtimeSharesList: payload.data,
                }),
            });
        case FETCH_HISTORICAL_VEHICLE_LOCATION:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isHistoricalVehicleLocationLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isHistoricalVehicleLocationLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, isHistoricalVehicleLocationLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    isHistoricalVehicleLocationLoading: false,
                }),
            });
        case FETCH_DVR_AND_LIVE_VIEW_CAMERA_CONFIG:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isHistoricalVehicleLocationLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, isHistoricalVehicleLocationLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    cameraConfig: payload.data,
                }),
            });
        case GET_DAY_WISE_AGGREGATION:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isLoadingDayWiseAggregation: true,
                }),
                finish: (prevState) => prevState,
                failure: (prevState) => ({ ...prevState, error: payload, isLoadingDayWiseAggregation: false }),
                success: (prevState) => {
                    const fuelData = [];
                    const fuelEfficiencyData = [];
                    let averageMileageAggregation = 0;
                    let totalDistance = 0;
                    let totalFuel = 0;
                    forEach(
                        sortBy(payload.data, (o) => getMomentTime(o.date).valueOf()),
                        (value) => {
                            fuelEfficiencyData.push({
                                date: get(value, 'date'),
                                mileage: get(value, 'mileage'),
                                distance: get(value, 'totalOdometer'),
                                fuel: get(value, 'totalFuelConsumption'),
                                wasteFuel: get(value, 'wasteFuel'),
                                idleDuration: get(value, 'idleDuration'),
                            });
                            totalDistance += get(value, 'totalOdometer');
                            totalFuel += get(value, 'totalFuelConsumption');
                        }
                    );
                    if (fuelEfficiencyData.length !== 0) {
                        averageMileageAggregation = totalDistance / totalFuel;
                    }
                    return {
                        ...prevState,
                        isLoadingDayWiseAggregation: false,
                        fuelData,
                        fuelEfficiencyData,
                        averageMileageAggregation,
                    };
                },
            });
        case GET_VEHICLE_WISE_AGGREGATION:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isLoadingVehicleWiseAggregation: true,
                }),
                finish: (prevState) => prevState,
                failure: (prevState) => ({ ...prevState, error: payload, isLoadingVehicleWiseAggregation: false }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        isLoadingVehicleWiseAggregation: false,
                        vehicleWiseAggregation: payload.data,
                    };
                },
            });
        default:
            return state;
    }
}
