import axios from 'axios';
import { ROOT_API_ERP, parseQueryParams } from '../../constant';
import { isViewERPConfigurationAllowedForUser } from 'utils/permissionUtils/erpPermissions';
import { getDummyActionResponse } from 'utils/permissionUtils/utils';
export const FETCH_TEMPLATE_BY_ID = 'fetch_template_by_Id';
export const FETCH_TEMPLATES_BY_FORM_TYPE = 'fetch_templates_by_form_type';
export const FETCH_FORM_TEMPLATES = 'fetch_form_templates';
export const CREATE_TEMPLATE = 'create_template';
export const UPDATE_TEMPLATE = 'update_template';
export const DELETE_TEMPLATE = 'delete_template';
export function fetchTemplateById(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}configuration/${id}`, config);
    return {
        type: FETCH_TEMPLATE_BY_ID,
        promise: request,
    };
}
export function fetchTemplateByFormType(accesstoken, formType) {
    if (!isViewERPConfigurationAllowedForUser()) {
        return getDummyActionResponse({ type: FETCH_TEMPLATES_BY_FORM_TYPE });
    }
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}configuration?formType=${formType}`, config);
    return {
        type: FETCH_TEMPLATES_BY_FORM_TYPE,
        promise: request,
    };
}
export function fetchFormTemplates(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}configuration`, config);
    return {
        type: FETCH_FORM_TEMPLATES,
        promise: request,
    };
}
export function createTemplate(accesstoken, payload) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ERP}configuration`, payload, config);
    return {
        type: CREATE_TEMPLATE,
        promise: request,
    };
}
export function updateTemplate(accesstoken, payload) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_ERP}configuration`, payload, config);
    return {
        type: UPDATE_TEMPLATE,
        promise: request,
    };
}
export function deleteTemplate(accesstoken, id, otherParams = {}) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...otherParams,
        }),
    };
    const request = axios.delete(`${ROOT_API_ERP}configuration/${id}`, config);
    return {
        type: DELETE_TEMPLATE,
        promise: request,
    };
}
