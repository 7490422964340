import { jsx as _jsx } from "react/jsx-runtime";
import { debounce } from 'lodash';
const FxTextSearchField = (passedProps) => {
    const { defaultValue, handleTextFilter, name, placeholder } = passedProps;
    const setTextFilter = debounce((key, term) => {
        handleTextFilter({ [key]: term });
    }, 1000);
    const handleOnTextFilterChange = (event) => {
        setTextFilter(event.target.dataset.datafield, event.target.value);
    };
    return (_jsx("input", { type: "text", name: name, placeholder: placeholder, defaultValue: defaultValue, className: "form-control rounded Select-control grey-placeholder", onChange: handleOnTextFilterChange, "data-datafield": name, "data-testid": name }));
};
export default FxTextSearchField;
