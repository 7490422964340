export async function initZapscale({ name, id, email, accountId, accountName, roleName, roleId }) {
    if (!window.zapscale) {
        return;
    }
    window.zapscale.init(
        '64006e502946f16cd199f398',
        {
            product_name: 'Fleetx Dashboard', // ---> name of the product
            organization_id: accountId, // ---> id of the company/organization
            organization_name: accountName, // ---> organization's name
            unique_organization_id: accountId, // ---> unique id of the company/organization (optional)
            user_id: id, // ---> unique id of the authenticated user
            user_name: name, // ---> user's name
            user_email: email, // ---> user's email
            role_id: roleId, // ---> unique id of the user's role
            role_name: roleName, // ---> role name
        },
        {
            development: false, // ---> (optional) set it to true in development/staging environment
        }
    );
}
