import { handle } from 'redux-pack';
import {
    FETCH_PENDING_PAYMENTS,
    FETCH_PAYMENT_BY_ID,
    FETCH_PAYMENTS,
    FETCH_PAYMENTS_SUMMARY,
    INVOICE_PAYMENT_CUMULATIVE_SUMMARY,
} from '../../../actions/dashboard/finances/payments_action';

const initialState = {
    isLoading: false,
    error: null,
    isFetchPendingPaymentsLoading: false,
    pendingPayments: [],
    paymentById: {},
    isFetchingPaymentById: false,
    payments: {},
    isFetchingPayments: false,
    paymentsSummary: {},
    isFetchingPaymentsSummary: false,
    invoicePaymentCumulativeSummary: {},
    isFetchingInvoicePaymentCumulativeSummary: false,
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_PENDING_PAYMENTS:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    isFetchPendingPaymentsLoading: true,
                    error: null,
                    pendingPayments: [],
                }),
                finish: prevState => ({ ...prevState, isFetchPendingPaymentsLoading: false, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    pendingPayments: payload.data,
                }),
            });
        case FETCH_PAYMENT_BY_ID:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    isFetchingPaymentById: true,
                    error: null,
                    paymentById: {},
                }),
                finish: prevState => ({ ...prevState, isFetchingPaymentById: false, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    paymentById: payload.data,
                }),
            });
        case FETCH_PAYMENTS:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    isFetchingPayments: true,
                    error: null,
                    payments: {},
                }),
                finish: prevState => ({ ...prevState, isFetchingPayments: false, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    payments: payload.data,
                }),
            });
        case FETCH_PAYMENTS_SUMMARY:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    isFetchingPaymentsSummary: true,
                    error: null,
                    paymentsSummary: {},
                }),
                finish: prevState => ({ ...prevState, isFetchingPaymentsSummary: false, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    paymentsSummary: payload.data,
                }),
            });
        case INVOICE_PAYMENT_CUMULATIVE_SUMMARY:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    isFetchingInvoicePaymentCumulativeSummary: true,
                    error: null,
                    invoicePaymentCumulativeSummary: {},
                }),
                finish: prevState => ({
                    ...prevState,
                    isFetchingInvoicePaymentCumulativeSummary: false,
                    isLoading: false,
                }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    invoicePaymentCumulativeSummary: payload.data,
                }),
            });
        default:
            return state;
    }
}
