import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { FxButton, FxFlex, FxImage, FxTypography } from 'sharedV2';
import { useNavigate } from 'react-router';
import { image, isMobileView } from 'constant';
const NotAuthorizedComponent = (props) => {
    const {} = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (_jsx(FxFlex, { justify: "center", align: "center", className: "p-5", style: {
            marginLeft: '-25px',
            marginRight: '-25px',
            height: isMobileView() ? '90%' : '100vh',
            background: 'linear-gradient(to top right, transparent 55%, rgba(0, 120, 212, 0.3) 100%)',
        }, children: _jsxs(FxFlex, { justify: "center", align: "start", gap: 68, children: [_jsx(FxImage, { src: image('/public/img/errorPage/desktop.svg'), preview: false, alt: "desktop-emoji", className: "d-flex justify-content-center ", style: { height: '354px' } }), _jsxs(FxFlex, { vertical: true, justify: "start", children: [_jsx(FxImage, { src: image('/public/img/errorPage/403.png'), preview: false, alt: "403-image", className: "d-flex justify-content-start", style: { height: '82px', width: '193px', marginBottom: '24px' } }), _jsx(FxTypography.Title, { level: 1, className: "mb-2 mt-0", style: {
                                fontSize: '64px',
                                fontWeight: '500',
                            }, children: "Authorisation error!" }), _jsxs(FxTypography.Paragraph, { className: "text-start", style: {
                                fontSize: '20px',
                                marginBottom: '80px',
                            }, children: ["Looks like you are not allowed to view this resource.", _jsx("br", {}), "Please contact support ", _jsx("a", { href: "mailto:support@fleetx.io", children: "support@fleetx.io" }), " or else you", _jsx("br", {}), "can go back to homepage"] }), _jsx(FxButton, { onClick: () => navigate(-1), style: { alignSelf: 'start', width: 'auto' }, type: "primary", size: "large", children: t('goBack') })] })] }) }));
};
export default NotAuthorizedComponent;
