import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditClientStoreComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditClientStore" */ 'components/dashboard/clientStores/add_edit_client_store_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ClientStoresComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-clientStores" */ 'components/dashboard/clientStores/client_store_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const clientStoreRoutes = [
    {
        index: true,
        lazy: ClientStoresComponent,
    },
    {
        path: 'add',
        lazy: AddEditClientStoreComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditClientStoreComponent,
    },
];
export default clientStoreRoutes;
