import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditCustomLineItemsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditCustomLineItems" */ 'components/dashboard/custom-line-items/AddEditCustomLineItems'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const CustomLineItemsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-customCharges" */ 'components/dashboard/custom-line-items/CustomLineItemsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const customLineItmesRoutes = [
    {
        index: true,
        lazy: CustomLineItemsComponent,
    },
    {
        path: 'add',
        lazy: AddEditCustomLineItemsComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditCustomLineItemsComponent,
    },
];
export default customLineItmesRoutes;
