import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const DutiesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-duties" */ 'components/dashboard/finances/duty/duties_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditDutyComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-add-edit-duty" */ 'components/dashboard/finances/duty/add_edit_duty_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewDutyComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-view-duty" */ 'components/dashboard/finances/duty/view_duty_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const InvoicesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-invoices" */ 'components/dashboard/finances/invoices/invoices_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditInvoiceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-add-edit-invoice" */ 'components/dashboard/finances/invoices/add_edit_invoice_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewInvoiceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-view-invoice" */ 'components/dashboard/finances/invoices/view_invoice_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TripSheetComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-tripsheet" */ 'components/dashboard/finances/tripsheet/trip_sheet_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditTripSheetComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-add-edit-tripsheet" */ 'components/dashboard/finances/tripsheet/AddEditTripSheetComponentV2'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewTripSheetComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-view-tripsheet" */ 'components/dashboard/finances/tripsheet/view_trip_sheet_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TransactionListComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName "app-dashboard-transaction-list" */ 'components/dashboard/finances/transactions/transaction_list_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditTransactionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-add-edit-transaction" */ 'components/dashboard/finances/transactions/AddEditTransactionFormV2'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewTransactionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-view-transaction" */ 'components/dashboard/finances/transactions/view_transaction_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const PaymentComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-payment" */ 'components/dashboard/finances/payments/payment_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ClientPaymentsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-client-payments" */ 'components/dashboard/finances/payments/client_payments_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditPaymentsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-add-edit-payment" */ 'components/dashboard/finances/payments/add_edit_payment_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const PaymentsSummaryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-payments-summary" */ 'components/dashboard/finances/payments/payments_summary_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditManualInvoiceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-add-edit-manual-invoice" */ 'components/dashboard/finances/invoices/add_edit_manual_invoice_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const financeRoutes = [
    {
        path: 'duties',
        lazy: DutiesComponent,
    },
    {
        path: 'duties/view/:id',
        lazy: ViewDutyComponent,
    },
    {
        path: 'duties/edit/:id',
        lazy: AddEditDutyComponent,
    },
    {
        path: 'duties/add',
        lazy: AddEditDutyComponent,
    },
    {
        path: 'invoices',
        lazy: InvoicesComponent,
    },
    {
        path: 'invoices/view/:id',
        lazy: ViewInvoiceComponent,
    },
    {
        path: 'invoices/edit/:id',
        lazy: AddEditInvoiceComponent,
    },
    {
        path: 'invoices/add',
        lazy: AddEditInvoiceComponent,
    },
    {
        path: 'invoices/manual/add',
        lazy: AddEditManualInvoiceComponent,
    },
    {
        path: 'invoices/manual/edit/:id',
        lazy: AddEditManualInvoiceComponent,
    },
    {
        path: 'transactions',
        lazy: TransactionListComponent,
    },
    {
        path: 'transactions/view/:id',
        lazy: ViewTransactionComponent,
    },
    {
        path: 'transactions/edit/:id',
        lazy: AddEditTransactionComponent,
    },
    {
        path: 'transactions/add',
        lazy: AddEditTransactionComponent,
    },
    {
        path: 'tripsheet',
        lazy: TripSheetComponent,
    },
    {
        path: 'tripsheet/view/:id',
        lazy: ViewTripSheetComponent,
    },
    {
        path: 'tripsheet/edit/:id',
        lazy: AddEditTripSheetComponent,
    },
    {
        path: 'tripsheet/add',
        lazy: AddEditTripSheetComponent,
    },
    {
        path: 'payments',
        lazy: PaymentsSummaryComponent,
    },
    {
        path: 'payments/client',
        lazy: ClientPaymentsComponent,
    },
    {
        path: 'payments/view/:id',
        lazy: PaymentComponent,
    },
    {
        path: 'payments/edit/:id',
        lazy: AddEditPaymentsComponent,
    },
    {
        path: 'payments/add',
        lazy: AddEditPaymentsComponent,
    },
];
export default financeRoutes;
