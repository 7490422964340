import axios from 'axios';
import { ROOT_API_URL } from '../../constant';
export const FETCH_CHANNEL_SALES_DATA = 'fetch_channel_sales_data';

export function fetchChannelSalesData(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: {
            ...params,
        },
    };

    const request = axios.get(`${ROOT_API_URL}users/fcs-dashboard`, config);

    return {
        type: FETCH_CHANNEL_SALES_DATA,
        promise: request,
    };
}
