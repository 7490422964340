import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Submit = (props) => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "20px", viewBox: "0 -960 960 960", width: "20px", fill: "#0078d4", children: _jsx("path", { d: "M480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q63 0 120 19t105 54l-52 52q-37-26-81-39.5T480-792q-130 0-221 91t-91 221q0 130 91 221t221 91q130 0 221-91t91-221q0-21-3-41.5t-8-40.5l57-57q13 32 19.5 67t6.5 72q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm-55-211L264-468l52-52 110 110 387-387 51 51-439 439Z" }) }));
};
const SubmitIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Submit, ...props });
};
export default SubmitIcon;
