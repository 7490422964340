import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const AccountingStatementComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-accounting-statement-component" */ 'components/dashboard/accounting-statement/AccountingStatementComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AccountingStatementRoutes = [
    {
        index: true,
        lazy: AccountingStatementComponent,
    },
];
export default AccountingStatementRoutes;
