import { useCallback } from 'react';
import SelectAsync from 'react-select/async';
import { map, get, set, find } from 'lodash';

const customStyles = (style) => {
    return {
        menu: (provided, state) => ({
            ...provided,
            zIndex: 5,
        }),
        container: (provided, state) => ({
            ...provided,
            ...style,
        }),
    };
};

const findOption = (options, value) => {
    const option = find(options, { value: value });
    return option ? option : { value: value };
};
const getValue = (props, option) => {
    if (option) {
        return props.isMulti ? map(option, (item) => item.value) : option && option.value;
    } else {
        return props.isMulti ? [] : null;
    }
};

const getSelectValue = (props, value) => {
    if (value) {
        return props.isMulti ? map(value, (v) => findOption(props.options, v)) : findOption(props.options, value);
    } else {
        return props.isMulti ? [] : null;
    }
};

export default (passedProps) => {
    const { field, form, refHandler, ...props } = passedProps;
    const { touched, errors } = form;

    const error = get(errors, field.name, '');
    const isTouched = get(touched, field.name, '');

    const errorClass = isTouched && error ? 'form-control-label text-danger' : '';

    const onChange = useCallback((option) => {
        const v = getValue(props, option);
        form.setFieldValue(field.name, v);
        if (props.onChange) {
            if (props.sendOptions) {
                props.onChange(option);
            } else {
                props.onChange(v);
            }
        }
    });
    const onRefHandler = useCallback((ref) => {
        if (refHandler) {
            refHandler(ref);
        }
    });

    return (
        <div className={`form-group ${props.className}`}>
            {props.label && (
                <div className={`mb-1 ${errorClass}`}>
                    {props.label}
                    {props.isRequired && <abbr className="text-danger">*</abbr>}
                </div>
            )}
            <SelectAsync
                {...props}
                {...field}
                ref={onRefHandler}
                value={getSelectValue(props, field.value)}
                onChange={onChange}
                onBlur={() => form.setFieldTouched(field.name)}
            />
            {isTouched && error && <div className="text-help text-danger">{error}</div>}
        </div>
    );
};
