import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP, ROOT_API_URL } from '../../../constant';
import { getFormattedTimeStringForAPI } from '../../../utils/date_utils';
export const CREATE_PART_ENTRY = 'create_part_entry';
export const UPDATE_PART_ENTRY = 'update_part_entry';
export const UPDATE_PART_ENTRY_QUANTITY = 'update_part_entry_quantity';
export const FILTER_PART_ENTRIES = 'filter_part_entries';
export const PART_ENTRIES_SUMMARY = 'part_entries_summary';
export const CREATE_PART_ENTRY_BULK = 'Create_Part_Entry_Bulk';
export function createPartEntry(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}part-entry`, data, config);
    return {
        type: CREATE_PART_ENTRY,
        promise: request,
    };
}
export function createPartEntryBulk(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}part-entry/bulk`, data, config);
    return {
        type: CREATE_PART_ENTRY_BULK,
        promise: request,
    };
}
export function updatePartEntry(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}part-entry/${data.id}`, data, config);
    return {
        type: UPDATE_PART_ENTRY,
        promise: request,
    };
}
export function updatePartEntryQuantity(accesstoken, id, quantity) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ quantity: quantity }),
    };
    const request = axios.patch(`${ROOT_API_URL}part-entry/quantity/${id}`, null, config);
    return {
        type: UPDATE_PART_ENTRY_QUANTITY,
        promise: request,
    };
}
export function filterPartEntries(accesstoken, params = {}) {
    const { id, billNumber, vendorId, partId, purchaseDate, sort, page = 1, size, partCategoryId, partModelId, partMakerId, fromPurchase, toPurchase, partName, targetCurrency, conversionDate, purchaseType, } = params;
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_filterPartEntries';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            id,
            billNumber,
            vendorId,
            partId,
            sort,
            page: page - 1,
            size,
            purchaseDate: purchaseDate ? getFormattedTimeStringForAPI(purchaseDate) : null,
            partCategoryId,
            partModelId,
            partMakerId,
            fromPurchase,
            toPurchase,
            partName,
            targetCurrency,
            conversionDate,
            purchaseType,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-entry/filter`, config);
    return {
        type: FILTER_PART_ENTRIES,
        promise: request,
    };
}
export function getPartEntrySummary(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-entry/summary`, config);
    return {
        type: PART_ENTRIES_SUMMARY,
        promise: request,
    };
}
