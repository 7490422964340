import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const EntrySvg = (props) => {
    return (_jsx("svg", { width: "15px", height: "18px", viewBox: "0 0 15 18", version: "1.1", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "m14.015 3.653-.24.865h-4.57l.24-.865h4.57zM11.803 9 9.347 6.104l-.005-.727h1.167c.303 0 .551-.054.747-.161.198-.11.346-.259.444-.445a1.35 1.35 0 0 0 .147-.63c0-.25-.046-.471-.137-.663a.983.983 0 0 0-.435-.45c-.198-.107-.46-.16-.786-.16H9.21l.264-.977h1.015c.576 0 1.055.086 1.436.258.38.173.665.424.854.752.192.33.288.726.288 1.192 0 .407-.07.766-.21 1.079-.14.31-.366.56-.678.752-.313.189-.728.306-1.245.351l2.246 2.662V9h-1.377zm2.216-7.11-.244.865H10.04l.24-.864h3.74zM5.059 13.117H4.85a.44.44 0 0 1-.17-.85l3.3-1.269a.44.44 0 0 0 .247-.581C6.926 7.297 6.601 3.45 6.597 3.404a.44.44 0 0 0-.804-.208l-.636.953-.954-.635a.44.44 0 0 0-.67.26l-.29 1.153-.8-.533a.44.44 0 0 0-.67.259l-.289 1.154-.8-.534a.44.44 0 0 0-.683.4c.01.114.227 2.828 1.052 5.59.491 1.646 1.099 2.964 1.806 3.917a2.51 2.51 0 0 0 2.023 1.017h.153c.845 0 1.55-.671 1.563-1.516a1.541 1.541 0 0 0-1.54-1.564zM2.23 8.442a.44.44 0 0 1 .245-.572l2.2-.88a.44.44 0 0 1 .326.817c-2.382.953-2.241.912-2.363.912a.44.44 0 0 1-.408-.277zm1.012 2.005a.44.44 0 0 1-.327-.817l2.2-.88a.44.44 0 0 1 .326.817l-2.2.88zm8.634.031c-.115 0 .112-.074-3.068 1.149-.004-.007-2.128.813-2.124.812a.22.22 0 0 0-.062.373c1.338 1.137 1.06 3.27-.523 4.029-.118.056-.079.235.052.235.366.001.716-.088 1.023-.248l4.743-1.953a.438.438 0 0 0 .239-.098c1.285-.53 1.225-.49 1.312-.582 1.33-1.393.334-3.717-1.592-3.717z", fill: "#333" }) }));
};
const PartEntryIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: EntrySvg, ...props });
};
export default PartEntryIcon;
