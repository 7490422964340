import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_GST_RATE = 'CREATE_GST_RATE';
export const UPDATE_GST_RATE = 'UPDATE_GST_RATE';
export const DELETE_GST_RATE = 'DELETE_GST_RATE';
export const FETCH_GST_RATES = 'FETCH_GST_RATES';
export const FETCH_GST_RATE = 'FETCH_GST_RATE';
export function createGstRate(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}gst-rate`, data, config);
    return {
        type: CREATE_GST_RATE,
        promise: request,
    };
}
export function updateGstRate(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}gst-rate`, data, config);
    return {
        type: UPDATE_GST_RATE,
        promise: request,
    };
}
export function fetchGstRates(accesstoken, filters = {}) {
    const { codeType } = filters;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            codeType,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}gst-rate`, config);
    return {
        type: FETCH_GST_RATES,
        promise: request,
    };
}
export function fetchGstRate(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}gst-rate/${id}`, config);
    return {
        type: FETCH_GST_RATE,
        promise: request,
    };
}
export function deleteGstRate(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}gst-rate/${id}`, config);
    return {
        type: DELETE_GST_RATE,
        promise: request,
    };
}
