import { jsx as _jsx } from "react/jsx-runtime";
import { Carousel } from 'antd';
import styled from 'styled-components';
const CarouselWrapper = styled(Carousel) `
    .slick-dots li button {
        background-color: ${(props) => (props.dotColor ? props.dotColor + ' !important' : props.dotColor)};
    }
`;
const FxCarousel = (props) => {
    return _jsx(CarouselWrapper, { ...props });
};
export default FxCarousel;
