import { FETCH_TYRE_BY_ID } from 'actions/dashboard/tyres/v2/tyre_actions';
import { handle } from 'redux-pack';
import { FETCH_TYRE_BRANDS, FETCH_TYRE_ID, FETCH_TYRE_LISTV2 } from '../../actions/dashboard/tyres_action';

const initialState = {
    tyreBrandsList: [],
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_TYRE_BRANDS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isTyreBrandsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isTyreBrandsLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    tyreBrandsList: payload.data,
                }),
            });
        case FETCH_TYRE_LISTV2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isTyreListLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                    isTyreListLoading: false,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    tyreList: payload.data.content,
                    tyreListCount: payload.data.count,
                }),
            });
        case FETCH_TYRE_BY_ID:
        case FETCH_TYRE_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, tyreDetails: payload.data.tyreDetails }),
            });
        default:
            return state;
    }
};
