import { map, get } from 'lodash';
export const getCommaSeparatedCommodites = (commodities) => {
    let scheduledQuantity = '';
    let actualQuantity = '';
    let variance = '';
    map(commodities, (commodity, index) => {
        scheduledQuantity += `${get(commodity, `commodity.name`)}(${get(commodity, 'scheduledCount', 0)})${index + 1 !== commodities.length ? ',' : ''}`;
        actualQuantity += `${get(commodity, 'commodity.name')}(${get(commodity, 'actualCount', 0)})${index + 1 !== commodities.length ? ',' : ''}`;
        variance += `${get(commodity, 'commodity.name')}(${get(commodity, 'actualCount', 0) - get(commodity, 'scheduledCount', 0)})${index + 1 !== commodities.length ? ',' : ''}`;
    });
    return { scheduledQuantity, actualQuantity, variance };
};
