import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { emptyServicePart } from 'components/dashboard/maintenance/serviceentries/add_edit_service_entry';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forEach, get, map, round, toNumber } from 'lodash';
import { Button } from 'reactstrap';
import { Field, FieldArray } from 'formik';
import { FxSelectField as Select, FxTextFieldStackedLabel } from 'shared/index';
import { VEHICLE_DOMAINS } from 'utils/workorderUtils';
import { SERVICE_WORK_TYPES } from 'constant';
import { Fragment, useMemo } from 'react';
import { mappedServiceTasks } from 'utils/form_utils';
import ServiceParts from 'components/dashboard/maintenance/serviceentries/service_parts';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
export const getSubTotal = (values, index, showUnitPartCostField) => {
    const parts = get(values, `serviceLineItems[${index}].serviceParts`, []);
    let subTotal = 0;
    forEach(parts, (part) => {
        let partTotal = 0;
        const cost = toNumber(get(part, 'cost', 0));
        const count = toNumber(get(part, 'count', 0));
        const discountPercent = get(part, 'discountPercent', 0);
        if (showUnitPartCostField) {
            partTotal += cost * count;
        }
        else {
            partTotal += cost;
        }
        partTotal -= (toNumber(partTotal) * toNumber(discountPercent)) / 100;
        const tax1Percent = get(part, 'tax1Percent', 0);
        const tax2Percent = get(part, 'tax2Percent', 0);
        partTotal +=
            (toNumber(partTotal) * toNumber(tax1Percent)) / 100 + (toNumber(partTotal) * toNumber(tax2Percent)) / 100;
        subTotal += partTotal;
    });
    return subTotal;
};
const ServiceLineItems = (props) => {
    const { index, isSparePart, arrayHelpers, formikProps, isEdit, setIsCreatePartModalOpen, showUnitPartCostField, partOptions, } = props;
    const { values } = formikProps;
    const currency = getCurrencyFromCountry(props.loggedInUser);
    const mapped = {
        serviceTasks: useMemo(() => mappedServiceTasks(props.serviceTasksList), [props.serviceTasksList]),
    };
    const labourCost = get(values, `serviceLineItems[${index}].laborCost`, 0);
    const subTotal = getSubTotal(values, index, showUnitPartCostField);
    return (_jsxs(Fragment, { children: [_jsx("div", { className: "py-3 new-gray-bg-fx", children: _jsxs("div", { className: "px-3 px-3 d-flex align-items-center", children: [_jsxs("h6", { className: "mb-0", children: [!isSparePart && `${index + 1}.`, " Service Task"] }), !isSparePart && index !== 0 && (_jsx(Button, { type: "button", color: "link", className: "ml-auto", onClick: () => {
                                arrayHelpers.remove(index);
                            }, children: "Remove" }))] }) }), _jsxs("div", { className: "px-3 row py-3", children: [!isSparePart && (_jsxs(_Fragment, { children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Service Task", name: `serviceLineItems[${index}].itemId`, placeholder: "Select Service Task", component: Select, options: mapped.serviceTasks, isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Vehicle Domain", name: `serviceLineItems[${index}].vehicleDomain`, placeholder: "Select Vehicle Domain", component: Select, options: VEHICLE_DOMAINS }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Work Type", name: `serviceLineItems[${index}].workType`, placeholder: "Select Work Type", component: Select, options: SERVICE_WORK_TYPES }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Description", name: `serviceLineItems[${index}].description`, placeholder: "Enter Description", component: FxTextFieldStackedLabel }) })] })), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Labour Cost", name: `serviceLineItems[${index}].laborCost`, placeholder: "Enter Labour Cost", component: FxTextFieldStackedLabel }) })] }), _jsx(FieldArray, { name: `serviceLineItems[${index}].serviceParts`, children: (innerArrayHelpers) => {
                    return (_jsxs("div", { children: [map(get(values, `serviceLineItems[${index}].serviceParts`), (item, innerIndex) => {
                                return (_jsx(ServiceParts, { index: index, item: item, innerIndex: innerIndex, innerArrayHelpers: innerArrayHelpers, setPartsSubTotal: props.setPartsSubTotal, formikProps: formikProps, isEdit: isEdit, setIsCreatePartModalOpen: setIsCreatePartModalOpen, showUnitPartCostField: showUnitPartCostField, partOptions: partOptions }));
                            }), _jsxs("div", { className: "d-flex justify-content-between flex-wrap mb-2", children: [_jsx(Button, { color: "link", type: "button", onClick: () => {
                                            innerArrayHelpers.push(emptyServicePart);
                                        }, children: get(values, `serviceLineItems[${index}].serviceParts.length`, 0) > 0
                                            ? 'Add More Parts'
                                            : 'Add Parts' }), _jsxs("div", { className: "px-3 d-flex align-items-center font-lg", children: ["Labour Cost:", ' ', _jsxs("span", { className: "font-weight-bold mx-1", children: [currency, " ", round(labourCost, 2) || 0] }), ' ', "| Sub Total:", ' ', _jsxs("span", { className: "font-weight-bold ml-1", children: [currency, " ", round(subTotal, 2) || 0] })] })] })] }));
                } })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state) {
    return {
        loggedInUser: get(state, 'settings.loggedInUser'),
        serviceTasksList: get(state, 'servicetasks.serviceTasksList', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceLineItems);
