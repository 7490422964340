import { jsx as _jsx } from "react/jsx-runtime";
import { Timeline, ConfigProvider } from 'antd';
const FxTimeline = (props) => {
    const { themeModify, ...rest } = props;
    return (_jsx(ConfigProvider, { theme: {
            token: {
                ...themeModify,
            },
        }, children: _jsx(Timeline, { ...rest }) }));
};
export default FxTimeline;
