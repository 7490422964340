import axios from 'axios';
import { ROOT_API_URL, parseQueryParams } from '../../constant';
import { assign } from 'lodash';
import { isViewCustomFieldAllowedForUser } from '../../components/dashboard/roles/permission_utils';
export const FETCH_ALL_CUSTOM_FIELDS_FOR_ACCOUNT = 'fetch_all_custom_fields_for_account';
export const CREATE_CUSTOM_FIELD_FOR_ACCOUNT = 'create_custom_field_for_account';
export const UPDATE_ACCOUNT_PLAN_TYPE = 'update_account_plan_type';
export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';
export function fetchCustomFieldsForAccount(accesstoken, types, accountId) {
    //@ts-ignore
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    //@ts-ignore
    if (!isViewCustomFieldAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ALL_CUSTOM_FIELDS_FOR_ACCOUNT,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            accountId,
            types,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}accounts/custom-fields`, config);
    return {
        type: FETCH_ALL_CUSTOM_FIELDS_FOR_ACCOUNT,
        promise: request,
    };
}
export function createCustomFieldsForAccount(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const data = assign({}, values);
    const request = axios.post(`${ROOT_API_URL}accounts/custom-fields`, [data], config);
    return {
        type: CREATE_CUSTOM_FIELD_FOR_ACCOUNT,
        promise: request,
    };
}
export function updateCustomField(data, id) {
    const request = axios.put(`${ROOT_API_URL}accounts/custom-fields/${id}`, data);
    return {
        type: UPDATE_ACCOUNT_PLAN_TYPE,
        promise: request,
    };
}
export const deleteCustomField = (id) => {
    const request = axios.delete(`${ROOT_API_URL}accounts/custom-fields/${id}`);
    return {
        type: DELETE_CUSTOM_FIELD,
        promise: request,
    };
};
