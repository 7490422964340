import { PART_STORE_HISTORY_BY_ID } from 'actions/dashboard/part_stock_inventory/parts_entry_action';
import { map } from 'lodash';
import { handle } from 'redux-pack';
import { CREATE_PART, DELETE_PART, UPDATE_PART, FETCH_PARTS, FETCH_PART_BY_ID, FETCH_ALL_MEASUREMENT_UNITS, } from '../../actions/dashboard/parts_inventory/parts_action';
import { FILTER_PART_ENTRIES } from '../../actions/dashboard/parts_inventory/parts_entry_action';
import { FETCH_PART_MAKERS } from '../../actions/dashboard/parts_inventory/part_maker_action';
import { FETCH_PART_MODELS } from '../../actions/dashboard/parts_inventory/part_model_action';
import { FETCH_PART_CATEGORIES } from '../../actions/dashboard/parts_inventory/part_category_action';
import { FILTER_PART_ENTRIES_IN_STORES } from '../../actions/dashboard/parts_inventory/part_entry_store_action';
import { FETCH_WORK_ORDERS, FETCH_WORK_ORDER_SUMMARY, FETCH_WORK_ORDER_BY_ID, FETCH_ALL_WORK_ORDERS, } from '../../actions/dashboard/parts_inventory/work_order_action';
const initialState = {
    isLoading: false,
    error: null,
    partList: [],
    isPartListLoading: false,
    part: {},
    isPartLoading: false,
    partEntryList: [],
    isPartEntryListLoading: false,
    partMakerList: [],
    isPartMakerListLoading: false,
    partCategoryList: [],
    isPartCategoryListLoading: false,
    measurementUnits: [],
    isMeasurementUnitsLoading: false,
    partModelList: [],
    isPartModelsListLoading: false,
    totalPartEntryElements: 0,
    workOrderList: [],
    totalWorkOrderElements: 0,
    isWorkOrderListLoading: false,
    workOrderAll: [],
    isWorkOrderAllLoading: false,
    workOrder: {},
    isWorkOrderLoading: false,
    workOrderSummary: {},
    isWorkOrderSummaryLoading: false,
    partEntriesInStoreList: [],
    isPartEntriesInStoreListLoading: false,
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        //parts
        case CREATE_PART:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_PART:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_PART:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_PARTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    partList: payload.data,
                    partListAsMap: new Map(map(payload.data, (part) => [part.id, part])),
                }),
            });
        case FETCH_PART_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, part: payload.data }),
            });
        case FETCH_ALL_MEASUREMENT_UNITS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isMeasurementUnitsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isMeasurementUnitsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    measurementUnits: payload.data,
                }),
            });
        //parts-entries
        case FILTER_PART_ENTRIES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartEntryListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartEntryListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    partEntryList: payload.data.content,
                    totalPartEntryElements: payload.data.totalElements,
                }),
            });
        //part makers
        case FETCH_PART_MAKERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartMakerListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartMakerListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    partMakerList: payload.data,
                }),
            });
        //part categories
        case FETCH_PART_CATEGORIES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartCategoryListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartCategoryListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    partCategoryList: payload.data,
                }),
            });
        //part models
        case FETCH_PART_MODELS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isPartModelsListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartModelsListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    partModelList: payload.data,
                }),
            });
        //work orders
        case FETCH_ALL_WORK_ORDERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isWorkOrderAllLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isWorkOrderAllLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    workOrderAll: payload.data,
                }),
            });
        case FETCH_WORK_ORDERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isWorkOrderListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isWorkOrderListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    workOrderList: payload.data.content,
                    totalWorkOrderElements: payload.data.totalElements,
                }),
            });
        case FETCH_WORK_ORDER_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isWorkOrderSummaryLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isWorkOrderSummaryLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    workOrderSummary: payload.data,
                }),
            });
        case FETCH_WORK_ORDER_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isWorkOrderLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isWorkOrderLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    workOrder: payload.data,
                }),
            });
        //parts-entries in store
        case FILTER_PART_ENTRIES_IN_STORES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isPartEntriesInStoreListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isPartEntriesInStoreListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    partEntriesInStoreList: payload.data,
                }),
            });
        case PART_STORE_HISTORY_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isPartEntriesInStoreListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isPartEntriesInStoreListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    partStoreHistory: payload.data.content,
                    totalPartStoreHistoryElements: payload.data.totalElements,
                }),
            });
        default:
            return state;
    }
}
