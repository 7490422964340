import { getHumanizeTime, getReportTime } from 'utils/date_utils';
import { forEach, get } from 'lodash';
import { ORIENTATION, REPORT_FORMAT, startReportCreation } from 'utils/report_utils';
import { fetchUserListMiniV2 } from 'actions/dashboard/users_action';

export const triggerBatteryInventoryReport = async (batteryDetails, accessToken) => {
    const reportName = 'battery_inventory_report';

    let usersMap = {};
    try {
        const users = await fetchUserListMiniV2(accessToken, null).promise;
        forEach(get(users, 'data', []), (arr) => {
            usersMap[arr[0]] = arr[1] + ' ' + arr[2];
        });
    } catch (e) {
        console.log(e);
    }

    const headers = [
        {
            name: 'batteryNo',
            prompt: 'Battery No',
        },
        {
            name: 'store',
            prompt: 'Store',
        },
        {
            name: 'vendor',
            prompt: 'Vendor',
        },
        {
            name: 'model',
            prompt: 'Model',
        },
        {
            name: 'billNo',
            prompt: 'Bill Number.',
        },
        {
            name: 'purchaseDate',
            prompt: 'Purchase Date',
        },
        {
            name: 'po',
            prompt: 'PO Number',
        },
        {
            name: 'amount',
            prompt: 'Purchase Price',
        },
        {
            name: 'currentAmount',
            prompt: 'Current Price',
        },
        {
            name: 'licensePlate',
            prompt: 'Vehicle Assign',
        },
        {
            name: 'attachDate',
            prompt: 'Battery Attach Date',
        },
        {
            name: 'detachDate',
            prompt: 'Battery Detach Date',
        },
        {
            name: 'openingKm',
            prompt: 'Opening Km',
        },
        {
            name: 'kmRun',
            prompt: 'KM Run',
        },
        {
            name: 'warrantyInKm',
            prompt: 'Warranty In Km',
        },
        {
            name: 'usePercentage',
            prompt: 'Running(%)',
        },
        {
            name: 'warrantyInDays',
            prompt: 'Warranty In Days',
        },
        {
            name: 'remarks',
            prompt: 'Remarks',
        },
        {
            name: 'createdBy',
            prompt: 'Created By',
        },
        {
            name: 'createdDate',
            prompt: 'Created Date',
        },
        {
            name: 'lastModifiedBy',
            prompt: 'Last Modified By',
        },
    ];
    const data = [];
    forEach(batteryDetails, (battery) => {
        const purchaseDate = get(battery, 'purchaseDate') ? getReportTime(get(battery, 'purchaseDate')) : '';
        const attachDate = get(battery, 'attachDate') ? getReportTime(get(battery, 'attachDate')) : '';
        const detachDate = get(battery, 'detachDate') ? getReportTime(get(battery, 'detachDate')) : '';
        // const attachOdometerReading = get(battery, 'attachOdometerReading', '');
        // const detachOdometerReading = get(battery, 'detachOdometerReading', '');
        // const lastUsedVehicle = get(vehicleList[get(battery, 'lastUsedVehicle')], 'licensePlate', '-');
        const vendor = get(battery, 'vendor.name', '-');
        const store = get(battery, 'store.name', '-');
        data.push({
            ...battery,
            model: get(battery, 'model.name', ''),
            brandName: get(battery, 'model.brand.name', ''),
            purchaseDate,
            attachDate,
            detachDate,
            // attachOdometerReading,
            // detachOdometerReading,
            vendor,
            store,
            // lastUsedVehicle,
            createdBy: usersMap[battery.createdBy] || '',
            createdDate: battery.createdDate ? getHumanizeTime(battery.createdDate) : '',
            lastModifiedBy: usersMap[battery.lastModifiedBy] || '',
        });
    });
    //total row
    data.push({
        batteryNo: `TOTAL ${data.length}`,
    });
    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        REPORT_FORMAT.CSV,
        'Battery Inventory Report',
        null,
        null,
        null,
        reportName,
        null,
        null,
        null,
        true,
        '',
        '',
        '',
        false,
        null,
        {},
        true,
        null
    );
};

export const serviceTypeOptions = [
    {
        value: 'B2B',
        label: 'B2B',
    },
    {
        value: 'B2C',
        label: 'B2C',
    },
];
