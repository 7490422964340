import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_HIRE_SLIP = 'CREATE_HIRE_SLIP';
export const FETCH_HIRE_SLIPS = 'FETCH_HIRE_SLIPS';
export const FETCH_HIRE_SLIP = 'FETCH_HIRE_SLIP';
export const UPDATE_HIRE_SLIP = 'UPDATE_HIRE_SLIP';
export const DELETE_HIRE_SLIP = 'DELETE_HIRE_SLIP';
export const FETCH_HIRE_SLIP_SUMMARY = 'FETCH_HIRE_SLIP_SUMMARY';
export const FETCH_HIRE_SLIP_HISTORY = 'FETCH_HIRE_SLIP_HISTORY';
export function createHireSlip(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}hire-slip`, data, config);
    return {
        type: CREATE_HIRE_SLIP,
        promise: request,
    };
}
export function updateHireSlip(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}hire-slip`, data, config);
    return {
        type: UPDATE_HIRE_SLIP,
        promise: request,
    };
}
export function deleteHireSlip(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}hire-slip/${id}`, config);
    return {
        type: DELETE_HIRE_SLIP,
        promise: request,
    };
}
export function fetchHireSlips(accesstoken, params = {}) {
    const { startDate, endDate, hireSlipOffice, hireSlipNumber, hireSlipParty, pendingAmountNull, hsDelivery, sizePerPage, currentPage, vehicleId, sortName, sortOrder, } = params;
    let sort = '';
    if (sortName) {
        sort = `${sortName}`;
    }
    if (sortName && sortOrder) {
        sort += `,${sortOrder}`;
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            hireSlipOffice,
            hireSlip: hireSlipNumber,
            hireSlipParty,
            pendingAmountNull,
            hsDelivery,
            size: sizePerPage ? sizePerPage : 10,
            page: currentPage ? currentPage - 1 : 0,
            vehicleId,
            sort,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}hire-slip`, config);
    return {
        type: FETCH_HIRE_SLIPS,
        promise: request,
    };
}
export function fetchHireSlip(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}hire-slip/${id}`, config);
    return {
        type: FETCH_HIRE_SLIP,
        promise: request,
    };
}
export function fetchHireSlipSummary(accesstoken, params = {}) {
    const { startDate, endDate, hireSlipOffice, hireSlipNumber, hireSlipParty, pendingAmountNull, hsDelivery, vehicleId, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            hireSlipOffice,
            hireSlip: hireSlipNumber,
            hireSlipParty,
            pendingAmountNull,
            hsDelivery,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}hire-slip/summary`, config);
    return {
        type: FETCH_HIRE_SLIP_SUMMARY,
        promise: request,
    };
}
export function fetchHireSlipHistory(accesstoken, id, params = {}) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}hire-slip/history/${id}`, config);
    return {
        type: FETCH_HIRE_SLIP_HISTORY,
        promise: request,
    };
}
