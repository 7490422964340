import axios from 'axios';
import { ROOT_API_ALFRED, parseQueryParams, ROOT_REPORTING_NODE_API_URL, ROOT_NODE_API_URL } from '../../../constant';
export const CREATE_AUCTION = 'create_auction';
export const UPDATE_AUCTION = 'update_auction';
export const DELETE_AUCTION = 'delete_auction';
export const FETCH_AUCTION_BY_ID = 'fetch_auction_by_id';
export const FETCH_AUCTIONS = 'fetch_auctions';
export const DOWNLOAD_SAVING_REPORT = 'download_saving_report';
export const DOWNLOAD_PER_AUCTION_REPORT = 'download_per_auction_report';
export const AUCTION_ACTION = 'AUCTION_ACTION';
export const FETCH_AUCTION_ANALYTICS = 'FETCH_AUCTION_ANALYTICS';
export const FETCH_AUCTION_SUMMARY = 'FETCH_AUCTION_SUMMARY';
export const FETCH_AUCTION_TREND_ANALYTICS = 'FETCH_AUCTION_TREND_ANALYTICS';
const CancelToken = axios.CancelToken;
export function createAuction(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ALFRED}/auction`, values, config);
    return {
        type: CREATE_AUCTION,
        promise: request,
    };
}
export function updateAuction(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_ALFRED}/auction`, values, config);
    return {
        type: UPDATE_AUCTION,
        promise: request,
    };
}
export function deleteAuction(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_ALFRED}/auction/${id}`, config);
    return {
        type: DELETE_AUCTION,
        promise: request,
    };
}
export function fetchAuction(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ALFRED}/auction/${id}`, config);
    return {
        type: FETCH_AUCTION_BY_ID,
        promise: request,
    };
}
export function fetchAuctions(accesstoken, params = {}) {
    const { accountId = null, size, page, sort, status, bidType, fromDate, toDate, transporterIds, winningTransporterId, routeIds, vehicleSizeIds, preBidding, partBidding, autoExtension, jobBookingId, } = params;
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_fetch_auction';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            accountId,
            size,
            page: page - 1,
            sort,
            status,
            bidType,
            fromDate,
            toDate,
            transporterIds,
            winningTransporterId,
            routeIds,
            vehicleSizeIds,
            preBidding,
            partBidding,
            autoExtension,
            jobBookingId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/auction`, config);
    return {
        type: FETCH_AUCTIONS,
        promise: request,
    };
}
export function fetchDownloadAuctionSavingReport(accesstoken, params) {
    let format = {};
    if (params.reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/triggerAuctionSavingReport`, config);
    return {
        type: DOWNLOAD_SAVING_REPORT,
        promise: request,
    };
}
export function fetchDownloadPerAuctionReport(accesstoken, auctionId, reportFormat) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'blob' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            auctionId,
            reportFormat,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/triggerPerAuctionReport`, config);
    return {
        type: DOWNLOAD_SAVING_REPORT,
        promise: request,
    };
}
export function auctionAction(accesstoken, auctionId, action) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            auctionId,
            action,
        }),
    };
    const request = axios.put(`${ROOT_API_ALFRED}/auction/action`, null, config);
    return {
        type: AUCTION_ACTION,
        promise: request,
    };
}
export function fetchAuctionAnalytics(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/auction/analytics`, config);
    return {
        type: FETCH_AUCTION_ANALYTICS,
        promise: request,
    };
}
export function fetchAuctionSummary(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/auction/summary`, config);
    return {
        type: FETCH_AUCTION_SUMMARY,
        promise: request,
    };
}
export function fetchAuctionTrendAnalytics(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/overview/auction/trend/analytics`, config);
    return {
        type: FETCH_AUCTION_TREND_ANALYTICS,
        promise: request,
    };
}
