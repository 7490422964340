import { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap';
import { isEqual } from 'lodash';
import { REPORT_FORMAT } from '../../../utils/report_utils';
import { renderReportIncludeCol } from '../../../constant';
import DownloadButtonsContainer from './download_buttons_container';
import { isAgConstructionAccount } from '../../../utils/account_utils';

class DownloadServiceHistoryReportModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            includeDate: true,
            includeOdometer: true,
            includeRunningHours: true,
            includeLineItems: true,
            includePartsCost: true,
            includeLabourCost: true,
            includeDiscount: true,
            includeTax: true,
            includeQuantity: true,
            includeUnit: true,
            includeWorkType: true,
            includeNotes: true,
            includeVendorName: true,
            includeReference: true,
            level: '',
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onDownloadCSV = this.onDownloadCSV.bind(this);
        this.onDownloadPDF = this.onDownloadPDF.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    render() {
        return (
            <div>
                {/* <Button color="success" onClick={this.toggle}>
                    <i className="fa fa-download" />
                </Button> */}
                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle caret color="success" className="pos-rel">
                        <span>
                            <i
                                style={{ verticalAlign: 'bottom', fontSize: '1.05rem' }}
                                data-gacategory="Vehicles"
                                data-gaaction="Click"
                                data-galabel="assets-report report Report "
                                className="fa fxicon-download2"
                            />{' '}
                            <span className="mx-1">Reports</span>
                        </span>
                    </DropdownToggle>
                    <DropdownMenu style={{ width: '100%' }}>
                        <DropdownItem
                            onClick={() => {
                                this.setState({ level: 'item' }, () => {
                                    this.toggleModal();
                                });
                            }}
                            className="whitespace-normal"
                        >
                            <span data-gacategory="assets" data-gaaction="Click" data-galabel="assets-report-download">
                                Download Item Level Report
                            </span>
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => {
                                this.setState({ level: 'part' }, () => {
                                    this.props.onDownloadReport(REPORT_FORMAT.CSV, this.state);
                                });
                            }}
                            className="whitespace-normal"
                        >
                            <span data-gacategory="assets" data-gaaction="Click" data-galabel="assets-report-download">
                                Download Part Level Report
                            </span>
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
                <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Download Service History Report</ModalHeader>
                    <ModalBody>
                        <div>
                            Select the data to be in included in the report.
                            <div className="row mt-2">
                                {renderReportIncludeCol(
                                    'Date',
                                    'includeDate',
                                    this.state.includeDate,
                                    this.onCheckChanged
                                )}
                                {renderReportIncludeCol(
                                    'Odometer',
                                    'includeOdometer',
                                    this.state.includeOdometer,
                                    this.onCheckChanged
                                )}
                            </div>
                            <div className="row mt-2">
                                {renderReportIncludeCol(
                                    'Parts Cost',
                                    'includePartsCost',
                                    this.state.includePartsCost,
                                    this.onCheckChanged
                                )}
                                {renderReportIncludeCol(
                                    'Labour Cost',
                                    'includeLabourCost',
                                    this.state.includeLabourCost,
                                    this.onCheckChanged
                                )}
                            </div>
                            <div className="row mt-2">
                                {renderReportIncludeCol(
                                    'Discount',
                                    'includeDiscount',
                                    this.state.includeDiscount,
                                    this.onCheckChanged
                                )}
                                {renderReportIncludeCol(
                                    'Tax',
                                    'includeTax',
                                    this.state.includeTax,
                                    this.onCheckChanged
                                )}
                                {isAgConstructionAccount(this.props.loggedInUser) &&
                                    renderReportIncludeCol(
                                        'Quantity',
                                        'includeQuantity',
                                        this.state.includeQuantity,
                                        this.onCheckChanged
                                    )}
                                {isAgConstructionAccount(this.props.loggedInUser) &&
                                    renderReportIncludeCol(
                                        'Unit',
                                        'includeUnit',
                                        this.state.includeUnit,
                                        this.onCheckChanged
                                    )}
                                {isAgConstructionAccount(this.props.loggedInUser) &&
                                    renderReportIncludeCol(
                                        'Work Type',
                                        'includeWorkType',
                                        this.state.includeWorkType,
                                        this.onCheckChanged
                                    )}
                                {isAgConstructionAccount(this.props.loggedInUser) &&
                                    renderReportIncludeCol(
                                        'Notes',
                                        'includeNotes',
                                        this.state.includeNotes,
                                        this.onCheckChanged
                                    )}
                            </div>
                            <div className="row mt-2">
                                {renderReportIncludeCol(
                                    'Vendor Name',
                                    'includeVendorName',
                                    this.state.includeVendorName,
                                    this.onCheckChanged
                                )}

                                {renderReportIncludeCol(
                                    'Reference No',
                                    'includeReference',
                                    this.state.includeReference,
                                    this.onCheckChanged
                                )}

                                {renderReportIncludeCol(
                                    'Running Hours',
                                    'includeRunningHours',
                                    this.state.includeRunningHours,
                                    this.onCheckChanged
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="d-block">
                        <DownloadButtonsContainer
                            gacategory="Service History"
                            galabelPrefix={`service history report`}
                            onDownloadEXCEL={this.onDownloadCSV}
                            onDownloadPDF={this.onDownloadPDF}
                            onCloseClick={this.toggleModal}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    }

    onCheckChanged(event) {
        const newState = {};
        newState[event.currentTarget.dataset.key] = event.target.checked;
        this.setState(newState);
    }

    onDownloadCSV() {
        this.toggleModal();
        this.props.onDownloadReport(REPORT_FORMAT.CSV, this.state);
    }

    onDownloadPDF() {
        this.toggleModal();
        this.props.onDownloadReport(REPORT_FORMAT.PDF, this.state);
    }
}

DownloadServiceHistoryReportModalComponent.propTypes = {
    onDownloadReport: PropTypes.func.isRequired,
};

export default DownloadServiceHistoryReportModalComponent;
