import {handle} from "redux-pack";

import {
    CREATE_FUEL_ENTRY,
    DELETE_FUEL_ENTRY,
    FETCH_FUEL_ENTRIES,
    FETCH_FUEL_ENTRIES_IN_BETWEEN,
    FETCH_FUEL_ENTRY,
    UPDATE_FUEL_ENTRY
} from "../../../actions/dashboard/easy/fuel_entry_action";


const initialState = {
    isLoading: false, error: null, data: {}, fuelEntryList: []
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case CREATE_FUEL_ENTRY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case UPDATE_FUEL_ENTRY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),

            });
        case DELETE_FUEL_ENTRY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, error: null
                }),
                finish: prevState => ({...prevState}),
                failure: prevState => ({...prevState, error: payload})
            });

        case FETCH_FUEL_ENTRIES :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, fuelEntryList: payload.data.content
                }),
            });

        case FETCH_FUEL_ENTRIES_IN_BETWEEN :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, fuelEntryList: payload.data.content
                }),
            });


        case FETCH_FUEL_ENTRY :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({...prevState, data: {...prevState.data, [payload.data.id]: payload.data}})
            });
        default:
            return state;
    }
}