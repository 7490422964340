import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const HistorySvg = (props) => {
    return (_jsx("svg", { width: "12px", height: "12px", viewBox: "0 0 12 12", version: "1.1", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M1.758 1.758.96.96A.563.563 0 0 0 0 1.357v2.58c0 .312.25.563.563.563h2.58a.563.563 0 0 0 .398-.96l-.721-.723a4.501 4.501 0 1 1 .61 6.877.752.752 0 0 0-.861 1.23 6 6 0 1 0-.81-9.166zM6 3a.561.561 0 0 0-.563.563V6c0 .15.06.293.165.398l1.687 1.688a.562.562 0 0 0 .795-.795L6.56 5.768V3.563A.561.561 0 0 0 5.998 3H6z", fill: "#333" }) }));
};
const PartHistoryIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: HistorySvg, ...props });
};
export default PartHistoryIcon;
