export const kmColAlarmReportMapping = [
    { prompt: 'Alarm Type', name: 2 },
    { prompt: 'Time', name: 4 },
    { prompt: 'Vehicle Number', name: 'vehicleNumber' },
    { prompt: 'Start Date', name: 'startDate' },
    { prompt: 'Start Time', name: 'startTime' },
    { prompt: 'Start Latitude', name: 'startLatitude' },
    { prompt: 'Start Longitude', name: 'startLongitude' },
    { prompt: 'End Date', name: 'endDate' },
    { prompt: 'End Time', name: 'endTime' },
    { prompt: 'End Latitude', name: 'endLatitude' },
    { prompt: 'End Longitude', name: 'endLongitude' },
    { prompt: 'End Location', name: 'endLocation' },
    { prompt: 'Latitude', name: 'latitude' },
    { prompt: 'Longitude', name: 'longitude' },
    { prompt: 'Distance', name: 'distance' },
    { prompt: 'Duration', name: 'duration' },
    { prompt: 'Max Speed (Km/hr)', name: 'maxSpeed' },
    { prompt: 'Running Time', name: 'runningHrs' },
    { prompt: 'Stop Time', name: 'stopHrs' },
    { prompt: 'View Map', name: 'mapLink' },
];
