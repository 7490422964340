import axios from 'axios';
import {
    parseQueryParams,
    ROOT_API_ERP,
    ROOT_API_URL,
    ROOT_NODE_API_URL,
    ROOT_REPORTING_NODE_API_URL,
} from '../../constant';

export const FETCH_TYRE_BRANDS = 'fetch_tyre_brands';
export const FETCH_TYRE_MODEL = 'fetch_tyre_model';
export const FETCH_TYRE_LIST = 'fetch_tyre_list';
export const FETCH_TYRE_LISTV2 = 'fetch_tyre_list_V2';
export const FETCH_TYRE_ID = 'fetch_tyre_id';
export const FETCH_TYRE_SUMMARY = 'fetch_tyre_summary';
export const FETCH_TYRE_CONFIGURATION_MASTER = 'fetch_tyre_configuration_master';
export const FETCH_TYRE_CONFIGURATION_ACCOUNT = 'fetch_tyre_configuration_account';
export const ADD_NEW_TYRE = 'add_new_tyre';
export const ARCHIVE_TYRE = 'archive_tyre';
export const ADD_NEW_TYRE_CONFIGURATION = 'add_new_tyre_configuration';
export const UPDATE_ACCOUNT_TYRE_CONFIG = 'add_new_tyre_configuration';
export const FETCH_TYRE_HISTORY = 'fetch_tyre_history';
export const FETCH_VEHICLE_TYRE_HISTORY = 'fetch_vehicle_tyre_history';
export const ATTACH_CONFIGURATION_TO_VEHICLE = 'attach_configuration_to_vehicle';
export const ATTACH_TYRE_TO_VEHICLE = 'attach_tyre_to_vehicle';
export const ATTACH_TYRE_TO_VEHICLE_BULK = 'attach_tyre_to_vehicle_bulk';
export const REMOVE_TYRE_FROM_VEHICLE = 'remove_tyre_from_vehicle';
export const ALL_ATTACHED_TYRES_TO_VEHICLE = 'all_attached_tyres_to_vehicle';
export const DELETE_TYRE_CONFIGURATION = 'delete_tyre_configuration';
export const FETCH_TYRE_HISTORY_REPORT = 'fetch_tyre_history_report';
export const TYRE_INVENTORY_REPORT = 'TYRE_INVENTORY_REPORT';

export function fetchTyreTypes(accesstoken) {
    const config = { 'headers': { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}tyres/brands/`, config);

    return {
        'type': FETCH_TYRE_BRANDS,
        'promise': request,
    };
}
export function fetchTyreModel(accesstoken) {
    const config = { 'headers': { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}tyres/models/`, config);

    return {
        'type': FETCH_TYRE_MODEL,
        'promise': request,
    };
}

export function fetchTyreSummary(
    accesstoken,
    vehicleId,
    startP,
    endP,
    enabled,
    brandId,
    vendorId,
    storeId,
    tyreSize,
    attachedStatus,
    attachType,
    profitable,
    tagIds,
    tyreDateFilter,
    fromDate,
    toDate,
    tyreNo = '',
    size = 5000
) {
    const config = {
        'headers': { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            startP,
            endP,
            enabled: !enabled,
            brandId,
            vendorId,
            storeId,
            tyreSize,
            attachedStatus,
            attachType,
            profitable,
            tagIds: tagIds ? tagIds.join(',') : '',
            tyreDateFilter,
            fromDate,
            toDate,
            size,
            tyreNo,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/account/summary/`, config);

    return {
        'type': FETCH_TYRE_SUMMARY,
        'promise': request,
    };
}

export const fetchTyreMasterConfiguration = (accesstoken) => {
    const config = { 'headers': { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}tyres/configurations-master`, config);

    return {
        'type': FETCH_TYRE_CONFIGURATION_MASTER,
        'promise': request,
    };
};
export const fetchTyreAccountConfiguration = (accesstoken) => {
    const config = { 'headers': { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_ERP}tyres/configurations`, config);
    return {
        'type': FETCH_TYRE_CONFIGURATION_ACCOUNT,
        'promise': request,
    };
};

export function fetchTyreList(accesstoken, id, page = 1, size = 5000) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request;
    if (id) {
        request = axios.get(`${ROOT_API_ERP}tyres/?id=${id}`, config);
        return {
            'type': FETCH_TYRE_ID,
            'promise': request,
        };
    } else {
        request = axios.get(`${ROOT_API_ERP}tyres`, config);
        return {
            'type': FETCH_TYRE_LIST,
            'promise': request,
        };
    }
}

export function fetchTyreByIdV2(accesstoken, id) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/search?id=${id}`, config);
    return {
        'type': FETCH_TYRE_ID,
        'promise': request,
    };
}

export function fetchTyreListV2(
    accesstoken,
    vehicleId,
    tyreSize,
    startRunP,
    endRunP,
    storeId,
    enabled,
    page = 1,
    size = 5000
) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            vehicleId,
            tyreSize,
            startRunP,
            endRunP,
            storeId,
            page: page - 1,
            size,
            enabled,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/search`, config);
    return {
        'type': FETCH_TYRE_LISTV2,
        'promise': request,
    };
}

export function fetchTyreListV2P1(
    accesstoken,
    vehicleId,
    startP,
    endP,
    enabled,
    brandId,
    vendorId,
    storeId,
    tyreSize,
    attachedStatus,
    attachType,
    profitable,
    tagIds,
    tyreDateFilter,
    fromDate,
    toDate,
    tyreNo,
    size = 5000
) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            vehicleId,
            startP,
            endP,
            enabled: !enabled,
            brandId,
            vendorId,
            storeId,
            tyreSize,
            attachedStatus,
            attachType,
            profitable,
            tagIds: tagIds ? tagIds.join(',') : '',
            tyreDateFilter,
            fromDate,
            toDate,
            size,
            tyreNo,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}tyres/search`, config);
    return {
        'type': FETCH_TYRE_LISTV2,
        'promise': request,
    };
}

export function fetchTyreById(accesstoken, id, page = 1, size = 2000) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request;
    if (id) {
        request = axios.get(`${ROOT_API_ERP}tyres/?id=${id}`, config);
        return {
            'type': FETCH_TYRE_ID,
            'promise': request,
        };
    }
}

export function fetchAttachedTyresToVehicle(vehicleId, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request;
    if (vehicleId) {
        request = axios.get(`${ROOT_API_ERP}tyres/?vehicleId=${vehicleId}`, config);
        return {
            'type': ALL_ATTACHED_TYRES_TO_VEHICLE,
            'promise': request,
        };
    }
}

export function attachTyreConfigurationToVehicle(data, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.put(
        `${ROOT_API_URL}vehicles/tyre-configuration?vehicleId=${data.vehicleId}&configurationId=${data.configurationId}`,
        '',
        config
    );
    return {
        'type': ATTACH_CONFIGURATION_TO_VEHICLE,
        'promise': request,
    };
}
export function attachTyreToVehicle(data, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.put(`${ROOT_API_ERP}tyres/vehicle-attach`, data, config);
    return {
        'type': ATTACH_TYRE_TO_VEHICLE,
        'promise': request,
    };
}
export function swapTyreWithinVehicle(data, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.put(`${ROOT_API_ERP}tyres/vehicle-attach/bulk`, data, config);
    return {
        'type': ATTACH_TYRE_TO_VEHICLE_BULK,
        'promise': request,
    };
}
export function removeTyreFromVehicle(id, removeTime, accesstoken, storeId, detachOdometerReading, images = []) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams({
            ids: id,
            eventTime: removeTime,
            storeId,
            detachOdometerReading,
        }),
    };
    let request = axios.put(`${ROOT_API_ERP}tyres/vehicle-detach`, images, config);
    return {
        'type': REMOVE_TYRE_FROM_VEHICLE,
        'promise': request,
    };
}

export function addTyre(data, isEdit, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request;
    if (isEdit && data.id) {
        request = axios.put(`${ROOT_API_ERP}tyres/${data.id}`, data, config);
    } else {
        request = axios.post(`${ROOT_API_ERP}tyres/`, data, config);
    }
    return {
        'type': ADD_NEW_TYRE,
        'promise': request,
    };
}

export function archiveTyre(accesstoken, data, type) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request;
    if (data.id) {
        request = axios.patch(`${ROOT_API_ERP}tyres/${type}/${data.id}`, data, config);
    }
    return {
        'type': ARCHIVE_TYRE,
        'promise': request,
    };
}
export function addTyreConfiguration(data, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.post(`${ROOT_API_ERP}tyres/configurations?masterId=${data}`, '', config);
    return {
        'type': ADD_NEW_TYRE_CONFIGURATION,
        'promise': request,
    };
}
export function updateTyreConfig(data, id, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.put(`${ROOT_API_ERP}tyres/configurations/${id}`, data, config);
    return {
        'type': UPDATE_ACCOUNT_TYRE_CONFIG,
        'promise': request,
    };
}
export function fetchTyreHistory(data, id, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.get(`${ROOT_API_ERP}tyres/audit/summary/?tyreId=${id}`, config);
    return {
        'type': FETCH_TYRE_HISTORY,
        'promise': request,
    };
}
export function fetchVehicleTyreHistory(id, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.get(`${ROOT_API_ERP}tyres/audit/summary?vehicleId=${id}`, config);
    return {
        'type': FETCH_VEHICLE_TYRE_HISTORY,
        'promise': request,
    };
}
export function deleteTyreConfigurationFromAccount(id, accesstoken) {
    const config = {
        'headers': {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request = axios.delete(`${ROOT_API_ERP}tyres/configurations/${id}`, config);
    return {
        'type': DELETE_TYRE_CONFIGURATION,
        'promise': request,
    };
}

export function fetchTyreHistoryReport(accesstoken, reportFormat, id, vehicleId) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            id,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getTyreHistoryReport`, config);

    return {
        type: FETCH_TYRE_HISTORY_REPORT,
        promise: request,
    };
}

export function triggerTyreInventoryReport(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams(params),
    };

    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/tyreInventoryReport`, config);

    return {
        type: TYRE_INVENTORY_REPORT,
        promise: request,
    };
}
