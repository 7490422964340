import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditUserComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditUser" */ 'components/dashboard/users/add_edit_user_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewUserComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-viewUser" */ 'components/dashboard/users/view_user_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const UsersComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-settings-users" */ 'components/dashboard/users/users_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const usersRoutes = [
    {
        index: true,
        lazy: UsersComponent,
    },
    {
        path: 'add',
        lazy: AddEditUserComponent,
    },
    {
        path: 'view/:id',
        lazy: ViewUserComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditUserComponent,
    },
];
export default usersRoutes;
