import { handle } from 'redux-pack';
import {
    CREATE_SERVICE_REMINDER,
    DELETE_SERVICE_REMINDER,
    FETCH_SERVICE_REMINDER,
    FETCH_SERVICE_REMINDERS,
    FETCH_SERVICE_REMINDERS_COUNT,
    FETCH_SERVICE_REMINDERS_VEHICLE_WISE_COUNT,
    UPDATE_SERVICE_REMINDER,
} from '../../../actions/dashboard/easy/service_reminders_action';

const initialState = {
    isLoading: false,
    error: null,
    serviceReminderList: [],
    data: {},
    isCountLoading: false,
    vehicleWiseCount: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_SERVICE_REMINDER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_SERVICE_REMINDER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_SERVICE_REMINDER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_SERVICE_REMINDERS_COUNT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isCountLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isCountLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_SERVICE_REMINDERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    serviceReminderList: payload.data.content,
                }),
            });

        case FETCH_SERVICE_REMINDER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        case FETCH_SERVICE_REMINDERS_VEHICLE_WISE_COUNT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    vehicleWiseCount: payload.data,
                }),
            });
        default:
            return state;
    }
}
