import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_CLIENT_CONTRACT_CHART = 'CREATE_CLIENT_VENDOR_CHART';
export const UPDATE_CLIENT_CONTRACT_CHART = 'UPDATE_CLIENT_CONTRACT_CHART';
export const FETCH_CLIENT_CONTRACT_CHART_BY_ID = 'FETCH_CLIENT_CONTRACT_CHART_BY_ID';
export const DELETE_CLIENT_CONTRACT_CHART_BY_ID = 'FETCH_CLIENT_CONTRACT_CHART_BY_ID';
export const FETCH_CLIENT_CONTRACT_CHARTS = 'FETCH_CLIENT_CONTRACT_CHARTS';
export function createClientContractChart(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}contract-chart`, data, config);
    return {
        type: CREATE_CLIENT_CONTRACT_CHART,
        promise: request,
    };
}
export function updateClientContractChart(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}contract-chart`, data, config);
    return {
        type: UPDATE_CLIENT_CONTRACT_CHART,
        promise: request,
    };
}
export function fetchClientContractCharts(accesstoken, clientId = null, validFrom = null, size = 100, otherParams = {
    validTo: null,
    from: null,
    to: null,
    contractNumber: '',
    branchId: null,
    routeId: null,
    billingNature: null,
    vehicleIds: null,
    routeIds: null,
}) {
    const { validTo, from, to, contractNumber, branchId, routeId, billingNature, vehicleIds, routeIds } = otherParams;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            clientId,
            size,
            validFrom,
            validTo,
            from,
            to,
            contractNumber,
            branchId,
            routeId,
            billingNature,
            vehicleIds,
            routeIds,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}contract-chart`, config);
    return {
        type: FETCH_CLIENT_CONTRACT_CHARTS,
        promise: request,
    };
}
export function fetchClientContractChartById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}contract-chart/${id}`, config);
    return {
        type: FETCH_CLIENT_CONTRACT_CHART_BY_ID,
        promise: request,
    };
}
export function deleteClientContractChart(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}contract-chart/${id}`, config);
    return {
        type: DELETE_CLIENT_CONTRACT_CHART_BY_ID,
        promise: request,
    };
}
