import { Component } from 'react';
import { FxReactSelect as Select } from '../../../../shared';
import { FxSelect } from 'sharedV2';
import { connect } from 'react-redux';
import { debounce, filter, map, includes, toLower, get } from 'lodash';
import { fetchAddressBook } from '../../../../actions/dashboard/route_dispatch/address_book_action';
import { SELECT_OPTION_LIMIT_ROUTE } from '../../../../constant';
import { withTranslation } from 'react-i18next';

class SelectRoute extends Component {
    filterCount = 0;
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            crazy: false,
            clearable: true,
        };
    }

    handleSelectChange = (value) => {
        this.props.handleRouteSelectChange(value);
    };

    customFilterOption = (option, filter, options) => {
        if (this.filterCount < SELECT_OPTION_LIMIT_ROUTE) {
            if (
                includes(toLower(get(option, 'label', '')), toLower(filter)) ||
                includes(toLower(get(option, 'value', '')), toLower(filter))
            ) {
                this.filterCount++;
                return true;
            }
            return false;
        } else {
            return false;
        }
    };

    resetFilterCount = () => {
        this.filterCount = 0;
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                {this.props.onAntPage ? (
                    <FxSelect
                        key="select-vehicle-dropdown"
                        placeholder={t('selectRoute')}
                        value={this.props.value}
                        options={this.props.options}
                        allowClear={this.state.clearable}
                        isLoading={this.props.loading}
                        onKeyDown={this.resetFilterCount}
                        onChange={this.handleSelectChange}
                        filterOption={(input, option) => {
                            return (
                                includes(toLower(get(option, 'label', '')), toLower(input)) ||
                                includes(toLower(get(option, 'value', '')), toLower(input))
                            );
                        }}
                    />
                ) : (
                    <Select
                        simpleValue
                        key="select-vehicle-dropdown"
                        placeholder={t('selectRoute')}
                        value={this.props.value}
                        options={this.props.options}
                        clearable={this.state.clearable}
                        isLoading={this.props.loading}
                        onKeyDown={this.resetFilterCount}
                        filterOption={this.customFilterOption}
                        onChange={this.handleSelectChange}
                    />
                )}
            </div>
        );
    }
}

function mapStateToProps(state, ownprops) {
    return {
        accesstoken: state.login.data.access_token,
        loggedInUser: state.settings.loggedInUser,
    };
}

export default connect(mapStateToProps, {})(withTranslation()(SelectRoute));
