import axios from 'axios';
import { ROOT_API_URL } from 'constant';

export const CREATE_VEHICLE_RENEWAL_TYPE = 'create_vehicle_renewal_type';
export const FETCH_VEHICLE_RENEWAL_TYPES = 'fetch_vehicle_renewal_types';
export const FETCH_VEHICLE_RENEWAL_TYPE = 'fetch_vehicle_renewal_type';
export const UPDATE_VEHICLE_RENEWAL_TYPE = 'update_vehicle_renewal_type';
export const DELETE_VEHICLE_RENEWAL_TYPE = 'delete_vehicle_renewal_type';

export function createVehicleRenewalType(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}vehicle_renewal_types/`, values, config);
    return {
        type: CREATE_VEHICLE_RENEWAL_TYPE,
        promise: request,
    };
}

export function updateVehicleRenewalType(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}vehicle_renewal_types/${id}`, values, config);

    return {
        type: UPDATE_VEHICLE_RENEWAL_TYPE,
        promise: request,
    };
}

export function fetchVehicleRenewalTypes(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_types/`, config);

    return {
        type: FETCH_VEHICLE_RENEWAL_TYPES,
        promise: request,
    };
}

export function fetchVehicleRenewalType(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_types/${id}`, config);

    return {
        type: FETCH_VEHICLE_RENEWAL_TYPE,
        promise: request,
    };
}

export function deleteVehicleRenewalType(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}vehicle_renewal_types/${id}`, config);
    return {
        type: DELETE_VEHICLE_RENEWAL_TYPE,
        promise: request,
    };
}
