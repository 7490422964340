import { useMemo } from 'react';
import Select from 'react-select';
import { find, map, get } from 'lodash';

const getSimpleValue = (option, props) => {
    if (option) {
        return props.multi ? map(option, (item) => item.value) : option && option.value;
    } else {
        return props.multi ? [] : null;
    }
};

const getSelectedOptionsFromSimpleValue = (selectedValue, props) => {
    if (selectedValue || typeof selectedValue === 'number') {
        return props.multi
            ? map(selectedValue, (value) => {
                  return find(props.options, { value: value });
              })
            : find(props.options, (option) => get(option, 'value') == selectedValue);
    } else {
        return props.multi ? [] : null;
    }
};

//issue in react-select doesn't handle this select
const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
};

export default (passedProps) => {
    const {
        simpleValue,
        value,
        onChange,
        clearable,
        style,
        optionRenderer,
        disabled,
        valueRenderer,
        providedCustomStyles = {},
        ...props
    } = passedProps;

    const handleOnChange = (option, action) => {
        if (simpleValue) {
            onChange(getSimpleValue(option, passedProps), action);
        } else {
            onChange(option ? option : props.multi ? [] : null, action);
        }
    };

    const getSelectedOption = (value) => {
        if (
            simpleValue ||
            typeof value !== 'object' ||
            (Array.isArray(value) && value.length > 0 && typeof value[0] !== 'object')
        ) {
            return getSelectedOptionsFromSimpleValue(value, passedProps);
        } else {
            return value;
        }
    };

    const customStyles = useMemo(() => {
        return {
            menu: (provided, state) => ({
                ...provided,
                zIndex: 5,
            }),
            container: (provided, state) => ({
                ...provided,
                ...style,
            }),
            option: (styles, state) => ({
                ...styles,
                cursor: 'pointer',
            }),
            control: (styles) => ({
                ...styles,
                cursor: 'pointer',
            }),
            ...providedCustomStyles,
        };
    }, [style]);

    return (
        <div onClick={stopPropagation}>
            <Select
                filterOption={
                    props.filterOption
                        ? (option, filter) => props.filterOption(option, filter, props.options)
                        : undefined
                }
                {...props}
                ref={props.innerRef}
                styles={customStyles}
                isDisabled={disabled}
                getOptionValue={valueRenderer}
                formatOptionLabel={optionRenderer}
                isMulti={props.multi}
                isClearable={clearable !== false}
                value={getSelectedOption(value)}
                onChange={handleOnChange}
                onKeyDown={props.onKeyDown ? props.onKeyDown : undefined}
            />
        </div>
    );
};
