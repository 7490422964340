import { jsx as _jsx } from "react/jsx-runtime";
import { Button, ConfigProvider } from 'antd';
import { DEFAULT_SIZE } from '../theme/utils';
const FxButton = (props) => {
    const { themeModify, innerRef, ...rest } = props;
    const themeLocal = {
        token: {
            ...themeModify,
        },
    };
    return (_jsx(ConfigProvider, { theme: themeLocal, children: _jsx(Button, { ref: innerRef, size: DEFAULT_SIZE, className: `reset-bootstrap-for-antd ${props.className ? props.className : ''}`, ...rest }) }));
};
export default FxButton;
