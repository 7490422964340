import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Job = (props) => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", children: _jsx("path", { d: "M3.01281 12.7995C2.77815 12.7995 2.57281 12.7153 2.39681 12.5468C2.22081 12.3784 2.13281 12.1819 2.13281 11.9574V5.22053C2.13281 4.99597 2.22081 4.79948 2.39681 4.63106C2.57281 4.46264 2.77815 4.37843 3.01281 4.37843H5.65281V2.97492C5.65281 2.75036 5.74081 2.55387 5.91681 2.38544C6.09281 2.21702 6.29815 2.13281 6.53281 2.13281H9.46615C9.70081 2.13281 9.90615 2.21702 10.0821 2.38544C10.2581 2.55387 10.3461 2.75036 10.3461 2.97492V4.37843H12.9861C13.2208 4.37843 13.4261 4.46264 13.6021 4.63106C13.7781 4.79948 13.8661 4.99597 13.8661 5.22053V11.9574C13.8661 12.1819 13.7781 12.3784 13.6021 12.5468C13.4261 12.7153 13.2208 12.7995 12.9861 12.7995H3.01281ZM3.01281 11.9574H12.9861V5.22053H3.01281V11.9574ZM6.53281 4.37843H9.46615V2.97492H6.53281V4.37843Z", fill: "#333333" }) }));
};
const JobIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Job, ...props });
};
export default JobIcon;
