import { handle } from 'redux-pack';
import {
    ADD_TRANSACTION,
    UPDATE_TRANSACTION,
    DELETE_TRANSACTION,
    FETCH_TRANSACTIONS_BY_DRIVER,
    FETCH_TRANSACTIONS,
    FETCH_TRANSACTION,
} from '../../../actions/dashboard/finances/transaction_action';
import { FETCH_JOB_BOOKING } from '../../../actions/dashboard/route_dispatch/job_booking_action';

const initialState = {
    isLoading: false,
    error: null,
    data: {},
    transactionList: [],
};

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_TRANSACTION:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
            });
        case UPDATE_TRANSACTION:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
            });

        case DELETE_TRANSACTION:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
            });

        case FETCH_TRANSACTIONS_BY_DRIVER:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isJobListLoading: true,
                }),
                finish: prevState => ({ ...prevState, isLoading: false, isJobListLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    data: payload.data,
                }),
            });
        case FETCH_TRANSACTIONS:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({
                    ...prevState,
                    transactionList: payload.data,
                }),
            });
        case FETCH_TRANSACTION:
            return handle(state, action, {
                start: prevState => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: prevState => ({ ...prevState, isLoading: false }),
                failure: prevState => ({ ...prevState, error: payload }),
                success: prevState => ({ ...prevState, data: { ...prevState.data, [payload.data.id]: payload.data } }),
            });

        default:
            return state;
    }
}
