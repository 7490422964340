import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { FxCol, FxRow, FxSelectField, FxTextFieldStackedLabelV2 } from '../../../sharedV2';
import { fetchLedgers } from 'actions/dashboard/consignments/ledgersActions';
import { getFilteredAndMappedLedgerBasedOnBaseGroup } from 'utils/ERPProfileUtils';
const LedgerDetailsComponent = (props) => {
    const { accesstoken, ledgerDetailsFields, ledgers, ledgersFromParent, isNewRow = true } = props;
    const finalLedgers = ledgersFromParent ? ledgersFromParent : ledgers;
    const { t } = useTranslation();
    useEffect(() => {
        if (!ledgersFromParent)
            props.fetchLedgers(accesstoken);
    }, []);
    const wrapper = (children) => {
        if (isNewRow) {
            return _jsx(FxRow, { gutter: 16, children: children });
        }
        else {
            return _jsx(_Fragment, { children: children });
        }
    };
    return wrapper(_jsx(_Fragment, { children: map(ledgerDetailsFields, (field) => {
            const name = get(field, 'name', '');
            const onChange = get(field, 'onChange', (ledgers) => { });
            const disabled = get(field, 'disabled', false);
            const label = get(field, 'label', '');
            const optionsFilter = get(field, 'optionsFilter', getFilteredAndMappedLedgerBasedOnBaseGroup);
            const options = useMemo(() => {
                return optionsFilter(finalLedgers) || [];
            }, [finalLedgers]);
            const shouldShowField = get(field, 'shouldShowField', true);
            const shouldShowAmountField = get(field, 'shouldShowAmountField', false);
            const amountFieldLabel = get(field, 'amountFieldInfo.label', '');
            const amountFieldName = get(field, 'amountFieldInfo.name', '');
            const isAmountFieldRequired = get(field, 'amountFieldInfo.isAmountFieldRequired', false);
            const isAmountFieldsDisabled = get(field, 'amountFieldInfo.disabled', false);
            const hasSibling = get(field, 'hasSibling', false);
            const siblingComponent = get(field, 'sibling', () => _jsx(_Fragment, {}));
            const isRequired = get(field, 'isRequired', false);
            const colSize = get(field, 'colSize', 8);
            const amountFieldType = get(field, 'amountFieldInfo.type', 'text');
            const placeholder = get(field, 'placeholder', null);
            return (_jsxs(_Fragment, { children: [shouldShowField && (_jsx(FxCol, { span: colSize, children: _jsx(Field, { name: name, component: FxSelectField, options: options, label: label, onChange: (val) => onChange(finalLedgers, val), disabled: disabled, isRequired: isRequired, clearable: true, placeholder: placeholder }) })), shouldShowAmountField && (_jsx(FxCol, { span: colSize, children: _jsx(Field, { type: amountFieldType, name: amountFieldName, component: FxTextFieldStackedLabelV2, label: amountFieldLabel, isRequired: isAmountFieldRequired, disabled: isAmountFieldsDisabled, placeholder: placeholder }) })), hasSibling && siblingComponent()] }));
        }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchLedgers }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        ledgers: state.consignments.ledgers,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LedgerDetailsComponent);
