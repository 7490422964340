import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import ErrorBoundary from 'components/utils/error_boundary_component';
import AddEditTyreComponentV2 from 'components/dashboard/tyres/v2/add_edit_tyre_component';
import IssueRemoveTyreComponent from 'components/dashboard/tyres/issue_remove_tyre_component';
const AddEditTyreComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-add-edit-tyre" */ 'components/dashboard/tyres/add_edit_tyre_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const TyreManagement = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-tyre-management" */ 'components/dashboard/tyres/tyre_management'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddTyreToVehicle = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-add-tyre-to-vehicle" */ 'components/dashboard/tyres/add_tyre_to_vehicle'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewTyreDetails = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-view-tyre-details" */ 'components/dashboard/tyres/view_tyre_details'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const CreateTyreInspectionChecklist = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-create-tyre-inspection-checklist" */ 'components/dashboard/vehicles/inspectionChecklist/CreateTyreInspectionChecklist'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const typreRoutes = [
    {
        index: true,
        lazy: TyreManagement,
    },
    {
        path: 'inward',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditTyreComponentV2, { tyreFlow: "INWARD" }) })),
    },
    {
        path: 'outward',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditTyreComponentV2, { tyreFlow: "OUTWARD" }) })),
    },
    {
        path: 'issue',
        element: (_jsxs(ErrorBoundary, { children: [' ', _jsx(IssueRemoveTyreComponent, { tyreFlow: "ISSUANCE" })] })),
    },
    {
        path: 'remove',
        element: (_jsx(ErrorBoundary, { children: _jsx(IssueRemoveTyreComponent, { tyreFlow: "REMOVAL" }) })),
    },
    {
        path: 'edit/:id',
        lazy: AddEditTyreComponent,
    },
    {
        path: 'add',
        lazy: AddEditTyreComponent,
    },
    {
        path: 'addtovehicle/:id',
        lazy: AddTyreToVehicle,
    },
    {
        path: 'view/:id',
        lazy: ViewTyreDetails,
    },
    {
        path: 'createinspectionchecklist',
        lazy: CreateTyreInspectionChecklist,
    },
];
export default typreRoutes;
