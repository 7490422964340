import { handle } from 'redux-pack';
import {
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_V2,
    FETCH_ACCOUNT_FOR_USER,
    FETCH_ALL_ACCOUNTS_FOR_USER,
    UPDATE_ACCOUNT_V2,
    FETCH_CHANNEL_PARTNERS,
    FETCH_BILLING_OVERDUE_VEHICLES,
} from '../../actions/dashboard/accounts_actions';

import { FETCH_FIRMS } from '../../actions/dashboard/firms_actions';

import { FETCH_POS } from '../../actions/dashboard/po_actions';
import { arrayToObject } from 'utils/list_utils';

const initialState = {
    isLoading: false,
    error: null,
    isFinished: false,
    posForAccount: [],
    firmsList: [],
    isFirmsListLoading: false,
    billingOverDueVehicles: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_ACCOUNT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case CREATE_ACCOUNT_V2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_ACCOUNT_V2:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_ALL_ACCOUNTS_FOR_USER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    allAccounts: payload.data,
                }),
            });
        case FETCH_ACCOUNT_FOR_USER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    singleAccount: payload.data,
                }),
            });
        case FETCH_POS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    posForAccount: payload.data,
                }),
            });
        case FETCH_FIRMS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isFirmsListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isFirmsListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    firmsList: payload.data,
                }),
            });
        case FETCH_CHANNEL_PARTNERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    channelPartners: payload.data,
                }),
            });

        case FETCH_BILLING_OVERDUE_VEHICLES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    billingOverDueVehicles: arrayToObject(payload.data, 'vehicleId'),
                }),
            });

        default:
            return state;
    }
}
