import { forEach } from 'lodash';
import { handle } from 'redux-pack';
import {
    CREATE_SERVICE_TASK,
    DELETE_SERVICE_TASK,
    FETCH_SERVICE_TASK,
    FETCH_SERVICE_TASKS,
    UPDATE_SERVICE_TASK,
} from 'actions/dashboard/easy/service_tasks_action';

const initialState = {
    isLoading: false,
    error: null,
    serviceTasksList: [],
    data: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_SERVICE_TASK:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_SERVICE_TASK:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_SERVICE_TASK:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_SERVICE_TASKS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const serviceTasksListAsMap = new Map();
                    forEach(payload.data.content, (serviceTask) => {
                        serviceTasksListAsMap.set(serviceTask.id, serviceTask);
                    });
                    return {
                        ...prevState,
                        serviceTasksList: payload.data.content,
                        serviceTasksListAsMap,
                    };
                },
            });

        case FETCH_SERVICE_TASK:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: payload.data },
                }),
            });
        default:
            return state;
    }
}
