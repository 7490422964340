import { handle } from 'redux-pack';
import { FETCH_VEHICLE_DOCUMENTS } from 'actions/dashboard/vehicle-documentation/actions';
const initialState = {
    documents: {},
    isLoading: false,
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_VEHICLE_DOCUMENTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        documents: payload.data,
                        isLoading: false,
                    };
                },
            });
        default:
            return state;
    }
}
