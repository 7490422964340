import { DispatchState, ESCALATION_MATRIX_CONFIG, REPORT_CONFIG } from '../constant';
import storage from './storage_utils';
import { SET_TABLE_CONFIG } from 'actions/dashboard/local_action';

const clearConfig = () => {
    try {
        storage.setItem('LOCAL_STORAGE_TABLE_CONFIG_TIMESTAMP', '');
        storage.setItem(ESCALATION_MATRIX_CONFIG, '');
        storage.setItem(REPORT_CONFIG, '');
        if (window[DispatchState]) {
            window[DispatchState]({ type: SET_TABLE_CONFIG, payload: {} });
        }
    } catch (e) {
        console.log(e);
        if (window && window.Sentry) {
            window.Sentry.captureException(e);
        }
    }
};

export const LOGIN_WITH = {
    PASSWORD: 'PASSWORD',
    OTP: 'OTP',
};

export default clearConfig;
