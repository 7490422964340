import axios, { CancelToken } from 'axios';
import { ROOT_API_URL } from '../../../constant';

export const FETCH_PENDING_PAYMENTS = 'fetch_pending_payments';
export const PAYMENT_CREATE = 'payment_create';
export const FETCH_PAYMENT_BY_ID = 'fetch_payment_by_id';
export const FETCH_PAYMENTS = 'fetch_payments';
export const PAYMENT_DELETE = 'payment_delete';
export const PAYMENT_UPDATE = 'payment_update';
export const FETCH_PAYMENTS_SUMMARY = 'payment_summary';
export const INVOICE_PAYMENT_CUMULATIVE_SUMMARY = 'invoice_payment_cumulative_summary';

export function fetchPendingPayments(accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}finance/pending/payment`, config);
    return {
        type: FETCH_PENDING_PAYMENTS,
        promise: request,
    };
}

//onAccount=false(payment with bill) and onAccount=true(payment on account)
export function paymentCreate(accesstoken, values) {
    //pass onAccount = true/false
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}invoices/payments`, values, config);
    return {
        type: PAYMENT_CREATE,
        promise: request,
    };
}

export function fetchPaymentById(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}invoices/payments/${id}`, config);
    return {
        type: FETCH_PAYMENT_BY_ID,
        promise: request,
    };
}

export function fetchPayments(accesstoken, from, to, customerId, status, groupId) {
    const page = 1,
        size = 3000;

    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const toQuery = to ? `&to=${to}` : '';
    const groupIdQuery = groupId ? `&groupId=${groupId}` : '';
    const customerIdQuery = customerId ? `&customerId=${customerId}` : '';
    const statusQuery = status ? `&status=${status}` : '';
    const pageQuery = page ? `&page=${page - 1}` : '';
    const sizeQuery = size ? `&size=${size}` : '';

    const request = axios.get(
        `${ROOT_API_URL}invoices/payments?from=${from}${toQuery}${customerIdQuery}${statusQuery}${groupIdQuery}${pageQuery}${sizeQuery}`,
        config
    );
    return {
        type: FETCH_PAYMENTS,
        promise: request,
    };
}

export function paymentDelete(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}invoices/payments/${id}`, config);
    return {
        type: PAYMENT_DELETE,
        promise: request,
    };
}

export function paymentUpdate(accesstoken, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}invoices/payments`, values, config);
    return {
        type: PAYMENT_UPDATE,
        promise: request,
    };
}

export function paymentsSummary(accesstoken, customerId, from, to, status) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const toQuery = to ? `&to=${to}` : '';
    const customerIdQuery = customerId ? `&customerId=${customerId}` : '';
    const statusQuery = status ? `&status=${status}` : '';

    const request = axios.get(
        `${ROOT_API_URL}invoices/payments/summary?from=${from}${toQuery}${customerIdQuery}${statusQuery}`,
        config
    );
    return {
        type: FETCH_PAYMENTS_SUMMARY,
        promise: request,
    };
}

export function invoicePaymentsCumulativeSummary(accesstoken, from, to, customerId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const toQuery = to ? `&to=${to}` : '';
    const customerIdQuery = customerId ? `&customerId=${customerId}` : '';

    const request = axios.get(
        `${ROOT_API_URL}invoices/summary/cumulative?from=${from}${toQuery}${customerIdQuery}`,
        config
    );
    return {
        type: INVOICE_PAYMENT_CUMULATIVE_SUMMARY,
        promise: request,
    };
}
