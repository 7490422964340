import { handle } from 'redux-pack';
import { FETCH_GST_RATES } from 'actions/dashboard/consignments/gstRatesActions';

const initialState = {
    gstRates: {},
    isGstRatesLoading: false,
};

export default function (state = initialState, action, root) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_GST_RATES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isGstRatesLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isGstRatesLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    gstRates: payload.data,
                }),
            });

        default:
            return state;
    }
}
