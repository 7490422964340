export const VOUCHER_TYPES = [
    {
        value: 'DRIVER_ADVANCE',
        label: 'Driver Advance',
    },
    {
        value: 'CN_ADVANCE',
        label: 'CN Advance',
    },
    {
        value: 'PAYMENT_RECEIPT',
        label: 'Payment Receipt',
    },
    {
        value: 'HIRE_SLIP',
        label: 'Balance Payment',
    },
    {
        value: 'ON_ACCOUNT_PAYMENT',
        label: 'On Account Payment',
    },
    {
        value: 'ON_ACCOUNT_RECEIPT',
        label: 'On Account Receipt',
    },
    {
        value: 'JOB_BILL_RECEIPT',
        label: 'Job Bill Receipt',
    },
];
