import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { shouldShowField } from '../utils/form_utils';
import styled from 'styled-components';
const Wrapper = styled.div `
    display: contents;
    &:hover .edit_form_field {
        visibility: visible;
    }
    &:not(:hover) .edit_form_field {
        visibility: hidden;
    }
`;
const FxTemplateFieldContainer = (props) => {
    const { fieldsMetaData, fieldId, children, isTemplate = true } = props;
    return (_jsx(_Fragment, { children: !isTemplate ? (_jsxs(_Fragment, { children: [" ", shouldShowField(fieldsMetaData, fieldId) ? children : null] })) : (_jsxs(_Fragment, { children: [' ', _jsx(Wrapper, { children: children })] })) }));
};
export default FxTemplateFieldContainer;
