import { useState } from 'react';
import MultiFileDropzoneComponentThumbnail from '../components/utils/multi_file_dropzone_component_thumbnail';
import { get } from 'lodash';

export default (props) => {
    const [fileUploadError, setFileUploadError] = useState(null);
    const {
        field,
        form,
        input,
        label,
        isRequired,
        outerDivStyle,
        labelDivStyle = '',
        style,
        lgThumbnailView,
        thumbStyle,
        addMoreStyle,
        currentIndex,
    } = props;
    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');

    return (
        <div className={outerDivStyle}>
            {label && (
                <div className={error ? 'form-control-label text-danger' : `${labelDivStyle}`}>
                    <label>{label}</label>
                    {isRequired && <abbr className="text-danger">*</abbr>}
                </div>
            )}
            <MultiFileDropzoneComponentThumbnail
                {...props}
                uploadedUrl={field.value || field.value === 0 ? field.value : null}
                fileErrorCallback={(error) => {
                    setFileUploadError(error);
                    props.fileErrorCallback();
                }}
                fileSuccessCallback={(url, currentIndex) => {
                    setFileUploadError(null);
                    form.setFieldValue(field.name, null);
                    props.fileSuccessCallback(url, currentIndex);
                }}
                fileDeleteCallback={(index, remove) => {
                    console.log('fileDeleteCallback', index, remove);
                    setFileUploadError(null);
                    form.setFieldValue(field.name, null);
                    props.fileDeleteCallback(index, remove);
                }}
                style={style}
                lgThumbnailView={lgThumbnailView}
                thumbStyle={thumbStyle}
                addMoreStyle={addMoreStyle}
                showDropZone={props.showDropZone}
                showAddMore={props.showAddMore}
                imageList={props.imageList}
                currentIndex={currentIndex}
            />
            {(fileUploadError || error) && (
                <div className="text-help text-danger">
                    {fileUploadError}-{error}
                </div>
            )}
        </div>
    );
};
