import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, filter, isEqual, includes } from 'lodash';
import { Field } from 'formik';
import { FxTextFieldStackedLabel, FxSelectField } from '../../../shared';
import { getMappedBookTypes, mappedStationaries } from '../../utils/mapping_utils';
import { fetchStationaries, getNextStationary, validateStationary, fetchStationary, } from '../../../actions/dashboard/stationary/actions';
import FxFieldLabelWithEditButton from '../../../shared/FxFieldLabelWithEditButton';
import FxTemplateFieldContainer from '../../../shared/FxTemplateFieldContainer';
import { isViewStationaryAllowedForUser } from '../roles/permission_utils';
class BillNumberComponent extends Component {
    initalStationaryId = null;
    initalStationaryNumber = null;
    state = {
        stationaryNature: null,
        isNextStationaryNumberFetching: false,
    };
    componentDidMount = async () => {
        const { branch, bookTypeIssuance } = this.props.formikProps.values;
        if (isViewStationaryAllowedForUser(this.props.login.scope) && branch && bookTypeIssuance) {
            this.props.fetchStationaries(branch, bookTypeIssuance, () => {
                this.onStationarySelect(this.props.formikProps.values.issuanceStationaryId, branch, bookTypeIssuance, this.props.formikProps);
            });
        }
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.isReadyToFetchBillNumber && this.props.isEdit) {
            if (!this.props.formikProps.values.branch && get(this.props.formikProps.values, 'issuanceStationaryId')) {
                this.initalStationaryId = get(this.props.formikProps.values, 'issuanceStationaryId');
                this.initalStationaryNumber = get(this.props.formikProps.values, 'issuanceStationaryNumber');
                this.props
                    .fetchStationary(this.props.accesstoken, get(this.props.formikProps.values, 'issuanceStationaryId'))
                    .then((res) => {
                    const stationary = get(res, 'payload.data');
                    this.props.formikProps.setFieldValue('branch', stationary.branch.id);
                    this.props.fetchStationaries(stationary.branch.id, stationary.bookType, () => {
                        this.onStationarySelect(this.props.formikProps.values.issuanceStationaryId, stationary.branch.id, stationary.bookType, this.props.formikProps);
                    });
                });
            }
        }
    };
    onStationarySelect = (val, branch, bookType, formikProps, forceFetchNextStationary) => {
        if (val) {
            formikProps.setFieldValue('issuanceStationaryId', val);
            const stationary = filter(this.props.allStationaries, (stationary) => stationary.id == val);
            const stationaryNature = get(stationary, '[0].nature');
            this.setState({
                stationaryNature,
            }, () => {
                if (this.state.stationaryNature == 'AUTO' &&
                    branch &&
                    bookType &&
                    (!formikProps.values.issuanceStationaryNumber || forceFetchNextStationary)) {
                    if (this.initalStationaryId != val) {
                        this.setState({ isNextStationaryNumberFetching: true });
                        this.props
                            .getNextStationary(this.props.accesstoken, branch, bookType, get(stationary, '[0].bookName'))
                            .then((res) => {
                            this.setState({ isNextStationaryNumberFetching: false });
                            const nextStationaryNumber = get(res, 'payload.data.[0].nextStationaryNumber', '');
                            if (!nextStationaryNumber) {
                                alert('Book name not valid or disabled !!');
                                formikProps.setFieldValue('issuanceStationaryId', '');
                            }
                            formikProps.setFieldValue('issuanceStationaryNumber', nextStationaryNumber);
                            this.props.onStationaryNumberPopulate &&
                                this.props.onStationaryNumberPopulate(nextStationaryNumber);
                        });
                    }
                    else {
                        formikProps.setFieldValue('issuanceStationaryNumber', this.initalStationaryNumber);
                        this.props.onStationaryNumberPopulate &&
                            this.props.onStationaryNumberPopulate(this.initalStationaryNumber);
                    }
                }
                else if (includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature) &&
                    (!formikProps.values.issuanceStationaryNumber || forceFetchNextStationary)) {
                    if (this.initalStationaryId != val) {
                        formikProps.setFieldValue('issuanceStationaryNumber', `${get(stationary, '[0].code')}-`);
                    }
                    else {
                        formikProps.setFieldValue('issuanceStationaryNumber', this.initalStationaryNumber);
                        this.props.onStationaryNumberPopulate &&
                            this.props.onStationaryNumberPopulate(this.initalStationaryNumber);
                    }
                }
            });
        }
    };
    validateBillNumber = (stationaryNumber, stationaryId, setFieldError) => {
        if (stationaryNumber && stationaryId) {
            this.props
                .validateStationary(this.props.accesstoken, stationaryId, stationaryNumber)
                .then((res) => {
                if (!get(res, 'payload.data')) {
                    setFieldError('issuanceStationaryNumber', 'Invalid Stationary Number');
                }
                else {
                    this.props.onStationaryNumberPopulate &&
                        this.props.onStationaryNumberPopulate(stationaryNumber);
                }
            })
                .catch((e) => { });
        }
    };
    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }
    render() {
        const { branch, bookTypeIssuance, issuanceStationaryId } = this.props.formikProps.values;
        const { isNextStationaryNumberFetching } = this.state;
        const { fieldsMetaData, isTemplate, handleBooleanUpdate, handleTextUpdate, onCreateTemplate, onUpdateTemplate, isUpdateTemplate, } = this.props;
        return (_jsx(_Fragment, { children: _jsx("div", { className: "form-group", children: _jsxs("div", { className: "row", children: [_jsx(FxTemplateFieldContainer, { fieldsMetaData: fieldsMetaData, fieldId: 'bookTypeIssuance', isTemplate: isTemplate, children: _jsx("div", { className: "col-md-6", children: _jsx(FxFieldLabelWithEditButton, { values: this.props.formikProps.values, onCreateTemplate: onCreateTemplate, onUpdateTemplate: onUpdateTemplate, isUpdateTemplate: isUpdateTemplate, fieldsMetaData: fieldsMetaData, fieldId: 'bookTypeIssuance', defaultLabel: 'Book Type', handleBooleanUpdate: handleBooleanUpdate, handleTextUpdate: handleTextUpdate, isTemplate: isTemplate, children: _jsx(Field, { name: "bookTypeIssuance", options: getMappedBookTypes(), component: FxSelectField, 
                                        // onSelected={(val) => this.fetchStationaries(branch, val, null, true)}
                                        disabled: true }) }) }) }), _jsx(FxTemplateFieldContainer, { fieldsMetaData: fieldsMetaData, fieldId: 'issuanceStationaryId', isTemplate: isTemplate, children: branch && bookTypeIssuance && (_jsx("div", { className: "col-md-6", children: _jsx(FxFieldLabelWithEditButton, { values: this.props.formikProps.values, onCreateTemplate: onCreateTemplate, onUpdateTemplate: onUpdateTemplate, isUpdateTemplate: isUpdateTemplate, fieldsMetaData: fieldsMetaData, fieldId: 'issuanceStationaryId', defaultLabel: 'Select Book Name', isRequired: this.props.branchRequired, handleTextUpdate: handleTextUpdate, handleBooleanUpdate: handleBooleanUpdate, isTemplate: isTemplate, children: _jsx(Field, { name: "issuanceStationaryId", isRequired: this.props.branchRequired, options: mappedStationaries(this.props.allStationaries), component: FxSelectField, onSelected: (val) => {
                                            if (this.props.onStationarySelectCb) {
                                                this.props.onStationarySelectCb(val);
                                            }
                                            this.onStationarySelect(val, branch, bookTypeIssuance, this.props.formikProps, true);
                                        }, disabled: get(this.props, 'fieldDisabled.bookName', false) }) }) })) }), _jsx(FxTemplateFieldContainer, { fieldsMetaData: fieldsMetaData, fieldId: 'issuanceStationaryNumber', isTemplate: isTemplate, children: branch && bookTypeIssuance && issuanceStationaryId && (_jsxs("div", { className: "col-md-6", children: [_jsx(FxFieldLabelWithEditButton, { values: this.props.formikProps.values, onCreateTemplate: onCreateTemplate, onUpdateTemplate: onUpdateTemplate, isUpdateTemplate: isUpdateTemplate, fieldsMetaData: fieldsMetaData, fieldId: 'issuanceStationaryNumber', defaultLabel: get(this.props, 'fieldLabel.issuanceStationaryNumber', 'Stationary Number'), handleBooleanUpdate: handleBooleanUpdate, handleTextUpdate: handleTextUpdate, isTemplate: isTemplate, children: _jsx(Field, { name: "issuanceStationaryNumber", type: "text", component: FxTextFieldStackedLabel, disabled: this.state.stationaryNature == 'AUTO' ||
                                                get(this.props, 'fieldDisabled.issuanceStationaryNumber', false), onBlur: includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature)
                                                ? (stationaryNumber) => this.validateBillNumber(stationaryNumber, issuanceStationaryId, this.props.formikProps.setFieldError)
                                                : () => { }, isRequired: !!issuanceStationaryId }) }), this.state.stationaryNature === 'AUTO' && (_jsx("div", { style: { marginTop: '-10px' }, className: "d-flex justify-content-end", children: _jsx("button", { disabled: isNextStationaryNumberFetching, type: "button", onClick: () => {
                                                this.onStationarySelect(issuanceStationaryId, branch, bookTypeIssuance, this.props.formikProps, true);
                                            }, className: "btn btn-link", children: "Refresh Stationary Number" }) }))] })) })] }) }) }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchStationaries, getNextStationary, validateStationary, fetchStationary }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillNumberComponent);
