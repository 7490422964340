import {handle} from "redux-pack";

import {ADD_USER_IMAGE, DELETE_USER_IMAGE, FETCH_USER_IMAGES} from "../../actions/dashboard/user_image_action";

const initialState = {
    isUserImageLoading: false, error: null, userImageList: []
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case ADD_USER_IMAGE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isLoading: false}),
                failure: prevState => ({...prevState, error: payload})

            });
        case DELETE_USER_IMAGE :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, error: null
                }),
                finish: prevState => ({...prevState}),
                failure: prevState => ({...prevState, error: payload})
            });

        case FETCH_USER_IMAGES :
            return handle(state, action, {
                start: prevState => ({
                    ...prevState, isUserImageLoading: true, error: null
                }),
                finish: prevState => ({...prevState, isUserImageLoading : false}),
                failure: prevState => ({...prevState, error: payload}),
                success: prevState => ({
                    ...prevState, userImageList: payload.data.content
                }),
            });

        default:
            return state;
    }
}
