import { capitalize, toLower, trim, forEach, split } from 'lodash';
import { getMomentTime } from 'utils/date_utils';

export function getAllWordsCapitalized(text) {
    return text ? toLower(text).replace(/\w+/g, capitalize) : text;
}

export function convertPlainTextIntoLinks(text, target = '_blank', textColor = 'white') {
    return (text || '').replace(/([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
        let hyperlink = url;
        if (!hyperlink.match('^https?://')) {
            hyperlink = 'http://' + hyperlink;
        }
        return `<pre>${space}</pre><a href="${hyperlink}" target="${target}" style="color:${textColor};" >${url}</a>`;
    });
}

export function queryStringParse(queryString) {
    if (!queryString) {
        return {};
    }
    let query = queryString.substring(1);
    let params = query.split('&');
    let paramsObject = {};
    let pair;
    for (let i = 0; i < params.length; i++) {
        pair = params[i].split('=');
        paramsObject[pair[0]] = decodeURIComponent(pair[1]);
    }
    return paramsObject;
}

export function queryStringStringify(paramsObject) {
    if (!paramsObject) {
        return '';
    }
    return Object.keys(paramsObject)
        .map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(paramsObject[key]);
        })
        .join('&');
}

export const queryString = {
    parse: queryStringParse,
    stringify: queryStringStringify,
};

export function matchAnyWordStartWith(text, searchFilter) {
    if (!text || !searchFilter) {
        return false;
    }
    try {
        const searchTerm = toLower(trim(searchFilter));
        const regex = new RegExp(`([\\s-_|$/\(]|^)${searchTerm}.*`);
        const searchInTerm = toLower(trim(text));
        return !!searchInTerm.match(regex);
    } catch (e) {
        return false;
    }
}

/**
 *
 * @param arrayString ex. "a,b,c ,d   ,e"
 * @param sep
 * @returns {Array}  ex. [a,b,c,d]
 */
export function safeArrayStringSplit(arrayString, sep = ',') {
    let list = [];
    let temp;
    forEach(split(arrayString, sep), (s) => {
        temp = trim(s);
        if (temp) {
            list.push(temp);
        }
    });

    return list;
}

export function generateSuffix({ vehicleId, startDate, endDate, viewType }) {
    let suffix = `vehicleId=${vehicleId}&startDate=${getMomentTime(startDate).valueOf()}&view=${viewType}`;
    if (endDate) {
        suffix += `&endDate=${getMomentTime(endDate).valueOf()}`;
    }
    return suffix;
}

export function getEllipsisedText(text, maxLen) {
    if (!text || text.length <= maxLen || maxLen <= 3) {
        return text;
    } else {
        return `${text.substring(0, maxLen - 3)}...`;
    }
}

export function camelCaseToWords(text = '') {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}

function capitalizeWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function capitalizeWords(input) {
    return input.split(' ').map(capitalizeWord).join(' ');
}
