import { handle } from 'redux-pack';
import { FETCH_BILLS } from 'actions/dashboard/BillingInfo';

const initialState = {
    isLoading: false,
    bills: [],
    totalElements: 0,
    error: null,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_BILLS:
            console.log('payload', payload);
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    bills: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        default:
            return state;
    }
};
