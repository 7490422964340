import { handle } from 'redux-pack';
import { FETCH_REPORT_TEMPLATES } from '../../actions/dashboard/reportTemplatesAction';
const initialState = {
    isReportTemplatesLoading: false,
    reportTemplates: [],
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_REPORT_TEMPLATES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isReportTemplatesLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isReportTemplatesLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, reportTemplates: [] }),
                success: (prevState) => ({
                    ...prevState,
                    reportTemplates: payload.data,
                }),
            });
        default:
            return state;
    }
}
