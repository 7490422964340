import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const ShiftComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-shift" */ 'components/dashboard/settings/log_shift/log_shift'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const LogShiftStart = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-logShiftStart" */ 'components/dashboard/settings/log_shift/log_shift_start'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const LogShiftEnd = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-logShiftEnd" */ 'components/dashboard/settings/log_shift/log_shift_end'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const logShiftRoutes = [
    {
        index: true,
        lazy: ShiftComponent,
    },
    {
        path: 'start',
        lazy: LogShiftStart,
    },
    {
        path: 'end',
        lazy: LogShiftEnd,
    },
];
export default logShiftRoutes;
