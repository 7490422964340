import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { FxFlex } from '../../shared';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, map, upperFirst, toLower } from 'lodash';
import { Button } from 'reactstrap';
import { setLocalTransporterAccount } from '../../actions/dashboard/local_action';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { alternateRowClassNameFormat } from '../../utils/grid_utils';
import { withRoute } from 'components/dashboard/customHocs/withRoute';
class AccountsComponent extends Component {
    renderAccounts = () => {
        const data = get(this.props, 'loggedInUser.userAccounts', []);
        let accountData = map(data, (a) => a.account);
        const options = {
            noDataText: 'No accounts found',
            onRowClick: this.onRowClick,
        };
        return (_jsx("div", { className: "pos-rel", children: _jsxs(BootstrapTable, { containerClass: "white-background", tableContainerClass: "fleetx-table2-container", tableHeaderClass: "fleetx-table", tableBodyClass: "fleetx-table2", trClassName: alternateRowClassNameFormat, data: accountData, options: options, version: "4", children: [_jsx(TableHeaderColumn, { width: "150", dataField: "name", dataFormat: this.showName, children: "Account" }), _jsx(TableHeaderColumn, { width: "70", className: "whitespace-normal", dataFormat: this.showMoreButtons, thStyle: { whiteSpace: 'normal', paddingRight: 0 }, tdStyle: {
                            whiteSpace: 'normal',
                            verticalAlign: 'middle',
                            overflow: 'visible',
                        }, isKey: true, dataField: "id" })] }) }));
    };
    onRowClick = ({ id }) => {
        this.props.setLocalTransporterAccount(id);
        this.props.navigate('/dashboard/vendors');
    };
    handleAccountClick = (e, id) => {
        e.stopPropagation();
        this.props.setLocalTransporterAccount(id);
        this.props.navigate('/dashboard/vendors');
    };
    showName = (name) => {
        return _jsx("div", { children: upperFirst(toLower(name)) });
    };
    showMoreButtons = (id, account) => {
        return (_jsx(Button, { color: "success", className: "my-2 mx-4", onClick: (e) => this.handleAccountClick(e, get(account, 'id')), children: "Switch" }));
    };
    render() {
        return (_jsx("div", { className: "mt-4", children: _jsxs(FxFlex, { className: "mt-4", direction: "column", justifyContent: "center", alignItems: "center", children: [_jsx("h5", { className: "card-title col-md-9 px-0", "col-md-9": true, "px-0": true, children: "Select Account" }), _jsx("div", { className: "card col-md-9 px-0", children: this.renderAccounts() })] }) }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLocalTransporterAccount }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: state.login.data.access_token,
        login: state.login.data,
        loggedInUser: state.settings.loggedInUser,
    };
}
export default withRoute(connect(mapStateToProps, mapDispatchToProps)(AccountsComponent));
