import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP, ROOT_API_URL } from '../../../constant';
import { getMomentTime } from '../../../utils/date_utils';
export const CREATE_PART = 'create_part';
export const UPDATE_PART = 'update_part';
export const DELETE_PART = 'delete_part';
export const FETCH_PARTS = 'fetch_parts';
export const GET_PARTS_REPORT = 'get_parts_report';
export const FETCH_PART_BY_ID = 'fetch_part_by_id';
export const FETCH_ALL_MEASUREMENT_UNITS = 'fetch_all_measurement_units';
export const FETCH_PART_EXPIRY = 'FETCH_PART_EXPIRY';
export const FETCH_VENDOR_WISE_PART = 'FETCH_VENDOR_WISE_PART';
export const BULK_STORE_TRANSFER = 'BULK_STORE_TRANSFER';
export function createPart(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}part`, data, config);
    return {
        type: CREATE_PART,
        promise: request,
    };
}
export function updatePart(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}part`, data, config);
    return {
        type: UPDATE_PART,
        promise: request,
    };
}
export function fetchParts(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}part`, config);
    return {
        type: FETCH_PARTS,
        promise: request,
    };
}
export function getPartsReport(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}report/part`, config);
    return {
        type: GET_PARTS_REPORT,
        promise: request,
    };
}
export function fetchPartById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}part/${id}`, config);
    return {
        type: FETCH_PART_BY_ID,
        promise: request,
    };
}
export function deletePart(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}part/${id}`, config);
    return {
        type: DELETE_PART,
        promise: request,
    };
}
export function fetchAllMeasurementUnits(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}part/measurement`, config);
    return {
        type: FETCH_ALL_MEASUREMENT_UNITS,
        promise: request,
    };
}
export function fetchPartExpiry(accesstoken, partId, vehicleId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            partId,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}work_orders/part-expiry`, config);
    return {
        type: FETCH_PART_EXPIRY,
        promise: request,
    };
}
export function fetchVendorWiseParts(accesstoken, from, to) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part/search`, config);
    return {
        type: FETCH_VENDOR_WISE_PART,
        promise: request,
    };
}
export function partStoreOut(accesstoken, partId, storeId, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: {
            storeId,
        },
    };
    const request = axios.post(`${ROOT_API_ERP}part/out/${partId}`, data, config);
    return {
        type: BULK_STORE_TRANSFER,
        promise: request,
    };
}
export function bulkStoreTransfer(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}part/bulk/store-out`, data, config);
    return {
        type: BULK_STORE_TRANSFER,
        promise: request,
    };
}
