import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const ConsignmentsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-tmsConsignments" */ 'components/dashboard/vendor_management/tmsConsignments/ConsignmentsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditConsignmentsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-tmsConsignments" */ 'components/dashboard/vendor_management/tmsConsignments/form/AddEditConsignmentsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const tmsConsignmentsRoutes = [
    {
        path: 'add',
        lazy: AddEditConsignmentsComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditConsignmentsComponent,
    },
    {
        index: true,
        lazy: ConsignmentsComponent,
    },
];
export default tmsConsignmentsRoutes;
