import axios from 'axios';
import { ROOT_API_ALFRED, parseQueryParams } from '../../../../constant';
export const FETCH_MODULE_FILTERS = 'FETCH_MODULE_FILTERS';
export function fetchModuleFilters(accesstoken, params) {
    const { module } = params || {};
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            module,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/module-filter`, config);
    return {
        type: FETCH_MODULE_FILTERS,
        promise: request,
    };
}
