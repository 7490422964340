import { handle } from 'redux-pack';
import { FETCH_CONTRACTS } from '../../actions/dashboard/vendor_management/contractManagementActions';
const initialState = {
    isContractsLoading: false,
    allContracts: [],
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_CONTRACTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isContractsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isContractsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    allContracts: payload.data,
                }),
            });
        default:
            return state;
    }
}
