import { isEqual } from 'lodash';
import CalendarSingleDatePicker from '../components/utils/calendar_single_date_picker';
import { getMomentTime } from '../utils/date_utils';

const FxSingleDatePicker = (passedProps) => {
    const { field, form, startDate, showDateLeft, ...props } = passedProps;
    const { touched, errors } = form;
    const onDateSelected = (startDate) => {
        this.props.onDateSelected(startDate);
    };

    return (
        <CalendarSingleDatePicker
            showDateLeft
            onDateSelected={onDateSelected}
            startDate={startDate}
            {...props}
            {...field}
        />
    );
};

export default FxSingleDatePicker;
