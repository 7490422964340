import { GST_NATURES } from 'utils/consignmentsUtils';
export const shouldCalcTax = ({ gstNature, vendorGstNature }) => {
    return ((gstNature === GST_NATURES.FCM && vendorGstNature === GST_NATURES.FCM) ||
        (gstNature === GST_NATURES.RCM && vendorGstNature === GST_NATURES.FCM) ||
        (gstNature === GST_NATURES.FCM && vendorGstNature === GST_NATURES.RCM));
};
export const shouldAddTaxInTotal = ({ gstNature, vendorGstNature }) => {
    return ((gstNature === GST_NATURES.FCM && vendorGstNature === GST_NATURES.FCM) ||
        (gstNature === GST_NATURES.RCM && vendorGstNature === GST_NATURES.FCM));
};
export const shouldShowOutputTax = ({ gstNature, vendorGstNature }) => {
    return gstNature === GST_NATURES.FCM && vendorGstNature === GST_NATURES.RCM;
};
