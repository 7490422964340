import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
const FxCustomSegment = (props) => {
    const { onSelect, size = 'large', buttonStyle = 'solid', innerRef, ...rest } = props;
    const { t } = useTranslation();
    const onChangeHandler = (event) => {
        const newValue = event.target.value;
        onSelect(newValue);
    };
    return (_jsx(Radio.Group, { ref: innerRef, buttonStyle: buttonStyle, optionType: 'button', size: props.size || 'large', onChange: onChangeHandler, ...rest }));
};
export default FxCustomSegment;
